import React from 'react'
import { Grid, Typography, Card } from '@mui/material'
import { KeyboardArrowRight, KeyboardArrowDown } from '@mui/icons-material'

import { CARD_TEXT_COLOR, LIGHT_GRAY_BORDER_COLOR, PRIMARY_COLOR } from '../../../utils/constants'

import { AvatarNoImage } from '../../common'
import { useTranslation } from 'react-i18next'

const CheckoutSubscription = ({
  subscription,
  currency,
  expandSelectTrainer,
  selectedTrainer,
  computeSelectedTrainerName,
  handleSetSelectedTrainer,
  setExpandSelectTrainer
}) => {
  const { t } = useTranslation()
  return (
    <Grid container display="flex" flexDirection="column">
      <Grid item lg={12}>
        <Grid
          component={Card}
          sx={{ borderRadius: 2, border: `3px solid ${PRIMARY_COLOR}` }}
          container
          display="flex"
          flexDirection="column"
        >
          <Grid
            item
            lg={12}
            sx={{ borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }}
            p={2}
            pt={0}
            pb={0}
            display="flex"
            alignItems="center"
          >
            <Grid container display="flex" flexDirection="row">
              <Grid item lg={8}>
                <Typography
                  variant="h6"
                  fontWeight={600}
                  color="primary"
                  sx={{ textDecoration: 'underline' }}
                >
                  {subscription.name}
                </Typography>
              </Grid>
              <Grid item lg={4} ml={{ xs: 'auto' }}>
                <Typography
                  sx={{ float: 'right' }}
                  fontWeight={600}
                  variant="h6"
                  color="primary"
                >{`${subscription.priceRon ? currency : ''} ${subscription.priceRon || 'N/A'
                  }`}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} sx={{ borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }} p={2}>
            <Typography color={CARD_TEXT_COLOR} variant="body1">
              {t('checkout.description')}
            </Typography>
            <Typography variant="body2">{subscription.description}</Typography>
          </Grid>
          <Grid item lg={12} sx={{ borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }} p={2}>
            <Grid container display="flex" flexDirection="row">
              <Grid item lg={6} sx={{ maxHeight: 50 }}>
                <Grid container display="flex" flexDirection="column">
                  <Grid item lg={12}>
                    <Typography color={CARD_TEXT_COLOR} variant="body1">
                      {t('checkout.trainer')}
                    </Typography>
                    <Typography
                      sx={{ textDecoration: 'underline' }}
                      color="primary"
                      variant="body2"
                    >
                      {subscription.SubscriptionsTrainers &&
                        subscription.SubscriptionsTrainers.length === 1
                        ? `${subscription.SubscriptionsTrainers[0].Trainer.firstName} ${subscription.SubscriptionsTrainers[0].Trainer.lastName}`
                        : subscription.SubscriptionsTrainers &&
                          subscription.SubscriptionsTrainers.length > 1
                          ? subscription.SubscriptionsTrainers.map(
                            (el) => `${el.Trainer.firstName} ${el.Trainer.lastName}`
                          ).join(',')
                          : 'N/A'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={6} ml={{ xs: 'auto' }}>
                <Grid container display="flex" flexDirection="column" alignItems="flex-end">
                  <Grid item>
                    <Typography color={CARD_TEXT_COLOR} variant="body1">
                      {t('checkout.duration')}
                    </Typography>
                    <Typography lg={12} sx={{ float: 'right' }} variant="body2">
                      {subscription.duration} {t('checkout.days')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {subscription &&
        subscription.SubscriptionsTrainers &&
        subscription.SubscriptionsTrainers.length > 1 ? (
        <Grid item lg={12} pt={2}>
          <Grid
            container
            display="flex"
            flexDirection="row"
            component={Card}
            p={2}
            sx={{ cursor: 'pointer' }}
            onClick={() => setExpandSelectTrainer((prevValue) => !prevValue)}
          >
            <Grid item>
              {!expandSelectTrainer ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
            </Grid>
            <Grid item lg={10}>
              <Typography variant="body1" fontWeight={600}>
                {selectedTrainer ? computeSelectedTrainerName() : 'Select your trainer'}
              </Typography>
              {expandSelectTrainer ? (
                <Grid container display="flex" flexDirection="column">
                  {subscription.SubscriptionsTrainers.map((el, index) => {
                    return (
                      <Grid
                        item
                        lg={12}
                        pt={index === 0 ? 2 : 0.5}
                        pb={0.5}
                        onClick={() => handleSetSelectedTrainer(el.Trainer.id)}
                      >
                        <Grid
                          container
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          sx={{ maxHeight: 40 }}
                        >
                          <Grid item pr={2}>
                            {el.Trainer.avatarPhotoSrc ? (
                              <img
                                width={30}
                                style={{ objectFit: 'contain' }}
                                height={30}
                                src={el.Trainer.avatarPhotoSrc}
                              />
                            ) : (
                              <AvatarNoImage
                                typographyVariant={'body2'}
                                width={30}
                                height={30}
                                firstName={el.Trainer.firstName}
                                lastName={el.Trainer.lastName}
                              />
                            )}
                          </Grid>
                          <Grid item lg={10}>
                            <Typography variant="body2">
                              {el.Trainer.firstName} {el.Trainer.lastName}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  )
}

export default CheckoutSubscription
