import React, { useEffect, useState, useMemo } from 'react'
import { Container, Grid, Typography, CircularProgress, Card, Avatar, TextField, Button, Box, Select, MenuItem, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Money, CreditCard, Receipt, PendingActions, Summarize, ArrowLeft, GroupAdd, CallMerge, Addchart, PlaylistRemove } from '@mui/icons-material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useNavigate, useLocation } from 'react-router-dom'
import moment from 'moment'

import { VerticalBarChart, Table } from '../../../common'
import PaymentModal from './PaymentsModal'
import ClientsModal from './ClientsModal'

import * as PAYMENT_REQUESTS from '../../../../api/payments'
import * as COMPANY_REQUESTS from '../../../../api/company'
import * as REPORT_REQUESTS from '../../../../api/reports'
import { useAuthentication } from '../../../../context/auth-context'
import { numberFomatter } from '../../../../utils/helpers/numberFomatter'
import { GRAY_TEXT_COLOR, PAYMENT_METHOD, GREEN_COLOR, COMPANIES_TRAINERS_REQUEST_STATUS, ERROR_COLOR, PRIMARY_COLOR } from '../../../../utils/constants'

const RevenueTypeCard = ({ onClick, value, icon, type, total, color, text, noText, noPercantage, noPercentageText, givenPercentage, noPercent, fixedHeight, currency }) => {
    const { t } = useTranslation()

    let percentage = Number(Number(value) * Number(100) / Number(total))

    if (isNaN(percentage)) {
        percentage = 0
    }

    return (
        <Grid item component={Card} p={2} onClick={onClick ? onClick : () => { }} sx={onClick ? { cursor: 'pointer' } : {}}>
            <Grid item xs={12} display="flex" flexDirection="row" alignItems="center">
                {icon}  <Typography variant="h6" color={color ? color : GREEN_COLOR} fontWeight="bold" pl={1}>{type}</Typography>
            </Grid>
            <Grid item xs={12} pt={1}>
                <Typography sx={fixedHeight ? { minHeight: 100 } : {}} variant="body2" fontWeight={500} color={GRAY_TEXT_COLOR}>{text ? text : t('reports.revenue.totalSales') + ' ' + type}</Typography>
                {noText ? null : <Typography variant="body1" fontWeight="bold" color="success">{numberFomatter(value)} {currency}</Typography>}
            </Grid>
            {noPercantage ? null : <Grid item xs={12}>
                {noPercentageText ? null : <Typography variant="body2" fontWeight={500} color={GRAY_TEXT_COLOR}>{t('reports.revenue.percentageTotalSales')}</Typography>}
                <Typography variant="body1" fontWeight="bold" color="success">{givenPercentage !== null && givenPercentage !== undefined ? noPercent ? givenPercentage : numberFomatter(givenPercentage) : numberFomatter(percentage)}{noPercent ? '' : '%'} </Typography>
            </Grid>}
        </Grid>
    )
}

const Filters = ({ onStartChange, onEndChange, startValue, endValue, onSubscriptionChange, subscriptions, currency }) => {
    const { t } = useTranslation()
    return (
        <Grid container display="flex" spacing={1} pt={1}>
            <Grid item xs={6} lg={3} display="flex" flexDirection="column">
                <Typography variant="p" fontWeight={500} gutterBottom>{t('reports.revenue.startTime')}</Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        inputFormat="dd/MM/yyyy"
                        value={moment(startValue)}
                        onChange={(newValue) => {
                            if (Date.parse(newValue)) {
                                onStartChange(moment(newValue).format('YYYY-MM-DD'))
                            }
                        }}
                        renderInput={(params) => <TextField
                            fullWidth
                            sx={{
                                '.MuiIconButton-root': { marginRight: 1 }
                            }}
                            {...params}
                        />}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={6} lg={3} display="flex" flexDirection="column">
                <Typography variant="p" fontWeight={500} gutterBottom>{t('reports.revenue.endTime')}</Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        inputFormat="dd/MM/yyyy"
                        value={moment(endValue)}
                        onChange={(newValue) => {
                            if (Date.parse(newValue)) {
                                onEndChange(moment(newValue).format('YYYY-MM-DD'))
                            }
                        }}
                        renderInput={(params) => <TextField
                            fullWidth
                            sx={{
                                '.MuiIconButton-root': { marginRight: 1 }
                            }}
                            {...params}
                        />}
                    />
                </LocalizationProvider>
            </Grid>
            {onSubscriptionChange && subscriptions ? <Grid item xs={12} lg={3} display="flex" flexDirection="column">
                <Typography variant="p" fontWeight={500}>{t('reports.revenue.selectedSubscription')}</Typography>
                <Select
                    onChange={onSubscriptionChange}
                >
                    {subscriptions.map((el) => {
                        return <MenuItem value={el.id} key={el.id}>
                            {el.name + ' - ' + (el.priceRon == 0 ? 'Free' : (`${el.priceRon} ${currency}`))}
                        </MenuItem>
                    })}
                </Select>
            </Grid> : null}
        </Grid>
    )
}

export default () => {
    const { t } = useTranslation()
    const { user } = useAuthentication()
    const navigate = useNavigate()
    const currency = useLocation()?.state?.currency || 'RON'

    const [showPaymentModal, setShowPaymentModal] = useState()
    const [payments, setPayments] = useState([])
    const [subscriptions, setSubscriptions] = useState([])
    const [selectedSubscription, setSelectedSubscription] = useState()
    const [loading, setLoading] = useState(true)
    const [start, setStart] = useState()
    const [end, setEnd] = useState()
    const [startConversion, setStartConversion] = useState()
    const [endConversion, setEndConversion] = useState()
    const [trainers, setTrainers] = useState({})
    const [conversion, setConversion] = useState()
    const [showNewClientsModal, setShowNewClientsModal] = useState()
    const [showConversionRatesModal, setShowConversionRatesModal] = useState()
    const [showLostClientsModal, setShowLostClientsModal] = useState()

    useEffect(() => {
        const currentDate = new Date()
        const year = currentDate.getFullYear()
        const month = currentDate.getMonth()
        const firstDay = new Date(year, month, 1)
        const lastDay = new Date(year, month + 1, 0)

        setStart(moment(firstDay.toISOString().split('T')[0]))
        setEnd(moment(lastDay.toISOString().split('T')[0]))
        setStartConversion(moment(firstDay.toISOString().split('T')[0]))
        setEndConversion(moment(lastDay.toISOString().split('T')[0]))
    }, [])

    useEffect(() => {
        if (!start || !end) return

        REPORT_REQUESTS.getConversions({
            start: moment(startConversion).startOf('day').toISOString().split('T')[0],
            end: moment(endConversion).startOf('day').toISOString().split('T')[0],
            ...(selectedSubscription ? { subscriptionId: selectedSubscription } : {})
        })
            .then(result => {
                setConversion(result)
            })
    }, [startConversion, endConversion, selectedSubscription])


    useEffect(() => {

        COMPANY_REQUESTS.getAllMembers(user.companyId)
            .then(response => {
                const data = {}

                response.filter(el => el.status === COMPANIES_TRAINERS_REQUEST_STATUS.APPROVED).forEach(el => {
                    data[el.id] = el
                })

                setTrainers(data)
            })
    }, [user])

    const getPayments = () => {
        setLoading(true)
        PAYMENT_REQUESTS.getPayments(user.companyId, {
            startDate: moment(start).startOf('day').toISOString().split('T')[0],
            endDate: moment(end).startOf('day').toISOString().split('T')[0]
        })
            .then(result => setPayments(result.rows))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (!start || !end) return
        getPayments()
    }, [user, start, end])

    useEffect(() => {
        COMPANY_REQUESTS.getAllSubscriptions(user.companyId)
            .then(result => setSubscriptions(result))
    }, [user])

    const successPayments = useMemo(() => {
        return payments.filter(el => el.success)
    }, [payments])

    const unsuccessPayments = useMemo(() => {
        return payments.filter(el => !el.success)
    }, [payments])

    const totalUnsuccessPayments = useMemo(() => {
        return unsuccessPayments.reduce((prev, curr) => {
            return prev + curr.Subscription.priceRon
        }, 0)
    }, [unsuccessPayments])

    const totalRevenue = useMemo(() => {
        return successPayments.filter(el => el.success).reduce((prev, curr) => {
            return prev + curr.Subscription.priceRon
        }, 0)
    }, [successPayments])

    const totalRevenueType = useMemo(() => {
        return successPayments.reduce((prev, curr) => {
            if (curr.paymentOrderId && curr.paymentMethod === PAYMENT_METHOD.CARD) {
                prev[PAYMENT_METHOD.ONLINE] += curr.Subscription.priceRon
            } else {
                prev[curr.paymentMethod] += curr.Subscription.priceRon
            }

            return prev
        }, {
            [PAYMENT_METHOD.BANK_TRANSFER]: 0,
            [PAYMENT_METHOD.CARD]: 0,
            [PAYMENT_METHOD.CASH]: 0,
            [PAYMENT_METHOD.ONLINE]: 0
        })
    }, [successPayments])

    const totalRevenueSubscriptions = useMemo(() => {
        const data = {}

        subscriptions.map(el => data[el.name] = 0)

        return successPayments.reduce((prev, curr) => {
            if (!curr.Subscription || !curr.Subscription.name) return prev
            if (prev[curr.Subscription.name]) {
                prev[curr.Subscription.name] += curr.Subscription.priceRon
            } else {
                prev[curr.Subscription.name] = curr.Subscription.priceRon
            }

            return prev
        }, data)
    }, [successPayments, subscriptions])

    const totalPercentageSubscriptions = useMemo(() => {
        const data = {}

        subscriptions.map(el => data[el.name] = 0)

        return Object.keys(totalRevenueSubscriptions).reduce((prev, key) => {
            let item = totalRevenueSubscriptions[key]
            if (!item) return prev

            const percentage = Number(Number(item) * Number(100) / Number(totalRevenue))

            data[key] = percentage

            return prev
        }, data)
    }, [totalRevenueSubscriptions, totalRevenue, subscriptions])

    const totalRevenueTrainers = useMemo(() => {
        const data = {}

        Object.keys(trainers).map(el => data[el] = { value: 0, count: 0 })

        return successPayments.reduce((prev, curr) => {
            if (!curr.generatedTrainerId) return prev
            if (prev[curr.generatedTrainerId]) {
                prev[curr.generatedTrainerId].value += curr.Subscription.priceRon
                prev[curr.generatedTrainerId].count++
            }

            return prev
        }, data)
    }, [successPayments, trainers])

    if (loading) {
        return <Container maxWidth="xl">
            <Grid container pt={4}>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Button variant="outlined" onClick={() => navigate('/reports')}><ArrowLeft /> {t('button.back')}</Button>
                        <Typography variant="h6" fontWeight={'bold'} pl={2}>
                            {t('reports.revenue.ReportsRevenue')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} display="flex" flexDirection="row" aligItems="center" justifyContent="center">
                    <CircularProgress />
                </Grid>
            </Grid>
        </Container>
    }

    return (
        <>
            {showNewClientsModal && conversion ? <ClientsModal
                open={showNewClientsModal}
                start={start}
                newUsers={conversion.newUsers}
                end={end}
                onClose={() => setShowNewClientsModal()}
            /> : null}
            {showLostClientsModal && conversion ? <ClientsModal
                open={showLostClientsModal}
                start={start}
                lostUsers={conversion.lostUsers}
                end={end}
                onClose={() => setShowLostClientsModal()}
            /> : null}
            {showConversionRatesModal && conversion ? <ClientsModal
                open={showConversionRatesModal}
                start={start}
                conversionUsers={conversion.conversionUsers}
                end={end}
                onClose={() => setShowConversionRatesModal()}
            /> : null}
            {showPaymentModal ? <PaymentModal
                open={showPaymentModal}
                paymentMethod={showPaymentModal}
                start={start}
                end={end}
                onClose={() => setShowPaymentModal()}
            /> : null}
            <Container maxWidth="xl">
                <Grid container pt={4} pb={5} spacing={3}>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Button variant="outlined" onClick={() => navigate('/reports')}><ArrowLeft /> {t('button.back')}</Button>
                            <Typography variant="h6" fontWeight={'bold'} pl={2}>
                                {t('reports.revenue.ReportsRevenue')}
                            </Typography>
                        </Box>

                    </Grid>
                    {conversion ? <Grid item xs={12} mt={3}>
                        <Typography variant="p" fontWeight="bold">{t('reports.revenue.conversionRate')}</Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Filters
                                    subscriptions={subscriptions}
                                    endValue={endConversion}
                                    startValue={startConversion}
                                    onEndChange={setEndConversion}
                                    onStartChange={setStartConversion}
                                    subscription={selectedSubscription}
                                    onSubscriptionChange={event => setSelectedSubscription(event.target.value)}
                                    currency={currency}
                                />
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <RevenueTypeCard
                                    icon={<Addchart fontSize='large' sx={{ color: GREEN_COLOR }} />}
                                    noPercentageText
                                    noText
                                    fixedHeight
                                    onClick={() => setShowNewClientsModal(true)}
                                    color={GREEN_COLOR}
                                    type={'New Users'}
                                    noPercent
                                    givenPercentage={conversion ? conversion.newUsers.length : null}
                                    text={'Numarul de utilizatori care au avut un abonament in perioada selectata si nu au mai avut in trecut'}
                                />
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <RevenueTypeCard
                                    icon={<PlaylistRemove fontSize='large' sx={{ color: ERROR_COLOR }} />}
                                    noPercentageText
                                    noText
                                    fixedHeight
                                    onClick={() => setShowLostClientsModal(true)}
                                    color={ERROR_COLOR}
                                    type={'Lost Users'}
                                    noPercent
                                    givenPercentage={conversion ? conversion.lostUsers.length : null}
                                    text={'Numarul de utilizatori care au avut un abonament in trecut si nu mai au in perioada selectata'}
                                />
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <RevenueTypeCard
                                    icon={<GroupAdd fontSize='large' sx={{ color: PRIMARY_COLOR }} />}
                                    noPercentageText
                                    noText
                                    fixedHeight
                                    onClick={() => setShowConversionRatesModal(true)}
                                    color={PRIMARY_COLOR}
                                    type={t('reports.revenue.conversionRate')}
                                    givenPercentage={isNaN(conversion.conversionRate) ? 0 : conversion.conversionRate}
                                    text={t('reports.revenue.conversionRateDescription')}
                                />
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <RevenueTypeCard
                                    icon={<CallMerge fontSize='large' sx={{ color: PRIMARY_COLOR }} />}
                                    noPercentageText
                                    noText
                                    fixedHeight
                                    color={PRIMARY_COLOR}
                                    type={t('reports.revenue.retentionRate')}
                                    givenPercentage={isNaN(conversion.retentionRate) || conversion.retentionRate == null ? 0 : conversion.retentionRate}
                                    text={t('reports.revenue.retentionRateDescription')}
                                />
                            </Grid>
                        </Grid>
                    </Grid> : null}
                    <Grid item xs={12} mt={3}>
                        <Typography variant="p" fontWeight="bold">{t('reports.revenue.cashFlow')}</Typography>
                        <Grid item xs={12}>
                            <Filters
                                endValue={end}
                                startValue={start}
                                onEndChange={setEnd}
                                onStartChange={setStart}
                            />
                        </Grid>
                        <Grid container spacing={1} display="flex" flexDirection="row" pt={1}>
                            <Grid item xs={6} lg={3}>
                                <RevenueTypeCard
                                    onClick={() => setShowPaymentModal(PAYMENT_METHOD.CASH)}
                                    icon={<Money fontSize='large' sx={{ color: GREEN_COLOR }} />}
                                    value={totalRevenueType[PAYMENT_METHOD.CASH]}
                                    total={totalRevenue}
                                    type={t(`subscriptionModal.${PAYMENT_METHOD.CASH}`)}
                                    currency={currency}
                                />
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <RevenueTypeCard
                                    onClick={() => setShowPaymentModal(PAYMENT_METHOD.BANK_TRANSFER)}
                                    icon={<Receipt fontSize='large' sx={{ color: GREEN_COLOR }} />}
                                    value={totalRevenueType[PAYMENT_METHOD.BANK_TRANSFER]}
                                    total={totalRevenue}
                                    type={t(`subscriptionModal.${PAYMENT_METHOD.BANK_TRANSFER}`)}
                                    currency={currency}
                                />
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <RevenueTypeCard
                                    onClick={() => setShowPaymentModal(PAYMENT_METHOD.ONLINE)}
                                    icon={<CreditCard fontSize='large' sx={{ color: GREEN_COLOR }} />}
                                    value={totalRevenueType[PAYMENT_METHOD.ONLINE]}
                                    total={totalRevenue}
                                    type={'Online'}
                                    currency={currency}
                                />
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <RevenueTypeCard
                                    onClick={() => setShowPaymentModal(PAYMENT_METHOD.CARD)}
                                    icon={<Avatar variant="square" src={require('../../../../assets/pos.png')} style={{ width: 30, height: 30 }} />}
                                    value={totalRevenueType[PAYMENT_METHOD.CARD]}
                                    total={totalRevenue}
                                    type={'POS'}
                                    currency={currency}
                                />
                            </Grid>

                            <Grid item xs={6} lg={3}>
                                <RevenueTypeCard
                                    icon={<Summarize fontSize='large' sx={{ color: PRIMARY_COLOR }} />}
                                    value={totalRevenue}
                                    text={t('reports.revenue.totalDoneSales')}
                                    color={PRIMARY_COLOR}
                                    noPercantage={true}
                                    currency={currency}
                                />
                            </Grid>

                            <Grid item xs={6} lg={3}>
                                <RevenueTypeCard
                                    onClick={() => setShowPaymentModal('NOT_PROCESSED')}
                                    icon={<PendingActions fontSize='large' sx={{ color: ERROR_COLOR }} />}
                                    color={ERROR_COLOR}
                                    value={totalUnsuccessPayments}
                                    text={t('reports.revenue.totalUndoneSales')}
                                    noPercantage={true}
                                    currency={currency}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} mt={3}>
                        <Typography variant="p" fontWeight="bold">{t('reports.revenue.percentageSubscriptions')}</Typography>
                        <Grid container spacing={1} pt={1}>
                            <Grid item xs={12}>
                                <VerticalBarChart
                                    style={{ height: 300 }}
                                    horizontal
                                    maintainAspectRatio={false} data={{
                                        labels: Object.keys(totalPercentageSubscriptions),
                                        datasets: [
                                            {
                                                borderColor: '#ffffff',
                                                label: t('helper.membershipsSold'),
                                                data: Object.values(totalPercentageSubscriptions),
                                                backgroundColor: 'rgba(33, 150, 243, 1)',
                                            }
                                        ]
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} mt={3}>
                        <Typography variant="p" fontWeight="bold">{t('reports.revenue.valueSubscriptions', { currency: currency })}</Typography>
                        <Grid container spacing={1} pt={1}>
                            <Grid item xs={12}>
                                <VerticalBarChart
                                    style={{ height: 300 }}
                                    horizontal
                                    maintainAspectRatio={false} data={{
                                        labels: Object.keys(totalRevenueSubscriptions),
                                        datasets: [
                                            {
                                                borderColor: '#ffffff',
                                                label: t('helper.membershipsSold'),
                                                data: Object.values(totalRevenueSubscriptions),
                                                backgroundColor: 'rgba(33, 150, 243, 1)',
                                            }
                                        ]
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} mt={3}>
                        <Typography variant="p" fontWeight="bold">{t('reports.revenue.trainers')}</Typography>
                        <Grid item xs={12} component={Card}>
                            <Table
                                dense
                                headCells={[
                                    t('reports.revenue.name'),
                                    t('reports.revenue.totalSales'),
                                    t('reports.revenue.subscriptionsCountSold'),
                                    t('reports.revenue.percentageOfTotalSales')
                                ]}
                                rows={Object.keys(totalRevenueTrainers).filter(key => trainers[key]).map(key => {
                                    const trainer = trainers[key]
                                    const data = totalRevenueTrainers[key]
                                    const percentage = Number(Number(data.value) * Number(100) / Number(totalRevenue))

                                    return {
                                        name: `${trainer.firstName} ${trainer.lastName}`,
                                        sales: numberFomatter(data.value) + `${currency}`,
                                        salesNumber: data.count,
                                        percantage: numberFomatter(percentage) + '%'
                                    }
                                })}
                            />
                        </Grid>
                    </Grid>

                </Grid>
            </Container>
        </>
    )
}