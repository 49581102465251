import React, { useState } from 'react'
import { Dialog, Grid, Typography, IconButton, Button } from '@mui/material'
import { Close, Help } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { LIGHT_GRAY_BORDER_COLOR, VOUCHER_TYPES } from '../../../utils/constants'

export default ({ onClose, onAcceptWithRecurring, onAcceptWithoutRecurring, vouchers, company }) => {
    const { t } = useTranslation()

    const [disabled, setDisabled] = useState(false)
    const [voucher, setVoucher] = useState(0)

    React.useEffect(() => {
        if (!vouchers || !vouchers.length) return

        const recurringVoucher = vouchers.find(el => el.type === VOUCHER_TYPES.RECURRING)

        if (!recurringVoucher || !recurringVoucher.discount) return

        setVoucher(recurringVoucher)
    }, [vouchers])

    return (
        <Dialog open onClose={onClose} width={300} fullWidth>
            <Grid container p={4} pb={0} pr={0} pl={0}>
                <Grid item xs={12} pr={4} pl={4}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item
                            xs={10}
                            md={10}
                            lg={10}>
                        </Grid>
                        <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                            <IconButton onClick={onClose}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                        <Help color="primary" style={{ height: 60, width: 60 }} />
                    </Grid>
                    <Grid item xs={12} pt={1} display="flex" justifyContent="center">
                        <Typography variant="h6" fontWeight='bold' >{t('recurringPaymentModal.automaticRecurringDiscount')} </Typography>
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent='center'>
                        <Typography variant="p">{t('recurringPaymentModal.description', {
                            gymName: company ? company.name : '',
                            discount: voucher.hasFixedDiscount ? `${voucher.discount} ${company.currency}` : `${voucher.discount} %`
                        })} </Typography>
                    </Grid>
                    <Grid item xs={12} pt={3} pb={3} display="flex" justifyContent="center">
                        <Typography variant="p" fontWeight="bold">{t('recurringPaymentModal.question')} </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} borderTop={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
                    <Grid container spacing={2} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                        <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
                            <Button sx={{ height: 60 }} variant="text" color='error' disabled={disabled} fullWidth onClick={() => {
                                setDisabled(true)
                                onAcceptWithoutRecurring()
                            }} >{t('recurringPaymentModal.no')}</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button sx={{ height: 60 }} variant="text" color="primary" disabled={disabled} onClick={() => {
                                setDisabled(true)
                                onAcceptWithRecurring({ ...(voucher.id ? { recurringVoucherId: voucher.id } : {}) })

                            }} fullWidth>{t('recurringPaymentModal.yes')}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog >
    )

}