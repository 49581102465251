import axios from './axios'

export const findAll = async ({ from, limit, filterOptions }) => {
    const res = await axios.get(`/payouts?from=${from}&limit=${limit}&filterOptions=${filterOptions}`)
    const data = await res.data
    return data
}

export const findById = async (id) => {
    const res = await axios.get('/payouts/' + id)
    const data = await res.data
    return data
}

// Payouts 2.0

export const getBussinessMonths = async () => {
    const res = await axios.get(`/payouts/company/months-totals`);
    const data = await res.data;

    return data;
};

export const getBussinessPayouts = async (month, userNameFilter, subscriptionId) => {
    const res = await axios.get(`/payouts/company?month=${month}
        &userName=${userNameFilter !== 'unset' ? userNameFilter : ''}
        ${subscriptionId && subscriptionId !== 'unset' ? `&subscriptionId=${subscriptionId}` : ''}
    `);
    
    const data = await res.data;

    return data;
};

export const downloadFeesInvoiceFromPayouts = async (toSend) => {
    const res = await axios.post(`/payouts/downloadFeesInvoice`, {...toSend});
    const data = await res.data;

    return data;
};

export const downloadPaymentsInvoiceFromPayouts = async (toSend) => {
    const res = await axios.post(`/payouts/downloadPaymentsInvoice`, {...toSend});
    const data = await res.data;

    return data;
};

export const downloadPaymentInvoiceFromPayouts = async (id) => {
    const res = await axios.post(`/payouts/downloadPaymentInvoice`, {paymentId: id});
    const data = await res.data;

    return data;
};

export const exportCSVFromPayouts = async (month) => {
    const res = await axios.post(`/payouts/exportCSV`, {month: month});
    const data = await res.data;

    return data;
};
// Routes for downloading invoices; not needed right now because clubs will download invoices only from Stripe Billing Portal
// export const extraMonthlyFee = async (companyId, month) => {
//     const res = await axios.get(`/payouts/invoice/extra?month=${month}${companyId ? `&companyId=${companyId}` : ''}`);

//     return res.data
// }

// export const cardMonthly = async (companyId, month) => {
//     const res = await axios.get(`/payouts/invoice/card?month=${month}${companyId ? `&companyId=${companyId}` : ''}`);

//     return res.data;
// }