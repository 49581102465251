import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Service from './subcomponents/Service'

import * as INTERESTED_REQUESTS from '../../../../api/interested'
import { useAuthentication } from '../../../../context/auth-context'

const Services = ({ services, handleGetServices }) => {
  const { t } = useTranslation()
  const { user } = useAuthentication()

  const [interestedLoading, setInterestedLoading] = useState(false)

  const handleInterested = (serviceId, interestedByLoggedUser) => {
    if (interestedByLoggedUser) {
      INTERESTED_REQUESTS.removeInterestedInService(serviceId).then(() => {
        handleGetServices()
        setInterestedLoading(false)
      })
    } else {
      setInterestedLoading(true)
      INTERESTED_REQUESTS.interestedInService(serviceId).then(() => {
        handleGetServices()
        setInterestedLoading(false)
      })
    }
  }

  return (
    <Grid container display="flex" p={3} pt={5}>
      <Grid container>
        {services.length > 0 ? (
          services.map((service, index) => {
            return (
              <Grid key={index} xs={12} item pt={2} pb={2}>
                <Service
                  interestedLoading={interestedLoading}
                  id={service.id}
                  handleInterested={handleInterested}
                  Trainers={service.Trainers}
                  title={service.name}
                  description={service.description}
                  price={service.priceRon}
                  isAvailable={service.isAvailable}
                  interestedByLoggedUser={
                    user
                      ? service.UserInterests.findIndex((el) => el.userId === user.id) >= 0
                      : false
                  }
                  {...service}
                />
              </Grid>
            )
          })
        ) : (
          <Typography pl={1} color="#979797" fontWeight={500} fontSize={17}>
            {t('helper.noResultsFound')}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default Services
