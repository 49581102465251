import React from 'react'
import { Box, Card, Typography, Avatar } from '@mui/material'

export default ({ title, icon, backgroundColor, textColor, subtitle }) => {
    return (
        <Box component={Card} sx={{ padding: 3, ...(backgroundColor ? { backgroundColor } : {}) }} >
            {icon}
            <Typography fontWeight='bold' variant="h6" sx={{ ...(textColor ? { color: textColor } : {}) }}>{title}</Typography>
            <Typography fontWeight={500} sx={{ ...(textColor ? { color: textColor } : {}) }}>{subtitle}</Typography>
        </Box>
    )
}