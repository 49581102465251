import React, { useState, useEffect } from 'react'
import { Grid, Card } from '@mui/material'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

import { Tabs } from '../../common'
import Details from './subcomponents/Details'
import Reviews from './subcomponents/Reviews'
import Services from './subcomponents/Services'
import Subscriptions from './subcomponents/Subscriptions'

import { LIGHT_GRAY_BORDER_COLOR } from '../../../utils/constants'

import * as COMPANY_REQUESTS from '../../../api/company'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
    flexGrow: 1
  }
})

const BusinessActivity = ({ company, goToReview, resetGoToReview }) => {
  const classes = useStyles()
  const { domain } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [activeTab, setActiveTab] = useState(undefined)
  const [services, setServices] = useState([])
  const [subscriptions, setSubscriptions] = useState([])

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    })
    const activeOption = params['active_option']
    if (activeOption) setActiveTab(Number(activeOption))
    else setActiveTab(0)
  }, [])

  useEffect(() => {
    if (!goToReview) return
    setActiveTab(3)
    resetGoToReview()
  }, [goToReview])

  const handleGetServices = () => {
    COMPANY_REQUESTS.getAllServicesByDomain(domain).then(setServices)
  }

  const handleGetSubscriptions = () => {
    COMPANY_REQUESTS.getAllSubscriptionsByDomain(domain).then(subscriptions => {
      setSubscriptions(subscriptions.filter(el => el.visibility !== 'PRIVATE'))
    })
  }

  useEffect(() => {
    if (activeTab === 1) {
      handleGetServices()
    }
    if (activeTab === 2) {
      handleGetSubscriptions()
    }
  }, [activeTab])

  if (activeTab === undefined) return null

  return (
    <Grid item sm={12} xl={12}>
      <Grid container justifyContent="flex-end">
        <Grid className={classes.content} component={Card} item sm={12} xl={12} minHeight={'100%'}>
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => {
              const to = { pathname: location.pathname, search: `active_option=${newValue}` }
              navigate(to, { replace: true })
              setActiveTab(newValue)
            }}
            options={[
              {
                label: t('userProfile.activity.details'),
                value: <Details company={company} />
              },
              {
                label: t('userProfile.activity.services'),
                value: <Services handleGetServices={handleGetServices} services={services} />
              },
              {
                label: t('userProfile.activity.yourSubscriptions'),
                value: <Subscriptions subscriptions={subscriptions} currency={company.currency} />
              },
              {
                label: t('userProfile.activity.reviews'),
                value: (
                  <Reviews reviewsAverage={company?.reviewsAverage > 5 ? 5 : company?.reviewsAverage < 1 ? 1 : company?.reviewsAverage} companyName={company.name} companyPhotoSrc={company.avatarPhotoSrc} />
                )
              }
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BusinessActivity
