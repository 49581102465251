import axios from './axios'
import queryString from 'query-string'

export const getTrainerReports = (filters = {}) => {
  return axios.get(`/reports/trainers?${queryString.stringify(filters)}`).then((res) => res.data)
}

export const getUsers = (filters = {}) => {
  return axios.get(`/reports/users?${queryString.stringify(filters)}`).then((res) => res.data)
}

export const getConversions = (filters = {}) => {
  return axios.get(`/reports/conversion-rates?${queryString.stringify(filters)}`).then((res) => res.data)
}