import { Box, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import * as SUPER_ADMIN_REQUESTS from '../../api/superAdmin';
import * as COMPANY_REQUESTS from '../../api/company';
import { toast } from 'react-toastify';
import moment from "moment";
import SuperAdminTransactionsFilter from "./SuperAdminTransactionsFilters";

const rowDesign = {
    paddingY: 3
};

const SuperAdminTransactions = () => {
    const [clubTransactions, setClubTransactions] = useState([]);
    const [feesAmount, setFeesAmount] = useState({});
    const [filterOptions, setFilterOptions] = useState({
        date: {
            startDate: moment().startOf('month'),
            endDate: moment().endOf('month')
        },
        paymentMethod: ''
    });

    const getClubTransactions = useCallback(async () => {
        try {
            const clubsResp = await SUPER_ADMIN_REQUESTS.getClubTransactions(filterOptions);
            const newFeesAmount = {};
            clubsResp.forEach((item) => {
                newFeesAmount[item.companyId] = item.feesAmount || 0;
            });
            setFeesAmount(newFeesAmount);
            setClubTransactions(clubsResp);
        } catch (e) {
            console.error(e);
        }
    }, [filterOptions]);

    useEffect(() => {
        getClubTransactions();
    }, [getClubTransactions, filterOptions]);

    const handleCheckboxChange = (index) => {
        setClubTransactions(prevTransactions => {
            const newTransactions = [...prevTransactions];
            const currentTransaction = newTransactions[index];
            currentTransaction.generateInvoice = !currentTransaction.generateInvoice;
            if (!currentTransaction.generateInvoice) {
                setFeesAmount(prevFees => ({
                    ...prevFees,
                    [currentTransaction.companyId]: 0
                }));
            }
            return newTransactions;
        });
    };

    const handleFeesAmountChange = (companyId, value) => {
        setFeesAmount(prevFees => ({
            ...prevFees,
            [companyId]: value
        }));
    };

    const handleSave = async () => {
        const changedCompanies = clubTransactions.map(item => ({
            companyId: item.companyId,
            feesAmount: feesAmount[item.companyId],
            generateInvoice: item.generateInvoice
        }));

        for (const item of changedCompanies) {
            try {
                await COMPANY_REQUESTS.updateBusiness(item.companyId, {
                    paymentsInvoice: item.generateInvoice,
                    feesAmount: item.feesAmount
                });
                toast.success('Successfully updated companies', { position: 'bottom-right' });
            } catch (error) {
                toast.error(`Error: ${error.message}`, { position: 'bottom-right' });
            }
        }
    };

    // For simulation of the conjob - only for testing
    // const callInvoicesCronJob = async () => {
    //     try {
    //         await SUPER_ADMIN_REQUESTS.invoicesCronJob();

    //         toast.success('Cronjob run successfully!', { position: 'bottom-right' });
    //     } catch (error) {
    //         toast.error(`Error on calling invoices cronjob: ${error.message}`, { position: 'bottom-right'});
    //     }

    // }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <SuperAdminTransactionsFilter filterOptions={filterOptions} setFilterOptions={setFilterOptions} />
            <Box>
                <TableContainer component={Paper}>
                    <Table dense sx={{ minWidth: '100%' }} aria-label="club transactions table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>Business Name</TableCell>
                                <TableCell>Total Transactions</TableCell>
                                <TableCell>POS</TableCell>
                                <TableCell>Card</TableCell>
                                <TableCell>Cash</TableCell>
                                <TableCell>Bank</TableCell>
                                <TableCell>Generate Invoice</TableCell>
                                <TableCell>Fees Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clubTransactions.map((item, index) => (
                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell sx={rowDesign}>{item.email}</TableCell>
                                    <TableCell sx={rowDesign}>{item.bussinessName}</TableCell>
                                    <TableCell sx={rowDesign}>{item.totalTransactions}</TableCell>
                                    <TableCell sx={rowDesign}>{item.cardTransactions}</TableCell>
                                    <TableCell sx={rowDesign}>{item.onlineTransactions}</TableCell>
                                    <TableCell sx={rowDesign}>{item.cashTransactions}</TableCell>
                                    <TableCell sx={rowDesign}>{item.bankTransactions}</TableCell>
                                    <TableCell sx={rowDesign}>
                                        <Checkbox
                                            checked={item.generateInvoice}
                                            onChange={() => handleCheckboxChange(index)}
                                        />
                                    </TableCell>
                                    <TableCell sx={rowDesign}>
                                        <TextField
                                            InputProps={{
                                                inputProps: { min: 0 }
                                            }}
                                            type="number"
                                            disabled={!item.generateInvoice}
                                            value={feesAmount[item.companyId]}
                                            onChange={(e) => handleFeesAmountChange(item.companyId, e.target.value)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', py: 2, gap: 5 }}>
                        <Button variant='contained' onClick={handleSave}>Save Changes</Button>
                        {/* <Button variant='contained' onClick={callInvoicesCronJob}>Cronjob to Generate Invoices</Button> */}
                    </Box>
                </TableContainer>
            </Box>
        </Box>
    );
}

export default SuperAdminTransactions;
