import React, { useMemo, useState } from 'react'
import { Grid, Card, Typography, Button, Box, Rating, Avatar, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { CheckCircle, Instagram, Facebook, Coffee } from '@mui/icons-material'
import parse from 'html-react-parser'
import { toast } from 'react-toastify';

import MessageTrainerDialog from './subcomponents/subcomponents/MessageDialog'
import { FollowModal } from '../../common'

import { refetchPublicTrainerProfileObservable } from '../../../utils/observables'
import {
  LIGHT_GRAY_BORDER_COLOR,
  CARD_TEXT_COLOR,
  GRAY_TEXT_COLOR,
  GRAY_BG_COLOR,
  PRIMARY_COLOR,
  BLACK_BACKGROUND_COLOR,
  ROLE_BUSINESS_ADMINISTRATOR,
  BUSINESS_ROLES,
  ROLE_TRAINER
} from '../../../utils/constants'

import * as FOLLOWER_REQUESTS from '../../../api/follower'
import * as MESSAGE_REQUESTS from '../../../api/message'
import { useAuthentication } from '../../../context/auth-context'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  avatarContainer: {},
  credibilityContainer: {
    borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  borderRight: {
    borderRight: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  countReviewersFollowers: {
    fontWeight: 800,
    fontSize: 24
  },
  performanceText: {
    color: GRAY_TEXT_COLOR
  },
  memberFrom: {
    color: CARD_TEXT_COLOR
  }
})

const TrainerDetails = ({
  isFollowing,
  handleGoToReview,
  user: {
    domain,
    lastName,
    firstName,
    reviewsAverage,
    bio,
    avatarPhotoSrc,
    Companies,
    TrainerReviews,
    followersCount,
    performance,
    instagramProfileUrl,
    facebookProfileUrl,
    id,
    isApproved,
    paymentLink
  }
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useAuthentication()
  const navigate = useNavigate()

  const [fullBio, setFullBio] = useState(false)
  const [openMessageDialog, setOpenMessageDialog] = useState(false)
  const [showFollowModal, setShowFollowModal] = useState(false)

  const onFollowHandler = () => {
    if (isFollowing) {
      return FOLLOWER_REQUESTS.unfollow({ followerUserId: id }).then(() => {
        refetchPublicTrainerProfileObservable.setRefetchPublicTrainerProfile({})
      })
    }

    return setShowFollowModal(true)
  }

  const handleSendMessage = async (title, message) => {
    try {
      await MESSAGE_REQUESTS.sendMessageToUser(id, { title, message })
      setOpenMessageDialog(false)
    } catch (e) {
      console.error(e)
    }
  }

  const renderTrainerReviews = useMemo(() => {
    let score = Math.ceil(reviewsAverage);

    return (
      <>
        <Rating value={score} disabled sx={{ color: PRIMARY_COLOR }} />
        <Typography pl={1} fontWeight="bold">
          {score} / 5
        </Typography>
      </>
    )
  }, [TrainerReviews])

  return (
    <>
      {showFollowModal ? (
        <FollowModal
          onCancel={() => setShowFollowModal(false)}
          onConfirm={() => {
            FOLLOWER_REQUESTS.follow({ followerUserId: id })
              .then(() => {
                refetchPublicTrainerProfileObservable.setRefetchPublicTrainerProfile({})
                setShowFollowModal(false)
              })
              .catch(() => {
                toast.error(
                  `${t('helper.alreadyFollow')} ${firstName} ${lastName}`
                ), { position: 'bottom-right', autoClose: false }
              })
          }}
        />
      ) : null}
      {openMessageDialog ? (
        <MessageTrainerDialog
          handleSend={handleSendMessage}
          handleClose={() => setOpenMessageDialog(false)}
          trainerName={`${firstName} ${lastName}`}
        />
      ) : null}
      <Grid container className={classes.content} component={Card} flexDirection="column">
        <Grid container pt={3} width="100%" className={classes.avatarContainer}>
          <Grid
            xs={12}
            pl={2}
            pr={2}
            item
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {avatarPhotoSrc ? (
              <Avatar src={avatarPhotoSrc} alt="avatar" style={{ width: 100, height: 100 }} />
            ) : (
              <Box
                sx={{
                  width: 100,
                  height: 100,
                  backgroundColor: GRAY_BG_COLOR,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 50
                }}
              >
                <Typography color={GRAY_TEXT_COLOR} variant="h4" fontWeight="bold">
                  {firstName.charAt(0)} {lastName.charAt(0)}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            pt={3}
            flexDirection="row"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h5" pr={1} fontWeight="bold">
              {firstName} {lastName}
            </Typography>
            {isApproved ? <CheckCircle color="primary" /> : null}
          </Grid>
          {Companies && Companies[0] && Companies[0].name && (
            <Grid
              item
              xs={12}
              pb={3}
              flexDirection="row"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h6" pr={1} fontWeight={500} color={CARD_TEXT_COLOR}>
                {t('userProfile.activity.trainerDetailComponent.trainerAt')}
              </Typography>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            pl={2}
            pr={2}
            flexDirection="row"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {renderTrainerReviews}
          </Grid>

          <Grid
            item
            xs={12}
            pt={1}
            pb={3}
            flexDirection="row"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              onClick={() => handleGoToReview()}
              variant="subtitle2"
              color="primary"
              fontWeight={600}
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              {t('userProfile.activity.trainerDetailComponent.seeReviews')}
            </Typography>
          </Grid>

          <Grid
            className={classes.credibilityContainer}
            container
            borderTop={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
          >
            <Grid
              item
              xs={6}
              pl={3}
              pr={2}
              pb={2}
              pt={2}
              className={classes.borderRight}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography variant="p" className={classes.performanceText}>
                {t('userProfile.performance')}
              </Typography>
              <Grid pt={0.5} item display="flex" flexDirection="row">
                {performance ? (
                  <>
                    <Typography color="primary" fontWeight={500} variant="h4">
                      {Number(performance).toFixed(0)}
                    </Typography>
                    <Typography fontWeight={500} variant="p">
                      /5
                    </Typography>
                  </>
                ) : (
                  <Typography color="primary" fontWeight={500} variant="h4">
                    {'N/A'}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid
              pl={3}
              pr={4}
              pb={2}
              pt={2}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-end"
              item
              xs={6}
            >
              <Typography variant="p" className={classes.performanceText}>
                {t('userProfile.followers').toUpperCase()}
              </Typography>
              <Grid pt={0.5} item display="flex" flexDirection="row">
                <Typography color="primary" variant="h4" fontWeight={500}>
                  {followersCount && followersCount > 0 ? followersCount : 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            pb={3}
            pl={2}
            pr={2}
            pt={3}
            flexDirection="row"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography fontWeight={500} variant="subtitle2" color={`${bio ? 'black' : '#979797'}`} className="bio">
              {bio ? (fullBio ? parse(bio) : parse(bio.slice(0, 150))) : t('helper.noInformation')}
              <Typography
                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                fontWeight={'bold'}
                color="primary"
                onClick={() => setFullBio((prevValue) => !prevValue)}
                variant="span"
              >
                {bio ? (fullBio ? t('userProfile.showLess') : t('userProfile.readMore')) : ''}
              </Typography>
            </Typography>
          </Grid>

          <Grid item xs={12} pb={3} pl={2} pr={2}>
            <Grid
              container
              flexDirection="column"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item width="100%">
                <Grid container display="flex" flexDirection="row">
                  <Grid item lg={6} xs={12} pr={{ xs: 0, lg: 1 }}>
                    <Button
                      disabled={
                        !user ||
                        user.role === ROLE_BUSINESS_ADMINISTRATOR ||
                        user.role === BUSINESS_ROLES.BUSINESS_EMPLOYEE ||
                        user.role === ROLE_TRAINER
                      }
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={onFollowHandler}
                    >
                      {isFollowing && user ? t('button.unfollow') : t('userProfile.follow')}
                    </Button>
                  </Grid>
                  <Grid item lg={6} xs={12} pl={{ xs: 0, lg: 1 }} mt={{ xs: 2, lg: 0 }}>
                    <Button
                      disabled={!user}
                      onClick={() => setOpenMessageDialog(true)}
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      {t('userProfile.activity.trainerDetailComponent.message')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid pt={2} item width="100%">
                <Button onClick={() => navigate(`/calendar/${domain}`)} fullWidth variant="contained" color="success">
                  {t('userProfile.calendar')}
                </Button>
              </Grid>
              <Grid item xs={12} width="100%" pt={2}>
                <Button onClick={() => navigate(`/calendar-services/trainer/${domain}`)} fullWidth variant="contained" color="primary">
                  BOOK
                </Button>
              </Grid>
              <Grid pt={2} item width="100%">
                <Button
                  disabled={!paymentLink || !user}
                  onClick={(e) => {
                    e.stopPropagation()
                    const winURL = window.open(`${paymentLink}`, '_blank')
                    winURL.focus()
                  }}
                  fullWidth
                  variant="contained"
                  style={{ backgroundColor: BLACK_BACKGROUND_COLOR }}
                >
                  <Coffee sx={{ marginRight: 2 }} />{' '}
                  {t('userProfile.activity.trainerDetailComponent.coffee')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {(instagramProfileUrl || facebookProfileUrl) && (
          <Grid
            container
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            pt={2}
            pb={2}
          >
            <Grid item xs={12}>
              <Typography fontWeight={500} variant="subtitle2">
                {t('userProfile.followMe')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container display="flex" flexDirection="row">
                {instagramProfileUrl && (
                  <Grid item pr={1}>
                    <a href={instagramProfileUrl} rel="noreferrer" target="_blank">
                      <Instagram color="primary" />
                    </a>
                  </Grid>
                )}
                {facebookProfileUrl && (
                  <Grid item pr={1}>
                    <a href={facebookProfileUrl} rel="noreferrer" target="_blank">
                      <Facebook color="primary" />
                    </a>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default TrainerDetails
