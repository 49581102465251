import React, { useEffect, useMemo, useState } from 'react'
import {
  Grid,
  Typography,
  Card,
  CardContent
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Event } from '@mui/icons-material'

import { useAuthentication } from '../../../../context/auth-context'

import { PRIMARY_COLOR, ROLE_BUSINESS_ADMINISTRATOR, BUSINESS_ROLES, ROLE_TRAINER, ROLE_USER } from '../../../../utils/constants'
import * as DASHBOARD_REQUESTS from '../../../../api/dashboard'
import { getStatus } from '../../../../utils/helpers/userSubscription'

const Overview = ({ isTrainer, isUser, isClub }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { user } = useAuthentication()

  const [overview, setOverview] = useState({})
  const [loading, setLoading] = useState(true)

  const getCompanyOverview = () => {
    setLoading(true)
    return DASHBOARD_REQUESTS.getCompanyOverview()
      .then((response) => {
        setOverview(response)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getUserOverview = () => {
    setLoading(true)
    return DASHBOARD_REQUESTS.getUserOverview()
      .then((response) => {
        setOverview(response)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getTrainerOverview = () => {
    setLoading(true)
    return DASHBOARD_REQUESTS.getTrainerOverview()
      .then((response) => {
        setOverview(response)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!user) return

    if (user.role === ROLE_BUSINESS_ADMINISTRATOR || user.role === BUSINESS_ROLES.BUSINESS_EMPLOYEE) {
      getCompanyOverview()
    } else if (user.role === ROLE_TRAINER) {
      getTrainerOverview()
    } else if (user.role === ROLE_USER) {
      getUserOverview()
    }
  }, [user])

  const renderOverviewAthlete = useMemo(() => {
    if (!isUser || loading) return null
    const activeSubscriptions = overview.allUserSubscriptions ? overview.allUserSubscriptions.filter(el => getStatus(el).active) : []

    return (
      <Grid
        container
        display="flex"
        flexDirection="row"
        maxWidth={{ xs: '90vw', lg: '100%' }}
        overflow={'auto'}
        whiteSpace={'nowrap'}
        flexWrap={'nowrap'}
      >
        <Grid flex={1} minWidth={{ xs: 250 }} pr={1.5} pb={2}>
          <Card
            sx={{
              boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
              border: '1px solid #F0F0F0',
              borderRadius: 2
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="p" color={'#9CB2CD'} fontWeight={500}>
                {t('dashboard.overview.activeSubscriptions')}
              </Typography>
              <Typography variant="span" fontSize={12} color={'#9CB2CD'}>
                {t('dashboard.overview.today')}
              </Typography>
              <Typography paddingTop={0.5} variant="p" fontSize={30} fontWeight={'bold'}>
                {activeSubscriptions.length || 0}
              </Typography>
              <Typography
                paddingTop={2}
                variant="p"
                fontSize={14}
                fontWeight={600}
                color={PRIMARY_COLOR}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => navigate('/user-profile?active_option=1')}
              >
                {t('dashboard.overview.goToSubscriptions')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid flex={1} minWidth={{ xs: 250 }} pr={1.5} pb={2}>

          <Card
            sx={{
              boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
              border: '1px solid #F0F0F0',
              borderRadius: 2
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="p" color={'#9CB2CD'} fontWeight={500}>
                {t('dashboard.overview.events')}
              </Typography>
              <Typography variant="span" fontSize={12} color={'#9CB2CD'}>
                {t('dashboard.overview.bookEvent')}
              </Typography>
              <Typography paddingTop={0.5} variant="p" fontSize={30} fontWeight={'bold'}>
                {overview.todayAndFutureEvents || 0}
              </Typography>
              <Typography
                paddingTop={2}
                variant="p"
                fontSize={14}
                fontWeight={600}
                color={PRIMARY_COLOR}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => navigate('/calendar')}
              >
                {t('dashboard.overview.goToCalendar')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid flex={1} minWidth={{ xs: 250 }} pl={1.5} pr={1.5} pb={2}>
          <Card
            sx={{
              boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
              border: '1px solid #F0F0F0',
              borderRadius: 2
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="p" color={'#9CB2CD'} fontWeight={500}>
                {t('dashboard.overview.totalSubscriptions')}
              </Typography>
              <Typography variant="span" fontSize={12} color={'#9CB2CD'}>
                {t('dashboard.overview.all')}
              </Typography>
              <Typography paddingTop={0.5} variant="p" fontSize={30} fontWeight={'bold'}>
                {overview.allUserSubscriptions ? overview.allUserSubscriptions.length : 0}
              </Typography>
              <Typography
                paddingTop={2}
                variant="p"
                fontSize={14}
                fontWeight={600}
                color={PRIMARY_COLOR}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => navigate('/user-profile?active_option=1')}
              >
                {t('dashboard.overview.goToSubscriptions')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid flex={1} minWidth={{ xs: 250 }} pl={1.5} pb={2}>
          <Card
            sx={{
              boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
              border: '1px solid #F0F0F0',
              borderRadius: 2
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="p" color={'#9CB2CD'} fontWeight={500}>
                {t('dashboard.overview.checkins')}
              </Typography>
              <Typography variant="span" fontSize={12} color={'#9CB2CD'}>
                {t('dashboard.overview.all')}
              </Typography>
              <Typography paddingTop={0.5} variant="p" fontSize={30} fontWeight={'bold'}>
                {overview.checkinsTotal || 0}
              </Typography>
              <Typography
                paddingTop={2}
                variant="p"
                fontSize={14}
                fontWeight={600}
                color={PRIMARY_COLOR}
              >
                {t('dashboard.overview.gymPresence')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }, [isUser, overview, loading])

  const renderOverviewTrainer = useMemo(() => {
    if (!isTrainer || loading) return null

    const sales = Intl.NumberFormat('ro-RO', {
      style: 'currency',
      currency: 'RON',
      currencyDisplay: 'name'
    })
      .format(overview.totalSales)
      .replace('lei', '')
      .replace('românești', '')

    return (
      <Grid
        container
        display="flex"
        flexDirection="row"
        maxWidth={{ xs: '90vw', lg: '100%' }}
        overflow={'auto'}
        whiteSpace={'nowrap'}
        flexWrap={'nowrap'}
      >
        <Grid flex={1} minWidth={{ xs: 250 }} pr={1.5} pb={2}>
          <Card
            sx={{
              boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
              border: '1px solid #F0F0F0',
              borderRadius: 2
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="p" color={'#9CB2CD'} fontWeight={500}>
                {t('dashboard.overview.subscriptionsSold')}
              </Typography>
              <Typography variant="span" fontSize={12} color={'#9CB2CD'}>
                {t('dashboard.overview.today')}
              </Typography>
              <Typography paddingTop={0.5} variant="p" fontSize={30} fontWeight={'bold'}>
                {overview.soldMemberships || 0}
              </Typography>
              <Typography
                paddingTop={2}
                variant="p"
                fontSize={14}
                fontWeight={600}
                color={PRIMARY_COLOR}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => navigate('/trainer-profile?active_option=2')}
              >
                {t('dashboard.overview.goToSubscriptions')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid flex={1} minWidth={{ xs: 250 }} pl={1.5} pr={1.5} pb={2}>
          <Card
            sx={{
              boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
              border: '1px solid #F0F0F0',
              borderRadius: 2
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="p" color={'#9CB2CD'} fontWeight={500}>
                {t('dashboard.overview.revenue')}
              </Typography>
              <Typography variant="span" fontSize={12} color={'#9CB2CD'}>
                {t('dashboard.overview.today')}
              </Typography>
              <Typography paddingTop={0.5} variant="p" fontSize={30} fontWeight={'bold'}>
                {sales || 0} {overview.currency}
              </Typography>
              <Typography
                paddingTop={2}
                variant="p"
                fontSize={14}
                fontWeight={600}
                color={PRIMARY_COLOR}
              >
                {t('dashboard.overview.reports')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid flex={1} minWidth={{ xs: 250 }} pl={1.5} pr={1.5} pb={2}>
          <Card
            sx={{
              boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
              border: '1px solid #F0F0F0',
              borderRadius: 2
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="p" color={'#9CB2CD'} fontWeight={500}>
                {t('dashboard.overview.newClients')}
              </Typography>
              <Typography variant="span" fontSize={12} color={'#9CB2CD'}>
                {t('dashboard.overview.today')}
              </Typography>
              <Typography paddingTop={0.5} variant="p" fontSize={30} fontWeight={'bold'}>
                {overview.totalAthletes || 0}
              </Typography>
              <Typography
                paddingTop={2}
                variant="p"
                fontSize={14}
                fontWeight={600}
                color={PRIMARY_COLOR}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => navigate('/clients')}
              >
                {t('dashboard.overview.goToClients')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid flex={1} minWidth={{ xs: 250 }} pl={1.5} pr={1.5} pb={2}>
          <Card
            sx={{
              boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
              border: '1px solid #F0F0F0',
              borderRadius: 2
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="p" color={'#9CB2CD'} fontWeight={500}>
                {t('dashboard.overview.activeSubscriptions')}
              </Typography>
              <Typography variant="span" fontSize={12} color={'#9CB2CD'}>
                {t('dashboard.overview.today')}
              </Typography>
              <Typography paddingTop={0.5} variant="p" fontSize={30} fontWeight={'bold'}>
                {overview.activeSubscriptions || 0}
              </Typography>
              <Typography
                paddingTop={2}
                variant="p"
                fontSize={14}
                fontWeight={600}
                color={PRIMARY_COLOR}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => navigate('/clients')}
              >
                {t('dashboard.overview.goToClients')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid flex={1} minWidth={{ xs: 250 }} pl={1.5} pb={2}>
          <Card
            sx={{
              boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
              border: '1px solid #F0F0F0',
              borderRadius: 2
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="p" color={'#9CB2CD'} fontWeight={500}>
                {t('dashboard.overview.participants')}
              </Typography>
              <Typography variant="span" fontSize={12} color={'#9CB2CD'}>
                {t('dashboard.overview.today')}
              </Typography>
              <Typography paddingTop={0.5} variant="p" fontSize={30} fontWeight={'bold'}>
                {overview.participants || 0}
              </Typography>
              <Typography
                paddingTop={2}
                variant="p"
                fontSize={14}
                fontWeight={600}
                color={PRIMARY_COLOR}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => navigate('/calendar')}
              >
                {t('dashboard.overview.goToCalendar')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }, [isTrainer, overview, loading])

  const renderOverviewClub = useMemo(() => {
    if (!isClub || loading) return null

    const sales = Intl.NumberFormat('ro-RO', {
      style: 'currency',
      currency: 'RON',
      currencyDisplay: 'name'
    })
      .format(overview.totalSales)
      .replace('lei', '')
      .replace('românești', '')

    return (
      <Grid
        container
        display="flex"
        flexDirection="row"
        maxWidth={{ xs: '90vw', lg: '100%' }}
        overflow={'auto'}
        whiteSpace={'nowrap'}
        flexWrap={'nowrap'}
      >
        <Grid flex={1} minWidth={{ xs: 250 }} pr={1.5} pb={2}>
          <Card
            sx={{
              boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
              border: '1px solid #F0F0F0',
              borderRadius: 2
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="p" color={'#9CB2CD'} fontWeight={500}>
                {t('dashboard.overview.subscriptionsSold')}
              </Typography>
              <Typography variant="span" fontSize={12} color={'#9CB2CD'}>
                {t('dashboard.overview.today')}
              </Typography>
              <Typography paddingTop={0.5} variant="p" fontSize={30} fontWeight={'bold'}>
                {overview.soldMemberships || 0}
              </Typography>
              <Typography
                paddingTop={2}
                variant="p"
                fontSize={14}
                fontWeight={600}
                color={PRIMARY_COLOR}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => navigate('/business-profile?active_option=2')}
              >
                {t('dashboard.overview.goToSubscriptions')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid flex={1} minWidth={{ xs: 250 }} pl={1.5} pr={1.5} pb={2}>
          <Card
            sx={{
              boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
              border: '1px solid #F0F0F0',
              borderRadius: 2
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="p" color={'#9CB2CD'} fontWeight={500}>
                {t('dashboard.overview.revenue')}
              </Typography>
              <Typography variant="span" fontSize={12} color={'#9CB2CD'}>
                {t('dashboard.overview.today')}
              </Typography>
              <Typography paddingTop={0.5} variant="p" fontSize={30} fontWeight={'bold'}>
                {sales || 0} {overview.currency}
              </Typography>
              <Typography
                paddingTop={2}
                variant="p"
                fontSize={14}
                fontWeight={600}
                color={PRIMARY_COLOR}
              >
                {t('dashboard.overview.reports')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid flex={1} minWidth={{ xs: 250 }} pl={1.5} pr={1.5} pb={2}>
          <Card
            sx={{
              boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
              border: '1px solid #F0F0F0',
              borderRadius: 2
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="p" color={'#9CB2CD'} fontWeight={500}>
                {t('dashboard.overview.newClients')}
              </Typography>
              <Typography variant="span" fontSize={12} color={'#9CB2CD'}>
                {t('dashboard.overview.today')}
              </Typography>
              <Typography paddingTop={0.5} variant="p" fontSize={30} fontWeight={'bold'}>
                {overview.totalAthletes || 0}
              </Typography>
              <Typography
                paddingTop={2}
                variant="p"
                fontSize={14}
                fontWeight={600}
                color={PRIMARY_COLOR}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => navigate('/clients')}
              >
                {t('dashboard.overview.goToClients')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid flex={1} minWidth={{ xs: 250 }} pl={1.5} pb={2}>
          <Card
            sx={{
              boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
              border: '1px solid #F0F0F0',
              borderRadius: 2
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="p" color={'#9CB2CD'} fontWeight={500}>
                {t('dashboard.overview.checkins')}
              </Typography>
              <Typography variant="span" fontSize={12} color={'#9CB2CD'}>
                {t('dashboard.overview.today')}
              </Typography>
              <Typography paddingTop={0.5} variant="p" fontSize={30} fontWeight={'bold'}>
                {overview.checkins || 0}
              </Typography>
              <Typography
                paddingTop={2}
                variant="p"
                fontSize={14}
                fontWeight={600}
                color={PRIMARY_COLOR}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => navigate('/checkin')}
              >
                {t('dashboard.overview.goToSelfIn')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }, [isClub, overview, loading])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container paddingBottom={1.5} alignItems="center">
          <Grid item>
            <Typography variant="p" fontWeight={'bold'} fontSize={20}>
              {t('dashboard.overview.overview')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} paddingBottom={4}>
        {renderOverviewTrainer}
        {renderOverviewClub}
        {renderOverviewAthlete}
      </Grid>
    </Grid>
  )
}

export default Overview
