import React, { useEffect, useState } from 'react'
import {
  Grid,
  Card,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Container,
  Radio,
  Button,
  CircularProgress,
  Box
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify';

import { InputGenerator, Toggle, Footer } from '../common'
import * as USER_REQUESTS from '../../api/auth'
import * as customValidators from '../../utils/helpers/validator'

import { useAuthentication } from '../../context/auth-context'
import { LIGHT_GRAY_BORDER_COLOR, DEFAULT_MAX_LENGTH } from '../../utils/constants'

const UserSettings = () => {
  const { t } = useTranslation()
  const { handleToken, handleUser } = useAuthentication()

  const [user, setUser] = useState()
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [loading, setLoading] = useState(true)
  const [passwordFields, setPasswordFields] = useState({
    currentPassword: {
      value: null,
      hasError: false,
      isTouched: false,
      errorText: t('settings.errorTextPassword')
    },
    password: {
      value: null,
      hasError: false,
      isTouched: false,
      errorText: t('settings.errorTextPassword')
    },
    confirmPassword: {
      value: null,
      hasError: false,
      isTouched: false,
      errorText: t('settings.errorTextPassword')
    }
  })

  const onPasswordChangeHandler = (name, value) => {
    setPasswordFields({
      ...passwordFields,
      [name]: {
        ...passwordFields[name],
        isTouched: true,
        value,
        hasError: value.length < 6 || value === user.email.value
      }
    })
  }

  const onPasswordSaveHandler = async () => {
    try {
      await USER_REQUESTS.changePassword(
        passwordFields.currentPassword.value,
        passwordFields.confirmPassword.value,
        passwordFields.password.value
      )
      setShowChangePassword(false)
      toast.success(t('helper.passwordUpdatedSuccessfully'), { position: 'bottom-right' })
      setPasswordFields({
        currentPassword: {
          value: null,
          hasError: false,
          isTouched: false
        },
        password: {
          value: null,
          hasError: false,
          isTouched: false
        },
        confirmPassword: {
          value: null,
          hasError: false,
          isTouched: false
        }
      })
    } catch (e) {
      toast.error(t('helper.passwordNotUpdated'), { position: 'bottom-right', autoClose: false })
    }
  }

  const isPasswordDisabled = () => {
    return (
      !Object.keys(passwordFields).some((key) => passwordFields[key].isTouched) ||
      Object.keys(passwordFields).some((key) => passwordFields[key].hasError)
    )
  }

  const getMe = () => {
    return USER_REQUESTS.getMe().then((res) => {
      setUser({
        firstName: {
          value: res.firstName,
          isTouched: false,
          hasError: false,
          errorText: t('settings.errorEmpty')
        },
        lastName: {
          value: res.lastName,
          isTouched: false,
          hasError: false,
          errorText: t('settings.errorEmpty')
        },
        email: {
          value: res.email,
          isTouched: false,
          hasError: false,
          errorText: t('settings.errorEmail')
        },
        gender: {
          value: res.gender,
          isTouched: false
        },
        phoneNumber: {
          value: res.phoneNumber,
          isTouched: false,
          hasError: false,
          errorText: t('settings.errorPhoneNumber')
        },
        locationName: {
          value: res.locationName,
          isTouched: false
        },
        calendarReminder: {
          value: res.calendarReminder,
          isTouched: false
        },
        newsletter: {
          value: res.newsletter,
          isTouched: false
        },
        newMessages: {
          value: res.newsletter,
          isTouched: false
        }
      })
      setLoading(false);
    })
  }

  const isDisabled = () => {
    let emptyError = !user.phoneNumber.value || !user.email.value || !user.firstName.value || !user.lastName.value

    if (emptyError) return true

    return (
      Object.keys(user).every((key) => user[key].isTouched === false) ||
      Object.keys(user).some((key) => user[key].hasError === true)
    )
  }

  const updateMeHandler = () => {
    const data = {}

    Object.keys(user).forEach((key) => {
      if (user[key].isTouched && user[key].value) {
        data[key] = user[key].value
      }
    })

    return USER_REQUESTS.updateUser(data)
      .then(() => {
        return USER_REQUESTS.regenerateToken()
      })
      .then((response) => {
        handleToken(response.token)
        handleUser(response.user)
        localStorage.setItem('token', response.token)

        getMe()
        toast.success(t('helper.settingsUpdateSuccessfully'), { position: 'bottom-right' })
      })
      .catch(() => {
        getMe()
        toast.error(t('helper.settingsNotUpdated'), { position: 'bottom-right', autoClose: false })
      })
  }

  const onChangeHandler = (name, value) => {
    const computeError = (name) => {
      if (name === 'firstName' || name === 'lastName') {
        return !customValidators.isValidText(value)
      } else if (name === 'email') {
        return !customValidators.isValidEmail(value)
      } else if (name === 'phoneNumber') {
        return !customValidators.isValidPhone(value)
      }

      return false
    }

    setUser({
      ...user,
      [name]: {
        ...user[name],
        isTouched: true,
        value,
        hasError: computeError(name)
      }
    })
  }

  useEffect(() => {
    getMe()
  }, [])

  if (loading || !user) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Container maxWidth="xl">
      <Grid
        container
        pt={4}
        paddingLeft={{ xs: 1 }}
        paddingRight={{ xs: 1 }}
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Grid
          p={4}
          mt={{ xs: 3, lg: 0, md: 0 }}
          component={Card}
          container
          border={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
          flexDirection={{ xs: 'column' }}
        >
          <Grid container display="flex" flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}>
            <Grid item xs={12} md={6} lg={6} pr={{ xs: 0, lg: 8, md: 8 }}>
              <Grid container display="flex" flexDirection="column">
                <Grid item>
                  <Typography variant="h6" fontWeight="bold">
                    {t('settings.generalInformation')}
                  </Typography>
                </Grid>

                <Grid container pt={3}>
                  <Grid xs={12} md={6} lg={6} item pr={{ xs: 0, md: 2, lg: 2 }}>
                    <Grid xs={12} item>
                      <Typography fontWeight={500} variant="p">
                        {t('settings.firstName')}
                      </Typography>
                    </Grid>
                    <Grid xs={12} pt={1} item>
                      <TextField
                        inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                        error={user.firstName.hasError}
                        helperText={user.firstName.hasError && user.firstName.errorText}
                        value={user.firstName.value}
                        onChange={(e) => onChangeHandler('firstName', e.target.value)}
                        fullWidth
                        placeholder="John"
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} pt={{ xs: 2, lg: 0, md: 0 }} md={6} lg={6} item>
                    <Grid xs={12} item>
                      <Typography fontWeight={500} variant="p">
                        {t('settings.lastName')}
                      </Typography>
                    </Grid>
                    <Grid xs={12} pt={1} item>
                      <TextField
                        inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                        error={user.lastName.hasError}
                        helperText={user.lastName.hasError && user.lastName.errorText}
                        onChange={(e) => onChangeHandler('lastName', e.target.value)}
                        value={user.lastName.value}
                        fullWidth
                        placeholder="Doe"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container pt={{ xs: 2, lg: 3, md: 3 }}>
                  <Grid xs={12} item>
                    <Typography fontWeight={500} variant="p">
                      {t('settings.gender')}
                    </Typography>
                  </Grid>
                  <Grid xs={12} item>
                    <RadioGroup row>
                      <FormControlLabel
                        value="male"
                        control={
                          <Radio
                            onClick={() => onChangeHandler('gender', 'MALE')}
                            checked={user.gender.value === 'MALE'}
                          />
                        }
                        label={t('settings.MALE')}
                      />
                      <FormControlLabel
                        value="female"
                        control={
                          <Radio
                            onClick={() => onChangeHandler('gender', 'FEMALE')}
                            checked={user.gender.value === 'FEMALE'}
                          />
                        }
                        label={t('settings.FEMALE')}
                      />
                      <FormControlLabel
                        value="other"
                        control={
                          <Radio
                            onClick={() => onChangeHandler('gender', 'OTHER')}
                            checked={user.gender.value === 'OTHER'}
                          />
                        }
                        label={t('settings.OTHER')}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>

                <Grid container pt={{ xs: 2, md: 3, lg: 3 }}>
                  <Grid xs={12} md={6} lg={6} item pr={{ xs: 0, lg: 2, md: 2 }}>
                    <Grid xs={12} item>
                      <Typography fontWeight={500} variant="p">
                        {t('settings.email')}
                      </Typography>
                    </Grid>
                    <Grid xs={12} pt={1} item>
                      <TextField
                        inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                        error={user.email.hasError}
                        helperText={user.email.hasError && user.email.errorText}
                        onChange={(e) => onChangeHandler('email', e.target.value)}
                        value={user.email.value}
                        fullWidth
                        placeholder="john.doe@somemail.com"
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={6} lg={6} item pt={{ xs: 2, lg: 0, md: 0 }}>
                    <InputGenerator
                      handleOnChangePhone={(value) => onChangeHandler('phoneNumber', value)}
                      input={{
                        type: 'phone',
                        label: t('settings.phoneNumber'),
                        error: user.phoneNumber.hasError,
                        helperText: user.phoneNumber.hasError && user.phoneNumber.errorText,
                        placeholder: '0728391121',
                        value: user.phoneNumber.value
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container pt={{ xs: 1, md: 3, lg: 3 }}>
                  <Grid xs={12} item>
                    <Grid xs={12} pt={1} item>
                      <InputGenerator
                        handleOnChangeGoogle={(event) =>
                          onChangeHandler('locationName', event.label)
                        }
                        input={{
                          name: 'city',
                          defaultValue: user.locationName.value ? user.locationName.value : '',
                          placeholder: user.locationName.value
                            ? user.locationName.value
                            : t('settings.searchCityPlaceholder'),
                          type: 'google',
                          label: t('settings.city')
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} lg={6} pt={{ xs: 3, lg: 0, md: 0 }}>
              <Grid container display="flex" flexDirection="column">
                <Grid item>
                  <Typography variant="h6" fontWeight="bold">
                    {t('settings.emailNotification')}
                  </Typography>
                </Grid>

                <Grid container pt={3}>
                  <Grid xs={12} item>
                    <Grid xs={12} item alignItems="center" display="flex" flexDirection="row">
                      <Grid item pr={1}>
                        <Toggle
                          color="primary"
                          defaultChecked={user.calendarReminder.value}
                          onClick={() =>
                            onChangeHandler('calendarReminder', !user.calendarReminder.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography fontWeight={600} variant="p">
                          {t('settings.calendarReminder')}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      pt={2}
                      alignItems="center"
                      display="flex"
                      flexDirection="row"
                    >
                      <Grid item pr={1}>
                        <Toggle
                          color="primary"
                          defaultChecked={user.newsletter.value}
                          onClick={() => onChangeHandler('newsletter', !user.newsletter.value)}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography fontWeight={600} variant="p">
                          {t('settings.newsletters')}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      pt={2}
                      alignItems="center"
                      display="flex"
                      flexDirection="row"
                    >
                      <Grid item pr={1}>
                        <Toggle
                          color="primary"
                          defaultChecked={user.newMessages.value}
                          onClick={() => onChangeHandler('newMessages', !user.newMessages.value)}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography fontWeight={600} variant="p">
                          {t('settings.newMessages')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {!showChangePassword && (
                  <Grid item pt={{ xs: 3, lg: 6, md: 6 }}>
                    <Grid xs={12} item>
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={() => setShowChangePassword(true)}
                      >
                        {t('settings.changePassword')}
                      </Button>
                    </Grid>
                  </Grid>
                )}
                {showChangePassword && (
                  <>
                    <Grid
                      mt={3}
                      borderRadius={3}
                      p={3}
                      display="flex"
                      flexDirection="column"
                      border={`2px solid ${LIGHT_GRAY_BORDER_COLOR}`}
                    >
                      <Grid item>
                        <Typography variant="h6" fontWeight="bold">
                          {t('settings.changePassword')}
                        </Typography>
                      </Grid>

                      <Grid container pt={3}>
                        <Grid xs={12} item>
                          <Grid xs={12} item>
                            <Typography fontWeight={500} variant="p">
                              {t('settings.password')}
                            </Typography>
                          </Grid>
                          <Grid xs={12} pt={1} item>
                            <TextField
                              inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                              value={passwordFields.currentPassword.value}
                              error={passwordFields.currentPassword.hasError}
                              helperText={
                                passwordFields.currentPassword.hasError &&
                                passwordFields.currentPassword.errorText
                              }
                              onChange={(e) =>
                                onPasswordChangeHandler('currentPassword', e.target.value)
                              }
                              fullWidth
                              type="password"
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container pt={{ xs: 2, lg: 3, md: 3 }}>
                        <Grid xs={12} md={6} lg={6} item pr={{ xs: 0, md: 2, lg: 2 }}>
                          <Grid xs={12} item>
                            <Typography fontWeight={500} variant="p">
                              {t('settings.newPassword')}
                            </Typography>
                          </Grid>
                          <Grid xs={12} pt={1} item>
                            <TextField
                              inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                              value={passwordFields.password.value}
                              error={passwordFields.password.hasError}
                              helperText={
                                passwordFields.password.hasError &&
                                passwordFields.password.errorText
                              }
                              onChange={(e) => onPasswordChangeHandler('password', e.target.value)}
                              fullWidth
                              type="password"
                            />
                          </Grid>
                        </Grid>
                        <Grid xs={12} md={6} lg={6} pt={{ xs: 2, lg: 0, md: 0 }} item>
                          <Grid xs={12} item>
                            <Typography fontWeight={500} variant="p">
                              {t('settings.confirmPassword')}
                            </Typography>
                          </Grid>
                          <Grid xs={12} pt={1} item>
                            <TextField
                              inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                              value={passwordFields.confirmPassword.value}
                              error={passwordFields.confirmPassword.hasError}
                              helperText={
                                passwordFields.confirmPassword.hasError &&
                                passwordFields.confirmPassword.errorText
                              }
                              onChange={(e) =>
                                onPasswordChangeHandler('confirmPassword', e.target.value)
                              }
                              fullWidth
                              type="password"
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container pb={3} width="100%">
                        <Grid item xs={12} md={12} lg={12}>
                          <Grid
                            container
                            display="flex"
                            flexDirection={{ xs: 'row-reverse', lg: 'column', md: 'column' }}
                          >
                            <Grid item pt={6} xs={12} pr={{ xs: 0, md: 2, lg: 2 }}>
                              <Button
                                fullWidth
                                variant="outlined"
                                onClick={() => {
                                  setShowChangePassword(false)
                                  setPasswordFields({
                                    currentPassword: {
                                      value: null,
                                      hasError: false,
                                      isTouched: false
                                    },
                                    password: {
                                      value: null,
                                      hasError: false,
                                      isTouched: false
                                    },
                                    confirmPassword: {
                                      value: null,
                                      hasError: false,
                                      isTouched: false
                                    }
                                  })
                                }}
                              >
                                {t('settings.cancel')}
                              </Button>
                            </Grid>
                            <Grid item pt={{ xs: 2, lg: 6, md: 6 }} xs={12}>
                              <Button
                                fullWidth
                                variant="contained"
                                onClick={onPasswordSaveHandler}
                                disabled={isPasswordDisabled()}
                              >
                                {t('settings.changePassword')}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item display="flex" alignItems="center" justifyContent="center" p={3} width="100%">
            <Grid xs={12} md={3} lg={3} pt={6} item>
              <Button
                variant="contained"
                disabled={isDisabled()}
                fullWidth
                onClick={updateMeHandler}
              >
                {t('settings.saveChanges')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default UserSettings
