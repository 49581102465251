import React, { useEffect, useState } from 'react'
import { Grid, Typography, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Subscription from './subcomponents/Subscription'
import CancelSubscriptionDialog from './subcomponents/CancelSubscriptionDialog'
import PauseSubscriptionDialog from './subcomponents/PauseSubscriptionDialog'
import { Snackbar } from '../../../common'

import * as USER_REQUESTS from '../../../../api/user'
import { getStatus } from '../../../../utils/helpers/userSubscription'
import moment from 'moment'

const Subscriptions = () => {
  const { t } = useTranslation()

  const [pauseSubscription, setPauseSubscription] = useState()
  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const [selectedSubscription, setSelectedSubscription] = useState()
  const [userSubscriptions, setUserSubscriptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [showSnackbar, setShowSnackbar] = useState(false)

  const getUserSubscriptions = () => {
    setLoading(true)
    return USER_REQUESTS.getSubscriptions().then((response) => {
      const filteredResponse = response.filter(el => {
        const status = getStatus(el)
        if (!status.expired) return true

        if (Math.ceil(moment().diff(moment(el.validUntil), 'days', true)) > 31) return false
        return true
      })
      setUserSubscriptions(filteredResponse)
      setLoading(false)
    })
  }

  const handleCancel = () => {
    USER_REQUESTS.cancelSubscription(selectedSubscription).then(() => {
      setOpenCancelDialog(false)
      setSelectedSubscription(null)
      getUserSubscriptions()
    })
      .catch(() => {
        setOpenCancelDialog(false)
        setShowSnackbar(true)
      })
  }

  const handleOnCancel = (id) => {
    setSelectedSubscription(id)
    setOpenCancelDialog(true)
  }

  useEffect(() => {
    getUserSubscriptions()
  }, [])

  const renderUserSubscriptions = () => {
    if (loading) return null

    return (
      <>
        {userSubscriptions.length > 0 ? (
          userSubscriptions.map((el, index) => {
            return (
              <Grid key={index} item pb={4}>
                <Subscription
                  {...el}
                  id={el.id}
                  onPauseSubscriptionHandler={() => setPauseSubscription(el)}
                  isTrainer={!el.Subscription.companyId}
                  pauseEnabled={el.Subscription.pauseSubscription}
                  pauseMinimumDays={el.Subscription.minimumPauseDays}
                  pauseMaximumDays={el.Subscription.maximumPauseDays}
                  gymName={
                    el.Subscription.Company
                      ? el.Subscription.Company.name
                      : `${el.Subscription.Trainer.firstName} ${el.Subscription.Trainer.lastName}`
                  }
                  paymentFinalDate={el.validUntil}
                  gymSubscriptionDetails={el.Subscription.description}
                  price={el.price}
                  gymSubscriptionName={el.Subscription.name}
                  handleOnCancel={handleOnCancel}
                  currency={el.Payment?.currency}
                />
              </Grid>
            )
          })
        ) : (
          <Typography color="#979797" fontWeight={500} fontSize={17}>
            {t('helper.noResultsFound')}
          </Typography>
        )}
      </>
    )
  }

  return (
    <>
      {showSnackbar ? (
        <Snackbar message={t('userProfile.subCannotBe')} />
      ) : null}
      {pauseSubscription ? <PauseSubscriptionDialog
        userSubscription={pauseSubscription}
        onCancel={() => setPauseSubscription()}
        toDelete={!!pauseSubscription.Pause}
        onConfirm={() => {
          return getUserSubscriptions()
            .finally(() => setPauseSubscription())
        }}
      /> : null}
      {openCancelDialog && selectedSubscription ? (
        <CancelSubscriptionDialog
          handleDelete={handleCancel}
          handleClose={() => {
            return getUserSubscriptions()
              .finally(() => {
                setOpenCancelDialog(false)
                setSelectedSubscription(null)
              })
          }}
        />
      ) : null}
      <Grid
        p={4}
        pr={{ xs: 1, lg: 4 }}
        pl={{ xs: 1, lg: 4 }}
        container
        display="flex"
        flexDirection="column"
      >
        {loading && (
          <Grid item pt={4} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
        {renderUserSubscriptions()}
      </Grid>
    </>
  )
}

export default Subscriptions
