import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'
import { useNavigate } from 'react-router-dom'

import BG_IMAGE from '../../assets/club.png'
import { InputGenerator, SendingButton as SendingButtonChild, Footer } from '../common'
import { computeArrayOfInputsWithError } from '../../utils/helpers/validator'

import * as COMPANY_REQUESTS from '../../api/company'
import { useAuthentication } from '../../context/auth-context'

const CreateClub = () => {
  const { t } = useTranslation()
  const { user } = useAuthentication()
  const navigate = useNavigate()

  const INPUTS = [
    {
      name: 'domain',
      placeholder: t('input.placeholder.domain'),
      label: t('input.label.domain'),
      value: '',
      type: 'text',
      startAdornment: 'sportin.io/',
      level: 0,
      mandatory: true
    },
    {
      name: 'clubName',
      placeholder: t('input.placeholder.clubName'),
      label: t('input.label.clubName'),
      value: '',
      type: 'text',
      level: 1,
      mandatory: true
    },
    {
      name: 'businessName',
      placeholder: t('input.placeholder.businessName'),
      label: t('input.label.businessName'),
      value: '',
      type: 'text',
      level: 1,
      mandatory: true
    },
    {
      name: 'city',
      label: t('input.label.city'),
      placeholder: t('input.placeholder.city'),
      value: 'cluj',
      type: 'google',
      level: 6,
      mandatory: true
    }
  ]

  const [sending, setSending] = useState(false)
  const [inputs, setInputs] = useState([])
  const [wrongInputs, setWrongInputs] = useState([])
  const [locationLat, setLocationLat] = useState()
  const [locationLng, setLocationLng] = useState()
  const [locationName, setLocationName] = useState('')
  const [error, setError] = useState()

  useEffect(() => {
    if (error === 'DOMAIN_EXISTS') {
      setWrongInputs((prevValue) => {
        const prevValueWithoutEmail = [...prevValue].filter((el) => el.name !== 'domain')
        return [...prevValueWithoutEmail, { name: 'domain', messageKey: 'DOMAIN_EXISTS' }]
      })
    }
  }, [error])

  useEffect(() => {
    setInputs([...INPUTS])
  }, [])

  const handleOnChange = (event) => {
    setWrongInputs((prevValues) => [...prevValues.filter((el) => el.name !== event.target.name)])
    setInputs((prevValues) => {
      const inputPos = prevValues.findIndex((f) => f.name === event.target.name)
      if (inputPos < 0) return [...prevValues]

      const prevValuesCopy = [...prevValues]
      prevValuesCopy[inputPos].value =
        event.target.name === 'domain'
          ? event.target.value.trim().replace(' ', '')
          : event.target.value

      return prevValuesCopy
    })
  }

  const handleOnChangeGoogle = (googleData) => {
    setLocationName(googleData.label)
    geocodeByAddress(googleData.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setLocationLat(lat)
        setLocationLng(lng)
      })
  }

  const mapInputToCompany = (input) => {
    if (input === 'clubName') return 'name'
    if (input === 'businessName') return 'legalName'
    if (input === 'domain') return 'domain'
    return input
  }

  const handleOnSubmit = () => {
    setSending(true)

    let wrong = false
    const inputsWithError = computeArrayOfInputsWithError(inputs)
    const companyInputs = ['domain', 'clubName', 'businessName']

    if (inputsWithError && inputsWithError.length > 0) {
      setWrongInputs([...inputsWithError])
      wrong = true
    }

    const registerData = {}

    companyInputs.forEach((input) => {
      const inputPos = inputs.findIndex((f) => f.name === input)
      if (inputPos < 0) return

      registerData[mapInputToCompany(input)] = inputs[inputPos].value
    })

    if (wrong) return
    if (locationLat) {
      registerData['locationLat'] = String(locationLat)
    }
    if (locationLng) {
      registerData['locationLng'] = String(locationLng)
    }
    registerData['locationName'] = locationName
    registerData['locationName'] = locationName

    return COMPANY_REQUESTS.createClub({
      businessOwnerId: user.id,
      ...registerData
    })
      .then(() => {
        navigate('/employees')
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          setError(error.response.data.includes('DOMAIN_EXISTS') ? 'DOMAIN_EXISTS' : null)
        }

        setSending(false)
      })
  }

  const filterInputs = useCallback(
    (level) => inputs.filter((input) => input.level === level),
    [inputs]
  )

  const computeHelperText = (input) => {
    if (wrongInputs.map((el) => el.name).includes(input.name)) {
      if (input.value === '' && input.name !== 'confirmPassword')
        return `${input.label} ${t('signup.cantBeEmpty')}`
      if (input.name === 'email' || input.name === 'phoneNumber' || input.name === 'domain') {
        const inputIndex = wrongInputs.findIndex((el) => el.name === input.name)
        if (inputIndex >= 0) {
          return t(`input.error.${wrongInputs[inputIndex].messageKey}`)
        }
      }
      return t(`input.error.${input.name}`)
    }
  }

  const handleOnChangePhone = (phone, name) => {
    setWrongInputs((prevValues) => [...prevValues.filter((el) => el.name !== name)])
    setInputs((prevValues) => {
      const inputPos = prevValues.findIndex((f) => f.name === name)
      if (inputPos < 0) return [...prevValues]

      const prevValuesCopy = [...prevValues]
      prevValuesCopy[inputPos].value = phone

      return prevValuesCopy
    })
  }

  const renderForm = useMemo(() => {
    const levels = inputs.map((el) => el.level)
    const levelsWithoutDuplicates = levels.filter((element, index) => {
      return levels.indexOf(element) === index
    })

    return (
      <Grid item xs={12} lg={6} p={{ xs: 4, lg: 10 }} pl={{ xs: 2 }} pr={{ xs: 2 }}>
        <Grid container height="100%">
          <Grid container direction="column" rowSpacing={4}>
            <Grid item>
              <Typography fontWeight="bold" variant="h4" color="primary">
                {t('title.newClubTitle')}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container flexDirection="column">
                {levelsWithoutDuplicates.map((level, index) => {
                  const inputsToUse = filterInputs(level)
                  return (
                    <Grid pb={3} key={index} container flexDirection="row">
                      {inputsToUse.map((inp, idx) => {
                        return (
                          <Grid
                            pr={{ lg: idx !== inputsToUse.length - 1 ? 4 : 0, xs: 0 }}
                            lg={12 / inputsToUse.length}
                            xs={12}
                            key={idx}
                            item
                          >
                            <InputGenerator
                              input={{ ...inp, error: computeHelperText(inp) }}
                              handleOnChangeGoogle={handleOnChangeGoogle}
                              handleOnChangePhone={handleOnChangePhone}
                              handleOnChange={handleOnChange}
                              extraLabel={
                                inp.name === 'domain'
                                  ? t('helper.thisWillBeUnique')
                                  : ''
                              }
                              error={wrongInputs.map((el) => el.name).includes(inp.name)}
                              helperText={computeHelperText(inp)}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
            <Grid item display="flex" flexDirection="row">
              <Grid container display="flex" flexDirection="row">
                <Grid item paddingRight={{ lg: 4, xs: 1 }} xs={6} lg={4}>
                  <Button
                    fullWidth
                    onClick={() => navigate('/business-profile')}
                    color="primary"
                    variant="outlined"
                  >
                    {t('button.back')}
                  </Button>
                </Grid>
                <Grid item paddingLeft={{ lg: 4, xs: 1 }} xs={6} lg={8}>
                  <Button
                    disabled={
                      sending ||
                      !locationName ||
                      !locationLat ||
                      !locationLng ||
                      inputs.some((el) => !el.value)
                    }
                    fullWidth
                    onClick={handleOnSubmit}
                    color="primary"
                    variant="contained"
                  >
                    {!sending ? t('button.getStarted') : <SendingButtonChild />}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }, [inputs, sending, wrongInputs, locationName, locationLat, locationLng])

  const renderImage = useMemo(() => {
    return (
      <Grid
        item
        alignItems="center"
        display={{ xs: 'none', sm: 'none', lg: 'block' }}
        lg={6}
        maxHeight={'98vh'}
      >
        <img
          style={{ position: 'relative', left: '3%', top: '-30%', objectFit: 'cover' }}
          height={'150%'}
          src={BG_IMAGE}
          width={1200}
        />
      </Grid>
    )
  }, [])

  return (
    <Grid container style={{ padding: 0, overflowX: 'hidden' }} height="100%">
      {renderForm}
      {renderImage}
    </Grid>
  )
}

export default CreateClub
