import React from 'react'
import { Box, Typography } from '@mui/material'
import { Star } from '@mui/icons-material'
import moment from 'moment'
import Linkify from 'react-linkify'

import { CARD_TEXT_COLOR } from '../../../../../utils/constants'

const Review = ({ comment, name, stars, createdAt }) => {
  const generateStars = () => {
    const starsArr = []
    while (stars) {
      starsArr.push(stars)
      stars -= 1
    }

    return starsArr
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" justifyContent="flex-start">
        <Typography
          pr={1}
          sx={{ textDecoration: 'underline' }}
          fontWeight={600}
          variant="p"
          color="primary"
        >
          {name}
        </Typography>
        {generateStars().map((index) => (
          <Star key={index} color="primary" />
        ))}
      </Box>
      <Typography gutterBottom color={CARD_TEXT_COLOR}>
        {moment(createdAt).format('DD/MM/YYYY')}
      </Typography>
      <Linkify>
        <Typography fontWeight={500}>{comment}</Typography>
      </Linkify>
    </Box>
  )
}

export default Review
