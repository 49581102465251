import React, { useEffect, useState } from 'react'
import {
  Grid,
  List,
  ListItem,
  Button,
  ListItemText,
  ListItemIcon,
  Checkbox
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { InputGenerator } from '../../../../common'

import { EQUIPMENTS_TYPES, SPORTTYPES_TYPES } from '../../../../../utils/constants'
import * as EQUIPMENTS_REQUESTS from '../../../../../api/equipments'
import * as SPORTTYPES_REQUESTS from '../../../../../api/sportTypes'
import * as COMPANY_REQUESTS from '../../../../../api/company'

const Equipments = ({ equipment, type, companyId, companyDefaultSportType, refetchCompany }) => {
  const { t } = useTranslation()

  const [equipmentsText, setEquipmentsText] = useState('')
  const [sportTypesText, setSportTypesText] = useState('')
  const [equipments, setEquipments] = useState([])
  const [sportTypes, setSportTypes] = useState([])
  const [defaultSportType, setDefaultSportType] = useState(companyDefaultSportType)

  const equipmentTypes = equipment
    ? Object.keys(EQUIPMENTS_TYPES).map((key) => ({
      label: t(`equipmentsOptions.${EQUIPMENTS_TYPES[key]}`),
      value: EQUIPMENTS_TYPES[key]
    }))
    : []

  const sportTypesTypes = type
    ? Object.keys(SPORTTYPES_TYPES).map((key) => ({
      label: t(`sportTypesOptions.${SPORTTYPES_TYPES[key]}`),
      value: SPORTTYPES_TYPES[key]
    }))
    : []

  const getEquipments = () =>
    EQUIPMENTS_REQUESTS.findAll().then((response) => {
      setEquipments(response)
    })

  const removeEquipments = (id) => EQUIPMENTS_REQUESTS.remove(id).then(getEquipments)

  const addEquipment = (title) => {
    return EQUIPMENTS_REQUESTS.create({ title }).then(getEquipments)
  }

  const getSportTypes = () =>
    SPORTTYPES_REQUESTS.companyFindAll().then((response) => {
      setSportTypes(response)
    })

  const removeSportType = (id) => SPORTTYPES_REQUESTS.companyRemove(id).then(getSportTypes)

  const addSportType = (title) => {
    return SPORTTYPES_REQUESTS.companycreate({ title }).then(getSportTypes)
  }

  const updateDefaultSportType = defaultSportType => {
    return COMPANY_REQUESTS.updateBusiness(companyId, { defaultSportType })
      .then(refetchCompany)
      .then(() => setDefaultSportType(defaultSportType))
  }

  useEffect(() => {
    equipment && getEquipments()
    type && getSportTypes()
  }, [equipment, type])

  useEffect(() => {
    setDefaultSportType(companyDefaultSportType)
  }, [companyDefaultSportType])

  const equipmentsTitles = equipment ? equipments.map((el) => el.title) : []
  const sportTypesTitles = type ? sportTypes.map((el) => el.title) : []

  return (
    <Grid
      container
      p={4}
      pr={0}
      pl={0}
      display="flex"
      flexDirection="row"
      alignItems={'center'}
      justifyContent="center"
      spacing={3}
    >
      {equipment ? (
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <InputGenerator
                handleOnChange={(event) => setEquipmentsText(event.target.value)}
                input={{
                  type: 'text',
                  label: '',
                  placeholder: t('businessEditProfile.searchEquipment'),
                  value: equipmentsText
                }}
              />
              <List sx={{ width: '100%' }} dense>
                {equipmentTypes
                  .filter((el) => el.label.toLowerCase().includes(equipmentsText.toLowerCase()))
                  .map((key, index) => {
                    return (
                      <ListItem divider key={index}>
                        <ListItemIcon onClick={() =>
                          equipmentsTitles.includes(key.value)
                            ? removeEquipments(key.value)
                            : addEquipment(key.value)
                        }>
                          <Checkbox
                            edge="start"
                            checked={equipmentsTitles.includes(key.value)}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText>{key.label}</ListItemText>
                      </ListItem>
                    )
                  })}
              </List>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {type ? (
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <InputGenerator
                handleOnChange={(event) => setSportTypesText(event.target.value)}
                input={{
                  type: 'text',
                  label: '',
                  placeholder: t('businessEditProfile.searchSportType'),
                  value: sportTypesText
                }}
              />
              <List sx={{ width: '100%' }} dense>
                {sportTypesTypes
                  .filter((el) => el.label.toLowerCase().includes(sportTypesText.toLowerCase()))
                  .map((key, index) => {
                    const isDefault = key.value === defaultSportType
                    const isChecked = sportTypesTitles.includes(key.value)

                    return (
                      <ListItem divider key={index}>
                        <ListItemIcon onClick={() =>
                          isChecked
                            ? removeSportType(key.value)
                            : addSportType(key.value)
                        }>
                          <Checkbox
                            edge="start"
                            checked={isChecked}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText>{key.label}</ListItemText>
                        <Button disabled={isDefault} variant={isDefault ? "contained" : "outlined"} onClick={() => updateDefaultSportType(key.value)}>
                          {isDefault ? t('helper.selected') : t('helper.setDefault')}
                        </Button>
                      </ListItem>
                    )
                  })}
              </List>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  )
}

export default Equipments
