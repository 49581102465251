import React, { useState, useEffect, useMemo } from 'react'
import { Dialog, IconButton, Button, Typography, Grid, TextField, Autocomplete, Box, Avatar, Chip, InputAdornment } from '@mui/material'
import { Close, StackedLineChart, ArrowDropDown, Percent, EventRepeat } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { useAuthentication } from '../../../context/auth-context'
import { LIGHT_GRAY_BORDER_COLOR, GRAY_BG_COLOR, SECONDARY_TEXT_COLOR, ROLE_USER, VOUCHER_TYPES, PRIMARY_COLOR, GRAY_TEXT_COLOR } from '../../../utils/constants'
import * as COMPANY_REQUESTS from '../../../api/company'
import * as VOUCHER_REQUESTS from '../../../api/voucher'
import * as USER_REQUESTS from '../../../api/user'

const AssignModal = ({
    onCancel,
    onConfirm,
    edit
}) => {
    const { t } = useTranslation()
    const { user } = useAuthentication()

    const [personOptionsLoading, setPersonOptionsLoading] = useState(false)
    const [selectedPersons, setSelectedPersons] = useState([])
    const [personSearch, setPersonSearch] = useState('')
    const [personOptions, setPersonOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [subscriptions, setSubscriptions] = useState([])
    const [selectedSubscription, setSelectedSubscription] = useState()
    const [vouchers, setVouchers] = useState([])
    const [selectedVoucher, setSelectedVoucher] = useState()

    const getSubscriptions = () => {
        return COMPANY_REQUESTS.getAllSubscriptions(user.companyId)
            .then(response => {
                setSubscriptions(response)
            })
    }

    const getVouchers = () => {
        return VOUCHER_REQUESTS.getAll({ subscriptionId: selectedSubscription })
            .then(response => setVouchers(response))
    }

    useEffect(() => {
        if (!selectedSubscription) return

        getVouchers()
    }, [selectedSubscription])

    const submitHandler = () => {
        setLoading(true)
        VOUCHER_REQUESTS.bulkCreateUserVouchers(selectedPersons.map(el => ({ userId: el.value, voucherId: selectedVoucher })))
            .then(() => {
                onConfirm()
            })
    }

    useEffect(() => {
        if (!personSearch) return
        getAllUsers()
    }, [personSearch])

    const getAllUsers = () => {
        setPersonOptionsLoading(true)
        if (!personSearch) {
            setPersonOptions([])
            setPersonOptionsLoading(false)
            return
        }
        return USER_REQUESTS.getAllUsers({
            role: ROLE_USER,
            ...(personSearch ? { search: personSearch } : {})
        }).then((response) => {
            setPersonOptions(response)
            setPersonOptionsLoading(false)
        })
    }

    const renderTypeIcon = type => {
        return type === VOUCHER_TYPES.CONSECUTIVE_BUYS ? <StackedLineChart /> : type === VOUCHER_TYPES.DISCOUNT ? <Percent /> : <EventRepeat />
    }

    const selectSubscriptionHandler = subscriptionId => {
        setSelectedSubscription(subscriptionId)
    }

    useEffect(() => {
        if (!user.companyId) return
        getSubscriptions()
    }, [user])

    const renderPersonDropdown = useMemo(() => {
        const companyId = user.companyId

        return (
            <Autocomplete
                options={personOptions.map((el) => ({
                    label: `${el.firstName} ${el.lastName}`,
                    avatar: el.avatarPhotoSrc,
                    phoneNumber: el.phoneNumber,
                    value: el.id,
                    firstName: el.firstName,
                    lastName: el.lastName,
                    belongingCompanyId: el.belongingCompanyId
                }))}
                autoHighlight
                disabled={!selectedSubscription || !selectedVoucher}
                loading={personOptionsLoading}
                renderOption={(props, option) => {
                    return (
                        <Box {...props} sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {option.avatar ? (
                                    <Avatar
                                        sx={{ height: 40, width: 40 }}
                                        src={option.avatar}
                                    >{option.firstName.charAt(0)}{option.lastName.charAt(0)}</Avatar>
                                ) : (
                                    <Box
                                        sx={{
                                            width: 40,
                                            height: 40,
                                            backgroundColor: GRAY_BG_COLOR,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: 25
                                        }}
                                    >
                                        <Typography fontWeight="bold" color={GRAY_TEXT_COLOR}>
                                            {option.firstName.charAt(0)} {option.lastName.charAt(0)}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 1 }}>
                                <Typography fontWeight={500} color={companyId == option.belongingCompanyId ? PRIMARY_COLOR : null}>{option.label}</Typography>
                                <Typography fontWeight={500} color={companyId == option.belongingCompanyId ? PRIMARY_COLOR : null}>{option.phoneNumber}</Typography>
                            </Box>
                        </Box>
                    )
                }}
                inputValue={personSearch}
                filterOptions={(options, state) => options}
                onInputChange={(event) => {
                    if (!event || !event.target) {
                        setPersonSearch('')
                        return
                    }

                    setPersonSearch(event.target.value)
                }}
                onChange={(_, newValue) => {
                    if (!newValue) return

                    const userIds = selectedPersons.map(el => el.value)

                    if (userIds.includes(newValue.value)) return

                    setSelectedPersons(prevValue => prevValue.concat([newValue]))
                }}
                renderInput={(params) => (
                    <TextField
                        placeholder={t('businessEditProfile.searchTrainer')}
                        {...params}
                        value={personSearch}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="start">
                                    <ArrowDropDown />
                                </InputAdornment>
                            )
                        }}
                        inputProps={{
                            ...params.inputProps,
                            style: { marginLeft: 6, padding: 11.5, color: SECONDARY_TEXT_COLOR, fontWeight: 500 }
                        }}
                    />
                )}
            />
        )
    }, [personOptions, selectedPersons, user, selectedSubscription, selectedVoucher, personSearch])

    return (
        <Dialog open maxWidth="sm" fullWidth>
            <Grid container pt={4}>
                <Grid item xs={12} pl={5} pr={5}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item xs={10}>
                            <Typography variant="h5" fontWeight="bold" color={'black'}>
                                {t('userVouchers.assignModal.title')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" flexDirection="row" justifyContent={"flex-end"}>
                            <IconButton onClick={onCancel}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    pt={4}
                    pb={5}
                    xs={12}
                    borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
                >
                    <Grid container pl={5} pr={5} spacing={2}>
                        <Grid item xs={12}>
                            <Typography pb={0.5}>{t('vouchers.createModal.subscriptions')}</Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {subscriptions.map((subscription) => (
                                    <Chip
                                        variant={selectedSubscription === subscription.id ? 'contained' : 'outlined'}
                                        color={'primary'}
                                        key={subscription.id}
                                        label={subscription.name}
                                        sx={{ cursor: 'pointer' }}
                                        disabled={edit}
                                        onClick={() => selectSubscriptionHandler(subscription.id)}
                                    />
                                ))}
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography pb={0.5}>{t('userVouchers.assignModal.vouchers')}</Typography>
                            {selectedSubscription ? <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {vouchers.map((voucher) => (
                                    <Chip
                                        variant={selectedVoucher === voucher.id ? 'contained' : 'outlined'}
                                        color={'success'}
                                        key={voucher.id}
                                        label={voucher.discount + '%' + ' ' + voucher.name}
                                        sx={{ cursor: 'pointer' }}
                                        icon={renderTypeIcon(voucher.type)}
                                        disabled={edit}
                                        onClick={() => setSelectedVoucher(voucher.id)}
                                    />
                                ))}
                            </Box> : <Typography variant="span" color={GRAY_TEXT_COLOR}>{t('userVouchers.assignModal.selectSubscription')}</Typography>}
                        </Grid>

                        <Grid item xs={12}>
                            <Typography pb={0.5}>{t('userVouchers.assignModal.clients')}</Typography>
                            {renderPersonDropdown}
                            <Box mt={1}>
                                <Grid container spacing={1} pt={1}>
                                    {selectedPersons.map((el) => {
                                        return <Chip
                                            index={el.value}
                                            label={el.firstName + el.lastName}
                                            onDelete={() => {
                                                setSelectedPersons(selectedPersons.filter(person => el.value !== person.value))
                                            }}
                                        />
                                    })}
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="row" sx={{ height: 60 }}>
                        <Grid item xs={6} lg={6} sx={{ height: 60 }} display="flex" justifyContent="center" alignItems="center" borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
                            <Button style={{ height: '100%', fontWeight: 'bold' }} onClick={onCancel} fullWidth variant="text" color="error">
                                {t('vouchers.createModal.cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <Button
                                disabled={loading || !selectedSubscription || !selectedVoucher || !selectedPersons.length}
                                sx={{ height: 60 }}
                                style={{ height: '100%', fontWeight: 'bold' }}
                                onClick={() => {
                                    submitHandler()
                                }}
                                fullWidth
                                color={'primary'}
                                variant="text"
                            >
                                {t('vouchers.createModal.submit')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default AssignModal
