import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, IconButton, Button, Typography, Grid } from '@mui/material'
import { Close } from '@mui/icons-material'
import moment from 'moment'

import * as USER_SUBSCRIPTION_REQUESTS from '../../../../api/userSubscriptions'
import { DATE_FORMAT, SUBSCRIPTION_PAYMENT_STATUS } from '../../../../utils/constants'
import { useTranslation } from 'react-i18next'

const Confirm = ({
    onCancel,
    onConfirm,
    userSubscriptionId
}) => {

    const { t } = useTranslation()

    const [userSubscription, setUserSubscription] = useState()
    const [loading, setLoading] = useState(false)

    const confirmPayment = () => {
        setLoading(true)
        USER_SUBSCRIPTION_REQUESTS.update(userSubscriptionId, { status: SUBSCRIPTION_PAYMENT_STATUS.CONFIRMED })
            .then(() => {
                onConfirm()
            })
            .catch(() => {
                console.error(t('checkIn.somethingWrong'))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        USER_SUBSCRIPTION_REQUESTS.findById(userSubscriptionId)
            .then(response => {
                setUserSubscription(response)
            })
    }, [userSubscriptionId])

    if (!userSubscription) return null

    const fullName = `${userSubscription.User.firstName} ${userSubscription.User.lastName}`
    const currentDate = moment(new Date(), DATE_FORMAT)
    const paymentDeadlineDate = moment(userSubscription.createdAt, DATE_FORMAT).add(userSubscription.Subscription.paymentDeadline, 'days')
    const isAllowedToCheckInWithoutPayment = currentDate.isBefore(paymentDeadlineDate)

    return (
        <Dialog open maxWidth="sm" fullWidth>
            <Grid container p={4}>
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item
                            xs={10}
                            md={10}
                            lg={10}
                            pr={{ xs: 0, md: 0, lg: 2 }}
                            pl={{ xs: 0, md: 0, lg: 2 }}>
                            <Typography variant="h4" fontWeight={500} color={'black'}>
                                {t('checkIn.subPayment')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                            <IconButton onClick={onCancel}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    pt={4}
                    pb={4}
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}
                >
                    <Typography gutterBottom variant="h6"><b>{fullName}</b> {t('checkIn.hasToPay')} <b>{userSubscription.Subscription.name}</b>.</Typography>
                    <Typography variant="h6" sx={{ paddingTop: 3 }}>1. {fullName} {t('checkIn.hasToPayToYou')}</Typography>
                    <Typography variant="h6">2.{t('checkIn.confirmPayment')}</Typography>
                    <Typography variant="h6">3. {t('checkIn.youWillBeAble')} {fullName} {t('checkIn.forTheSubscription')} {userSubscription.Subscription.name}.</Typography>
                </Grid>
                {(!userSubscription.Subscription.confirmation && isAllowedToCheckInWithoutPayment) && <Grid
                    item
                    pt={4}
                    pb={4}
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}
                >
                    <Typography gutterBottom variant="h6"><b>{userSubscription.Subscription.name}</b> {t('checkIn.paymentDeadlineIs')} <b>{paymentDeadlineDate.format(DATE_FORMAT)}</b>.</Typography>
                    <Typography variant="h6" gutterBottom sx={{ paddingTop: 3 }}>{fullName} {t('checkIn.canCheckInUntil')} <b>{paymentDeadlineDate.format(DATE_FORMAT)}</b>. {t('checkIn.afterBuy')}</Typography>
                    <Typography variant="h6" gutterBottom sx={{ paddingTop: 3 }}>{t('checkIn.ifYouWantToDo')}</Typography>
                </Grid>}
                {(!userSubscription.Subscription.confirmation && !isAllowedToCheckInWithoutPayment) && <Grid
                    item
                    pt={4}
                    pb={4}
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}
                >
                    <Typography gutterBottom color="error" variant="h6"><b>{userSubscription.Subscription.name}</b> {t('checkIn.paymentDeadlineOf')} <b>{paymentDeadlineDate.format(DATE_FORMAT)}</b> {t('checkIn.reached')}.</Typography>
                    <Typography variant="h6" color="error" gutterBottom>{fullName} {t('checkIn.hasToPayToCheckIn')}</Typography>
                </Grid>}
                <Grid item xs={12} pt={3}>
                    <Grid container display="flex" flexDirection="row" spacing={3}>
                        <Grid item xs={12} md={12} lg={6}>
                            <Button style={{ minHeight: 45 }} onClick={onCancel} fullWidth variant="outlined">
                                {t('checkIn.cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <Button
                                style={{ minHeight: 45 }}
                                onClick={confirmPayment}
                                fullWidth
                                disabled={loading}
                                color={'success'}
                                variant="contained"
                            >
                                {t('checkIn.confirmPay')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}

Confirm.propTypes = {
    cancelText: PropTypes.string,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    confirmText: PropTypes.string,
    titleText: PropTypes.string,
    titleColor: PropTypes.string
}

export default Confirm
