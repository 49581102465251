import { createTheme } from '@mui/material/styles';
import radioButtonTheme from './Radio/radioMui';
import buttonBaseTheme from './ButtonBase/buttonBaseMui';
import checkBoxTheme from './Checkbox/checkboxMui';
import typographyTheme from './Typography/typographyMui';
import formLabelTheme from './FormLabel/formLabelMui';

import {
  ERROR_HOVER_COLOR,
  ERROR_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  LIGHT_PRIMARY_COLOR,
  GRAY_BG_COLOR,
  GRAY_TEXT_COLOR,
  WHITE_COLOR,
  GREEN_COLOR,
  LIGHT_GRAY_BORDER_COLOR,
  YELLOW_CHIP_COLOR
} from '../constants';

let theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR
    },
    secondary: {
      main: SECONDARY_COLOR
    },
    error: {
      main: ERROR_COLOR
    }
  },
  typography: {
    fontFamily: 'Lato, Medium',
    fontSize: 14,
    h1: {
      fontFamily: 'Lato, Medium',
      fontWeight: 500, 
    },
    h2: {
      fontFamily: 'Lato, Medium',
      fontWeight: 500, 
    },
    h3: {
      fontFamily: 'Lato, Medium',
      fontWeight: 500, 
    },
    h4: {
      fontFamily: 'Lato, Medium',
      fontWeight: 500, 
    },
    h5: {
      fontFamily: 'Lato, Medium',
      fontWeight: 500, 
    },
    h6: {
      fontFamily: 'Lato, Medium',
      fontWeight: 500, 
    },
    body1: {
      fontFamily: 'Lato, Medium',
      fontWeight: 400, 
    },
    body2: {
      fontFamily: 'Lato, Medium',
      fontWeight: 400, 
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingTop: 25,
          paddingBottom: 25
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: { borderRadius: 10 }
      }
    },
    MuiRating: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            opacity: 1
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderWidth: 2
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiIconButton-root': {
            marginRight: '2px !important'
          }
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '11.5px 14px',
          '&::placeholder': {
            color: '#9CB2CD'
          }
        },
        root: {
          backgroundColor: LIGHT_PRIMARY_COLOR,
          borderRadius: 4,
          padding: '0px !important',
          '&.Mui-disabled': {
            backgroundColor: GRAY_BG_COLOR,
            color: GRAY_TEXT_COLOR
          },
          '&.Mui-error': {
            color: ERROR_COLOR,
            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${ERROR_COLOR}`
            }
          },
          '&.Mui-focused': {
            '&.Mui-error': {
              color: ERROR_COLOR,
              '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${ERROR_COLOR}`
              }
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${PRIMARY_COLOR}`
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          }
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          paddingLeft: 14
        }
      }
    },
    MuiSelect: {
      styleOverrides: {}
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiMenu-paper': {
            boxShadow: 'none'
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          marginTop: 4,
          borderRadius: 4,
          border: `1px solid ${PRIMARY_COLOR}`
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: '18px !important'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: LIGHT_PRIMARY_COLOR
          },
          ':focus': {
            backgroundColor: WHITE_COLOR,
            color: PRIMARY_COLOR
          },
          ':disabled': {
            color: GRAY_TEXT_COLOR
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          height: 'fit-content',
          boxShadow: '0 13px 33px 0 rgba(41, 40, 56, 0.08)',
          borderRadius: 4
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          width: '100%',
          color: 'black',
          '&.Mui-selected': {
            color: PRIMARY_COLOR,
            borderBottom: `3px solid ${PRIMARY_COLOR}`,
            fontWeight: 'bold'
          },
          borderBottom: `3px solid ${LIGHT_GRAY_BORDER_COLOR}`
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          padding: '11.5px 14px !important'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minHeight: 45,
          borderRadius: '7px',
          fontWeight: 600,
          borderWidth: 2,
          boxShadow: 'none',
          ':hover': {
            borderWidth: 2
          },
          ':disabled': {
            borderWidth: 2
          }
        },
        containedPrimary: {
          backgroundColor: PRIMARY_COLOR,
          ':hover': {
            boxShadow: 'none',
            backgroundColor: SECONDARY_COLOR
          },
          ':disabled': {
            backgroundColor: PRIMARY_COLOR,
            opacity: '40%',
            color: WHITE_COLOR
          }
        },
        outlinedPrimary: {
          backgroundColor: WHITE_COLOR,
          ':disabled': {
            borderColor: PRIMARY_COLOR,
            opacity: '40%',
            color: PRIMARY_COLOR
          }
        },
        containedError: {
          backgroundColor: ERROR_COLOR,
          ':hover': {
            backgroundColor: `${ERROR_HOVER_COLOR} !important`
          },
          ':disabled': {
            backgroundColor: ERROR_COLOR,
            opacity: '40%',
            color: WHITE_COLOR
          }
        },
        outlinedError: {
          borderColor: ERROR_COLOR,
          ':hover': {
            borderColor: `${ERROR_HOVER_COLOR} !important`
          },
          ':disabled': {
            borderColor: ERROR_COLOR,
            opacity: '40%',
            color: WHITE_COLOR
          }
        },
        outlinedSuccess: {
          borderColor: GREEN_COLOR,
          ':hover': {
            borderColor: `${GREEN_COLOR} !important`
          },
          ':disabled': {
            borderColor: GREEN_COLOR,
            opacity: '40%',
            color: WHITE_COLOR
          }
        },
        outlinedWarning: {
          borderColor: YELLOW_CHIP_COLOR,
          ':hover': {
            borderColor: `${YELLOW_CHIP_COLOR} !important`
          },
          ':disabled': {
            borderColor: YELLOW_CHIP_COLOR,
            opacity: '40%',
            color: WHITE_COLOR
          }
        },
        containedSuccess: {
          backgroundColor: GREEN_COLOR,
          ':hover': {
            backgroundColor: `${GREEN_COLOR} !important`
          },
          ':disabled': {
            backgroundColor: GREEN_COLOR,
            opacity: '40%',
            color: WHITE_COLOR
          }
        }
      }
    }
  }
});

theme = createTheme({
  ...radioButtonTheme,
  ...buttonBaseTheme,
  ...checkBoxTheme,
  ...typographyTheme,
  ...formLabelTheme,
  ...theme
});

export default theme;
