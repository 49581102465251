import React from 'react'
import { CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { GRAY_BG_COLOR } from '../../utils/constants'

const SendingButtonChild = () => {
  const { t } = useTranslation()

  return (
    <>
      <CircularProgress style={{ color: GRAY_BG_COLOR, marginRight: 4 }} size={22} />
      {t('button.sending')}
    </>
  )
}

export default SendingButtonChild
