import { Star } from '@mui/icons-material'
import { Rating, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CARD_TEXT_COLOR, PRIMARY_COLOR } from './constants';


const RenderReviews = ({ reviews, rating, reviewsAverage }) => {
    const { t } = useTranslation()
    let steps = [];
    for (let i = 0; i < reviewsAverage; i++) {
        steps.push(i + 1);
    }

    return (
        <>
            {rating ?
                <Rating value={reviewsAverage} disabled sx={{ color: PRIMARY_COLOR }} />
                :
                <>{
                    steps.map((_, index) => (
                        <Star key={index} color="primary" />
                    ))
                }</>
            }

            <Typography pl={2} fontWeight="bold" color="black" display="flex" flexDirection="row">
                {reviewsAverage}
                <strong style={{ color: CARD_TEXT_COLOR }}>
                    /5 ({reviews.length} {t('userProfile.activity.reviews').toLocaleLowerCase()})
                </strong>
            </Typography>
        </>
    );
}
export default RenderReviews;