import React, { useState, useEffect } from 'react'
import { Grid, Card } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Tabs } from '../../common'
import Activities from './subcomponents/Activities'

import { LIGHT_GRAY_BORDER_COLOR } from '../../../utils/constants'

import * as COMPANY_REQUESTS from '../../../api/company'
import * as COURSE_REQUEST from '../../../api/course'
import * as TRAINER_REQUESTS from '../../../api/trainer'

import { useAuthentication } from '../../../context/auth-context'
import NewDeals from '../../NewDeals'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
    flexGrow: 1
  }
})

const BusinessActivity = ({ type, trainer }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useAuthentication()
  const location = useLocation()
  const navigate = useNavigate()

  const [activities, setActivities] = useState([])
  const [activitiesUntouched, setActivitiesUntouched] = useState([])
  const [typeComponent, setTypeComponent] = useState()
  const [filters, setFilters] = useState({
    active: 'all',
    public: 'all'
  })
  const [activeTab, setActiveTab] = useState(undefined)

  useEffect(() => {
    if (type)
      setTypeComponent(type)
  }, [type])

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    })
    const activeOption = params['active_option']
    if (activeOption) setActiveTab(Number(activeOption))
    else setActiveTab(0)
  }, [])

  useEffect(() => {
    setActivities(() => {
      let activitiesCopy = [...activitiesUntouched]
      activitiesCopy = activitiesCopy.filter((el) => {
        if (filters.active !== 'all' && filters.public !== 'all')
          return el.isActive === filters.active && el.isPublic === filters.public
        if (filters.active !== 'all' && filters.public === 'all')
          return el.isActive === filters.active
        if (filters.active === 'all' && filters.public !== 'all')
          return el.isPublic === filters.public

        return true
      })

      return activitiesCopy
    })
  }, [filters])

  const handleGetAllActivities = () => {
    typeComponent && typeComponent === 'Organization' ? null :  typeComponent === 'Company' ? COMPANY_REQUESTS.getAllCourses(user.companyId).then((res) => {
      const mappedActivities = res.map((el) => ({
        id: el.id,
        name: el.title,
        description: el.description,
        priceRon: el.priceRon,
        currency: el.Company.currency,
        isFree: el.isFree,
        trainerName: el.Trainer ? `${el.Trainer.firstName} ${el.Trainer.lastName}` : null,
        Reviews: [],
        handleExpand,
        expand: el.expand,
        isActive: el.isActive,
        isPublic: el.isPublic,
        Trainers: el.Trainers
      }))
      setActivities(mappedActivities)
      setActivitiesUntouched(mappedActivities)
    })
      :
      TRAINER_REQUESTS.getTrainerCourses(trainer.companyId).then((res) => {
        const mappedServices = res.map((el) => ({
          id: el.id,
          name: el.title,
          description: el.description,
          priceRon: el.priceRon,
          currency: el.Trainer.trainerCurrency,
          isFree: el.isFree,
          trainerName: el.Trainer ? `${el.Trainer.firstName} ${el.Trainer.lastName}` : null,
          Reviews: [],
          handleExpand,
          expand: el.expand,
          isActive: el.isActive,
          isPublic: el.isPublic,
          Trainers: el.Trainers
        }))
        setActivities(mappedServices)
        setActivitiesUntouched(mappedServices)
      })

  }

  useEffect(() => {
    if (activeTab === 0) {
      handleGetAllActivities()
    }
  }, [activeTab, typeComponent])

  const handleExpand = (id) => {
    setActivities((prevValue) => {
      let prevValueCopy = [...prevValue]
      const idPosition = prevValueCopy.findIndex((f) => f.id === id)

      if (idPosition < 0) return prevValueCopy

      prevValueCopy[idPosition].expand = !prevValueCopy[idPosition].expand

      return prevValueCopy
    })
  }

  const handleSearch = (event) => {
    setActivities(
      activitiesUntouched.filter((el) =>
        el.name.toLowerCase().includes(event.target.value.toLowerCase())
      )
    )
  }

  const handleFilters = (name, value, type) => {
    if (type !== 'subscription') {
      setFilters((prevValue) => {
        const prevValueCopy = {
          ...prevValue,
          [name]: value
        }

        return prevValueCopy
      })
    }
  }

  const handleOnDeleteActivities = (id) => {
    typeComponent && typeComponent === 'Company' ? COURSE_REQUEST.deleteCourse(id).then(() => {
      handleGetAllActivities()
    }) : TRAINER_REQUESTS.deleteCourse(id).then(() => {
      handleGetAllActivities()
    })

  }

  if (activeTab === undefined) return null
  const options = typeComponent !== 'Organization' ? [
    {
      label: t('userProfile.courses'),
      value: (
        <Activities
          type={typeComponent}
          handleFilters={handleFilters}
          handleSearch={handleSearch}
          handleGetAllActivities={handleGetAllActivities}
          handleOnDelete={handleOnDeleteActivities}
          Activities={activities}
          filters={filters}
        />
      )
    },
    {
      label: t('userProfile.deals'),
      value: (
        <NewDeals
          type={typeComponent}
        />
      )
    }
  ] : [
    {
      label: t('userProfile.deals'),
      value: (
        <NewDeals
          type={typeComponent}
        />
      )
    }
  ]
  return (
    <Grid container justifyContent="flex-end" height="100%">
      <Grid className={classes.content} component={Card} item sm={12} xl={12}>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => {
            const to = { pathname: location.pathname, search: `active_option=${newValue}` }
            navigate(to, { replace: true })
            setActiveTab(newValue)
          }}
          options={options}
        />
      </Grid>
    </Grid>
  )
}

export default BusinessActivity
