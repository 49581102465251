import * as React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Avatar } from '@mui/material'
import { Pause, Check, Close, Delete } from '@mui/icons-material'

import { BUSINESS_USER_REQUEST_STATUS } from '../../../utils/constants'

const Status = ({ status }) => {
    if (status === BUSINESS_USER_REQUEST_STATUS.ACCEPTED) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Check sx={{ mr: 1 }} />  {status}
            </Box>
        )
    }

    if (status === BUSINESS_USER_REQUEST_STATUS.DECLINED) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Close sx={{ mr: 1 }} />  {status}
            </Box>
        )
    }

    if (status === BUSINESS_USER_REQUEST_STATUS.DELETED) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Delete sx={{ mr: 1 }} />  {status}
            </Box>
        )
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Pause sx={{ mr: 1 }} />  {status}
        </Box>
    )
}

export default ({ data }) => {

    return (
        <TableContainer>
            <Table sx={{ minWidth: 650 }} size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Avatar</TableCell>
                        <TableCell >Nume</TableCell>
                        <TableCell >Numar Telefon</TableCell>
                        <TableCell >Email</TableCell>
                        <TableCell >Status</TableCell>
                        <TableCell align="right">Creat La</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((request) => (
                        <TableRow
                            key={request.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {request.id}
                            </TableCell>
                            <TableCell><Avatar sizes='small' variant="rounded" src={request.User.avatarPhotoSrc}>{request.User.firstName.charAt(0)} {request.User.lastName.charAt(0)}</Avatar></TableCell>
                            <TableCell >{request.User.firstName} {request.User.lastName}</TableCell>
                            <TableCell >{request.User.phoneNumber}</TableCell>
                            <TableCell >{request.User.email}</TableCell>
                            <TableCell ><Status status={request.status} /></TableCell>
                            <TableCell align="right">{request.createdAt}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}