import axios from './axios'

export const findAll = () => {
  return axios.get(`/equipments`).then((res) => res.data)
}

export const create = (payload) => {
  return axios.post(`/equipments`, { ...payload }).then((res) => res.data)
}

export const remove = (id) => {
  return axios.delete(`/equipments/${id}`).then((res) => res.data)
}
