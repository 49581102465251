import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from "@mui/material";
import CardAdsOns from './card';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const AdsOns = ({ selectedIndices, setSelectedIndices, cards }) => {

    return (
        <Grid display={'flex'} flexDirection={'column'} pl={{ lg: '110px', md: '20px' }} justifyContent={'center'} sx={{ marginTop: '10%' }}>

            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Typography sx={{ fontWeight: 'bold' }} variant="h6" gutterBottom>
                    Customize your plan with additional features
                </Typography>
                <QuestionMarkIcon sx={{ color: '#fff', backgroundColor: '#000', borderRadius: '10px', marginLeft: '10px', fontSize: '20px', marginBottom: '8px' }} />
            </Box>

            <Typography color={'#707070'} variant="subtitle1" gutterBottom>
                Add your card details here and start using SportIn today. No need to talk to our sales team.
            </Typography>
            <Box sx={{
                width: { lg: '90%', ms: '90' }, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90%'
            }}>
                <Box
                    sx={{ width: '100%', marginTop: '40px', marginBottom: '30px' }}
                >
                    <Grid container display={'flex'} justifyContent={'space-between'} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: -3 }}>
                        {cards.map((el, index) => (
                            <Grid item md={6} lg={6} xs={12} key={index}>
                                <CardAdsOns
                                    index={index}
                                    selectedIndices={selectedIndices}
                                    setSelectedIndices={setSelectedIndices}
                                    title={el.title}
                                    subtitle={el.subtitle}
                                    content={el.content}
                                    price={el.price}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </Grid>
    );
};

export default AdsOns;
