import React, { useEffect, useState } from 'react'

import Deals from './subcomponents/Deals'

import * as COMPANY_REQUESTS from '../../api/company'
import * as ORGANIZATION_REQUESTS from '../../api/organization'
import { refetchBusinessProfileObservable } from '../../utils/observables'
import { useAuthentication } from '../../context/auth-context'

const NewDeals = ({ type }) => {
  const refetchBusinessProfile = refetchBusinessProfileObservable.useRefetchBusinessProfile()
  const { user } = useAuthentication()

  const [company, setCompany] = useState()


  const getCompany = () => {
    type === 'Company' ? COMPANY_REQUESTS.getBusiness(user?.companyId).then(setCompany) : user.role === 'ORGANIZATION' ? ORGANIZATION_REQUESTS.getOrganization(user.organizationId).then(setCompany) : setCompany(user);
  }

  useEffect(() => {
    getCompany()
  }, [user, type])

  useEffect(() => {
    if (!refetchBusinessProfile) return
    getCompany()
  }, [refetchBusinessProfile])

  if (!company) return

  return (
    <Deals type={type} trainer={company} />
  )
}

export default NewDeals
