import React, { useCallback, useMemo } from 'react'
import { Grid, Typography, Tooltip, Chip } from '@mui/material'
import { Info } from '@mui/icons-material'

import {
  CARD_TEXT_COLOR,
  CHIP_BLUE_COLOR,
  EVENT_COLORS,
  LIGHT_PRIMARY_COLOR,
  PRIMARY_COLOR,
  ERROR_COLOR,
  RED_CHIP_COLOR,
} from '../../../utils/constants'

import { CalendarItem } from '.'
import { InputGenerator, Toggle } from '../../common'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const CalendarEventDetails = ({
  inputs,
  disabled,
  eventDetails,
  editing,
  selectedDesign,
  selectedColor,
  handleEnterPressed,
  handleOnDetailsInputChanged,
  handleOnSelectedColorChanged,
  handleOnDeleteCustomSelect,
  eventParticipants,
  design
}) => {

  const { t } = useTranslation()

  const getInputData = useCallback((inputName, field, fromArray, position) => {
    const inputPosition = inputs.findIndex((el) => el.name === inputName)
    if (inputPosition < 0) return ''

    return fromArray ? inputs[inputPosition][field][position] : inputs[inputPosition][field]
  }, [inputs])

  const createLabelAndInfo = useCallback(
    (inputName, withoutPadding) => {
      const inputPosition = inputs.findIndex((el) => el.name === inputName)
      if (inputPosition < 0) return null

      return (
        <Grid container display="flex" flexDirection="row" pb={withoutPadding ? 0 : 1}>
          <Grid item pr={0.5}>
            <Typography fontWeight={500} variant="p">
              {inputs[inputPosition].label}
            </Typography>
          </Grid>
          {inputs[inputPosition].mandatory ? (
            <Grid item pr={0.5}>
              <Typography fontWeight={500} variant="p" color="primary">
                *
              </Typography>
            </Grid>
          ) : null}
          {inputs[inputPosition].infoMessage ? (
            <Grid item pl={0.5} pt={0.3}>
              <Tooltip title={inputs[inputPosition].infoMessage} arrow placement="top-start">
                <Info sx={{ color: CARD_TEXT_COLOR }} fontSize="14px" />
              </Tooltip>
            </Grid>
          ) : null}
        </Grid>
      )
    },
    [inputs]
  )

  const computeRenderValue = useCallback((selectedValues, label) => {
    return !selectedValues.length ? <Typography color={"#9CB2CD"}>{t('calendar.selectAValue')}</Typography> : `${selectedValues.length} ${label} selected`
  }, [])

  const createSimpleInput = useCallback(
    (inputName) => {
      const inputPosition = inputs.findIndex((el) => el.name === inputName)
      if (inputPosition < 0) return null
      let maxParticipantsError = false
      const minParticipantsIndex = inputs.findIndex(el => el.name === 'minParticipants')

      const errorBasedOnMinParticipants = (Number(inputs[inputPosition].value) && (Number(inputs[inputPosition].value) < Number(inputs[minParticipantsIndex].value)))
      const errorBasedOnAlreadyExistingParticipants = (Number(inputs[inputPosition].value) && eventParticipants && (eventParticipants.map(el => el.Events_Participants).filter(el => !el.isInWaitingList).length > Number(inputs[inputPosition].value)))
      if (inputName === 'maxParticipants') {
        maxParticipantsError = errorBasedOnMinParticipants || errorBasedOnAlreadyExistingParticipants
      }

      let inputToUse = {
        name: inputName,
        type: inputs[inputPosition].type,
        placeholder: inputs[inputPosition].placeholder,
        value: inputs[inputPosition].value,
        defaultValue: inputs[inputPosition].value,
        maxLength: inputs[inputPosition].maxLength,
        values: inputs[inputPosition].values,
        disabled: inputs[inputPosition].disabled || (disabled && (inputName === 'access' || inputName === 'cost' || inputName === 'value' || inputName === 'payment')),
        multiple: inputs[inputPosition].multiple,
        endAdornmentComponent: inputs[inputPosition].endAdornmentComponent,
        displayEmpty: true
      }

      if (inputs[inputPosition].multiple) inputToUse = {
        ...inputToUse,
        renderValue: (selectedValues) => computeRenderValue(selectedValues, inputs[inputPosition].label)
      }

      if (inputs[inputPosition].name === 'value') inputToUse = {
        ...inputToUse,
        inputProps: {
          pattern: "[0-9]*",
        }
      }

      return (
        <InputGenerator
          handleOnChange={e => {
            if (e.target.name === 'subscriptions') {
              if ((eventDetails && eventDetails.EventSubscriptions.length > e.target.value.length) && disabled && editing) {
                toast.error("You cannot deleted selected subscriptions", { position: 'bottom-right' })
                return
              }
            }
            handleOnDetailsInputChanged(e)
          }}
          handleEnterPressed={handleEnterPressed}
          handleOnChangeGoogle={(googleData) =>
            handleOnDetailsInputChanged({ target: { name: inputName, value: googleData.label } })
          }
          error={inputName === 'maxParticipants' && maxParticipantsError}
          helperText={maxParticipantsError ? `${t('calendar.cantBeLower')} ${errorBasedOnMinParticipants ? inputs[minParticipantsIndex].value : eventParticipants.map(el => el.Events_Participants).filter(el => !el.isInWaitingList).length}` : ''}
          input={inputToUse}
        />
      )
    },
    [handleOnDetailsInputChanged, editing, handleEnterPressed, disabled, eventParticipants, computeRenderValue, inputs]
  )

  const createCustomSelectValues = useCallback(
    (inputName) => {
      const inputPosition = inputs.findIndex((el) => el.name === inputName)
      if (inputPosition < 0) return null

      return (
        <Grid container display="flex" flexDirection="row" pt={2}>
          {inputs[inputPosition].values.map((el) => (
            <Grid item>
              <Chip
                sx={{
                  backgroundColor: CHIP_BLUE_COLOR,
                  '& .MuiChip-deleteIcon': {
                    color: ERROR_COLOR,
                    '&:hover': {
                      color: RED_CHIP_COLOR
                    }
                  }
                }}
                label={el}
                onDelete={() => handleOnDeleteCustomSelect(inputName, el)}
              />
            </Grid>
          ))}
        </Grid>
      )
    },
    [handleOnDetailsInputChanged, inputs]
  )

  const createToggleInput = useCallback(
    (inputName) => {
      const inputPosition = inputs.findIndex((el) => el.name === inputName)
      if (inputPosition < 0) return null

      return (
        <Grid
          container
          display="flex"
          flexDirection="row"
          sx={{ backgroundColor: LIGHT_PRIMARY_COLOR, borderRadius: '4px' }}
          p={1.3}
        >
          <Grid item>
            <Typography>{createLabelAndInfo(inputName, true)}</Typography>
          </Grid>
          <Grid item ml={'auto'}>
            <Toggle
              color="primary"
              defaultChecked={inputs[inputPosition].value}
              onClick={() =>
                handleOnDetailsInputChanged({
                  target: { name: inputName, value: !inputs[inputPosition].value }
                })
              }
            />
          </Grid>
        </Grid>
      )
    },
    [inputs, disabled]
  )

  const renderLeftInputs = useMemo(() => {
    return (
      <Grid item lg={6} xs={12} pr={{ lg: 3, xs: 0 }}>
        <Grid container display="flex" flexDirection="row" pb={{ lg: 6, xs: 2 }}>
          <Grid item lg={12} xs={12}>
            {createLabelAndInfo('name')}
            {createSimpleInput('name')}
          </Grid>
        </Grid>
        <Grid container display="flex" flexDirection="row" pb={{ lg: 6, xs: 2 }}>
          <Grid item lg={6} xs={12} pr={{ lg: 1, xs: 0 }} pb={{ xs: 1, lg: 0 }}>
            {createLabelAndInfo('sportType')}
            {createSimpleInput('sportType')}
          </Grid>
          <Grid item lg={6} xs={12} pl={{ lg: 1, xs: 0 }} pt={{ xs: 1, lg: 0 }}>
            {createLabelAndInfo('equipment')}
            {createSimpleInput('equipment')}
          </Grid>
        </Grid>
        <Grid container display="flex" flexDirection="row" pb={{ lg: 6, xs: 2 }}>
          <Grid item lg={12} xs={12}>
            {createLabelAndInfo('location')}
            {createSimpleInput('location')}
          </Grid>
        </Grid>
        <Grid container display="flex" flexDirection="row" pb={{ lg: 6, xs: 2 }}>
          <Grid item lg={6} xs={12} pr={{ lg: 1, xs: 0 }} pb={{ xs: 1, lg: 0 }}>
            {createLabelAndInfo('minParticipants')}
            {createSimpleInput('minParticipants')}
          </Grid>
          <Grid item lg={6} xs={12} pl={{ lg: 1, xs: 0 }} pt={{ xs: 1, lg: 0 }} height={75}>
            {createLabelAndInfo('maxParticipants')}
            {createSimpleInput('maxParticipants')}
          </Grid>
        </Grid >
        <Grid container display="flex" flexDirection="row" pb={{ lg: 6, xs: 2 }}>
          <Grid item lg={12} xs={12}>
            {createLabelAndInfo('tags')}
            {createSimpleInput('tags')}
            {createCustomSelectValues('tags')}
          </Grid>
        </Grid>
      </Grid >
    )
  }, [inputs])

  const renderRightInputs = useMemo(() => {
    return (
      <Grid item lg={6} xs={12} pl={{ lg: 3, xs: 0 }}>
        <Grid container display="flex" flexDirection="row" pb={{ lg: 6, xs: 2 }}>
          <Grid item lg={6} xs={12} pr={{ lg: 1, xs: 0 }} pb={{ xs: 1, lg: 0 }}>
            {createLabelAndInfo('availability')}
            {createSimpleInput('availability')}
          </Grid>
          <Grid item lg={6} xs={12} pl={{ lg: 1, xs: 0 }} pt={{ xs: 1, lg: 0 }}>
            {createLabelAndInfo('access')}
            {createSimpleInput('access')}
          </Grid>
        </Grid>
        <Grid container display="flex" flexDirection="row" pb={{ lg: 6, xs: 2 }}>
          <Grid item lg={6} xs={12} pr={{ lg: 1, xs: 0 }} pb={{ xs: 1, lg: 0 }}>
            {createLabelAndInfo('subscriptions')}
            {createSimpleInput('subscriptions')}
          </Grid>
          <Grid item lg={6} xs={12} pl={{ lg: 1, xs: 0 }} pt={{ xs: 1, lg: 0 }}>
            {createLabelAndInfo('cost')}
            {createSimpleInput('cost')}
          </Grid>
        </Grid >
        <Grid container display="flex" flexDirection="row" pb={{ lg: 6, xs: 2 }}>
          <Grid item lg={6} xs={12} pr={{ lg: 1, xs: 0 }} pb={{ xs: 1, lg: 0 }}>
            {createLabelAndInfo('value')}
            {createSimpleInput('value')}
          </Grid>
          <Grid item lg={6} xs={12} pl={{ lg: 1, xs: 0 }} pt={{ xs: 1, lg: 0 }}>
            {createLabelAndInfo('payment')}
            {createSimpleInput('payment')}
          </Grid>
        </Grid >
        <Grid container display="flex" flexDirection="row" pb={{ lg: 6, xs: 2 }}>
          <Grid item lg={getInputData('withWaitingList', 'value') ? 6 : 12} xs={12} pr={{ lg: 1, xs: 0 }} pt={{ lg: 4.5, xs: 2 }} pb={{ xs: 1, lg: 0 }}>
            {createToggleInput('withWaitingList')}
          </Grid>
          {getInputData('withWaitingList', 'value') ? <Grid item lg={6} xs={12} pl={{ lg: 1, xs: 0 }} pt={{ xs: 1, lg: 0 }}>
            {createLabelAndInfo('maxWaitingList')}
            {createSimpleInput('maxWaitingList')}
          </Grid> : null}
        </Grid >
        <Grid container display="flex" flexDirection="row" pb={{ lg: 6, xs: 2 }}>
          <Grid item lg={12} xs={12} pt={{ lg: 4.2, xs: 2 }}>
            {createToggleInput('showParticipantsName')}
          </Grid>
        </Grid>
      </Grid >
    )
  }, [inputs])



  const renderEventItemPreview = useMemo(() => {
    return (
      <Grid
        container
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item lg={12} xs={12}>
          <CalendarItem
            item={{
              title: getInputData('name', 'value') || 'Event Name',
              firstChip: getInputData('tags', 'values', true, 0),
              secondChip: getInputData('tags', 'values', true, 1),
              thirdChip: getInputData('tags', 'values', true, 2),
              start: '12:00',
              duration: 70,
              custom: selectedDesign === 'DETAILED',
              backgroundColor: selectedColor,
              participants: Array.from({ length: 5 }).map((_, idx) => idx),
              maxParticipants: getInputData('maxParticipants', 'value'),
              minParticipants: getInputData('minParticipants', 'value'),
              withWaitingList: getInputData('withWaitingList', 'value'),
              maxWaitingList: getInputData('maxWaitingList', 'value'),
              inWaitingList: 2,
              value: 0,
              payment: '',
              public: getInputData('access', 'value'),
              trainers: [],
              trainer: null,
              company: { domain: '' },
              avatar:
                selectedDesign !== 'DETAILED'
                  ? 'https://i.kinja-img.com/gawker-media/image/upload/t_original/ijsi5fzb1nbkbhxa2gc1.png'
                  : '',
              backgroundImage: getInputData('sportType', 'value')
            }}
          />
        </Grid>
        {selectedDesign === 'COMPACT' ? (
          <Grid item lg={12}>
            <Grid container display="flex" flexDirection="row">
              {EVENT_COLORS.map((color, index) => (
                <Grid
                  onClick={() => handleOnSelectedColorChanged(color)}
                  item
                  key={index}
                  ml={1}
                  mr={1}
                  sx={{
                    width: 20,
                    height: 20,
                    borderRadius: '50%',
                    cursor: 'pointer',
                    border: color === selectedColor ? `2px solid ${PRIMARY_COLOR}` : null,
                    backgroundColor: color
                  }}
                />
              ))}
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    )
  }, [inputs, selectedColor, getInputData, selectedDesign])

  const renderDetails = useMemo(() => {
    return (
      <Grid container display="flex" flexDirection="column" pt={{ lg: 6, xs: 2 }} pb={{ lg: 6, xs: 2 }} minHeight={'65vh'}>
        <Grid item lg={12} xs={12}>
          <Grid container display="flex" flexDirection="row">
            {renderLeftInputs}
            {renderRightInputs}
          </Grid>
        </Grid>
        {design && <Grid item lg={12} xs={12}>
          {renderEventItemPreview}
        </Grid>}
      </Grid>
    )
  }, [inputs, design, selectedColor, getInputData, selectedDesign])

  return renderDetails
}

export default CalendarEventDetails
