import React, { useEffect, useState } from 'react'
import { Typography, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Subscription from '../../../UserProfile/subcomponents/subcomponents/subcomponents/Subscription'

import { useAuthentication } from '../../../../context/auth-context'
import * as USER_REQUESTS from '../../../../api/user'

import { getStatus } from '../../../../utils/helpers/userSubscription'

export default () => {
    const { t } = useTranslation()

    const [subscriptions, setSubscriptions] = useState([])

    useEffect(() => {
        USER_REQUESTS.getSubscriptions()
            .then(userSubscriptions => {
                setSubscriptions(userSubscriptions.filter(userSubscription => getStatus(userSubscription).active))
            })
    }, [])

    return (
        <Grid container>
            <Grid item xs={12} paddingBottom={1}>
                <Typography variant="p" fontWeight={'bold'} fontSize={20}>
                    {t('helper.subscriptions')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    {subscriptions.length > 0 ? (
                        subscriptions.map((el, index) => {
                            return (
                                <Grid key={index} item pb={4} xs={12}>
                                    <Subscription
                                        {...el}
                                        id={el.id}
                                        onPauseSubscriptionHandler={() => { }}
                                        isTrainer={!el.Subscription.companyId}
                                        pauseEnabled={el.Subscription.pauseSubscription}
                                        pauseMinimumDays={el.Subscription.minimumPauseDays}
                                        pauseMaximumDays={el.Subscription.maximumPauseDays}
                                        gymName={
                                            el.Subscription.Company
                                                ? el.Subscription.Company.name
                                                : `${el.Subscription.Trainer.firstName} ${el.Subscription.Trainer.lastName}`
                                        }
                                        paymentFinalDate={el.validUntil}
                                        gymSubscriptionDetails={el.Subscription.description}
                                        price={el.Subscription.priceRon}
                                        gymSubscriptionName={el.Subscription.name}
                                        handleOnCancel={() => { }}
                                        dashboard={true}
                                        currency={el.Payment.currency}
                                    />
                                </Grid>
                            )
                        })
                    ) : (
                        <Typography color="#979797" fontWeight={500} fontSize={17}>
                            {t('helper.noResultsFound')}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}