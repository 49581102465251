import React, { useEffect, useState } from 'react'
import { Grid, Typography, Container, CircularProgress, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import LastMonthCompare from './LastMonthCompare'
import SubscriptionsSold from './SubscriptionsSold'
import Traffic from './Traffic'
import TopUsers from './TopUsers'
import ActiveUsers from './ActiveUsers'
import TrainersCredits from './TrainersCredits'
import TrainersCreditsServices from './TrainersCreditsServices'
import Collections from './Collections'
import Costs from './Costs'
import * as COMPANY_REQUESTS from '../../api/company'

const Reports = () => {
    const [currency, setCurrency] = useState('RON')
    const [loading, setLoading] = useState(true)
    const { t } = useTranslation()

    useEffect(() => {
        getCompanyCurrency()
    }, [])

    const getCompanyCurrency = async () => {
        try {
            setLoading(true)
            const currency = await COMPANY_REQUESTS.getCompanyCurrency()
            setCurrency(currency?.currency)
        } catch (error) {
            console.error(`Error getting company currency: ${error}`)
        } finally {
            setLoading(false)
        }
    }

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="50vh"
            >
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Container maxWidth="xl">
            <Grid container pt={8}>
                <Grid item xs={12} paddingBottom={2} display="flex" flexDirection="column">
                    <Typography variant="h5" fontWeight="bold" pb={2} pl={1}>{t('reports.reports')}</Typography>
                </Grid>
                <Grid item xs={12} pb={4}>
                    <LastMonthCompare currency={currency} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item xs={12} lg={6} pr={{ xs: 0, lg: 1 }}>
                            <Collections />
                        </Grid>
                        <Grid item xs={12} lg={6} pl={{ xs: 0, lg: 1 }}>
                            <Costs />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} pt={4}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item xs={12} lg={6} pr={{ xs: 0, lg: 1 }}>
                            <SubscriptionsSold />
                        </Grid>
                        <Grid item xs={12} lg={6} pl={{ xs: 0, lg: 1 }}>
                            <Traffic />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} pt={4}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item xs={12} lg={6} pr={{ xs: 0, lg: 1 }}>
                            <TopUsers />
                        </Grid>
                        <Grid item xs={12} lg={6} pl={{ xs: 0, lg: 1 }}>
                            <ActiveUsers />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} pt={4}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item xs={12} lg={6} pr={{ xs: 0, lg: 1 }}>
                            <TrainersCreditsServices />
                        </Grid>
                        <Grid item xs={12} lg={6} pl={{ xs: 0, lg: 1 }}>
                            <TrainersCredits />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Reports
