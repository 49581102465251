import React, { useCallback, useEffect, useState } from 'react'
import { Grid, TextField, Box, Button, Typography, FormControl, Select, MenuItem, Avatar, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { CreditCard, Money, AccountBalance, Percent, CreditScore, PendingActions } from '@mui/icons-material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { CSVLink } from "react-csv"

import { Table, InputGenerator } from '../common'
import SendPaymentsModal from './SendPayments'

import * as SUPER_ADMIN_REQUESTS from '../../api/superAdmin'
import { numberFomatter } from '../../utils/helpers/numberFomatter'
import { uniqueArray } from '../../utils/helpers/uniqueArray'
import { PRIMARY_COLOR, PAYMENT_METHOD, ERROR_COLOR } from '../../utils/constants'
import SuperAdminGenerateInvoice from './SuperAdminGenerateInvoice'
import { GREEN_COLOR } from '../../utils/constants'

const SuperAdminPayments = () => {
    const { t } = useTranslation()

    const [showSendPayments, setShowSendPayments] = useState(false)
    const [payments, setPayments] = useState([])
    const [untouchedPayments, setUntouchedPayments] = useState([])
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [paymentMethod, setPaymentMethod] = useState('CARD')
    const [paymentState, setPaymentState] = useState('DEPOSITED')
    const [exportDay, setExportDay] = useState(moment())
    const [exportEndDay, setExportEndDay] = useState(moment())
    const [showGenerateInvoice, setShowGenerateInvoice] = useState(false)

    const renderPaymentMethod = (method, isBt) => {
        if (method === PAYMENT_METHOD.CARD && !isBt) {
            return <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <Avatar variant="square" src={require('../../assets/pos.png')} style={{ width: 25, height: 25, paddingRight: 1 }} /> {t('subscriptionModal.pos')}
            </Box>
        }

        if (method === PAYMENT_METHOD.CARD && isBt) {
            return <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <CreditCard sx={{ paddingRight: 1 }} color='success' /> {t('subscriptionModal.card')}
            </Box>
        }


        if (method === PAYMENT_METHOD.BANK_TRANSFER) {
            return <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <AccountBalance sx={{ paddingRight: 1 }} color="success" /> {t('subscriptionModal.BANK_TRANSFER')}
            </Box>
        }

        return <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <Money sx={{ paddingRight: 1 }} color="success" /> {t('subscriptionModal.cash')}
        </Box>

    }

    const handleGetPayments = useCallback(async () => {
        try {
            const paymentsResp = await SUPER_ADMIN_REQUESTS.getPayments(exportDay, exportEndDay, 'day', paymentMethod, paymentState)
            setPayments(paymentsResp.rows)
            setUntouchedPayments(paymentsResp.rows)
        } catch (e) {
            console.error(e)
        }
    }, [exportDay, exportEndDay, paymentMethod, paymentState])

    const handleOnChange = (value) => {
        setSelectedCompanies(value)
        setPayments(untouchedPayments.filter(el => value.includes(el.companyId)))
    }

    useEffect(() => {
        handleGetPayments()
    }, [exportDay, exportEndDay, paymentMethod, paymentState])

    const computeRenderValue = useCallback((selectedValues) => {
        return !selectedValues.length ? <Typography color='#323E4A' >Select Companies</Typography> : `${selectedValues.length} Companies selected`
    }, [])

    const totalPeriodFull = payments.map(payment => {
        const amount = Number(payment.value)

        return amount
    }).reduce((prev, cur) => prev + cur, 0)

    const totalPeriod = payments.map(payment => {
        const amount = Number(payment.value)
        const valueWithCommission = payment.paymentOrderId && payment.Company ? amount - (amount * (payment.Company.commission) / 100) : amount

        return valueWithCommission
    }).reduce((prev, cur) => prev + cur, 0)
    const totalPeriodBT = ((0.9 / 100) * (totalPeriodFull))
    const totalPeriodUs = totalPeriodFull - totalPeriod - totalPeriodBT

    return (
        <Box>
            {showSendPayments ? <SendPaymentsModal
                payments={payments}
                onCancel={() => setShowSendPayments(false)}
                startDate={exportDay}
                endDate={exportEndDay}
                companyIds={selectedCompanies}
            /> : null}
            {showGenerateInvoice && <SuperAdminGenerateInvoice handleClose={() => setShowGenerateInvoice(false)} />}
            <Grid
                container
                pt={4}
                paddingLeft={{ xs: 1 }}
                paddingRight={{ xs: 1 }}
                display="flex"
                flexDirection="column"
                width="100%"
            >
                <Grid item xs={12}>
                    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                        <Box minWidth={300}>
                            <InputGenerator
                                input={{
                                    name: 'companies',
                                    label: 'Companies',
                                    infoMessage: 'Companies',
                                    value: selectedCompanies,
                                    values: uniqueArray(untouchedPayments, 'companyId').filter(el => el.companyId).map(el => ({
                                        value: el.Company.id,
                                        label: el.Company.name
                                    })),
                                    multiple: true,
                                    type: 'select',
                                    placeholder: 'Companies',
                                    renderValue: (selectedValues) => computeRenderValue(selectedValues)
                                }}
                                handleOnChange={event => {
                                    handleOnChange(event.target.value)
                                }}
                            />
                        </Box>
                        <Box ml={{ xs: 0, md: 1 }}>
                            <Typography variant="p">Payment Method</Typography>
                            <FormControl sx={{ pt: 1, width: '100%' }}>
                                <Select
                                    sx={{ width: '100%' }}
                                    defaultValue={paymentMethod}
                                    value={paymentMethod}
                                    onChange={(event) => { setPaymentMethod(event.target.value) }}
                                >
                                    <MenuItem value="">
                                        <em>ALL</em>
                                    </MenuItem>
                                    <MenuItem value={'CARD'}>CARD</MenuItem>
                                    <MenuItem value={'CASH'}>CASH</MenuItem>
                                    <MenuItem value={'BANK_TRANSFER'}>BANK_TRANSFER</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box ml={{ xs: 0, md: 1 }}>
                            <Typography variant="p">Payment State</Typography>
                            <FormControl sx={{ pt: 1, width: '100%' }}>
                                <Select
                                    defaultValue={paymentState}
                                    value={paymentState}
                                    onChange={(event) => { setPaymentState(event.target.value) }}
                                >
                                    <MenuItem value="">
                                        <em>ALL</em>
                                    </MenuItem>
                                    <MenuItem value={'DEPOSITED'}>DEPOSITED</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box pt={{ xs: 2, lg: 3.5 }} pl={{ xs: 0, lg: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    inputFormat="MM/dd/yyyy"
                                    value={exportDay}
                                    onChange={(newValue) => setExportDay(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            sx={{
                                                '.MuiIconButton-root': { marginRight: 1 }
                                            }}
                                            {...params}
                                        />
                                    )} />
                            </LocalizationProvider>
                        </Box>
                        <Box pt={{ xs: 2, lg: 3.5 }} pl={{ xs: 0, lg: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    inputFormat="MM/dd/yyyy"
                                    value={exportEndDay}
                                    onChange={(newValue) => setExportEndDay(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            sx={{
                                                '.MuiIconButton-root': { marginRight: 1 }
                                            }}
                                            {...params}
                                        />
                                    )} />
                            </LocalizationProvider>
                        </Box>
                        <Box pt={3.5} marginLeft={{ xs: 0, md: 'auto' }}>
                            <CSVLink
                                data={payments.filter(payment => payment.paymentOrderId && payment.paymentState === 'DEPOSITED' && payment.paymentMethod === 'CARD').map(el => {
                                    const amount = Number(el.value)
                                    const valueWithCommission = el.paymentOrderId && el.Company ? amount - (amount * (el.Company.commission) / 100) : amount
                                    return {
                                        date: moment(exportDay).format('DDMMYY'),
                                        name: el.Company.legalName,
                                        cui: el.Company.cui,
                                        iban: el.Company.bankAccount,
                                        bank: el.Company.bankName,
                                        street: 'Comuna Valcau de Jos nr.21',
                                        town: 'Valcau de Jos',
                                        amount: new Intl.NumberFormat("de-DE").format(Number(valueWithCommission).toFixed(2)),
                                        currency: el.currency,
                                        budget: 'NO',
                                        urgent: 'NO',
                                        orderNumber: el.paymentOrderNumber,
                                        details1: el.Subscription ? el.Subscription.name.replace(';', ' ') : '-',
                                        details2: `${el.User.firstName} ${el.User.lastName}`,
                                        details3: '',
                                        details4: '',
                                        us: 'XTR Ai Development',
                                        ibanUs: 'RO25BTRLRONCRT0669512801',
                                        cuiUs: 'RO47259134',
                                        bankUs: 'BTRL'
                                    }
                                })}
                                filename={`SportIn_CSV_${moment(exportDay).format('YYYY-MM-DD')}`}
                                style={{
                                    textDecoration: 'none'
                                }}
                                target="_blank"
                                headers={[
                                    { label: 'Data', key: 'date' },
                                    { label: 'Nume platitor', key: 'us' },
                                    { label: 'CUI/CNP platitor', key: 'cuiUs' },
                                    { label: 'IBAN platitor', key: 'ibanUs' },
                                    { label: 'Banca platitor', key: 'bankUs' },
                                    { label: 'Strada platitor', key: 'street' },
                                    { label: 'Oras platitor', key: 'town' },
                                    { label: 'Suma', key: 'amount' },
                                    { label: 'Valuta (RON/EUR)', key: 'currency' },
                                    { label: 'Budget', key: 'budget' },
                                    { label: 'Urgent', key: 'urgent' },
                                    { label: 'Numar ordin', key: 'orderNumber' },
                                    { label: 'Detalii 1', key: 'details1' },
                                    { label: 'Detalii 2', key: 'details2' },
                                    { label: 'Detalii 3', key: 'details3' },
                                    { label: 'Detalii 4', key: 'details4' },
                                    { label: 'Nume beneficiar', key: 'name' },
                                    { label: 'Cont beneficiar', key: 'iban' },
                                    { label: 'Banca beneficiar', key: 'bank' },
                                    { label: 'CUI/CNP beneficiar', key: 'cui' },
                                    { label: 'Nume Banca beneficiar', key: 'bank' },
                                ]}
                            >
                                <Button fullWidth variant="contained">CSV</Button>
                            </CSVLink>
                        </Box>
                        <Box pt={3.5} pl={{ xs: 0, md: 1 }} ml={{ xs: '', md: 'auto' }}>
                            <Button fullWidth onClick={() => setShowGenerateInvoice(true)} variant="contained">Invoice</Button>
                        </Box>
                        <Box pt={3.5} pl={{ xs: 0, md: 1 }} ml={{ xs: '', md: 'auto' }}>
                            <Button fullWidth onClick={() => setShowSendPayments(true)} variant="contained">Bank Transfer</Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} pt={4} pb={4}>
                    <Box width="100%" display="flex" flexDirection="row">
                        <Box flex={1}>
                            <Typography variant="h6" pr={2} sx={{ color: GREEN_COLOR }}>Total Interval {numberFomatter(Number(totalPeriodFull).toFixed(2))} RON</Typography>
                        </Box>
                        <Box flex={1}>
                            <Typography variant="h6" pr={2} sx={{ color: GREEN_COLOR }}>Total SportIn {numberFomatter(Number(totalPeriodUs).toFixed(2))} RON</Typography>
                        </Box>
                        <Box flex={1}>
                            <Typography variant="h6" pr={2} sx={{ color: ERROR_COLOR }}>Total BT {numberFomatter(Number(totalPeriodBT).toFixed(2))} RON</Typography>
                        </Box>
                        <Box flex={1}>
                            <Typography variant="h6" pr={2} sx={{ color: ERROR_COLOR }}>Total Gyms {numberFomatter(Number(totalPeriod).toFixed(2))} RON</Typography>
                        </Box>
                    </Box>
                    <Table
                        dense
                        headCells={[
                            'company',
                            'paymentOrderNumber',
                            'username',
                            'subscription',
                            'status',
                            'paymentMethod',
                            'createdAt',
                            'amount',
                            'voucher',
                            'commission',
                            'amountCommission',
                            'moneySend'
                        ].map((key) => t(`payments.${key}`))}
                        rows={payments.map(payment => {
                            const amount = Number(payment.value)
                            const valueWithCommission = payment.paymentOrderId && payment.Company ? amount - (amount * (payment.Company.commission) / 100) : amount
                            const status = payment.paymentState === 'DEPOSITED' ? 'Paid' : payment.paymentState

                            return {
                                company: <Typography fontWeight={"bold"} color={PRIMARY_COLOR}>{payment.Company ? payment.Company.name : 'Trainer'}</Typography>,
                                paymentOrderNumber: payment.paymentOrderNumber || 0,
                                username: payment.User ? payment.User.firstName + ' ' + payment.User.lastName : '-',
                                subscription: payment.Subscription ? payment.Subscription.name : payment.Slot && payment.Slot.CalendarService && payment.Slot.CalendarService.name ? payment.Slot.CalendarService.name : '-',
                                status: <Typography variant="body2" fontWeight="bold" color={status === 'Paid' ? 'green' : 'red'}>{status}</Typography>,
                                paymentMethod: renderPaymentMethod(payment.paymentMethod, payment.paymentOrderId),
                                createdAt: moment(payment.createdAt).format('DD/MM/YYYY HH:mm'),
                                amount: `${numberFomatter(Number(payment.value).toFixed(2))} ${payment.currency}`,
                                voucher: <>
                                    {
                                        payment.Voucher ? <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            {!payment.Voucher.hasFixedDiscount && <Percent color="primary" fontSize='small' />}
                                            <Typography fontWeight="bold" variant="body2" color="primary">
                                                {payment.Voucher.discount} {' '}
                                                {payment.Voucher.hasFixedDiscount && `${payment.currency}`}
                                            </Typography>
                                        </Box> : '-'
                                    }
                                </>,
                                commission: `${payment.Company && payment.paymentMethod === 'CARD' ? Number(payment.Company.commission).toFixed(2) : 0}%`,
                                amountCommission: `${numberFomatter(Number(valueWithCommission).toFixed(2))} ${payment.currency}`,
                                moneySend: (payment.paymentMethod === PAYMENT_METHOD.CARD && payment.paymentOrderId) ? <Tooltip arrow title={payment.bankTransferDone ? t('payments.moneySendDescription') : t('payments.noMoneySendDescription')}>{payment.bankTransferDone ? <CreditScore fontSize='small' color='success' /> : <PendingActions color='warning' fontSize='small' />}</Tooltip> : ''
                            }
                        })
                        }
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default SuperAdminPayments