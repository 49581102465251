import React, { useMemo, useState } from 'react'
import { Grid, Card, Typography, Button, Box, Avatar, Tooltip, Slide, Rating } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { CheckCircle, Star } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'

import EditProfile from './subcomponents/EditProfile'
import {
  LIGHT_GRAY_BORDER_COLOR,
  CARD_TEXT_COLOR,
  GRAY_TEXT_COLOR,
  GRAY_BG_COLOR,
  PRIMARY_COLOR
} from '../../../utils/constants'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  avatarContainer: {},
  credibilityContainer: {
    borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  borderRight: {
    borderRight: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  performanceText: {
    color: GRAY_TEXT_COLOR
  },
  memberFrom: {
    color: CARD_TEXT_COLOR
  }
})

const TrainerDetails = ({
  getMeHandler,
  user: {
    lastName,
    firstName,
    bio,
    reviewsAverage,
    avatarPhotoSrc,
    Company,
    TrainerReviews,
    performance,
    followersCount,
    isApproved
  }
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [openEditProfile, setOpenEditProfile] = useState(false)
  const [fullBio, setFullBio] = useState(false)

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })

  const renderTrainerReviews = useMemo(() => {
    const score = Math.ceil(reviewsAverage)
    let steps = []

    for (let i = 0; i < score; i++) {
      steps.push(i + 1)
    }

    return (
      <>
        <Rating sx={{ color: PRIMARY_COLOR }} disabled value={score} />
        <Typography pl={1} fontWeight="bold" display="flex" flexDirection="row">
          {Math.ceil(score)}
          <strong style={{ color: CARD_TEXT_COLOR }}>/5</strong>
        </Typography>
      </>
    )
  }, [TrainerReviews])

  return (
    <>
      {openEditProfile && (
        <EditProfile
          getMeHandler={getMeHandler}
          onClose={() => {
            setOpenEditProfile(false)
            getMeHandler()
          }}
          Transition={Transition}
        />
      )}
      <Grid container className={classes.content} component={Card} flexDirection="column">
        <Grid container pt={3} width="100%" className={classes.avatarContainer}>
          <Grid
            item
            xs={12}
            pl={2}
            pr={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {avatarPhotoSrc ? (
              <Avatar src={avatarPhotoSrc} alt="avatar" style={{ width: 100, height: 100 }} />
            ) : (
              <Box
                sx={{
                  width: 100,
                  height: 100,
                  backgroundColor: GRAY_BG_COLOR,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 50
                }}
              >
                <Typography fontWeight="bold" color={GRAY_TEXT_COLOR} variant="h4">
                  {firstName.charAt(0)} {lastName.charAt(0)}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            pt={1}
            flexDirection="row"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h5" pr={1} fontWeight="bold">
              {firstName} {lastName}
            </Typography>
            {isApproved ? <CheckCircle color="primary" /> : null}
          </Grid>
          <Grid
            item
            xs={12}
            pb={2}
            flexDirection="row"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h6" pr={1} color={CARD_TEXT_COLOR}>
              {t('userProfile.activity.trainerDetailComponent.trainerAt')}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            pl={2}
            pr={2}
            pb={2}
            flexDirection="row"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {renderTrainerReviews}
          </Grid>

          {TrainerReviews.length > 0 ? (
            <Grid
              item
              xs={12}
              pt={1}
              pb={3}
              flexDirection="row"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="subtitle2"
                color="primary"
                fontWeight={600}
                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                {t('userProfile.activity.trainerDetailComponent.seeReviews')}
              </Typography>
            </Grid>
          ) : null}

          <Grid
            className={classes.credibilityContainer}
            container
            borderTop={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
          >
            <Grid
              item
              xs={6}
              pl={3}
              pr={2}
              pb={2}
              pt={2}
              className={classes.borderRight}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography variant="p" className={classes.performanceText}>
                {t('userProfile.performance')}
              </Typography>
              <Grid pt={0.5} item display="flex" flexDirection="row">
                {performance ? (
                  <>
                    <Typography color="primary" fontWeight={500} variant="h4">
                      {Number(performance).toFixed(0)}
                    </Typography>
                    <Typography fontWeight={500} variant="p">
                      /5
                    </Typography>
                  </>
                ) : (
                  <Typography color="primary" fontWeight={500} variant="h4">
                    {'N/A'}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid
              pl={3}
              pr={4}
              pb={2}
              pt={2}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-end"
              item
              xs={6}
            >
              <Typography variant="p" className={classes.performanceText}>
                {t('userProfile.followers').toUpperCase()}
              </Typography>
              <Grid pt={0.5} item display="flex" flexDirection="row">
                <Typography color="primary" variant="h4" fontWeight={500}>
                  {followersCount && followersCount > 0 ? followersCount : 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            pb={3}
            pl={3}
            pr={3}
            pt={3}
            flexDirection="column"
            display="flex"
            alignItems="center"
            justifyContent="center"
            className="bio"
          >
            <Typography fontWeight={500} variant="subtitle2">
              {bio ? (
                fullBio ? (
                  parse(bio)
                ) : (
                  parse(bio.slice(0, 150))
                )
              ) : (
                <Typography color={`${bio ? 'black' : '#979797'}`} fontSize={17}>
                  {t('helper.noInformation')}
                </Typography>
              )}
              <Typography
                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                fontWeight={'bold'}
                color="primary"
                onClick={() => setFullBio((prevValue) => !prevValue)}
                variant="span"
              >
                {bio ? (fullBio ? t('userProfile.showLess') : t('userProfile.readMore')) : null}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid item display="flex" justifyContent="center" alignItems="center">
          <Grid item pt={12} pb={4} xs={6}>
            <Button fullWidth onClick={() => setOpenEditProfile(true)} variant="outlined">
              {t('userProfile.editProfile')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default TrainerDetails
