import axios from './axios'
import queryString from 'query-string'

export const getClientsBusiness = (id, options = {}) => {
    return axios
        .get(`/clients/company/${id}?${queryString.stringify({ ...options })}`)
        .then((res) => res.data)
}

export const getRevenue = id => {
    return axios.get(`/clients/company/${id}/revenue`)
        .then(res => res.data)
}

export const getRevenueTrainer = id => {
    return axios.get(`/clients/trainer/${id}/revenue`)
        .then(res => res.data)
}

export const getClientsTrainer = (id, search, from, limit, status, payment) => {
    return axios
        .get(`/clients/trainer/${id}?search=${search}&from=${from}&limit=${limit}&status=${status}&payment=${payment}`)
        .then((res) => res.data)
}