import React, { useCallback, useEffect, useState } from 'react'
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Box } from '@mui/material'

import * as SUPER_ADMIN_REQUESTS from '../../api/superAdmin'
import moment from 'moment'

const SuperAdminHistory = ({ search }) => {

    const typeMapping = {
        'BOOK': 'Book',
        'UN_BOOK': 'Unbook',
        'EDIT_EVENT': 'Edit Event',
        'CHECK_IN': 'Check In',
        'RETURN_CREDITS_EVENT': 'Return Credit',
        'RETURN_CREDITS_SERVICE': 'Return Credit'
    }

    const [historyEntries, setHistoryEntries] = useState([])

    const handleGetHistory = useCallback(async () => {
        const response = await SUPER_ADMIN_REQUESTS.getHistory(search)
        setHistoryEntries(response.map(el => ({
            type: typeMapping[el.type],
            message: el.type === 'RETURN_CREDITS_EVENT' || el.type === 'RETURN_CREDITS_SERVICE' ? el.message : `${el.User ? `${el.User.firstName} ${el.User.lastName}` : el.Company.name} ${el.message}`,
            createdAt: moment(el.createdAt).format('YYYY-MM-DD HH:mm:ss')
        })))
    }, [search])

    useEffect(() => {
        handleGetHistory()
    }, [handleGetHistory, search])

    return (
        <Box display="flex" flexDirection="column">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Message</TableCell>
                            <TableCell>Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {historyEntries.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>
                                    {row.type}
                                </TableCell>

                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>
                                    {row.message}
                                </TableCell>

                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>
                                    {row.createdAt}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* '' */}
        </Box>
    )
}

export default SuperAdminHistory