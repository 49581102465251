/* eslint-disable no-unused-vars */
import { Close } from '@mui/icons-material'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    Box,
    Button,
    IconButton
} from '@mui/material'

import THINKING from '../../../assets/thinking.gif'
import { useTranslation } from 'react-i18next'

const PaymentEventModal = ({ handleClose, handleConfirm, type, price, currency }) => {

    const { t } = useTranslation()

    const cashMessage = `${t('calendar.payStart')} ${price} ${currency} . ${t('calendar.cashPayEnd')} `
    const onlineMessage = `${t('calendar.payStart')} ${price} ${currency} . ${t('calendar.onlinePayEnd')} `

    return (
        <Dialog
            open={true}
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                p={4}
            >
                <Grid container display="flex" flexDirection="row">
                    <Grid item xs={10}>
                    </Grid>
                    <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                        <IconButton style={{ color: 'black' }} onClick={handleClose}>
                            <Close fontSize="medium" color="inherit" />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <img style={{ objectFit: 'contain' }} height={150} src={THINKING} alt="ro flag" />
                    <Typography variant="h5" pb={2} pt={2} fontWeight={600} textAlign="center">{t('calendar.paymentAgreement')}</Typography>
                    <Typography variant="p" pb={2} pt={2} textAlign="center">{type === 'CASH' ? cashMessage : onlineMessage} </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }} pb={1} pt={1}>
                        <Button sx={{ width: 150, marginRight: 1 }} onClick={handleConfirm} variant="contained" color="primary">{type === 'CASH' ? t('calendar.yes') : t('calendar.buy')}</Button>
                        <Button style={{ width: 150, marginLeft: 3 }} color="primary" onClick={handleClose} variant="outlined">Cancel</Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default PaymentEventModal
