import React from 'react'
import { IMaskInput } from 'react-imask'

export const HourMinutesMask = React.forwardRef(function HourMinutesMask(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask="##:##"
      definitions={{
        '#': /[0-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  )
})
