import React, { useState, useEffect } from 'react'
import { Grid, Card } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Tabs } from '../../common'
import Details from './subcomponents/Details'
import Subscriptions from './subcomponents/Subscriptions'
import Reviews from './subcomponents/Reviews'

import { LIGHT_GRAY_BORDER_COLOR, ROLE_ORGANIZATION } from '../../../utils/constants'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
    flexGrow: 1
  }
})

const UserActivity = ({ user, handleGetMe }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState(undefined)

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    })
    const activeOption = params['active_option']
    if (activeOption) setActiveTab(Number(activeOption))
    else setActiveTab(0)
  }, [])

  if (activeTab === undefined) return null
  const options = user.role.toLowerCase() === ROLE_ORGANIZATION.toLowerCase() ? [
    {
      label: t('userProfile.activity.details'),
      value: <Details bio={user.bio} Reviews={user.Reviews} />
    }
  ] : [
    {
      label: t('userProfile.activity.details'),
      value: <Details bio={user.bio} Reviews={user.Reviews} />
    },
    {
      label: t('userProfile.activity.yourSubscriptions'),
      value: <Subscriptions />
    },
    {
      label: t('userProfile.activity.reviews'),
      value: <Reviews handleGetMe={handleGetMe} Reviews={user.Reviews} />
    }
  ]
  return (
    <Grid item sm={12} xl={12} justifyContent="flex-end">
      <Grid className={classes.content} component={Card} item sm={12} xl={12}>
        <Tabs
          variant="fullWidth"
          value={activeTab}
          onChange={(_, newValue) => {
            const to = { pathname: location.pathname, search: `active_option=${newValue}` }
            navigate(to, { replace: true })
            setActiveTab(newValue)
          }}
          options={options}
        />
      </Grid>
    </Grid>
  )
}

export default UserActivity
