import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Star } from '@mui/icons-material'
import moment from 'moment'

import Review from './subcomponents/Review'

import { LIGHT_GRAY_BORDER_COLOR } from '../../../../utils/constants'

import * as COMPANY_REQUESTS from '../../../../api/company'
import { useAuthentication } from '../../../../context/auth-context'
import RenderReviews from '../../../../utils/renderReviews'

const Reviews = ({ reviewsAverage }) => {
  const { t } = useTranslation()
  const { user } = useAuthentication()

  const [reviews, setReviews] = useState([])
  useEffect(() => {
    COMPANY_REQUESTS.getAllReviews(user.companyId).then(setReviews)

  }, [user])

  return (
    <Grid
      container
      display="flex"
      flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }}
      p={3}
      pt={5}
    >
      <Grid item xs={12}>
        <Typography fontWeight={500} color="black" fontSize={20}>
          {t('userProfile.activity.reviews')}
        </Typography>
      </Grid>
      <Grid pt={5} item xs={12} display="flex" alignItems="center" flexDirection="row">
        <RenderReviews reviewsAverage={reviewsAverage} reviews={reviews} />
      </Grid>
      <Grid item pt={5} xs={12}>
        <Grid container display="flex">
          {reviews.length > 0 ? (
            reviews.map((el, index) => {
              return (
                <Grid
                  key={index}
                  item
                  pt={2}
                  pb={2}
                  width="100%"
                  borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
                >
                  <Review
                    name={el.User?.firstName + ' ' + el.User?.lastName}
                    comment={el.comment}
                    createdAt={moment(el.createdAt).format('YYYY-MM-DD')}
                    stars={el.stars}
                  />
                </Grid>
              )
            })
          ) : (
            <Typography pl={1} color="#979797" fontWeight={500} fontSize={17}>
              {t('helper.noResultsFound')}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Reviews
