import React from 'react'
import { Grid, Card, Typography, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import Linkify from 'react-linkify'

import {
  LIGHT_GRAY_BORDER_COLOR,
  CARD_TEXT_COLOR,
  ROLE_TRAINER,
  ROLE_BUSINESS_ADMINISTRATOR,
  BUSINESS_ROLES
} from '../../../../../utils/constants'
import { useAuthentication } from '../../../../../context/auth-context'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  }
})

const Program = ({
  name,
  description,
  duration,
  price,
  currency,
  id,
  credits,
  isAvailable,
  limitedActivation,
  Trainers,
  isPartTime,
  startTime,
  endTime,
  limitedActivationUsed
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useAuthentication()

  const isTrainer = user && user.role && user.role.toLowerCase() === ROLE_TRAINER.toLowerCase()
  const isBusinessAdministrator =
    user && user.role && user.role.toLowerCase() === ROLE_BUSINESS_ADMINISTRATOR.toLowerCase()
  const isClubAdministrator =
    user && user.role && user.role.toLowerCase() === BUSINESS_ROLES.BUSINESS_EMPLOYEE.toLowerCase()
  const isSubscriptionAvailable = isAvailable
  const hasLimitedActivation = limitedActivation != null

  const computeColor = () => {
    if(hasLimitedActivation && limitedActivation === 0) return 'error'
    if (hasLimitedActivation && (limitedActivationUsed <= 0)) return 'error'
    if (!isSubscriptionAvailable || (hasLimitedActivation && limitedActivationUsed == 0)) return 'error'
    if (hasLimitedActivation && limitedActivationUsed > 0) return 'primary'

    return 'primary'
  }

  const computeText = () => {
    if(hasLimitedActivation && limitedActivation === 0) return t('userProfile.activity.no')
    if (hasLimitedActivation && (limitedActivationUsed <= 0)) return t('userProfile.activity.no')
    if (!isSubscriptionAvailable) return t('userProfile.activity.no')
    if (hasLimitedActivation && limitedActivationUsed > 0) return `${limitedActivationUsed}`
    if (isSubscriptionAvailable && (hasLimitedActivation ? limitedActivationUsed > 0 : true)) return t('userProfile.activity.yes')
    if (!isSubscriptionAvailable && (hasLimitedActivation ? limitedActivationUsed == 0 : true)) return t('userProfile.activity.no')

    return t('userProfile.activity.no')
  }

  return (
    <Grid container component={Card} className={classes.content}>
      <Grid
        xs={12}
        item
        borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
        display="flex"
        flexDirection={{ lg: 'row', xs: 'column' }}
        width="100%"
      >
        <Grid
          item
          lg={7}
          borderRight={{
            xs: 'none',
            lg: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
          }}
          borderBottom={{
            xs: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
            lg: 'none'
          }}
          display="flex"
          alignItems="center"
          flexWrap="noWrap"
        >
          <Grid item xs={12} lg={9} pl={1.5} display="flex" alignItems="center" flexWrap="noWrap">
            <Typography
              sx={{ cursor: 'pointer' }}
              p={1.5}
              variant="h6"
              fontWeight={500}
              display="flex"
              flexDirection="row"
            >
              {name}
            </Typography>
          </Grid>
          {isPartTime && (
            <Grid
              item
              xs={12}
              lg={3}
              pr={{ xs: 0, lg: 4 }}
              pl={{ xs: 3, lg: 0 }}
              display="flex"
              alignItems={{ xs: 'flex-start', lg: 'flex-end' }}
              borderTop={{ xs: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`, lg: 'none' }}
              flexDirection="column"
              flexWrap="noWrap"
            >
              <Typography pt={2} color={CARD_TEXT_COLOR}>
                {t('userProfile.activity.trainerProgramComponent.access')}
              </Typography>
              <Typography pb={2} fontWeight={500} color="primary">
                {startTime + '-' + endTime}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item lg={5}>
          <Grid container display="flex" alignItems={{ xs: 'flex-start', lg: 'flex-end' }}>
            <Grid xs={12} lg={6} item borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
              <Grid
                xs={12}
                item
                display="flex"
                flexDirection="column"
                pl={{ lg: 3, xs: 3 }}
                alignItems={{ lg: 'flex-start', xs: 'flex-start' }}
                justifyContent="center"
              >
                <Typography pt={2} color={CARD_TEXT_COLOR}>
                  {t('userProfile.activity.trainerProgramComponent.duration')}
                </Typography>
                <Typography pb={2} fontWeight={500} color="primary">
                  {duration}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={12} lg={6} item borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
              <Grid
                xs={12}
                item
                display="flex"
                flexDirection="column"
                pr={{ lg: 3, xs: 3 }}
                pl={{ xs: 3, lg: 0 }}
                alignItems={{ lg: 'flex-end', xs: 'flex-start' }}
                justifyContent="center"
              >
                <Typography pt={2} color={CARD_TEXT_COLOR}>
                  {t('userProfile.activity.trainerProgramComponent.credits').toUpperCase()}
                </Typography>
                <Typography pb={2} fontWeight={500} color="primary">
                  {credits ? credits : 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item display="flex" flexDirection={{ xs: 'column', lg: 'row' }} width="100%">
        <Grid lg={7} item borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
          <Grid flexWrap="noWrap" p={3} display="flex" flexDirection="column">
            <Typography color={CARD_TEXT_COLOR}>{t('userProfile.activity.description')}</Typography>
            <Linkify>
              <Typography fontWeight={500}>{description}</Typography>
            </Linkify>
          </Grid>
        </Grid>
        <Grid
          lg={5}
          pr={{ lg: 3, xs: 3 }}
          pl={{ lg: 3, xs: 3 }}
          pb={{ xs: 3, lg: 0 }}
          pt={1}
          item
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid container display="flex" flexDirection="row" pb={1}>
            <Grid xs={4} lg={6} item>
              <Typography color={CARD_TEXT_COLOR}>{t('userProfile.activity.available')}</Typography>
              <Typography
                color={computeColor()}
              >
                {computeText()}
              </Typography>
            </Grid>
            <Grid
              xs={8}
              lg={6}
              item
              display="flex"
              flexDirection="column"
              alignItems={{ lg: 'flex-end', xs: 'flex-end' }}
            >
              <Typography color={CARD_TEXT_COLOR}>
                {t('userProfile.activity.trainerProgramComponent.trainer').toUpperCase()}
              </Typography>
              {Trainers.length
                ? Trainers.map(({ firstName, lastName, domain }, index) => {
                  return (
                    <Link key={index} style={{ textDecoration: 'none' }} to={'/' + domain}>
                      <Typography
                        display="flex"
                        justifyContent="flex-end"
                        sx={{ textDecoration: 'underline', textAlign: 'right' }}
                        fontWeight={600}
                        color="primary"
                      >{`${firstName} ${lastName}`}</Typography>
                    </Link>
                  )
                })
                : null}
              {!Trainers.length ? (
                <Typography
                  display="flex"
                  sx={{ textAlign: 'right' }}
                  justifyContent="flex-end"
                  pb={2}
                  fontWeight={600}
                  color="primary"
                >
                  N / A
                </Typography>
              ) : null}
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="flex-end"
            height="100%"
            pb={2}
            pt={1}
          >
            <Button
              onClick={() => {
                navigate(`/checkout?subscription=${id}`)
              }}
              fullWidth
              disabled={
                isTrainer ||
                isClubAdministrator ||
                isBusinessAdministrator ||
                (hasLimitedActivation && limitedActivationUsed <= 0) ||
                (hasLimitedActivation && limitedActivation === 0) || 
                (!user ? true : isAvailable ? false : true)
              }
              sx={{ minWidth: 120 }}
              variant="contained"
              color={price === 0 ? 'success' : 'primary'}
            >
              {price === 0 ? t('userProfile.activity.trainerProgramComponent.free') : `${t('button.buyFor')} ${price || 'N/A'} ${currency}`}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Program
