import { createTheme } from '@mui/material/styles'

const formLabelTheme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Quicksand'
        }
      }
    }
  }
})

export default formLabelTheme
