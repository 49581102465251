import { TableCell, Avatar, Box, Typography, Link } from '@mui/material';

const UserDetailsCell = ({ index, userDetails }) => (
    <TableCell sx={{ display: 'flex', alignItems: 'center', gap: '8%', fontWeight: 500, border: 'none !important', width: '15%' }} key={`userDetails-${index}`}>
        <Avatar src={userDetails.avatar} />
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
            <Link alignSelf={'flex-start'} fontSize={'1rem'}>{userDetails.name}</Link>
            <Typography fontSize={'1rem'}>+{userDetails.phone}</Typography>
        </Box>
    </TableCell>
);

export default UserDetailsCell;