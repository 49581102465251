import { Grid, Container } from '@mui/material'

import { Footer } from '../common'
import SelectUser from './subcomponents/SelectUser'
import Reports from '../UserProfile/subcomponents/subcomponents/Report'
import { useState } from 'react'

const Measurements = () => {

  const [selectedUser, setSelectedUser] = useState()

  const handleSelectUser = (newUser) => {
    setSelectedUser({
      fullName: `${newUser.firstName} ${newUser.lastName}`,
      id: newUser.id
    })
  }

  return (
    <Container maxWidth="xl">
      <Grid container pt={5} pb={5}>
        <SelectUser handleSelectUser={handleSelectUser} />
        {selectedUser && <Reports selectedUser={selectedUser} />}
      </Grid>
    </Container>
  )
}

export default Measurements
