import React, { useState } from 'react'
import { Grid, Card, Typography, Box, Avatar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

import {
  LIGHT_GRAY_BORDER_COLOR,
  CARD_TEXT_COLOR,
  GRAY_TEXT_COLOR,
  GRAY_BG_COLOR
} from '../../../utils/constants'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  avatarContainer: {
    borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  credibilityContainer: {
    borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  borderRight: {
    borderRight: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  countReviewersFollowers: {
    fontWeight: 800,
    fontSize: 24
  },
  performanceText: {
    color: GRAY_TEXT_COLOR
  },
  memberFrom: {
    color: CARD_TEXT_COLOR
  }
})

const UserDetails = ({ user: { lastName, firstName, createdAt, bio, avatarPhotoSrc } }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [fullBio, setFullBio] = useState(false)

  return (
    <Grid container className={classes.content} component={Card} flexDirection="column">
      <Grid container pt={3} width="100%" className={classes.avatarContainer}>
        <Grid container width="100%">
          <Grid
            xs={12}
            pl={2}
            pr={2}
            item
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {avatarPhotoSrc ? (
              <Avatar src={avatarPhotoSrc} alt="avatar" style={{ width: 100, height: 100 }} />
            ) : (
              <Box
                sx={{
                  width: 100,
                  height: 100,
                  backgroundColor: GRAY_BG_COLOR,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 50
                }}
              >
                <Typography variant="h4" color={GRAY_TEXT_COLOR} fontWeight="bold">
                  {firstName.charAt(0)} {lastName.charAt(0)}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          pt={1}
          pb={3}
          sx={{ display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column' }}
        >
          <Typography variant="p" fontSize={{ xl: 22, lg: 16 }} fontWeight="bold">
            {firstName} {lastName}
          </Typography>
          <Typography
            variant="p"
            fontWeight={500}
            fontSize={{ xl: 20, lg: 14 }}
            className={classes.memberFrom}
          >
            {t('userProfile.memberFrom')} {createdAt}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default UserDetails
