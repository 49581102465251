import React, { useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import moment from 'moment/min/moment-with-locales'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import { CARD_TEXT_COLOR, LIGHT_PRIMARY_COLOR, SECONDARY_TEXT_COLOR } from '../../../utils/constants'
import CalendarItem from './CalendarItem'

const CalendarDay = ({ dayName, loadingBookUnbook, showPastEvents, dayDate, dayItems, handleOpenPayment, handleBookEventWithOneSubscription, handleEventDetails, handleOpenSubscriptionSelector, handleOpenWaitingList, handleBook, handleUnbook }) => {
  const { i18n } = useTranslation()
  const theme = useTheme()
  const matchesPhone = useMediaQuery(theme.breakpoints.down('sm'))

  const [expanded, setExpanded] = useState(['services', 'events'])

  return (
    <Grid container display="flex" flexDirection="column" alignItems="center" p={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography variant="subtitle1" fontWeight={700} color={dayName.toLowerCase() === moment().locale(i18n.language).format('dddd').toLowerCase() && dayDate.toLowerCase() === moment().locale(i18n.language).format('MM/DD/YYYY').toLowerCase() ? 'primary' : ''}>
          {dayName}
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography variant="subtitle2" fontWeight={600} color={dayName.toLowerCase() === moment().locale(i18n.language).format('dddd').toLowerCase() && dayDate.toLowerCase() === moment().locale(i18n.language).format('MM/DD/YYYY').toLowerCase() ? 'primary' : CARD_TEXT_COLOR}>
          {dayDate}
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} pt={4} width="100%">
        <Grid container display="flex" flexDirection="column" alignItems="center" width="100%">
          {dayItems.filter(el => el.type && el.type === 'service').length > 0 ? <Box
            minWidth={matchesPhone ? '85vw' : 400}
            maxWidth={matchesPhone ? '85vw' : 400}
            bgcolor={LIGHT_PRIMARY_COLOR}
            borderRadius={2}
            boxSizing={'border-box'}
            display="flex"
            flexDirection="column"
          >
            <Box sx={{ cursor: 'pointer' }} display="flex" flexDirection="row" p={1} pl={1.5} pr={1.5} onClick={() => {
              setExpanded(prevValue => {
                let prevValueCopy = [...prevValue]
                if (prevValueCopy.includes('services')) {
                  prevValueCopy = prevValueCopy.filter(el => el !== 'services')
                }
                else {
                  prevValueCopy = prevValueCopy.concat(['services'])
                }
                return prevValueCopy
              })
            }}>
              <Box><Typography fontWeight={500} color={SECONDARY_TEXT_COLOR}>Services ({dayItems.filter(el => el.type && el.type === 'service').length})</Typography></Box>
              <Box ml="auto">{expanded.includes('services') ? <KeyboardArrowDown sx={{ color: SECONDARY_TEXT_COLOR }} /> : <KeyboardArrowRight sx={{ color: SECONDARY_TEXT_COLOR }} />}</Box>
            </Box>
            {expanded.includes('services') ? <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
              {dayItems.filter(el => el.type && el.type === 'service').map((item, index) => (
                <CalendarItem
                  day={dayDate}
                  showPastEvents={showPastEvents}
                  handleOpenPayment={handleOpenPayment}
                  handleOpenWaitingList={handleOpenWaitingList}
                  handleEventDetails={handleEventDetails}
                  handleOpenSubscriptionSelector={handleOpenSubscriptionSelector}
                  handleBookEventWithOneSubscription={handleBookEventWithOneSubscription}
                  key={index}
                  item={item}
                  handleBook={handleBook}
                  handleUnbook={handleUnbook}
                />
              ))}
            </Box> : null}
          </Box> : null}
        </Grid>
        <Grid container display="flex" flexDirection="column" alignItems="center" width="100%" pt={dayItems.filter(el => el.type === 'service').length > 0 ? 2 : 0}>
          {dayItems.filter(el => el.type === 'event').length > 0 ? <Box
            minWidth={matchesPhone ? '85vw' : 400}
            maxWidth={matchesPhone ? '85vw' : 400}
            bgcolor={LIGHT_PRIMARY_COLOR}
            borderRadius={2}
            boxSizing={'border-box'}
            display="flex"
            flexDirection="column"
          >
            <Box sx={{ cursor: 'pointer' }} display="flex" flexDirection="row" p={1} pl={1.5} pr={1.5} onClick={() => {
              setExpanded(prevValue => {
                let prevValueCopy = [...prevValue]
                if (prevValueCopy.includes('events')) {
                  prevValueCopy = prevValueCopy.filter(el => el !== 'events')
                }
                else {
                  prevValueCopy = prevValueCopy.concat(['events'])
                }
                return prevValueCopy
              })
            }}>
              <Box><Typography fontWeight={500} color={SECONDARY_TEXT_COLOR}>Events ({dayItems.filter(el => el.type === 'event').length})</Typography></Box>
              <Box ml="auto">{expanded.includes('events') ? <KeyboardArrowDown sx={{ color: SECONDARY_TEXT_COLOR }} /> : <KeyboardArrowRight sx={{ color: SECONDARY_TEXT_COLOR }} />}</Box>
            </Box>
            {expanded.includes('events') ? <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
              {dayItems.filter(el => el.type === 'event').map((item, index) => (
                <CalendarItem
                  day={dayDate}
                  showPastEvents={showPastEvents}
                  handleOpenPayment={handleOpenPayment}
                  handleOpenWaitingList={handleOpenWaitingList}
                  handleEventDetails={handleEventDetails}
                  handleOpenSubscriptionSelector={handleOpenSubscriptionSelector}
                  handleBookEventWithOneSubscription={handleBookEventWithOneSubscription}
                  key={index}
                  item={item}
                  handleBook={handleBook}
                  handleUnbook={handleUnbook}
                  loadingBookUnbook={loadingBookUnbook}
                />
              ))}
            </Box> : null}
          </Box> : null}
        </Grid>
      </Grid>
    </Grid >
  )
}

export default CalendarDay
