import React, { useEffect, useState } from 'react'
import { Dialog, IconButton, Button, Typography, Grid, TextField } from '@mui/material'
import { Close } from '@mui/icons-material'

import * as USER_SUBSCRIPTION_REQUESTS from '../../../../api/userSubscriptions'
import { useTranslation } from 'react-i18next'

const LimitedActivation = ({
    onCancel,
    onConfirm,
    userSubscription
}) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [limitedActivationUsed, setLimitedActivationUsed] = useState()

    const confirmUpdate = () => {
        setLoading(true)
        return USER_SUBSCRIPTION_REQUESTS.update(userSubscription.id, { limitedActivationUsed: userSubscription.limitedActivationTotal - limitedActivationUsed })
            .catch(() => {
                console.error('Something went wrong')
            })
            .finally(() => {
                setLoading(false)
                onConfirm()
                onCancel()
            })
    }
    const onLimitedActivationChangeHandler = event => {
        if (event.target.value >= 0 && event.target.value <= userSubscription.limitedActivationTotal) {
            setLimitedActivationUsed(event.target.value)
        }
    }

    useEffect(() => {
        if (!userSubscription) return

        setLimitedActivationUsed(userSubscription.limitedActivationTotal - userSubscription.limitedActivationUsed)
    }, [userSubscription])

    if (!userSubscription) return null

    return (
        <Dialog open maxWidth="sm" fullWidth>
            <Grid container p={4}>
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item
                            xs={10}
                            md={10}
                            lg={10}
                            pr={{ xs: 0, md: 0, lg: 2 }}
                            pl={{ xs: 0, md: 0, lg: 2 }}>
                            <Typography variant="h4" fontWeight={500} color={'black'}>
                                {t('clients.credits')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                            <IconButton onClick={onCancel}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    pt={4}
                    pb={4}
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}
                >
                    <Typography variant="h6">{t('clients.youCanEdit')} {userSubscription.Subscription.name}.</Typography>
                </Grid>
                <Grid
                    item
                    pt={4}
                    pb={4}
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}
                >
                    <Grid container display='flex' flexDirection="row" spacing={1}>
                        <Grid item xs={6}>
                            <Typography variant="p">{t('clients.creditsAv')}</Typography>
                            <TextField fullWidth type="number" value={limitedActivationUsed} placeholder="Credits Number" onChange={onLimitedActivationChangeHandler} />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="p">{t('clients.creditsTotal')}</Typography>
                            <TextField fullWidth disabled placeholder={t('clients.creditsTotal')} value={userSubscription.limitedActivationTotal} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} pt={3}>
                    <Grid container display="flex" flexDirection="row" spacing={3}>
                        <Grid item xs={12} md={12} lg={6}>
                            <Button onClick={onCancel} fullWidth variant="outlined">
                                {t('clients.cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <Button
                                onClick={confirmUpdate}
                                fullWidth
                                disabled={loading}
                                color={'warning'}
                                variant="contained"
                            >
                                {t('clients.confirm')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default LimitedActivation
