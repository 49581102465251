import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

const SuperAdminTransactionsFilter = ({ filterOptions, setFilterOptions }) => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex' }}>
            <Box pt={{ xs: 2, lg: 3.5 }} pl={{ xs: 0, lg: 1 }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        inputFormat="MM/dd/yyyy"
                        value={filterOptions.date.startDate}
                        onChange={(newValue) => setFilterOptions((prev) => ({
                            ...prev,
                            date: {
                                ...prev.date,
                                startDate: moment(newValue)
                            }
                        }))}
                        renderInput={(params) => (
                            <TextField
                                fullWidth
                                sx={{
                                    '.MuiIconButton-root': { marginRight: 1 }
                                }}
                                {...params}
                            />
                        )} />
                </LocalizationProvider>
            </Box>
            <Box pt={{ xs: 2, lg: 3.5 }} pl={{ xs: 0, lg: 1 }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        inputFormat="MM/dd/yyyy"
                        value={filterOptions.date.endDate}
                        onChange={(newValue) => setFilterOptions((prev) => ({
                            ...prev,
                            date: {
                                ...prev.date,
                                endDate: moment(newValue)
                            }
                        }))}
                        renderInput={(params) => (
                            <TextField
                                fullWidth
                                sx={{
                                    '.MuiIconButton-root': { marginRight: 1 }
                                }}
                                {...params}
                            />
                        )} />
                </LocalizationProvider>
            </Box>
        </Box>
        <Box sx={{ width: '20%' }}>
            <Typography variant="p">Payment Method</Typography>
            <FormControl sx={{ pt: 1, width: '100%' }}>
                <Select
                    sx={{ width: '100%' }}
                    defaultValue={filterOptions.paymentMethod}
                    value={filterOptions.paymentMethod}
                    onChange={(e) => {
                        setFilterOptions((prev) => ({
                            ...prev,
                            paymentMethod: e.target.value
                        }))
                    }}
                >
                    <MenuItem value={'All'}>All</MenuItem>
                    <MenuItem value={'CARD'}>CARD</MenuItem>
                    <MenuItem value={'CASH'}>CASH</MenuItem>
                    <MenuItem value={'BANK_TRANSFER'}>BANK TRANSFER</MenuItem>
                    <MenuItem value={'POS'}>POS</MenuItem>
                </Select>
            </FormControl>
        </Box>
    </Box>
);

export default SuperAdminTransactionsFilter;