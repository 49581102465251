import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const ApexChart = ({ data }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const matchesPhone = useMediaQuery(theme.breakpoints.down('sm'))

    const [series, setSeries] = useState([])

    useEffect(() => {
        if (data && Object.keys(data)) {
            setSeries(Object.keys(data).map(key => {
                return ({
                    name: t(`days.${key}`),
                    data: Object.values(data[key])
                })
            }))
        }
    }, [data])

    const categoriesBasedOnResolution = matchesPhone ?
        ['05', '', '', '', '09', '', '', '', '13', '', '', '', '17', '', '', '', '21'] :
        ['05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']

    const options = {
        chart: {
            height: 350,
            type: 'heatmap',
        },
        dataLabels: {
            enabled: false
        },
        colors: ["#008FFB"],
        xaxis: {
            type: 'category',
            categories: categoriesBasedOnResolution
        },

    }
    return (
        <div id="chart">
            <ReactApexChart options={options} series={series} type="heatmap" height={250} />
        </div>
    );
}


export default ApexChart
