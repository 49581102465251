import React, { useMemo } from 'react'
import { Grid, Typography, FormControlLabel, Radio } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { CalendarItem } from '.'
import { getBackgroundImage } from '../../../utils/helpers/bgImages'

const CalendarEventDesign = ({ selectedDesign, handleOnDesignChanged }) => {
  const { t } = useTranslation()

  const renderEventDesign = useMemo(() => {
    return (
      <Grid
        container
        display="flex"
        flexDirection="column"
        alignItems={{ lg: 'center', xs: 'start' }}
        justifyContent={'center'}
        p={{ lg: 6, xs: 0 }}
        pt={{ lg: 6, xs: 2 }}
        minHeight={'65vh'}
      >
        <Grid item lg={12} pb={3}>
          <Typography variant="body1" fontWeight={600}>
            {t('calendar.designTitle')}
          </Typography>
        </Grid>
        <Grid item lg={12} xs={12} pt={{ lg: 3, xs: 0 }}>
          <Grid container display="flex" flexDirection="row">
            <Grid item lg={6} pr={{ lg: 3, xs: 0 }} pb={{ lg: 0, xs: 2 }} width="100%">
              <Grid container display="flex" flexDirection="column" alignItems={{ lg: 'center', xs: 'start' }} width="100%">
                <Grid item lg={12} width="100%">
                  <CalendarItem
                    small
                    item={{
                      title: 'YOGA',
                      firstChip: 'Beginners',
                      secondChip: 'EXTREME FIT',
                      trainerName: 'Silviu-Raul MANZUR',
                      avatar:
                        'https://i.kinja-img.com/gawker-media/image/upload/t_original/ijsi5fzb1nbkbhxa2gc1.png',
                      start: '12:00',
                      duration: 70,
                      maxParticipants: 10,
                      participants: Array.from({ length: 5 }).map((_, idx) => idx),
                      withWaitingList: true,
                      backgroundColor: '#0277bd',
                      trainers: [],
                      trainer: null,
                      company: { domain: '' },
                      payment: '',
                      value: 0,
                    }}
                  />
                </Grid>
                <Grid item lg={12} xs={10}>
                  <CalendarItem
                    small
                    item={{
                      title: 'YOGA',
                      firstChip: 'Beginners',
                      trainerName: 'Silviu-Raul MANZUR',
                      secondChip: 'EXTREME FIT',
                      avatar:
                        'https://i.kinja-img.com/gawker-media/image/upload/t_original/ijsi5fzb1nbkbhxa2gc1.png',
                      start: '12:00',
                      duration: 70,
                      maxParticipants: 10,
                      participants: Array.from({ length: 5 }).map((_, idx) => idx),
                      withWaitingList: true,
                      backgroundColor: '#37474f',
                      trainers: [],
                      trainer: null,
                      company: { domain: '' },
                      payment: '',
                      value: 0,
                    }}
                  />
                </Grid>
                <Grid item lg={12}>
                  <FormControlLabel
                    onChange={handleOnDesignChanged}
                    labelPlacement="bottom"
                    value={'compact'}
                    checked={selectedDesign === 'COMPACT'}
                    control={<Radio />}
                    label={t('calendar.designCompact')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={6} pl={{ lg: 3, xs: 0 }} pb={{ lg: 0, xs: 2 }}>
              <Grid container display="flex" flexDirection="column" alignItems={{ lg: 'center', xs: 'start' }}>
                <Grid item lg={12}>
                  <CalendarItem
                    small
                    item={{
                      title: 'METCON CROSSFIT',
                      firstChip: 'STUDIO B - Beginners',
                      secondChip: 'EXTREME FIT',
                      trainerName: 'Silviu-Raul MANZUR',
                      avatar:
                        'https://i.kinja-img.com/gawker-media/image/upload/t_original/ijsi5fzb1nbkbhxa2gc1.png',
                      start: '12:00',
                      duration: 70,
                      maxParticipants: 10,
                      participants: Array.from({ length: 5 }).map((_, idx) => idx),
                      custom: true,
                      withWaitingList: true,
                      trainers: [],
                      trainer: null,
                      company: { domain: '' },
                      payment: '',
                      backgroundImage: getBackgroundImage(),
                      value: 0,
                    }}
                  />
                </Grid>
                <Grid item lg={12}>
                  <FormControlLabel
                    onChange={handleOnDesignChanged}
                    labelPlacement="bottom"
                    value={'detailed'}
                    checked={selectedDesign === 'DETAILED'}
                    control={<Radio />}
                    label={t('calendar.designDetailed')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }, [selectedDesign])

  return renderEventDesign
}

export default CalendarEventDesign
