import React, { useState, useEffect, useMemo } from 'react'
import { Grid, Typography, Chip, Button, Avatar } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Check, AccessTime, ArrowForwardIos } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import Linkify from 'react-linkify'
import parse from 'html-react-parser'
import { useNavigate } from 'react-router-dom'

import FavouriteClub from './subcomponents/FavouriteClub'

import { LIGHT_GRAY_BORDER_COLOR, CHIP_BLUE_COLOR, AFFILIATE_DOMAINS } from '../../../../utils/constants'
import * as TRAINER_REQUESTS from '../../../../api/trainer'
import { refetchTrainerProfileObservable } from '../../../../utils/observables'
import GalleryImageDialog from '../../../common/GalleryImageDialog'

const Details = ({ bio, Photos, Licences }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const refetchTrainerProfile = refetchTrainerProfileObservable.useRefetchTrainerProfile()

  const [affiliatedClubs, setAffiliatedClubs] = useState([])
  const [licences, setLicences] = useState([])
  const [services, setServices] = useState([])
  const [galleryDialog, setGalleryDialog] = useState({
    open: false,
    image: null
  })

  useEffect(() => {
    if (!refetchTrainerProfile) return
    TRAINER_REQUESTS.getTrainerServices().then((result) => {
      setServices(result)
      refetchTrainerProfileObservable.setRefetchTrainerProfile(false)
    })
  }, [refetchTrainerProfile])

  useEffect(() => {
    setLicences(Licences)
  }, [Licences])

  useEffect(() => {
    TRAINER_REQUESTS.getTrainerServices().then((result) => {
      setServices(result)
    })

    TRAINER_REQUESTS.getCompanies().then((res) => {
      setAffiliatedClubs(res)
    })
  }, [])

  const renderLicences = useMemo(() => {
    return licences.map((licence, index) => {
      return (
        <Grid item key={index}>
          <Chip
            sx={{
              fontWeight: 500,
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
            color={licence.approved ? 'success' : 'warning'}
            variant="outlined"
            icon={licence.approved ? <Check /> : <AccessTime />}
            label={licence.description}
          />
        </Grid>
      )
    })
  }, [licences])

  const renderServices = useMemo(() => {
    return services
      .filter((el) => el.display)
      .map((service, index) => {
        return (
          <Grid item key={index}>
            <Chip
              sx={{ fontWeight: 500, backgroundColor: CHIP_BLUE_COLOR }}
              color="primary"
              variant="outlined"
              label={service.title}
            />
          </Grid>
        )
      })
  }, [services])

  const renderAffiliatedClubs = useMemo(() => {
    return affiliatedClubs.map((el, index) => {
      return (
        <Grid key={index} mr={5}>
          <Link style={{ textDecoration: 'none', cursor: 'pointer' }} to={'/' + el.domain}>
            <FavouriteClub avatar={el.avatarPhotoSrc} name={el.name} address={el.locationName} />
          </Link>
        </Grid>
      )
    })
  }, [affiliatedClubs])

  return (
    <>
      {galleryDialog.open && galleryDialog.image ? (
        <GalleryImageDialog
          image={galleryDialog.image}
          onClose={() =>
            setGalleryDialog({
              open: false,
              image: null
            })
          }
        />
      ) : null}
      <Grid
        container
        width="100%"
        display="flex"
        flexDirection={{ xs: 'column', lg: 'column', xl: 'column' }}
        pt={2}
        pb={4}
      >
        <Grid
          item
          display="flex"
          flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }}
          xs={12}
          borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
        >
          <Grid
            item
            pl={3}
            pr={3}
            lg={8}
            xs={12}
            display="flex"
            flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }}
          >
            <Grid width="100%" item pb={2} display="flex" flexDirection="column">
              <Grid pb={1}>
                <Typography fontWeight={500} color="black" fontSize={20}>
                  {t('userProfile.activity.detailComponent.bio')}
                </Typography>
              </Grid>
              <Grid className="bio">
                {bio ? (
                  <Linkify>
                    <Typography fontWeight={500} color={`${bio ? 'black' : '#979797'}`}>
                      {parse(bio) || t('helper.noInformation')}
                    </Typography>
                  </Linkify>
                ) : (
                  <Typography color="#979797" fontWeight={500} fontSize={17}>
                    {t('helper.noInformation')}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid pl={3} pr={3} xs={12} lg={4} item pb={2} display="flex" flexDirection="column">
            <Grid item pb={1.5}>
              <Typography fontWeight={500} color="black" fontSize={20}>
                {t('userProfile.activity.trainerDetailComponent.galery')}
              </Typography>
            </Grid>
            <Grid
              container
              flexWrap="wrap"
              display="flex"
              flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }}
            >
              <Grid container display="flex" flexWrap="wrap" spacing={1} pr={1} pb={1}>
                {Photos.length > 0 ? (
                  Photos.filter((el) => el.isPhoto).map((photo, index) => {
                    return (
                      <Grid
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setGalleryDialog({ open: true, image: photo.src })}
                        key={index}
                        item
                        xs={4}
                        lg={4}
                      >
                        <img
                          width="100%"
                          height={100}
                          lazy
                          src={photo.src}
                          style={{ objectFit: 'cover' }}
                        />
                      </Grid>
                    )
                  })
                ) : (
                  <Typography pl={1} color="#979797" fontWeight={500} fontSize={17}>
                    {t('helper.noInformation')}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          width="100%"
          pl={3}
          pr={3}
          pb={2}
          pt={2}
          borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
          display="flex"
          flexDirection="column"
        >
          <Grid pb={1.5}>
            <Typography fontWeight={500} color="black" fontSize={20}>
              {t('userProfile.activity.trainerDetailComponent.qualifications')}
            </Typography>
          </Grid>
          <Grid
            container
            display="flex"
            flexWrap="wrap"
            spacing={1}
            maxWidth={{ lg: '40%', xs: '90%' }}
          >
            {services.filter((el) => el.display).length > 0 ? (
              renderServices
            ) : (
              <Typography pl={1} color="#979797" fontWeight={500} fontSize={17}>
                {t('helper.noInformation')}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item width="100%" pl={3} pr={3} pb={2} pt={2} display="flex" flexDirection="column">
          <Grid pb={1}>
            <Typography fontWeight={500} color="black" fontSize={20}>
              {t('userProfile.activity.trainerDetailComponent.licences')}
            </Typography>
          </Grid>
          <Grid container display="flex" flexWrap="wrap" spacing={1}>
            {licences.length > 0 ? (
              renderLicences
            ) : (
              <Typography color="#979797" fontWeight={500} pl={1} pt={1} fontSize={17}>
                {t('helper.noInformation')}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid
          pt={2}
          mr={3}
          ml={3}
          borderTop={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
          item
          width="100%"
          pb={1.5}
        >
          <Grid item xs={12} display="flex" flexDirection="row" alignItems="center">
            <Typography fontWeight={500} color="black" fontSize={20}>
              {t('userProfile.activity.trainerDetailComponent.needLicence')}
            </Typography>
            <Button onClick={() => { window.open('https://abcfitness.ro/', '_blank') }} variant="text" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 'auto', marginRight: 4 }}>
              <Avatar
                sx={{ width: 56, height: 56 }}
                src={require('../../../../assets/abc.jpeg')}
              />
              <Typography pl={1} variant="h6" fontWeight="500" sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>ABC Fitness School <ArrowForwardIos fontSize='small' sx={{ pl: 1 }} /> </Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid
          pt={2}
          mr={3}
          ml={3}
          borderTop={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
          item
          width="100%"
        >
          <Grid pb={1.5}>
            <Typography fontWeight={500} color="black" fontSize={20}>
              {t('userProfile.activity.trainerDetailComponent.relatedClubs')}
            </Typography>
          </Grid>
          <Grid sx={{ overflowX: 'scroll' }} display="flex" flexDirection="row" pb={2}>
            {affiliatedClubs.length > 0 ? (
              renderAffiliatedClubs
            ) : (
              <Typography color="#979797" fontWeight={500} fontSize={17}>
                {t('helper.noInformation')}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid >
    </>
  )
}

export default Details
