import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Grid, Container } from '@mui/material'
import _ from 'lodash'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import moment from 'moment'
import ReactGA from 'react-ga'

import SearchSection from './subcomponents/SearchSection'
import ClubList from './subcomponents/ClubList'
import TrainerList from './subcomponents/TrainerList'
import Service from './subcomponents/Service'
import Events from './subcomponents/Events'
import Filter from './subcomponents/Filters'

import * as EXPLORE_REQUESTS from '../../api/explore'
import * as USER_REQUESTS from '../../api/user'
import * as AUTH_REQUESTS from '../../api/auth'
import { useAuthentication } from '../../context/auth-context'
import { BUSINESS_ROLES, ROLE_BUSINESS_ADMINISTRATOR, ROLE_TRAINER, SUBSCRIPTION_PAYMENT_STATUS } from '../../utils/constants'
import { DialogOnboarding } from '../Dashboard/subcomponents'

const Explore = () => {
  const theme = useTheme()
  const matchesTablet = useMediaQuery(theme.breakpoints.down('md'))
  const { user } = useAuthentication()
  const isTrainer = user && user.role && user.role.toLowerCase() === ROLE_TRAINER.toLowerCase()
  const isClub =
    user && user.role && user.role.toLowerCase() === ROLE_BUSINESS_ADMINISTRATOR.toLowerCase()
  const isEmployee =
    user && user.role && user.role.toLowerCase() === BUSINESS_ROLES.BUSINESS_EMPLOYEE.toLowerCase()

  const [onBoarding, setOnBoarding] = useState(false)
  const [userSubscriptions, setUserSubscriptions] = useState('unset')
  const [clubs, setClubs] = useState([])
  const [trainers, setTrainers] = useState([])
  const [trainersCount, setTrainersCount] = useState(0)
  const [clubsCount, setClubsCount] = useState(0)
  const [servicesCount, setServicesCount] = useState(0)
  const [trainersLoading, setTrainersLoading] = useState(false)
  const [clubLoading, setClubLoading] = useState(false)
  const [services, setServices] = useState([])
  const [servicesLoading, setServicesLoading] = useState(false)
  const [events, setEvents] = useState([])
  const [eventsLoading, setEventsLoading] = useState(false)
  const [eventsCount, setEventsCount] = useState(0)
  const [showEvents, setShowEvents] = useState(true)
  const [showClubs, setShowClubs] = useState(false)
  const [showTrainers, setShowTrainers] = useState(true)
  const [showServices, setShowServices] = useState(true)
  const [extraFilters, setExtraFilters] = useState({
  })
  const [clubsPagination, setClubsPagination] = useState({
    from: 0,
    limit: matchesTablet ? 5 : 10
  })
  const [trainersPagination, setTrainersPagination] = useState({
    from: 0,
    limit: matchesTablet ? 5 : 10
  })
  const [servicesPagination, setServicesPagination] = useState({
    from: 0,
    limit: matchesTablet ? 5 : 10
  })
  const [eventsPagination, setEventsPagination] = useState({
    from: 0,
    limit: matchesTablet ? 5 : 10
  })
  const [showSelfIn, setShowSelfIn] = useState(false)
  const [showSelfOut, setShowSelfOut] = useState(false)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    setShowSelfIn(params.get('selfIn') === 'show')
    setShowSelfOut(params.get('selfOut') === 'show')

    setTimeout(() => {
      setShowSelfIn(false)
      setShowSelfOut(false)
    }, 10000)

    const notParsedRelayId = params.get('relayId')

    if (notParsedRelayId) {
      setRelayId(Number(notParsedRelayId))
    }
  }, [window.location.search])

  useEffect(() => {
    if (!user) return
    handleGetMe()
  }, [user])

  const handleGetMe = () => {
    return AUTH_REQUESTS.getMe().then((result) => {
      if (!result.onboardingCompleted) {
        setOnBoarding(true)
      }
    })
  }

  useEffect(() => {
    if (!user || isTrainer || isClub || isEmployee) return setUserSubscriptions([])
    handleGetUserSubscriptions()
  }, [isClub, isTrainer, isEmployee, user])

  const handleGetUserSubscriptions = useCallback(async () => {
    try {
      if (!user) return setUserSubscriptions([])
      const subscriptionsResponse = await USER_REQUESTS.getSubscriptions({
        userId: user.id
      })
      setUserSubscriptions(subscriptionsResponse)
    } catch (e) {
      console.error(e)
    }
  }, [user])

  const clearSearch = () => {
    setTrainers([])
    setTrainersCount(0)
    setClubs([])
    setClubsCount(0)
    setServices([])
    setServicesCount(0)
    setEvents([])
    setEventsCount(0)
    setClubsPagination({
      from: 0,
      limit: matchesTablet ? 5 : 10
    })
    setTrainersPagination({
      from: 0,
      limit: matchesTablet ? 5 : 10
    })
    setServicesPagination({
      from: 0,
      limit: matchesTablet ? 5 : 10
    })
    setEventsPagination({
      from: 0,
      limit: matchesTablet ? 5 : 10
    })
  }

  const getServices = (filters) => {
    setServicesLoading(true)
    return EXPLORE_REQUESTS.getServices(filters)
      .then((response) => {
        const allServices = services.concat(
          response.rows.map((service) => ({
            ...service,
            fromCompany: !!service.Company,
            id: service.id,
            services: service.title,
            club: service.Company
              ? service.Company.name
              : `${service.Trainer.firstName} ${service.Trainer.lastName}`,
            text: service.description,
            price: service.priceRon
          }))
        )

        setServices(_.uniqBy(allServices, 'id'))
        setServicesCount(response.count)
        setServicesLoading(false)
      })
      .finally(() => {
        setServicesLoading(false)
      })
  }

  const computeStatus = (subscription, eventStartDate) => {
    let currentTime = new Date().getTime()

    if (eventStartDate) {
      if (subscription.Pause && (moment(eventStartDate).utc().isBetween(moment(subscription.Pause.startDate).utc(), moment(subscription.Pause.endDate).utc(), 'day', '[]'))) {
        return "PAUSED"
      }
    }

    if (!eventStartDate && subscription.Pause && (currentTime >= new Date(subscription.Pause.startDate).getTime() && currentTime <= new Date(subscription.Pause.endDate).getTime())) {
      return "PAUSED"
    }

    if (moment(eventStartDate ? eventStartDate : null).utc().isAfter(moment(subscription.validUntil).utc())) {
      return "EXPIRED"
    }

    if (subscription.status !== SUBSCRIPTION_PAYMENT_STATUS.CONFIRMED) {
      return "INACTIVE"
    }

    return "ACTIVE"
  }

  const getEvents = (filters, refetch, userSubscriptionsToUse) => {
    if (userSubscriptionsToUse === 'unset') return
    setEventsLoading(true)
    return EXPLORE_REQUESTS.getEvents(filters)
      .then((response) => {
        const allEvents = (refetch ? [] : events).concat(
          response.rows.map((el) => ({
            id: el.id,
            title: el.name || '-',
            firstChip: el.tags.split(',')[0] || null,
            thirdChip: el.tags.split(',')[1] || null,
            secondChip: el.tags.split(',')[2] || null,
            fullStart: el.start,
            onlineBooking: el.onlineBooking,
            book: el.book,
            cancel: el.cancel,
            start: moment(el.start).utc().format('HH:mm'),
            duration: el.duration,
            custom: true,
            backgroundColor: el.backgroundColor || '#0277bd',
            participants: el.EventsParticipants.filter(participant => !participant.isInWaitingList),
            maxParticipants: el.maxParticipants,
            minParticipants: el.minParticipants,
            withWaitingList: el.withWaitingList,
            maxWaitingList: el.maxWaitingList,
            inWaitingList: el.EventsParticipants.filter(participant => participant.isInWaitingList).length,
            public: el.access,
            trainers: el.EventsTrainers,
            trainer: el.Trainer,
            company: el.Company,
            payment: el.payment,
            value: el.value,
            booked: !user ? false : el.EventsParticipants.map(el => el.Participant.id).includes(user.id),
            EventsParticipants: el.EventsParticipants.map(el => el.Participant),
            EventSubscriptions: el.EventSubscriptions.map(el => el.Subscription),
            cost: el.cost,
            showParticipantsName: el.showParticipantsName,
            cannotOpenDetailsDialog: ((isTrainer && el.Trainer && user.id === el.Trainer.id) || (isClub && el.Company && user.companyId === el.Company.id) ? false : !el.onlineBooking ? true : (el.accessOnlyOnStart && moment().diff(moment(el.start)) < 0) ? true : false),
            hasValidSubscription: (
              (isTrainer || isClub) ||
              (el.EventSubscriptions.length === 0) ||
              (userSubscriptionsToUse.filter(sub => {
                return computeStatus(sub, el.start) === "ACTIVE" && moment(el.start).diff(moment(sub.validFrom)) > 0 && moment(el.start).utc().isBefore(moment(sub.validUntil).utc()) && !sub.isDeleted
              }).map(sub => sub.Subscription.id).some(sub => el.EventSubscriptions.map(sub => sub.Subscription.id).includes(sub))) ||
              (userSubscriptionsToUse.filter(sub => {
                const dayLimit = moment(sub.validFrom).utc().add(sub.Subscription.paymentDeadline - 1, 'days').endOf('day')
                return computeStatus(sub, el.start) === "INACTIVE" && (!sub.Subscription.confirmation ? dayLimit.diff(moment(el.start).utc()) >= 0 && moment(el.start).utc().date() >= moment(sub.validFrom).utc().date() : false)
              }).map(sub => sub.Subscription.id).some(sub => el.EventSubscriptions.map(sub => sub.Subscription.id).includes(sub)))),
            avatar:
              el.type !== 'DETAILED'
                ? 'https://i.kinja-img.com/gawker-media/image/upload/t_original/ijsi5fzb1nbkbhxa2gc1.png'
                : '',
            backgroundImage: el.sportType
          }))
        )

        setEvents(_.uniqBy(allEvents, 'id'))
        setEventsCount(response.count)
        setEventsLoading(false)
      })
      .finally(() => {
        setEventsLoading(false)
      })
  }

  const getCompanies = (filters) => {
    setClubLoading(true)
    return EXPLORE_REQUESTS.getCompanies(filters)
      .then((response) => {

        const allClubs =
          response.rows.map((company) => ({
            id: company.id,
            clubName: company.name,
            domain: company.domain,
            reviewsCount: company.reviewsCount,
            reviewsAverage: company.reviewsAverage,
            address: company.locationName,
            verified: company.registrationCertificateApproved && company.identityCardApproved,
            avatarPhotoSrc: company.avatarPhotoSrc,
            defaultSportType: company.defaultSportType
          }))

        setClubsCount(response.count)
        setClubs(allClubs)
        setClubLoading(false)
      })
      .finally(() => {
        setClubLoading(false)
      })
  }

  const getTrainers = (filters) => {
    setTrainersLoading(true)
    return EXPLORE_REQUESTS.getTrainers(filters)
      .then((response) => {
        const allTrainers = trainers.concat(
          response.rows.map((trainer) => ({
            id: trainer.id,
            domain: trainer.domain,
            locationName: trainer.locationName,
            trainerName: `${trainer.firstName} ${trainer.lastName}`,
            verified: trainer.isApproved,
            avatarPhotoSrc: trainer.avatarPhotoSrc,
            defaultSportType: trainer.defaultSportType
          }))
        )

        setTrainersCount(response.count)
        setTrainers(_.uniqBy(allTrainers, 'id'))
        setTrainersLoading(false)
      })
      .finally(() => {
        setTrainersLoading(false)
      })
  }

  const onSearch = (searchFields) => {
    clearSearch()
    setExtraFilters((prevValue) => {
      if (searchFields.search) {
        const { locationLat, locationLng, locationName, distance, ...restOfObject } = searchFields
        return { ...restOfObject }
      }
      return {
        ...prevValue,
        ...searchFields
      }
    })
    ReactGA.event({
      category: 'POST_LOGIN',
      action: 'EXPLORE_SEARCH',
      label: 'SUCCESS'
    })
  }

  useEffect(() => {
    if (!showServices) return

    getServices({ ...extraFilters, ...servicesPagination })
  }, [showServices, extraFilters, servicesPagination])


  // useEffect(() => {
  //   if (!showEvents || userSubscriptions === 'unset') return

  //   getEvents({ ...extraFilters, ...eventsPagination }, false, userSubscriptions)
  // }, [showEvents, userSubscriptions, extraFilters, eventsPagination])

  useEffect(() => {
    if (!showClubs) return

    getCompanies({ ...extraFilters, ...clubsPagination })
  }, [showClubs, extraFilters, clubsPagination])

  useEffect(() => {
    if (!showTrainers) return
    getTrainers({ ...extraFilters, ...trainersPagination })
  }, [showTrainers, extraFilters, trainersPagination])

  const renderClubs = useMemo(() => {
    if (!showClubs) return null

    return (
      <ClubList
        clubs={clubs}
        count={clubsCount}
        loading={clubLoading}
        onNextPage={() => {
          setClubsPagination({
            from: clubs.length,
            limit: matchesTablet ? 5 : 10
          })
        }}
      />
    )
  }, [clubs, showClubs, clubLoading, clubsCount])

  const renderServices = useMemo(() => {
    if (!showServices) return null

    return (
      <Service
        services={services}
        loading={servicesLoading}
        count={servicesCount}
        setServices={setServices}
        onNextPage={() => {
          setServicesPagination({
            from: services.length,
            limit: 10
          })
        }}
      />
    )
  }, [services, showServices, servicesLoading, servicesCount])

  const renderTrainers = useMemo(() => {
    if (!showTrainers) return null

    return (
      <TrainerList
        trainers={trainers}
        count={trainersCount}
        loading={trainersLoading}
        onNextPage={() => {
          setTrainersPagination({
            from: trainers.length,
            limit: 10
          })
        }}
      />
    )
  }, [trainers, showTrainers, trainersLoading, trainersCount])

  const renderEvents = useMemo(() => {
    if (!showEvents) return null

    return (
      <Events
        events={events}
        loading={eventsLoading}
        count={events.length}
        userSubscriptions={userSubscriptions}
        handleGetUserSubscriptions={handleGetUserSubscriptions}
        getEvents={(userSubscriptionsToUse) => {
          getEvents({ ...extraFilters, ...eventsPagination }, true, userSubscriptionsToUse)
        }}
        setEvents={setEvents}
        onNextPage={() => {
          setEventsPagination({
            from: events.length,
            limit: 10
          })
        }}
      />
    )
  }, [events, showEvents, extraFilters, eventsPagination, userSubscriptions, eventsLoading, eventsCount])

  const onFiltersChangeHandler = (data) => {
    if (
      data.distance !== extraFilters.distance ||
      data.verified !== extraFilters.verified ||
      data.reviewsFrom !== extraFilters.reviewsFrom ||
      !_.isEqual(data.equipments, extraFilters.equipments) ||
      !_.isEqual(data.sportTypes, extraFilters.sportTypes)
    ) {
      clearSearch()
    }

    setExtraFilters((prevFilters) => ({
      ...prevFilters,
      ...data
    }))
  }

  const renderOnBoarding = useMemo(() => {
    if (!onBoarding) return null

    return <DialogOnboarding onClose={() => setOnBoarding(false)} />
  }, [onBoarding])

  return (
    <Grid container>
      <SearchSection onSearch={onSearch} />
      {/* {renderOnBoarding} */}
      <Container maxWidth="xl">
        <Grid item xs={12} pt={8} pb={8}>
          <Filter
            onDistanceChange={(value) => {
              onFiltersChangeHandler({ distance: value / 1000 })
            }}
            onShowClubChange={setShowClubs}
            onShowTrainerChange={setShowTrainers}
            onShowEventChange={setShowEvents}
            onShowServiceChange={setShowServices}
            onFiltersChange={onFiltersChangeHandler}
          />
          {renderClubs}
          {renderTrainers}
          {renderServices}
          {renderEvents}
        </Grid>
      </Container>
    </Grid>
  )
}

export default Explore
