/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { Close, Add } from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
  DialogActions,
  TextField,
  IconButton
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import moment from 'moment'

import { InputGenerator } from '../../common'
import { MAIN_MEASUREMENTS, PRIMARY_COLOR } from '../../../utils/constants'

import * as MEASUREMENT_TYPES_REQUESTS from '../../../api/measurementType'

const CreateMeasurements = ({ handleClose, handleCreateMeasurement, type, userId }) => {
  const [from, setFrom] = useState(moment())
  const [measurementType, setMeasurementType] = useState()
  const [value, setValue] = useState()
  const [optionsFetched, setOptionsFetched] = useState(false)
  const [measurementOptions, setMeasurementOptions] = useState([])

  const { t } = useTranslation()

  useEffect(() => {
    handleMeasurementOptions()
  }, [type])

  const handleAdd = () => {
    const objectToAdd = {
      from: moment(from).format('YYYY-MM-DD'),
      secondaryType: measurementType || 'secondary',
      value: Number(value),
      mainType: type
    }

    handleCreateMeasurement(objectToAdd)
  }

  const handleMeasurementOptions = async () => {
    if (type === MAIN_MEASUREMENTS.FAT_PERCENTAGE) {
      setMeasurementOptions([
        { value: 'lombar', label: t('measurements.Lumbar') },
        { value: 'gamba', label: t('measurements.Calf') },
        { value: 'coapsa', label: t('measurements.Thigh') },
        { value: 'midaxillar', label: t('measurements.Midaxillary') },
        { value: 'abodminal', label: t('measurements.Abdominal') },
        { value: 'subscapular', label: t('measurements.Subscapular') },
        { value: 'suprailiac', label: t('measurements.Suprailiac') },
        { value: 'triceps', label: t('measurements.Triceps') },
        { value: 'piept', label: t('measurements.Chest') }
      ])
      setMeasurementType('lombar')
      setOptionsFetched(true)
    } else if (type === MAIN_MEASUREMENTS.WEIGHT) {
      setMeasurementOptions([{ value: 'weight', label: 'Weight' }])
      setMeasurementType('weight')
      setOptionsFetched(true)
    } else if (type === MAIN_MEASUREMENTS.MEASUREMENT) {
      setMeasurementOptions([
        { value: 'gat', label: t('measurements.Neck') },
        { value: 'fesieri', label: t('measurements.Glutes') },
        { value: 'gamba_dreapta', label: t('measurements.CalfR') },
        { value: 'gamba_stanga', label: t('measurements.CalfL') },
        { value: 'sold', label: t('measurements.Hips') },
        { value: 'talie', label: t('measurements.Waist') },
        { value: 'piept', label: t('measurements.Chest') },
        { value: 'antebrat_stang', label: t('measurements.ForearmL') },
        { value: 'antebrat_drept', label: t('measurements.ForearmR') },
        { value: 'coapsa_dreapta', label: t('measurements.ThighR') },
        { value: 'coapsa_stanga', label: t('measurements.ThighL') },
        { value: 'biceps_drept', label: t('measurements.BicepsR') },
        { value: 'biceps_stang', label: t('measurements.BicepsL') }
      ])
      setMeasurementType('gat')
      setOptionsFetched(true)
    } else if (type === MAIN_MEASUREMENTS.CUSTOM) {
      const types = await MEASUREMENT_TYPES_REQUESTS.getMeasurementsTypes({}, userId)
      if (types && types.length > 0) {
        setMeasurementOptions(types.map((el) => ({ value: el.name, label: el.name })))
        setMeasurementType(types[0].name)
        setOptionsFetched(true)
      }
    }
  }

  if (!optionsFetched) return null

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth={'sm'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        p={4}
        pl={{ xs: 2, md: 2, lg: 7 }}
        pr={{ xs: 2, md: 2, lg: 3 }}
      >
        <Grid container display="flex" flexDirection="row">
          <Grid item xs={12} display="flex" flexDirection="row" justifyContent="flex-end">
            <IconButton style={{ color: 'black' }} onClick={handleClose}>
              <Close fontSize="medium" color="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container display="flex" flexDirection="column" p={{ lg: 1, xs: 0 }}>
          <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" pb={1}>
            <Typography variant="h4" fontWeight="bold">
              {t('measurements.addMeasurements')}
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" pb={3}>
            <Typography variant="h6" fontWeight="600">
              {t(`helper.${type.toLowerCase()}`)}
            </Typography>
          </Grid>
          <Grid item xs={12} pt={6}>
            <Grid container display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
              <Grid item xs={12} sm={6} pr={{ xs: 0, sm: 1 }}>
                <Grid container display="flex" flexDirection="column">
                  <Grid pb={1} item>
                    {
                      <Typography fontWeight={500} variant="p">
                        {t('measurements.from')}{' '}
                        <Typography variant="span" color={PRIMARY_COLOR}>
                          *
                        </Typography>
                      </Typography>
                    }
                  </Grid>
                </Grid>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      value={from}
                      onChange={(newValue) => setFrom(newValue)}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          sx={{
                            '.MuiIconButton-root': { marginRight: 1 }
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} pl={{ xs: 0, sm: 1 }} pt={{ xs: 2, sm: 0 }}>
                <Grid item>
                  <InputGenerator
                    handleOnChange={(event) => setMeasurementType(event.target.value)}
                    input={{
                      type: 'select',
                      label: t('measurements.measurements'),
                      mandatory: true,
                      placeholder: t('measurements.selectMeasurement'),
                      value: measurementType,
                      values: measurementOptions
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} pt={{ xs: 2, sm: 3 }} pb={6}>
            <Grid container display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
              <Grid item xs={12} sm={6}>
                <Grid item>
                  <InputGenerator
                    handleOnChange={(event) => setValue(event.target.value)}
                    input={{
                      type: 'number',
                      label: t('measurements.value'),
                      mandatory: true,
                      placeholder: t('measurements.enterValue'),
                      value: value
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: 4 }}>
        <Grid
          container
          display="flex"
          flexDirection={{ lg: 'row', xs: 'column' }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item pr={{ xs: 0, lg: 1 }}>
            <Button sx={{ minWidth: 260 }} variant="outlined" color="primary" onClick={handleClose}>
              {t('measurements.cancel')}
            </Button>
          </Grid>
          <Grid item pl={{ xs: 0, lg: 1 }} mt={{ lg: 0, xs: 1 }}>
            <Button
              disabled={!from || !value || !measurementType}
              sx={{ minWidth: 260 }}
              variant="contained"
              color="primary"
              onClick={handleAdd}
            >
              {t('measurements.add')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default CreateMeasurements
