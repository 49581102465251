import React, { useEffect, useState } from 'react'
import { Dialog, IconButton, Button, Typography, Grid, TextField, Autocomplete, Box, Avatar, Chip, } from '@mui/material'
import { Close, StackedLineChart, Percent, EventRepeat, Money, Receipt } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import moment from 'moment'

import { Toggle } from '../../common'

import { useAuthentication } from '../../../context/auth-context'
import {
    GRAY_BG_COLOR,
    GRAY_TEXT_COLOR,
    SECONDARY_TEXT_COLOR,
    ROLE_USER,
    SUBSCRIPTION_PAYMENT_STATUS,
    DATE_FORMAT,
    VOUCHER_TYPES,
    PAYMENT_METHOD,
    COMPANIES_TRAINERS_REQUEST_STATUS
} from '../../../utils/constants'
import * as COMPANY_REQUESTS from '../../../api/company'
import * as USER_REQUESTS from '../../../api/user'
import * as USER_SUBSCRIPTIONS from '../../../api/userSubscriptions'
import * as VOUCHER_REQUESTS from '../../../api/voucher'
import { numberFomatter } from '../../../utils/helpers/numberFomatter'

const AddClient = ({
    onCancel,
    onConfirm,
    currency
}) => {
    const theme = useTheme()
    const matchesTablet = useMediaQuery(theme.breakpoints.down('md'))
    const { t } = useTranslation()
    const { user } = useAuthentication()

    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState()
    const [users, setUsers] = useState([])
    const [subscriptions, setSubscriptions] = useState([])
    const [selectedUser, setSelectedUser] = useState()
    const [selectedSubscription, setSelectedSubscription] = useState()
    const [startDate, setStartDate] = useState(moment())
    const [endDate, setEndDate] = useState(moment())
    const [paid, setPaid] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState()
    const [limitedActivationAvailable, setLimitedAvailable] = useState()
    const [selectedVoucher, setSelectedVoucher] = useState()
    const [vouchers, setVouchers] = useState([])
    const [userVouchers, setUserVouchers] = useState([])
    const [selectedUserVoucher, setSelectedUserVoucher] = useState()
    const [trainers, setTrainers] = useState([])
    const [selectedTrainer, setSelectedTrainer] = useState()

    const renderTypeIcon = type => {
        return type === VOUCHER_TYPES.CONSECUTIVE_BUYS ? <StackedLineChart /> : type === VOUCHER_TYPES.DISCOUNT ? <Percent /> : <EventRepeat />
    }

    const onCreateHandler = () => {
        setLoading(true)
        const data = {
            userId: selectedUser.id,
            subscriptionId: selectedSubscription.id,
            status: paid ? SUBSCRIPTION_PAYMENT_STATUS.CONFIRMED : SUBSCRIPTION_PAYMENT_STATUS.PENDING,
            validFrom: moment(startDate).toISOString(),
            validUntil: moment(endDate).toISOString(),
            limitedActivationUsed: selectedSubscription.limitedActivation - limitedActivationAvailable,
            companyId: user.companyId,
            currency: currency,
            ...(selectedTrainer ? { selectedTrainerId: selectedTrainer } : {})
        }

        if (selectedVoucher) {
            data.voucherId = selectedVoucher
        }

        if (selectedUserVoucher) {
            data.userVoucherId = selectedUserVoucher
        }

        if (paid && paymentMethod) {
            data.type = paymentMethod
        }

        return USER_SUBSCRIPTIONS.createAdmin(data)
            .then(() => {
                return onConfirm()
            })
            .finally(() => {
                setLoading(false)
                onCancel()
            })
    }

    useEffect(() => {
        COMPANY_REQUESTS.getAllMembers(user.companyId)
            .then(response => {
                setTrainers(response.filter(el => el.status === COMPANIES_TRAINERS_REQUEST_STATUS.APPROVED))
            })
    }, [user])

    useEffect(() => {
        if (!user) return

        if (selectedSubscription) {
            VOUCHER_REQUESTS.getAll({ subscriptionId: selectedSubscription.id })
                .then(data => {
                    setVouchers(data)
                })
        }

        if (selectedUser) {
            VOUCHER_REQUESTS.getAllUsers({ userId: selectedUser.id })
                .then(data => {
                    setUserVouchers(data)
                })
        }
    }, [selectedSubscription, selectedUser])

    const getAllUsers = (filter) => {
        const query = {}

        if (filter) {
            query.search = filter
        }

        if (!filter) {
            setUsers([])
            return
        }

        return USER_REQUESTS.getAllUsers({ ...query, role: ROLE_USER }).then((response) => {
            setUsers(response)
        })
    }

    const getAllCompanySubscriptions = () => {
        return COMPANY_REQUESTS.getAllSubscriptions(user.companyId).then((response) => {
            setSubscriptions(response)
        })
    }

    useEffect(() => {
        getAllCompanySubscriptions()
    }, [user])

    useEffect(() => {
        getAllUsers(filter)
    }, [filter])

    const renderVoucherDiscount = () => {
        const price = numberFomatter(Number(selectedSubscription.priceRon))
        if (!selectedVoucher && !selectedUserVoucher) return <Typography variant="p" color={GRAY_TEXT_COLOR}>
            {price} {currency}
        </Typography>

        let voucher

        if (selectedVoucher) {
            voucher = vouchers.find(el => el.id === selectedVoucher)
        }

        if (selectedUserVoucher) {
            let userVoucher = userVouchers.find(el => el.Voucher.id === selectedUserVoucher)

            if (!userVoucher.Voucher) return <Typography variant="p" color={GRAY_TEXT_COLOR}>
                {price} {currency}
            </Typography>

            voucher = userVoucher.Voucher
        }

        const newPrice = voucher.hasFixedDiscount ? numberFomatter(Number(selectedSubscription.priceRon) - Number(voucher.discount)) : numberFomatter(Number(selectedSubscription.priceRon) - (Number(selectedSubscription.priceRon) * Number(voucher.discount) / 100))

        return (
            <>
                <Typography variant="p" color={GRAY_TEXT_COLOR}>
                    <s>{price} {currency}</s>
                </Typography>
                <Typography variant="p" color={GRAY_TEXT_COLOR}>
                    {newPrice} {currency}
                </Typography>
            </>
        )
    }

    const renderTrainers = () => {
        if (!trainers.length) return null

        return <Grid item xs={12} pt={2}>
            <Typography variant="p" fontWeight={500}>Trainers</Typography>
            <Grid container pt={1} spacing={1}>
                {trainers.map(el => {
                    const isSelected = el.id === selectedTrainer

                    return <Grid item key={el.id} onClick={() => {
                        if (!isSelected) {
                            setSelectedTrainer(el.id)
                        } else {
                            setSelectedTrainer()
                        }
                    }}>
                        <Chip
                            variant={isSelected ? "primary" : "outlined"}
                            color="primary"
                            label={`${el.firstName} ${el.lastName}`}
                            avatar={<Avatar alt={el.firstName} src={el.avatarPhotoSrc} />}
                        />
                    </Grid>
                })}

            </Grid>
        </Grid>
    }

    return (
        <Dialog open maxWidth="sm" fullScreen={matchesTablet} fullWidth PaperProps={{ ...(matchesTablet ? { sx: { borderRadius: 0 } } : {}) }}>
            <Grid container p={4}>
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item
                            xs={10}
                            md={10}
                            lg={10}
                            pr={{ xs: 0, md: 0, lg: 2 }}
                            pl={{ xs: 0, md: 0, lg: 2 }}>
                            <Typography variant="h4" fontWeight={500} color={'black'}>
                                {t('clients.addSub')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                            <IconButton onClick={onCancel}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    pt={4}
                    pb={4}
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}
                >
                    <Grid item xs={12}>
                        <Box pb={0.5}>
                            <Typography variant="p" fontWeight={500} >
                                {t('employees.selectUser')}
                            </Typography>
                        </Box>
                        <Autocomplete
                            options={users.map((el) => ({
                                label: `${el.firstName} ${el.lastName}`,
                                avatar: el.avatarPhotoSrc,
                                phoneNumber: el.phoneNumber,
                                value: el.id,
                                id: el.id,
                                firstName: el.firstName,
                                lastName: el.lastName
                            }))}
                            isOptionEqualToValue={(option, value) => {
                                return option.value === value.value
                            }}
                            autoHighlight
                            value={selectedUser}
                            renderOption={(props, option) => {
                                return (
                                    <Box
                                        {...props}
                                        sx={{ display: 'flex', flexDirection: 'row' }}
                                        key={option.value}
                                    >
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {option.avatar ? (
                                                <Avatar style={{ width: 50, height: 50 }} src={option.avatar} />
                                            ) : (
                                                <Box
                                                    sx={{
                                                        width: 50,
                                                        height: 50,
                                                        backgroundColor: GRAY_BG_COLOR,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        borderRadius: 25
                                                    }}
                                                >
                                                    <Typography fontWeight="bold" color={GRAY_TEXT_COLOR}>
                                                        {option.firstName.charAt(0)} {option.lastName.charAt(0)}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 1 }}>
                                            <Typography fontWeight={500}>{option.label}</Typography>
                                            <Typography fontWeight={500}>{option.phoneNumber}</Typography>
                                        </Box>
                                    </Box>
                                )
                            }}
                            filterOptions={(options, state) => options}
                            onInputChange={(event) => {
                                setFilter(event.target.value)
                            }}
                            onChange={(_, newValue) => {
                                if (newValue) {
                                    setSelectedUser({ ...newValue })
                                }
                                else {
                                    setSelectedUser()
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    placeholder={t('businessEditProfile.searchTrainer')}
                                    {...params}
                                    InputProps={{ ...params.InputProps, endAdornment: null }}
                                    inputProps={{
                                        ...params.inputProps,
                                        style: {
                                            marginLeft: 6,
                                            padding: 11.5,
                                            color: SECONDARY_TEXT_COLOR,
                                            fontWeight: 500
                                        }
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} pt={2}>
                        <Box pb={0.5}>
                            <Typography variant="p" fontWeight={500} >
                                {t('clients.selectSub')}
                            </Typography>
                        </Box>
                        <Autocomplete
                            options={subscriptions.map((el) => ({
                                label: el.name,
                                id: el.id,
                                duration: el.duration,
                                limitedActivation: el.inHouseCredits,
                                priceRon: el.priceRon
                            }))}
                            isOptionEqualToValue={(option, value) => {
                                return option.id === value.id
                            }}
                            autoHighlight
                            noOptionsText={t('employees.searchLocation')}
                            renderOption={(props, option) => {
                                return (
                                    <Box
                                        {...props}
                                        sx={{ display: 'flex', flexDirection: 'row' }}
                                        key={option.value}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 1 }}>
                                            <Typography fontWeight={500}>{option.label}</Typography>
                                        </Box>
                                    </Box>
                                )
                            }}
                            onChange={(_, newValue) => {
                                if (newValue) {
                                    setEndDate(moment(startDate, DATE_FORMAT).add(Number(newValue.duration || 0), 'days'))
                                    setLimitedAvailable(newValue.limitedActivation)
                                    setSelectedSubscription(newValue)
                                }
                                else {
                                    setSelectedSubscription()
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    placeholder={'Search subscription'}
                                    {...params}
                                    InputProps={{ ...params.InputProps, endAdornment: null }}
                                    inputProps={{
                                        ...params.inputProps,
                                        style: {
                                            marginLeft: 6,
                                            padding: 11.5,
                                            color: SECONDARY_TEXT_COLOR,
                                            fontWeight: 500
                                        }
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    {selectedSubscription && selectedSubscription.limitedActivation ? <Grid item pt={2} xs={12} display="flex" flexDirection="row">
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Box pb={0}>
                                    <Typography variant="p" fontWeight={500}>{t('clients.creditsAv')}</Typography>
                                </Box>
                                <TextField
                                    fullWidth
                                    type="number"
                                    value={limitedActivationAvailable}
                                    onChange={event => {
                                        setLimitedAvailable(Number(event.target.value))
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="p" fontWeight={500}>{t('clients.creditsTotal')}</Typography>
                                <TextField
                                    fullWidth
                                    type="number"
                                    disabled
                                    value={selectedSubscription.limitedActivation || 0}
                                />
                            </Grid>
                        </Grid>
                    </Grid> : null}
                    <Grid item pt={2} xs={12} display="flex" flexDirection="row">
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Typography variant="p" fontWeight={500}>{t('clients.validFrom')}</Typography>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        inputFormat="dd/MM/yyyy"
                                        value={startDate}
                                        onChange={(newValue) => {
                                            setStartDate(newValue)
                                        }}
                                        renderInput={(params) => <TextField
                                            fullWidth
                                            sx={{
                                                '.MuiIconButton-root': { marginRight: 1 }
                                            }}
                                            {...params}
                                        />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="p" fontWeight={500}>{t('clients.validUntil')}</Typography>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        inputFormat="dd/MM/yyyy"
                                        value={endDate}
                                        onChange={(newValue) => {
                                            setEndDate(newValue)
                                        }}
                                        renderInput={(params) => <TextField
                                            fullWidth
                                            sx={{
                                                '.MuiIconButton-root': { marginRight: 1 }
                                            }}
                                            {...params}
                                        />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} pt={2}>
                        <Typography variant="p" fontWeight={500}>{t('clients.paidNormal')}</Typography>
                        <Box display="flex" flexDirection="row">
                            <Toggle defaultChecked={paid} onClick={() => setPaid(prevValue => !prevValue)} /> <Typography pl={1}>{paid ? t('clients.subPaid') : t('clients.subNotPaid')}</Typography>
                        </Box>

                        {paid ? <Box pt={1} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {Object.values(PAYMENT_METHOD).map((el) => {
                                if (el === PAYMENT_METHOD.CASH) {
                                    return (
                                        <Chip
                                            key={el}
                                            icon={<Money />}
                                            sx={{
                                                fontWeight: 500,
                                            }}
                                            onClick={() => setPaymentMethod(PAYMENT_METHOD.CASH)}
                                            color={paymentMethod === PAYMENT_METHOD.CASH ? "success" : "primary"}
                                            variant={"outlined"}
                                            label={t('subscriptionModal.cash')}
                                        />
                                    )
                                }

                                if (el === PAYMENT_METHOD.BANK_TRANSFER) {
                                    return (
                                        <Chip
                                            key={el}
                                            icon={<Receipt />}
                                            sx={{
                                                fontWeight: 500,
                                            }}
                                            onClick={() => setPaymentMethod(PAYMENT_METHOD.BANK_TRANSFER)}
                                            color={paymentMethod === PAYMENT_METHOD.BANK_TRANSFER ? "success" : "primary"}
                                            variant={"outlined"}
                                            label={t('subscriptionModal.bankTransfer')}
                                        />
                                    )
                                }

                                if (el === PAYMENT_METHOD.CARD) {
                                    return (
                                        <Chip
                                            key={el}
                                            icon={<Avatar variant="square" src={require('../../../assets/pos.png')} style={{ width: 20, height: 20 }} />}
                                            onClick={() => setPaymentMethod(PAYMENT_METHOD.CARD)}
                                            sx={{
                                                fontWeight: 500,
                                            }}
                                            color={paymentMethod === PAYMENT_METHOD.CARD ? "success" : "primary"}
                                            variant={"outlined"}
                                            label={t('subscriptionModal.pos')}
                                        />
                                    )
                                }
                            })}
                        </Box> : null}
                    </Grid>
                    {vouchers.length || userVouchers.length ? <Grid item xs={12} pt={2}>
                        <Typography variant="p" fontWeight={500}>Voucher</Typography>
                        <Grid container pt={1} spacing={1}>
                            {vouchers.map(el => {
                                const isSelected = el.id === selectedVoucher

                                return <Grid item key={el.id} onClick={() => {
                                    setSelectedVoucher(isSelected ? null : el.id)
                                    setSelectedUserVoucher()
                                }}>
                                    <Chip
                                        variant={isSelected ? "primary" : "outlined"}
                                        color="primary"
                                        label={el.name + ' ' + (el.hasFixedDiscount ? el.discount + ' ' + `${currency}` : el.discount + ' %')}
                                        icon={renderTypeIcon(el.type)}
                                    />
                                </Grid>
                            })}
                            {userVouchers.map(el => {
                                const isSelected = el.id === selectedUserVoucher

                                return <Grid item key={el.Voucher.id} onClick={() => {
                                    setSelectedVoucher()
                                    setSelectedUserVoucher(isSelected ? null : el.id)
                                }}>
                                    <Chip
                                        variant={isSelected ? "primary" : "outlined"}
                                        color="primary"
                                        label={el.Voucher.name + ' ' + (el.Voucher.hasFixedDiscount ? el.Voucher.discount + ' ' + `${currency}` : el.Voucher.discount + ' %')}
                                        icon={renderTypeIcon(el.Voucher.type)}
                                    />
                                </Grid>
                            })}
                        </Grid>
                    </Grid> : null}
                    {selectedSubscription ? <Grid item xs={12} pt={1} display="flex" flexDirection="column">
                        <Typography variant="p" fontWeight={500}>Pret</Typography>
                        {renderVoucherDiscount()}
                    </Grid> : null}
                    {renderTrainers()}
                </Grid>

                <Grid
                    item
                    pt={4}
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}
                >
                </Grid>
                <Grid item xs={12} pt={3} pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}>
                    <Grid container display="flex" flexDirection="row" spacing={3}>
                        <Grid item xs={12} md={12} lg={6}>
                            <Button onClick={onCancel} fullWidth variant="outlined">
                                {t('clients.cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <Button
                                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                fullWidth
                                disabled={loading || !selectedSubscription || !selectedUser || (paid && !paymentMethod)}
                                onClick={() => {
                                    onCreateHandler()
                                }}
                                variant="contained"
                            >
                                {t('clients.save')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default AddClient
