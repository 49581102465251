import React, { useEffect, useState } from 'react'
import { Dialog, Grid, Typography, IconButton, Avatar, Box, TextField, Button } from '@mui/material'
import { Close } from '@mui/icons-material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import * as CHECKIN_REQUESTS from '../../api/checkin'

import { GRAY_TEXT_COLOR } from '../../utils/constants'

export default ({ data, onClose }) => {
    const { t } = useTranslation()

    const [checkIns, setCheckIns] = useState([])
    const [loading, setLoading] = useState()

    const updateCheckIn = (id, keyNumber) => {
        setLoading(id)
        return CHECKIN_REQUESTS.update(id, { keyNumber })
            .then(() => {
                const indexOfCheckIn = checkIns.findIndex(el => el.id === id)

                if (indexOfCheckIn > -1) {
                    let newCheckIns = [...checkIns]

                    newCheckIns[indexOfCheckIn].metadata.keyNumber = 'updated'
                    newCheckIns[indexOfCheckIn].keyNumber = 'updated'

                    setCheckIns(newCheckIns)
                }

                setLoading()
            })
    }

    useEffect(() => {
        setCheckIns([...data.map(checkIn => ({ ...checkIn, metadata: { keyNumber: checkIn.keyNumber || '' } }))])
    }, [data])

    return (
        <Dialog open onClose={() => { }} maxWidth="sm" fullWidth>
            <Grid container p={4} pt={2} pb={2}>
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item
                            xs={10}
                            md={10}
                            lg={10}>
                            <Typography variant="h5" fontWeight="bold">
                                {t('selfInEmployeeDialog.title')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                            <IconButton onClick={onClose}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} pt={3} pb={3}>
                        <Grid container spacing={4}>
                            {checkIns.map(el => {
                                const hasError = el.hasError
                                const errorReason = el.reason

                                return (
                                    <Grid key={el.id} item xs={12}>
                                        <Box style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                            <Avatar src={el.user.avatarPhotoSrc}>{el.user.firstName[0]}{el.user.lastName[0]}</Avatar>
                                            <Box pl={2}>
                                                <Box>
                                                    <Typography variant="span" gutterBottom fontWeight="bold" color='black'>{el.user.firstName} {el.user.lastName}</Typography>
                                                </Box>
                                                <Typography variant="body1" fontWeight="bold" color={hasError ? 'error' : GRAY_TEXT_COLOR}>{!hasError ? t('selfInEmployeeDialog.checkedAt', {
                                                    time: moment(el.createdAt).format('HH:mm'),
                                                    subscriptionName: el.name
                                                }) : t('selfInEmployeeDialog.failedCheckedAt', {
                                                    time: moment(el.createdAt).format('HH:mm')
                                                })}, {errorReason}</Typography>
                                            </Box>
                                        </Box>
                                        {(!el.keyNumber && !hasError) ? <Box pt={2} style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                            <TextField
                                                fullWidth
                                                value={el.metadata.keyNumber}
                                                placeholder={t('selfInEmployeeDialog.keyNumber')}
                                                type="text"
                                                onChange={event => {
                                                    const checkInIndex = checkIns.findIndex(checkin => checkin.id === el.id)
                                                    if (checkInIndex < 0) return

                                                    const newCheckIns = [...checkIns]

                                                    newCheckIns[checkInIndex].metadata.keyNumber = event.target.value

                                                    setCheckIns(newCheckIns)
                                                }}
                                            />
                                            <Button
                                                fullWidth
                                                style={{ marginLeft: 22 }}
                                                variant="outlined"
                                                onClick={() => updateCheckIn(el.id, el.metadata.keyNumber)}
                                                disabled={!el.metadata.keyNumber || loading === el.id}>{t('selfInEmployeeDialog.save')}</Button>
                                        </Box> : null}
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog >
    )

}