import React, { useEffect, useState } from 'react'
import { Container, Grid, Typography, CircularProgress, Card, Avatar, Button, Box } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { CreditCard } from '@mui/icons-material'

import { Footer } from '../common'
import { numberFomatter } from '../../utils/helpers/numberFomatter'
import { GRAY_TEXT_COLOR, LIGHT_GRAY_BORDER_COLOR, PRIMARY_COLOR } from '../../utils/constants'

import * as RECURRING_PAYMENTS_REQUESTS from '../../api/recurringPayments'

export default () => {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(true)
    const [recurringPayments, setRecurringPayments] = useState([])

    const onRemovePaymentRecurringHandler = id => {
        setLoading(true)

        return RECURRING_PAYMENTS_REQUESTS.remove(id)
            .then(() => {
                return RECURRING_PAYMENTS_REQUESTS.findAll()
            })
            .then(result => {
                setRecurringPayments(result.filter(el => el.Subscription && el.Subscription.companyId))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        RECURRING_PAYMENTS_REQUESTS.findAll()
            .then(result => {
                setRecurringPayments(result.filter(el => el.Subscription && el.Subscription.companyId))
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    return (
        <>
            <Container maxWidth="md" style={{ height: '90%' }}>
                <Grid container pt={5}>
                    <Grid item xs={12}>
                        <Typography variant="h5" fontWeight="bold">
                            {t('recurringPayments.title')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="p" color={GRAY_TEXT_COLOR}>
                            {t('recurringPayments.description')}
                        </Typography>
                    </Grid>

                    <Grid container pt={4}>
                        {loading ? <Grid item xs={12} display="flex" justifyContent="center" alignItems="center"><CircularProgress /></Grid> : null}
                        {(!loading && !recurringPayments.length) ? <Typography variant="p">
                            {t('recurringPayments.noRecurrings')}
                        </Typography> : null}
                        {!loading ? recurringPayments.map(recurringPayment => {
                            return (
                                <Grid item xs={12} index={recurringPayment.id}>
                                    <Grid container component={Card} p={3} mt={3} display="flex">
                                        <Grid item xs={12} pb={2} borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
                                            <Grid container display="flex" flexDirection="row">
                                                <Grid item xs={9}>
                                                    <Typography variant="h6" fontWeight="bold">
                                                        {recurringPayment.Subscription.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} display="flex" flexDirection="row" justifyContent="flex-end">
                                                    <Typography variant="h6" fontWeight="bold" color={PRIMARY_COLOR}>
                                                        {numberFomatter(recurringPayment.Subscription.priceRon)} lei
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} pt={3}>
                                            <Grid container display="flex" flexDirection="row" alignItems="center">
                                                <Avatar sx={{ height: 60, width: 60 }} variant="rounded" src={recurringPayment.Subscription.Company.avatarPhotoSrc}>{recurringPayment.Subscription.Company.name[0]}</Avatar>
                                                <Box pl={2} >
                                                    <Box xs={12}>
                                                        <Typography variant="p" fontWeight="bold">
                                                            {recurringPayment.Subscription.Company.name}
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant="body2" color={GRAY_TEXT_COLOR}>
                                                        {recurringPayment.Subscription.Company.locationName}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ marginLeft: 'auto' }}>
                                                    <Button variant="text" onClick={() => onRemovePaymentRecurringHandler(recurringPayment.id)} fullWidth color="error">Anuleaza</Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} pt={2}>
                                            <Typography variant="body2" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                {t('recurringPayments.payedWith')} <CreditCard sx={{ pl: 1 }} />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                {t('recurringPayments.cardUsed')} <strong>&nbsp;{recurringPayment.maskedCardNumber}</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} pt={2} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                            <Typography variant="body2">
                                                {t('recurringPayments.firstDescriptionPayment')} <strong>{numberFomatter(recurringPayment.Subscription.priceRon)} {recurringPayment.Subscription.Company.currency}</strong> {t('recurringPayments.secondDescriptionPayment')} <strong>{moment(recurringPayment.scheduledPaymentDate).format('YYYY-MM-DD')}</strong>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        }) : null}
                    </Grid>
                </Grid>
            </Container >
        </>
    )
}