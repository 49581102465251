import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { TableContainer, Typography, Table, TableHead, TableBody, TableRow, TableCell, Paper, Checkbox, Box, IconButton, Grid, TextField, Button } from '@mui/material'
import { Edit, ArrowBack } from '@mui/icons-material'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import * as SUPER_ADMIN_REQUESTS from '../../api/superAdmin'

import { Footer, InputGenerator } from '../common'

const SuperAdminProfessionals = ({ search }) => {

    const [professionals, setProfessionals] = useState([])
    const [editing, setEditing] = useState()
    const [message, setMessage] = useState('')

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')

    const handleGetProfessionals = useCallback(async () => {
        try {
            const professionalsResp = await SUPER_ADMIN_REQUESTS.getProfessionals(search)
            setProfessionals(professionalsResp)
        } catch (e) {
            console.error(e)
        }
    }, [search])

    useEffect(() => {
        handleGetProfessionals()
    }, [handleGetProfessionals, search])

    const handleDelete = useCallback(async (e, userId) => {
        try {
            await SUPER_ADMIN_REQUESTS.updateUser(userId, { isDeleted: e.target.checked })
            handleGetProfessionals()
        } catch (e) {
            console.error(e)
        }
    }, [])

    const handleAccepted = useCallback(async (e, userId) => {
        try {
            await SUPER_ADMIN_REQUESTS.updateUser(userId, { isAccepted: e.target.checked })
            handleGetProfessionals()
        } catch (e) {
            console.error(e)
        }
    }, [])

    const handleDeleteProfileImage = useCallback(async (userId) => {
        try {
            await SUPER_ADMIN_REQUESTS.updateUser(userId, { identityCardSrc: null, avatarPhotoSrc: null })
            handleGetProfessionals()
            alert("Successfully removed")
        } catch (e) {
            console.error(e)
            alert("Error removing avatar")
        }
    }, [])

    const handleUpdate = useCallback(async (userId) => {
        try {
            await SUPER_ADMIN_REQUESTS.updateUser(userId, { firstName, lastName, email, phoneNumber })
            handleGetProfessionals()
            alert("Successfully updated")
        } catch (e) {
            console.error(e)
            alert("Error updating!")
        }
    }, [firstName, lastName, email, phoneNumber])

    const handleApproveAccount = useCallback(async (userId) => {
        try {
            await SUPER_ADMIN_REQUESTS.updateUser(userId, { isApproved: !editing.isApproved })
            await handleGetProfessionals()
            alert("Successfully approved")
        } catch (e) {
            console.error(e)
            alert("Error approving!")
        }
    }, [editing])

    useEffect(() => {
        if (editing) {
            setEditing(prevValue => professionals.filter(el => el.id === prevValue.id)[0])
        }
    }, [editing, professionals])

    const handleApproveLicense = useCallback(async (e, licenseId) => {
        try {
            if (e.target.value === 'denied') {
                await SUPER_ADMIN_REQUESTS.deleteLicense(licenseId)
            }
            else {
                await SUPER_ADMIN_REQUESTS.updateLicense(licenseId, { approved: e.target.value === 'approved' ? true : false })
            }
            await handleGetProfessionals()
            alert("Successfully updated")
        } catch (e) {
            console.error(e)
            alert("Error updating!")
        }
    }, [professionals])

    const handleApproveIdentityCard = useCallback(async (e, userId) => {
        try {
            if (e.target.value === 'denied') {
                await SUPER_ADMIN_REQUESTS.updateUser(userId, { identityCardApproved: false, identityCardSrc: null })
            }
            else {
                await SUPER_ADMIN_REQUESTS.updateUser(userId, { identityCardApproved: e.target.value === 'approved' ? true : false })
            }
            await handleGetProfessionals()
            alert("Successfully updated")
        } catch (e) {
            console.error(e)
            alert("Error updating!")
        }
    }, [professionals])

    const handleSendMessage = useCallback(async (userId) => {
        try {
            await SUPER_ADMIN_REQUESTS.sendEmail({ message, type: 'trainer', entityId: userId })
            handleGetProfessionals()
            alert("Successfully sent")
        } catch (e) {
            console.error(e)
            alert("Error sending!")
        }
    }, [firstName, lastName, email, phoneNumber])

    const getLabelForKey = key => {
        if (key === 'firstName') return "First Name"
        if (key === 'lastName') return "Last Name"
        if (key === 'email') return "Email"
        if (key === 'phoneNumber') return "Phone Number"
        return ""
    }

    const getValueForKey = key => {
        if (key === 'firstName') return firstName
        else if (key === 'lastName') return lastName
        else if (key === 'email') return email
        else if (key === 'phoneNumber') return phoneNumber
    }

    const renderQuill = useMemo(() => {
        return (
            <Grid paddingTop={2.8}>
                <ReactQuill
                    defaultValue={message}
                    modules={{
                        clipboard: {
                            matchVisual: false
                        }
                    }}
                    onChange={(newContent) => {
                        setMessage(newContent)
                    }}
                >
                    <div style={{ minHeight: 250 }} />
                </ReactQuill>
            </Grid>
        )
    }, [message])

    if (editing) return (
        <Grid container display="flex" flexDirection="column">
            <Grid item xs={12}>
                <IconButton onClick={() => setEditing()}><ArrowBack /></IconButton>
            </Grid>
            <Grid item xs={12} pt={2} pl={1.5}>
                <Grid container display="flex" flexDirection="column">
                    <Grid item xs={12}>
                        <Typography variant="h6">General Details</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container display="flex" flexDirection="row">
                            <Grid item xs={6}>
                                <Grid container display="flex" flexDirection="column">
                                    {['firstName', 'lastName', 'email', 'phoneNumber'].map(key => {
                                        return (
                                            <Grid container display="flex" flexDirection="column" pt={0.5} pb={0.5} pr={2}>
                                                <Grid item xs={12} pb={0.5}>
                                                    <Typography fontWeight={500} variant="p">
                                                        {getLabelForKey(key)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField onChange={e => {
                                                        if (key === 'firstName') setFirstName(e.target.value)
                                                        else if (key === 'lastName') setLastName(e.target.value)
                                                        else if (key === 'email') setEmail(e.target.value)
                                                        else if (key === 'phoneNumber') setPhoneNumber(e.target.value)
                                                    }} value={getValueForKey(key)} fullWidth />
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                    <Grid container display="flex" flexDirection="column" pt={0.5} pb={0.5} pr={2}>
                                        <Grid item xs={12}>
                                            Do you want to remove profile image?
                                        </Grid>
                                        <Grid item xs={12} pt={2}>
                                            <Button onClick={() => handleDeleteProfileImage(editing.id)} variant="contained" color="error">Remove</Button>
                                        </Grid>
                                    </Grid>
                                    <Grid container display="flex" flexDirection="column" pt={0.5} pb={0.5} pr={2}>
                                        <Grid item xs={12} pt={2}>
                                            <Button onClick={() => handleUpdate(editing.id)} variant="contained" color="primary">Save your changes</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} >
                                {renderQuill}
                                <Box pt={2}>
                                    <Button onClick={() => handleSendMessage(editing.id)} variant="contained">Send Message</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} pt={2} pl={1.5}>
                <Grid container display="flex" flexDirection="column">
                    <Grid item xs={12}>
                        <Typography variant="h6">Licenses</Typography>
                    </Grid>
                    <Grid item xs={12} display="flex" flexDirection="column">
                        {editing.Licences.length > 0 ? editing.Licences.map((license, index) => {
                            return (
                                <Grid item xs={12} display="flex" flexDirection="row" alignItems="center" pt={0.5} pb={1}>
                                    <Grid item xs={0.5}>
                                        <Typography>{index + 1}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => window.open(license.documentSrc, '_blank', 'noreferrer')}>{license.description}</Typography>
                                    </Grid>
                                    <Grid item xs={3.5} display="flex" flexDirection="row" alignItems="center">
                                        <InputGenerator
                                            handleOnChange={(event) => {
                                                handleApproveLicense(event, license.id)
                                            }}
                                            input={{
                                                type: 'select',
                                                value: license.approved ? 'approved' : 'pending',
                                                values: [
                                                    {
                                                        label: 'Approved',
                                                        value: 'approved'
                                                    },
                                                    {
                                                        label: 'Pending',
                                                        value: 'pending'
                                                    },
                                                    {
                                                        label: 'Denied',
                                                        value: 'denied'
                                                    }]
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        }) : <Typography>There are no documents uploaded</Typography>}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} pt={2} pl={1.5}>
                <Grid container display="flex" flexDirection="column">
                    <Grid item xs={12}>
                        <Typography variant="h6">Identity Card</Typography>
                    </Grid>
                    {editing.identityCardSrc ? <Grid item xs={12} display="flex" flexDirection="row" alignItems="center" pt={0.5} pb={1}>
                        <Grid xs={0.5} />
                        <Grid item xs={4} pt={2}>
                            {<Typography sx={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => window.open(editing.identityCardSrc, '_blank', 'noreferrer')}>Visualize Identity Card</Typography>}
                        </Grid>
                        <Grid item xs={3.5}>
                            {<InputGenerator
                                handleOnChange={(event) => {
                                    handleApproveIdentityCard(event, editing.id)
                                }}
                                input={{
                                    type: 'select',
                                    value: editing.identityCardApproved ? 'approved' : 'pending',
                                    values: [
                                        {
                                            label: 'Approved',
                                            value: 'approved'
                                        },
                                        {
                                            label: 'Pending',
                                            value: 'pending'
                                        },
                                        {
                                            label: 'Denied',
                                            value: 'denied'
                                        }]
                                }}
                            />}
                        </Grid>
                    </Grid> : <Typography>There are no documents uploaded</Typography>}
                </Grid>
            </Grid>
            <Grid item xs={12} pt={2} pl={1.5}>
                <Grid container display="flex" flexDirection="column">
                    <Grid item xs={12}>
                        <Typography variant="h6">Approve Account</Typography>
                        <Typography variant="subtitle1">By pressing this button, an icon with checked will {editing.isApproved ? 'disappear' : 'appear'} at this account.</Typography>
                    </Grid>
                    <Grid item xs={12} pt={2}>
                        <Button onClick={() => handleApproveAccount(editing.id)} variant="contained" color="primary">{editing.isApproved ? 'Unapprove' : 'Approve'} Account</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>Country</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell align='right'>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {professionals.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>
                                    {row.firstName} {row.lastName}
                                </TableCell>
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>{row.email}</TableCell>
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>{row.phoneNumber}</TableCell>
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>{row.country ? row.country : '-'}</TableCell>
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>{row.city ? row.city : '-'}</TableCell>
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }} align='right'>
                                    <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                        <Box pl={1} pr={1}>
                                            <Checkbox checked={row.isDeleted} onChange={e => handleDelete(e, row.id)} /> Deleted
                                        </Box>
                                        <Box pl={1} pr={1}>
                                            <Checkbox checked={row.isAccepted} onChange={e => handleAccepted(e, row.id)} /> Public
                                        </Box>
                                        <Box pl={1} pr={1}>
                                            <IconButton onClick={() => {
                                                setEditing(row)
                                                setFirstName(row.firstName)
                                                setLastName(row.lastName)
                                                setEmail(row.email)
                                                setPhoneNumber(row.phoneNumber)
                                            }}><Edit /></IconButton>
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default SuperAdminProfessionals