/* eslint-disable no-unused-vars */
import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Close, Coffee, ManageAccounts, CheckCircle, Error } from '@mui/icons-material'
import {
  Dialog,
  Button,
  Slide,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  IconButton,
  Container,
  Card,
  Avatar,
  Tooltip
} from '@mui/material'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import Linkify from 'react-linkify'
import parse from 'html-react-parser'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import * as CALENDAR_REQUESTS from '../../../api/calendar'
import {
  BLACK_BACKGROUND_COLOR,
  BUSINESS_ROLES,
  EQUIPMENTS_TYPES,
  ERROR_COLOR,
  GREEN_COLOR,
  LIGHT_GRAY_BORDER_COLOR,
  PRIMARY_COLOR,
  ROLE_BUSINESS_ADMINISTRATOR,
  ROLE_TRAINER,
  SPORTTYPES_TYPES,
  SUBSCRIPTION_PAYMENT_STATUS,
  WHITE_COLOR
} from '../../../utils/constants'
import moment from 'moment'
import { useAuthentication } from '../../../context/auth-context'

import CalendarEventParticipants from './CalendarEventParticipants'
import SubscriptionSelectorModal from './SubscriptionSelectorModal'
import CalendarEventTrainers from './CalendarEventTrainers'
import { getStatus } from '../../../utils/helpers/userSubscription'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CalendarEventDetailsModal = ({
  showPastEvents,
  loadingBookUnbook,
  handleClose,
  userSubscriptionsToUse,
  handleOpenSubscriptionSelector,
  handleBookEventWithOneSubscription,
  handleOpenPayment,
  handleBuyACoffee,
  handleOpenWaitingList,
  handleUnbook,
  handleBook,
  handleDelete,
  handleMessage,
  handleEdit,
  eventId
}) => {
  const theme = useTheme()
  const { user } = useAuthentication()
  const matchPhone = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate()
  const { t } = useTranslation()

  const isTrainer = user && user.role && user.role.toLowerCase() === ROLE_TRAINER.toLowerCase()
  const isClub =
    user && user.role && user.role.toLowerCase() === ROLE_BUSINESS_ADMINISTRATOR.toLowerCase()
  const isEmployee =
    user && user.role && user.role.toLowerCase() === BUSINESS_ROLES.BUSINESS_EMPLOYEE.toLowerCase()

  const [checkins, setCheckins] = useState([])
  const [availableUsers, setAvailableUsers] = useState([])
  const [content, setContent] = useState('')
  const [eventDetails, setEventDetails] = useState()
  const [subscriptionSelector, setSubscriptionSelector] = useState({
    open: false,
    participantId: null
  })

  const handleGetCheckins = useCallback(async () => {
    try {
      const checkinsResponse = await CALENDAR_REQUESTS.getCheckinsForEvent(eventId)
      setCheckins(checkinsResponse)
    } catch (e) {
      console.error(e)
    }
  }, [eventId])

  const handleGetEventById = useCallback(async () => {
    try {
      const eventResponse = await CALENDAR_REQUESTS.getEventById(eventId)
      setEventDetails(eventResponse)
    } catch (e) {
      console.error(e)
    }
  }, [eventId])

  useEffect(() => {
    if (loadingBookUnbook) return
    handleGetEventById()
    handleGetCheckins()
  }, [eventId, loadingBookUnbook])

  const handleBookUnbook = async () => {
    if (eventDetails.EventsParticipants.map((el) => el.id).includes(user.id)) {
      await handleUnbook(eventDetails.id)
    } else {
      if (
        eventDetails.EventsParticipants.filter((el) => !el.Events_Participants.isInWaitingList)
          .length === eventDetails.maxParticipants &&
        eventDetails.maxParticipants
      ) {
        if (
          Number(
            eventDetails.EventsParticipants.filter((el) => el.Events_Participants.isInWaitingList)
              .length
          ) === Number(eventDetails.maxWaitingList)
        )
          return
        handleOpenWaitingList(eventDetails)
      } else {
        if (!eventDetails.payment) {
          if (eventDetails.cost === 'CREDITS') {
            if (eventDetails.EventSubscriptions.length > 1) {
              handleOpenSubscriptionSelector(eventDetails, eventDetails.EventSubscriptions)
            } else {
              await handleBookEventWithOneSubscription(eventDetails)
            }
          } else {
            await handleBook(eventDetails.id)
          }
        } else handleOpenPayment(eventDetails)
      }
    }
  }

  const handleAddParticipant = async (participantId, userSubscriptionId, usersToUse) => {
    try {
      if (userSubscriptionId) {
        await CALENDAR_REQUESTS.addParticipantToEvent(eventId, participantId, userSubscriptionId)
      } else if (
        eventDetails.access === 'SUBSCRIPTION' &&
        eventDetails.EventSubscriptions.length === 1
      ) {
        const userSubscriptions = (usersToUse || availableUsers).find(
          (el) => el.id === participantId
        )
        const userSubscriptionSelected = userSubscriptions.Subscriptions.find(
          (el) => el.subscriptionId === eventDetails.EventSubscriptions[0].id && moment(el.validUntil).isAfter(moment())
          )
        if (
          userSubscriptionSelected.limitedActivationTotal &&
          userSubscriptionSelected.limitedActivationUsed + eventDetails.value >
            userSubscriptionSelected.limitedActivationTotal
        ) {
          toast.error(t('calendar.notEnoughCredits'), {
            position: 'bottom-right',
            autoClose: false
          })
          return
        }
        await CALENDAR_REQUESTS.addParticipantToEvent(
          eventId,
          participantId,
          userSubscriptionSelected.id
        )
      } else if (
        eventDetails.access === 'SUBSCRIPTION' &&
        eventDetails.EventSubscriptions.length > 1
      ) {
        return setSubscriptionSelector({
          open: true,
          participantId
        })
      } else {
        await CALENDAR_REQUESTS.addParticipantToEvent(eventId, participantId)
      }
      handleGetEventById()
      toast.success(t('calendar.successParticipant'), { position: 'bottom-right' })
    } catch (e) {
      toast.error(t('calendar.errorParticipant'), { position: 'bottom-right', autoClose: false })
      console.error(e)
    }
  }

  const handleDeleteParticipant = async (participantId) => {
    try {
      await CALENDAR_REQUESTS.removeParticipantFromEvent(eventId, participantId)
      handleGetEventById()
      toast.success(t('calendar.successRemoveParticipant'), { position: 'bottom-right' })
    } catch (e) {
      toast.error(t('calendar.errorRemoveParticipant'), {
        position: 'bottom-right',
        autoClose: false
      })
      console.error(e)
    }
  }

  const handleAddTrainer = async (trainerId) => {
    try {
      await CALENDAR_REQUESTS.addTrainerToEvent(eventId, trainerId)
      handleGetEventById()
      toast.success(t('calendar.successTrainer'), { position: 'bottom-right' })
    } catch (e) {
      toast.error(t('calendar.errorTrainer'), { position: 'bottom-right', autoClose: false })
      console.error(e)
    }
  }

  const handleDeleteTrainer = async (trainerId) => {
    try {
      await CALENDAR_REQUESTS.removeTrainerFromEvent(eventId, trainerId)
      handleGetEventById()
      toast.success(t('calendar.successRemoveTrainer'), { position: 'bottom-right' })
    } catch (e) {
      toast.error(t('calendar.errorRemoveTrainer'), { position: 'bottom-right', autoClose: false })
      console.error(e)
    }
  }

  const handleAvatarClick = () => {
    if (
      (isTrainer || isClub || isEmployee) &&
      ((eventDetails.Trainer && eventDetails.Trainer.id === user.id) ||
        (eventDetails.Company && eventDetails.Company.id === user.companyId))
    ) {
      navigate(`/clients`)
    }
  }

  const computeStatus = (subscription, eventStartDate) => {
    let currentTime = new Date().getTime()
    const subscriptionStart = moment(subscription.validFrom).utc()
    const subscriptionEnd = moment(subscription.validUntil).utc()
    const subscriptionIsBetween = moment.utc(eventStartDate).isBetween(subscriptionStart, subscriptionEnd, '[]')

    const paymentDeadline = {
      hasPaymentDeadline: !subscription.Subscription.confirmation && !!subscription.Subscription.paymentDeadline && subscription.Subscription.paymentDeadline > 0
    }

    if (paymentDeadline.hasPaymentDeadline) {
      const deadlinePayment = moment(subscriptionStart, 'YYYY-MM-DD').add(Number(subscription.Subscription.paymentDeadline) - 1, 'days').set('hours', 23).set('minutes', 59)

      paymentDeadline.isBetween = moment().utc().isBetween(subscriptionStart, deadlinePayment, '[]')
      paymentDeadline.eventIsBetween = moment(eventStartDate).utc().isBetween(subscriptionStart, deadlinePayment, '[]')
    }

    const pauseData = {
      hasPause: !!subscription.Pause
    }

    if (pauseData.hasPause) {
      const startPause = moment(subscription.Pause.startDate, 'YYYY-MM-DD').set('hours', 0).set('minutes', 1)
      const endPause = moment(subscription.Pause.endDate, 'YYYY-MM-DD').set('hours', 23).set('minutes', 59)

      pauseData.isBetween = moment().utc().isBetween(startPause, endPause, '[]')
    }
  
    const paidSubscription = subscription.status === SUBSCRIPTION_PAYMENT_STATUS.CONFIRMED

    const activeSubscription = subscriptionIsBetween &&
    (subscription.Subscription.confirmation
      ? paidSubscription
      : !paidSubscription
      ? paymentDeadline.hasPaymentDeadline && paymentDeadline.eventIsBetween 
      : paidSubscription) &&
    (subscription.Pause ? !pauseData.isBetween : true) &&
    (subscription.limitedActivationTotal
      ? subscription.limitedActivationUsed < subscription.limitedActivationTotal
      : true)

    if (eventStartDate) {
      if (
        subscription.Pause &&
        moment(eventStartDate).isBetween(
          moment(subscription.Pause.startDate).utc(),
          moment(subscription.Pause.endDate).utc(),
          'day',
          '[]'
        )
      ) {
        return 'PAUSED'
      }
    }

    if (
      !eventStartDate &&
      subscription.Pause &&
      currentTime >= new Date(subscription.Pause.startDate).getTime() &&
      currentTime <= new Date(subscription.Pause.endDate).getTime()
    ) {
      return 'PAUSED'
    }

    if (
      moment(eventStartDate ? eventStartDate : null).isAfter(moment(subscription.validUntil).utc())
    ) {
      return 'EXPIRED'
    }

    if (!activeSubscription) {
      return 'INACTIVE'
    }

    return 'ACTIVE'
  }

  const hasActiveOrValidSubscriptions = useMemo(() => {
    if (!eventDetails) return
    
    return userSubscriptionsToUse.filter(subscription =>
      getStatus(subscription, null ,eventDetails?.start || eventDetails?.fullStart).inactive === false
    ).filter(el => eventDetails?.EventSubscriptions.map(el => el.Events_Subscriptions.SubscriptionId).includes(el.Subscription.id))

  }, [userSubscriptionsToUse, eventDetails]);

  const renderButtons = useMemo(() => {
    if (!eventDetails) return null
    const startTime = moment(new Date(eventDetails.start).toISOString().split('.')[0]).utc()

    if (!user)
      return (
        <Typography variant="body2">
          Opss! It looks like you are not logged in. In order to proceed, please{' '}
          <span
            onClick={() => navigate(`/login?eventId=${eventDetails.id}`)}
            style={{
              color: PRIMARY_COLOR,
              textDecoration: 'underline',
              fontWeight: 'bold',
              cursor: 'pointer'
            }}>
            Login with an existing account
          </span>{' '}
          or{' '}
          <span
            onClick={() => navigate(`/signup?eventId=${eventDetails.id}`)}
            style={{
              color: PRIMARY_COLOR,
              textDecoration: 'underline',
              fontWeight: 'bold',
              cursor: 'pointer'
            }}>
            Create a new account
          </span>
          . Thank you!
        </Typography>
      )

    const availableSubscriptionsForBookWithCredits =
      isTrainer ||
      isClub ||
      eventDetails.EventSubscriptions.length === 0 ||
      (userSubscriptionsToUse
        .filter((sub) => {
          return (
            computeStatus(sub, eventDetails.start) === 'ACTIVE' &&
            moment(eventDetails.start).diff(moment(sub.validFrom)) > 0 &&
            moment(eventDetails.start).isBefore(moment(sub.validUntil)) &&
            !sub.isDeleted &&
            (sub.limitedActivationTotal && sub.limitedActivationUsed + eventDetails.value <= sub.limitedActivationTotal)
          )
        })
        .map((sub) => sub.Subscription.id)
        .filter((sub) => eventDetails.EventSubscriptions.map((sub) => sub.id).includes(sub))) ||
      (userSubscriptionsToUse
        .filter((sub) => {
          const dayLimit = moment(sub.validFrom)
            .utc()
            .add(sub.Subscription.paymentDeadline - 1, 'days')
            .endOf('day')
          return (
            computeStatus(sub, eventDetails.start) === 'INACTIVE' &&
            (!sub.Subscription.confirmation
              ? dayLimit.diff(moment(eventDetails.start).utc()) >= 0 &&
                moment(eventDetails.start).utc().date() >= moment(sub.validFrom).utc().date()
              : false)
          )
        })
        .map((sub) => sub.Subscription.id)
        .filter((sub) => eventDetails.EventSubscriptions.map((sub) => sub.Subscription.id).includes(sub)))

    const hasValidSubscription = eventDetails.EventSubscriptions.length === 0 ? true : availableSubscriptionsForBookWithCredits.length > 0

    const isUserInWaitingList = eventDetails.EventsParticipants.some(
      (eventParticipant) =>
        eventParticipant.Events_Participants.isInWaitingList &&
        Number(eventParticipant.Events_Participants.UserId) === Number(user.id)
    )
    const participants = eventDetails.EventsParticipants.filter(
      (eventParticipant) => !eventParticipant.Events_Participants.isInWaitingList
    )
    const usersInWaitingList = eventDetails.EventsParticipants.filter(
      (eventParticipant) => eventParticipant.Events_Participants.isInWaitingList
    ).length
    const eventBooked = !user.id
      ? false
      : eventDetails.EventsParticipants.map((event) => event.Events_Participants)
          .map((event) => event.UserId)
          .includes(user.id)

    const handleMobileLabel = () => {
      if (isUserInWaitingList) return 'calendar.leave'
      else if (participants.length >= eventDetails.maxParticipants) {
        if (usersInWaitingList >= eventDetails.maxWaitingList || !eventDetails.withWaitingList) {
          if (eventBooked) return 'calendar.unbook'
          else return 'calendar.full'
        } else if (!eventBooked && (!hasValidSubscription || eventDetails.withWaitingList)) {
          return 'calendar.join'
        } else {
          return eventBooked ? 'calendar.unbook' : 'calendar.book'
        }
      } else {
        return eventBooked ? 'calendar.unbook' : 'calendar.book'
      }
    }
    
    const webLabel = handleMobileLabel()
    const buttonColor =
      webLabel === 'calendar.book'
        ? '#22b24c'
        : webLabel === 'calendar.unbook'
        ? '#E27900'
        : webLabel === 'calendar.full'
        ? 'rgba(255, 0, 0, 0.5)'
        : webLabel === 'calendar.join'
        ? '#0075EB'
        : webLabel === 'calendar.leave'
        ? '#E27900'
        : '#22b24c'

    if (isTrainer && !eventDetails.Trainer) return null

    if (!user) {
      return (
        <>
          <Grid item>
            <Button
              disabled
              variant="contained"
              fullWidth
              style={{ backgroundColor: BLACK_BACKGROUND_COLOR }}>
              <Coffee sx={{ marginRight: 2 }} />{' '}
              {t('userProfile.activity.trainerDetailComponent.coffee')}
            </Button>
          </Grid>
          <Grid item pt={2}>
            <Button fullWidth variant="contained" color="primary" disabled>
              MESSAGE
            </Button>
          </Grid>
          {!isTrainer && !isClub ? (
            <Grid item pt={2}>
              <Button
                disabled
                fullWidth
                variant="contained"
                style={{
                  backgroundColor: GREEN_COLOR
                }}>
                {t('calendar.book')}
              </Button>
            </Grid>
          ) : null}
        </>
      )
    }

    if (
      (isTrainer || isClub || isEmployee) &&
      ((eventDetails.Trainer && eventDetails.Trainer.id === user.id) ||
        (eventDetails.Company && eventDetails.Company.id === user.companyId))
    ) {
      return (
        <>
          <Grid item>
            <Button
              fullWidth
              variant="contained"
              color="error"
              onClick={() => {
                if (eventDetails.EventsParticipants && eventDetails.EventsParticipants.length > 0) {
                  toast.error(t('calendar.eventWithParticipant'), {
                    position: 'bottom-right',
                    autoClose: false
                  })

                  return
                }
                handleDelete(eventId)
              }}>
              {t('calendar.delete')}
            </Button>
          </Grid>
          <Grid item pt={2}>
            <Button
              disabled={
                showPastEvents &&
                (moment().format('YYYY-MM-DD') !==
                moment(eventDetails.start).utc().format('YYYY-MM-DD')
                  ? moment().isAfter(moment(eventDetails.start).utc())
                  : moment().hour() > moment(eventDetails.start).utc().hour())
              }
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => handleEdit(eventId)}>
              {t('calendar.edit')}
            </Button>
          </Grid>
        </>
      )
    }

    if (eventDetails.EventsTrainers.length > 0 || eventDetails.Trainer) {
      return (
        <>
          <Grid item>
            <Button
              disabled={
                !user ||
                (eventDetails.EventsTrainers &&
                  eventDetails.EventsTrainers[0] &&
                  !eventDetails.EventsTrainers[0].paymentLink) ||
                (eventDetails.Trainer && !eventDetails.Trainer.paymentLink)
              }
              variant="contained"
              fullWidth
              onClick={() => {
                const paymentLink =
                  eventDetails.EventsTrainers && eventDetails.EventsTrainers[0]
                    ? eventDetails.EventsTrainers[0].paymentLink
                    : eventDetails.Trainer
                    ? eventDetails.Trainer.paymentLink
                    : ''
                handleBuyACoffee(
                  eventDetails.EventsTrainers,
                  paymentLink,
                  eventDetails.EventsTrainers.length === 0 && eventDetails.Trainer
                )
              }}
              style={{ backgroundColor: BLACK_BACKGROUND_COLOR }}>
              <Coffee sx={{ marginRight: 2 }} />{' '}
              {t('userProfile.activity.trainerDetailComponent.coffee')}
            </Button>
          </Grid>
          <Grid item pt={2}>
            <Button
              onClick={() => {
                const name =
                  eventDetails.EventsTrainers && eventDetails.EventsTrainers[0]
                    ? `${eventDetails.EventsTrainers[0].firstName} ${eventDetails.EventsTrainers[0].lastName}`
                    : eventDetails.Trainer
                    ? `${eventDetails.Trainer.firstName} ${eventDetails.Trainer.lastName}`
                    : eventDetails.Company
                    ? eventDetails.Company.name
                    : ''
                const id =
                  eventDetails.EventsTrainers && eventDetails.EventsTrainers[0]
                    ? eventDetails.EventsTrainers[0].id
                    : eventDetails.Trainer
                    ? eventDetails.Trainer.id
                    : eventDetails.Company
                    ? eventDetails.Company.id
                    : ''
                const type =
                  eventDetails.EventsTrainers && eventDetails.EventsTrainers[0]
                    ? 'trainer'
                    : eventDetails.Trainer
                    ? `trainer`
                    : 'company'
                handleMessage(
                  name,
                  id,
                  type,
                  eventDetails.EventsTrainers,
                  eventDetails.EventsTrainers.length === 0,
                  eventDetails.Company
                    ? {
                        name: eventDetails.Company.name,
                        id: eventDetails.Company.id,
                        avatarPhotoSrc: eventDetails.Company.avatarPhotoSrc,
                        domain: eventDetails.Company.domain
                      }
                    : null
                )
              }}
              fullWidth
              variant="contained"
              color="primary">
              {t('calendar.message')}
            </Button>
          </Grid>
          {!isTrainer && !isClub ? (
            <Grid item pt={2}>
              <Button
                disabled={
                  (hasActiveOrValidSubscriptions.length < 1 && webLabel === 'calendar.book') ||
                  webLabel === 'calendar.full' ||
                  !user ||
                  (!hasValidSubscription && (webLabel !== 'calendar.unbook' && webLabel !== 'calendar.leave')) ||
                  loadingBookUnbook === eventDetails.id ||
                  !eventDetails.onlineBooking ||
                  (eventDetails.onlineBooking &&
                    eventDetails.book &&
                    webLabel !== 'calendar.join' &&
                    webLabel !== 'calendar.leave' &&
                    startTime.diff(moment(), 'days') >= eventDetails.book) ||
                  (user &&
                    eventDetails.onlineBooking &&
                    eventDetails.cancel &&
                    eventDetails.EventsParticipants.filter((participant) => !participant.Events_Participants.isInWaitingList).map((participant) => participant.id).includes(user.id) &&
                    startTime.diff(moment(), 'hours', true) <= eventDetails.cancel) ||
                  startTime.diff(moment()) < 0 ||
                  (moment().utc().date() < startTime.utc().date() ||
                  moment().utc().month() < startTime.utc().month() ||
                  moment().utc().year() < startTime.utc().year()
                    ? false
                    : moment().utc().hour() < startTime.utc().hour()
                    ? false
                    : moment().utc().hour() === startTime.utc().hour()
                    ? moment().utc().minute() < startTime.utc().minute()
                      ? false
                      : true
                    : true)
                }
                onClick={() => {
                  handleBookUnbook()
                }}
                fullWidth
                variant="contained"
                style={{
                  backgroundColor: buttonColor
                }}>
                {t(webLabel)}
              </Button>
            </Grid>
          ) : null}
        </>
      )
    }
    
    return (
      <>
        <Grid item>
          <Button
            onClick={() => {
              const name =
                eventDetails.EventsTrainers && eventDetails.EventsTrainers[0]
                  ? `${eventDetails.EventsTrainers[0].firstName} ${eventDetails.EventsTrainers[0].lastName}`
                  : eventDetails.Trainer
                  ? `${eventDetails.Trainer.firstName} ${eventDetails.Trainer.lastName}`
                  : eventDetails.Company
                  ? eventDetails.Company.name
                  : ''
              const id =
                eventDetails.EventsTrainers && eventDetails.EventsTrainers[0]
                  ? eventDetails.EventsTrainers[0].id
                  : eventDetails.Trainer
                  ? eventDetails.Trainer.id
                  : eventDetails.Company
                  ? eventDetails.Company.id
                  : ''
              const type =
                eventDetails.EventsTrainers && eventDetails.EventsTrainers[0]
                  ? 'trainer'
                  : eventDetails.Trainer
                  ? `trainer`
                  : 'company'
              handleMessage(
                name,
                id,
                type,
                eventDetails.EventsTrainers,
                eventDetails.EventsTrainers.length === 0,
                eventDetails.Company
                  ? {
                      name: eventDetails.Company.name,
                      id: eventDetails.Company.id,
                      avatarPhotoSrc: eventDetails.Company.avatarPhotoSrc,
                      domain: eventDetails.Company.domain
                    }
                  : null
              )
            }}
            disabled={!user}
            fullWidth
            variant="contained"
            color="primary">
            {t('calendar.message')}
          </Button>
        </Grid>
        {!isTrainer && !isClub ? (
          <Grid item pt={2}>
            <Button
              disabled={
                (hasActiveOrValidSubscriptions.length < 1 && webLabel === 'calendar.book') ||
                webLabel === 'calendar.full' ||
                !user ||
                loadingBookUnbook === eventDetails.id ||
                (!hasValidSubscription && (webLabel !== 'calendar.unbook' && webLabel !== 'calendar.leave')) ||
                !eventDetails.onlineBooking ||
                (eventDetails.onlineBooking &&
                  eventDetails.book > 0 &&
                  webLabel !== 'calendar.join' &&
                  webLabel !== 'calendar.leave' &&
                  startTime.diff(moment(), 'days') >= eventDetails.book) ||
                (eventDetails.onlineBooking &&
                  eventDetails.cancel > 0 &&
                  eventDetails.EventsParticipants.filter((participant) => !participant.Events_Participants.isInWaitingList).map((participant) => participant.id).includes(user.id) &&
                  (startTime.diff(moment(), 'hours', true)) <= eventDetails.cancel) ||
                startTime.diff(moment()) < 0
              }
              onClick={() => {
                handleBookUnbook()
              }}
              fullWidth
              variant="contained"
              style={{
                backgroundColor: buttonColor
              }}>
              {t(webLabel)}
            </Button>
          </Grid>
        ) : null}
        <Grid item pt={2}>
          <Button
            fullWidth
            variant="contained"
            style={{ backgroundColor: '#E60000' }}
            onClick={handleClose}>
            {t('calendar.cancel')}
          </Button>
        </Grid>
      </>
    )
  }, [isTrainer, showPastEvents, isClub, user, eventDetails, loadingBookUnbook])

  const renderLeftDetails = useMemo(() => {
    if (!eventDetails) return null
    return (
      <Grid
        item
        xs={12}
        lg={8}
        pr={{ xs: 0, lg: 3 }}
        pt={{ xs: 4, lg: 0 }}
        display="flex"
        flexDirection="column">
        <Grid
          item
          pt={{ lg: 0, xs: 1 }}
          pb={{ lg: 0, xs: 1 }}
          lg={12}
          sx={{ borderRadius: 3 }}
          pl={3}
          pr={3}
          mt={2}
          component={Card}
          maxHeight={100}
          minHeight={100}
          display="flex"
          flexDirection={{ lg: 'row', xs: 'column' }}
          alignItems={{ lg: 'center', xs: 'flex-start' }}>
          <Grid item pl={2} xs={12} lg={3}>
            <Typography variant="h6" fontWeight={600}>
              {t('calendar.participants')}
            </Typography>
          </Grid>
          <Grid item pl={2} lg={9} xs={12} display="flex" flexDirection="row" alignItems="center">
            {eventDetails.showParticipantsName ||
            (eventDetails.Trainer && eventDetails.Trainer.id === user.id) ||
            (eventDetails.Company && eventDetails.Company.id === user.companyId) ? (
              <Grid container display="flex" flexDirection="row">
                {eventDetails.EventsParticipants.length > 0
                  ? eventDetails.EventsParticipants.filter(
                      (participant) => !participant.Events_Participants.isInWaitingList
                    )
                      .slice(0, matchPhone ? 2 : 9)
                      .map((el) => (
                        <Tooltip
                          title={
                            (isTrainer || isClub || isEmployee) &&
                            ((eventDetails.Trainer && eventDetails.Trainer.id === user.id) ||
                              (eventDetails.Company && eventDetails.Company.id === user.companyId))
                              ? `${el.firstName} ${el.lastName} ${
                                  checkins.map((checkin) => checkin.userId).includes(el.id)
                                    ? t('calendar.with')
                                    : t('calendar.without')
                                } ${t('calendar.checkin')}`
                              : ''
                          }>
                          <Grid item pl={0.3} pr={0.3}>
                            <Avatar
                              src={el.avatarPhotoSrc}
                              sx={{
                                width: 50,
                                height: 50,
                                border:
                                  (isTrainer || isClub) &&
                                  ((eventDetails.Trainer && eventDetails.Trainer.id === user.id) ||
                                    (eventDetails.Company &&
                                      eventDetails.Company.id === user.companyId))
                                    ? `3px solid ${
                                        checkins.map((checkin) => checkin.userId).includes(el.id)
                                          ? GREEN_COLOR
                                          : ERROR_COLOR
                                      }`
                                    : ''
                              }}>
                              {el.firstName.charAt(0).toUpperCase()}{' '}
                              {el.lastName.charAt(0).toUpperCase()}
                            </Avatar>
                          </Grid>
                        </Tooltip>
                      ))
                  : null}
                {eventDetails.EventsParticipants.filter(
                  (participant) => !participant.Events_Participants.isInWaitingList
                ).length > (matchPhone ? 2 : 9) ? (
                  <Grid item>
                    <Avatar sx={{ width: 50, height: 50, border: `2px solid ${WHITE_COLOR}` }}>
                      +{' '}
                      {eventDetails.EventsParticipants.filter(
                        (participant) => !participant.Events_Participants.isInWaitingList
                      ).length - (matchPhone ? 2 : 9)}
                    </Avatar>
                  </Grid>
                ) : null}
              </Grid>
            ) : null}
            {(isTrainer || isClub || isEmployee) &&
            ((eventDetails.Trainer && eventDetails.Trainer.id === user.id) ||
              (eventDetails.Company && eventDetails.Company.id === user.companyId)) ? (
              <Grid item onClick={() => setContent('participants')}>
                <IconButton>
                  <ManageAccounts color="primary" />
                </IconButton>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid
          item
          pt={{ lg: 0, xs: 1 }}
          pb={{ lg: 0, xs: 1 }}
          lg={12}
          sx={{ borderRadius: 3 }}
          pl={3}
          pr={3}
          mt={2}
          component={Card}
          maxHeight={100}
          minHeight={100}
          display="flex"
          flexDirection={{ lg: 'row', xs: 'column' }}
          alignItems={{ lg: 'center', xs: 'flex-start' }}>
          <Grid item pl={2} lg={3} xs={12}>
            <Typography variant="h6" fontWeight={600}>
              {t('calendar.waitingList')}
            </Typography>
          </Grid>
          <Grid item pl={2} lg={9} xs={12} display="flex" flexDirection="row" alignItems="center">
            {eventDetails.showParticipantsName ||
            (eventDetails.Trainer && eventDetails.Trainer.id === user.id) ||
            (eventDetails.Company && eventDetails.Company.id === user.companyId) ? (
              <Grid container display="flex" flexDirection="row">
                {eventDetails.EventsParticipants.length > 0
                  ? eventDetails.EventsParticipants.filter(
                      (participant) => participant.Events_Participants.isInWaitingList
                    )
                      .slice(0, matchPhone ? 2 : 9)
                      .map((el) => (
                        <Tooltip title={`${el.firstName} ${el.lastName}`}>
                          <Grid item>
                            <Avatar
                              onClick={handleAvatarClick}
                              src={el.avatarPhotoSrc}
                              sx={{
                                cursor: 'pointer',
                                width: 50,
                                height: 50,
                                border: `2px solid ${WHITE_COLOR}`
                              }}>
                              {el.firstName.charAt(0).toUpperCase()}{' '}
                              {el.lastName.charAt(0).toUpperCase()}
                            </Avatar>
                          </Grid>
                        </Tooltip>
                      ))
                  : null}
                {eventDetails.EventsParticipants.filter(
                  (participant) => participant.Events_Participants.isInWaitingList
                ).length > (matchPhone ? 2 : 9) ? (
                  <Grid item>
                    <Avatar sx={{ width: 50, height: 50, border: `2px solid ${WHITE_COLOR}` }}>
                      +{' '}
                      {eventDetails.EventsParticipants.filter(
                        (participant) => participant.Events_Participants.isInWaitingList
                      ).length - (matchPhone ? 2 : 9)}
                    </Avatar>
                  </Grid>
                ) : null}
              </Grid>
            ) : null}
            {(isTrainer || isClub || isEmployee) &&
            ((eventDetails.Trainer && eventDetails.Trainer.id === user.id) ||
              (eventDetails.Company && eventDetails.Company.id === user.companyId)) ? (
              <Grid item onClick={() => setContent('waitingList')}>
                <IconButton>
                  <ManageAccounts color="primary" />
                </IconButton>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid
          item
          pt={{ lg: 0, xs: 1 }}
          pb={{ lg: 0, xs: 1 }}
          lg={12}
          sx={{ borderRadius: 3 }}
          pl={3}
          pr={3}
          mt={2}
          component={Card}
          maxHeight={100}
          minHeight={100}
          display="flex"
          flexDirection={{ lg: 'row', xs: 'column' }}
          alignItems={{ lg: 'center', xs: 'flex-start' }}>
          <Grid item pl={2} xs={12} lg={3}>
            <Typography variant="h6" fontWeight={600}>
              {t('calendar.trainers')}
            </Typography>
          </Grid>
          <Grid item pl={2} lg={9} xs={12} display="flex" flexDirection="row" alignItems="center">
            <Grid container display="flex" flexDirection="row">
              {eventDetails.EventsTrainers.length > 0 ? (
                eventDetails.EventsTrainers.slice(0, matchPhone ? 2 : 9).map((el) => (
                  <Tooltip title={`${el.firstName} ${el.lastName}`}>
                    <Grid item onClick={() => navigate(`/${el.domain}`)}>
                      <Avatar
                        src={el.avatarPhotoSrc}
                        sx={{
                          width: 50,
                          height: 50,
                          border: `2px solid ${WHITE_COLOR}`,
                          cursor: 'pointer'
                        }}>
                        {el.firstName.charAt(0).toUpperCase()} {el.lastName.charAt(0).toUpperCase()}
                      </Avatar>
                    </Grid>
                  </Tooltip>
                ))
              ) : eventDetails.Trainer ? (
                <Tooltip
                  title={`${eventDetails.Trainer.firstName} ${eventDetails.Trainer.lastName}`}>
                  <Grid item onClick={() => navigate(`/${eventDetails.Trainer.domain}`)}>
                    <Avatar
                      src={eventDetails.Trainer.avatarPhotoSrc}
                      sx={{
                        width: 50,
                        height: 50,
                        border: `2px solid ${WHITE_COLOR}`,
                        cursor: 'pointer'
                      }}>
                      {eventDetails.Trainer.firstName.charAt(0).toUpperCase()}{' '}
                      {eventDetails.Trainer.lastName.charAt(0).toUpperCase()}
                    </Avatar>
                  </Grid>
                </Tooltip>
              ) : null}
            </Grid>
            {(isClub || isEmployee) &&
            eventDetails.Company &&
            eventDetails.Company.id === user.companyId ? (
              <Grid item onClick={() => setContent('trainers')}>
                <IconButton>
                  <ManageAccounts color="primary" />
                </IconButton>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        {eventDetails.withVideoLink &&
        eventDetails.EventsParticipants.map((el) => el.id).includes(user.id) &&
        (!eventDetails.accessOnlyOnStart ||
          (eventDetails.accessOnlyOnStart && moment().diff(moment(eventDetails.start)) > 0)) ? (
          <Grid
            item
            lg={12}
            sx={{ borderRadius: 3 }}
            maxHeight={100}
            minHeight={100}
            pl={3}
            pr={3}
            mt={2}
            component={Card}
            display="flex"
            flexDirection="row"
            alignItems="center">
            <Grid item pl={2} xs={3}>
              <Typography variant="h6" fontWeight={600}>
                {t('calendar.video')}
              </Typography>
            </Grid>
            <Grid item pl={2}>
              <Typography
                fontWeight={500}
                color={`#979797`}
                sx={{ cursor: 'pointer', color: PRIMARY_COLOR }}
                onClick={() => window.open(eventDetails.videoLink)}>
                {eventDetails.videoLink || ''}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        <Grid
          item
          lg={12}
          mt={2}
          pl={3}
          pr={3}
          sx={{ borderRadius: 3 }}
          minHeight={200}
          component={Card}
          display="flex"
          flexDirection="column"
          pt={3}>
          <Grid item pl={2}>
            <Typography variant="h6" fontWeight={600}>
              {t('calendar.about')}
            </Typography>
          </Grid>
          <Grid item pl={2} pt={2}>
            <Linkify>
              <Typography fontWeight={500} color={!eventDetails.notes ? `#979797` : ''}>
                {parse(eventDetails.notes || t('helper.noInformation'))}
              </Typography>
            </Linkify>
          </Grid>
        </Grid>
      </Grid>
    )
  }, [t, user, matchPhone, checkins, isTrainer, isClub, eventDetails])

  const renderRightDetails = useCallback(() => {
    if (!eventDetails) return null
    return (
      <Grid
        item
        xs={12}
        lg={4}
        pl={3}
        pr={3}
        display="flex"
        flexDirection="column"
        alignItems="center"
        pt={4}
        pb={2}
        sx={{ boxShadow: '0 13px 33px 0 rgb(41 40 56 / 8%)', borderRadius: 3, height: '100%' }}>
        <Grid
          item
          sx={{ borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }}
          width="100%"
          textAlign={'center'}
          pb={2}
          pt={2}>
          <Typography variant="body1" color={`#979797`}>
            {moment(eventDetails.start).utc().format('MM/DD/YYYY HH:mm')} -{' '}
            {moment(eventDetails.start).utc().add(eventDetails.duration, 'minutes').format('HH:mm')}
          </Typography>
        </Grid>

        <Grid
          item
          sx={{ borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }}
          width="100%"
          textAlign={'center'}
          pb={2}
          pt={2}>
          <Typography variant="body1" color={`#979797`}>
            {eventDetails.sportType !== 'NONE'
              ? t(`sportTypesOptions.${SPORTTYPES_TYPES[eventDetails.sportType]}`)
              : '-'}
          </Typography>
        </Grid>
        <Grid
          item
          display="flex"
          flexDirection="column"
          color={`#979797`}
          sx={{ borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }}
          width="100%"
          textAlign={'center'}
          pb={2}
          pt={2}>
          {eventDetails.equipment.length > 0 ? (
            <Typography variant="body1" color={`#979797`}>
              {eventDetails.equipment
                .map((el) => t(`equipmentsOptions.${EQUIPMENTS_TYPES[el]}`))
                .join(',')}
            </Typography>
          ) : (
            <Typography variant="body1" fontWeight={500} color={`#979797`}>
              -
            </Typography>
          )}
        </Grid>
        <Grid
          item
          display="flex"
          flexDirection="column"
          sx={{ borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }}
          width="100%"
          textAlign={'center'}
          pb={2}
          pt={2}>
          <Typography sx={{ marginBottom: 2 }} fontWeight={500}>
            {t('calendar.subscriptions')}
          </Typography>
          {eventDetails.EventSubscriptions.length > 0 ? (
            eventDetails.EventSubscriptions.filter((sub) => {
              return sub.isAvailable && sub.visibility === 'PUBLIC'
            }).map((el) => (
              <Tooltip
                placement="top"
                title={`${t('calendar.redirectedToBuy')} ${el.name} ${t('calendar.for')} ${
                  el.priceRon
                } ${eventDetails?.Company?.currency || eventDetails?.Trainer?.trainerCurrency || 'RON'}.`}>
                <Typography
                  mb={1}
                  sx={{
                    fontWeight: 500,
                    color: PRIMARY_COLOR,
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}
                  onClick={() =>
                    !user
                      ? navigate(`/login?eventId=${eventDetails.id}`)
                      : navigate(`/checkout?subscription=${el.id}&fromEvent=${eventDetails.id}`)
                  }
                  variant="body1"
                  color={`#979797`}>
                  {el.name}
                </Typography>
              </Tooltip>
            ))
          ) : (
            <Typography fontWeight={500} color={`#979797`}>
              -
            </Typography>
          )}
        </Grid>
        <Grid
          item
          sx={{ borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }}
          width="100%"
          textAlign={'center'}
          pb={2}
          pt={2}>
          <Typography
            color={eventDetails.cost === 'FREE' ? GREEN_COLOR : PRIMARY_COLOR}
            fontWeight={600}
            variant="body1">
            {eventDetails.cost === 'FREE'
              ? t('calendar.free')
              : `${eventDetails.value} ${
                  eventDetails.cost === 'CREDITS' ? t('calendar.credits') : eventDetails?.Company?.currency || eventDetails?.Trainer?.trainerCurrency || 'RON'
                }`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }}
          width="100%"
          textAlign={'center'}
          pb={2}
          pt={2}>
          <Typography variant="body1" fontWeight={600} color={'primary'} sx={{ cursor: 'pointer' }}>
            <a
              style={{ textDecoration: 'none', color: PRIMARY_COLOR }}
              href={`https://www.google.com/maps/search/?api=1&query=${eventDetails.location}`}
              rel="noreferrer"
              target="_blank">
              {eventDetails.location}
            </a>
          </Typography>
        </Grid>
        <Grid
          item
          sx={{ borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }}
          width="100%"
          textAlign={'center'}
          pb={2}
          pt={2}>
          <Typography
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              navigate(
                `/${
                  eventDetails.Trainer ? eventDetails.Trainer.domain : eventDetails.Company.domain
                }`
              )
            }
            color="primary"
            fontWeight={600}
            variant="body1">
            {eventDetails.Trainer
              ? `${eventDetails.Trainer.firstName} ${eventDetails.Trainer.lastName}`
              : `${eventDetails.Company.name}`}
          </Typography>
        </Grid>

        <Grid item display="flex" flexDirection="column" pt={8} mt="auto" pb={4} width="100%">
          {renderButtons}
        </Grid>
      </Grid>
    )
  }, [eventDetails, renderButtons])

  const renderSubscriptionSelectorModal = useMemo(() => {
    if (!subscriptionSelector.open || !subscriptionSelector.participantId) return null
    return (
      <SubscriptionSelectorModal
        subscriptions={availableUsers
          .find((el) => el.id === subscriptionSelector.participantId)
          .Subscriptions.filter((sub) =>
            eventDetails.EventSubscriptions.map(
              (s) => s.Events_Subscriptions.SubscriptionId
            ).includes(sub.subscriptionId)
          )}
        event={{ ...eventDetails, fullStart: eventDetails.start }}
        handleConfirm={(userSubscriptionId) => {
          handleAddParticipant(subscriptionSelector.participantId, userSubscriptionId)
          setSubscriptionSelector({
            open: false,
            participantId: null
          })
        }}
        handleClose={() =>
          setSubscriptionSelector({
            open: false,
            participantId: null
          })
        }
      />
    )
  }, [subscriptionSelector, eventDetails])

  const renderDetails = useMemo(() => {
    if (!eventDetails) return null

    return (
      <>
        <DialogTitle
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          p={4}
          pl={{ xs: 2, md: 2, lg: 3 }}>
          <Grid
            pl={{ xs: 0, sm: 0, md: 3, lg: 3 }}
            pr={{ xs: 0, sm: 0, md: 3, lg: 3 }}
            container
            display="flex"
            flexDirection="row">
            <Grid item lg={10} xs={10}>
              <Typography variant="h5" fontWeight="bold">
                {eventDetails.name || '-'}
              </Typography>
            </Grid>
            <Grid item lg={2} xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
              <IconButton style={{ color: 'black' }} onClick={handleClose}>
                <Close fontSize="medium" color="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            pl={{ xs: 0, sm: 0, md: 3, lg: 3 }}
            pr={{ xs: 0, sm: 0, md: 3, lg: 3 }}
            pt={4}
            pb={8}
            item
            lg={12}>
            <Grid container display="flex" flexDirection="row">
              {matchPhone ? renderRightDetails() : null}
              {renderLeftDetails}
              {!matchPhone ? renderRightDetails() : null}
            </Grid>
          </Grid>
        </DialogContent>
      </>
    )
  }, [matchPhone, renderLeftDetails, eventDetails, renderRightDetails])

  const renderContent = useMemo(() => {
    if (!eventDetails) return null

    if (content === 'trainers')
      return (
        <CalendarEventTrainers
          trainers={eventDetails.EventsTrainers}
          handleClose={() => {
            setContent('')
            handleGetEventById()
          }}
          handleDeleteTrainer={handleDeleteTrainer}
          handleAddTrainer={handleAddTrainer}
        />
      )

    if (content === 'participants' || content === 'waitingList')
      return (
        <CalendarEventParticipants
          subscriptions={eventDetails.EventSubscriptions}
          handleGetCheckins={handleGetCheckins}
          checkins={checkins}
          type={content}
          participants={eventDetails.EventsParticipants}
          handleSetAvailableUsers={(users) => setAvailableUsers(users)}
          access={eventDetails.access}
          eventId={eventDetails.id}
          handleClose={() => {
            setContent('')
            handleGetEventById()
          }}
          eventStart={eventDetails.start}
          handleDeleteParticipant={handleDeleteParticipant}
          handleAddParticipant={(participantId, userSubscriptionId, usersToUse) => {
            if (
              content === 'participants' &&
              eventDetails.maxParticipants ===
                eventDetails.EventsParticipants.filter(
                  (el) => !el.Events_Participants.isInWaitingList
                ).length
            ) {
              toast.warn(t('calendar.exceededParticipants'), { position: 'bottom-right' })
              return
            }
            handleAddParticipant(participantId, userSubscriptionId, usersToUse)
          }}
        />
      )

    return renderDetails
  }, [content, checkins, eventDetails, renderDetails])

  if (!eventDetails) return null

  return (
    <>
      {renderSubscriptionSelectorModal}
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth={'xl'}
        fullScreen={matchPhone ? true : false}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            borderRadius: matchPhone ? 0 : '',
            paddingTop: '3%',
            height: matchPhone ? '100%' : '90%'
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <Container
          maxWidth="xl"
          sx={{ paddingLeft: '0px !important', paddingRight: '0px !important' }}>
          <>{renderContent}</>
        </Container>
      </Dialog>
    </>
  )
}

export default CalendarEventDetailsModal
