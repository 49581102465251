import * as React from 'react'
import { Tabs, Tab, Box } from '@mui/material'

const TabPanel = (props) => {
  const { children, value, index } = props

  return value === index && <Box>{children}</Box>
}

const CommanTabs = ({ options, value, onChange, variant, ...props }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Tabs
          {...props}
          value={value}
          variant={variant || 'scrollable'}
          scrollButtons="auto"
          allowScrollButtonsMobile
          onChange={onChange}
        >
          {options.map((option, index) => (
            <Tab
              sx={{ flex: 1, minWidth: 'min-content' }}
              key={index}
              label={option.label}
              disabled={option.disabled}
            />
          ))}
        </Tabs>
      </Box>
      {options.map((option, index) => (
        <TabPanel key={index} value={value} index={index}>
          {option.value}
        </TabPanel>
      ))}
    </Box>
  )
}

export default CommanTabs
