import React, { useCallback, useMemo, useState } from 'react'
import { Grid, Typography, TextField, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

import LOGIN_BG from '../../assets/login.png'

import { ResetEmailSent } from '../ResetPassword/subcomponents/'
import { SendingButton as SendingButtonChild } from '../common'
import { BLACK, DEFAULT_MAX_LENGTH } from '../../utils/constants'

import { isValidEmail } from '../../utils/helpers/validator'
import * as AUTH_REQUESTS from '../../api/auth'

const useStyles = makeStyles({
  forgottenContainer: {
    paddingTop: '0.4rem'
  },
  forgottenText: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  createAccountText: {
    cursor: 'pointer'
  }
})

const ResetPassword = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [wrongInputs, setWrongInputs] = useState([])

  const [email, setEmail] = useState('')
  const [resetEmail, setResetEmail] = useState(false)
  const [sending, setSending] = useState(false)

  const handleChangeEmail = useCallback((event) => {
    setWrongInputs((prevValues) => [...prevValues.filter((el) => el !== 'email')])
    setEmail(event.target.value)
  }, [])

  const handleSentEmail = async () => {
    try {
      let wrong = false
      if (!isValidEmail(email)) {
        setWrongInputs((prevValues) => [...prevValues, 'email'])
        wrong = true
      }

      if (wrong) return

      setSending(true)
      await AUTH_REQUESTS.resetPassword(email)
      setSending(false)

      setResetEmail(true)
    } catch (e) {
      setSending(false)
      setResetEmail(true)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSentEmail()
    }
  }

  const renderEnterEmail = useMemo(() => {
    return (
      <Grid item xs={12} lg={6} p={{ xs: 2, lg: 10 }}>
        <Grid container height="100%" alignItems="center">
          <Grid container direction="column" rowSpacing={4}>
            <Grid item>
              <Typography variant="h4" fontWeight={'bold'} style={{ color: BLACK }}>
                {t('helper.pleaseSelectEmail')}
              </Typography>
            </Grid>
            <Grid
              maxHeight={90}
              height={90}
              item
              maxWidth={{ sx: '100% !important', lg: '60% !important' }}
            >
              <TextField
                onKeyDown={handleKeyDown}
                onChange={handleChangeEmail}
                defaultValue={email}
                placeholder={t('input.placeholder.email')}
                variant="outlined"
                fullWidth
                inputProps={{
                  maxLength: DEFAULT_MAX_LENGTH
                }}
                error={wrongInputs.includes('email')}
                helperText={wrongInputs.includes('email') ? t('input.error.email') : ''}
              />
            </Grid>
            <Grid item display="flex" flexDirection="row">
              <Grid container display="flex" flexDirection="row">
                <Grid item xs={12} lg={6} pl={{ xs: 0, lg: 8 }}>
                  <Button
                    disabled={sending || !email}
                    onClick={handleSentEmail}
                    style={{ backgroundColor: BLACK, color: '#fff' }}
                    variant="contained"
                    fullWidth
                  >
                    {!sending ? t('button.resetLink') : <SendingButtonChild />}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }, [
    classes.forgottenContainer,
    classes.createAccountText,
    classes.forgottenText,
    handleChangeEmail,
    email,
    wrongInputs,
    sending,
    t
  ])

  const renderImage = useMemo(() => {
    return (
      <Grid
        item
        alignItems="center"
        display={{ xs: 'none', sm: 'none', lg: 'block' }}
        lg={6}
        maxHeight={'98vh'}
      >
        <img
          style={{ position: 'relative', left: '3%', top: '-30%', objectFit: 'cover' }}
          height={'150%'}
          src={LOGIN_BG}
          width={1200}
        />
      </Grid>
    )
  }, [])

  return resetEmail ? (
    <ResetEmailSent email={email} handleSendLink={handleSentEmail} />
  ) : (
    <Grid container style={{ padding: 0, overflowX: 'hidden' }} height="100%">
      {renderEnterEmail}
      {renderImage}
    </Grid>
  )
}

export default ResetPassword
