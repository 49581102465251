import React, { useEffect, useState } from 'react'
import { Typography, Grid, Card, Box, IconButton, Popper, Fade, Paper, TextField, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useAuthentication } from '../../../context/auth-context'
import { ROLE_BUSINESS_ADMINISTRATOR, GRAY_TEXT_COLOR } from '../../../utils/constants'
import * as DASHBOARD_REQUESTS from '../../../api/dashboard'
import { VerticalBarChart } from '../../common'
import { FileDownloadOutlined } from '@mui/icons-material';


export default () => {
    const { t } = useTranslation()
    const { user } = useAuthentication()

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()
    const [anchorEl, setAnchorEl] = useState(null)
    const [open, setOpen] = useState(false)
    const [placement, setPlacement] = useState()
    const [exportStart, setExportStart] = useState()
    const [exportEnd, setExportEnd] = useState()

    useEffect(() => {
        if (!user || !user.role === ROLE_BUSINESS_ADMINISTRATOR) return

        DASHBOARD_REQUESTS.getReportCompanyActiveUsers()
            .then(response => {
                setData(response)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [user])

    const handleOpenPopper = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget)
        setOpen((prev) => placement !== newPlacement || !prev)
        setPlacement(newPlacement)
    }

    const handleExport = () => {
        setAnchorEl()
        setOpen(false)
    }

    if (loading || !data) return null

    return (
        <>
            {/* <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <Box p={2} border={`1px solid rgba(0,0,0,0.1)`}>
                                <Typography pb={2} variant="subtitle1">Please select Time Interval</Typography>
                                <Box display="flex" flexDirection="row">
                                    <Box pr={1}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                inputFormat="MM/dd/yyyy"
                                                value={exportStart}
                                                onChange={(newValue) => setExportStart(newValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        sx={{
                                                            '.MuiIconButton-root': { marginRight: 1 }
                                                        }}
                                                        {...params}
                                                    />
                                                )} />
                                        </LocalizationProvider>
                                    </Box>
                                    <Box pl={1}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                inputFormat="MM/dd/yyyy"
                                                value={exportEnd}
                                                onChange={(newValue) => setExportEnd(newValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        sx={{
                                                            '.MuiIconButton-root': { marginRight: 1 }
                                                        }}
                                                        {...params}
                                                    />
                                                )} />
                                        </LocalizationProvider>
                                    </Box>
                                </Box>
                                <Box pt={2}>
                                    <Button onClick={handleExport} variant="outlined" color="primary">Export</Button>
                                </Box>
                            </Box>
                        </Paper>
                    </Fade>
                )}
            </Popper> */}
            <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column">
                        <Typography variant="p" fontWeight={'bold'} fontSize={20} pl={1}>
                            {t('dashboard.overview.activeUsers')}
                        </Typography>
                        <Typography variant="p" pb={1} pl={1} color={GRAY_TEXT_COLOR}>{t('reports.last12months')}</Typography>
                    </Box>
                    {/* <Box ml="auto">
                        <IconButton onClick={handleOpenPopper('bottom-start')}><FileDownloadOutlined /></IconButton>
                    </Box> */}
                </Box>
                <Grid container component={Card} sx={{
                    boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
                    border: '1px solid #F0F0F0',
                    borderRadius: 2
                }} p={2} mt={1}>
                    <Grid item xs={12}>
                        <VerticalBarChart maintainAspectRatio={true} data={{
                            labels: Object.keys(data).map(el => {
                                return el
                            }),
                            datasets: [
                                {
                                    borderColor: '#ffffff',
                                    label: t('helper.activeUsers'),
                                    data: Object.values(data).map(value => value.length),
                                    backgroundColor: 'rgba(33, 150, 243, 1)',
                                }
                            ]
                        }} />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}