import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { LIGHT_GRAY_BORDER_COLOR } from "../../../../utils/constants";
import UserDetailsCell from "./UserDetailsCell";
import TypeCell from "./TypeCell";
import ActionCell from "./ActionCell";
import CreditsUsedCell from "./CreditsUsedCell";
import CreditsAvailableCell from "./CreditsAvailableCell";
import ItemCell from "./ItemCell";
import TimestampCell from "./TimestampCell";

const ExpendedMonthDetails = ({ data, index }) => (
    <TableContainer>
        <Table size={'medium'}>
            <TableBody>
                {data.map((item, index) => (
                    <TableRow key={index} sx={{ display: 'flex', alignItems: 'center', '&:last-child td, &:last-child th': { border: 0 }, height: '9vh', overflow: 'hidden' }} style={{ backgroundColor: index % 2 === 0 ? LIGHT_GRAY_BORDER_COLOR : 'white' }}>
                        <TableCell sx={{ width: '35px', border: 'none !important' }}></TableCell>
                        <UserDetailsCell userDetails={item.user} />
                        <TypeCell type={item.type} />
                        <ItemCell item={item.item} />
                        <ActionCell action={item.action} />
                        <CreditsUsedCell credits={item.creditsUsed} />
                        <CreditsAvailableCell credits={item.creditsAvailable} />
                        <TimestampCell timestamp={item.timestamp} />
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
);

export default ExpendedMonthDetails;