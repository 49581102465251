import React, { useMemo } from 'react'
import { Grid, Typography, Skeleton, Box, Paper, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ArrowForwardIos } from '@mui/icons-material'

import { GRAY_TEXT_COLOR } from '../../../utils/constants'

const TrainerList = ({ trainers, loading, count, onNextPage }) => {
  const { t } = useTranslation()

  const renderContent = useMemo(() => {
    return trainers.map((row, key) => {
      return (
        <Grid item xs={12} lg={2.4} key={key}>
          <Link style={{ textDecoration: 'none' }} to={'/' + row.domain}>
            <Grid
              container
              component={Paper}
              elevation={3}
              sx={{
                ...(row.avatarPhotoSrc
                  ? {
                    backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.5)),url(${row.avatarPhotoSrc})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                  }
                  : {
                    backgroundColor: 'black'
                  }),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: 220
              }}
              key={key}
            >
              <Grid item xs={12} p={1.5}>
                <Box style={{ height: '50%', color: 'white' }}></Box>
                <Box
                  style={{
                    height: '50%',
                    justifyContent: 'flex-end',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant="h5" noWrap fontWeight="bold" color="white" maxWidth="90%">
                    {row.trainerName}
                  </Typography>
                  <Typography variant="p" color="white" noWrap maxWidth="90%">
                    {row.defaultSportType ? t(`sportTypesOptions.${row.defaultSportType}`) : row.locationName}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Link>
        </Grid>
      )
    })
  }, [trainers])

  const renderMore = () => {
    return (
      <Grid item xs={12} lg={2.4} onClick={onNextPage}>
        <Grid
          container
          component={Paper}
          elevation={0}
          style={{ height: 220, cursor: 'pointer', boxShadow: '0 13px 33px 0 rgb(41 40 56 / 8%)' }}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <IconButton>
            <ArrowForwardIos fontSize="medium" style={{ color: GRAY_TEXT_COLOR }} />
          </IconButton>
          <Typography variant="subtitle1" fontWeight={500} color={GRAY_TEXT_COLOR}>
            {t('explore.showMore')}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container display="flex" flexDirection="row">
          <Grid item xs={12} lg={12}>
            <Typography alignSelf="center" fontWeight="bold" variant="h5">
              {trainers.length && count ? count : null} {t('helper.findFavoriteTrainer')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
          pb={5}
          pt={1}
          spacing={2}
        >
          {!loading && !trainers.length ? (
            <Grid item xs={12}>
              <Typography variant="h6" fontWeight={500} color={GRAY_TEXT_COLOR}>
                {t('explore.noResult')}
              </Typography>
            </Grid>
          ) : null}
          {trainers.length ? renderContent : null}
          {loading ? (
            <Grid item xs={12} lg={2.4}>
              <Skeleton
                variant="rectangular"
                component={Paper}
                elevation={3}
                style={{ borderRadius: 2 }}
                height={220}
              />
            </Grid>
          ) : null}
          {loading ? (
            <Grid item xs={12} lg={2.4}>
              <Skeleton
                variant="rectangular"
                component={Paper}
                elevation={3}
                style={{ borderRadius: 2 }}
                height={220}
              />
            </Grid>
          ) : null}
          {loading ? (
            <Grid item xs={12} lg={2.4}>
              <Skeleton
                variant="rectangular"
                component={Paper}
                elevation={3}
                style={{ borderRadius: 2 }}
                height={220}
              />
            </Grid>
          ) : null}
          {loading ? (
            <Grid item xs={12} lg={2.4}>
              <Skeleton
                variant="rectangular"
                component={Paper}
                elevation={3}
                style={{ borderRadius: 2 }}
                height={220}
              />
            </Grid>
          ) : null}
          {loading ? (
            <Grid item xs={12} lg={2.4}>
              <Skeleton
                variant="rectangular"
                component={Paper}
                elevation={3}
                style={{ borderRadius: 2 }}
                height={220}
              />
            </Grid>
          ) : null}
          {loading ? (
            <Grid item xs={12} lg={2.4}>
              <Skeleton
                variant="rectangular"
                component={Paper}
                elevation={3}
                style={{ borderRadius: 2 }}
                height={220}
              />
            </Grid>
          ) : null}
          {trainers.length && trainers.length < count ? renderMore() : null}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TrainerList
