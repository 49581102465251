import React, { useEffect, useState, useMemo, useRef } from 'react'
import {
  Grid,
  IconButton,
  Dialog,
  Typography,
  Button,
  TextField,
  Box,
  Divider,
  Autocomplete,
  Chip,
  Tooltip,
  Container
} from '@mui/material'
import { toast } from 'react-toastify';
import {
  Close,
  Add,
  NotInterested,
  CheckCircleOutline,
  Delete,
  PendingActionsOutlined,
  PlayArrow,
  RemoveCircle
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { Tabs, InputGenerator, ConfirmNoSavingModal } from '../../../common'
import FavouritTrainer from './subcomponents/FavouritTrainer'
import { HourMinutesMask } from '../../../../utils/inputMask'
import { refetchBusinessProfileObservable } from '../../../../utils/observables'
import { SendingButton as SendingButtonChild } from '../../../common'
import * as validators from '../../../../utils/helpers/validator'
import Equipments from './subcomponents/Equipments'

import * as COMPANY_REQUESTS from '../../../../api/company'
import * as USER_REQUESTS from '../../../../api/user'
import * as DOCUMENTS_REQUESTS from '../../../../api/documents'

import { useAuthentication } from '../../../../context/auth-context'
import { BUCKET_BASE_URL } from '../../../../utils/config'
import { switchRoleObservable } from '../../../../utils/observables'

import {
  SECONDARY_TEXT_COLOR,
  GRAY_BG_COLOR,
  GRAY_TEXT_COLOR,
  PRIMARY_COLOR,
  LIGHT_BLUE_COLOR,
  CARD_TEXT_COLOR,
  CHIP_BLUE_COLOR,
  YELLOW_CHIP_COLOR,
  COMPANIES_TRAINERS_REQUEST_STATUS,
  GREEN_CHIP_COLOR,
  RED_CHIP_COLOR,
  DEFAULT_MAX_LENGTH,
} from '../../../../utils/constants'

const useStyles = makeStyles(() => ({
  noBorder: {
    borderRadius: 0
  }
}))

const ERRORS_MAPPING = {
  'PART_OF_SUBSCRIPTIONS': 'Ops! It looks like this trainer is part of a subscription.',
  'PART_OF_UPCOMING_EVENTS': 'Ops! It looks like this trainer is part of an upcoming event.'
}

const EditProfile = ({ onClose, Transition }) => {
  const { t } = useTranslation()
  const { user } = useAuthentication()
  const classes = useStyles()

  const profileFileInputRef = useRef()
  const photoFileInputRef = useRef()
  const registrationCertificateInputRef = useRef()
  const identityCardInputRef = useRef()

  const [isLoading, setIsLoading] = useState(false)
  const [profileFileLoading, setProfileFileLoading] = useState(false)
  const [photoLoading, setPhotoLoading] = useState(false)
  const [registrationCertificateLoading, setRegistrationCertificateLoading] = useState(false)
  const [identityCardLoading, setIdentityCardLoading] = useState(false)

  const [trainerFilter, setTrainerFilter] = useState()
  const [photos, setPhotos] = useState([])
  const [company, setCompany] = useState()
  const [services, setServices] = useState([])
  const [selectedServices, setSelectedServices] = useState([])
  const [trainers, setTrainers] = useState([])
  const [selectedTrainers, setSelectedTrainers] = useState([])

  const [activeTab, setActiveTab] = useState(0)

  const [openClose, setOpenClose] = useState(false)
  const [changesMade, setChangesMade] = useState(false)

  const getAllTrainers = (options = {}) => {
    return USER_REQUESTS.getAllUsers({ role: 'TRAINER', isAccepted: true, ...options }).then((response) => {
      setTrainers(response)
    })
  }

  const renderEquipments = useMemo(() => {
    return <Equipments equipment={true} />
  }, [])

  const renderTypes = useMemo(() => {
    if (!company) return null
    return <Equipments type={true} companyId={company.id} companyDefaultSportType={company.defaultSportType} refetchCompany={() => COMPANY_REQUESTS.getBusiness(company.id).then(response => setCompany(response))} />
  }, [company])

  useEffect(() => {
    COMPANY_REQUESTS.getAllMembers(user.companyId).then((associatedTrainers) =>
      setSelectedTrainers(associatedTrainers)
    )

    COMPANY_REQUESTS.getBusiness(user.companyId).then((response) => {
      setCompany(response)
      setPhotos(response.Documents.filter((el) => el.isPhoto))
      setSelectedServices(
        response.Services.filter((el) => el.display).map((el) => ({ id: el.id, label: el.title }))
      )
    })
    COMPANY_REQUESTS.getAllServices(user.companyId).then(setServices)
  }, [user])

  useEffect(() => {
    if (trainerFilter) {
      getAllTrainers({
        search: trainerFilter
      })
    }
  }, [trainerFilter])

  const onChangeHandler = (name, value) => {
    setCompany({
      ...company,
      [name]: value
    })
  }

  const onRegistrationCertificateChangeHandler = (event) => {
    const file = event.target.files[0]
    const name = Date.now()
    const extension = file.type.split('/')[1]

    setRegistrationCertificateLoading(true)
    DOCUMENTS_REQUESTS.generatePresignedUrl({ name, extension })
      .then((presignedUrl) => {
        return DOCUMENTS_REQUESTS.uploadDocumentS3(presignedUrl, file)
      })
      .then(() => {
        return COMPANY_REQUESTS.updateBusiness(company.id, {
          registrationCertificateSrc: `${BUCKET_BASE_URL}/${name}.${extension}`
        })
      })
      .then(() => {
        setChangesMade(true)
        setCompany((prevCompany) => ({
          ...prevCompany,
          registrationCertificateSrc: `${BUCKET_BASE_URL}/${name}.${extension}`
        }))
        setRegistrationCertificateLoading(false)
      })
      .catch(() => {
        setRegistrationCertificateLoading(false)
      })
  }

  const onIdentityCardChangeHandler = (event) => {
    const file = event.target.files[0]
    const name = Date.now()
    const extension = file.type.split('/')[1]

    setIdentityCardLoading(true)
    DOCUMENTS_REQUESTS.generatePresignedUrl({ name, extension })
      .then((presignedUrl) => {
        return DOCUMENTS_REQUESTS.uploadDocumentS3(presignedUrl, file)
      })
      .then(() => {
        return COMPANY_REQUESTS.updateBusiness(company.id, {
          identityCardSrc: `${BUCKET_BASE_URL}/${name}.${extension}`
        })
      })
      .then(() => {
        setChangesMade(true)
        setCompany((prevCompany) => ({
          ...prevCompany,
          identityCardSrc: `${BUCKET_BASE_URL}/${name}.${extension}`
        }))
        setIdentityCardLoading(false)
      })
      .catch(() => {
        setIdentityCardLoading(false)
      })
  }

  const onProfileFileChangeHandler = (event) => {
    const file = event.target.files[0]
    const name = Date.now()
    const extension = file.type.split('/')[1]

    setProfileFileLoading(true)
    DOCUMENTS_REQUESTS.generatePresignedUrl({ name, extension })
      .then((presignedUrl) => {
        return DOCUMENTS_REQUESTS.uploadDocumentS3(presignedUrl, file)
      })
      .then(() => {
        return COMPANY_REQUESTS.updateBusiness(company.id, {
          avatarPhotoSrc: `${BUCKET_BASE_URL}/${name}.${extension}`
        })
      })
      .then(() => {
        setProfileFileLoading(false)
        return COMPANY_REQUESTS.getBusiness(company.id)
      })
      .then((response) => {
        setChangesMade(true)
        setCompany(response)
        setPhotos(response.Documents.filter((el) => el.isPhoto))
        setSelectedServices(
          response.Services.filter((el) => el.display).map((el) => ({ id: el.id, label: el.title }))
        )
      })
      .catch(() => {
        setProfileFileLoading(false)
      })
  }

  const onPhotoChangeHandler = (event) => {
    const file = event.target.files[0]

    const name = Date.now()
    const extension = file.type.split('/')[1]

    setPhotoLoading(true)

    DOCUMENTS_REQUESTS.generatePresignedUrl({ name, extension })
      .then((presignedUrl) => {
        return DOCUMENTS_REQUESTS.uploadDocumentS3(presignedUrl, file)
      })
      .then(() => {
        return DOCUMENTS_REQUESTS.createDocument({
          src: `${BUCKET_BASE_URL}/${name}.${extension}`,
          companyId: company.id,
          isPhoto: true
        })
      })
      .then((res) => {
        setChangesMade(true)
        setPhotos(photos.concat({ ...res }))
        setPhotoLoading(false)
      })
      .catch(() => {
        setPhotoLoading(false)
      })
  }

  const onSaveHandler = () => {
    if ((company.mondayFridayStartProgram && company.mondayFridayStartProgram.length !== 5) || (company.mondayFridayEndProgram && company.mondayFridayEndProgram.length !== 5) || (company.saturdayStartProgram && company.saturdayStartProgram.length !== 5) || (company.saturdayEndProgram && company.saturdayEndProgram.length !== 5) || (company.sundayStartProgram && company.sundayStartProgram.length !== 5) || (company.sundayEndProgram && company.sundayEndProgram.length !== 5)) {
      toast.error(t('businessEditProfile.wrongDates'), { position: 'bottom-right', autoClose: false })
      return
    }
    setIsLoading(true)
    const { domain, ...restOfPayload } = company
    return COMPANY_REQUESTS.updateBusiness(company.id, {
      ...restOfPayload,
      selectedServices: selectedServices.map((el) => el.id)
    }).then(() => {
      refetchBusinessProfileObservable.setRefetchBusinessProfile(true)
      switchRoleObservable.setSwitchCompanyRole({ companyId: user.companyId })
      setIsLoading(false)
      onClose()
    })
  }

  const onDeleteMemberHandler = (memberId) => {
    return COMPANY_REQUESTS.removeMember(user.companyId, memberId)
      .then(() => COMPANY_REQUESTS.getAllMembers(user.companyId))
      .then((associatedTrainers) => setSelectedTrainers(associatedTrainers))
      .catch((e) => {
        if (e && e.response && e.response.data) {
          if (e.response.data.split('\n').length > 0) {
            if (e.response.data.split('\n')[0].split('Error: ').length > 1) {
              toast.error(ERRORS_MAPPING[e.response.data.split('\n')[0].split('Error: ')[1]], { position: 'bottom-right' })
            }
          }
        }
      })
  }

  const onAddMemberHandler = (memberId) => {
    return COMPANY_REQUESTS.addMember(user.companyId, memberId)
      .then(() => COMPANY_REQUESTS.getAllMembers(user.companyId))
      .then((associatedTrainers) => setSelectedTrainers(associatedTrainers))
  }

  const handleRemoveProfileImage = async () => {
    try {
      setProfileFileLoading(true)
      await COMPANY_REQUESTS.updateBusiness(company.id, { avatarPhotoSrc: null })

      COMPANY_REQUESTS.getBusiness(company.id).then((response) => {
        setCompany(response)
        setPhotos(response.Documents.filter((el) => el.isPhoto))
        setSelectedServices(
          response.Services.filter((el) => el.display).map((el) => ({ id: el.id, label: el.title }))
        )
      })
      setProfileFileLoading(false)
    } catch (e) {
      setProfileFileLoading(false)
      console.error(e)
    }
  }

  const handleDeletePhoto = async (photo) => {
    try {
      await DOCUMENTS_REQUESTS.deleteDocument(photo.id)
      COMPANY_REQUESTS.getBusiness(company.id).then((response) => {
        setCompany(response)
        setPhotos(response.Documents.filter((el) => el.isPhoto))
        setSelectedServices(
          response.Services.filter((el) => el.display).map((el) => ({ id: el.id, label: el.title }))
        )
      })
    } catch (e) {
      console.error(e)
    }
  }

  const renderActionRegistrationCertificate = useMemo(() => {
    if (!company) return null

    const onDocumentCancel = () => {
      setRegistrationCertificateLoading(true)
      return COMPANY_REQUESTS.updateBusiness(company.id, {
        registrationCertificateSrc: null,
        registrationCertificateDenied: false
      }).then(() => {
        setCompany((prevCompany) => ({
          ...prevCompany,
          registrationCertificateSrc: null,
          registrationCertificateDenied: false
        }))
        setRegistrationCertificateLoading(false)
      })
    }

    let icon = <CheckCircleOutline color="primary" fontSize="small" />
    let title = t("helper.registerCertificateSuccess")
    let cancelIcon = null

    if (!company.registrationCertificateApproved && company.registrationCertificateSrc) {
      icon = <PendingActionsOutlined color="warning" fontSize="small" />
      title = t("helper.registerCertificateWait")
      cancelIcon = (
        <IconButton onClick={onDocumentCancel}>
          <Close color="error" fontSize="small" />
        </IconButton>
      )
    }

    if (!company.registrationCertificateApproved && !company.registrationCertificateSrc) {
      icon = <PlayArrow color="warning" fontSize="small" />
      title = t("helper.registerCertificatePhoto")
    }

    if (company.registrationCertificateDenied && company.registrationCertificateSrc) {
      icon = <NotInterested color="error" fontSize="small" />
      title = t("helper.registerCertificateNotApproved")
    }

    return (
      <Tooltip title={title} arrow>
        <Box display="flex" flexDirection="row" alignItems="center">
          {icon}{' '}
          <Typography
            onClick={
              !company.registrationCertificateSrc || company.registrationCertificateDenied
                ? () => {
                  if (registrationCertificateLoading) return

                  registrationCertificateInputRef.current.click()
                }
                : null
            }
            sx={{
              ':hover': {
                cursor: company.registrationCertificateSrc
                  ? null
                  : registrationCertificateLoading
                    ? 'wait'
                    : 'pointer',
                textDecoration: company.registrationCertificateSrc
                  ? 'none'
                  : registrationCertificateLoading
                    ? 'none'
                    : 'underline'
              }
            }}
            pl={0.5}
            variant="p"
            color="black"
          >
            {' '}
            {t('businessEditProfile.companyRegistration')}
          </Typography>{' '}
          {cancelIcon}
        </Box>
      </Tooltip>
    )
  }, [company, registrationCertificateLoading])

  const renderIdentity = useMemo(() => {
    if (!company) return null

    const onDocumentCancel = () => {
      setIdentityCardLoading(true)
      return COMPANY_REQUESTS.updateBusiness(company.id, {
        identityCardSrc: null,
        identityCardDenied: false
      }).then(() => {
        setCompany((prevCompany) => ({
          ...prevCompany,
          identityCardSrc: null,
          identityCardDenied: false
        }))
        setIdentityCardLoading(false)
      })
    }

    let title = t("helper.registerCertificateSuccess")
    let icon = <CheckCircleOutline color="primary" fontSize="small" />
    let cancelIcon = null

    if (!company.identityCardApproved && company.identityCardSrc) {
      icon = <PendingActionsOutlined color="warning" fontSize="small" />
      title = t("helper.registerCertificateWait")
      cancelIcon = (
        <IconButton onClick={onDocumentCancel}>
          <Close color="error" fontSize="small" />
        </IconButton>
      )
    }

    if (!company.identityCardApproved && !company.identityCardSrc) {
      icon = <PlayArrow color="warning" fontSize="small" />
      title = t("helper.registerCertificatePhoto")
    }

    if (company.identityCardDenied && company.identityCardSrc) {
      icon = <NotInterested color="error" fontSize="small" />
      title = t("helper.registerCertificateNotApproved")
    }

    return (
      <Tooltip title={title} arrow>
        <Box display="flex" flexDirection="row" alignItems="center">
          {icon}{' '}
          <Typography
            onClick={
              !company.identityCardSrc || company.identityCardDenied
                ? () => {
                  if (identityCardLoading) return

                  identityCardInputRef.current.click()
                }
                : null
            }
            sx={{
              ':hover': {
                cursor: company.identityCardSrc ? null : identityCardLoading ? 'wait' : 'pointer',
                textDecoration: company.identityCardSrc
                  ? 'none'
                  : identityCardLoading
                    ? 'none'
                    : 'underline'
              }
            }}
            pl={0.5}
            variant="p"
            color="black"
          >
            {t('businessEditProfile.companyIdentityCard')}
          </Typography>
          {cancelIcon}
        </Box>
      </Tooltip>
    )
  }, [company, identityCardLoading])

  const renderGeneralInformation = useMemo(() => {
    if (!company) return null

    const selectedServicesIds = selectedServices.map((el) => el.id)

    return (
      <>
        <input
          type="file"
          ref={profileFileInputRef}
          onChangeCapture={onProfileFileChangeHandler}
          style={{ display: 'none' }}
        />
        <input
          type="file"
          ref={identityCardInputRef}
          onChangeCapture={onIdentityCardChangeHandler}
          style={{ display: 'none' }}
        />
        <input
          type="file"
          ref={registrationCertificateInputRef}
          onChangeCapture={onRegistrationCertificateChangeHandler}
          style={{ display: 'none' }}
        />
        <input
          type="file"
          ref={photoFileInputRef}
          onChangeCapture={onPhotoChangeHandler}
          style={{ display: 'none' }}
        />
        <Grid item xs={12} pt={4} pb={4}>
          <Grid container display="flex" flexDirection="row">
            <Grid item xs={12} lg={6} md={6} pr={{ xs: 0, lg: 1.5, md: 1.5 }}>
              <Grid container display="flex" flexDirection="column">
                <Grid item display="flex" flexDirection="row">
                  <Grid xs={12} item display="flex" alignItems="center">
                    <Grid item>
                      {company.avatarPhotoSrc ? (
                        <img
                          src={company.avatarPhotoSrc}
                          alt="avatar"
                          style={{ borderRadius: 50, objectFit: 'fill' }}
                          height={100}
                          width={100}
                        />
                      ) : (
                        <Box
                          sx={{
                            width: 100,
                            height: 100,
                            backgroundColor: GRAY_BG_COLOR,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 50
                          }}
                        >
                          <Typography fontWeight="bold" color={GRAY_TEXT_COLOR} variant="h4">
                            {company.BusinessOwner.firstName.charAt(0)}{' '}
                            {company.BusinessOwner.lastName.charAt(0)}
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                    <Grid container display="flex" flexDirection={{ lg: 'row', xs: 'column' }}>
                      <Grid xs={12} lg={7} pl={2} pb={{ xs: 1 }} item>
                        <Button
                          onClick={() => profileFileInputRef.current.click()}
                          fullWidth
                          variant="outlined"
                          disabled={profileFileLoading}
                        >
                          {profileFileLoading ? (
                            <SendingButtonChild />
                          ) : (
                            t('userProfile.uploadImage')
                          )}
                        </Button>
                      </Grid>
                      <Grid item lg={4} xs={12} pl={{ xs: 1 }}>
                        {company.avatarPhotoSrc ? (
                          <Button
                            onClick={handleRemoveProfileImage}
                            sx={{ minWidth: 70, marginLeft: 1 }}
                            variant="outlined"
                            color="error"
                          >
                            <Delete />
                          </Button>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid paddingTop={2}>
                  <Grid>
                    <Typography fontWeight={500} variant="p">
                      {t('businessEditProfile.about')}
                    </Typography>
                  </Grid>
                  <Grid paddingTop={1}>
                    <ReactQuill
                      defaultValue={company.bio}
                      modules={{
                        clipboard: {
                          matchVisual: false
                        }
                      }}
                      onChange={(newContent) => {
                        onChangeHandler('bio', newContent)
                        setChangesMade(true)
                      }}
                    >
                      <div style={{ minHeight: 250 }} />
                    </ReactQuill>
                  </Grid>
                </Grid>

                <Grid paddingTop={2} pr={{ xs: 0, lg: 3 }}>
                  <Grid>
                    <Typography fontWeight={500} variant="p">
                      {t('businessEditProfile.services')}
                    </Typography>
                  </Grid>
                  <Grid paddingTop={1}>
                    <Autocomplete
                      options={services
                        .filter((el) => !el.display && !selectedServicesIds.includes(el.id))
                        .map((el) => ({ id: el.id, label: el.title }))}
                      autoHighlight
                      onChange={(_, newValue) => {
                        if (!newValue) return
                        setChangesMade(true)
                        setSelectedServices(selectedServices.concat(newValue))
                      }}
                      renderInput={(params) => (
                        <TextField
                          placeholder={'Search Service'}
                          {...params}
                          InputProps={{ ...params.InputProps, endAdornment: null }}
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              marginLeft: 6,
                              padding: 11.5,
                              color: SECONDARY_TEXT_COLOR,
                              fontWeight: 500
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid container pt={2} flexWrap="wrap" spacing={1}>
                    {selectedServices.map((el, index) => {
                      return (
                        <Grid item key={index}>
                          <Chip
                            sx={{ fontWeight: 500, backgroundColor: CHIP_BLUE_COLOR }}
                            color="primary"
                            variant="outlined"
                            label={el.label}
                            onDelete={() => {
                              setChangesMade(true)
                              setSelectedServices(
                                selectedServices.filter((service) => service.id !== el.id)
                              )
                            }}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>

                <Grid item pt={2}>
                  <Grid pb={2}>
                    <Typography fontWeight={500} variant="p">
                      {t('businessEditProfile.galery')}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    flexWrap="wrap"
                    display="flex"
                    pl={1}
                    flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }}
                  >
                    <Grid container display="flex" flexWrap="wrap" pr={1}>
                      {photos.map((el, index) => {
                        return (
                          <>
                            <Box
                              key={index}
                              maxWidth={60}
                              minWidth={60}
                              maxHeight={60}
                              minHeight={60}
                              md={2}
                              lg={1.5}
                              mr={2}
                              sx={{
                                backgroundImage: `url(${el.src})`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                              }}
                            >
                              <RemoveCircle
                                onClick={() => handleDeletePhoto(el)}
                                sx={{
                                  color: '#e53935',
                                  cursor: 'pointer',
                                  position: 'relative',
                                  left: 45,
                                  top: -10
                                }}
                              />
                            </Box>
                          </>
                        )
                      })}
                      <Grid item xs={3} md={2} lg={1.5} display="flex" flex={1}>
                        <Box
                          mt={{ xs: photos.length >= 4 ? 2 : 0, sm: 0 }}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 60,
                            height: 60,
                            border: `1px solid ${PRIMARY_COLOR}`,
                            backgroundColor: LIGHT_BLUE_COLOR
                          }}
                        >
                          <IconButton
                            disabled={photoLoading}
                            onClick={() => {
                              if (photos.length === 6) {
                                toast.error(
                                  t("helper.maxPhotos"), { position: 'bottom-right' }
                                ), { position: 'bottom-right' }
                                return
                              }

                              photoFileInputRef.current.click()
                            }}
                            sx={{ width: '100%', height: '100%', borderRadius: 0 }}
                          >
                            <Add color="primary" />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={6} md={6} pl={{ xs: 0, lg: 1.5, md: 1.5 }}>
              <Grid container display="flex" flexDirection="column">
                <Grid paddingTop={2}>
                  <Grid>
                    <Typography fontWeight={500} variant="p" display="flex" flexDirection="row">
                      {t('businessEditProfile.clubName')}
                      <Typography color="primary">&nbsp;*</Typography>
                    </Typography>
                  </Grid>
                  <Grid paddingTop={1}>
                    <TextField
                      fullWidth
                      error={!validators.isValidText(company.name)}
                      helperText={
                        !validators.isValidText(company.name) && t('input.error.clubName')
                      }
                      placeholder={t('businessEditProfile.detailsPlaceholder')}
                      style={{ padding: 0 }}
                      inputProps={{
                        style: { color: SECONDARY_TEXT_COLOR, fontWeight: 500 },
                        maxLength: DEFAULT_MAX_LENGTH
                      }}
                      value={company.name}
                      onChange={(event) => {
                        setChangesMade(true)
                        onChangeHandler('name', event.target.value)
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid paddingTop={2}>
                  <Grid xs={12} item>
                    <InputGenerator
                      handleOnChangeGoogle={(event) => {
                        onChangeHandler('locationName', event.label)
                        setChangesMade(true)
                      }}
                      input={{
                        name: 'city',
                        placeholder: company.locationName
                          ? company.locationName
                          : t('businessEditProfile.locationPlaceholder'),
                        type: 'google',
                        label: (
                          <Typography
                            fontWeight={500}
                            variant="p"
                            display="flex"
                            flexDirection="row"
                          >
                            {t('businessEditProfile.address')}
                            <Typography color="primary">&nbsp;*</Typography>
                          </Typography>
                        )
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  paddingTop={2}
                  pr={{ xs: 0, lg: 2 }}
                  display="flex"
                  alignItems="center"
                  flexDirection="row"
                >
                  <Typography fontWeight="bold" variant="p" pr={1}>
                    {t('businessEditProfile.program')}
                  </Typography>
                  <Divider sx={{ flex: 1 }} />
                </Grid>

                <Grid paddingTop={2}>
                  <Grid xs={12} item>
                    <Typography fontWeight={500} variant="p" display="flex" flexDirection="row">
                      {t('businessEditProfile.mondayFriday')}{' '}
                      <Typography color="primary">&nbsp;*</Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} display="flex" flexDirection="row" paddingTop={1}>
                    <Grid item xs={6} lg={2.5} md={2.5} pr={1}>
                      <TextField
                        fullWidth
                        placeholder={'HH:MM'}
                        value={company.mondayFridayStartProgram}
                        style={{ padding: 0 }}
                        inputProps={{
                          style: { color: SECONDARY_TEXT_COLOR, fontWeight: 500 },
                          maxLength: DEFAULT_MAX_LENGTH,
                          minLength: 5
                        }}
                        onChange={(event) => {
                          onChangeHandler('mondayFridayStartProgram', event.target.value)
                          setChangesMade(true)
                        }
                        }
                        InputProps={{
                          inputComponent: HourMinutesMask
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} lg={2.5} md={2.5} pl={1}>
                      <TextField
                        fullWidth
                        value={company.mondayFridayEndProgram}
                        placeholder={'HH:MM'}
                        style={{ padding: 0 }}
                        inputProps={{
                          style: { color: SECONDARY_TEXT_COLOR, fontWeight: 500 },
                          maxLength: DEFAULT_MAX_LENGTH
                        }}
                        onChange={(event) => {
                          setChangesMade(true)
                          onChangeHandler('mondayFridayEndProgram', event.target.value)
                        }
                        }
                        InputProps={{
                          inputComponent: HourMinutesMask
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid paddingTop={2}>
                  <Grid xs={12} item>
                    <Typography fontWeight={500} variant="p" display="flex" flexDirection="row">
                      {t('businessEditProfile.saturday')}{' '}
                      <Typography color="primary">&nbsp;*</Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} display="flex" flexDirection="row" paddingTop={1}>
                    <Grid item xs={6} lg={2.5} md={2.5} pr={1}>
                      <TextField
                        fullWidth
                        value={company.saturdayStartProgram}
                        placeholder={'HH:MM'}
                        style={{ padding: 0 }}
                        inputProps={{
                          style: { color: SECONDARY_TEXT_COLOR, fontWeight: 500 },
                          maxLength: DEFAULT_MAX_LENGTH
                        }}
                        onChange={(event) => {
                          setChangesMade(true)
                          onChangeHandler('saturdayStartProgram', event.target.value)
                        }
                        }
                        InputProps={{
                          inputComponent: HourMinutesMask
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} lg={2.5} md={2.5} pl={1}>
                      <TextField
                        fullWidth
                        value={company.saturdayEndProgram}
                        placeholder={'HH:MM'}
                        style={{ padding: 0 }}
                        inputProps={{
                          style: { color: SECONDARY_TEXT_COLOR, fontWeight: 500 },
                          maxLength: DEFAULT_MAX_LENGTH
                        }}
                        onChange={(event) => {
                          setChangesMade(true)
                          onChangeHandler('saturdayEndProgram', event.target.value)
                        }
                        }
                        InputProps={{
                          inputComponent: HourMinutesMask
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid paddingTop={2}>
                  <Grid xs={12} item>
                    <Typography fontWeight={500} variant="p" display="flex" flexDirection="row">
                      {t('businessEditProfile.sunday')}{' '}
                      <Typography color="primary">&nbsp;*</Typography>
                    </Typography>
                  </Grid>
                  <Grid xs={12} item display="flex" flexDirection="row" paddingTop={1}>
                    <Grid item xs={6} lg={2.5} md={2.5} pr={1}>
                      <TextField
                        fullWidth
                        value={company.sundayStartProgram}
                        placeholder={'HH:MM'}
                        style={{ padding: 0 }}
                        inputProps={{
                          style: { color: SECONDARY_TEXT_COLOR, fontWeight: 500 },
                          maxLength: DEFAULT_MAX_LENGTH
                        }}
                        onChange={(event) => {
                          onChangeHandler('sundayStartProgram', event.target.value)
                          setChangesMade(true)
                        }
                        }
                        InputProps={{
                          inputComponent: HourMinutesMask
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} lg={2.5} md={2.5} pl={1}>
                      <TextField
                        fullWidth
                        value={company.sundayEndProgram}
                        placeholder={'HH:MM'}
                        style={{ padding: 0 }}
                        inputProps={{
                          style: { color: SECONDARY_TEXT_COLOR, fontWeight: 500 },
                          maxLength: DEFAULT_MAX_LENGTH
                        }}
                        onChange={(event) => {
                          onChangeHandler('sundayEndProgram', event.target.value)
                          setChangesMade(true)
                        }
                        }
                        InputProps={{
                          inputComponent: HourMinutesMask
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} pt={1} item>
                    <Typography
                      color={CARD_TEXT_COLOR}
                      variant="p"
                      display="flex"
                      flexDirection="row"
                    >
                      {t('businessEditProfile.leaveEmpty')}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  paddingTop={2}
                  pr={{ xs: 0, lg: 2 }}
                  display="flex"
                  alignItems="center"
                  flexDirection="row"
                >
                  <Typography fontWeight="bold" variant="p" pr={1}>
                    {t('businessEditProfile.documents')}
                  </Typography>
                  <Divider sx={{ flex: 1 }} />
                </Grid>

                <Grid container pt={2} display="flex" flexDirection="row">
                  <Grid item xs={12} md={7} lg={7} pr={1} display="flex" flexDirection="column">
                    <Grid item xs={12} display="flex" flexDirection="row" alignItems="center">
                      {renderActionRegistrationCertificate}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      pt={1}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      {renderIdentity}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    pt={{ xs: 2, lg: 0, md: 0 }}
                    flexWrap="noWrap"
                    xs={12}
                    md={5}
                    lg={5}
                    pl={1}
                  >
                    <Typography
                      color={CARD_TEXT_COLOR}
                      variant="p"
                      display="flex"
                      flexDirection="row"
                    >
                      {t('businessEditProfile.approvalDescription')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }, [
    company,
    selectedServices,
    services,
    profileFileLoading,
    photoLoading,
    photos,
    identityCardLoading,
    registrationCertificateLoading
  ])

  const renderTeamInformation = useMemo(() => {
    if (!company) return null

    const selectedTrainerIds = selectedTrainers.map((trainer) => trainer.id)

    return (
      <Grid item xs={12} pt={4} pb={4}>
        <Grid container display="flex" flexDirection="column">
          <Grid item xs={12}>
            <Autocomplete
              options={trainers
                .filter((el) => !selectedTrainerIds.includes(el.id))
                .map((el) => ({
                  label: `${el.firstName} ${el.lastName}`,
                  avatar: el.avatarPhotoSrc,
                  phoneNumber: el.phoneNumber,
                  value: el.id,
                  firstName: el.firstName,
                  lastName: el.lastName
                }))}
              autoHighlight
              isOptionEqualToValue={(option, value) => {
                return option.value === value.value
              }}
              placeholder="Search for trainer"
              renderOption={(props, option) => {
                return (
                  <Box {...props} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {option.avatar ? (
                        <img
                          style={{ borderRadius: 25 }}
                          width={50}
                          height={50}
                          src={option.avatar}
                        />
                      ) : (
                        <Box
                          sx={{
                            width: 50,
                            height: 50,
                            backgroundColor: GRAY_BG_COLOR,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 25
                          }}
                        >
                          <Typography fontWeight="bold" color={GRAY_TEXT_COLOR}>
                            {option.firstName.charAt(0)} {option.lastName.charAt(0)}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 1 }}>
                      <Typography fontWeight={500}>{option.label}</Typography>
                      <Typography fontWeight={500}>{option.phoneNumber}</Typography>
                    </Box>
                  </Box>
                )
              }}
              filterOptions={(options, state) => options}
              onInputChange={(event) => setTrainerFilter(event.target.value)}
              onChange={(_, newValue) => {
                if (!newValue) return

                return onAddMemberHandler(newValue.value)
              }}
              renderInput={(params) => (
                <TextField
                  placeholder={t('businessEditProfile.searchTrainer')}
                  {...params}
                  InputProps={{ ...params.InputProps, endAdornment: null }}
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      marginLeft: 6,
                      padding: 11.5,
                      color: SECONDARY_TEXT_COLOR,
                      fontWeight: 500
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item pt={4} xs={12}>
            <Grid container flexWrap="wrap" display="flex">
              {selectedTrainers.map((el, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    md={6}
                    pb={1}
                    key={index}
                    display="flex"
                    flexDirection="row"
                  >
                    <Grid xs={12} md={12} lg={5} item>
                      <Link
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                        to={'/' + el.domain}
                      >
                        <FavouritTrainer
                          firstName={el.firstName}
                          lastName={el.lastName}
                          avatarPhotoSrc={el.avatarPhotoSrc}
                        />
                      </Link>
                    </Grid>
                    <Grid
                      xs={8}
                      md={8}
                      lg={4}
                      item
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {el.status === COMPANIES_TRAINERS_REQUEST_STATUS.PENDING && (
                        <Chip
                          sx={{ fontWeight: 500, backgroundColor: YELLOW_CHIP_COLOR }}
                          color="warning"
                          variant="outlined"
                          label={t(`status.${COMPANIES_TRAINERS_REQUEST_STATUS.PENDING}`)}
                        />
                      )}
                      {el.status === COMPANIES_TRAINERS_REQUEST_STATUS.APPROVED && (
                        <Chip
                          sx={{ fontWeight: 500, backgroundColor: GREEN_CHIP_COLOR }}
                          color="success"
                          variant="outlined"
                          label={t(`status.${COMPANIES_TRAINERS_REQUEST_STATUS.APPROVED}`)}
                        />
                      )}
                      {el.status === COMPANIES_TRAINERS_REQUEST_STATUS.DENIED && (
                        <Chip
                          sx={{ fontWeight: 500, backgroundColor: RED_CHIP_COLOR }}
                          color="error"
                          variant="outlined"
                          label={t(`status.${COMPANIES_TRAINERS_REQUEST_STATUS.DENIED}`)}
                        />
                      )}
                    </Grid>
                    <Grid
                      md={4}
                      xs={4}
                      lg={3}
                      item
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-end"
                      pr={3}
                    >
                      {el.fromCompany && (
                        <IconButton onClick={() => onDeleteMemberHandler(el.id)} variant="text">
                          <Delete sx={{ color: 'black' }} />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }, [company, trainers, selectedTrainers])

  if (!company) return null

  return (
    <>
      {openClose ? <ConfirmNoSavingModal onClose={() => setOpenClose(false)} onConfirm={() => {
        setOpenClose(false)
        setChangesMade(false)
        onClose()
      }} /> : null}
      <Dialog
        fullScreen={true}
        open={true}
        TransitionComponent={Transition}
        classes={{
          paper: classes.noBorder
        }}
      >
        <Container maxWidth="xl">
          <Grid container p={2} pt={4} pb={4}>
            <Grid container display="flex" flexDirection="row" pb={3}>
              <Grid item xs={10} display="flex" alignItems="center">
                <Typography variant="h4" fontWeight="bold">
                  {t('trainerProfile.editModal.title')}
                </Typography>
              </Grid>
              <Grid item xs={2} display="flex" justifyContent="flex-end" alignItems="center">
                <IconButton onClick={() => {
                  if (changesMade) {
                    setOpenClose(true)
                  }
                  else {
                    onClose()
                  }
                }}>
                  <Close sx={{ color: 'black' }} />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container display="flex" pt={2} flexDirection="row" justifyContent="center">
              <Grid item xs={12} lg={12} md={12}>
                <Tabs
                  variant="scrollable"
                  value={activeTab}
                  onChange={(_, newValue) => {
                    setActiveTab(newValue)
                  }}
                  centered
                  options={[
                    {
                      label: t('businessEditProfile.general'),
                      value: renderGeneralInformation
                    },
                    {
                      label: t('businessEditProfile.team'),
                      value: renderTeamInformation
                    },
                    {
                      label: t('businessEditProfile.equipments'),
                      value: renderEquipments
                    },
                    {
                      label: t('businessEditProfile.types'),
                      value: renderTypes
                    }
                  ]}
                />
              </Grid>
            </Grid>

            {activeTab === 0 ? (
              <Grid item pt={3} pr={{ xs: 2, lg: 0 }} pl={{ xs: 2, lg: 0 }} pb={5} xs={12}>
                <Grid container display="flex" justifyContent="center" alignItems="center">
                  <Grid item md={4} xs={6} lg={4} pr={{ xs: 1, lg: 1.5, md: 1.5 }}>
                    <Button style={{ height: 45 }} fullWidth variant="outlined" onClick={() => {
                      if (changesMade) {
                        setOpenClose(true)
                      }
                      else {
                        onClose()
                      }
                    }}>
                      {t('businessEditProfile.cancel')}
                    </Button>
                  </Grid>

                  <Grid item lg={4} md={4} xs={6} pl={{ xs: 1, lg: 1.5, md: 1.5 }}>
                    <Button
                      fullWidth
                      style={{ height: 45 }}
                      disabled={!validators.isValidText(company.name) || isLoading}
                      variant="contained"
                      onClick={() => {
                        onSaveHandler()
                      }}
                    >
                      {t('businessEditProfile.save')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Container>
      </Dialog>
    </>
  )
}

export default EditProfile
