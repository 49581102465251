import React, { useState } from 'react'
import { Grid, Typography, Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Linkify from 'react-linkify'
import parse from 'html-react-parser'

import GalleryImageDialog from '../../../common/GalleryImageDialog'

import FavouritTrainer from './subcomponents/FavouritTrainer'

import {
  LIGHT_GRAY_BORDER_COLOR,
  CHIP_BLUE_COLOR,
  COMPANIES_TRAINERS_REQUEST_STATUS
} from '../../../../utils/constants'

const Details = ({ company }) => {
  const { t } = useTranslation()

  const [galleryDialog, setGalleryDialog] = useState({
    open: false,
    image: null
  })

  return (
    <>
      {galleryDialog.open && galleryDialog.image ? (
        <GalleryImageDialog
          image={galleryDialog.image}
          onClose={() =>
            setGalleryDialog({
              open: false,
              image: null
            })
          }
        />
      ) : null}
      <Grid
        container
        width="100%"
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }}
        pt={2}
        pb={4}
      >
        <Grid
          display="flex"
          pb={{ xs: 2, lg: 0 }}
          flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }}
          xs={12}
          item
        >
          <Grid item pl={3} pr={3} lg={8} xs={12}>
            <Grid
              pb={3}
              borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
              display="flex"
              justifyContent="center"
              flexDirection="column"
            >
              <Grid pb={1.5}>
                <Typography fontWeight={500} color="black" fontSize={20}>
                  {t('userProfile.activity.detailComponent.bio')}
                </Typography>
              </Grid>
              <Grid className="bio">
                {company.bio ? (
                  <Linkify>
                    <Typography color={`${company.bio ? 'black' : '#979797'}`}>
                      {parse(company.bio) || t('helper.noInformation')}
                    </Typography>
                  </Linkify>
                ) : (
                  <Typography color="#979797" fontWeight={500} fontSize={17}>
                    {t('helper.noInformation')}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid item width="100%" pt={2} pb={2} display="flex" flexDirection="column">
              <Grid pb={1.5}>
                <Typography fontWeight={500} color="black" fontSize={20}>
                  {t('userProfile.activity.services')}
                </Typography>
              </Grid>
              <Grid container display="flex" flexWrap="wrap" spacing={1}>
                {company.Services.filter((el) => el.display).length > 0 ? (
                  company.Services.filter((service) => service.display).map(
                    (qualification, index) => {
                      return (
                        <Grid item key={index}>
                          <Chip
                            sx={{ fontWeight: 500, backgroundColor: CHIP_BLUE_COLOR }}
                            color="primary"
                            variant="outlined"
                            label={qualification.title}
                          />
                        </Grid>
                      )
                    }
                  )
                ) : (
                  <Typography pl={1} color="#979797" pt={1} fontWeight={500} fontSize={17}>
                    {t('helper.noInformation')}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid pl={3} pr={3} xs={12} lg={4} item display="flex" flexDirection="column">
            <Grid item pb={1.5}>
              <Typography fontWeight={500} color="black" fontSize={20}>
                {t('userProfile.activity.trainerDetailComponent.galery')}
              </Typography>
            </Grid>
            <Grid
              container
              flexWrap="wrap"
              display="flex"
              flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }}
              pb={1}
            >
              <Grid pt={1} container display="flex" flexWrap="wrap" spacing={1} pr={1}>
                {company.Documents.length > 0 ? (
                  company.Documents.filter((el) => el.isPhoto).map((photo, index) => {
                    return (
                      <Grid
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setGalleryDialog({ open: true, image: photo.src })}
                        key={index}
                        item
                        xs={4}
                        lg={4}
                      >
                        <img
                          width="100%"
                          height={100}
                          lazy
                          style={{ objectFit: 'cover' }}
                          src={photo.src}
                        />
                      </Grid>
                    )
                  })
                ) : (
                  <Typography pl={1} color="#979797" fontWeight={500} fontSize={17}>
                    {t('helper.noInformation')}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid pr={3} pl={3} width="100%">
          <Grid pt={2} pb={2} container borderTop={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
            <Grid item pb={1.5}>
              <Typography fontWeight={500} color="black" fontSize={20}>
                {t('userProfile.activity.team')}
              </Typography>
            </Grid>
            <Grid container flexWrap="wrap" display="flex">
              {company.Users.length > 0 && company.Users.filter(
                (el) =>
                  el.Companies &&
                  el.Companies[el.Companies.findIndex(comp => comp.id === company.id)] &&
                  el.Companies[el.Companies.findIndex(comp => comp.id === company.id)].Companies_Trainers.status ===
                  COMPANIES_TRAINERS_REQUEST_STATUS.APPROVED
              ).length > 0 ? (
                company.Users.filter(
                  (el) =>
                    el.Companies &&
                    el.Companies[el.Companies.findIndex(comp => comp.id === company.id)] &&
                    el.Companies[el.Companies.findIndex(comp => comp.id === company.id)].Companies_Trainers.status ===
                    COMPANIES_TRAINERS_REQUEST_STATUS.APPROVED
                ).map((el, index) => {
                  return (
                    <Grid item xs={12} lg={4} pb={1} key={index}>
                      <Link
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                        to={'/' + el.domain}
                      >
                        <FavouritTrainer
                          avatarPhotoSrc={el.avatarPhotoSrc}
                          firstName={el.firstName}
                          lastName={el.lastName}
                        />
                      </Link>
                    </Grid>
                  )
                })
              ) : (
                <Typography color="#979797" fontWeight={500} fontSize={17}>
                  {t('helper.noInformation')}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Details
