const cards = [
    {
        title: 'Challenges',
        price: 'Free',
        subtitle: 'Now available in BETA',
        content: 'Our Leaderboard system is designed for effortless operation, offering complete automation for tracking client activities and gathering essential participant data.'
    },
    {
        title: 'Courses',
        price: 'Free',
        subtitle: 'Now available in BETA',
        content: 'Create online courses, offering expertise and extra monetization opportunities to engage and inspire the sports community.'
    },
    {
        title: 'Access System',
        price: 199,
        subtitle: 'Extra costs can be added',
        content: 'Our Leaderboard system is designed for effortless operation, offering complete automation for tracking client activities and gathering essential participant data.'
    },
    {
        title: 'Deals',
        price: 'Free',
        subtitle: 'Now available in BETA',
        content: 'Unique opportunity for brands or clubs to engage with our dynamic community of sports enthusiasts through targeted promotions and exclusive deals.'
    }
]

const marks = [
    { value: 1, label: '0K' },
    { value: 1.5, label: '1K' },
    { value: 2, label: '2K' },
    { value: 3, label: '3K' },
    { value: 4, label: '4K' },
    { value: 5, label: '5K' },
    { value: 6, label: '6K' },
    { value: 7, label: '7K' },
    { value: 8, label: '8K' },
    { value: 9, label: '9K' },
    { value: 10, label: '10K' },
    { value: 10.515 },
    { value: 11.020, label: '20K' },
    { value: 11.525 },
    { value: 12.030, label: '30K' },
    { value: 12.535 },
    { value: 13.040, label: '40K' },
    { value: 13.545 },
    { value: 14.050, label: '50K' },
    { value: 14.555 },
    { value: 15.060, label: '60K' },
];
export { marks, cards }