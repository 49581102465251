import { Container } from "@mui/material";
import Stats from "./Stats";
import PayoutsTable from "./PayoutsTable";

const Payouts = () => {
    return (
        <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'center', paddingY: 7, flexDirection: 'column', gap: 3 }}>
            {/* <Stats /> */}
            <PayoutsTable />
        </Container>
    )
}

export default Payouts;