import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import {
  Dialog,
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
  Autocomplete,
  Chip,
  IconButton,
  Box,
  Tooltip,
  Avatar,
  Container
} from '@mui/material'
import {
  Close,
  Add,
  Check,
  AccessTime,
  Edit,
  CheckCircleOutline,
  PendingActionsOutlined,
  NotInterested,
  PlayArrow,
  Delete,
  RemoveCircle
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import ReactQuill from 'react-quill'
import { toast } from 'react-toastify';
import 'react-quill/dist/quill.snow.css'
import * as USER_REQUESTS from '../../../../api/auth'
import * as TRAINER_REQUESTS from '../../../../api/trainer'
import * as DOCUMENTS_REQUESTS from '../../../../api/documents'
import * as AUTH_REQUESTS from '../../../../api/auth'
import * as LICENSE_TYPE_REQUESTS from '../../../../api/licenseType'

import SportTypes from './subcomponents/SportTypes'

import {
  CARD_TEXT_COLOR,
  CHIP_BLUE_COLOR,
  GRAY_BG_COLOR,
  SECONDARY_TEXT_COLOR,
  PRIMARY_COLOR,
  LIGHT_BLUE_COLOR,
  GRAY_BORDER_COLOR,
  GRAY_TEXT_COLOR,
  DEFAULT_MAX_LENGTH,
  LONG_DEFAULT_MAX_LENGTH
} from '../../../../utils/constants'
import { useAuthentication } from '../../../../context/auth-context'
import { SendingButton as SendingButtonChild, Tabs, ConfirmNoSavingModal, InputGenerator } from '../../../common'
import { BUCKET_BASE_URL } from '../../../../utils/config'
import { refetchTrainerProfileObservable } from '../../../../utils/observables'

const useStyles = makeStyles(() => ({
  noBorder: {
    borderRadius: 0
  }
}))

const EditProfile = ({ onClose, Transition }) => {
  const { t } = useTranslation()
  const { handleToken, handleUser } = useAuthentication()
  const classes = useStyles()

  const profileFileInputRef = useRef()
  const licenceFileInputRef = useRef()
  const photoFileInputRef = useRef()
  const identityCardInputRef = useRef()

  const [activeTab, setActiveTab] = useState(0)
  const [identityCardLoading, setIdentityCardLoading] = useState(false)
  const [licences, setLicences] = useState([])
  const [selectedAward, setSelectedAward] = useState()
  const [firstName, setFirstName] = useState()
  const [paymentLink, setPaymentLink] = useState()
  const [lastName, setLastName] = useState()
  const [bio, setBio] = useState('')
  const [user, setUser] = useState()
  const [services, setServices] = useState([])
  const [selectedServices, setSelectedServices] = useState([])
  const [photos, setPhotos] = useState([])
  const [profileFileLoading, setProfileFileLoading] = useState(false)
  const [licenceFileLoading, setLicenceFileLoading] = useState(false)
  const [newLicence, setNewLicence] = useState({ documentSrc: null, description: null })
  const [showUploadCertificate, setShowUploadCertificate] = useState(false)
  const [photoLoading, setPhotoLoading] = useState(false)
  const [openClose, setOpenClose] = useState(false)
  const [changesMade, setChangesMade] = useState(false)
  const [licenseTypes, setLicenseTypes] = useState([])

  const handleGetLicenseTypes = useCallback(async () => {
    try {
      const resp = await LICENSE_TYPE_REQUESTS.findAll()
      setLicenseTypes(resp)
    } catch (e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    handleGetLicenseTypes()
  }, [handleGetLicenseTypes])


  const onLicenceFileChangeHandler = (event) => {
    const file = event.target.files[0]

    const name = Date.now()
    const extension = file.type.split('/')[1]

    setLicenceFileLoading(true)

    DOCUMENTS_REQUESTS.generatePresignedUrl({ name, extension })
      .then((presignedUrl) => {
        return DOCUMENTS_REQUESTS.uploadDocumentS3(presignedUrl, file)
      })
      .then(() => {
        setChangesMade(true)
        setNewLicence({
          ...newLicence,
          documentSrc: `${BUCKET_BASE_URL}/${name}.${extension}`
        })
        setLicenceFileLoading(false)
      })
      .catch(() => {
        setLicenceFileLoading(false)
      })
  }

  const onLicenceDeleteHandler = () => {
    const { id } = newLicence

    return TRAINER_REQUESTS.deleteLicence(id)
      .then(() => {
        return USER_REQUESTS.getMe()
      })
      .then((user) => {
        setLicences(
          user.Licences.map((licence) => ({
            id: licence.id,
            approved: licence.approved,
            label: licence.description
          }))
        )
        setNewLicence({ description: null, documentSrc: null, id: null })
        setSelectedAward()
        setShowUploadCertificate(false)
      })
  }

  const onLicenceEditHandler = () => {
    const { id, ...restOfLicence } = newLicence

    const data = {}

    Object.keys(restOfLicence).forEach((key) => {
      if (restOfLicence[key]) {
        data[key] = restOfLicence[key]
      }
    })

    TRAINER_REQUESTS.updateLicence(id, { ...data })
      .then(() => {
        return USER_REQUESTS.getMe()
      })
      .then((user) => {
        setLicences(
          user.Licences.map((licence) => ({
            id: licence.id,
            approved: licence.approved,
            label: licence.description
          }))
        )
        setNewLicence({ description: null, documentSrc: null, id: null })
        setSelectedAward()
        setShowUploadCertificate(false)
      })
  }

  const onLicenceSaveHandler = () => {
    TRAINER_REQUESTS.createLicence({ ...newLicence }).then((response) => {
      setLicences(
        licences.concat({
          id: response.id,
          approved: response.approved,
          label: response.description
        })
      )
      setNewLicence({ description: null, documentSrc: null })
      setShowUploadCertificate(false)
    })
  }

  const onProfileFileChangeHandler = (event) => {
    const file = event.target.files[0]

    const name = Date.now()
    const extension = file.type.split('/')[1]

    setProfileFileLoading(true)

    DOCUMENTS_REQUESTS.generatePresignedUrl({ name, extension })
      .then((presignedUrl) => {
        return DOCUMENTS_REQUESTS.uploadDocumentS3(presignedUrl, file)
      })
      .then(() => {
        return AUTH_REQUESTS.updateUser({
          avatarPhotoSrc: `${BUCKET_BASE_URL}/${name}.${extension}`
        })
      })
      .then(() => {
        setProfileFileLoading(false)
        return AUTH_REQUESTS.getMe()
      })
      .then((user) => {
        setChangesMade(true)
        setUser(user)
      })
      .catch(() => {
        setProfileFileLoading(false)
      })
  }

  const onIdentityCardChangeHandler = (event) => {
    const file = event.target.files[0]
    const name = Date.now()
    const extension = file.type.split('/')[1]

    setIdentityCardLoading(true)
    DOCUMENTS_REQUESTS.generatePresignedUrl({ name, extension })
      .then((presignedUrl) => {
        return DOCUMENTS_REQUESTS.uploadDocumentS3(presignedUrl, file)
      })
      .then(() => {
        return USER_REQUESTS.updateUser({
          identityCardSrc: `${BUCKET_BASE_URL}/${name}.${extension}`
        })
      })
      .then(() => {
        setChangesMade(true)
        setUser((prevUser) => ({
          ...prevUser,
          identityCardSrc: `${BUCKET_BASE_URL}/${name}.${extension}`
        }))
        setIdentityCardLoading(false)
      })
      .catch(() => {
        setIdentityCardLoading(false)
      })
  }

  const onPhotoChangeHandler = (event) => {
    const file = event.target.files[0]

    const name = Date.now()
    const extension = file.type.split('/')[1]

    setPhotoLoading(true)

    DOCUMENTS_REQUESTS.generatePresignedUrl({ name, extension })
      .then((presignedUrl) => {
        return DOCUMENTS_REQUESTS.uploadDocumentS3(presignedUrl, file)
      })
      .then(() => {
        return DOCUMENTS_REQUESTS.createDocument({
          src: `${BUCKET_BASE_URL}/${name}.${extension}`,
          userId: user.id,
          isPhoto: true
        })
      })
      .then((res) => {
        setChangesMade(true)
        setPhotos(photos.concat({ ...res }))
        setPhotoLoading(false)
      })
      .catch(() => {
        setPhotoLoading(false)
      })
  }

  const handleRemoveProfileImage = async () => {
    try {
      setProfileFileLoading(true)
      await AUTH_REQUESTS.updateUser({ avatarPhotoSrc: null })

      USER_REQUESTS.getMe().then((user) => {
        setUser(user)
        setFirstName(user.firstName)
        setLastName(user.lastName)
        setBio(user.bio)
        setPaymentLink(user.paymentLink)
        setPhotos(user.Documents.filter((el) => el.isPhoto))
        setLicences(
          user.Licences.map((licence) => ({
            id: licence.id,
            approved: licence.approved,
            label: licence.description
          }))
        )
      })

      setProfileFileLoading(false)
    } catch (e) {
      setProfileFileLoading(false)
      console.error(e)
    }
  }

  useEffect(() => {
    TRAINER_REQUESTS.getTrainerServices().then((res) => {
      setServices(res.map((el) => ({ label: el.title, value: el.id })))
      setSelectedServices(
        res.filter((el) => el.display).map((el) => ({ value: el.id, label: el.title }))
      )
    })

    USER_REQUESTS.getMe().then((user) => {
      setUser(user)
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setBio(user.bio)
      setPaymentLink(user.paymentLink)
      setPhotos(user.Documents.filter((el) => el.isPhoto))
      setLicences(
        user.Licences.map((licence) => ({
          id: licence.id,
          approved: licence.approved,
          label: licence.description
        }))
      )
    })
  }, [])

  const handleDeletePhoto = async (photo) => {
    try {
      await DOCUMENTS_REQUESTS.deleteDocument(photo.id)
      USER_REQUESTS.getMe().then((user) => {
        setUser(user)
        setFirstName(user.firstName)
        setLastName(user.lastName)
        setBio(user.bio)
        setPaymentLink(user.paymentLink)
        setPhotos(user.Documents.filter((el) => el.isPhoto))
        setLicences(
          user.Licences.map((licence) => ({
            id: licence.id,
            approved: licence.approved,
            label: licence.description
          }))
        )
      })
    } catch (e) {
      console.error(e)
    }
  }

  const renderProfile = () => {
    return (
      <>
        <Grid pb={4} pt={4} container>
          <Grid item xs={12} lg={7} pr={{ xs: 0, lg: 3 }} display="flex" flexDirection="column">
            <Grid container pr={{ xs: 0, lg: 2 }} display="flex" flexDirection="row">
              <Grid xs={12} item display="flex" alignItems="center">
                <Grid item>
                  {user.avatarPhotoSrc ? (
                    <Avatar
                      src={user.avatarPhotoSrc}
                      alt="avatar"
                      style={{ width: 100, height: 100 }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: 100,
                        height: 100,
                        backgroundColor: GRAY_BG_COLOR,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 50
                      }}
                    >
                      <Typography color={GRAY_TEXT_COLOR} variant="h4" fontWeight="bold">
                        {user.firstName.charAt(0)} {user.lastName.charAt(0)}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid container display="flex" flexDirection={{ lg: 'row', xs: 'column' }}>
                  <Grid xs={12} lg={7} item pl={2} pb={{ xs: 1 }}>
                    <Button
                      disabled={profileFileLoading}
                      onClick={() => profileFileInputRef.current.click()}
                      fullWidth
                      variant="outlined"
                    >
                      {profileFileLoading ? <SendingButtonChild /> : t('userProfile.uploadImage')}
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={12} pl={1}>
                    {user.avatarPhotoSrc ? (
                      <Button
                        onClick={handleRemoveProfileImage}
                        sx={{ minWidth: 70, marginLeft: 1 }}
                        variant="outlined"
                        color="error"
                      >
                        <Delete />
                      </Button>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid paddingTop={3} pr={{ xs: 0, lg: 2 }}>
              <Grid>
                <Typography fontWeight={500} variant="p">
                  {t('trainerProfile.editModal.bio')}
                </Typography>
              </Grid>
              <Grid paddingTop={1}>
                <ReactQuill
                  defaultValue={bio}
                  modules={{
                    clipboard: {
                      matchVisual: false
                    }
                  }}
                  onChange={(newContent) => {
                    setBio(newContent)
                    setChangesMade(true)
                  }}
                >
                  <div style={{ minHeight: 250 }} />
                </ReactQuill>
              </Grid>
            </Grid>
            <Grid paddingTop={3} pr={{ xs: 0, lg: 2 }}>
              <Grid>
                <Typography fontWeight={500} variant="p">
                  {t('trainerProfile.editModal.qualifications')}
                </Typography>
              </Grid>
              <Grid paddingTop={1} pb={2}>
                <Autocomplete
                  blurOnSelect={true}
                  clearOnBlur={true}
                  options={services.filter(
                    (service) => !selectedServicesIds.includes(service.value)
                  )}
                  autoHighlight
                  onChange={(_, newValue) => {
                    if (!newValue) return

                    setChangesMade(true)
                    setSelectedServices(selectedServices.concat(newValue))
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t('trainerProfile.editModal.searchService')}
                      {...params}
                      InputProps={{ ...params.InputProps, endAdornment: null }}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          marginLeft: 6,
                          padding: 11.5,
                          color: SECONDARY_TEXT_COLOR,
                          fontWeight: 500
                        }
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid container flexWrap="wrap" spacing={1}>
                {selectedServices.map((el, index) => {
                  return (
                    <Grid item key={index}>
                      <Chip
                        sx={{ fontWeight: 500, backgroundColor: CHIP_BLUE_COLOR }}
                        color="primary"
                        variant="outlined"
                        label={el.label}
                        onDelete={() => {
                          setChangesMade(true)
                          setSelectedServices(
                            selectedServices.filter((element) => el.value !== element.value)
                          )
                        }}
                      />
                    </Grid>
                  )
                })}
              </Grid>

              <Grid item pt={2}>
                <Grid>
                  <Typography fontWeight={500} variant="p">
                    {t('trainerProfile.editModal.paymentLink')}
                  </Typography>
                </Grid>
                <Grid paddingTop={1}>
                  <TextField
                    fullWidth
                    placeholder={t('trainerProfile.editModal.paymentLink')}
                    style={{ padding: 0 }}
                    value={paymentLink}
                    inputProps={{
                      style: { color: SECONDARY_TEXT_COLOR, fontWeight: 500 },
                      maxLength: DEFAULT_MAX_LENGTH
                    }}
                    onChange={(e) => {
                      setPaymentLink(e.target.value)
                      setChangesMade(true)
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                paddingTop={2}
                pr={{ xs: 0, lg: 0 }}
                display="flex"
                alignItems="center"
                flexDirection="row"
              >
                <Typography fontWeight="bold" variant="p" pr={1}>
                  {t('businessEditProfile.documents')}
                </Typography>
                <Divider sx={{ flex: 1 }} />
              </Grid>

              <Grid container pt={2} display="flex" flexDirection="row">
                <Grid item xs={12} md={7} lg={7} display="flex" flexDirection="column">
                  <Grid item xs={12} pt={1} display="flex" flexDirection="row" alignItems="center">
                    {renderIdentity}
                  </Grid>
                </Grid>
                <Grid item pt={{ xs: 2, lg: 0, md: 0 }} flexWrap="noWrap" xs={12} md={5} lg={5}>
                  <Typography
                    color={CARD_TEXT_COLOR}
                    pb={{ xs: 2 }}
                    variant="p"
                    display="flex"
                    flexDirection="row"
                  >
                    {t('businessEditProfile.approvalDescription')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5} pr={{ xs: 0, lg: 3 }} display="flex" flexDirection="column">
            <Grid container display="flex" flexDirection="column">
              <Grid item>
                <Grid>
                  <Typography fontWeight={500} variant="p">
                    {t('trainerProfile.editModal.firstName')}
                  </Typography>
                </Grid>
                <Grid paddingTop={1}>
                  <Tooltip title={t('trainerProfile.editModal.firstNameValidated')} arrow>
                    <TextField
                      disabled={(user.identityCardSrc ? true : false) || user.identityCardApproved}
                      fullWidth
                      multiline
                      style={{ padding: 0 }}
                      error={!firstName.length}
                      helperText={!firstName && t('trainerProfile.editModal.helperText')}
                      inputProps={{
                        style: { color: SECONDARY_TEXT_COLOR, fontWeight: 500 },
                        maxLength: DEFAULT_MAX_LENGTH
                      }}
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value)
                        setChangesMade(true)
                      }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid item pt={3}>
                <Grid>
                  <Typography fontWeight={500} variant="p">
                    {t('trainerProfile.editModal.lastName')}
                  </Typography>
                </Grid>
                <Grid paddingTop={1}>
                  <Tooltip title={t('trainerProfile.editModal.lastNameValidated')} arrow>
                    <TextField
                      disabled={(user.identityCardSrc ? true : false) || user.identityCardApproved}
                      fullWidth
                      multiline
                      style={{ padding: 0 }}
                      error={!lastName.length}
                      helperText={!lastName && t('trainerProfile.editModal.helperText')}
                      inputProps={{
                        style: { color: SECONDARY_TEXT_COLOR, fontWeight: 500 },
                        maxLength: DEFAULT_MAX_LENGTH
                      }}
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value)
                        setChangesMade(true)
                      }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid item pt={3}>
                <Grid>
                  <Typography fontWeight={500} variant="p">
                    {t('trainerProfile.editModal.galery')}
                  </Typography>
                </Grid>
                <Grid
                  container
                  flexWrap="wrap"
                  display="flex"
                  flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }}
                >
                  <Grid pt={1} container display="flex" flexWrap="wrap" spacing={1} pr={1}>
                    {photos.map((el, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Grid key={index} item>
                            <img
                              style={{ objectFit: 'contain' }}
                              width={50}
                              height={50}
                              src={el.src}
                            />
                          </Grid>
                          <RemoveCircle
                            onClick={() => {
                              handleDeletePhoto(el)
                              setChangesMade(true)
                            }}
                            sx={{
                              color: '#e53935',
                              cursor: 'pointer',
                              position: 'relative',
                              left: -12,
                              top: 2
                            }}
                          />
                        </React.Fragment>
                      )
                    })}
                    <Grid
                      item
                      sx={{ width: 50, height: 50 }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          height: '100%',
                          border: `1px solid ${PRIMARY_COLOR}`,
                          backgroundColor: LIGHT_BLUE_COLOR
                        }}
                      >
                        <IconButton
                          disabled={photoLoading}
                          onClick={() => {
                            if (photos.length === 6) {
                              toast.error(t('helper.maxPhotos'), { position: 'bottom-right', autoClose: false })
                              return
                            }
                            photoFileInputRef.current.click()
                          }}
                          sx={{ width: '100%', height: '100%', borderRadius: 0 }}
                        >
                          <Add color="primary" />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item pt={3}>
                <Grid>
                  <Typography fontWeight={500} variant="p">
                    {t('trainerProfile.editModal.licences')}
                  </Typography>
                </Grid>
                <Grid pt={1} pb={3} container display="flex" flexDirection="column">
                  {licences.map((el, index) => {
                    return (
                      <Grid
                        pt={1}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        item
                        key={index}
                      >
                        <Chip
                          sx={{
                            fontWeight: 500,
                            backgroundColor: 'white',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                          }}
                          color={el.approved ? 'success' : 'warning'}
                          variant="outlined"
                          deleteIcon={<Edit />}
                          icon={el.approved ? <Check /> : <AccessTime />}
                          label={el.label}
                          onDelete={() => {
                            setChangesMade(true)
                            setSelectedAward(el)
                            setShowUploadCertificate(true)
                            setNewLicence({ ...newLicence, description: el.label, id: el.id })
                          }}
                        />
                      </Grid>
                    )
                  })}
                  {!showUploadCertificate && (
                    <Grid pt={2} display="flex" flexDirection="row" alignItems="center" item>
                      <Typography
                        fontWeight={600}
                        sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                        color="primary"
                        onClick={() => setShowUploadCertificate(true)}
                      >
                        {t('trainerProfile.editModal.addNewLicence')}
                      </Typography>
                    </Grid>
                  )}
                </Grid>

                {showUploadCertificate && (
                  <Grid
                    container
                    display="flex"
                    p={2}
                    flexDirection="column"
                    border={`2px solid ${GRAY_BORDER_COLOR}`}
                    borderRadius={3}
                  >
                    <Grid item>
                      <Grid>
                        <Typography fontWeight={500} variant="p">
                          {selectedAward
                            ? t('button.edit') + ' ' + selectedAward.label
                            : ''}
                        </Typography>
                      </Grid>
                      <Grid paddingTop={1}>
                        <InputGenerator
                          handleOnChange={(event) => {
                            setChangesMade(true)
                            setNewLicence({ ...newLicence, description: event.target.value })
                          }}
                          input={{
                            type: 'select',
                            label: t('trainerProfile.editModal.licenceName'),
                            mandatory: true,
                            value: newLicence.description,
                            values: licenseTypes.map(el => ({
                              label: el.description,
                              value: el.description
                            }))
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid item pt={2}>
                      <Grid>
                        <Typography fontWeight={500} variant="p">
                          {t('trainerProfile.editModal.certDiploma')}
                        </Typography>
                      </Grid>
                      <Grid paddingTop={1}>
                        <Button
                          disabled={licenceFileLoading}
                          onClick={() => {
                            licenceFileInputRef.current.click()
                            setChangesMade(true)
                          }}
                          variant="outlined"
                          fullWidth
                          sx={{ color: CARD_TEXT_COLOR, borderColor: CARD_TEXT_COLOR }}
                        >
                          {licenceFileLoading ? (
                            <SendingButtonChild />
                          ) : (
                            t('trainerProfile.editModal.upload')
                          )}
                        </Button>
                      </Grid>
                    </Grid>

                    {selectedAward && (
                      <Grid item pt={2}>
                        <Grid>
                          <Typography
                            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={onLicenceDeleteHandler}
                            color="error"
                            variant="subtitle2"
                          >
                            {t('trainerProfile.editModal.deleteLicence')}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}

                    <Grid container display="flex" flexDirection="row" pt={3}>
                      <Grid item lg={6} pr={1}>
                        <Button
                          fullWidth
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            setShowUploadCertificate(false)
                            setSelectedAward()
                            setNewLicence({ documentSrc: null, description: null })
                          }}
                        >
                          {t('trainerProfile.editModal.cancel')}
                        </Button>
                      </Grid>
                      <Grid item lg={6}>
                        <Button
                          fullWidth
                          disabled={
                            !selectedAward
                              ? !newLicence.description || !newLicence.documentSrc
                              : false
                          }
                          variant="outlined"
                          color="primary"
                          onClick={selectedAward ? onLicenceEditHandler : onLicenceSaveHandler}
                        >
                          {selectedAward ? t('button.edit') : t('trainerProfile.editModal.save')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          pr={{ xs: 2, lg: 0 }}
          pl={{ xs: 2, lg: 0 }}
          pb={5}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={6} lg={4} pr={{ xs: 2, lg: 2 }}>
            <Button fullWidth variant="outlined" style={{ height: 45 }} onClick={() => {
              if (changesMade) {
                setOpenClose(true)
              }
              else {
                onClose()
              }
            }}>
              {t('trainerProfile.editModal.cancel')}
            </Button>
          </Grid>

          <Grid item lg={4} xs={6}>
            <Button
              fullWidth
              style={{ height: 45 }}
              variant="contained"
              disabled={!lastName || !firstName}
              onClick={() => {
                USER_REQUESTS.updateUser({
                  bio,
                  firstName,
                  lastName,
                  paymentLink,
                  selectedServices: selectedServicesIds
                })
                  .then(() => {
                    return USER_REQUESTS.regenerateToken()
                  })
                  .then((response) => {
                    handleToken(response.token)
                    handleUser(response.user)
                    localStorage.setItem('token', response.token)
                    refetchTrainerProfileObservable.setRefetchTrainerProfile(true)
                    onClose()
                  })
              }}
            >
              {t('trainerProfile.editModal.save')}
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }

  const renderIdentity = useMemo(() => {
    if (!user) return null

    const onDocumentCancel = () => {
      setIdentityCardLoading(true)
      return AUTH_REQUESTS.updateUser({ identityCardSrc: null, isDenied: false }).then(() => {
        setChangesMade(true)
        setUser((prevUser) => ({
          ...prevUser,
          identityCardSrc: null,
          isDenied: false
        }))
        setIdentityCardLoading(false)
      })
    }

    let title = t('helper.identityCardUploaded')
    let icon = <CheckCircleOutline color="primary" fontSize="small" />
    let cancelIcon = null

    if (!user.identityCardApproved && user.identityCardSrc) {
      icon = <PendingActionsOutlined color="warning" fontSize="small" />
      title = t('helper.identityCardApproved')
      cancelIcon = (
        <IconButton onClick={onDocumentCancel}>
          <Close fontSize="small" color="error" />
        </IconButton>
      )
    }

    if (!user.identityCardApproved && !user.identityCardSrc) {
      icon = <PlayArrow color="warning" fontSize="small" />
      title = t('helper.uploadIdentity')
    }

    if (user.isDenied && user.identityCardSrc) {
      icon = <NotInterested color="error" fontSize="small" />
      title = t('helper.identityNotApproved')
    }

    return (
      <Tooltip title={title} arrow>
        <Box display="flex" flexDirection="row" alignItems="center">
          {icon}{' '}
          <Typography
            onClick={
              !user.identityCardSrc || user.isDenied
                ? () => {
                  if (identityCardLoading) return

                  identityCardInputRef.current.click()
                }
                : null
            }
            sx={{
              ':hover': {
                cursor:
                  user.identityCardSrc || user.identityCardApproved
                    ? null
                    : identityCardLoading
                      ? 'wait'
                      : 'pointer',
                textDecoration:
                  identityCardLoading || user.identityCardSrc || user.identityCardApproved
                    ? 'none'
                    : 'underline'
              }
            }}
            pl={0.5}
            variant="p"
            color="black"
          >
            {t('businessEditProfile.companyIdentityCard')}
          </Typography>
          {cancelIcon}
        </Box>
      </Tooltip>
    )
  }, [user, identityCardLoading])

  const selectedServicesIds = selectedServices.map((el) => el.value)

  if (!user) return null

  return (
    <>
      {openClose ? <ConfirmNoSavingModal onClose={() => setOpenClose(false)} onConfirm={() => {
        setOpenClose(false)
        setChangesMade(false)
        onClose()
      }} /> : null}
      <input
        type="file"
        ref={identityCardInputRef}
        onChangeCapture={onIdentityCardChangeHandler}
        style={{ display: 'none' }}
      />
      <input
        type="file"
        ref={photoFileInputRef}
        onChangeCapture={onPhotoChangeHandler}
        style={{ display: 'none' }}
      />
      <input
        type="file"
        ref={licenceFileInputRef}
        onChangeCapture={onLicenceFileChangeHandler}
        style={{ display: 'none' }}
      />
      <input
        type="file"
        ref={profileFileInputRef}
        onChangeCapture={onProfileFileChangeHandler}
        style={{ display: 'none' }}
      />
      <Dialog
        fullScreen={true}
        open={true}
        TransitionComponent={Transition}
        classes={{
          paper: classes.noBorder
        }}
      >
        <Container maxWidth="xl">
          <Grid p={2} pt={4} container>
            <Grid item xs={10} display="flex" alignItems="center">
              <Typography variant="h4" fontWeight="bold">
                {t('trainerProfile.editModal.title')}
              </Typography>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end" alignItems="center">
              <IconButton onClick={() => {
                if (changesMade) {
                  setOpenClose(true)
                }
                else {
                  onClose()
                }
              }}>
                <Close sx={{ color: 'black' }} />
              </IconButton>
            </Grid>
          </Grid>

          <Grid container display="flex" pt={4} flexDirection="row" justifyContent="center">
            <Grid item xs={12} lg={12} md={12}>
              <Tabs
                variant="fullWidth"
                value={activeTab}
                onChange={(_, newValue) => {
                  setActiveTab(newValue)
                }}
                centered
                options={[
                  {
                    label: t('profile'),
                    value: renderProfile()
                  },
                  {
                    label: t('sportTypes'),
                    value: <SportTypes
                      userId={user.id}
                      userDefaultSportType={user.defaultSportType}
                      updateDefaultSportType={defaultSportType => USER_REQUESTS.updateUser({
                        defaultSportType
                      }).then(AUTH_REQUESTS.getMe)
                        .then(response => setUser(prevUser => ({
                          ...prevUser,
                          defaultSportType: response.defaultSportType
                        })))
                      }
                    />
                  }
                ]}
              />
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </>
  )
}

export default EditProfile
