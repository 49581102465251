import { TableCell, Chip } from '@mui/material';

const openStatusStyle = { width: '80%', backgroundColor: '#DBFFE7', border: '0.1px solid #00A83A', fontSize: '1rem', fontWeight: 'bold', color: '#00A83A' }
const paidStatusStyle = { width: '80%', backgroundColor: '#E5EFFF', border: '0.1px solid #0664F2', fontSize: '1rem', fontWeight: 'bold', color: '#0664F2' }

const StatusOfPaymentCell = ({ status, index }) => (
    <TableCell sx={{ display: 'flex', border: 'none !important', width: '8.2%' }} key={`statusOfPayment-${index}`}>
        {status.toLowerCase() === "open" ? (<Chip label="OPEN" sx={openStatusStyle} />) : (<Chip label="PAID" sx={paidStatusStyle} />)}
    </TableCell>
);

export default StatusOfPaymentCell;