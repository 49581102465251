import React from 'react';
import { Box, Grid, Typography } from "@mui/material";
import { marks } from '../data';
import { PrettoSlider } from './styled';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const Transactions = ({ setSelectValue, selectValue }) => {
    const getLabelForValue = (value) => {
        const mark = marks.find(mark => mark.value === value);
        if (mark) {
            if (mark.label) {
                setSelectValue(mark.label);
                return mark.label;
            } else if (String(mark.value).includes('.')) {
                const [whole, decimal] = String(mark.value).split('.');
                const label = `${decimal[1]}${decimal[0]}K`;
                setSelectValue(label);
                return label;
            }
        }
        setSelectValue(String(value));
        return String(value);
    }

    return (
        <Grid pl={{ lg: '110px', md: '30px', xs: '0px' }} display={'flex'} flexDirection={'column'} justifyContent={'center'} sx={{ marginTop: '10%' }}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Typography sx={{ fontWeight: 'bold' }} variant="h6" gutterBottom>
                    Simulate your plan
                </Typography>
                <QuestionMarkIcon sx={{ color: '#fff', backgroundColor: '#000', borderRadius: '10px', marginLeft: '10px', fontSize: '20px', marginBottom: '8px' }} />
            </Box>
            <Typography color={'#707070'} variant="subtitle1" gutterBottom>
                Use the slider to select your monthly transactions volume.
            </Typography>

            <Grid pl={{ lg: '0px', md: '0px', xs: '10px' }} sx={{ width: '90%', marginTop: '70px' }}>
                <PrettoSlider
                    colorValue={selectValue}
                    aria-label="Pretto steps"
                    step={null}
                    marks={marks}
                    min={1}
                    max={15.1}
                    valueLabelDisplay="on"
                    valueLabelFormat={getLabelForValue}
                    defaultValue={parseFloat(selectValue.replace('K', ''))}
                />
            </Grid>
        </Grid>
    );
};

export default Transactions;
