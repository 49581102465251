import React, { useEffect, useState } from 'react'
import { Typography, Grid, Card, CardContent, Box, Avatar, AvatarGroup } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAuthentication } from '../../../context/auth-context'
import { PRIMARY_COLOR, ROLE_BUSINESS_ADMINISTRATOR, GREEN_COLOR, ERROR_COLOR, GRAY_TEXT_COLOR, COMPANIES_TRAINERS_REQUEST_STATUS } from '../../../utils/constants'
import * as DASHBOARD_REQUESTS from '../../../api/dashboard'
import { numberFomatter } from '../../../utils/helpers/numberFomatter'
import * as COMPANY_REQUESTS from '../../../api/company'

const CardComparation = ({
    lastMonth,
    thisMonth,
    format = false,
    currency,
    title,
    navigateText,
    navigateTo
}) => {
    const navigate = useNavigate()
  
    const thisMonthNumber = Number(thisMonth || 0)
    const lastMonthNumber = Number(lastMonth || 0)
    const percentageDiff = !thisMonthNumber ? 0 : lastMonth === 0 ? 100 : (lastMonthNumber * 100 / (thisMonthNumber)).toFixed()

    const color = thisMonthNumber >= lastMonthNumber ? GREEN_COLOR : ERROR_COLOR

    return (
        <Grid item xs={12} lg={3} pr={1.5} pb={2}>
            <Card
                sx={{
                    boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
                    border: '1px solid #F0F0F0',
                    borderRadius: 2
                }}
            >
                <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="p" color={'#9CB2CD'} fontWeight={500}>
                        {title}
                    </Typography>
                    <Typography paddingTop={0.5} variant="p" fontSize={30} fontWeight={'bold'}>
                        {format ? numberFomatter(thisMonthNumber) : thisMonthNumber}{currency}
                    </Typography>
                    {!percentageDiff ? null : <Typography paddingTop={0.5} variant="p" fontWeight={'bold'} color={color}>
                        {color === GREEN_COLOR ? '+' : '-'} {percentageDiff > 100 && color !== GREEN_COLOR ? 100 : percentageDiff}%
                    </Typography>}
                    <Typography
                        paddingTop={2}
                        variant="p"
                        fontSize={14}
                        fontWeight={600}
                        color={PRIMARY_COLOR}
                        sx={navigateTo ? { textDecoration: 'underline', cursor: 'pointer' } : {}}
                        onClick={() => {
                            if (navigateTo) {
                                navigate(navigateTo, { state: { currency }})
                            }
                        }}
                    >
                        {navigateText}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default ({ currency }) => {
    const { t } = useTranslation()
    const { user } = useAuthentication()
    const [trainers, setTrainers] = useState([])
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()

    useEffect(() => {
        if (!user.companyId) return

        COMPANY_REQUESTS.getAllMembers(user.companyId)
            .then(response => {
                setTrainers(response.filter(el => el.status = COMPANIES_TRAINERS_REQUEST_STATUS.APPROVED).map(el => ({
                    firstName: el.firstName,
                    lastName: el.lastName,
                    avatarPhotoSrc: el.avatarPhotoSrc,
                    id: el.id
                })))
            })
    }, [user])

    useEffect(() => {
        if (!user || !user.role === ROLE_BUSINESS_ADMINISTRATOR) return

        DASHBOARD_REQUESTS.getReportsCompanyOverview()
            .then(response => {
                setData(response)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [user])

    if (loading || !data) return null

    return (
        <Box display="flex" flexDirection="column">
            <Typography variant="p" fontWeight={'bold'} fontSize={20} pl={1}>
                {t('dashboard.overview.overview')}
            </Typography>
            <Typography variant="p" pb={1} pl={1} color={GRAY_TEXT_COLOR}>{t('reports.lastCurrentMonth')}</Typography>
            <Grid container display="flex" flexDirection="row" sx={{ overflowX: 'auto' }} mt={1}>
                <CardComparation
                    thisMonth={data.currentMonth.totalSales || 0}
                    lastMonth={data.lastMonth.totalSales || 0}
                    title={t('dashboard.overview.revenue')}
                    format
                    currency={currency}
                    navigateText={t('dashboard.overview.goToRevenue')}
                    navigateTo={'/reports/revenue'}
                />
                <CardComparation
                    thisMonth={data.currentMonth.soldMemberships || 0}
                    lastMonth={data.lastMonth.soldMemberships || 0}
                    title={t('dashboard.overview.subscriptionsSold')}
                    navigateText={t('dashboard.overview.goToClients')}
                    navigateTo={'/reports/clients'}
                />
                <CardComparation
                    thisMonth={data.currentMonth.totalAthletes || 0}
                    lastMonth={data.lastMonth.totalAthletes || 0}
                    title={t('dashboard.overview.newClients')}
                    navigateText={t('dashboard.overview.goToClients')}
                    navigateTo={'/reports/clients'}
                />

                <Grid item xs={12} lg={3} pr={1.5} pb={2}>
                    <Card
                        sx={{
                            boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
                            border: '1px solid #F0F0F0',
                            borderRadius: 2
                        }}
                    >
                        <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="p" color={'#9CB2CD'} gutterBottom fontWeight={500}>
                                {t('dashboard.overview.trainers')}

                            </Typography>
                            <Box sx={{ display: 'flex', cursor: 'pointer' }}>
                                <AvatarGroup>
                                    {trainers.map(el => {
                                        return <Avatar alt={el.firstName} key={el.id} src={el.avatarPhotoSrc}>
                                            {el.firstName[0] + el.lastName[0]}
                                        </Avatar>
                                    })}
                                </AvatarGroup>
                            </Box>
                            <Typography
                                paddingTop={4}
                                variant="p"
                                fontSize={14}
                                fontWeight={600}
                                color={PRIMARY_COLOR}
                                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => navigate('/reports/trainers')}
                            >
                                {t('dashboard.overview.goToTrainerReports')}

                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}