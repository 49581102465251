import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import {
  Explore,
  CalendarMonth,
  AddTask,
  CardMembership,
  SupervisorAccount,
  Equalizer,
  Menu,
  Person,
  PieChart,
  QrCode2
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { QrReader } from '../common'

import { useAuthentication } from '../../context/auth-context'
import {
  ROLE_BUSINESS_ADMINISTRATOR,
  ROLE_TRAINER,
  ROLE_USER,
  LIGHT_GRAY_BORDER_COLOR,
  BOTTOMBAR_OPTIONS,
  BUSINESS_ROLES
} from '../../utils/constants'

const BottomBar = ({ handleOpenMenu }) => {
  const navigate = useNavigate()
  const { user, isLogged } = useAuthentication()
  const { t } = useTranslation()

  const [showScanQr, setShowScanQr] = useState(false)
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState('dashboard')

  const handleIcon = useCallback(
    (name) => {
      if (name === 'selfin')
        return <QrCode2 fontSize={'small'} color={`${selected === name ? 'primary' : ''}`} />
      if (name === 'calendar')
        return <CalendarMonth fontSize={'small'} color={`${selected === name ? 'primary' : ''}`} />
      if (name === 'checkin')
        return <AddTask fontSize={'small'} color={`${selected === name ? 'primary' : ''}`} />
      if (name === 'membership')
        return <CardMembership fontSize={'small'} color={`${selected === name ? 'primary' : ''}`} />
      if (name === 'clients')
        return (
          <SupervisorAccount fontSize={'small'} color={`${selected === name ? 'primary' : ''}`} />
        )
      if (name === 'measurements')
        return <Equalizer fontSize={'small'} color={`${selected === name ? 'primary' : ''}`} />
      if (name === 'progress')
        return <Equalizer fontSize={'small'} color={`${selected === name ? 'primary' : ''}`} />
      if (name === 'explore')
        return <Explore fontSize={'small'} color={`${selected === name ? 'primary' : ''}`} />
      if (name === 'menu')
        return <Menu fontSize={'small'} color={`${selected === name ? 'primary' : ''}`} />
      if (name === 'profile')
        return <Person fontSize={'small'} color={`${selected === name ? 'primary' : ''}`} />
      if (name === 'reports')
        return <PieChart fontSize={'small'} color={`${selected === name ? 'primary' : ''}`} />
    },
    [selected]
  )

  useEffect(() => {
    if (!user) return

    let optionsToAdd = []
    if (user.role.toLowerCase() === ROLE_USER.toLowerCase()) {
      optionsToAdd = BOTTOMBAR_OPTIONS.map((el) => ({
        ...el,
        label: t(`navbar.option.${el.name}`),
        icon: handleIcon(el.name)
      })).concat([
        {
          name: 'explore',
          active: false,
          navigate: '/explore',
          label: t(`navbar.option.explore`),
          icon: handleIcon('explore'),

        },
        {
          name: 'selfin',
          active: true,
          onClick: () => setShowScanQr(true),
          icon: handleIcon('selfin'),
          label: t('navbar.option.selfin')
        },
        {
          name: 'menu',
          active: false,
          label: t(`navbar.option.menu`),
          icon: handleIcon('menu'),
          onClick: () => handleOpenMenu()
        }
      ])
    } else if (user.role.toLowerCase() === ROLE_TRAINER.toLowerCase()) {
      optionsToAdd = BOTTOMBAR_OPTIONS.map((el) => ({
        ...el,
        label: t(`navbar.option.${el.name}`),
        icon: handleIcon(el.name),
        navigate: el.name
      }))
        .concat([
          {
            name: 'selfin',
            active: true,
            onClick: () => setShowScanQr(true),
            icon: handleIcon('selfin'),
            label: t('navbar.option.selfin')
          },
          {
            name: 'reports',
            active: false,
            navigate: '/reports',
            label: t(`navbar.option.reports`),
            icon: handleIcon('reports')
          }
        ])
        .concat([
          {
            name: 'profile',
            active: false,
            navigate: '/trainer-profile',
            label: t(`navbar.option.profile`),
            icon: handleIcon('profile')
          },
          {
            name: 'menu',
            active: false,
            label: t(`navbar.option.menu`),
            icon: handleIcon('menu'),
            onClick: () => handleOpenMenu()
          }
        ])
    } else if (
      user.role.toLowerCase() === ROLE_BUSINESS_ADMINISTRATOR.toLowerCase() ||
      user.role.toLowerCase() === BUSINESS_ROLES.BUSINESS_EMPLOYEE.toLowerCase()
    ) {
      optionsToAdd = BOTTOMBAR_OPTIONS.map((el) => ({
        ...el,
        label: t(`navbar.option.${el.name}`),
        icon: handleIcon(el.name)
      }))
        .concat([
          {
            name: 'reports',
            active: false,
            navigate: '/reports',
            label: t(`navbar.option.reports`),
            icon: handleIcon('reports')
          }
        ])
        .concat([
          {
            name: 'profile',
            active: false,
            navigate: '/business-profile',
            label: t(`navbar.option.profile`),
            icon: handleIcon('profile')
          },
          {
            name: 'menu',
            active: false,
            label: t(`navbar.option.menu`),
            icon: handleIcon('menu'),
            onClick: () => handleOpenMenu()
          }
        ])
    }

    setOptions(optionsToAdd)
  }, [user, selected])

  return (
    <>
      {showScanQr ? <QrReader
        onCancel={() => setShowScanQr(false)}
      /> : null}
      <Grid
        display={{ xs: isLogged ? 'flex' : 'none', lg: 'none' }}
        container
        position="fixed"
        bottom={0}
        style={{ backgroundColor: 'white' }}
        width={'100%'}
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        p={1}
        borderTop={`2px solid ${LIGHT_GRAY_BORDER_COLOR}`}
      >
        {options.map((el, index) => {
          return (
            <Grid
              xs={12 / Math.ceil(options.length)}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (el.name !== 'menu') {
                  setSelected(el.name)
                }

                if (el.navigate) {
                  navigate(el.navigate)
                } else {
                  el.onClick()
                }
              }}
              key={index}
              item
            >
              <Grid container flexDirection="column" alignItems="center">
                <Grid item>{el.icon}</Grid>
                <Grid item>
                  <Typography
                    color={`${selected === el.name ? 'primary' : ''}`}
                    fontWeight={200}
                    fontSize={12}
                  >
                    {el.label}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export default BottomBar
