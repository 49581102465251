import { Box, CircularProgress, TableBody } from "@mui/material";

const Loading = () => (
    <TableBody sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1vw', width: '20%' }}>
            <CircularProgress />
        </Box>
    </TableBody>
)

export default Loading;