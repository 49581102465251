import React from 'react'
import { Typography, Box } from '@mui/material'

import { useAuthentication } from '../../../../context/auth-context'
import { GRAY_TEXT_COLOR } from '../../../../utils/constants'

export default () => {
    const { user } = useAuthentication()

    return (
        <Box style={{ padding: 3 }}>
            <Typography variant="h6" color={GRAY_TEXT_COLOR}>
                Hello, {user.firstName} {user.lastName}
            </Typography>
            <Typography variant='span' color={GRAY_TEXT_COLOR}>
                This is the main page of the eCredits system provided by SportIn. If you want to do specifics go to the appropiate page.
            </Typography>
        </Box>
    )
}