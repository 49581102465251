// OptionsStep.js
import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Typography, Autocomplete, TextField, Chip } from '@mui/material';
import Close from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { Toggle } from '../../../common';
import Lesson from '../Lesson';
import ReactGA from 'react-ga';
import { BUCKET_BASE_URL } from '../../../../utils/config'
import * as DOCUMENTS_REQUESTS from '../../../../api/documents'
import {
    CARD_TEXT_COLOR,
    CHIP_BLUE_COLOR,
} from '../../../../utils/constants'

function OptionsStep({
    lesson,
    setLesson,
    filterOptions,
    type,
    open,
    setOpen,
    photoLoading,
    handleOpenDelete,
    availableTrainers,
    selectedTrainers,
    setSelectedTrainers,
    isPublic,
    setIsPublic,
    setPhotoLoading,
    setProfilePhoto,
    profilePhoto
}) {
    const { t } = useTranslation();
    const [editName, setEditName] = useState('')
    const [editLink, setEditLink] = useState('')
    const [editDes, setEditDes] = useState('')
    const [editPho, setEditPho] = useState('')
    const [initialEditPho, setInitialEditPho] = useState('')
    const [nameLeson, setNameLesson] = useState('')
    const [linkLesson, setLinkLesson] = useState('')
    const [value, setValue] = useState("");
    const [photo, setPhoto] = useState('');
    const photoFileInputRef = useRef();
    const [isFree, setIsFree] = useState(false)
    const [isFreeEdit, setIsFreeEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openCreate, setOpenCreate] = useState(false)

    useEffect(() => {
        if (lesson.length > 0 && !openCreate) {
            setOpenCreate(false)
        } else {
            setOpenCreate(true)
        }
    }, [lesson, openCreate])

    const onPhotoChangeHandler = (event) => {
        const file = event.target.files[0]

        const name = Date.now()
        const extension = file.type.split('/')[1]


        setProfilePhoto({ name, extension, originalName: file.name, file })

    }

    const handleOpen = (item) => {
        setOpen(item)
        setEditName(item?.nameLeson)
        setEditLink(item?.linkLesson)
        setEditDes(item?.description)
        setEditPho(item?.photo)
        setInitialEditPho(item?.photo)
        setIsFreeEdit(item.isFree)
    }

    const handleCancel = () => {
        setValue('');
        setLinkLesson('');
        setNameLesson('');
        setPhoto('');
        setIsFree(false)
    }

    const handleSave = async () => {
        setLoading(true)
        try {
            setPhotoLoading(true)
            const newArray = lesson;

            if (photo) {
                const presignedUrlResponse = await DOCUMENTS_REQUESTS.generatePresignedUrl({ name: photo.name, extension: photo.extension });

                await DOCUMENTS_REQUESTS.uploadDocumentS3(presignedUrlResponse, photo.file);

                const documentResponse = await DOCUMENTS_REQUESTS.createDocument({
                    src: `${BUCKET_BASE_URL}/${photo.name}.${photo.extension}`,
                    lessonId: lesson.length,
                    isPhoto: true
                });

                ReactGA.event({
                    category: 'POST_LOGIN',
                    action: 'CREATE_SERVICE',
                    label: 'SUCCESS'
                });
                newArray.push({ nameLeson, linkLesson, description: value, photo: documentResponse.src, isFree: isFree })
            } else {
                newArray.push({ nameLeson, linkLesson, description: value, photo: '', isFree: isFree })
            }

            setLesson([...newArray])
            handleCancel()
            setOpenCreate(false)
            setPhotoLoading(false)
            setLoading(false)
        } catch (error) {
            console.error("Eroare la încărcarea fotografiei:", error);
            ReactGA.event({
                category: 'POST_LOGIN',
                action: 'CREATE_SERVICE',
                label: 'ERROR'
            });
        }
    }
    const handleSaveEdit = async (index) => {
        if (index >= 0 && index < lesson.length) {
            const updatedLessons = [...lesson];
            setLoading(true)
            try {
                if (editPho) {

                    const presignedUrlResponse = await DOCUMENTS_REQUESTS.generatePresignedUrl({ name: editPho.name, extension: editPho.extension });

                    await DOCUMENTS_REQUESTS.uploadDocumentS3(presignedUrlResponse, editPho.file);

                    const documentResponse = await DOCUMENTS_REQUESTS.createDocument({
                        src: `${BUCKET_BASE_URL}/${editPho.name}.${editPho.extension}`,
                        lessonId: lesson.length,
                        isPhoto: true
                    });

                    ReactGA.event({
                        category: 'POST_LOGIN',
                        action: 'CREATE_SERVICE',
                        label: 'SUCCESS'
                    });
                    updatedLessons[index] = { nameLeson: editName, linkLesson: editLink, description: editDes, photo: initialEditPho !== editPho ? documentResponse.src : editPho, isFree: isFreeEdit };
                } else {
                    updatedLessons[index] = { nameLeson: editName, linkLesson: editLink, description: editDes, photo: editPho, isFree: isFreeEdit };
                }
                setLesson(updatedLessons);
                setLoading(false)
            } catch (error) {
                console.error("Eroare la încărcarea fotografiei:", error);
                ReactGA.event({
                    category: 'POST_LOGIN',
                    action: 'CREATE_SERVICE',
                    label: 'ERROR'
                });
            }
        } else {
            console.error("Indexul specificat este în afara limitelor array-ului.");
        }
    };

    return (
        <>
            <Grid
                container
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                {type === 'Company' ? (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {t('course.whenYouAdd')}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            lg={8}
                            xs={12}
                            width="100%"
                            display="flex"
                            justifyContent="center"
                            flexDirection="row"
                            pt={6}
                        >
                            <Autocomplete
                                disablePortal
                                id="trainers-input"
                                filterOptions={filterOptions}
                                onChange={(_, newValue) => {
                                    const { value, label, ...restOfValue } = newValue
                                    setSelectedTrainers((prevValue) => [...prevValue, { ...restOfValue }])
                                }}
                                options={availableTrainers
                                    .filter((el) => !selectedTrainers.map((tr) => tr.id).includes(el.id))
                                    .map((el) => ({
                                        label: `${el.firstName} ${el.lastName}`,
                                        value: el.id,
                                        ...el
                                    }))}
                                sx={{ width: '100%' }}
                                renderInput={(params) => (
                                    <TextField
                                        placeholder={t('course.searchTrainers')}
                                        {...params}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={8}
                            xs={12}
                            width="100%"
                            display="flex"
                            alignItems="center"
                            flexDirection="row"
                            pt={6}
                        >
                            {selectedTrainers && selectedTrainers?.length > 0 ? (
                                selectedTrainers.map((el, index) => {
                                    return (
                                        <Grid key={index} item xs={4}>
                                            <Grid display="flex" flexDirection="row">
                                                <Grid pr={{ xl: 2, lg: 1, xs: 2 }}>
                                                    <img
                                                        src={el.avatarPhotoSrc}
                                                        alt="avatar"
                                                        style={{ borderRadius: 25, objectFit: 'contain' }}
                                                        height={50}
                                                        width={50}
                                                    />
                                                </Grid>
                                                <Grid display="flex" flexDirection="column" justifyContent="center">
                                                    <Typography
                                                        variant="p"
                                                        justifyContent="center"
                                                        fontWeight={600}
                                                        sx={{ textDecoration: 'underline' }}
                                                    >
                                                        {el.firstName} {el.lastName}
                                                    </Typography>
                                                    <Typography
                                                        variant="subtitle2"
                                                        color={CARD_TEXT_COLOR}
                                                        display="flex"
                                                        justifyContent="flex-start"
                                                        flexDirection="row"
                                                    >
                                                        {t('userProfile.activity.detailComponent.favouriteTrainer.trainerAt')}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    display="flex"
                                                    flexDirection="column"
                                                    justifyContent="center"
                                                    pl={2}
                                                >
                                                    <Close
                                                        onClick={() =>
                                                            setSelectedTrainers((prevValue) => [
                                                                ...prevValue.filter((tr) => tr.id !== el.id)
                                                            ])
                                                        }
                                                        sx={{ cursor: 'pointer' }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            ) : (
                                <Typography color="#979797" fontWeight={500} fontSize={17}>
                                    {t('helper.noInformation')}
                                </Typography>
                            )}
                        </Grid>
                    </>
                ) : (
                    <Typography variant="h6">{t('course.extraInfo')}</Typography>
                )}
                <Box sx={{ height: '2px', width: '80%', backgroundColor: '#ccc', borderRadius: 6, marginTop: 6, marginBottom: 3 }}></Box>
                <Grid item lg={8} xs={12} width="100%" pt={type === 'Company' ? 0 : 6}>
                    <Grid container display="flex" flexDirection="column">
                        {lesson?.length > 0 && lesson?.map((el, index) =>
                        (<Box key={`element-${el.nameLeson}-${index}`} sx={{ borderRadius: 4, border: 3, borderColor: '#ccc', marginTop: 3, padding: 2, display: 'flex', flexDirection: open === el ? 'column' : 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6" color='#979797'>{el.nameLeson}</Typography>
                            {open !== el ? <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Button
                                    sx={{ minWidth: 120 }}
                                    onClick={() => handleOpen(el)}
                                    variant="outlined"
                                >
                                    {t('button.edit')}
                                </Button>
                                <Button
                                    sx={{ minWidth: 70, marginLeft: 1 }}
                                    onClick={() => handleOpenDelete(index)}
                                    variant="outlined"
                                    color="error"
                                >
                                    <Delete />
                                </Button>
                            </Box>
                                :
                                <Lesson loading={loading} setIsFree={setIsFreeEdit} isFree={isFreeEdit} photoLoading={photoLoading} photo={editPho} setPhoto={setEditPho} name={editName} link={editLink} description={editDes} setName={setEditName} setLink={setEditLink} setDescription={setEditDes} handleSave={() => handleSaveEdit(index)} handleCancel={() => setOpen('')} />
                            }
                        </Box>)
                        )}
                        {!openCreate ?
                            <Grid item xs={12} display="flex" flexDirection="row" alignItems="center" justifyContent='flex-end' sx={{ marginTop: 3 }}>
                                <Button
                                    sx={{ minWidth: 250 }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => setOpenCreate(true)}
                                >
                                    {loading ? <CircularProgress sx={{ color: '#fff' }} size={24} /> : t('course.addLesson')}
                                </Button>
                            </Grid> :
                            <Lesson loading={loading} setIsFree={setIsFree} isFree={isFree} photoLoading={photoLoading} photo={photo} setPhoto={setPhoto} name={nameLeson} link={linkLesson} description={value} setName={setNameLesson} setLink={setLinkLesson} setDescription={setValue} handleSave={handleSave} handleCancel={handleCancel} />
                        }
                        <Grid container display="flex" flexDirection={{ lg: 'row', xs: 'column' }} sx={{ marginTop: 3 }}>
                            <Grid item xs={6} display="flex" flexDirection="row" alignItems="center">
                                <Toggle
                                    defaultChecked={isPublic}
                                    color="primary"
                                    onClick={() => setIsPublic((prevValue) => !prevValue)}
                                />
                                <Typography pl={1} variant="p">
                                    {t('course.public')}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} display="flex" flexDirection="row" alignItems="center" justifyContent='flex-end'>
                                <input
                                    type="file"
                                    ref={photoFileInputRef}
                                    onChangeCapture={onPhotoChangeHandler}
                                    style={{ display: 'none' }}
                                />
                                {profilePhoto ?
                                    <Chip
                                        sx={{ fontWeight: 500, backgroundColor: CHIP_BLUE_COLOR }}
                                        color="primary"
                                        variant="outlined"
                                        label={profilePhoto.originalName ? profilePhoto.originalName : 'photo'}
                                        onDelete={() => setProfilePhoto('')}
                                    />
                                    :
                                    <Button
                                        sx={{ minWidth: 250 }}
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => photoFileInputRef.current.click()}
                                    >
                                        {`${t('course.photoCourse')}`}
                                    </Button>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </>)
}

export default OptionsStep;
