import { useState, useCallback, useEffect, useMemo } from "react";
import { downloadFeesInvoiceFromPayouts, downloadPaymentInvoiceFromPayouts, downloadPaymentsInvoiceFromPayouts, getBussinessMonths, getBussinessPayouts, exportCSVFromPayouts } from "../api/payout";

const usePayouts = () => {
    const [months, setMonths] = useState([]);
    const [monthPayouts, setMonthPayouts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [clubSubscriptions, setClubSubscriptions] = useState([]);

    const getMonths = useCallback(async () => {
        try {
            const res = await getBussinessMonths();

            setMonths(res.payouts);
            setMonthPayouts(res.payouts[0].currentMonthPayouts);
            setClubSubscriptions(res.clubSubscriptions)
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching months:", error);
        }
    }, []);

    useEffect(() => {
        getMonths();
    }, [getMonths]);

    const getPayoutsByMonth = async (month, userNameFilter, subscriptionId) => {
        setIsLoading(true);
        try {
            const res = await getBussinessPayouts(month, userNameFilter, subscriptionId);
            setMonthPayouts(res);
        } catch (error) {
            console.error("Error fetching payouts:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const memoizedMonths = useMemo(() => months, [months]);

    const downloadFeesInvoice = async (data) => {
        try {
            const res = await downloadFeesInvoiceFromPayouts(data);

            return res;
        } catch (error) {
            console.log("err", error);
        }
    };

    const downloadPaymentsInvoice = async (data) => {
        try {
            const res = await downloadPaymentsInvoiceFromPayouts(data);

            return res;
        } catch (error) {
            console.log("err", error);
        }
    };

    const downloadPaymentInvoice = async (paymentId) => {
        try {
            const res = await downloadPaymentInvoiceFromPayouts(paymentId);

            return res;
        } catch (error) {
            console.log("err", error);
        }
    };

    const exportCSV = async (month) => {
        try {
            const res = await exportCSVFromPayouts(month);

            return res;
        } catch (error) {
            console.log("err", error);
        }
    };
// Used for downlaoding invoices from payouts page; not needed for the moment
    // const downloadExtraMonthlyFee = async (companyId, month) => {
    //     try {
    //         const res = await extraMonthlyFee(companyId, month);

    //         return res.invoice_pdf;
    //     } catch (error) {
    //         console.error(`Error getting extra monthly fee: ${error}`);
    //     }
    // };

    // const downloadCardMonthly = async (companyId, month) => {
    //     try {
    //         const res = await cardMonthly(companyId, month);
    //         console.log({res});

    //         return res.invoice_pdf;
    //     } catch (error) {
    //         console.error(`Error getting card monthly: ${error}`);
    //     }
    // }

    return {
        getPayoutsByMonth,
        monthPayouts,
        isLoading,
        memoizedMonths,
        downloadFeesInvoice,
        downloadPaymentsInvoice,
        downloadPaymentInvoice,
        exportCSV,
        clubSubscriptions
    };
};

export default usePayouts;
