import React from 'react'
import { Grid } from '@mui/material'

import { InputGenerator } from '../../common'

const BillingInputs = ({
  billingInputs,
  wrongBillingInputs,
  wrongCompanyInputs,
  companyInputs,
  billingType,
  filterCompanyInputs,
  filterBillingInputs,
  computeBillingHelperText,
  computeCompanyHelperText,
  handleOnChangeGoogle,
  handleOnChangePhone,
  handleOnChangeBilling,
  handleOnChangeBillingCompany
}) => {
  if (billingType === 'individual') {
    const levels = billingInputs.map((el) => el.level)
    const levelsWithoutDuplicates = levels.filter(
      (element, index) => levels.indexOf(element) === index
    )

    return (
      <Grid item lg={12} pt={4}>
        {levelsWithoutDuplicates.map((level, index) => {
          const inputsToUse = filterBillingInputs(level)
          return (
            <Grid pb={3} key={index} container flexDirection="row">
              {inputsToUse.map((inp, idx) => {
                return (
                  <Grid
                    pr={{ lg: idx !== inputsToUse.length - 1 ? 4 : 0, xs: 0 }}
                    lg={12 / inputsToUse.length}
                    xs={12}
                    key={idx}
                    item
                  >
                    <InputGenerator
                      input={{ ...inp, error: computeBillingHelperText(inp) }}
                      handleOnChangeGoogle={handleOnChangeGoogle}
                      handleOnChangePhone={handleOnChangePhone}
                      handleOnChange={handleOnChangeBilling}
                      error={wrongBillingInputs.includes(inp.name)}
                      helperText={computeBillingHelperText(inp)}
                    />
                  </Grid>
                )
              })}
            </Grid>
          )
        })}
      </Grid>
    )
  } else {
    const levels = companyInputs.map((el) => el.level)
    const levelsWithoutDuplicates = levels.filter(
      (element, index) => levels.indexOf(element) === index
    )

    return (
      <Grid item lg={12} pt={4}>
        {levelsWithoutDuplicates.map((level, index) => {
          const inputsToUse = filterCompanyInputs(level)
          return (
            <Grid pb={3} key={index} container flexDirection="row">
              {inputsToUse.map((inp, idx) => {
                return (
                  <Grid
                    pr={{ lg: idx !== inputsToUse.length - 1 ? 4 : 0, xs: 0 }}
                    lg={12 / inputsToUse.length}
                    xs={12}
                    key={idx}
                    item
                  >
                    <InputGenerator
                      input={{ ...inp, error: computeCompanyHelperText(inp) }}
                      handleOnChange={handleOnChangeBillingCompany}
                      error={wrongCompanyInputs.includes(inp.name)}
                      helperText={computeCompanyHelperText(inp)}
                    />
                  </Grid>
                )
              })}
            </Grid>
          )
        })}
      </Grid>
    )
  }
}

export default BillingInputs
