import { bind } from '@react-rxjs/core'
import { createSignal } from '@react-rxjs/utils'

const [refetchBusinessProfileChange$, setRefetchBusinessProfile] = createSignal()
// eslint-disable-next-line no-unused-vars
const [useRefetchBusinessProfile, refetchBusinessProfile$] = bind(refetchBusinessProfileChange$, '')

export const refetchBusinessProfileObservable = {
  setRefetchBusinessProfile,
  useRefetchBusinessProfile
}

const [refetchTrainerProfileChange$, setRefetchTrainerProfile] = createSignal()
// eslint-disable-next-line no-unused-vars
const [useRefetchTrainerProfile, refetchTrainerProfile$] = bind(refetchTrainerProfileChange$, '')

export const refetchTrainerProfileObservable = {
  setRefetchTrainerProfile,
  useRefetchTrainerProfile
}

const [refetchPublicBusinessProfileChange$, setRefetchPublicBusinessProfile] = createSignal()
// eslint-disable-next-line no-unused-vars
const [useRefetchPublicBusinessProfile, refetchPublicBusinessProfile$] = bind(
  refetchPublicBusinessProfileChange$,
  true
)

export const refetchPublicBusinessProfileObservable = {
  setRefetchPublicBusinessProfile,
  useRefetchPublicBusinessProfile
}

const [refetchPublicTrainerProfileChange$, setRefetchPublicTrainerProfile] = createSignal()
// eslint-disable-next-line no-unused-vars
const [useRefetchPublicTrainerProfile, refetchPublicTrainerProfile$] = bind(
  refetchPublicTrainerProfileChange$,
  true
)

export const refetchPublicTrainerProfileObservable = {
  setRefetchPublicTrainerProfile,
  useRefetchPublicTrainerProfile
}

const [switchCompanyRoleChange$, setSwitchCompanyRole] = createSignal()
// eslint-disable-next-line no-unused-vars
const [useSwitchCompany, switchCompanyRole$] = bind(switchCompanyRoleChange$, null)

export const switchRoleObservable = {
  setSwitchCompanyRole,
  useSwitchCompany
}
