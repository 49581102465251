import React from 'react'
import { Dialog, Slide, Grid, Typography, IconButton, Avatar, Card, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { makeStyles } from '@mui/styles'
import { Close, GroupAdd, CallSplit, CallMerge } from '@mui/icons-material'

import { Table, SimplePagination } from '../../../common'

import { GRAY_TEXT_COLOR, DATE_FORMAT, PRIMARY_COLOR, GREEN_COLOR, ERROR_COLOR } from '../../../../utils/constants'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles(() => ({
    noBorder: {
        borderRadius: 0
    }
}))

const PAGE_SIZE = 7

export default ({ open, onClose, start, end, newUsers, lostUsers, conversionUsers }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const [page, setPage] = React.useState({
        lostClientsPage: 1,
        newClientsPage: 1,
        conversionClientsPage: 1
    })

    return (
        <Dialog classes={{ paper: classes.noBorder }} fullScreen TransitionComponent={Transition} open={open}>
            <Grid container p={2}>
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item xs={10}>
                            <Typography variant='h4' fontWeight="bold">
                                {t('reports.revenue.clientsConversions.title')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                            <IconButton onClick={onClose}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="p" color={GRAY_TEXT_COLOR}>
                        {t('reports.revenue.clientsConversions.description', {
                            start: moment(start).format(DATE_FORMAT),
                            until: moment(end).format(DATE_FORMAT),
                        })}
                    </Typography>
                </Grid>
                {newUsers ? <Grid mt={4} p={2} item xs={12} display="flex" flexDirection="column">
                    <Typography variant="h6" color={PRIMARY_COLOR} fontWeight="bold" display="flex" alignItems="center">
                        <GroupAdd sx={{ color: PRIMARY_COLOR, pr: 1 }} /> {t('reports.revenue.clientsConversions.newClients')} ({newUsers.length})
                    </Typography>
                    {newUsers.length ? <>
                        <Table
                            dense
                            headCells={[
                                'avatar',
                                'firstName',
                                'lastName',
                                'email',
                                'phoneNumber'
                            ].map((key) => t(`reports.revenue.clientsConversions.${key}`))}
                            rows={newUsers.slice((page.newClientsPage - 1) * PAGE_SIZE, (page.newClientsPage - 1) * PAGE_SIZE + PAGE_SIZE).map(user => {
                                return {
                                    avatar: <Avatar fontSize='small' src={user.avatarPhotosrc}>{user.firstName[0]}{user.lastName[0]}</Avatar>,
                                    firstName: <Typography>{user.firstName}</Typography>,
                                    lastName: <Typography>{user.lastName}</Typography>,
                                    email: <Typography>{user.email}</Typography>,
                                    phoneNumber: <Typography>{user.phoneNumber}</Typography>,
                                }
                            })}
                        />
                        <Box style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 10 }}>
                            <SimplePagination
                                count={Math.ceil(Number(newUsers.length / PAGE_SIZE))}
                                onPageChange={pageNumber => setPage({ ...page, newClientsPage: pageNumber })}
                            />
                        </Box>
                    </> : <Typography color={GRAY_TEXT_COLOR}>{t('reports.revenue.clientsConversions.noClients')}</Typography>}
                </Grid> : null}
                {conversionUsers ? <Grid mt={4} p={2} item xs={12} display="flex" flexDirection="column">
                    <Typography variant="h6" color={GREEN_COLOR} fontWeight="bold" display="flex" alignItems="center">
                        <CallMerge sx={{ color: GREEN_COLOR, pr: 1 }} /> {t('reports.revenue.clientsConversions.conversionClients')} ({conversionUsers.length})
                    </Typography>
                    {conversionUsers.length ? <>
                        <Table
                            dense
                            headCells={[
                                'avatar',
                                'firstName',
                                'lastName',
                                'email',
                                'phoneNumber'
                            ].map((key) => t(`reports.revenue.clientsConversions.${key}`))}
                            rows={conversionUsers.slice((page.conversionClientsPage - 1) * PAGE_SIZE, (page.conversionClientsPage - 1) * PAGE_SIZE + PAGE_SIZE).map(user => {
                                return {
                                    avatar: <Avatar fontSize='small' src={user.avatarPhotosrc}>{user.firstName[0]}{user.lastName[0]}</Avatar>,
                                    firstName: <Typography>{user.firstName}</Typography>,
                                    lastName: <Typography>{user.lastName}</Typography>,
                                    email: <Typography>{user.email}</Typography>,
                                    phoneNumber: <Typography>{user.phoneNumber}</Typography>,
                                }
                            })}
                        />
                        <Box style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 10 }}>
                            <SimplePagination
                                count={Math.ceil(Number(conversionUsers.length / PAGE_SIZE))}
                                onPageChange={pageNumber => setPage({ ...page, conversionClientsPage: pageNumber })}
                            />
                        </Box>
                    </> : <Typography color={GRAY_TEXT_COLOR}>{t('reports.revenue.clientsConversions.noClients')}</Typography>}
                </Grid> : null}
                {lostUsers ? <Grid mt={4} p={2} item xs={12} display="flex" flexDirection="column">
                    <Typography variant="h6" color={ERROR_COLOR} fontWeight="bold" display="flex" alignItems="center">
                        <CallSplit sx={{ color: ERROR_COLOR, pr: 1 }} /> {t('reports.revenue.clientsConversions.lostClients')} ({lostUsers.length})
                    </Typography>
                    {lostUsers.length ? <>
                        <Table
                            dense
                            headCells={[
                                'avatar',
                                'firstName',
                                'lastName',
                                'email',
                                'phoneNumber'
                            ].map((key) => t(`reports.revenue.clientsConversions.${key}`))}
                            rows={lostUsers.slice((page.lostClientsPage - 1) * PAGE_SIZE, (page.lostClientsPage - 1) * PAGE_SIZE + PAGE_SIZE).map(user => {
                                return {
                                    avatar: <Avatar fontSize='small' src={user.avatarPhotosrc}>{user.firstName[0]}{user.lastName[0]}</Avatar>,
                                    firstName: <Typography>{user.firstName}</Typography>,
                                    lastName: <Typography>{user.lastName}</Typography>,
                                    email: <Typography>{user.email}</Typography>,
                                    phoneNumber: <Typography>{user.phoneNumber}</Typography>,
                                }
                            })}
                        />
                        <Box style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 10 }}>
                            <SimplePagination
                                count={Math.ceil(Number(lostUsers.length / PAGE_SIZE))}
                                onPageChange={pageNumber => setPage({ ...page, lostClientsPage: pageNumber })}
                            />
                        </Box>
                    </> : <Typography color={GRAY_TEXT_COLOR}>{t('reports.revenue.clientsConversions.noClients')}</Typography>}
                </Grid> : null}
            </Grid>
        </Dialog >

    )
}