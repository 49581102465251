import { ArrowDropDown, Close, CloudDownload, CreditCard, Money, Send } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography, TextField, Tooltip } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import * as SUPER_ADMIN_REQUESTS from '../../api/superAdmin'
import { uniqueArray } from '../../utils/helpers/uniqueArray'
import { InputGenerator, Table } from '../common'
import { numberFomatter } from '../../utils/helpers/numberFomatter'

const SuperAdminGenerateInvoice = ({ handleClose }) => {
    const { t } = useTranslation()

    const [month, setMonth] = useState()
    const [untouchedPayments, setUntouchedPayments] = useState([])
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [expandPayments, setExpandPayments] = useState()

    const handleGetPayments = useCallback(async () => {
        try {
            const paymentsResp = await SUPER_ADMIN_REQUESTS.getPayments(moment(month).format('YYYY-MM-DD'), 'month')
            setUntouchedPayments(paymentsResp.rows)
            setSelectedCompanies(uniqueArray(paymentsResp.rows, 'companyId').map(el => el.companyId))
        } catch (e) {
            console.error(e)
        }
    }, [month])

    const handleSendInvoices = useCallback(async () => {
        try {
            toast.info("We have started the process, please wait!", { position: 'bottom-right' })
            const data = selectedCompanies.map(companyId => {
                const relatedPayments = untouchedPayments.filter(el => el.companyId && el.companyId === companyId && el.paymentOrderId && el.paymentState === 'DEPOSITED' && el.paymentMethod === 'CARD')
                if (!relatedPayments.length) return null
                const totalSum = relatedPayments.reduce((prev, cur) => {
                    const amount = Number(cur.value)
                    const valueWithCommission = cur.paymentOrderId && cur.Company ? (amount * (cur.Company.commission) / 100) + 1.99 : amount
                    return prev + valueWithCommission
                }, 0)
                return ({
                    id: companyId,
                    price: Number(totalSum).toFixed(2),
                    monthYear: moment(relatedPayments[0].createdAt).format('MM-YYYY'),
                    monthYearDay: moment(relatedPayments[0].createdAt).format('YYYY-MM-DD'),
                })
            }).filter(el => el)
            await SUPER_ADMIN_REQUESTS.sendInvoices({ companyInfos: data.filter(el => el) })
            toast.success("We have successfully sent invoices.", { position: 'bottom-right' })
        } catch (e) {
            console.error(e)
            toast.error("There was an error sending invoices, contact one of the developers to investigate.", { position: 'bottom-right' })
        }
    }, [selectedCompanies, untouchedPayments])

    const handleDownloadInvoices = useCallback(async (justDownload = false) => {
        try {
            toast.info("We have started the process, please wait!", { position: 'bottom-right' })
            const data = selectedCompanies.map(companyId => {
                const relatedPayments = untouchedPayments.filter(el => el.companyId && el.companyId === companyId && el.paymentOrderId && el.paymentState === 'DEPOSITED' && el.paymentMethod === 'CARD')
                if (!relatedPayments.length) return
                const totalSum = relatedPayments.reduce((prev, cur) => {
                    const amount = Number(cur.value)
                    const valueWithCommission = cur.paymentOrderId && cur.Company ? (amount * (cur.Company.commission) / 100) + 1 : amount
                    return prev + valueWithCommission
                }, 0)
                return ({
                    id: companyId,
                    price: Number(totalSum).toFixed(2),
                    monthYear: moment(relatedPayments[0].createdAt).format('MM-YYYY'),
                    monthYearDay: moment(relatedPayments[0].createdAt).format('YYYY-MM-DD'),
                })
            })
            await SUPER_ADMIN_REQUESTS.downloadInvoices({ companyInfos: data.filter(el => el), justDownload: justDownload })
            toast.success("We have successfully download invoices.", { position: 'bottom-right' })
        } catch (e) {
            console.error(e)
            toast.error("There was an error downloading invoices, contact one of the developers to investigate.", { position: 'bottom-right' })

        }
    }, [untouchedPayments])

    const handleSendSingleInvoice = useCallback(async (companyId) => {
        try {
            toast.info("We have started the process, please wait!", { position: 'bottom-right' })
            const relatedPayments = untouchedPayments.filter(el => el.companyId && el.companyId === companyId && el.paymentOrderId && el.paymentState === 'DEPOSITED' && el.paymentMethod === 'CARD')
            const totalSum = relatedPayments.reduce((prev, cur) => {
                const amount = Number(cur.value)
                const valueWithCommission = cur.paymentOrderId && cur.Company ? (amount * (cur.Company.commission) / 100) + 1 : amount
                return prev + valueWithCommission
            }, 0)
            const data = {
                id: companyId,
                price: Number(totalSum).toFixed(2),
                monthYear: moment(relatedPayments[0].createdAt).format('MM-YYYY'),
                monthYearDay: moment(relatedPayments[0].createdAt).format('YYYY-MM-DD'),
            }
            await SUPER_ADMIN_REQUESTS.sendInvoices({ companyInfos: [data] })
            toast.success("We have successfully sent invoices.", { position: 'bottom-right' })
        } catch (e) {
            console.error(e)
            toast.error("There was an error downloading invoices, contact one of the developers to investigate.", { position: 'bottom-right' })

        }
    }, [untouchedPayments])

    const handleDownloadSingleInvoice = useCallback(async (companyId) => {
        try {
            toast.info("We have started the process, please wait!", { position: 'bottom-right' })
            const relatedPayments = untouchedPayments.filter(el => el.companyId && el.companyId === companyId && el.paymentOrderId && el.paymentState === 'DEPOSITED' && el.paymentMethod === 'CARD')
            const totalSum = relatedPayments.reduce((prev, cur) => {
                const amount = Number(cur.value)
                const valueWithCommission = cur.paymentOrderId && cur.Company ? (amount * (cur.Company.commission) / 100) + 1 : amount
                return prev + valueWithCommission
            }, 0)
            const data = {
                id: companyId,
                price: Number(totalSum).toFixed(2),
                monthYear: moment(relatedPayments[0].createdAt).format('MM-YYYY'),
                monthYearDay: moment(relatedPayments[0].createdAt).format('YYYY-MM-DD'),
            }
            await SUPER_ADMIN_REQUESTS.downloadInvoices({ companyInfos: [data], justDownload: false })
            toast.success("We have successfully download invoices.", { position: 'bottom-right' })

        } catch (e) {
            console.error(e)
            toast.error("There was an error downloading invoices, contact one of the developers to investigate.", { position: 'bottom-right' })

        }
    }, [selectedCompanies, untouchedPayments])

    useEffect(() => {
        handleGetPayments()
    }, [month])

    const computeRenderValue = useCallback((selectedValues) => {
        return !selectedValues.length ? <Typography color='#323E4A' >Select Companies</Typography> : `${selectedValues.length} Companies selected`
    }, [])

    return (
        <Dialog
            open={true}
            fullWidth={true}
            PaperProps={{
                sx: {
                    height: '100vh',
                    minWidth: '98vw'
                }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                p={4}
            >
                <Grid container display="flex" flexDirection="row">
                    <Grid item xs={10}>
                        <Typography color='#323E4A' variant="h6">Generate Invoices</Typography>
                    </Grid>
                    <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                        <IconButton style={{ color: 'black' }} onClick={handleClose}>
                            <Close fontSize="medium" color="inherit" />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container display="flex" flexDirection="column">
                    <Grid item xs={12} pb={3}>
                        <Typography color='#323E4A' variant="h6">In this section you can generate invoices for clubs that have online payments.</Typography>
                        <Typography color='#323E4A' variant="body2">Please follow next steps in order to have visible payments.</Typography>
                    </Grid>
                    <Grid item xs={12} pt={3}>
                        <Grid container display="flex" flexDirection="row">
                            <Grid item xs={2.5} pr={2}>
                                <Grid container display="flex" flexDirection="column">
                                    <Grid item xs={12} pb={2}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                value={month}
                                                onChange={(newValue) => {
                                                    setMonth(newValue)
                                                }}
                                                openTo="month"
                                                views={['year', 'month']}
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        sx={{
                                                            '.MuiIconButton-root': { marginRight: 1 }
                                                        }}
                                                        {...params}
                                                    />
                                                )} />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} pt={2}>
                                        <InputGenerator
                                            input={{
                                                name: 'companies',
                                                label: 'Companies',
                                                infoMessage: 'Companies',
                                                value: selectedCompanies,
                                                values: uniqueArray(untouchedPayments, 'companyId').filter(el => el.companyId).map(el => ({
                                                    value: el.Company.id,
                                                    label: el.Company.name
                                                })),
                                                multiple: true,
                                                type: 'select',
                                                placeholder: 'Companies',
                                                renderValue: (selectedValues) => computeRenderValue(selectedValues)
                                            }}
                                            handleOnChange={event => {
                                                setSelectedCompanies(event.target.value)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={9.5} pl={2}>
                                <Grid container display="flex" flexDirection="column">
                                    {selectedCompanies.map(companyId => {
                                        const relatedPayments = untouchedPayments.filter(el => el.companyId && el.companyId === companyId && el.paymentOrderId && el.paymentState === 'DEPOSITED' && el.paymentMethod === 'CARD')
                                        if (!relatedPayments.length) return null
                                        const companyName = relatedPayments[0].Company.name
                                        const currency = relatedPayments[0].currency
                                        const totalSum = relatedPayments.reduce((prev, cur) => {
                                            const amount = Number(cur.value)
                                            const commissionAmount = cur.paymentOrderId && cur.Company ? (amount * (cur.Company.commission) / 100) + 1 : amount
                                            return prev + commissionAmount
                                        }, 0)

                                        return (
                                            <Grid item xs={12} pb={3}>
                                                <Box display="flex" flexDirection="row">
                                                    <Typography color='#323E4A' variant="h6" pr={2}>Invoice Information for {companyName}</Typography>
                                                    <Tooltip title={`Send Invoice to ${companyName}`}>
                                                        <Send onClick={() => handleSendSingleInvoice(companyId)} sx={{ position: 'relative', fontSize: 18, top: 9, paddingRight: 1, cursor: 'pointer', color: '#323E4A' }} />
                                                    </Tooltip>
                                                    <Tooltip title={`Download Invoice related to ${companyName}`}>
                                                        <CloudDownload onClick={() => handleDownloadSingleInvoice(companyId)} sx={{ position: 'relative', fontSize: 18, top: 9, paddingLeft: 1, cursor: 'pointer', color: '#323E4A' }} />
                                                    </Tooltip>
                                                </Box>
                                                <Typography color='#323E4A' variant="body1">Total Amount - {Number(totalSum).toFixed(2)} {currency}</Typography>
                                                <Grid container display="flex" flexDirection="column" pt={1}>
                                                    <Grid item xs={12}>
                                                        <Box display="flex" flexDirection="row" sx={{ cursor: 'pointer' }} onClick={() => expandPayments && expandPayments === companyId ? setExpandPayments() : setExpandPayments(companyId)}>
                                                            <Typography color='#323E4A' variant="body2">Expand to see details</Typography>
                                                            <ArrowDropDown sx={{ position: 'relative', top: -2, paddingLeft: 2 }} />
                                                        </Box>
                                                        {expandPayments === companyId && <Box>
                                                            <Table
                                                                dense
                                                                headCells={[
                                                                    'paymentOrderNumber',
                                                                    'username',
                                                                    'subscription',
                                                                    'status',
                                                                    'paymentMethod',
                                                                    'createdAt',
                                                                    'amount',
                                                                    'commission',
                                                                    'commissionPrice'
                                                                ].map((key) => t(`payments.${key}`))}
                                                                rows={relatedPayments.map(payment => {
                                                                    const amount = Number(payment.value)
                                                                    const commissionAmount = payment.paymentOrderId && payment.Company ? (amount * (payment.Company.commission) / 100) : amount
                                                                    const status = payment.paymentState === 'DEPOSITED' ? 'Paid' : payment.paymentState
                                                                    return {
                                                                        paymentOrderNumber: payment.paymentOrderNumber || 0,
                                                                        username: payment.User ? payment.User.firstName + ' ' + payment.User.lastName : '-',
                                                                        subscription: payment.Subscription ? payment.Subscription.name : '-',
                                                                        status: status,
                                                                        paymentMethod: payment.paymentMethod === 'CARD' ? <Box sx={{ display: 'flex', alignItems: 'center' }}> <CreditCard sx={{ paddingRight: 1 }} color="success" /> Card</Box> : <Box sx={{ display: 'flex', alignItems: 'center' }}><Money sx={{ paddingRight: 1 }} color="success" /> Cash</Box>,
                                                                        createdAt: moment(payment.createdAt).format('DD/MM/YYYY HH:mm'),
                                                                        amount: `${numberFomatter(Number(payment.value).toFixed(2))} ${payment.currency}`,
                                                                        commission: `${payment.Company && payment.paymentMethod === 'CARD' ? `${Number(payment.Company.commission).toFixed(2)}%` : 0}`,
                                                                        amountCommission: `${numberFomatter(Number(commissionAmount + 1).toFixed(2))} ${payment.currency}`,
                                                                    }
                                                                })
                                                                }
                                                            />
                                                        </Box>}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ paddingBottom: 4 }}>
                <Grid
                    container
                    display="flex"
                    flexDirection={{ lg: 'row', xs: 'column' }}
                    alignItems="center"
                    justifyContent="center"
                >
                    {/* <Grid item pr={{ xs: 0, lg: 2 }}>
                        <Button onClick={() => handleDownloadInvoices()} sx={{ minWidth: 260 }} variant="outlined" color="primary">
                            Generate & Download All Invoices
                        </Button>
                    </Grid> */}
                    <Grid item pl={{ xs: 0, lg: 2 }} pr={{ xs: 0, lg: 2 }} mt={{ lg: 0, xs: 1 }}>
                        <Button onClick={handleSendInvoices} sx={{ minWidth: 260 }} variant="contained" color="primary">
                            Send All Invoices
                        </Button>
                    </Grid>
                    <Grid item pl={{ xs: 0, lg: 2 }}>
                        <Button onClick={() => handleDownloadInvoices(true)} sx={{ minWidth: 260 }} variant="outlined" color="primary">
                            Download All Invoices
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default SuperAdminGenerateInvoice