import React, { useCallback, useEffect, useState } from 'react'
import { Container, Grid, Typography, Box, Avatar, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { CreditCard, Money, AccountBalance, CreditScore, PendingActions } from '@mui/icons-material'

import { Table, SimplePagination } from '../common'

import * as PAYMENT_REQUESTS from '../../api/payments'
import { useAuthentication } from '../../context/auth-context'
import { numberFomatter } from '../../utils/helpers/numberFomatter'
import { PRIMARY_COLOR, ROLE_USER, PAYMENT_METHOD } from '../../utils/constants'

const PAGE_SIZE = 30

const Payments = () => {
    const { t } = useTranslation()
    const { user } = useAuthentication()
    const isUser = user && user.role === ROLE_USER
    const [payments, setPayments] = useState([])
    const [paymentsCount, setPaymentsCount] = useState(0)
    const [page, setPage] = useState(1)

    const handleGetPayments = useCallback(async () => {
        try {
            if (isUser) {
                const paymentsResp = await PAYMENT_REQUESTS.getPaymentsUser({
                    from: (page - 1) * PAGE_SIZE,
                    limit: PAGE_SIZE,
                })
                setPayments(paymentsResp.rows)
                setPaymentsCount(paymentsResp.count)
            } else {
                const paymentsResp = await PAYMENT_REQUESTS.getPayments(user.companyId, {
                    from: (page - 1) * PAGE_SIZE,
                    limit: PAGE_SIZE
                })
                setPayments(paymentsResp.rows)
                setPaymentsCount(paymentsResp.count)
            }
        } catch (e) {
            console.error(e)
        }
    }, [user, isUser, page])

    useEffect(() => {
        handleGetPayments()
    }, [isUser, user, page])

    const renderPaymentMethod = (method, isBt) => {
        if (method === PAYMENT_METHOD.CARD && !isBt) {
            return <Tooltip arrow title={t('subscriptionModal.pos')}><Avatar variant="square" src={require('../../assets/pos.png')} style={{ width: 25, height: 25, paddingRight: 8 }} /></Tooltip>

        }

        if (method === PAYMENT_METHOD.CARD && isBt) {
            return <Tooltip arrow title={t('subscriptionModal.card')}><CreditCard sx={{ paddingRight: 1 }} color='success' /></Tooltip>
        }


        if (method === PAYMENT_METHOD.BANK_TRANSFER) {
            return <Tooltip arrow title={t('subscriptionModal.BANK_TRANSFER')}>   <AccountBalance sx={{ paddingRight: 1 }} color="success" /></Tooltip>
        }

        return <Tooltip arrow title={t('subscriptionModal.cash')}> <Money sx={{ paddingRight: 1 }} color="success" /></Tooltip>
    }

    return (
        <Container maxWidth="xl">
            <Grid
                container
                pt={4}
                paddingLeft={{ xs: 1 }}
                paddingRight={{ xs: 1 }}
                display="flex"
                flexDirection="column"
                width="100%"
            >
                <Grid item xs={12} pb={2.5}>
                    <Typography variant="h5" fontWeight="bold">
                        {isUser ? t('navbar.transactions') : t('navbar.payments')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        {isUser ? t('navbar.transactionsSubtitle1') : t('navbar.paymentsSubtitle1')}
                    </Typography>
                </Grid>
                {isUser ? null : <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        {t('navbar.paymentsSubtitle2')}
                    </Typography>
                </Grid>}
                <Grid item xs={12} pt={2}>
                    <Table
                        dense
                        headCells={[
                            'company',
                            'paymentOrderNumber',
                            'username',
                            'subscription',
                            'status',
                            'paymentMethod',
                            'createdAt',
                            'amount',
                            'voucher',
                            ...(isUser ? [] : ['moneySend'])
                        ].map((key) => t(`payments.${key}`))}
                        rows={payments.map(payment => {
                            const status = payment.paymentState === 'DEPOSITED' ? 'Paid' : payment.paymentState
                            return {
                                company: <Typography fontWeight={'bold'} color={PRIMARY_COLOR}>{payment.Company ? payment.Company.name : '-'}</Typography>,
                                paymentOrderNumber: payment.paymentOrderNumber || 0,
                                username: payment.User ? payment.User.firstName + ' ' + payment.User.lastName : '-',
                                subscription: payment.Subscription ? payment.Subscription.name : payment.Slot && payment.Slot.CalendarService && payment.Slot.CalendarService.name ? payment.Slot.CalendarService.name : '-',
                                status: <Typography variant="body2" fontWeight="bold" color={status === 'Paid' ? 'green' : 'red'}>{status}</Typography>,
                                paymentMethod: renderPaymentMethod(payment.paymentMethod, payment.paymentOrderId),
                                createdAt: moment(payment.createdAt).format('DD/MM/YYYY HH:mm'),
                                amount: `${numberFomatter(Number(payment.value))} ${payment.currency}`,
                                voucher: payment.Voucher ? `${payment.Voucher.discount} ${payment.Voucher.hasFixedDiscount ? payment.currency : '%'}` : '-',
                                ...(isUser ? {} : {
                                    moneySend: (payment.paymentMethod === PAYMENT_METHOD.CARD && payment.paymentOrderId) ? <Tooltip arrow title={payment.bankTransferDone ? t('payments.moneySendDescription') : t('payments.noMoneySendDescription')}>{payment.bankTransferDone ? <CreditScore fontSize='small' color='success' /> : <PendingActions color='warning' fontSize='small' />}</Tooltip> : ''
                                })
                            }
                        })}
                    />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" pt={1}>
                    <SimplePagination
                        count={Math.ceil(Number(paymentsCount / PAGE_SIZE))}
                        onPageChange={setPage}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}

export default Payments