import axios from './axios'

export const getPublicServiceDetails = serviceId => {
  return axios.get(`/public/services/${serviceId}`).then((res) => res.data)
}

export const getServiceForCompany = (serviceId) => {
  return axios.get(`/companies/services/${serviceId}`).then((res) => res.data)
}

export const getServiceForTrainer = (serviceId) => {
  return axios.get(`trainers/services/${serviceId}`).then((res) => res.data)
}

export const createServiceForCompany = (companyId, payload) => {
  return axios.post(`/companies/services/company/${companyId}`, payload).then((res) => res.data)
}

export const createServiceForTrainer = (payload) => {
  return axios.post(`/trainers/services`, payload).then((res) => res.data)
}

export const updateService = (serviceId, payload) => {
  return axios.patch(`/services/${serviceId}`, payload).then((res) => res.data)
}

export const getAllReviews = (serviceId) => {
  return axios.get(`/services/${serviceId}/reviews`).then((res) => res.data)
}

export const getServiceById = (serviceId) => {
  return axios.get(`/services/${serviceId}`).then((res) => res.data)
}
