import React, { useMemo } from 'react'
import { Grid, Typography, Rating } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { LIGHT_GRAY_BORDER_COLOR, PRIMARY_COLOR } from '../../../../utils/constants'
import Review from './subcomponents/Review'

const Reviews = ({ Reviews, reviewsAverage }) => {
  const { t } = useTranslation()
  const renderReviews = useMemo(() => {
    let score = Math.ceil(reviewsAverage)

    return (
      <>
        <Rating value={score} disabled sx={{ color: PRIMARY_COLOR }} />
        <Typography pl={2} fontWeight={700} color="black">
          {score} / 5
        </Typography>
        <Typography pl={2} fontWeight={700} color="black">
          ({Reviews.length} {t('userProfile.activity.reviews').toLocaleLowerCase()})
        </Typography>
      </>
    )
  }, [Reviews])

  return (
    <Grid
      container
      display="flex"
      flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }}
      p={3}
      pt={5}
    >
      <Grid item xs={12}>
        <Typography fontWeight={500} color="black" fontSize={20}>
          {t('userProfile.activity.reviews')}
        </Typography>
      </Grid>
      <Grid pt={2} item xs={12} display="flex" alignItems="center" flexDirection="row">
        {renderReviews}
      </Grid>
      <Grid pt={5} container display="flex">
        {Reviews.length > 0 ? (
          Reviews.map((el, index) => {
            return (
              <Grid
                key={index}
                item
                pt={2}
                pb={2}
                width="100%"
                borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
              >
                <Review
                  name={el.User.firstName + ' ' + el.User.lastName}
                  comment={el.comment}
                  createdAt={moment(el.createdAt).format('YYYY-MM-DD')}
                  stars={el.stars}
                />
              </Grid>
            )
          })
        ) : (
          <Typography color="#979797" fontWeight={500} fontSize={17}>
            {t('helper.noResultsFound')}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default Reviews
