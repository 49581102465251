import { createTheme } from '@mui/material/styles'
import { PRIMARY_COLOR } from '../../constants'

const radioButtonTheme = createTheme({
  components: {
    MuiRadio: {
      styleOverrides: {
        colorPrimary: {
          color: PRIMARY_COLOR
        }
      }
    }
  }
})

export default radioButtonTheme
