import axios from './axios'


export const getCourseForCompany = (courseId) => {
    return axios.get(`/companies/courses/${courseId}`).then((res) => res.data)
}

export const getCourseForTrainer = (courseId) => {
    return axios.get(`trainers/courses/${courseId}`).then((res) => res.data)
}

export const createCourseForCompany = (companyId, payload) => {
    return axios.post(`/companies/courses/company/${companyId}`, payload).then((res) => res.data)
}
export const deleteCourse = (id) => {
    return axios.delete(`/companies/courses/${id}`).then((res) => res.data)
}

export const createCourseForTrainer = (payload) => {
    return axios.post(`/trainers/courses`, payload).then((res) => res.data)
}

export const updateCourse = (courseId, payload) => {
    return axios.patch(`/courses/${courseId}`, payload).then((res) => res.data)
}

export const getAllReviews = (courseId) => {
    return axios.get(`/courses/${courseId}/reviews`).then((res) => res.data)
}

export const getCourseById = (courseId) => {
    return axios.get(`/courses/${courseId}`).then((res) => res.data)
}
