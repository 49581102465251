import React, { useMemo } from 'react'
import { Grid, Typography } from '@mui/material'
import ReactQuill from 'react-quill'
import { useTranslation } from 'react-i18next'
import 'react-quill/dist/quill.snow.css'
const CalendarEventNotes = ({ notes, handleOnNotesChanged }) => {
  const { t } = useTranslation()

  const renderNotes = useMemo(() => {
    return (
      <Grid container display="flex" flexDirection="column" pt={{ lg: 6, xs: 2 }} pb={6} minHeight={'65vh'}>
        <Grid item lg={12} pb={2}>
          <Typography variant="body1" fontWeight={600}>
            {t('calendar.notesTitle')}
          </Typography>
        </Grid>
        <Grid item lg={12} pt={2}>
          <ReactQuill
            defaultValue={notes}
            onChange={(newContent) => {
              handleOnNotesChanged(newContent)
            }}
          >
            <div style={{ minHeight: 250 }} />
          </ReactQuill>
        </Grid>
      </Grid>
    )
  }, [])

  return renderNotes
}

export default CalendarEventNotes
