import axios from 'axios'

import { CASH_REGISTER_URL } from '../config'

const instance = axios.create({ withCredentials: true })

export const createInvoice = async payload => {
    try {
        await instance.post(CASH_REGISTER_URL, payload)
    } catch (error) {

    }

    return true
}