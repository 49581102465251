import React, { useEffect, useState, useMemo } from 'react'
import { Grid, Typography, Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CheckCircle } from '@mui/icons-material'
import { useParams, Link } from 'react-router-dom'
import Linkify from 'react-linkify'
import parse from 'html-react-parser'

import GalleryImageDialog from '../../../common/GalleryImageDialog'
import FavouriteClub from './subcomponents/FavouriteClub'
import * as TRAINER_REQUESTS from '../../../../api/trainer'

import { LIGHT_GRAY_BORDER_COLOR, CHIP_BLUE_COLOR } from '../../../../utils/constants'

const Details = ({ bio, Companies, Photos, Licences }) => {
  const { t } = useTranslation()
  const { domain } = useParams()

  const [services, setServices] = useState([])
  const [galleryDialog, setGalleryDialog] = useState({
    open: false,
    image: null
  })

  useEffect(() => {
    if (!domain) return

    TRAINER_REQUESTS.getTrainerServicesByDomain(domain).then((res) => {
      setServices(res)
    })
  }, [domain])

  const renderServices = useMemo(() => {
    return (
      <>
        {services.filter((service) => service.display).length > 0 ? (
          services
            .filter((service) => service.display)
            .map((service, index) => {
              return (
                <Grid item key={index}>
                  <Chip
                    sx={{ fontWeight: 500, backgroundColor: CHIP_BLUE_COLOR }}
                    color="primary"
                    variant="outlined"
                    label={service.title}
                  />
                </Grid>
              )
            })
        ) : (
          <Typography pl={1} color="#979797" fontWeight={500} fontSize={17}>
            {t('helper.noInformation')}
          </Typography>
        )}
      </>
    )
  }, [services])

  const licences = Licences.filter((licence) => licence.approved)

  return (
    <>
      {galleryDialog.open && galleryDialog.image ? (
        <GalleryImageDialog
          image={galleryDialog.image}
          onClose={() =>
            setGalleryDialog({
              open: false,
              image: null
            })
          }
        />
      ) : null}
      <Grid
        container
        width="100%"
        display="flex"
        flexDirection={{ xs: 'column', lg: 'column', xl: 'column' }}
        pt={2}
        pb={4}
      >
        <Grid
          item
          display="flex"
          flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }}
          xs={12}
          pb={{ xs: 2, lg: 0 }}
          borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
        >
          <Grid
            item
            pl={3}
            pr={3}
            lg={8}
            xs={12}
            display="flex"
            flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }}
          >
            <Grid width="100%" item pb={3} display="flex" flexDirection="column">
              <Grid pb={1}>
                <Typography fontWeight={500} color="black" variant="h6">
                  {t('userProfile.activity.detailComponent.bio')}
                </Typography>
              </Grid>
              <Grid className="bio">
                {bio ? (
                  <Linkify>
                    <Typography fontWeight={500} color={`${bio ? 'black' : '#979797'}`}>
                      {parse(bio) || t('helper.noInformation')}
                    </Typography>
                  </Linkify>
                ) : (
                  <Typography color="#979797" fontWeight={500} fontSize={17}>
                    {t('helper.noInformation')}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item pl={3} pr={3} xs={12} lg={4} display="flex" flexDirection="column">
            <Grid item pb={1}>
              <Typography fontWeight={500} color="black" variant="h6">
                {t('userProfile.activity.trainerDetailComponent.galery')}
              </Typography>
            </Grid>
            <Grid item display="flex" flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }}>
              <Grid
                container
                flexWrap="wrap"
                display="flex"
                flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }}
              >
                <Grid pt={1} container display="flex" flexWrap="wrap" spacing={1} pr={1}>
                  {Photos.length > 0 ? (
                    Photos.filter((el) => el.isPhoto).map((photo, index) => {
                      return (
                        <Grid
                          sx={{ cursor: 'pointer' }}
                          onClick={() =>
                            setGalleryDialog({
                              open: true,
                              image: photo.src
                            })
                          }
                          key={index}
                          item
                          xs={4}
                          lg={4}
                        >
                          <img
                            width="100%"
                            height="100%"
                            lazy
                            style={{ height: 100, objectFit: 'cover' }}
                            src={photo.src}
                          />
                        </Grid>
                      )
                    })
                  ) : (
                    <Typography pl={1} color="#979797" fontWeight={500} fontSize={17}>
                      {t('helper.noInformation')}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          width="100%"
          pl={3}
          pr={3}
          pt={2}
          pb={2}
          borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
          display="flex"
          flexDirection="column"
        >
          <Grid pb={1.5}>
            <Typography fontWeight={500} color="black" variant="h6">
              {t('userProfile.activity.trainerDetailComponent.qualifications')}
            </Typography>
          </Grid>
          <Grid container display="flex" flexWrap="wrap" spacing={1} maxWidth={'40%'}>
            {renderServices}
          </Grid>
        </Grid>
        <Grid
          item
          width="100%"
          pl={3}
          pr={3}
          pt={2}
          pb={2}
          display="flex"
          flexDirection="column"
          borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
        >
          <Grid pb={1.5}>
            <Typography fontWeight={500} color="black" variant="h6">
              {t('userProfile.activity.trainerDetailComponent.licences')}
            </Typography>
          </Grid>
          <Grid container display="flex" flexWrap="wrap" spacing={1}>
            {licences.length > 0 ? (
              licences.map((licence, index) => {
                return (
                  <Grid item key={index}>
                    <Chip
                      sx={{ fontWeight: 500, backgroundColor: 'white' }}
                      color="primary"
                      variant="outlined"
                      icon={<CheckCircle color="primary" />}
                      label={licence.description}
                    />
                  </Grid>
                )
              })
            ) : (
              <Typography pl={1} color="#979797" fontWeight={500} fontSize={17}>
                {t('helper.noInformation')}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid pt={1.5} mr={3} ml={3} item width="100%">
          <Grid pb={1}>
            <Typography fontWeight={500} color="black" variant="h6">
              {t('userProfile.activity.trainerDetailComponent.relatedClubs')}
            </Typography>
          </Grid>
          <Grid sx={{ overflowX: 'auto' }} display="flex" flexDirection="row" pb={2}>
            {Companies.length > 0 ? (
              Companies.map((el, index) => {
                return (
                  <Grid key={index} mr={5}>
                    <Link style={{ textDecoration: 'none' }} to={'/' + el.domain}>
                      <FavouriteClub
                        avatar={el.avatarPhotoSrc}
                        name={el.name}
                        address={el.locationName}
                      />
                    </Link>
                  </Grid>
                )
              })
            ) : (
              <Typography color="#979797" fontWeight={500} fontSize={17}>
                {t('helper.noInformation')}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Details
