import React, { useEffect, useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga'

import { LANGUAGE_RO, ROLE_BUSINESS_ADMINISTRATOR, ROLE_TRAINER, ROLE_USER, TYPE_ATHLETE } from '../../utils/constants'

import * as AUTH_REQUESTS from '../../api/auth'
import * as USER_ERQUESTS from '../../api/user'
import { useAuthentication } from '../../context/auth-context'
import { useError } from '../../context/error-context'
import { TypeAthlete, TypeOrgaization, Welcome } from '../SignUp/subcomponents'

const SignUpIndividualCompany = ({ typeSignUp }) => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    const { handleError } = useError()
    const { handleLoggedIn, handleToken, handleUser } = useAuthentication()
    const { domain } = useParams()

    const [searchParams, setSearchParams] = useSearchParams()
    const [step, setStep] = useState(1)
    const [type, setType] = useState('')
    const [register, setRegister] = useState()
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()

    useEffect(() => {
        if (searchParams.get('type')) {
            if (searchParams.get('serviceId') || searchParams.get('eventId')) {
                setStep(1)
                setType(t(`type.${TYPE_ATHLETE.toLowerCase()}`))
            }
        }
    }, [searchParams, t])

    useEffect(() => {
        if (!domain) return

        setStep(1)
        setType(t(`type.${TYPE_ATHLETE.toLowerCase()}`).toLowerCase())
    }, [domain])

    const handleNext = () => {
        setStep((prevValue) => prevValue + 1)
    }

    const handlePrev = () => {
        if (step === 1) navigate('/login')
        else setStep((prevValue) => prevValue - 1)
    }

    const handleSubmit = async (data) => {
        try {
            handleError('')
            setSending(true)
            const { domain, ...restOfData } = data
            const registerData = await AUTH_REQUESTS.register(type.toLowerCase() === t(`type.${TYPE_ATHLETE.toLowerCase()}`).toLowerCase() ? restOfData : data, typeSignUp)

            setSending(false)
            setRegister({ ...registerData, password: typeSignUp === 'user' ? data.password : data.user.password })
            if (searchParams.get('serviceId') || searchParams.get('eventId')) {
                handleDashboard(LANGUAGE_RO, { ...registerData, password: typeSignUp === 'user' ? data.password : data.user.password })
            }
            else {
                handleNext()
            }
            ReactGA.event({
                category: 'PRE_LOGIN',
                action: 'SIGN_UP',
                label: 'SUCCESS'
            })
        } catch (e) {
            setSending(false)
            handleError(t('signup.error'))
            setError(
                e && e.response && e.response.data
                    ? e.response.data.split('Error: ')[1].split('\n')[0]
                    : 'register_error'
            )
            ReactGA.event({
                category: 'PRE_LOGIN',
                action: 'SIGN_UP',
                label: 'ERROR'
            })
            console.error(e)
        }
    }

    const handleDashboard = async (selectedLanguage, registerData) => {
        try {
            const { user } = register ? register : registerData
            setSending(true)
            const loginData = await AUTH_REQUESTS.login(user.email, register ? register.password : registerData.password)
            setSending(false)

            handleLoggedIn(true)
            handleToken(loginData.token)
            handleUser(loginData.user)
            localStorage.setItem('token', loginData.token)

            await USER_ERQUESTS.updateLanguage(loginData.user.id, selectedLanguage)
            i18n.changeLanguage(selectedLanguage.toLowerCase() === 'eng' ? 'en' : selectedLanguage.toLowerCase())

            if (searchParams.get('serviceId')) {
                let queryParams = `?successRegister=true&`
                const keys = ['selectedSlot', 'selectedCost', 'selectedPaymentMethod', 'day', 'serviceId', 'selectedSubscription']
                keys.forEach(key => {
                    queryParams += `${key}=${searchParams.get(key)}&`
                })
                navigate(`/calendar/services/${searchParams.get('serviceId')}${queryParams}`)
            }
            else if (searchParams.get('eventId')) {
                navigate(`/calendar?eventId=${searchParams.get('eventId')}`)
            }
            else {
                if (loginData.user.role === ROLE_USER) navigate('/user-profile')
                else if (loginData.user.role === ROLE_TRAINER) navigate('/trainer-profile')
                else if (loginData.user.role === ROLE_BUSINESS_ADMINISTRATOR) navigate('/business-profile')
                else navigate('/')
            }
        } catch (e) {
            setSending(false)
            console.error(e)
        }
    }

    const handleLogin = (language) => {
        navigate(`/login?lang=${language}`)
    }

    const renderTypeForm = useMemo(() => {
        return typeSignUp === 'user' ? (
            <TypeAthlete
                error={error}
                sending={sending}
                handlePrev={handlePrev}
                handleSubmit={handleSubmit}
            />
        ) : (
            <TypeOrgaization
                error={error}
                sending={sending}
                handlePrev={handlePrev}
                handleSubmit={handleSubmit}
            />
        )

    }, [step, sending, error, typeSignUp])

    const renderWelcome = useMemo(() => {
        return <Welcome sending={sending} handleDashboard={handleDashboard} handleLogin={handleLogin} />
    }, [handleDashboard, handleLogin, sending])

    const renderContent = useMemo(() => {
        if (step === 1) return renderTypeForm
        else if (step === 2) return renderWelcome
    }, [step, error, type, renderTypeForm])

    return (
        <Grid paddingTop={4} container height="100%" spacing={2}>
            {renderContent}
        </Grid>
    )
}

export default SignUpIndividualCompany
