import axios from './axios'

export const companyFindAll = () => {
  return axios.get(`/sport-types/company`).then((res) => res.data)
}

export const companycreate = (payload) => {
  return axios.post(`/sport-types/company`, { ...payload }).then((res) => res.data)
}

export const companyRemove = (id) => {
  return axios.delete(`/sport-types/company/${id}`).then((res) => res.data)
}

export const trainerFindAll = () => {
  return axios.get(`/sport-types/trainer`).then((res) => res.data)
}

export const trainercreate = (payload) => {
  return axios.post(`/sport-types/trainer`, { ...payload }).then((res) => res.data)
}

export const trainerRemove = (id) => {
  return axios.delete(`/sport-types/trainer/${id}`).then((res) => res.data)
}
