import React from 'react'
import Pagination from '@mui/material/Pagination'
import PropTypes from 'prop-types'

const SimplePagination = ({ count, onPageChange }) => {
  return (
    <Pagination
      onChange={(_, value) => onPageChange(value)}
      defaultChecked
      defaultPage={1}
      color="primary"
      count={count}
      variant="outlined"
      shape="rounded"
    />
  )
}

SimplePagination.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
}

export default SimplePagination
