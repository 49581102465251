import React, { useEffect, useState } from 'react'
import { Typography, Grid, Box, Button, Container } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { Table, Footer } from '../common'
import { AddEmployeeDialog, ConfirmDeleteEmployee } from './subcomponents'

import { ROLE_TRAINER } from '../../utils/constants'
import * as EMPLOYEES_REQUESTS from '../../api/employees'
import { useAuthentication } from '../../context/auth-context'

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
})

const Employees = () => {
  const classes = useStyles()
  const { user } = useAuthentication()
  const { t } = useTranslation()

  const [deleteDialog, setDeleteDialog] = useState({
    show: false,
    employeeId: null,
    title: t('helper.deleteEmployee'),
    text: t('helper.confirmDeleteEmployee')
  })
  const [employees, setEmployees] = useState([])
  const [showAddEmployee, setShowAddEmployee] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState()

  const onCreateEmployeeHandler = ({ employeeId, companyId, role }) => {
    return EMPLOYEES_REQUESTS.create(companyId, employeeId, role).then(getAllEmployees)
  }

  const deleteEmployeeHandler = (employeeId) => {
    return EMPLOYEES_REQUESTS.deleteEmployee(employeeId).then(getAllEmployees)
  }

  const getAllEmployees = () => {
    return EMPLOYEES_REQUESTS.getAll({ businessAdministratorId: user.companyId }).then(
      (employees) => {
        setEmployees(
          employees.map((employee) => {
            return {
              nume: (
                <Link
                  style={{ textDecoration: 'none' }}
                  to={
                    employee.Employee.role === ROLE_TRAINER
                      ? '/' + employee.Employee.domain
                      : '/athlete/' + employee.Employee.id
                  }
                >
                  <Typography
                    variant="subtitle1"
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    color="primary"
                    fontWeight={'500'}
                  >
                    {employee.Employee.firstName} {employee.Employee.lastName}
                  </Typography>
                </Link>
              ),
              role: (
                <Typography variant="subtitle1" fontWeight={'500'}>
                  {t(`roles.${employee.role}`)}
                </Typography>
              ),
              companie: (
                <Typography variant="subtitle1" fontWeight={'500'}>
                  {employee.Company.name}
                </Typography>
              ),
              locatie: (
                <Typography variant="subtitle1" fontWeight={'500'} noWrap>
                  {employee.Company.locationName}
                </Typography>
              ),
              from: (
                <Typography variant="subtitle1" fontWeight={'500'}>
                  {moment(employee.createdAt).format('DD-MM-YYYY')}
                </Typography>
              ),
              accountType: (
                <Typography variant="subtitle1" fontWeight={'500'} noWrap>
                  {employee.Employee.role === ROLE_TRAINER ? t('trainer') : t('user')}
                </Typography>
              ),
              actiuni: (
                <Box className={classes.actions}>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: 10, backgroundColor: 'inherit' }}
                    onClick={() => {
                      setSelectedEmployee(employee.id)
                    }}
                  >
                    {t('button.edit')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    style={{ marginleft: 10, backgroundColor: 'inherit' }}
                    onClick={() => {
                      setDeleteDialog((prevState) => ({
                        ...prevState,
                        show: true,
                        employeeId: employee.id,
                        onDelete: () =>
                          deleteEmployeeHandler(employee.id).then(() => {
                            setDeleteDialog((prevState) => ({
                              ...prevState,
                              show: false,
                              employeeId: null,
                              onDelete: null,
                              onClose: null
                            }))
                          }),
                        onClose: () => {
                          setDeleteDialog((prevState) => ({
                            ...prevState,
                            show: false,
                            employeeId: null,
                            onDelete: null,
                            onClose: null
                          }))
                        }
                      }))
                    }}
                  >
                    <Delete fontSize="small" />
                  </Button>
                </Box>
              )
            }
          })
        )
      }
    )
  }

  useEffect(() => {
    if (selectedEmployee) {
      setShowAddEmployee(true)
    }
  }, [selectedEmployee])

  useEffect(() => {
    getAllEmployees()
  }, [user])

  return (
    <Container maxWidth="xl">
      {deleteDialog.show && <ConfirmDeleteEmployee {...deleteDialog} />}
      {showAddEmployee && (
        <AddEmployeeDialog
          id={selectedEmployee}
          onClose={() => {
            setShowAddEmployee(false)
            setSelectedEmployee()
          }}
          onSave={(data) => onCreateEmployeeHandler(data)}
        />
      )}
      <Grid container pt={8} pb={8}>
        <Grid item pb={5} xs={12}>
          <Grid container flexDirection="row" display="flex">
            <Grid item xs={12} md={12} lg={6}>
              <Typography variant="h5" fontWeight="bold">
                {t('employees.title')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              justifyContent="flex-end"
              flexDirection="row"
              display="flex"
            >
              <Grid
                container
                display="flex"
                flexDirection="row"
                spacing={3}
                justifyContent="flex-end"
              >
                <Grid item xs={12} md={12} lg={4} display="flex">
                  <Button
                    style={{ height: 45 }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setShowAddEmployee(true)
                    }}
                  >
                    {t('employees.addEmployee')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pb={5}>
          <Table
            headCells={[
              'firstName',
              'role',
              'employedAt',
              'location',
              'employedFrom',
              'accountType',
              'actions'
            ].map((key) => t(`employees.tableHead.${key}`))}
            rows={employees}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Employees
