import React, { useEffect, useState } from 'react'
import { Dialog, IconButton, Button, Typography, Grid, FormControl, FormLabel, Radio, RadioGroup, FormControlLabel } from '@mui/material'
import { Close } from '@mui/icons-material'

import * as USER_SUBSCRIPTION_REQUESTS from '../../../../api/userSubscriptions'
import { SUBSCRIPTION_PAYMENT_STATUS, PAYMENT_METHOD } from '../../../../utils/constants'
import { useTranslation } from 'react-i18next'

const Confirm = ({
    onCancel,
    onConfirm,
    userSubscriptionId,
    currency
}) => {
    const { t } = useTranslation()
    const [userSubscription, setUserSubscription] = useState()
    const [loading, setLoading] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.CASH)

    const confirmPayment = () => {
        setLoading(true)
        USER_SUBSCRIPTION_REQUESTS.update(userSubscriptionId, { status: SUBSCRIPTION_PAYMENT_STATUS.CONFIRMED, type: paymentMethod, currency: currency })
            .then(() => {
                onConfirm()
            })
            .catch(e => {
                console.error(e)
            })
            .finally(() => {
                setLoading(false)
                onCancel()
            })
    }

    useEffect(() => {
        USER_SUBSCRIPTION_REQUESTS.findById(userSubscriptionId)
            .then(response => {
                setUserSubscription(response)
            })
    }, [userSubscriptionId])

    if (!userSubscription) return null

    const fullName = `${userSubscription.User.firstName} ${userSubscription.User.lastName}`

    return (
        <Dialog open maxWidth="sm" fullWidth>
            <Grid container p={4}>
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item
                            xs={10}
                            md={10}
                            lg={10}
                            pr={{ xs: 0, md: 0, lg: 2 }}
                            pl={{ xs: 0, md: 0, lg: 2 }}>
                            <Typography variant="h4" fontWeight={500} color={'black'}>
                                {t('clients.confirmPay')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                            <IconButton onClick={onCancel}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    pt={4}
                    pb={4}
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}
                >
                    <Typography gutterBottom variant="h6" pb={2}>{t('clients.confirmPaymentForSub')} <b>{userSubscription.Subscription.name}</b> {t('clients.forTheUser')} <b>{fullName}</b>.</Typography>
                    <FormControl>
                        <Typography variant="p" gutterBottom>{t('clients.payMethod')}</Typography>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                        >
                            <FormControlLabel onClick={() => setPaymentMethod(PAYMENT_METHOD.CASH)} value={PAYMENT_METHOD.CASH} control={<Radio checked={paymentMethod === PAYMENT_METHOD.CASH} />} label={t('clients.cash')} />
                            <FormControlLabel onClick={() => setPaymentMethod(PAYMENT_METHOD.CARD)} value={PAYMENT_METHOD.CARD} control={<Radio checked={paymentMethod === PAYMENT_METHOD.CARD} />} label={t('clients.card')} />
                            <FormControlLabel onClick={() => setPaymentMethod(PAYMENT_METHOD.BANK_TRANSFER)} value={PAYMENT_METHOD.BANK_TRANSFER} control={<Radio checked={paymentMethod === PAYMENT_METHOD.BANK_TRANSFER} />} label={t('clients.bank')} />
                        </RadioGroup>
                    </FormControl>
                    <Typography sx={{ textDecoration: 'underline' }} gutterBottom variant="h6" pt={2} color="error">{t('clients.cannotBeUndone')}</Typography>
                </Grid>
                <Grid item xs={12} pt={3}>
                    <Grid container display="flex" flexDirection="row" spacing={3}>
                        <Grid item xs={12} md={12} lg={6}>
                            <Button style={{ minHeight: 45 }} onClick={onCancel} fullWidth variant="outlined">
                                {t('clients.cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <Button
                                style={{ minHeight: 45 }}
                                onClick={confirmPayment}
                                fullWidth
                                disabled={loading}
                                color={'success'}
                                variant="contained"
                            >
                                {t('clients.confirmPay')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default Confirm
