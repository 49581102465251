/* eslint-disable no-unused-vars */
import { useState } from 'react'
import { Close } from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
  DialogActions,
  IconButton
} from '@mui/material'

import { InputGenerator } from '../../common'
import { useTranslation } from 'react-i18next'

const CreateMeasurements = ({ handleClose, handleCreateMeasurementType }) => {
  const { t } = useTranslation()
  const [name, setName] = useState()

  const handleAdd = () => {
    const objectToAdd = {
      name
    }
    handleCreateMeasurementType(objectToAdd)
  }

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth={'sm'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        p={4}
        pl={{ xs: 2, md: 2, lg: 7 }}
        pr={{ xs: 2, md: 2, lg: 3 }}
      >
        <Grid container display="flex" flexDirection="row">
          <Grid item xs={12} display="flex" flexDirection="row" justifyContent="flex-end">
            <IconButton style={{ color: 'black' }} onClick={handleClose}>
              <Close fontSize="medium" color="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container display="flex" flexDirection="column" p={{ lg: 1, xs: 0 }}>
          <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" pb={1}>
            <Typography variant="h4" fontWeight="bold">
              {t('measurements.addMeasurementsType')}
            </Typography>
          </Grid>
          <Grid item xs={12} pt={6}>
            <Grid container display="flex" flexDirection="row">
              <Grid item xs={12} pr={1}>
                <InputGenerator
                  handleOnChange={(event) => setName(event.target.value)}
                  input={{
                    type: 'text',
                    label: t('measurements.name'),
                    mandatory: true,
                    placeholder: t('measurements.enterName'),
                    value: name
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: 4 }}>
        <Grid
          container
          display="flex"
          flexDirection={{ lg: 'row', xs: 'column' }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item pr={{ xs: 0, lg: 1 }}>
            <Button sx={{ minWidth: 260 }} variant="outlined" color="primary" onClick={handleClose}>
              {t('measurements.cancel')}
            </Button>
          </Grid>
          <Grid item pl={{ xs: 0, lg: 1 }} mt={{ lg: 0, xs: 1 }}>
            <Button
              disabled={!name}
              sx={{ minWidth: 260 }}
              variant="contained"
              color="primary"
              onClick={handleAdd}
            >
              {t('measurements.add')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default CreateMeasurements
