/* eslint-disable no-unused-vars */
import { Close, Info } from '@mui/icons-material'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    Box,
    IconButton,
    Chip,
    Tooltip,
} from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SUBSCRIPTION_PAYMENT_STATUS } from '../../../utils/constants'

const SubscriptionSelectorModal = ({ handleClose, handleConfirm, event, subscriptions }) => {

    const { t } = useTranslation()

    const [checkOnlyOneSub, setCheckOnlyOneSub] = useState(false)

    useEffect(() => {
        const subscriptionsToCheck = subscriptions.filter(sub => {
            const dayLimit = moment(sub.validFrom).utc().add(Number(sub.Subscription.paymentDeadline) - 1, 'days').endOf('day')

            return (computeStatus(sub, event.fullStart) === "ACTIVE" && moment(event.fullStart).utc().diff(moment(sub.validFrom)) > 0 && moment(event.fullStart).utc().isBefore(moment(sub.validUntil)) && !sub.isDeleted) ||
                (computeStatus(sub, event.fullStart) === "INACTIVE" && (!sub.Subscription.confirmation ? dayLimit.diff(moment(event.fullStart).utc()) >= 0 && moment(event.fullStart).utc().date() >= moment(sub.validFrom).utc().date() : false))
        })
        if (subscriptionsToCheck.length === 1) {
            const invalidSubscription = subscriptionsToCheck[0].limitedActivationTotal && (subscriptions[0].limitedActivationUsed + event.value > subscriptions[0].limitedActivationTotal)
            if (!invalidSubscription) {
                handleConfirm(subscriptionsToCheck[0].id)
            }
        }
        setCheckOnlyOneSub(true)
    }, [subscriptions, event])

    const computeStatus = (subscription, eventStartDate) => {
        let currentTime = new Date().getTime()

        if (eventStartDate) {
            if (subscription.Pause && (moment(eventStartDate).utc().isBetween(moment(subscription.Pause.startDate).utc(), moment(subscription.Pause.endDate).utc(), 'day', '[]'))) {
                return "PAUSED"
            }
        }

        if (!eventStartDate && subscription.Pause && (currentTime >= new Date(subscription.Pause.startDate).getTime() && currentTime <= new Date(subscription.Pause.endDate).getTime())) {
            return "PAUSED"
        }

        if (moment(eventStartDate ? eventStartDate : null).utc().isAfter(moment(subscription.validUntil).utc())) {
            return "EXPIRED"
        }

        if (subscription.status !== SUBSCRIPTION_PAYMENT_STATUS.CONFIRMED) {
            return "INACTIVE"
        }

        return "ACTIVE"
    }

    if (!checkOnlyOneSub) return null

    return (
        <Dialog
            open={true}
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                p={4}
            >
                <Grid container display="flex" flexDirection="row">
                    <Grid item xs={10}>
                        <Typography variant="h6"
                            color="#292838" fontWeight={600}>{t('calendar.selectSubscription')}</Typography>
                    </Grid>
                    <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                        <IconButton style={{ color: 'black' }} onClick={handleClose}>
                            <Close fontSize="medium" color="inherit" />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body2" fontWeight={600}>{t('calendar.inOrderToBook')} {event.value} {t('calendar.credits').toLowerCase()}.</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }} pt={2}>
                    {subscriptions ? subscriptions.filter(sub => {
                        const dayLimit = moment(sub.validFrom).utc().add(Number(sub.Subscription.paymentDeadline) - 1, 'days').endOf('day')

                        return (computeStatus(sub, event.fullStart) === "ACTIVE" && moment(event.fullStart).utc().diff(moment(sub.validFrom)) > 0 && moment(event.fullStart).utc().isBefore(moment(sub.validUntil)) && !sub.isDeleted) ||
                            (computeStatus(sub, event.fullStart) === "INACTIVE" && (!sub.Subscription.confirmation ? dayLimit.diff(moment(event.fullStart).utc()) >= 0 && moment(event.fullStart).utc().date() >= moment(sub.validFrom).utc().date() : false))
                    }).map((subscription, index) => {
                        const disabledValue = subscription.limitedActivationTotal && (subscription.limitedActivationUsed + event.value > subscription.limitedActivationTotal)
                        if (disabledValue) return null
                        return (
                            <Grid container display="flex" flexDirection="row" key={index} mb={2}>
                                <Grid item xs={10} display="flex" alignItems="center">
                                    <Typography fontWeight={500} variant="body1">{subscription.Subscription.name}</Typography>
                                    <Typography fontWeight={500} variant="body2" pt={0.5} pl={1} pr={1}>{!subscription.limitedActivationTotal ? '' : subscription.limitedActivationUsed} {!subscription.limitedActivationTotal ? '' : '/'} {!subscription.limitedActivationTotal ? '' : subscription.limitedActivationTotal}</Typography>
                                    {disabledValue && <Tooltip placement='top' title={t('calendar.notEnoughCredits')}><Info color="error" /></Tooltip>}
                                </Grid>
                                <Grid item xs={2} ml="auto">
                                    <Chip label={<Typography fontWeight={500}>{t('calendar.select')}</Typography>} variant="outlined" color="primary" disabled={disabledValue} onClick={() => handleConfirm(subscription.id)} />
                                </Grid>
                            </Grid>
                        )
                    }) : <Typography color="#979797" fontWeight={500} fontSize={17}>
                        No results found
                    </Typography>}
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default SubscriptionSelectorModal
