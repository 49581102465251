import React, { useMemo, useState } from 'react'
import { Grid, Card, Typography, Button, Rating, Box, Avatar, Tooltip, Slide } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { CheckCircle } from '@mui/icons-material'
import parse from 'html-react-parser'

import {
  LIGHT_GRAY_BORDER_COLOR,
  CARD_TEXT_COLOR,
  GRAY_TEXT_COLOR,
  PRIMARY_COLOR,
  GRAY_BG_COLOR,
  BUSINESS_ROLES
} from '../../../utils/constants'

import EditProfile from './subcomponents/EditProfile'
import { useAuthentication } from '../../../context/auth-context'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  avatarContainer: {
    borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  credibilityContainer: {
    borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  borderRight: {
    borderRight: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  performanceText: {
    color: GRAY_TEXT_COLOR
  },
  memberFrom: {
    color: CARD_TEXT_COLOR
  }
})

const BusinessDetails = ({ company, onEditCloseHandler }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useAuthentication()

  const [fullBio, setFullBio] = useState(false)
  const [showEditProfile, setShowEditProfile] = useState(false)

  const isClubAdministrator =
    user && user.role && user.role.toLowerCase() === BUSINESS_ROLES.BUSINESS_EMPLOYEE.toLowerCase()

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })

  const renderEditProfile = useMemo(() => {
    if (!showEditProfile) return null

    return (
      <EditProfile
        onClose={() => {
          setShowEditProfile(false)
          onEditCloseHandler()
        }}
        Transition={Transition}
      />
    )
  }, [showEditProfile, company])

  const renderGymReviews = () => {

    const score = Math.ceil(company?.reviewsAverage > 5 ? 5 : company?.reviewsAverage < 1 ? 1 : company?.reviewsAverage);

    return (
      <>
        <Rating sx={{ color: PRIMARY_COLOR }} disabled value={score} />
        <Typography pl={1} fontWeight="bold" display="flex" flexDirection="row">
          {score}
          <strong style={{ color: CARD_TEXT_COLOR }}>/5</strong>
        </Typography>
      </>
    )
  }

  const renderBusinessDetails = useMemo(() => {
    return (
      <Grid container className={classes.content} component={Card} flexDirection="column">
        <Grid container pt={3} width="100%" className={classes.avatarContainer}>
          <Grid
            xs={12}
            pl={2}
            pr={2}
            item
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {company.avatarPhotoSrc ? (
              <Avatar
                src={company.avatarPhotoSrc}
                alt="avatar"
                style={{ height: 100, width: 100 }}
              />
            ) : (
              <Box
                sx={{
                  width: 100,
                  height: 100,
                  backgroundColor: GRAY_BG_COLOR,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 50
                }}
              >
                <Typography fontWeight="bold" color={GRAY_TEXT_COLOR} variant="h4">
                  {company.BusinessOwner.firstName.charAt(0)}{' '}
                  {company.BusinessOwner.lastName.charAt(0)}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            pt={1}
            pl={3}
            pr={3}
            flexDirection="row"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Tooltip title={company.name}>
              <Typography variant="h5" pr={1} fontWeight="bold" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                {company.name}
              </Typography>
            </Tooltip>
            {company.registrationCertificateApproved && company.identityCardApproved ? (
              <CheckCircle color="primary" />
            ) : null}
          </Grid>
          <Grid item xs={12} pb={3} display="flex" alignItems="center" justifyContent="center">
            <Typography variant="h6" fontWeight={500} color={CARD_TEXT_COLOR}>
              {t('gymProfile.profile.description')}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            pl={2}
            pr={2}
            pb={2}
            flexDirection="row"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {renderGymReviews()}
          </Grid>
        </Grid>

        <Grid className={classes.credibilityContainer} container>
          <Grid
            item
            xs={6}
            pl={3}
            pr={2}
            pb={2}
            pt={2}
            className={classes.borderRight}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography className={classes.performanceText}>
              {t('gymProfile.profile.performance')}
            </Typography>
            <Grid pt={0.5} item display="flex" flexDirection="row">
              <Typography color="primary" fontWeight={500} variant="h4">
                {Number(company.performanceScore).toFixed(0)}
              </Typography>
              <Typography fontWeight={500}>/10</Typography>
            </Grid>
          </Grid>
          <Grid
            pl={3}
            pr={2}
            pb={2}
            pt={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            item
            xs={6}
          >
            <Typography
              display="flex"
              justifyContent="flex-end"
              className={classes.performanceText}
            >
              {t('gymProfile.profile.followers')}
            </Typography>
            <Grid pt={0.5} item display="flex" flexDirection="row" justifyContent="flex-end">
              <Typography
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                color="primary"
                variant="h4"
                fontWeight={500}
              >
                {company.followersCount || 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          pt={2}
          pb={2}
          pl={2}
          pr={2}
          borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
        >
          <Grid item xs={12} flexDirection="column" display="flex" className="bio">
            <Typography
              fontWeight={500}
              variant="subtitle2"
              color={`${company.bio ? 'black' : '#979797'}`}
            >
              {company.bio
                ? parse(fullBio
                  ? company.bio
                  : company.bio.slice(0, 150))
                : t('helper.noInformation')}
            </Typography>
            <Typography
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              fontWeight={'bold'}
              color="primary"
              onClick={() => {
                setFullBio(!fullBio)
              }}
              variant="subtitle2"
            >
              {company.bio ? (fullBio ? t('userProfile.showLess') : t('userProfile.readMore')) : ''}
            </Typography>
          </Grid>
        </Grid>

        <Grid container p={2} pb={0}>
          <Typography gutterBottom fontWeight="bold" variant="subtitle1">
            {t('gymProfile.profile.location')}
          </Typography>
          <Grid item xs={12} flexDirection="row" display="flex">
            <a
              style={{ textDecoration: 'none' }}
              href={`https://www.google.com/maps/search/?api=1&query=${company.locationName}`}
              rel="noreferrer"
              target="_blank"
            >
              <Typography
                color="primary"
                fontWeight={600}
                variant="subtitle2"
                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                {company.locationName}
              </Typography>
            </a>
          </Grid>
        </Grid>

        <Grid container p={2}>
          <Typography gutterBottom fontWeight="bold" variant="subtitle1">
            {t('gymProfile.profile.schedule')}
          </Typography>
          <Grid item xs={12} flexDirection="row" display="flex">
            <Grid container display="flex" flexDirection="row">
              <Grid item xs={2}>
                <Typography color={CARD_TEXT_COLOR} variant="subtitle2">
                  L-V{' '}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography fontWeight={500} variant="subtitle2">
                  {company.mondayFridayStartProgram && company.mondayFridayEndProgram ? (
                    <>
                      {company.mondayFridayStartProgram} - {company.mondayFridayEndProgram}
                    </>
                  ) : (
                    t('businessEditProfile.closed')
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} flexDirection="row" display="flex">
            <Grid item xs={2}>
              <Typography color={CARD_TEXT_COLOR} variant="subtitle2">
                S
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography fontWeight={500} variant="subtitle2">
                {company.saturdayStartProgram && company.saturdayEndProgram ? (
                  <>
                    {company.saturdayStartProgram} - {company.saturdayEndProgram}
                  </>
                ) : (
                  t('businessEditProfile.closed')
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} flexDirection="row" display="flex">
            <Grid item xs={2}>
              <Typography color={CARD_TEXT_COLOR} variant="subtitle2">
                D
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography fontWeight={500} variant="subtitle2">
                {company.sundayStartProgram && company.sundayEndProgram ? (
                  <>
                    {company.sundayStartProgram} - {company.sundayEndProgram}
                  </>
                ) : (
                  t('businessEditProfile.closed')
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item display="flex" justifyContent="center" alignItems="center">
          <Grid pt={2} pb={4} xs={6} item>
            {!isClubAdministrator && <Button fullWidth variant="outlined" onClick={() => setShowEditProfile(true)}>
              {t('userProfile.editProfile')}
            </Button>}
          </Grid>
        </Grid>
      </Grid>
    )
  }, [company, t, fullBio])

  return (
    <>
      {renderEditProfile}
      {renderBusinessDetails}
    </>
  )
}

export default BusinessDetails
