import axios from 'axios'

const instance = axios.create({ withCredentials: true })

export const open = async (openUrl, closeUrl) => {
    try {
        await instance.get(closeUrl)
    } catch (error) {

    }

    try {
        instance.get(openUrl)
    } catch (error) {

    }

    return true
}