import React, { useMemo, useState, useCallback } from 'react'
import { Grid, Typography, Paper, IconButton, Slide } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ArrowForwardIos } from '@mui/icons-material'
import moment from 'moment'
import { toast } from 'react-toastify';

import { GRAY_TEXT_COLOR, SUBSCRIPTION_PAYMENT_STATUS, } from '../../../utils/constants'

import * as CALENDAR_REQUESTS from '../../../api/calendar'
import * as MESSAGE_REQUESTS from '../../../api/message'

import { CalendarEditEvent, CalendarEventDetailsModal, CalendarItem, ListOfTrainersModal, PaymentEventModal, SubscriptionSelectorModal, WaitingListModal } from '../../Calendar/subcomponents'
import MessageCompanyDialog from '../../PublicBusinessProfile/subcomponents/subcomponents/subcomponents/MesssageDialog'
import DeleteEventDialog from '../../Calendar/subcomponents/DeleteEventModal'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Events = ({ events, userSubscriptions, loading, count, onNextPage, setEvents, getEvents }) => {
  const { t } = useTranslation()

  const computeStatus = (subscription, eventStartDate) => {
    let currentTime = new Date().getTime()

    if (eventStartDate) {
      if (subscription.Pause && (moment(eventStartDate).utc().isBetween(moment(subscription.Pause.startDate).utc(), moment(subscription.Pause.endDate).utc(), 'day', '[]'))) {
        return "PAUSED"
      }
    }

    if (!eventStartDate && subscription.Pause && (currentTime >= new Date(subscription.Pause.startDate).getTime() && currentTime <= new Date(subscription.Pause.endDate).getTime())) {
      return "PAUSED"
    }

    if (moment(eventStartDate ? eventStartDate : null).utc().isAfter(moment(subscription.validUntil).utc())) {
      return "EXPIRED"
    }

    if (subscription.status !== SUBSCRIPTION_PAYMENT_STATUS.CONFIRMED) {
      return "INACTIVE"
    }

    return "ACTIVE"
  }

  const [loadingBookUnbook, setLoadingBookUnbook] = useState(false)
  const [editEventModal, setEditEventModal] = useState({
    open: false,
    eventId: null
  })
  const [eventDetailsModal, setEventDetailsModal] = useState({
    open: false,
    eventId: null
  })
  const [deleteEventModal, setDeleteEventModal] = useState({
    open: false,
    eventId: null
  })
  const [messageModal, setMessageModal] = useState({
    open: false,
    name: '',
    type: '',
    id: null
  })
  const [waitingListModal, setWaitingListModal] = useState({
    open: false,
    event: null,
    availablePlaces: 0
  })
  const [paymentModal, setPaymentModal] = useState({
    open: false,
    eventId: null,
    type: '',
    price: 0
  })
  const [trainersModal, setTrainersModal] = useState({
    open: false,
    trainers: []
  })
  const [subscriptionSelector, setSubscriptionSelector] = useState({
    open: false,
    eventSubscriptions: [],
    event: null
  })

  const handleEventDetails = useCallback((eventDetails) => {
    setEventDetailsModal({
      open: true,
      eventId: eventDetails.id
    })
  }, [])

  const handleOpenDelete = useCallback(eventId => {
    setDeleteEventModal({
      open: true,
      eventId
    })
  }, [])


  const handleOpenMessage = useCallback((name, id, type, trainers, directly) => {
    setEventDetailsModal({
      open: false,
      eventId: null
    })
    if (directly) {
      setMessageModal({
        open: true,
        name,
        id,
        type
      })
    }
    else {
      setTrainersModal({
        open: true,
        type: 'message',
        title: t('calendar.sendMessageTo'),
        trainers: trainers
      })
    }
  }, [])

  const handleOpenBuyACoffee = useCallback((trainers, paymentLink, directly) => {
    setEventDetailsModal({
      open: false,
      eventId: null
    })
    if (directly) {
      window.open(paymentLink)
    }
    else {
      setTrainersModal({
        open: true,
        type: 'coffee',
        title: t('calendar.sendCoffeeTo'),
        trainers: trainers
      })
    }
  }, [])

  const handleDelete = useCallback(async () => {
    try {
      await CALENDAR_REQUESTS.deleteEvent(deleteEventModal.eventId)
      setEventDetailsModal({ open: false, eventId: null })
      setDeleteEventModal({ open: false, eventId: null })
      getEvents(userSubscriptions)
    } catch (e) {
      setDeleteEventModal({ open: false, eventId: null })
      console.error(e)
    }
  }, [deleteEventModal, userSubscriptions])

  const handleEdit = useCallback(eventId => {
    setEditEventModal({
      open: true,
      eventId
    })
    setEventDetailsModal({
      open: false,
      eventId: null
    })
  }, [])

  const handleOpenWaitingList = useCallback(event => {
    setWaitingListModal({
      open: true,
      event: event,
      availablePlaces: event.maxWaitingList - (event.inWaitingList ? event.inWaitingList : event.EventsParticipants.filter(el => el.Events_Participants.isInWaitingList).length)
    })
  })

  const handleOpenPayment = useCallback(event => {
    setPaymentModal({
      open: true,
      eventId: event.id,
      type: event.payment,
      price: event.value
    })
  })

  const handleSendMessage = async (title, message) => {
    try {
      if (messageModal.type === 'company') {
        await MESSAGE_REQUESTS.sendMessageToCompany(messageModal.id, { title, message })
      }
      else {
        await MESSAGE_REQUESTS.sendMessageToUser(messageModal.id, { title, message })
      }
      setMessageModal({
        open: true,
        name: '',
        id: null,
        type: ''
      })
      toast.success(t('calendar.messageSent'), { position: 'bottom-right' })
    } catch (e) {
      toast.error(t('calendar.messageError'), { position: 'bottom-right', autoClose: false })
      setMessageModal({
        open: true,
        name: '',
        id: null,
        type: ''
      })
      console.error(e)
    }
  }

  const handleBook = useCallback(async (eventId, refetch, userSubscriptionId) => {
    try {
      setLoadingBookUnbook(eventId)
      await CALENDAR_REQUESTS.bookEvent(eventId, userSubscriptionId)
      if (refetch) {
        getEvents(userSubscriptions)
      }
      setLoadingBookUnbook(false)
      toast.success(t('calendar.bookSuccess'), { position: 'bottom-right' })
    } catch (e) {
      setLoadingBookUnbook(false)
      toast.error(t('calendar.bookError'), { position: 'bottom-right', autoClose: false })
      console.error(e)
    }
  }, [userSubscriptions])

  const handleUnbook = useCallback(async (eventId, refetch) => {
    try {
      setLoadingBookUnbook(true)
      await CALENDAR_REQUESTS.unbookEvent(eventId)
      if (refetch) {
        getEvents(userSubscriptions)
      }
      setLoadingBookUnbook(false)
      toast.warn(t('calendar.unbookSuccess'), { position: 'bottom-right' })
    } catch (e) {
      setLoadingBookUnbook(false)
      toast.error(t('calendar.unbookError'), { position: 'bottom-right', autoClose: false })
      console.error(e)
    }
  }, [userSubscriptions])

  const handleBookEventWithOneSubscription = useCallback(async (eventToUse, userSubscriptionToUse) => {
    const userSubscription = userSubscriptionToUse.filter(el => {
      return computeStatus(el, eventToUse.fullStart) !== 'EXPIRED' && computeStatus(el, eventToUse.fullStart) !== 'PAUSED'
    }).find(el => el.Subscription.id === eventToUse.EventSubscriptions[0].id)
    if (!userSubscription || (userSubscription.limitedActivationTotal && (userSubscription.limitedActivationUsed + eventToUse.value > userSubscription.limitedActivationTotal))) {
      toast.error(t('calendar.notEnoughCredits'), { position: 'bottom-right', autoClose: false })
      return
    }

    await handleBook(eventToUse.id, true, userSubscription.id)

  }, [userSubscriptions])

  const handleOpenSubscriptionSelector = useCallback((eventToUse, eventSubscriptions) => {
    const availableSubscriptionsToUse = userSubscriptions.filter(el => eventSubscriptions.map(sub => sub.id).includes(el.Subscription.id))
    if (availableSubscriptionsToUse.length === 1) {
      handleBook(eventToUse.id, true, availableSubscriptionsToUse[0].id)
    }
    else {
      setSubscriptionSelector({
        open: true,
        eventSubscriptions,
        event: eventToUse
      })
    }
  }, [userSubscriptions])

  const renderContent = useMemo(() => {
    return events.map((row, key) => {
      return (
        <Grid item>
          <CalendarItem
            key={key}
            item={row}
            handleEventDetails={handleEventDetails}
            handleOpenPayment={handleOpenPayment}
            handleOpenWaitingList={handleOpenWaitingList}
            handleBook={handleBook}
            handleUnbook={handleUnbook}
            handleOpenSubscriptionSelector={handleOpenSubscriptionSelector}
            handleBookEventWithOneSubscription={(eventToUse) => handleBookEventWithOneSubscription(eventToUse, userSubscriptions)}
          />
        </Grid>
      )
    })
  }, [events, setEvents, userSubscriptions])

  const renderMore = () => {
    return (
      <Grid item xs={12} lg={3} onClick={onNextPage}>
        <Grid
          container
          component={Paper}
          elevation={0}
          style={{ height: 225, cursor: 'pointer', boxShadow: '0 13px 33px 0 rgb(41 40 56 / 8%)' }}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <IconButton>
            <ArrowForwardIos fontSize="medium" style={{ color: GRAY_TEXT_COLOR }} />
          </IconButton>
          <Typography variant="subtitle1" fontWeight={500} color={GRAY_TEXT_COLOR}>
            {t('explore.showMore')}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const renderEventDetailsModal = useMemo(() => {
    if (!eventDetailsModal.open || !eventDetailsModal.eventId) return null
    return (
      <CalendarEventDetailsModal
        loadingBookUnbook={loadingBookUnbook}
        eventId={eventDetailsModal.eventId}
        userSubscriptionsToUse={userSubscriptions}
        handleClose={() => {
          setEventDetailsModal({ open: false, eventId: null })
          getEvents(userSubscriptions)
        }}
        handleDelete={handleOpenDelete}
        handleEdit={handleEdit}
        handleMessage={handleOpenMessage}
        handleBuyACoffee={handleOpenBuyACoffee}
        handleOpenPayment={handleOpenPayment}
        handleOpenWaitingList={handleOpenWaitingList}
        handleOpenSubscriptionSelector={handleOpenSubscriptionSelector}
        handleBookEventWithOneSubscription={(eventToUse) => handleBookEventWithOneSubscription(eventToUse, userSubscriptions)}
        handleBook={handleBook}
        handleUnbook={handleUnbook}
        Transition={Transition}
      />
    )
  }, [eventDetailsModal, loadingBookUnbook, userSubscriptions])

  const renderDeleteEventModal = useMemo(() => {
    if (!deleteEventModal.open || !deleteEventModal.eventId) return null
    return (
      <DeleteEventDialog
        handleDelete={handleDelete}
        handleClose={() => {
          setDeleteEventModal({ open: false, eventId: null })
        }}
      />
    )
  }, [deleteEventModal])

  const renderMessageModal = useMemo(() => {
    if (!messageModal.open || !messageModal.id || !messageModal.name || !messageModal.type) return null
    return (
      <MessageCompanyDialog
        handleSend={handleSendMessage}
        handleClose={() => setMessageModal({
          open: false,
          name: '',
          id: null,
          type: ''
        })}
        companyName={`${messageModal.name}`}
      />
    )
  }, [messageModal])

  const renderWaitingListModal = useMemo(() => {
    if (!waitingListModal.open || !waitingListModal.event) return null
    return (
      <WaitingListModal
        availablePlaces={waitingListModal.availablePlaces}
        handleConfirm={() => {
          if (waitingListModal.event.cost === "CREDITS") {
            if (waitingListModal.event.EventSubscriptions.length > 1) {
              handleOpenSubscriptionSelector(waitingListModal.event, waitingListModal.event.EventSubscriptions)
            }
            else {
              handleBookEventWithOneSubscription(waitingListModal.event, userSubscriptions)
            }
          }
          else {
            handleBook(waitingListModal.event.id, true)
          }
          setWaitingListModal({
            open: false,
            event: null,
            availablePlaces: 0
          })
          setEventDetailsModal({
            open: false,
            eventId: null
          })
        }}
        handleClose={() => setWaitingListModal({
          open: false,
          event: null,
          availablePlaces: 0
        })}
      />
    )
  }, [waitingListModal, userSubscriptions])


  const renderPaymentModal = useMemo(() => {
    if (!paymentModal.open || !paymentModal.eventId) return null
    return (
      <PaymentEventModal
        type={paymentModal.type}
        price={paymentModal.price}
        handleConfirm={() => {
          handleBook(paymentModal.eventId, true)
          setPaymentModal({
            open: false,
            eventId: null,
            type: '',
            price: 0
          })
          setEventDetailsModal({
            open: false,
            eventId: null
          })
        }}
        handleClose={() => setPaymentModal({
          open: false,
          eventId: null,
          type: '',
          price: 0
        })}
      />
    )
  }, [paymentModal])

  const renderTrainersModal = useMemo(() => {
    if (!trainersModal.open) return null
    return (
      <ListOfTrainersModal
        title={trainersModal.title}
        trainers={trainersModal.trainers}
        type={trainersModal.type}
        handleConfirm={(name, id, type, paymentLink) => {
          setTrainersModal({
            open: false,
            title: '',
            type: '',
            trainers: []
          })
          if (trainersModal.type === 'coffee') {
            window.open(paymentLink)
          }
          else {
            setMessageModal({
              open: true,
              name,
              id,
              type
            })
          }
        }}
        handleClose={() => setTrainersModal({
          open: false,
          title: '',
          type: '',
          trainers: []
        })}
      />
    )
  }, [trainersModal])

  const renderEditEventModal = useMemo(() => {
    if (!editEventModal.open || !editEventModal.eventId) return null
    return (
      <CalendarEditEvent
        handleClose={() => {
          setEditEventModal({ open: false, eventId: null })
          getEvents(userSubscriptions)
        }}
        eventId={editEventModal.eventId}
        Transition={Transition}
      />
    )
  }, [editEventModal, userSubscriptions])

  const renderSubscriptionSelectorModal = useMemo(() => {
    if (!subscriptionSelector.open || !subscriptionSelector.event) return null
    return (
      <SubscriptionSelectorModal
        subscriptions={userSubscriptions.filter(el => subscriptionSelector.eventSubscriptions.map(sub => sub.id).includes(el.Subscription.id))}
        event={subscriptionSelector.event}
        handleConfirm={(userSubscriptionId) => {
          handleBook(subscriptionSelector.event.id, true, userSubscriptionId)
          if (eventDetailsModal.open) {
            setEventDetailsModal({
              open: false,
              eventId: null
            })
          }
          setSubscriptionSelector({
            open: false,
            eventSubscriptions: [],
            event: null
          })
        }}
        handleClose={() => setSubscriptionSelector({
          open: false,
          eventSubscriptions: [],
          event: null
        })}
      />
    )
  }, [subscriptionSelector, eventDetailsModal, userSubscriptions])

  return (
    <Grid item xs={12}>
      {renderEventDetailsModal}
      {renderMessageModal}
      {renderDeleteEventModal}
      {renderWaitingListModal}
      {renderPaymentModal}
      {renderEditEventModal}
      {renderTrainersModal}
      {renderSubscriptionSelectorModal}
      <Grid display="flex" flexDirection="row" container>
        <Grid item xs={12} lg={12}>
          <Typography fontWeight="bold" variant="h5">
            {events.length && count ? count : null} {t('calendar.events')}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ flexWrap: 'wrap' }}
        display="flex"
        flexDirection="row"
        spacing={2}
      >
        {!loading && !events.length ? (
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight={500} color={GRAY_TEXT_COLOR}>
              {t('explore.noResult')}
            </Typography>
          </Grid>
        ) : null}
        {events.length ? renderContent : null}
        {events.length && events.length < count ? renderMore() : null}
      </Grid>
    </Grid>
  )
}

export default Events
