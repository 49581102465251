import React from 'react'
import { Box, Typography } from '@mui/material'

import { GRAY_BG_COLOR, GRAY_TEXT_COLOR } from '../../utils/constants'

const AvatarNoImage = ({ name, firstName, lastName, width, height, typographyVariant }) => {
  if (!name && !firstName && !lastName) return null

  if (!name && (!firstName || !lastName)) return null

  return (
    <Box
      sx={{
        width: width || 100,
        height: height || 100,
        backgroundColor: GRAY_BG_COLOR,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%'
      }}
    >
      <Typography
        variant={typographyVariant ? typographyVariant : 'h4'}
        color={GRAY_TEXT_COLOR}
        fontWeight={500}
      >
        {name
          ? name.length > 0
            ? name.charAt(0)
            : '' + ' ' + name.length > 1
            ? name.charAt(1)
            : ''
          : (firstName.length > 0 ? firstName.charAt(0) : '') +
            ' ' +
            (lastName.length > 0 ? lastName.charAt(0) : '')}
      </Typography>
    </Box>
  )
}

export default AvatarNoImage
