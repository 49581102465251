import React, { useMemo } from 'react';
import { Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DEALS_IMG from '../../../assets/deals.png';
import CHALLENGES_IMG from '../../../assets/challenges.png';

import { BLACK, CHALLENGES, DEALS } from '../../../utils/constants';
import { TypeCard } from '../../SignUp/subcomponents';
import CustomTypography from '../../../utils/FontLato';

const Form = ({ handleOnSelect, handleNext, type }) => {
    const { t } = useTranslation();

    const types = useMemo(() => [
        {
            name: t(`type.${DEALS.toLowerCase()}`),
            subtitle: t('signup.deals.subtitle'),
            content: t('signup.deals.content'),
            titleRight: t('signup.deals.title')
        },
        {
            name: t(`type.${CHALLENGES.toLowerCase()}`),
            subtitle: t('signup.challenges.subtitle'),
            content: t('signup.challenges.content'),
            titleRight: t('signup.challenges.title')
        }
    ], [t]);

    const renderForm = useMemo(() => {
        return (
            <Grid xs={12} display="flex" direction="row" alignItems='center' lg={6} height={{ lg: '90%', xs: '70%' }}>
                <Grid display="flex" height={'100%'} direction="column" alignItems='center' justifyContent={{ md: 'start', lg: 'center' }}>

                    <Grid display="flex" justifyContent="center" maxWidth={'1200'} maxHeight={'700px'}>
                        {
                            type === 'Deals' || type === "Oferte" ? <img
                                width='35%'
                                height={'auto'}
                                src={DEALS_IMG}
                                alt={type}
                            /> : <img
                                width='80%'
                                height={'auto'}
                                src={CHALLENGES_IMG}
                                alt={type}
                            />
                        }

                    </Grid>
                    <Grid display="flex" alignItems={'flex-start'} justifyContent="center" mb={type === 'Deals' ? "5%" : '0px'}>
                        <Grid item width={'80%'}>
                            <CustomTypography fontWeight="bold" mb={'2%'} variant="h4" style={{ color: BLACK, textAlign: 'center', wordBreak: 'break-word', font: 'normal normal 500 Lato' }}>
                                {type === 'Deals' || type === "Oferte" ? t('signup.deals.imgTitle') : t('signup.challenges.imgTitle')}
                            </CustomTypography>
                            <CustomTypography variant="h5" style={{ color: '#9D9D9D', textAlign: 'center', wordBreak: 'break-word' }}>
                                {type === 'Deals' || type === "Oferte" ? t('signup.deals.imgSubtitle') : t('signup.challenges.imgSubtitle')}
                            </CustomTypography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        )
    }, [type, t])

    return (
        <Grid container style={{ padding: 0, overflowX: 'hidden', overflowY: 'hidden' }}>
            <Grid item xs={12} lg={6} p={{ xs: 4, lg: 10 }} pt={{ xs: 4, lg: 0 }} pr={{ xs: 2, lg: 0 }}>
                <Grid container height="100%" alignItems="center">
                    <Grid container direction="column" justifyContent="center" rowSpacing={4}>
                        <Grid item display="flex" direction="column" justifyContent={'space-between'} mb={'12%'}>
                            <CustomTypography fontWeight="bold" variant="h4">
                                <span style={{ color: BLACK, fontWeight: "bold", font: 'normal normal 500 Lato' }}>
                                    {t('signup.dealsChallenges.title').split(' ')[0]}
                                </span>
                                <span style={{ color: '#bbb', fontWeight: "bold", font: 'normal normal 500 Lato' }}>
                                    {' '} {t('signup.dealsChallenges.title')
                                        .split(' ')
                                        .slice(1, -1)
                                        .join(' ')}
                                </span>
                                <span style={{ color: BLACK, fontWeight: "bold", font: 'normal normal 500 Lato' }}>
                                    {' '}{t('signup.dealsChallenges.title').split(' ').slice(-1)}
                                </span>
                            </CustomTypography>

                            <Grid item display="flex" direction="row" justifyContent={'space-between'}>
                                <CustomTypography
                                    variant="subtitle1"
                                    style={{ color: '#9D9D9D', wordBreak: 'break-word' }}>
                                    {t('signup.dealsChallenges.subtitle')}
                                </CustomTypography>
                            </Grid>
                        </Grid>
                        {types.map((el, index) => {
                            return (
                                <Grid key={`${el.name}-${index}`} item xs={12}>
                                    <TypeCard
                                        subtitleColor={'#00A83A'}
                                        titleRight={el.titleRight}
                                        onSelect={handleOnSelect}
                                        selected={type}
                                        type={el.name}
                                        subtitle={el.subtitle}
                                        content={
                                            <CustomTypography color={'#9D9D9D'} variant="body2">
                                                {el.content}
                                            </CustomTypography>
                                        }
                                    />
                                </Grid>
                            );
                        })}
                        <Grid item display="flex" flexDirection="row">
                            <Grid container display="flex" flexDirection="row" justifyContent={'flex-end'}>
                                <Grid item paddingLeft={{ lg: 4, xs: 1 }} xs={6} lg={4}>
                                    <Button
                                        fullWidth
                                        onClick={handleNext}
                                        style={{ backgroundColor: BLACK, color: '#fff' }}
                                        variant="contained"
                                    >
                                        {t('button.next')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {renderForm}
        </Grid>
    );
};

export default Form;

