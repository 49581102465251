import React from 'react'
import { Grid, Typography, Button } from '@mui/material'

import { CARD_TEXT_COLOR, PRIMARY_COLOR } from '../../../utils/constants'
import { BASE_URL } from '../../../utils/config'

import CHECKOUT_SUCCESS from '../../../assets/checkout-success.gif'
import QUOTE from '../../../assets/quote.png'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const SuccessCheckout = ({
  computeName,
  computeOwnerName,
  computeDomain,
  computePhoneNumber,
  computeEmail
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <Grid
      container
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      pt={6}
      pb={6}
      pl={3}
      pr={3}
    >
      <Grid item xs={12}>
        <img height={200} width={200} src={CHECKOUT_SUCCESS} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" fontWeight={'bold'} color="primary">
          {t('checkout.congrats')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" pt={3}>
          {t('checkout.weSuccessfully')} {computeName()} {t('checkout.from')} {computeOwnerName()} {t('checkout.toYourAccount')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {t('checkout.allEvents')}
        </Typography>
      </Grid>
      <Grid item xs={12} pt={3}>
        <Typography variant="h6">{t('checkout.needHelp')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          {t('checkout.ifYouHave')}{' '}
          <a href={`${BASE_URL}/${computeDomain()}`}>{computeOwnerName()}</a> {t('checkout.at')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          {computeEmail() || '-'} {t('checkout.or')} {computePhoneNumber() || '-'}
        </Typography>
      </Grid>
      <Grid item xs={12} pt={3}>
        <img height={50} width={50} src={QUOTE} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" color={CARD_TEXT_COLOR}>
          {t('checkout.dontWait')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" color={CARD_TEXT_COLOR}>
          {t('checkout.beProud')}
        </Typography>
      </Grid>
      <Grid item xs={12} pt={2} width={{ xs: '100%', md: '25%' }}>
        <Button onClick={() => navigate(`/calendar/${computeDomain()}`)} fullWidth variant="contained" color="primary">
          {t('checkout.goToCalendar')}
        </Button>
      </Grid>
      <Grid item xs={12} pt={9}>
        <Typography variant="body1">{t('checkout.thanks')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <a href="https://www.facebook.com/groups/xtrainer/" style={{ color: PRIMARY_COLOR }}>
          {t('checkout.join')}
        </a>
      </Grid>
    </Grid>
  )
}

export default SuccessCheckout
