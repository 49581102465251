import { Typography, MenuItem, Menu, Divider } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import usePayouts from '../../../../hooks/usePayouts';
import FileSaver from 'file-saver';
import { decode } from 'base64-arraybuffer';
import { GREEN_COLOR, PRIMARY_COLOR } from '../../../../utils/constants';

const Actions = ({ anchor, open, onClose, selectedMonth, isAbleToDownloadFeesInvoices, isAbleToDownloadPaymentsInvoices }) => {
    const { t } = useTranslation();
    const { downloadFeesInvoice, downloadPaymentsInvoice, exportCSV } = usePayouts();

    const handleFeesInvoice = async () => {
        const response = await downloadFeesInvoice({ month: selectedMonth });
        const outputFilename = `Fees_Invoice_SportIn.pdf`;

        if (!response?.file) return toast.error(response, { position: 'bottom-right' });

        FileSaver.saveAs(new Blob([decode(response.file)]), outputFilename)
    };

    const handlePaymentsInvoice = async () => {
        const response = await downloadPaymentsInvoice({ month: selectedMonth });
        const outputFilename = `Payments_Invoice_SportIn.pdf`;

        if (!response?.file) return toast.error(response, { position: 'bottom-right' });

        FileSaver.saveAs(new Blob([decode(response.file)]), outputFilename)
    };

    // const handleExportCSV = async () => {
    //     const response = await exportCSV(selectedMonth);
    //     const outputFilename = `CSV_SportIn.csv`;

    //     if (!response?.file) return toast.error(response, { position: 'bottom-right' });

    //     FileSaver.saveAs(new Blob([decode(response.file)]), outputFilename)
    // };

    return (
        <Menu
            anchorEl={anchor}
            open={open}
            onClose={onClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            sx={{
                '& .css-1qnhf47-MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
                    border: 0,
                    width: "11%",
                    backgroundColor: '#0e0d0d00',
                    borderRadius: 3
                },
                '& .css-1gxnd98-MuiList-root-MuiMenu-list': {
                    border: '1px solid #DADCE0',
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    borderRadius: 3,
                }
            }}
        >
            <Typography marginLeft={2} marginBottom={1}>{t('payouts.actions.title')}</Typography>
            <Divider />
            {/* <MenuItem sx={{ display: 'flex', gap: 0.5 }} onClick={handleExportCSV}>
                <DescriptionIcon sx={{ width: '1vw' }} />
                <Typography>{t('payouts.actions.export')}</Typography>
            </MenuItem> */}
            <MenuItem sx={{ display: 'flex', gap: 0.5 }} disabled={!isAbleToDownloadFeesInvoices} onClick={handleFeesInvoice}>
                <CreditCardIcon sx={{ width: '1vw', color: PRIMARY_COLOR }} />
                <Typography>{t('payouts.actions.feesInvoice')}</Typography>
            </MenuItem>
            <MenuItem sx={{ display: 'flex', gap: 0.5 }} disabled={!isAbleToDownloadPaymentsInvoices} onClick={handlePaymentsInvoice}>
                <AccountBalanceWalletIcon sx={{ width: '1vw', color: GREEN_COLOR }} />
                <Typography>{t('payouts.actions.paymentsInvoice')}</Typography>
            </MenuItem>
        </Menu>
    )
}

export default Actions;