import React, { useState, useEffect } from 'react'
import { Grid, Card, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

import { Tabs } from '../../common'
import Details from './subcomponents/Details'
import Subscriptions from './subcomponents/Subscriptions'
import Reviews from './subcomponents/Reviews'
import Services from './subcomponents/Services'

import { LIGHT_GRAY_BORDER_COLOR } from '../../../utils/constants'

import * as TRAINER_REQUESTS from '../../../api/trainer'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
    flexGrow: 1
  }
})

const TrainerActivity = ({ user, goToReview, resetGoToReview }) => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { domain } = useParams()

  const [activeTab, setActiveTab] = useState(undefined)
  const [services, setServices] = useState([])
  const [subscriptions, setSubscriptions] = useState([])

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    })
    const activeOption = params['active_option']
    if (activeOption) setActiveTab(Number(activeOption))
    else setActiveTab(0)
  }, [])

  useEffect(() => {
    if (!goToReview) return
    setActiveTab(3)
    resetGoToReview()
  }, [goToReview])

  const handleGetServices = () => {
    TRAINER_REQUESTS.getTrainerServicesByDomain(domain).then(setServices)
  }

  const handleGetSubscriptions = () => {
    TRAINER_REQUESTS.getTrainerSubscriptionsByDomain(domain).then((res) => {
      setSubscriptions(res.filter(el => el.visibility !== 'PRIVATE'))
    })
  }

  useEffect(() => {
    if (activeTab === 1) {
      handleGetServices()
    } else if (activeTab === 2) {
      handleGetSubscriptions()
    }
  }, [activeTab])

  if (activeTab === undefined) return null

  return (
    <Grid item sm={12} xl={12}>
      <Grid container justifyContent="flex-end" height="100%">
        <Grid className={classes.content} component={Card} item sm={12} xl={12} minHeight={'100%'}>
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => {
              const to = { pathname: location.pathname, search: `active_option=${newValue}` }
              navigate(to, { replace: true })
              setActiveTab(newValue)
            }}
            options={[
              {
                label: t('userProfile.activity.details'),
                value: (
                  <Details
                    bio={user.bio}
                    Favourites={user.Favourites}
                    Photos={user.Documents.filter((doc) => doc.isPhoto)}
                    Qualifications={user.Qualifications}
                    Licences={user.Licences}
                    Companies={user.Companies.filter(el => el.Companies_Trainers.status === 'APPROVED')}
                  />
                )
              },
              {
                label: t('userProfile.services'),
                value: <Services services={services} handleGetServices={handleGetServices} />
              },
              {
                label: t('userProfile.subscriptions'),
                value: <Subscriptions subscriptions={subscriptions} />
              },
              {
                label: t('userProfile.activity.reviews'),
                value: (
                  <Reviews
                    reviewsAverage={user.reviewsAverage > 5 ? 5 : user.reviewsAverage < 1 ? 1 : user.reviewsAverage}
                    userName={`${user.firstName} ${user.lastName}`}
                    userPhotoSrc={user.avatarPhotoSrc}
                    userId={user.id}
                  />
                )
              }
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TrainerActivity
