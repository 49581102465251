/* eslint-disable no-unused-vars */
import { Close } from '@mui/icons-material'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    Button,
    DialogActions,
    IconButton,
    Container,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-quill/dist/quill.snow.css'
import * as COMPANY_REQUEST from '../../../api/company'
import * as TRAINER_REQUEST from '../../../api/trainer'
import * as ORGANIZATION_REQUEST from '../../../api/organization'
import { SendingButton } from '../../common'
import { useAuthentication } from '../../../context/auth-context';
import Form from '../Form'
import validator from 'validator'

const CreateDealsDialog = ({ handleClose, type, Transition }) => {
    const { t } = useTranslation()
    const [linkDeal, setLinkDeal] = useState('')
    const [errorLink, setErrorLink] = useState('')
    const [profilePhoto, setProfilePhoto] = useState('')
    const [loading, setLoading] = useState(false)
    const { user } = useAuthentication()
    const handleSubmit = async () => {
        try {
            setLoading(true)
            if (validator.isURL(linkDeal)) {
                if (user) {
                    const payload = {
                        link: linkDeal,
                        photo: profilePhoto
                    }
                    if (type === 'Company') {
                        await COMPANY_REQUEST.addDeal(user.companyDomain, payload)

                    } else if (type === 'Organization') {
                        await ORGANIZATION_REQUEST.addDeal(user.id, payload)

                    } else {
                        await TRAINER_REQUEST.addDeal(user.domain, payload)
                    }
                }
                setLoading(false)
                handleClose()
            } else {
                setErrorLink(t('deals.errorLink'))
                setLoading(false)
            }
            
        } catch (error) {
            console.error(error);
            setLoading(false)
        }
    }

    const computeSubmitButtonDisabled = () => {
        if (!linkDeal || !profilePhoto) return true

        return false
    }

    return (
        <Dialog
            open={true}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth={'xl'}
            fullScreen={true}
            PaperProps={{
                style: {
                    borderRadius: 0,
                    paddingTop: '4%'
                }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Container maxWidth="xl">
                <DialogTitle
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                    p={4}
                    pl={{ xs: 2, md: 2, lg: 7 }}
                    pr={{ xs: 2, md: 2, lg: 7 }}
                >
                    <Grid container display="flex" flexDirection="row">
                        <Grid item xs={10}>
                            <Typography variant="h5" fontWeight="bold">
                                {t('deals.createTitle')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                            <IconButton style={{ color: 'black' }} onClick={handleClose}>
                                <Close fontSize="medium" color="inherit" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container display="flex" flexDirection="column" p={{ lg: 4, xs: 0 }}>
                        <Grid item xs={12} pt={4}>
                            <Form setProfilePhoto={setProfilePhoto} setErrorLink={setErrorLink} errorLink={errorLink} profilePhoto={profilePhoto} linkDeal={linkDeal} setLinkDeal={setLinkDeal} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ paddingBottom: 8 }}>
                    <Grid
                        container
                        display="flex"
                        flexDirection={{ lg: 'row', xs: 'column' }}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item pr={{ xs: 0, lg: 1 }}>
                            <Button
                                sx={{ minWidth: 250 }}
                                variant="outlined"
                                color="primary"
                                onClick={() => handleClose()}
                            >
                                {t('course.cancel')}
                            </Button>
                        </Grid>
                        <Grid item pl={{ xs: 0, lg: 1 }} mt={{ lg: 0, xs: 1 }}>
                            <Button
                                disabled={computeSubmitButtonDisabled() || loading}
                                sx={{ minWidth: 250 }}
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    handleSubmit()
                                }
                            >
                                {loading ? <SendingButton /> : t('deals.save')}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Container>
        </Dialog>
    )
}

export default CreateDealsDialog
