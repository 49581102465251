import { Grid, Typography } from "@mui/material"

const Sections = ({ title, subtitle, content, colorContent, secondContent, colorSubtitle, colorTitle, boldSection, multipleContent }) => {
    return (
        <>
            {boldSection ? <Grid conatiner display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-end'} width={'100%'}>
                < Grid item xs={6} md={4} lg={4} >
                    <Typography sx={{ fontWeight: 'bold' }} variant="h6" gutterBottom>
                        {title}
                    </Typography>

                </Grid >
                <Grid item xs={6} md={4} lg={4} display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="h6" gutterBottom>
                        {content}
                    </Typography>
                </Grid>
            </Grid > :
                <Grid height={title === 'Ads-ons' ? '350px' : 'auto'} conatiner display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={title === 'Ads-ons' ? 'flex-start' : 'flex-end'} width={'100%'}>
                    <Grid item xs={8}>
                        <Typography color={colorTitle ? colorTitle : '#707070'} variant="subtitle1" gutterBottom>
                            {title}
                        </Typography>
                        {multipleContent ? multipleContent.map((el, index) =>
                            <Typography sx={colorSubtitle ? {} : { fontWeight: 'bold' }} color={colorSubtitle ? colorSubtitle : '#000000'} variant="subtitle1" gutterBottom>
                                {el.subtitle}
                            </Typography>) :
                            (subtitle &&
                                <Typography sx={colorSubtitle ? {} : { fontWeight: 'bold' }} color={colorSubtitle ? colorSubtitle : '#000000'} variant="subtitle1" gutterBottom>
                                    {subtitle}
                                </Typography>)
                        }
                    </Grid>

                    <Grid item xs={4} display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
                        {multipleContent ?
                            <Grid item xs={12} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'} alignItems={'flex-end'}>
                                {multipleContent.map((el, index) => {
                                    return <>
                                        <Typography sx={{ marginBottom: '0px', marginTop: index === 0 ? '32px' : '0px' }} color={colorContent ? colorContent : '#707070'} variant="subtitle1" gutterBottom>
                                            {el.content}
                                        </Typography>
                                        <Typography color={colorContent ? colorContent : '#707070'} variant="subtitle1" gutterBottom>
                                            {el.secondContent}
                                        </Typography>
                                    </>

                                })}
                            </Grid>

                            : (
                                <>
                                    <Typography color={colorContent ? colorContent : '#707070'} variant="subtitle1" gutterBottom>
                                        {content}
                                    </Typography>
                                    {secondContent &&
                                        <Typography color={colorContent ? colorContent : '#707070'} variant="subtitle1" gutterBottom>
                                            {secondContent}
                                        </Typography>
                                    }
                                </>
                            )
                        }
                    </Grid>

                </Grid >}
        </>

    )
}
export default Sections;