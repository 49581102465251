import React, { useCallback, useMemo } from 'react'
import { Avatar, Grid, Typography, Chip, Tooltip, Box } from '@mui/material'
import { Groups, WatchLater, Timer, Public, CardMembership } from '@mui/icons-material'

import {
  BUSINESS_ROLES,
  ERROR_COLOR,
  GREEN_COLOR,
  LIGHT_GRAY_BORDER_COLOR,
  ROLE_BUSINESS_ADMINISTRATOR,
  ROLE_TRAINER,
  SPORTTYPES_TYPES,
  WHITE_COLOR
} from '../../../utils/constants'
import { getBackgroundImage } from '../../../utils/helpers/bgImages'
import { useAuthentication } from '../../../context/auth-context'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

const CalendarItem = ({ item, day, small, loadingBookUnbook, showPastEvents, handleEventDetails, handleOpenPayment, handleOpenWaitingList, handleBook, handleBookEventWithOneSubscription, handleOpenSubscriptionSelector, handleUnbook, isDashboard }) => {
  const { t } = useTranslation()
  const { user } = useAuthentication()
  const navigate = useNavigate()
  const theme = useTheme()
  const matchesPhone = useMediaQuery(theme.breakpoints.down('sm'))

  const isTrainer = user && user.role && user.role.toLowerCase() === ROLE_TRAINER.toLowerCase()
  const isClub =
    user && user.role && user.role.toLowerCase() === ROLE_BUSINESS_ADMINISTRATOR.toLowerCase()
  const isEmployee =
    user && user.role && user.role.toLowerCase() === BUSINESS_ROLES.BUSINESS_EMPLOYEE.toLowerCase()

  const handleBookUnbook = () => {
    if (!handleOpenPayment || !handleUnbook) return
    if (item.booked) {
      handleUnbook(item.id, true)
    }
    else {
      if ((Number(item.participants.length) === Number(item.maxParticipants)) && item.maxParticipants) {
        if ((Number(item.inWaitingList) === Number(item.maxWaitingList))) return
        handleOpenWaitingList(item)
      }
      else {
        if (!item.payment) {
          if (item.cost === "CREDITS") {
            if (item.EventSubscriptions.length > 1) {
              handleOpenSubscriptionSelector(item, item.EventSubscriptions)
            }
            else {
              handleBookEventWithOneSubscription(item)
            }
          }
          else {
            handleBook(item.id, true)
          }
        }
        else handleOpenPayment(item)
      }
    }
  }

  const renderCustomItem = useCallback(() => {
    return (
      <Grid
        onClick={() => isDashboard ? navigate(`/calendar?eventId=${item.id}`) : handleEventDetails && !item.cannotOpenDetailsDialog ? handleEventDetails(item) : {}}
        container
        display="flex"
        flexDirection="column"
        pl={2}
        pr={2}
        pt={1.5}
        mb={2}
        minWidth={matchesPhone ? '85vw' : small ? 315 : 385}
        maxWidth={matchesPhone ? '85vw' : small ? 315 : 385}
        sx={{
          border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.6)),url(${getBackgroundImage(item.backgroundImage || '')})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          borderRadius: 3,
          color: WHITE_COLOR,
          boxShadow: '3px 7px 8px 0px #CFCFCF',
          opacity: item.transparency ? 0.5 : 1
        }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12} display="flex" flexDirection="row" minHeight={180}>
          <Grid item lg={2} md={2} sm={2} xs={2} display="flex">
            {item.trainers.length > 0 && !isDashboard ? <Grid container display="flex" flexDirection="column">
              {item.trainers.slice(0, 4).map(el => {
                if (!el) return null
                return (
                  <Grid item key={el && el.Trainer ? el.Trainer.id : 'trainer'} pt={0.5}>
                    <Tooltip title={`${el && el.Trainer ? el.Trainer.firstName : ''} ${el && el.Trainer ? el.Trainer.lastName : ''}`} placement="top">
                      <Avatar
                        src={el && el.Trainer ? el.Trainer.avatarPhotoSrc : ''}
                        sx={{ width: 25, height: 25, border: `2px solid ${WHITE_COLOR}` }}
                      >
                        <Typography variant="caption">
                          {el && el.Trainer ? el.Trainer.firstName.charAt(0).toUpperCase() : 'A'} {el && el.Trainer ? el.Trainer.lastName.charAt(0).toUpperCase() : 'A'}
                        </Typography>
                      </Avatar>
                    </Tooltip>
                  </Grid>
                )
              })}
              {item.trainers.length > 4 && !isDashboard ? <Grid item key={item.trainers.length} pt={0.5}><Avatar
                sx={{ width: 25, height: 25, border: `2px solid ${WHITE_COLOR}` }}
              >
                <Typography variant="caption">
                  {item.trainers.length - 4}
                </Typography>
              </Avatar> </Grid> : null}
            </Grid> : item.trainer ? <Tooltip title={`${item.trainer.firstName} ${item.trainer.lastName}`} placement="top">
              <Avatar
                src={item.trainer.avatarPhotoSrc}
                sx={{ width: 25, height: 25, border: `2px solid ${WHITE_COLOR}`, marginLeft: 0.5 }}
              >
                <Typography variant="caption">{item.trainer.firstName.charAt(0).toUpperCase()} {item.trainer.lastName.charAt(0).toUpperCase()}</Typography>
              </Avatar>
            </Tooltip> : null}
          </Grid>
          <Grid
            item
            lg={8}
            md={8}
            sm={8}
            xs={8}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            pt={5}
          >
            <Grid item>
              <Tooltip placement="top" title={item.public !== 'SUBSCRIPTION' ? t('calendar.publicEvent') : t('calendar.onlyMembership')}>
                {item.public !== 'SUBSCRIPTION' ? <Public /> : <CardMembership sx={{ cursor: 'pointer' }} onClick={() => navigate(`/${item.company ? item.company.domain : item.trainer ? item.trainer.domain : ''}?active_option=2`)} />}
              </Tooltip>
            </Grid>
            <Grid item pb={0.5}>
              <Typography variant="body2" fontWeight={"bold"} sx={{ textShadow: '3px 3px 2px rgba(0, 0, 0, 0.63)' }}>
                {item.title}
              </Typography>
            </Grid>
            <Grid
              pb={0.5}
              pt={0.5}
              container
              display="flex"
              flexDirection="row"
              justifyContent="center"
              columnSpacing={0.3}
              rowSpacing={0.2}
              boxSizing="border-box"
            >
              {item.firstChip ? <Grid item width="min-content">
                <Chip
                  sx={{
                    fontWeight: 500,
                    width: '100%',
                    fontSize: 9,
                    height: 20,
                    color: item.firstChip === 'FREE' ? GREEN_COLOR : '',
                    backgroundColor: 'rgba(255,255,255,0.8)'
                  }}
                  label={item.firstChip}
                />
              </Grid> : null}
              {item.secondChip ? <Grid item width="min-content">
                <Chip
                  sx={{
                    fontWeight: 600,
                    width: '100%',
                    fontSize: 9,
                    height: 20,
                    color: item.secondChip === 'FREE' ? GREEN_COLOR : '',
                    backgroundColor: 'rgba(255,255,255,0.8)'
                  }}
                  label={item.secondChip}
                />
              </Grid> : null}
              {item.thirdChip ? (
                <Grid item width="min-content">
                  <Chip
                    sx={{
                      fontWeight: 500,
                      width: '100%',
                      fontSize: 9,
                      height: 20,
                      color: item.thirdChip === 'FREE' ? GREEN_COLOR : '',
                      backgroundColor: 'rgba(255,255,255,0.8)'
                    }}
                    label={item.thirdChip}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid item pt={1}>
              {user && (!isTrainer && !isClub && !isEmployee) && !isDashboard ? <Chip
                onClick={e => {
                  e.stopPropagation()
                  handleBookUnbook()
                }}
                disabled={((loadingBookUnbook === item.id) || !!item.webDisabled)}
                sx={{
                  fontWeight: 600,
                  minWidth: 50,
                  fontSize: 12,
                  fontWeight: 'bold',
                  height: 25,
                  backgroundColor: item.buttonColor,
                  color: WHITE_COLOR,
                  '&:hover': {
                    backgroundColor: item.buttonColor,
                  }
                }}
                label={t(item.webLabel).toUpperCase()}
              /> : null}
              {(isTrainer || isClub || isEmployee) && (((Number(item.participants.length) === Number(item.maxParticipants)) && item.maxParticipants && !item.maxWaitingList) || (Number(item.inWaitingList) === Number(item.maxWaitingList) && item.maxWaitingList)) ? <Chip
                disabled={true}
                sx={{
                  fontWeight: 600,
                  minWidth: 50,
                  fontSize: 12,
                  fontWeight: 'bold',
                  height: 25,
                  backgroundColor: ERROR_COLOR,
                  color: WHITE_COLOR,
                  '&:hover': {
                    backgroundColor: ERROR_COLOR
                  }
                }}
                label={((Number(item.participants.length) === Number(item.maxParticipants)) && item.maxParticipants) ? !item.withWaitingList ? t('calendar.full') : Number(item.inWaitingList) === Number(item.maxWaitingList) ? item.booked ? t('calendar.unbook').toUpperCase() : t('calendar.full').toUpperCase() : item.booked ? t('calendar.unbook').toUpperCase() : t('calendar.book').toUpperCase() : item.booked ? t('calendar.unbook').toUpperCase() : t('calendar.book').toUpperCase()}
              /> : null}
            </Grid>
          </Grid>
          {item.showParticipantsName && !isDashboard ? <Grid item lg={2} md={2} sm={2} xs={2} display="flex" flexDirection="column" alignItems="flex-end">
            {
              item.participants.slice(0, 4).map((el, index) => (
                el ? <Avatar
                  key={el.Participant ? el.Participant.id : 'participant-key'}
                  src={el.Participant ? el.Participant.avatarPhotoSrc : ''}
                  sx={{
                    width: 25,
                    height: 25,
                    border: `2px solid ${!item.minParticipants ? WHITE_COLOR : item.participants.length < item.minParticipants ? ERROR_COLOR : GREEN_COLOR}`,
                    position: 'relative',
                    top: -10 * index
                  }}
                >
                  <Typography variant="caption">{el.Participant && el.Participant.firstName ? el.Participant.firstName.charAt(0).toUpperCase() : ''} {el.Participant && el.Participant.lastName ? el.Participant.lastName.charAt(0).toUpperCase() : ''}</Typography>
                </Avatar> : null
              ))
            }
            {
              item.participants.length > 4 && !isDashboard ? <Avatar
                sx={{
                  width: 25,
                  height: 25,
                  border: `2px solid ${WHITE_COLOR}`,
                  top: -25
                }}
              >
                {item.participants.length - 4}
              </Avatar>
                : null}
          </Grid> : <Grid item lg={2} xs={2} display="flex" flexDirection="column" alignItems="flex-end" />}
        </Grid>
        <Grid item lg={12} display="flex" flexDirection="row" alignItems="flex-end" pb={1} maxHeight={45}>
          <Grid item lg={isDashboard ? 5 : 2} xs={isDashboard ? 5 : 2} display="flex" flexDirection="row" alignItems="center">
            <WatchLater sx={{ fontSize: 16, marginLeft: 0.5, marginRight: 0.5 }} />
            <Typography variant="caption" fontWeight={600}>
              {isDashboard ? `${moment(item.fullStart).format('YYYY-MM-DD')} ${item.start}` : item.start}
            </Typography>
          </Grid>
          <Grid
            item
            lg={isDashboard ? 5 : 8}
            md={isDashboard ? 5 : 8}
            sm={isDashboard ? 5 : 8}
            xs={isDashboard ? 5 : 8}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="caption" fontWeight={600}>
            </Typography>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <>
              <Timer sx={{ fontSize: 16, marginLeft: 0.5, marginRight: 0.5 }} />
              <Typography variant="caption" fontWeight={600}>
                {item.duration}
              </Typography>
            </>
          </Grid>
        </Grid>
      </Grid>
    )
  }, [item, small, loadingBookUnbook, user, matchesPhone, showPastEvents, isDashboard])

  const renderSimpleItem = useCallback(() => {
    return (
      <Grid
        onClick={() => isDashboard ? navigate(`/calendar?eventId=${item.id}`) : handleEventDetails ? handleEventDetails(item) : {}}
        container
        display="flex"
        flexDirection="row"
        pl={2}
        pr={2}
        pt={1}
        pb={1}
        mb={2}
        minWidth={matchesPhone ? '85vw' : small ? 315 : 385}
        maxWidth={matchesPhone ? '85vw' : small ? 315 : 385}
        sx={{
          border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
          backgroundColor: item.backgroundColor,
          borderRadius: 3,
          color: WHITE_COLOR,
          boxShadow: '3px 7px 8px 0px #CFCFCF',
          opacity: item.transparency ? 0.5 : 1
        }}
      >
        <Grid item display="flex" flexDirection="column" pr={2} lg={9} md={9} sm={9} xs={9}>
          <Typography variant="body2" fontWeight={"bold"} sx={{ textShadow: '3px 3px 2px rgba(0, 0, 0, 0.63)' }}>
            {item.title}
          </Typography>
          <Grid container lg={12} display="flex" flexDirection="row" pt={1.5} columnSpacing={0.3} rowSpacing={0.2}>
            {item.firstChip ? <Grid item width="min-content">
              <Chip
                sx={{
                  fontWeight: 500,
                  width: '100%',
                  fontSize: 9,
                  height: 20,
                  color: item.firstChip === 'FREE' ? GREEN_COLOR : '',
                  backgroundColor: 'rgba(255,255,255,0.8)'
                }}
                label={item.firstChip}
              />
            </Grid> : null}
            {item.secondChip ? <Grid item width="min-content">
              <Chip
                sx={{
                  fontWeight: 600,
                  width: '100%',
                  fontSize: 9,
                  height: 20,
                  color: item.secondChip === 'FREE' ? GREEN_COLOR : '',
                  backgroundColor: 'rgba(255,255,255,0.8)'
                }}
                label={item.secondChip}
              />
            </Grid> : null}
            {item.thirdChip ? (
              <Grid item width="min-content">
                <Chip
                  sx={{
                    fontWeight: 500,
                    width: '100%',
                    fontSize: 9,
                    height: 20,
                    color: item.thirdChip === 'FREE' ? GREEN_COLOR : '',
                    backgroundColor: 'rgba(255,255,255,0.8)'
                  }}
                  label={item.thirdChip}
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid container lg={12} display="flex" flexDirection="row" pt={1.5}>
            <Grid item lg={4} display="flex" flexDirection="row" alignItems="center" sx={{ color: !item.minParticipants ? WHITE_COLOR : item.participants.length < item.minParticipants ? ERROR_COLOR : GREEN_COLOR }}>
              <>
                <Tooltip placement="top" title={`${item.participants.length} participants booked for this event`}>
                  <Typography variant="caption" fontWeight={600}>
                    {item.participants.length}
                  </Typography>
                </Tooltip>
                <Groups sx={{ fontSize: 16, marginLeft: 0.5, marginRight: 0.5 }} />
                <Typography variant="caption" fontWeight={600}>
                  {item.maxParticipants || '-'}
                </Typography>
              </>
            </Grid>
            <Grid item lg={isDashboard ? 7 : 4} display="flex" flexDirection="row" alignItems="center">
              <>
                <WatchLater sx={{ fontSize: 16, marginLeft: 0.5, marginRight: 0.5 }} />
                <Typography variant="caption" fontWeight={600}>
                  {isDashboard ? moment(item.fullStart).subtract(3, 'hours').format('YYYY-MM-DD HH:mm') : item.start}
                </Typography>
              </>
            </Grid>
            <Grid item lg={isDashboard ? 1 : 4} display="flex" flexDirection="row" alignItems="center">
              <>
                <Timer sx={{ fontSize: 16, marginLeft: 0.5, marginRight: 0.5 }} />
                <Typography variant="caption" fontWeight={600}>
                  {item.duration}
                </Typography>
              </>
            </Grid>
          </Grid>
        </Grid>
        <Grid item display="flex" justifyContent={item.trainers.length > 0 ? 'center' : 'flex-end'} alignItems="center" lg={3} md={3} sm={3} xs={3}>
          <Tooltip placement="top" title={item.public !== 'SUBSCRIPTION' ? t('calendar.publicEvent') : t('calendar.onlyMembership')}>
            {item.public !== 'SUBSCRIPTION' ? <Public fontSize='small' sx={{ marginRight: 0.5, paddingTop: 0.5, cursor: 'pointer' }} /> : <CardMembership fontSize='small' sx={{ marginRight: 0.5, paddingTop: 0.5 }} />}
          </Tooltip>
          {item.trainers.length > 0 ? <Tooltip title={`${item.trainers[0].Trainer.firstName} ${item.trainers[0].Trainer.lastName}`} placement="top">
            <Avatar
              src={item.trainers[0].Trainer.avatarPhotoSrc}
              sx={{ width: 60, height: 60, border: `2px solid ${WHITE_COLOR}`, marginLeft: 0.5 }}
            >
              {item.trainers[0].Trainer.firstName.charAt(0).toUpperCase()} {item.trainers[0].Trainer.lastName.charAt(0).toUpperCase()}
            </Avatar>
          </Tooltip> : item.trainer ? <Tooltip title={`${item.trainer.firstName} ${item.trainer.lastName}`} placement="top">
            <Avatar
              src={item.trainer.avatarPhotoSrc}
              sx={{ width: 60, height: 60, border: `2px solid ${WHITE_COLOR}`, marginLeft: 0.5 }}
            >
              {item.trainer.firstName.charAt(0).toUpperCase()} {item.trainer.lastName.charAt(0).toUpperCase()}
            </Avatar>
          </Tooltip> : null}
        </Grid>
      </Grid >
    )
  }, [item, isDashboard, matchesPhone, small])

  const renderService = useCallback(() => {
    return <Grid
      onClick={() => (isClub && (item.companyId === user.companyId)) || (isTrainer && (item.trainerId === user.id)) ? navigate(`/calendar/services/${item.id}/edit?day=${day}`, { state: { currency: item.currency } }) : navigate(`/calendar/services/${item.id}?day=${day}`)}
      container
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      pl={2}
      pr={2}
      pt={1}
      pb={1}
      mb={2}
      minWidth={matchesPhone ? '85vw' : small ? 315 : 385}
      maxWidth={matchesPhone ? '85vw' : small ? 315 : 385}
      sx={{
        border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
        borderRadius: 3,
        color: WHITE_COLOR,
        boxShadow: '3px 7px 8px 0px #CFCFCF',
        height: 225,
        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.6)),url(${getBackgroundImage(item.sportType || '')})`,
        backgroundSize: `${matchesPhone ? '85vw' : small ? '315px' : '385px'} 225px`,
        backgroundRepeat: 'no-repeat',
        opacity: showPastEvents && item.transparency ? 0.5 : 1
      }}
    >
      <Grid item xs={3} display="flex" justifyContent="center" />
      <Grid item xs={6} display="flex" flexDirection="column" justifyContent="center">
        <Box textAlign={"center"}>
          <Typography variant="body2" fontWeight={"bold"} sx={{ textShadow: '3px 3px 2px rgba(0, 0, 0, 0.63)' }}>
            {item.name}
          </Typography>
        </Box>
        <Grid container xs={12} lg={12} display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          {item.firstChip ? <Grid item width="min-content">
            <Chip
              sx={{
                fontWeight: 500,
                width: '100%',
                fontSize: 9,
                height: 20,
                color: item.firstChip === 'FREE' ? GREEN_COLOR : '',
                backgroundColor: 'rgba(255,255,255,0.8)'
              }}
              label={item.firstChip}
            />
          </Grid> : null}
          {item.secondChip ? <Grid item width="min-content">
            <Chip
              sx={{
                fontWeight: 600,
                width: '100%',
                fontSize: 9,
                height: 20,
                color: item.secondChip === 'FREE' ? GREEN_COLOR : '',
                backgroundColor: 'rgba(255,255,255,0.8)'
              }}
              label={item.secondChip}
            />
          </Grid> : null}
          {item.thirdChip ? (
            <Grid item width="min-content">
              <Chip
                sx={{
                  fontWeight: 500,
                  width: '100%',
                  fontSize: 9,
                  height: 20,
                  color: item.thirdChip === 'FREE' ? GREEN_COLOR : '',
                  backgroundColor: 'rgba(255,255,255,0.8)'
                }}
                label={item.thirdChip}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={3} display="flex" justifyContent="center">
        {isDashboard ? <Typography fontWeight={500} variant="caption">{item.Slots.filter(slot => slot.CSParticipants.map(el => el.id).includes(user.id)).map(slot => `${moment(slot.from).format('YYYY-MM-DD HH:mm')} - ${moment(slot.to).format('HH:mm')}`).join('\n')}</Typography> : null}
      </Grid>
    </Grid >
  }, [item, day, showPastEvents, user, isTrainer, isClub])

  const renderItem = useCallback(() => {
    if (item.type === 'service') return renderService()
    if (item.custom) return renderCustomItem()

    return renderSimpleItem()
  }, [item, showPastEvents, renderCustomItem, renderSimpleItem])

  return (
    <Grid item lg={12} sx={{ cursor: 'pointer' }}>
      {renderItem()}
    </Grid>
  )
}

export default CalendarItem
