import React from 'react'
import { Grid, Typography, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info';
import { GRAY_BORDER_COLOR } from '../../../utils/constants'

const PriceComputation = ({ computeVAT, computeSubtotal, operationalServicesFee, currency, selectedPaymentType }) => {
  const { t } = useTranslation()

  return (
    <Grid
      item
      lg={12}
      sx={{ borderTop: `1px solid ${GRAY_BORDER_COLOR}` }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      pb={{ xs: 2 }}
      pt={{ xs: 2 }}
    >
      <Grid container display="flex" flexDirection="row">
        <Grid item lg={6}>
          <Typography variant="body1">Subtotal</Typography>
        </Grid>
        <Grid item lg={6} ml={{ xs: 'auto' }}>
          <Typography sx={{ float: 'right' }} variant="body1">
            {currency} {computeSubtotal()}
          </Typography>
        </Grid>
      </Grid>
      {selectedPaymentType === 'card' && 
        <Grid container display="flex" flexDirection="row" pt={1}>
          <Grid item lg={6}>
            <Typography variant="body1">
              {t('checkout.operationalServices')}
              <Tooltip title={t('checkout.operationalServicesTooltip')} arrow>
                <InfoIcon style={{ verticalAlign: 'middle', marginLeft: '5px', fontSize: 20 }} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item lg={6} ml={{ xs: 'auto' }}>
            <Typography sx={{ float: 'right' }} variant="body1">
              {currency} {operationalServicesFee}
            </Typography>
          </Grid>
        </Grid>
      }
      <Grid container display="flex" flexDirection="row" pt={1}>
        <Grid item lg={6}>
          <Typography variant="body1">VAT (19%)</Typography>
        </Grid>
        <Grid item lg={6} ml={{ xs: 'auto' }}>
          <Typography sx={{ float: 'right' }} variant="body1">
            {currency} {computeVAT()}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PriceComputation
