import axios from './axios'
import FileSaver from 'file-saver'
import { decode } from 'base64-arraybuffer'
import moment from 'moment'

export const transferMoney = (startDate, endDate, companyIds) => {
    return axios.post(`/super-admin/banktransfer/send?startDate=${startDate}&endDate=${endDate}&companyIds=${companyIds}`).then((res) => res.data)
}

export const loginToClient = companyId => {
    return axios.post('/auth/admin/login', { companyId })
        .then(res => res.data)
}

export const loginToOrganization = organizationId => {
    return axios.post('/auth/admin/login/organization', { organizationId })
        .then(res => res.data)
}

export const getCounts = () => {
    return axios.get(`/super-admin/count`).then((res) => res.data)
}

export const getPayments = (createdAt, until, type, paymentMethod, paymentState) => {
    return axios.get(`/super-admin/payments?from=${createdAt}&until=${until}&type=${type}${paymentMethod ? `&paymentMethod=${paymentMethod}` : ''}${paymentState ? `&paymentState=${paymentState}` : ''}`).then((res) => res.data)
}

export const getHistory = (search) => {
    return axios.get(`/super-admin/history?search=${search}`).then((res) => res.data)
}

export const getIndividuals = (search, app, from, limit) => {
    return axios.get(`/super-admin/individuals/${app}?search=${search}&from=${from}&limit=${limit}`).then((res) => res.data)
}
export const getDeals = () => {
    return axios.get(`/super-admin/deals`).then((res) => res.data)
}
export const accept = (id, accept) => {
    return axios.patch(`/super-admin/deals/${id}/${accept}`).then((res) => res.data)
}
export const getProfessionals = (search) => {
    return axios.get(`/super-admin/trainers?search=${search}`).then((res) => res.data)
}

export const getClubTransactions = async (filterOptions) => {
    const res = await axios.get(`/super-admin/clubTransactions?startDate=${filterOptions.date.startDate}&endDate=${filterOptions.date.endDate}&paymentMethod=${filterOptions.paymentMethod}`);
    const data = await res.data;
    return data;
}

export const getClubs = (search) => {
    return axios.get(`/super-admin/clubs?search=${search}`).then((res) => res.data)
}
export const getCompanies = (search) => {
    return axios.get(`/super-admin/companies?search=${search}`).then((res) => res.data)
}
export const updateUser = (userId, payload) => {
    return axios.patch(`/super-admin/individuals/${userId}`, { ...payload }).then(res => res.data)
}

export const updateLicense = (licenseId, payload) => {
    return axios.patch(`/super-admin/licenses/${licenseId}`, { ...payload }).then(res => res.data)
}

export const deleteLicense = (licenseId) => {
    return axios.delete(`/super-admin/licenses/${licenseId}`).then(res => res.data)
}

export const updateClub = (clubId, payload) => {
    return axios.patch(`/super-admin/clubs/${clubId}`, { ...payload }).then(res => res.data)
}
export const updateCompany = (companyId, payload) => {
    return axios.patch(`/super-admin/companies/${companyId}`, { ...payload }).then(res => res.data)
}

export const sendEmail = (payload) => {
    return axios.post(`/super-admin/email`, { ...payload }).then(res => res.data)
}

export const sendInvoices = (payload) => {
    return axios.post(`/super-admin/payments/invoices/send`, { ...payload }).then(res => res.data)
}

export const downloadInvoices = (payload) => {
    return axios.post(`/super-admin/payments/invoices/download`, { ...payload }).then(res => {
        res.data.invoices.forEach(invoice => {
            const outputFilename = `Invoice_SportIn_${invoice.company}_${moment().format('YYYY-MM-DD HH:mm:ss')}.pdf`;

            FileSaver.saveAs(new Blob([decode(invoice.file)]), outputFilename)
        })
        return res.data
    })
}

// For simulation of the cronjob - only for testing
// export const invoicesCronJob = () => {
//     return axios.get('/super-admin/cronjob').then(res => res.data);
// }