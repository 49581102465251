/* eslint-disable no-unused-vars */
import { Close, Add } from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
  DialogActions,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Box,
  IconButton,
  Checkbox,
  Chip,
  Container
} from '@mui/material'
import { useEffect, useState, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import ReactQuill from 'react-quill'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import 'react-quill/dist/quill.snow.css'
import ReactGA from 'react-ga'

import { Toggle, SendingButton } from '../../common'
import Subscription from '../../TrainerProfile/subcomponents/subcomponents/subcomponents/Subscription'

import { useAuthentication } from '../../../context/auth-context'
import {
  PRIMARY_COLOR,
  LIGHT_BLUE_COLOR,
  CHIP_BLUE_COLOR,
  EXTRA_LONG_DEFAULT_MAX_LENGTH,
  CARD_TEXT_COLOR,
  COMPANIES_TRAINERS_REQUEST_STATUS,
  DEFAULT_MAX_LENGTH,
  LONG_DEFAULT_MAX_LENGTH
} from '../../../utils/constants'
import { BUCKET_BASE_URL } from '../../../utils/config'
import * as TRAINER_REQUESTS from '../../../api/trainer'
import * as COMPANY_REQUESTS from '../../../api/company'
import * as SERVICE_REQUESTS from '../../../api/services'
import * as DOCUMENTS_REQUESTS from '../../../api/documents'

const useStyles = makeStyles({
  dialogPaper: {
    height: '85vh !important'
  }
})

const CreateServiceDialog = ({ handleClose, type, Transition }) => {
  const { t } = useTranslation()
  const { user } = useAuthentication()

  const [steps, setSteps] = useState([])
  const [activeStep, setActiveStep] = useState(0)
  const [serviceName, setServiceName] = useState('')
  const [shortDescription, setShortDescription] = useState('')
  const [longDescription, setLongDescription] = useState('')

  const [searchSubscription, setSearchSubscription] = useState('')
  const [selectedSubscriptions, setSelectedSubscriptions] = useState([])
  const [availableSubscriptions, setAvailableSubscriptions] = useState([])

  const [selectedTrainers, setSelectedTrainers] = useState([])
  const [availableTrainers, setAvailabeTrainers] = useState([])
  const [photoLoading, setPhotoLoading] = useState(false)
  const [photo, setPhoto] = useState('')
  const [interested, setInterested] = useState(true)
  const [isPublic, setIsPublic] = useState(true)
  const [isActive, setActive] = useState(true)

  const [loading, setLoading] = useState(false)

  const photoFileInputRef = useRef()

  const handleGetSubscriptions = async (searchSubscription) => {
    try {
      let subscriptions = []

      if (type === 'trainer') {
        subscriptions = await TRAINER_REQUESTS.getTrainerSubscriptions(searchSubscription)
      } else {
        subscriptions = await COMPANY_REQUESTS.getAllSubscriptions(
          user.companyId,
          searchSubscription
        )
      }
      setAvailableSubscriptions(subscriptions)
    } catch (e) {
      setAvailableSubscriptions([])
      console.error(e)
    }
  }

  const handleGetTeam = async () => {
    try {
      const result = await COMPANY_REQUESTS.getAllMembers(user.companyId)
      setAvailabeTrainers(
        result.filter((member) => member.status === COMPANIES_TRAINERS_REQUEST_STATUS.APPROVED)
      )
    } catch (e) {
      setAvailabeTrainers([])
      console.error(e)
    }
  }

  useEffect(() => {
    if (!user) return
    if (activeStep === 1) {
      handleGetSubscriptions(searchSubscription)
    } else if (activeStep === 2 && type === 'company') {
      handleGetTeam()
    }
  }, [activeStep, type, searchSubscription, user])

  useEffect(() => {
    setSteps([{ label: t('service.details') }, { label: t('service.subs') }, { label: t('service.options') }])
  }, [])

  const handleNextStep = () => setActiveStep((prevValue) => prevValue + 1)

  const handlePrevStep = () => setActiveStep((prevValue) => prevValue - 1)

  const handleSubmit = async () => {
    try {
      setLoading(true)
      let minPrice

      if (selectedSubscriptions && selectedSubscriptions.length > 0) {
        minPrice = availableSubscriptions
          .filter((el) => selectedSubscriptions.includes(el.id))
          .map((e) => e.priceRon)
          .reduce((prev, current) => (prev < current ? prev : current), selectedSubscriptions[0].priceRon)
      }

      const serviceToAdd = {
        title: serviceName,
        description: shortDescription,
        longDescription,
        isPublic,
        isInterested: interested,
        isActive,
        subscriptionIds: selectedSubscriptions,
        trainerIds: selectedTrainers.map((el) => el.id),
        priceRon: minPrice ? minPrice : 0
      }

      let serviceResult

      if (type === 'company') {
        serviceResult = await SERVICE_REQUESTS.createServiceForCompany(user.companyId, serviceToAdd)
      } else {
        serviceResult = await SERVICE_REQUESTS.createServiceForTrainer(serviceToAdd)
      }

      if (photo && photo.length) {
        const photoPromises = []
        const s3DocumentsPromises = []
        const documentsPromises = []

        photo.forEach((ph) =>
          photoPromises.push(
            DOCUMENTS_REQUESTS.generatePresignedUrl({ name: ph.name, extension: ph.extension })
          )
        )

        const photoPromisesResult = await Promise.all(photoPromises)
        photoPromisesResult.forEach((presignedUrl, index) =>
          s3DocumentsPromises.push(
            DOCUMENTS_REQUESTS.uploadDocumentS3(presignedUrl, photo[index].file)
          )
        )

        const s3DocumentsPromisesResult = await Promise.all(s3DocumentsPromises)
        s3DocumentsPromisesResult.forEach((_, idx) =>
          documentsPromises.push(
            DOCUMENTS_REQUESTS.createDocument({
              src: `${BUCKET_BASE_URL}/${photo[idx].name}.${photo[idx].extension}`,
              serviceId: serviceResult.id,
              isPhoto: true
            })
          )
        )

        await Promise.all(documentsPromises)
        ReactGA.event({
          category: 'POST_LOGIN',
          action: 'CREATE_SERVICE',
          label: 'SUCCESS'
        })
      }
      setLoading(false)
      handleClose()
    } catch (e) {
      console.error(e)
      setLoading(false)
      ReactGA.event({
        category: 'POST_LOGIN',
        action: 'CREATE_SERVICE',
        label: 'ERROR'
      })
    }
  }

  const handleExpand = (id) => {
    setAvailableSubscriptions((prevValue) => {
      let prevValueCopy = [...prevValue]
      const idPosition = prevValueCopy.findIndex((f) => f.id === id)

      if (idPosition < 0) return prevValueCopy

      prevValueCopy[idPosition].expand = !prevValueCopy[idPosition].expand

      return prevValueCopy
    })
  }

  const onPhotoChangeHandler = (event) => {
    const file = event.target.files[0]

    const name = Date.now()
    const extension = file.type.split('/')[1]

    setPhotoLoading(true)

    setPhoto((prevValue) => [...prevValue, { name, extension, originalName: file.name, file }])

    setPhotoLoading(false)
  }

  const handleSelectSubscription = (event, id) => {
    setSelectedSubscriptions((prevValue) => {
      if (!event.target.checked) return [...prevValue.filter((el) => el !== id)]

      return prevValue.includes(id) ? [...prevValue] : [...prevValue.concat(id)]
    })
  }

  const renderRTE = useMemo(() => {
    return (
      <Grid item xs={12}>
        <ReactQuill
          defaultValue={longDescription}
          onChange={(newContent) => {
            setLongDescription(newContent)
          }}
        >
        </ReactQuill>
      </Grid>
    )
  }, [longDescription])

  const renderDetailsStep = useMemo(() => {
    return (
      <Grid container display="flex" flexDirection="column">
        <Grid item xs={12} pt={2}>
          <Grid container flexDirection="column">
            <Grid pb={1} item>
              <Typography fontWeight={500} variant="p">
                {t('service.name')}{' '}
                <Typography variant="span" color={PRIMARY_COLOR}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                value={serviceName}
                fullWidth
                placeholder={t('service.addName')}
                name={'serviceName'}
                onChange={(event) => setServiceName(event.target.value)}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pt={6}>
          <Grid container flexDirection="column">
            <Grid pb={1} item>
              <Typography fontWeight={500} variant="p">
                {t('service.short')}{' '}
                <Typography variant="span" color={PRIMARY_COLOR}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                inputProps={{ maxLength: LONG_DEFAULT_MAX_LENGTH }}
                value={shortDescription}
                fullWidth
                placeholder={t('service.thisWillAppear')}
                name={'shortDescription'}
                onChange={(event) => setShortDescription(event.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <Typography variant="caption" color={shortDescription.length >= LONG_DEFAULT_MAX_LENGTH ? 'red' : ''}>{shortDescription.length} / {LONG_DEFAULT_MAX_LENGTH}</Typography>
            </Grid>
            {shortDescription.length >= LONG_DEFAULT_MAX_LENGTH ?
              <Typography variant="caption" color={"red"}>{t('helper.reduceChars')}</Typography>
              : null}
          </Grid>
        </Grid>
        <Grid item xs={12} pt={6}>
          <Grid container display="flex" flexDirection="column">
            <Grid item xs={12} pb={1}>
              <Typography fontWeight={500} variant="p">
                {t('service.long')}{' '}
                <Typography variant="span" color={PRIMARY_COLOR}>
                  *
                </Typography>
              </Typography>
            </Grid>
            {renderRTE}
            <Grid item>
              <Typography variant="caption" color={longDescription.length >= EXTRA_LONG_DEFAULT_MAX_LENGTH ? 'red' : ''}>{longDescription.length} / {EXTRA_LONG_DEFAULT_MAX_LENGTH}</Typography>
            </Grid>
            {longDescription.length >= EXTRA_LONG_DEFAULT_MAX_LENGTH ?
              <Typography variant="caption" color={"red"}>{t('helper.reduceChars')}</Typography>
              : null}
          </Grid>
        </Grid>
      </Grid>
    )
  }, [shortDescription, longDescription, serviceName])

  const renderAvailableSubscriptions = useMemo(() => {
    return (
      <Grid container display="flex" flexDirection="column">
        <Grid item xs={12}>
          {availableSubscriptions.length > 0 ? (
            <Grid container display="flex" flexDirection="column">
              {availableSubscriptions.map((el, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    pb={2}
                    maxHeight={{ lg: !el.expand ? 110 : '', xs: !el.expand ? 150 : '' }}
                  >
                    <Grid container display="flex" flexDirection="row" alignItems="center">
                      <Grid item xs={2} lg={0.5} md={0.5}>
                        <Checkbox
                          checked={selectedSubscriptions.includes(el.id)}
                          onChange={(event) => handleSelectSubscription(event, el.id)}
                        />
                      </Grid>
                      <Grid item xs={10} lg={11.5} md={11.5}>
                        <Subscription
                          view
                          name={el.name}
                          description={el.description}
                          duration={el.duration}
                          price={el.priceRon}
                          currency={el.Company?.currency || el.Trainer.trainerCurrency}
                          Trainers={el.SubscriptionsTrainers.map((el) => el.Trainer)}
                          expand={el.expand || false}
                          handleExpand={handleExpand}
                          id={el.id}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          ) : (
            <Typography color="#979797" fontWeight={500} fontSize={17}>
              {t('helper.noResultsFound')}
            </Typography>
          )}
        </Grid>
      </Grid>
    )
  }, [availableSubscriptions, selectedSubscriptions])

  const renderSubscriptionsStep = useMemo(() => {
    return (
      <Grid
        container
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="h6">
            {t('service.youCanAttach')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          lg={8}
          width="100%"
          display="flex"
          justifyContent="center"
          flexDirection="row"
          pt={6}
        >
          <TextField
            inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
            value={searchSubscription}
            fullWidth
            placeholder={t('service.searchSub')}
            name={'searchSubscription'}
            onChange={(event) => setSearchSubscription(event.target.value)}
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={8}
          width="100%"
          display="flex"
          alignItems="center"
          flexDirection="column"
          pt={6}
        >
          {renderAvailableSubscriptions}
        </Grid>
      </Grid>
    )
  }, [searchSubscription, renderAvailableSubscriptions])

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.label + option.phoneNumber + option.email
  })

  const renderOptionsStep = useMemo(() => {
    return (
      <>
        <input
          type="file"
          ref={photoFileInputRef}
          onChangeCapture={onPhotoChangeHandler}
          style={{ display: 'none' }}
        />
        <Grid
          container
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {type === 'company' ? (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {t('service.whenYouAdd')}
                </Typography>
              </Grid>
              <Grid
                item
                lg={8}
                xs={12}
                width="100%"
                display="flex"
                justifyContent="center"
                flexDirection="row"
                pt={6}
              >
                <Autocomplete
                  disablePortal
                  id="trainers-input"
                  filterOptions={filterOptions}
                  onChange={(_, newValue) => {
                    const { value, label, ...restOfValue } = newValue
                    setSelectedTrainers((prevValue) => [...prevValue, { ...restOfValue }])
                  }}
                  options={availableTrainers
                    .filter((el) => !selectedTrainers.map((tr) => tr.id).includes(el.id))
                    .map((el) => ({
                      label: `${el.firstName} ${el.lastName}`,
                      value: el.id,
                      ...el
                    }))}
                  sx={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t('service.searchTrainers')}
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={8}
                xs={12}
                width="100%"
                display="flex"
                alignItems="center"
                flexDirection="row"
                pt={6}
              >
                {selectedTrainers && selectedTrainers.length > 0 ? (
                  selectedTrainers.map((el, index) => {
                    return (
                      <Grid key={index} item xs={4}>
                        <Grid display="flex" flexDirection="row">
                          <Grid pr={{ xl: 2, lg: 1, xs: 2 }}>
                            <img
                              src={el.avatarPhotoSrc}
                              alt="avatar"
                              style={{ borderRadius: 25, objectFit: 'contain' }}
                              height={50}
                              width={50}
                            />
                          </Grid>
                          <Grid display="flex" flexDirection="column" justifyContent="center">
                            <Typography
                              variant="p"
                              justifyContent="center"
                              fontWeight={600}
                              sx={{ textDecoration: 'underline' }}
                            >
                              {el.firstName} {el.lastName}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color={CARD_TEXT_COLOR}
                              display="flex"
                              justifyContent="flex-start"
                              flexDirection="row"
                            >
                              {t('userProfile.activity.detailComponent.favouriteTrainer.trainerAt')}
                            </Typography>
                          </Grid>
                          <Grid
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            pl={2}
                          >
                            <Close
                              onClick={() =>
                                setSelectedTrainers((prevValue) => [
                                  ...prevValue.filter((tr) => tr.id !== el.id)
                                ])
                              }
                              sx={{ cursor: 'pointer' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  })
                ) : (
                  <Typography color="#979797" fontWeight={500} fontSize={17}>
                    {t('helper.noInformation')}
                  </Typography>
                )}
              </Grid>
            </>
          ) : (
            <Typography variant="h6">{t('service.extraInfo')}</Typography>
          )}
          <Grid item lg={8} xs={12} width="100%" pt={type === 'company' ? 24 : 6}>
            <Grid container display="flex" flexDirection="column">
              <Grid item xs={12} lg={12} pb={2}>
                <Typography variant="p">{t('service.gallery')}</Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Grid container display="flex" flexDirection={{ lg: 'row', xs: 'column' }}>
                  <Grid item lg={6} xs={12}>
                    <Grid container display="flex" flexDirection={{ xs: 'column' }}>
                      {photo.length > 0 ? (
                        photo.map((el, index) => {
                          return (
                            <Grid key={index} item xs={6} pb={1}>
                              <Chip
                                sx={{ fontWeight: 500, backgroundColor: CHIP_BLUE_COLOR }}
                                color="primary"
                                variant="outlined"
                                label={el.originalName}
                                onDelete={() =>
                                  setPhoto((prevValue) => [
                                    ...prevValue.filter((photo) => photo.name !== el.name)
                                  ])
                                }
                              />
                            </Grid>
                          )
                        })
                      ) : (
                        <Typography pb={1}>{t('helper.noInformation')}</Typography>
                      )}
                      <Grid item xs={6}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            border: `1px solid ${PRIMARY_COLOR}`,
                            backgroundColor: LIGHT_BLUE_COLOR
                          }}
                        >
                          <IconButton
                            disabled={photoLoading}
                            onClick={() => photoFileInputRef.current.click()}
                            sx={{ width: '100%', height: '100%', borderRadius: 0 }}
                          >
                            <Add color="primary" />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={6} xs={12} mt={{ xs: 2 }}>
                    <Grid container display="flex" flexDirection="column">
                      <Grid item xs={12} display="flex" flexDirection="row">
                        <Toggle
                          defaultChecked={interested}
                          color="primary"
                          onClick={() => setInterested((prevValue) => !prevValue)}
                        />
                        <Typography pl={1} variant="p">
                          {t('service.activate')}{' '}
                          <Typography
                            variant="p"
                            color="primary"
                            fontWeight={600}
                          >{t('service.interested')}</Typography>{' '}
                          {t('service.button')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} display="flex" flexDirection="row" pt={2}>
                        <Toggle
                          defaultChecked={isPublic}
                          color="primary"
                          onClick={() => setIsPublic((prevValue) => !prevValue)}
                        />
                        <Typography pl={1} variant="p">
                          {t('service.public')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} display="flex" flexDirection="row" pt={2}>
                        <Toggle
                          defaultChecked={isActive}
                          color="primary"
                          onClick={() => setActive((prevValue) => !prevValue)}
                        />
                        <Typography pl={1} variant="p">
                          {t('service.active')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }, [selectedTrainers, photo, isPublic, isActive, interested, photoLoading, availableTrainers])

  const computeSubmitButtonDisabled = () => {
    if (!longDescription || !serviceName || !shortDescription) return true

    return false
  }

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={'xl'}
      fullScreen={true}
      PaperProps={{
        style: {
          borderRadius: 0,
          paddingTop: '4%'
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Container maxWidth="xl">
        <DialogTitle
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          p={4}
          pl={{ xs: 2, md: 2, lg: 7 }}
          pr={{ xs: 2, md: 2, lg: 7 }}
        >
          <Grid container display="flex" flexDirection="row">
            <Grid item xs={10}>
              <Typography variant="h5" fontWeight="bold">
                {t('service.createService')}
              </Typography>
            </Grid>
            <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
              <IconButton style={{ color: 'black' }} onClick={handleClose}>
                <Close fontSize="medium" color="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container display="flex" flexDirection="column" p={{ lg: 4, xs: 0 }}>
            <Grid item xs={12}>
              <Stepper activeStep={activeStep}>
                {steps.map((step, index) => {
                  return (
                    <Step key={index}>
                      <StepLabel>{step.label}</StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
            </Grid>
            <Grid item xs={12} pt={4}>
              {activeStep === 0 && renderDetailsStep}
              {activeStep === 1 && renderSubscriptionsStep}
              {activeStep === 2 && renderOptionsStep}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: 8 }}>
          <Grid
            container
            display="flex"
            flexDirection={{ lg: 'row', xs: 'column' }}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item pr={{ xs: 0, lg: 1 }}>
              <Button
                sx={{ minWidth: 250 }}
                variant="outlined"
                color="primary"
                onClick={() => (activeStep === 0 ? handleClose() : handlePrevStep())}
              >
                {activeStep === 0 ? t('service.cancel') : t('service.prev')}
              </Button>
            </Grid>
            <Grid item pl={{ xs: 0, lg: 1 }} mt={{ lg: 0, xs: 1 }}>
              <Button
                disabled={computeSubmitButtonDisabled() || loading}
                sx={{ minWidth: 250 }}
                variant="contained"
                color="primary"
                onClick={() => {
                  return activeStep === 2 ? handleSubmit() : handleNextStep()
                }}
              >
                {activeStep === 2 ? loading ? <SendingButton /> : t('service.submit') : t('service.next')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default CreateServiceDialog
