import { Box, Button, Chip, Grid, TextField, Typography } from "@mui/material"
import EditorText from "./EditorText"
import { useTranslation } from "react-i18next"
import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import {
    PRIMARY_COLOR,
    DEFAULT_MAX_LENGTH,
    CHIP_BLUE_COLOR,
} from '../../../utils/constants'
import { useRef } from "react"
import { Toggle } from "../../common";

const Lesson = ({ name, photo, setPhoto, loading, photoLoading, link, description, setName, setLink, setDescription, handleSave, handleCancel, isFree, setIsFree }) => {
    const { t } = useTranslation()
    const photoFileInputRef = useRef()

    const onPhotoChangeHandler = (event) => {
        const file = event.target.files[0]

        const name = Date.now()
        const extension = file.type.split('/')[1]


        setPhoto({ name, loading, extension, originalName: file.name, file })

    }

    return (
        <Box sx={{ borderRadius: 4, border: 3, borderColor: '#ccc', marginTop: 3, padding: 3 }}>
            <Grid container flexDirection="column" style={{ marginBottom: 24 }}>
                <Grid pb={1} item>
                    <Typography fontWeight='bold' variant="p" color='#979797'>
                        {t('course.leassonName')}{' '}
                        <Typography variant="span" color={PRIMARY_COLOR}>
                            *
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField
                        inputProps={{ maxLength: 50 }}
                        value={name}
                        fullWidth
                        placeholder={t('course.addLeassonName')}
                        name={'nameLeson'}
                        onChange={(event) => setName(event.target.value)}
                        variant="outlined"
                    />
                </Grid>
                <Grid item>
                    <Typography variant="caption" color={name.length >= 50 ? 'red' : ''}>{name.length} / {50}</Typography>
                </Grid>
            </Grid>
            <Grid container flexDirection="column" style={{ marginBottom: 24 }}>
                <Grid pb={1} item>
                    <Typography fontWeight='bold' variant="p" color='#979797'>
                        {t('course.privateLink')}{' '}
                        <Typography variant="span" color={PRIMARY_COLOR}>
                            *
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField
                        inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                        value={link}
                        fullWidth
                        placeholder={t('course.addLink')}
                        name={'linkLesson'}
                        onChange={(event) => setLink(event.target.value)}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <EditorText value={description} setValue={setDescription} />
            <input
                type="file"
                ref={photoFileInputRef}
                onChangeCapture={onPhotoChangeHandler}
                style={{ display: 'none' }}
            />
            <Grid
                container
                display="flex"
                style={{ marginTop: 24 }}
                flexDirection={{ lg: 'row', xs: 'column' }}
                alignItems="center"
                justifyContent='space-between'
            >
                <Grid item xs={4} display="flex" flexDirection="row" alignItems="center">
                    <Toggle
                        defaultChecked={isFree}
                        color="primary"
                        onClick={() => setIsFree((prevValue) => !prevValue)}
                    />
                    <Typography pl={1} variant="p">
                        {t('course.isFree')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                display="flex"
                style={{ marginTop: 24 }}
                flexDirection={{ lg: 'row', xs: 'column' }}
                alignItems="center"
                justifyContent='space-between'
            >

                <Grid item pr={{ xs: 0, lg: 1 }}>
                    {photo ?
                        <Chip
                            sx={{ fontWeight: 500, backgroundColor: CHIP_BLUE_COLOR }}
                            color="primary"
                            variant="outlined"
                            label={photo.originalName ? photo.originalName : 'photo'}
                            onDelete={() => setPhoto('')}
                        />
                        :
                        <Button
                            sx={{ minWidth: 250 }}
                            variant="outlined"
                            color="primary"
                            onClick={() => photoFileInputRef.current.click()}
                        >
                            {`${t('course.photo')}`}
                        </Button>}
                </Grid>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item pr={{ xs: 0, lg: 1 }}>
                        <Button
                            sx={{ minWidth: 250 }}
                            variant="outlined"
                            color="primary"
                            onClick={handleCancel}
                        >
                            {`${t('course.cancel')}`}
                        </Button>
                    </Grid>
                    <Grid item pl={{ xs: 0, lg: 1 }} mt={{ lg: 0, xs: 1 }}>
                        <Button
                            disabled={name === '' || description === '' || loading || photoLoading}
                            sx={{ minWidth: 250 }}
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                        >
                            {loading ? <CircularProgress sx={{ color: '#fff' }} size={24} /> : t('course.save')}
                        </Button>
                    </Grid>
                </Box>
            </Grid>
        </Box>
    )
}
export default Lesson;