import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Typography, Button, Box, Grid } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

import PublicTrainerProfile from '../PublicTrainerProfile/PublicTrainerProfile'
import PublicBusinessProfile from '../PublicBusinessProfile/PublicBusinessProfile'

import { useAuthentication } from '../../context/auth-context'
import { ROLE_BUSINESS_ADMINISTRATOR, ROLE_TRAINER } from '../../../src/utils/constants'
import * as TRAINER_REQUESTS from '../../api/trainer'

import BG from '../../assets/no-result.gif'
import { useTranslation } from 'react-i18next'

const Domain = () => {
  const { t } = useTranslation()
  const { domain } = useParams()
  const { user } = useAuthentication()
  const navigate = useNavigate()

  const [isTrainer, setIsTrainer] = useState()
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)

  useEffect(() => {
    if (user && user.role === ROLE_BUSINESS_ADMINISTRATOR && user.companyDomain === domain) {
      return navigate('/business-profile')
    }

    if (user && user.role === ROLE_TRAINER && user.domain === domain) {
      return navigate('/trainer-profile')
    }

    TRAINER_REQUESTS.isTrainer(domain).then(({ isTrainer, notFound }) => {
      if (notFound) {
        setNotFound(true)
      } else {
        setIsTrainer(isTrainer)
        setNotFound(false)
      }
      setLoading(false)
    })
  }, [domain, user])

  if (loading) return null

  if (notFound) {
    return (
      <Box
        container={'true'}
        sx={{
          width: '100%',
          height: '90vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <Typography gutterBottom variant="h4" fontWeight={'bold'}>
          {t('helper.pageNotExists')}
        </Typography>
        <img style={{ objectFit: 'contain' }} src={BG} alt="ro flag" />
        <Grid item width={{ xs: '90%', lg: '30%' }}>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Button fullWidth variant="contained">
              {t('helper.goToMyProfile')}
            </Button>
          </Link>
        </Grid>
      </Box>
    )
  }

  return isTrainer ? <PublicTrainerProfile /> : <PublicBusinessProfile />
}

export default Domain
