import { Grid, Button, Box } from '@mui/material';
import {
    Table,
    TableBody,
    TableContainer,
    TableRow,
} from '@mui/material';
import { LIGHT_GRAY_BORDER_COLOR } from '../../../../utils/constants';
import ActionCell from './ActionCell';
import UserDetailsCell from './UserDetailsCell';
import SubscriptionNameCell from './SubscriptionNameCell';
import DateOfPaymentCell from './DateOfPaymentCell';
import StatusOfPaymentCell from './StatusOfPaymentCell';
import AmountOfPaymentCell from './AmountOfPaymentCell';
import React, { useState } from 'react';
import _ from 'lodash';
import Loading from '../Loading';

const ExpendedMonthDetails = ({ isLoading, payouts }) => {
    const [displayedChunks, setDisplayedChunks] = useState(1);

    const handleLoadMore = () => {
        setDisplayedChunks(prevChunks => prevChunks + 1);
    };

    const handleLoadLess = () => {
        setDisplayedChunks(prevChunks => prevChunks - 1);
    };

    const chunks = _.chunk(payouts, 10).slice(0, displayedChunks);

    return (
        <Grid container>
            {chunks.map((chunk, chunkIndex) => (
                <TableContainer key={chunkIndex}>
                    <Table size={'medium'} sx={{ height: isLoading ? '250px' : 'auto' }}>
                        {isLoading && (
                            <Loading />
                        )}
                        {!isLoading && (
                            <TableBody>
                                {chunk.map((item, index) => (
                                    <TableRow sx={{ display: 'flex', alignItems: 'center', '&:last-child td, &:last-child th': { border: 0 } }} style={{ backgroundColor: index % 2 === 0 ? LIGHT_GRAY_BORDER_COLOR : 'white' }} key={index}>
                                        <ActionCell paymentId={item.id} index={index} />
                                        <UserDetailsCell index={index} userDetails={item.userDetails} />
                                        <SubscriptionNameCell subscriptionName={item.subscriptionName} index={index} />
                                        <DateOfPaymentCell index={index} userPaidAt={item.userPaidAt} superAdminPaidAt={item.superAdminPaidAt} paymentStatus={item.status} />
                                        <StatusOfPaymentCell index={index} status={item.status} />
                                        <AmountOfPaymentCell index={index} feesAmount={item.feesAmount} paymentAmount={item.paymentAmount} payoutsAmount={item.payoutsAmount} />
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            ))}
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                {displayedChunks * 10 < payouts.length && (
                    <Button onClick={handleLoadMore} variant="text" color="primary" style={{ marginTop: '10px' }}>
                        Load More
                    </Button>
                )}
                {displayedChunks > 1 && (
                    <Button onClick={handleLoadLess} variant="text" color="primary" style={{ marginTop: '10px', marginLeft: '10px' }}>
                        Load Less
                    </Button>
                )}
            </Box>
        </Grid>
    );
};

export default ExpendedMonthDetails;
