import React, { useEffect, useRef, useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { StyledGrid, StyledGridButton } from './styled';
import CardDeals from './CradDeals';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <Box
            className={className}
            style={{ ...style, display: "block", background: "#ccc", borderRadius: '30px' }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <Box
            className={className}
            style={{ ...style, display: "block", background: "#ccc", borderRadius: '30px' }}
            onClick={onClick}
        />
    );
}

const Section = ({ deals, status, setSelectedDealsId, setOpenEditDialog }) => {
    const [slidesToShow, setSlidesToShow] = useState(1);
    const handleOnOpenEdit = (dealsId) => {
        setSelectedDealsId(dealsId);
        setOpenEditDialog(true);
    };

    useEffect(() => {
        setSlidesToShow(deals?.length <= 3 ? deals?.length : 3);
    }, [deals]);

    const settings = {
        dots: true,
        speed: 50,
        slidesToShow: slidesToShow < 3 ? slidesToShow : 3,
        slidesToScroll: slidesToShow < 3 ? slidesToShow : 3,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1560,
                settings: {
                    slidesToShow: slidesToShow < 3 ? slidesToShow : 3,
                    slidesToScroll: slidesToShow < 3 ? slidesToShow : 3,
                }
            },
            {
                breakpoint: 1536,
                settings: {
                    slidesToShow: slidesToShow < 2 ? slidesToShow : 2,
                    slidesToScroll: slidesToShow < 2 ? slidesToShow : 2,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <Grid container display="flex" width={'100%'} flexDirection="row" alignItems='center' justifyContent={'space-around'}>
            <StyledGridButton item xs={3} lg={1} md={2} sm={2} >
                <Box
                    sx={{
                        backgroundColor: status === 'Active' ? '#EFFFF2' : '#F4F8FF',
                        width: '100%',
                        height: '35px',
                        borderRadius: '25px',
                        border: '0.5px solid',
                        borderColor: status === 'Active' ? '#00DB33' : '#0664F2',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: status === 'Active' ? '#00DB33' : '#0664F2',
                            width: '10px',
                            height: '10px',
                            borderRadius: '25px',
                            marginRight: '10px'
                        }}
                    ></Box>
                    <Typography>{status}</Typography>
                </Box>
            </StyledGridButton>
            <Grid item xs={7} lg={10} md={8} sm={8.5}>
                {deals?.length === 1 ?
                    <Grid item xs={12} lg={7} md={10} sm={10}>
                        <CardDeals
                            select
                            title={'Edit'}
                            onClick={() => handleOnOpenEdit(deals[0].id)}
                            image={deals[0].photo}
                            height={400}
                            width={350}
                        />
                    </Grid>
                    : deals?.length > 0 &&
                    <Slider {...settings}>
                        {deals?.map((el, index) => {
                            return (
                                <CardDeals
                                    select
                                    title={'Edit'}
                                    onClick={() => handleOnOpenEdit(el.id)}
                                    image={el.photo}
                                    height={400}
                                    width={350}
                                    key={index}
                                />
                            )
                        })}
                    </Slider>
                }
            </Grid>
        </Grid>
    );
}

export default Section;
