import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, Typography, Button, Checkbox } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete'

import BG_IMAGE from '../../../assets/club.png'
import { InputGenerator, SendingButton as SendingButtonChild } from '../../common'
import { BLACK, PRIMARY_COLOR } from '../../../utils/constants'
import { computeArrayOfInputsWithError } from '../../../utils/helpers/validator'
import { BootstrapButton } from '../styles'
import { toast } from 'react-toastify'

const TypeOrgaization = ({ handlePrev, handleSubmit, sending, error }) => {
    const { t } = useTranslation()

    const INPUTS = [
        {
            name: 'name',
            placeholder: t('input.placeholder.companyName'),
            label: t('input.label.companyName'),
            value: '',
            type: 'text',
            level: 1,
            mandatory: true
        },
        {
            name: 'brandName',
            placeholder: t('input.placeholder.businessNameOrganization'),
            label: t('input.label.businessName'),
            value: '',
            type: 'text',
            level: 1,
            mandatory: true
        },
        {
            name: 'firstName',
            placeholder: t('input.placeholder.firstName'),
            label: t('input.label.firstName'),
            value: '',
            type: 'text',
            level: 2,
            mandatory: true
        },
        {
            name: 'lastName',
            placeholder: t('input.placeholder.lastName'),
            label: t('input.label.lastName'),
            value: '',
            type: 'text',
            level: 2,
            mandatory: true
        },
        {
            name: 'email',
            placeholder: t('input.placeholder.email'),
            label: t('input.label.email'),
            value: '',
            type: 'text',
            level: 3,
            mandatory: true
        },
        {
            name: 'phoneNumber',
            placeholder: t('input.placeholder.phoneNumber'),
            label: t('input.label.phoneNumber'),
            value: '',
            type: 'phone',
            level: 3,
            mandatory: true
        },
        {
            name: 'password',
            placeholder: t('input.placeholder.password'),
            label: t('input.label.password'),
            value: '',
            type: 'password',
            level: 4,
            mandatory: true
        },
        {
            name: 'confirmPassword',
            placeholder: t('input.placeholder.confirmPassword'),
            label: t('input.label.confirmPassword'),
            value: '',
            type: 'password',
            level: 5,
            mandatory: true
        },
        {
          name: 'country',
          placeholder: t('input.placeholder.country'),
          label: t('input.label.country'),
          value: '',
          type: 'autocomplete',
          level: 6,
          mandatory: true
        },
        {
          name: 'city',
          placeholder: t('input.placeholder.city'),
          label: t('input.label.city'),
          value: '',
          type: 'autocomplete',
          level: 6,
          mandatory: true,
          countryCode : ''
        }
    ]

    const [inputs, setInputs] = useState([])
    const [termsAccepted, setTermsAccepted] = useState(false)
    const [wrongInputs, setWrongInputs] = useState([])

    useEffect(() => {
        if (error === 'EMAIL_EXISTS') {
            setWrongInputs((prevValue) => {
                const prevValueWithoutEmail = [...prevValue].filter((el) => el.name !== 'email')
                return [...prevValueWithoutEmail, { name: 'email', messageKey: 'EMAIL_EXISTS' }]
            })
        } else if (error === 'PHONE_NUMBER_EXISTS') {
            setWrongInputs((prevValue) => {
                const prevValueWithoutEmail = [...prevValue].filter((el) => el.name !== 'phoneNumber')
                return [
                    ...prevValueWithoutEmail,
                    { name: 'phoneNumber', messageKey: 'PHONE_NUMBER_EXISTS' }
                ]
            })
        }
    }, [error])

    useEffect(() => {
        setInputs([...INPUTS])
    }, [])

    const handleOnChange = (event) => {
        setWrongInputs((prevValues) => [...prevValues.filter((el) => el.name !== event.target.name)])
        setInputs((prevValues) => {
            const inputPos = prevValues.findIndex((f) => f.name === event.target.name)
            if (inputPos < 0) return [...prevValues]

            const prevValuesCopy = [...prevValues]
            prevValuesCopy[inputPos].value = event.target.value

            return prevValuesCopy
        })
    }

    const mapInputToCompany = (input) => {
        if (input === 'name') return 'name'
        if (input === 'brandName') return 'brandName'
        if (input === 'businessOwner') return 'ownerName'
        if (input === 'businessEmail') return 'email'
        if (input === 'businessEmail') return 'email'
        if (input === 'locationName') return 'locationName'
        if (input === 'domain') return 'domain'
        if (input === 'city') return 'city'
        if (input === 'country') return 'country'
    }

    const handleOnSubmit = async () => {
        let wrong = false
        const inputsWithError = computeArrayOfInputsWithError(inputs)
        const userInputs = [
            'firstName',
            'lastName',
            'phoneNumber',
            'email',
            'domain',
            'password',
            'confirmPassword'
        ]
        const companyInputs = ['name', 'brandName', 'city', 'country']

        if (inputsWithError && inputsWithError.length > 0) {
            setWrongInputs([...inputsWithError])
            wrong = true
        }

        const registerData = {
            user: {},
            organization: {}
        }

        userInputs.forEach((input) => {
            const inputPos = inputs.findIndex((f) => f.name === input)

            if (inputPos < 0) return

            registerData['user'][input] = inputs[inputPos].value
        })

        companyInputs.forEach((input) => {
            const inputPos = inputs.findIndex((f) => f.name === input)
            if (inputPos < 0) return

            registerData['organization'][mapInputToCompany(input)] = inputs[inputPos].value
        })

        if (registerData.user.password !== registerData.user.confirmPassword) {
            setWrongInputs([
                ...inputsWithError,
                { name: 'confirmPassword', messageKey: 'password_match' }
            ])
            wrong = true
        }

        if (wrong) return

        if (registerData['organization']['city'] && registerData['organization']['country']) {
            registerData['organization']['locationName'] = `${registerData['organization']['city']}, ${registerData['organization']['country']}`

            try {
                await geocodeByAddress(registerData['organization']['locationName'])
                .then(result => {
                    getLatLng(result[0])
                        .then(({ lat, lng }) => {
                            registerData['organization']['locationLat'] = String(lat);
                            registerData['organization']['locationLng'] = String(lng);
                        })
                })
            } catch (error) {
                return toast.error(t('helper.countryOrCityNotValid'), { position: 'bottom-right', autoClose: false })
            }
        }

        registerData['user']['acceptedTerms'] = true

        await handleSubmit(registerData, 'organization')
    }

    const handleOnChangeTerms = (event) => setTermsAccepted(event.target.checked)

    const filterInputs = useCallback(
        (level) => inputs.filter((input) => input.level === level),
        [inputs]
    )

    const computeHelperText = (input) => {
        if (wrongInputs.map((el) => el.name).includes(input.name)) {
            if (input.value === '' && input.name !== 'confirmPassword')
                return `${input.label} ${t('signup.cantBeEmpty')}`
            if (input.name === 'email' || input.name === 'phoneNumber' || input.name === 'domain') {
                const inputIndex = wrongInputs.findIndex((el) => el.name === input.name)
                if (inputIndex >= 0) {
                    return t(`input.error.${wrongInputs[inputIndex].messageKey}`)
                }
            }
            return t(`input.error.${input.name}`)
        }
    }

    const handleOnChangePhone = (phone, name) => {
        setWrongInputs((prevValues) => [...prevValues.filter((el) => el.name !== name)])
        setInputs((prevValues) => {
            const inputPos = prevValues.findIndex((f) => f.name === name)
            if (inputPos < 0) return [...prevValues]

            const prevValuesCopy = [...prevValues]
            prevValuesCopy[inputPos].value = phone

            return prevValuesCopy
        })
    }

    const handleOnChangeGoogle = async (name, value, placeId) => {
        let countryCode;

        if (name === 'country') {
            await geocodeByPlaceId(placeId)
              .then(result => countryCode = result[0].address_components[0].short_name)
    
            if (!countryCode) return;
        }
    
        setInputs((prevValues) => {
            const inputPos = prevValues.findIndex((f) => f.name === name)
            if (inputPos < 0) return [...prevValues]
      
            const prevValuesCopy = [...prevValues]
            prevValuesCopy[inputPos].value = value

            if (name === 'country') prevValuesCopy[inputPos + 1].countryCode = countryCode

            return prevValuesCopy
        })
      }

    const renderForm = useMemo(() => {
        const levels = inputs.map((el) => el.level)
        const levelsWithoutDuplicates = levels.filter((element, index) => {
            return levels.indexOf(element) === index
        })

        return (
            <Grid item xs={12} lg={6} p={{ xs: 4, lg: 10 }} pr={{ xs: 2 }}>
                <Grid container height="100%" alignItems="center">
                    <Grid container direction="column" rowSpacing={4}>
                        <Grid item>
                            <Typography fontWeight="bold" variant="h4" style={{ color: BLACK }}>
                                {t('title.aboutYouAndCompany')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container flexDirection="column">
                                {levelsWithoutDuplicates.map((level, index) => {
                                    const inputsToUse = filterInputs(level)
                                    return (
                                        <Grid pb={3} key={index} container flexDirection="row">
                                            {inputsToUse.map((inp, idx) => {
                                                return (
                                                    <Grid
                                                        pr={{ lg: idx !== inputsToUse.length - 1 ? 4 : 0, xs: 0 }}
                                                        lg={12 / inputsToUse.length}
                                                        xs={12}
                                                        key={idx}
                                                        item
                                                    >
                                                        <InputGenerator
                                                            input={{ ...inp, error: computeHelperText(inp) }}
                                                            handleOnChangeGoogle={handleOnChangeGoogle}
                                                            handleOnChangePhone={handleOnChangePhone}
                                                            handleOnChange={handleOnChange}
                                                            extraLabel={
                                                                inp.name === 'domain'
                                                                    ? t('helper.thisWillBeUnique')
                                                                    : ''
                                                            }
                                                            error={wrongInputs.map((el) => el.name).includes(inp.name)}
                                                            helperText={computeHelperText(inp)}
                                                        />
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            <Grid
                                maxWidth={{ lg: '75%', xs: '90%' }}
                                container
                                flexDirection="row"
                                alignItems="center"
                            >
                                <Grid item>
                                    <Checkbox sx={{
                                        color: BLACK,
                                        '&.Mui-checked': {
                                            color: BLACK,
                                        },
                                    }} onChange={handleOnChangeTerms} checked={termsAccepted} />
                                </Grid>
                                <Grid
                                    maxWidth={{ lg: '75%', xs: '90%' }}
                                    container
                                    flexDirection="row"
                                    alignItems="center"
                                >
                                    <Grid xs={10} item>
                                        <Typography variant="p" fontSize={14}>
                                            {t('helper.agreeWith')}
                                            <Typography
                                                variant="p"
                                                color={PRIMARY_COLOR}
                                                fontWeight={600}
                                                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                                                onClick={() => window.open('https://sportin.io/privacy-policy/')}
                                            >
                                                &nbsp;{t('helper.privacyPolicy')}&nbsp;
                                            </Typography>{' '}
                                            {t('helper.asWell')}
                                            <Typography
                                                variant="p"
                                                fontWeight={600}
                                                color={PRIMARY_COLOR}
                                                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                                                onClick={() => window.open('https://sportin.io/privacy-policy/')}
                                            >
                                                &nbsp;{t('helper.termsAndConditions')}&nbsp;
                                            </Typography>{' '}
                                            {t('helper.ofSportIn')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item display="flex" flexDirection="row">
                            <Grid container display="flex" flexDirection="row">
                                <Grid item paddingRight={{ lg: 4, xs: 1 }} xs={6} lg={4}>
                                    <BootstrapButton fullWidth onClick={handlePrev} style={{ color: BLACK }} variant="outlined">
                                        {t('button.back')}
                                    </BootstrapButton>
                                </Grid>
                                <Grid item paddingLeft={{ lg: 4, xs: 1 }} xs={6} lg={8}>
                                    <Button
                                        disabled={sending || !termsAccepted}
                                        fullWidth
                                        onClick={async () => await handleOnSubmit()}
                                        style={{ backgroundColor: BLACK, color: '#fff' }}
                                        variant="contained"
                                    >
                                        {!sending ? t('button.getStarted') : <SendingButtonChild />}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }, [inputs, sending, handleOnSubmit, termsAccepted, wrongInputs])

    const renderImage = useMemo(() => {
        return (
            <Grid
                item
                alignItems="center"
                display={{ xs: 'none', sm: 'none', lg: 'block' }}
                lg={6}
                maxHeight={'98vh'}
            >
                <img
                    style={{ position: 'relative', left: '3%', top: '-30%', objectFit: 'cover' }}
                    height={'150%'}
                    src={BG_IMAGE}
                    width={1200}
                />
            </Grid>
        )
    }, [])

    return (
        <Grid container style={{ padding: 0, overflowX: 'hidden' }} height="100%">
            {renderForm}
            {renderImage}
        </Grid>
    )
}

export default TypeOrgaization
