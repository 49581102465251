import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import { TableCell, Box, Typography } from '@mui/material';
import { GRAY_BG_COLOR } from '../../../../../utils/constants';

const DateOfPaymentCell = ({ index, userPaidAt, superAdminPaidAt, paymentStatus }) => (
    <TableCell sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '4%', border: 'none !important', width: '15%' }} key={`dateOfPayment-${index}`}>
        <Box display={'flex'} alignItems={'center'}>
            <SouthIcon sx={{ color: userPaidAt ? "#00A83A" : 'rgba(0, 0, 0, 0.87)' }} />
            <NorthIcon sx={{ marginLeft: -1, color: paymentStatus === 'PAID' ? "#0664F2" : GRAY_BG_COLOR }} />
        </Box>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
            <Typography fontSize={'1rem'}>{userPaidAt ? userPaidAt : "-"}</Typography>
            <Typography fontSize={'1rem'}>{superAdminPaidAt ? superAdminPaidAt : "-"}</Typography>
        </Box>
    </TableCell>
);

export default DateOfPaymentCell;