/* eslint-disable no-unused-vars */
import { Close } from '@mui/icons-material'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    Button,
    DialogActions,
    IconButton,
    Container,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-quill/dist/quill.snow.css'
import * as COMPANY_REQUEST from '../../../api/company'
import * as TRAINER_REQUEST from '../../../api/trainer'
import * as ORGANIZATION_REQUEST from '../../../api/organization'
import { SendingButton } from '../../common'
import Form from '../Form'
import DeleteDealDialog from '../Delete'
import validator from 'validator'

const EditDealsDialog = ({ handleClose, type, handleGetAllDeals, Transition, dealId }) => {
    const { t } = useTranslation()
    const [linkDeal, setLinkDeal] = useState('')
    const [errorLink, setErrorLink] = useState('')
    const [profilePhoto, setProfilePhoto] = useState('')
    const [loading, setLoading] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)

    const handleGet = async (dealId) => {
        if (type === 'Company') {
            await COMPANY_REQUEST.getDeal(dealId).then(response => {
                setLinkDeal(response.link)
                setProfilePhoto(response.photo)
            })

        } else if (type === "Organization") {
            await ORGANIZATION_REQUEST.getDeal(dealId).then(response => {
                setLinkDeal(response.link)
                setProfilePhoto(response.photo)
            })
        } else {
            await TRAINER_REQUEST.getDeal(dealId).then(response => {
                setLinkDeal(response.link)
                setProfilePhoto(response.photo)
            })
        }
    }

    const handleDeleteDeal = async (dealId) => {
        try {
            setLoadingDelete(true)
            if (type === 'Company') {
                await COMPANY_REQUEST.deleteDeal(dealId).then(() =>
                    handleClose()
                )

            } else if (type === 'Organization') {
                await ORGANIZATION_REQUEST.deleteDeal(dealId).then(() =>
                    handleClose()
                )

            } else {
                await TRAINER_REQUEST.deleteDeal(dealId).then(() =>
                    handleClose()
                )
            }
        } catch (error) {
            console.error(error);
            setLoadingDelete(false)
        }

    }
    useEffect(() => {
        handleGet(dealId)
    }, [type, dealId])

    const handleSubmit = async () => {
        try {
            setLoading(true)
            if (validator.isURL(linkDeal)) {
                if (dealId) {
                    const payload = {
                        link: linkDeal,
                        photo: profilePhoto
                    }
                    if (type === 'Company') {
                        await COMPANY_REQUEST.updateDeal(dealId, payload)

                    } else if (type === 'Organization') {
                        await ORGANIZATION_REQUEST.updateDeal(dealId, payload)

                    } else {
                        await TRAINER_REQUEST.updateDeal(dealId, payload)
                    }
                }
                setLoading(false)
                handleClose()
            } else {
                setErrorLink(t('deals.errorLink'))
                setLoading(false)
            }
        } catch (error) {
            console.error(error);
            setLoading(false)
        }
    }

    const computeSubmitButtonDisabled = () => {
        if (!linkDeal || !profilePhoto) return true

        return false
    }
    const handleDelete = async () => {
        await handleDeleteDeal(dealId)
        setOpenDeleteDialog(false)
        handleGetAllDeals()
    }
    return (
        <>
            {openDeleteDialog ? (
                <DeleteDealDialog
                    title={t('deals.deleteDeal')}
                    text={t('deals.confirmDelete')}
                    handleDelete={handleDelete}
                    handleClose={() => {
                        setOpenDeleteDialog(false)
                    }}
                />
            ) : null}
            <Dialog
                open={true}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={'xl'}
                fullScreen={true}
                PaperProps={{
                    style: {
                        borderRadius: 0,
                        paddingTop: '4%'
                    }
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Container maxWidth="xl">
                    <DialogTitle
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        p={4}
                        pl={{ xs: 2, md: 2, lg: 7 }}
                        pr={{ xs: 2, md: 2, lg: 7 }}
                    >
                        <Grid container display="flex" flexDirection="row">
                            <Grid item xs={10}>
                                <Typography variant="h5" fontWeight="bold">
                                    {t('deals.editTitle')}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                                <IconButton style={{ color: 'black' }} onClick={handleClose}>
                                    <Close fontSize="medium" color="inherit" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container display="flex" flexDirection="column" p={{ lg: 4, xs: 0 }}>
                            <Grid item xs={12} pt={4}>
                                <Form setErrorLink={setErrorLink} errorLink={errorLink} setProfilePhoto={setProfilePhoto} profilePhoto={profilePhoto} linkDeal={linkDeal} setLinkDeal={setLinkDeal} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ paddingBottom: 8 }}>
                        <Grid
                            container
                            display="flex"
                            flexDirection={{ lg: 'row', xs: 'column' }}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item pr={{ xs: 0, lg: 1 }}>
                                <Button
                                    sx={{ minWidth: 250 }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleClose()}
                                >
                                    {t('course.cancel')}
                                </Button>
                            </Grid>
                            <Grid item pl={{ xs: 0, lg: 1 }} mt={{ lg: 0, xs: 1 }}>
                                <Button
                                    disabled={computeSubmitButtonDisabled() || loading}
                                    sx={{ minWidth: 250 }}
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        handleSubmit()
                                    }
                                >
                                    {loading ? <SendingButton /> : t('deals.save')}
                                </Button>
                            </Grid>
                            <Grid item pl={{ xs: 0, lg: 1 }} mt={{ lg: 0, xs: 1 }}>
                                <Button
                                    disabled={computeSubmitButtonDisabled() || loading}
                                    sx={{ minWidth: 250 }}
                                    variant="contained"
                                    color="error"
                                    onClick={() =>
                                        setOpenDeleteDialog(true)
                                    }
                                >
                                    {loadingDelete ? <SendingButton /> : t('deals.delete')}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Container>
            </Dialog>
        </>
    )
}

export default EditDealsDialog
