import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { Link } from 'react-router-dom'

import CHECKOUT_ERROR from '../../../assets/error.gif'
import { useTranslation } from 'react-i18next'

export default () => {
    const { t } = useTranslation()
    return (
        <Box
            style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 10 }}
        >
            <Box style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img height={'60%'} width={'60%'} src={CHECKOUT_ERROR} />
            </Box>
            <Typography variant="h6" fontWeight={600} gutterBottom textAlign="center" maxWidth="80%">
                <strong>{t('selfin.ops')}</strong> {t('selfin.youDontSeem')}
            </Typography>
            <Link to="/" style={{ textDecoration: 'none', marginTop: 30 }}>
                <Button variant="outlined" color="error">
                    {t('selfin.goToMyProfile')}
                </Button>
            </Link>
        </Box>
    )
}