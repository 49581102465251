export const PRIMARY_COLOR = '#0075EB'
export const SECONDARY_COLOR = '#0067CF'
export const ERROR_COLOR = '#FF3838'
export const ERROR_HOVER_COLOR = '#DD2323'
export const WHITE_COLOR = '#FFFFFF'
export const GRAY_BG_COLOR = '#E3E3E3'
export const GRAY_TEXT_COLOR = '#B0B0B0'
export const CARD_TEXT_COLOR = '#9CB2CD'
export const LIGHT_PRIMARY_COLOR = '#F5F9FD'
export const LIGHT_GRAY_BORDER_COLOR = '#F3F3F3'
export const SECONDARY_TEXT_COLOR = '#292838'
export const LIGHT_GRAY_CHART = 'rgb(224, 224, 224)'
export const BLUE_CHART = 'rgb(0, 117, 235)'
export const CHIP_BLUE_COLOR = '#F1F8FF'
export const GREEN_COLOR = '#22B24C'
export const LIGHT_BLUE_COLOR = '#E3EEFC'
export const GRAY_BORDER_COLOR = '#CCD9E9'
export const YELLOW_CHIP_COLOR = '#FFF9C4'
export const GREEN_CHIP_COLOR = '#DCEDC8'
export const RED_CHIP_COLOR = '#FBE9E7'
export const BLACK_BACKGROUND_COLOR = '#292838'
export const LIGHT_GREEN_COLOR = '#e8f5e9'
export const LIGHT_YELLOW_COLOR = '#fffde7'
export const LIGHT_RED_COLOR = '#fbe9e7'
export const ORANGE_COLOR = '#ffac33'
export const BLACK = '#000'

export const AFFILIATE_DOMAINS = {
  ABC: 'abcfitnessschool'
}
export const VOUCHER_TYPES = {
  RECURRING: 'RECURRING',
  DISCOUNT: 'DISCOUNT',
  CONSECUTIVE_BUYS: 'CONSECUTIVE_BUYS',
  CUSTOM: 'CUSTOM'
}
export const TYPE_ATHLETE = 'INDIVIDUAL'
export const TYPE_ORG = 'ORGANIZATION'
export const TYPE_COMPANY = 'COMPANY'
export const TYPE_TRAINER = 'TRAINER'
export const DEALS = 'DEALS'
export const CHALLENGES = 'CHALLENGES'

export const LANGUAGE_EN = 'EN'
export const LANGUAGE_RO = 'RO'
export const GENDER_MALE = 'MALE'
export const GENDER_FEMALE = 'FEMALE'
export const GENDER_OTHER = 'OTHER'
export const ROLE_BUSINESS_ADMINISTRATOR = 'BUSINESS_ADMINISTRATOR'
export const ROLE_TRAINER = 'TRAINER'
export const ROLE_ORGANIZATION = 'ORGANIZATION'
export const ROLE_USER = 'USER'
export const SUPER_ADMIN = 'SUPER_ADMIN'
export const ECREDITS_COMPANY_ADMIN_ROLE = 'ECREDITS_COMPANY_ADMIN'
export const ECREDIST_COMPANY_EMPLOYEE_ROLE = 'ECREDITS_COMPANY_EMPLOYEE'
export const BUSINESS_ROLES = {
  BUSINESS_EMPLOYEE: 'BUSINESS_EMPLOYEE'
}
export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm'
export const DEFAULT_MAX_LENGTH = 255
export const LONG_DEFAULT_MAX_LENGTH = 5000
export const EXTRA_LONG_DEFAULT_MAX_LENGTH = 10000
export const BUSINESS_USER_REQUEST_STATUS = {
  DECLINED: 'DECLINED',
  ACCEPTED: 'ACCEPTED',
  PENDING: 'PENDING',
  DELETED: 'DELETED'
}
export const COMPANIES_TRAINERS_REQUEST_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  DENIED: 'DENIED'
}
export const USER_SUBSCRIPTION_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PAUSED: 'PAUSED',
  DEFAULT: 'DEFAULT'
}
export const USER_SUBSCRIPTION_PAYMENT_STATUS = {
  PAID: 'PAID',
  UNPAID: 'UNPAID'
}
export const SUBSCRIPTION_PAYMENT_STATUS = {
  CONFIRMED: 'CONFIRMED',
  PENDING: 'PENDING',
  CANCELED: 'CANCELED',
  EXPIRED: 'EXPIRED'
}
export const SUBSCRIPTION_EXPIRE_TYPES = {
  DAY_DURATION: {
    value: 'DAY_DURATION'
  }
}
export const SUBSCRIPTION_TYPES = {
  FULL_TIME: 'FULL_TIME',
  PART_TIME: 'PART_TIME'
}
export const SUBSCRIPTION_VISIBILITY = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC'
}
export const PAYMENT_METHOD = {
  ONLINE: 'ONLINE',
  CASH: 'CASH',
  CARD: 'CARD',
  BANK_TRANSFER: 'BANK_TRANSFER'
}
export const EQUIPMENTS_TYPES = {
  WEIGHT_PLATES: 'WEIGHT_PLATES',
  HYPOXI_VACUUM_BYKE: 'HYPOXI_VACUUM_BYKE',
  SLED_PUSH: 'SLED_PUSH',
  TREADMIL: 'TREADMIL',
  ELLIPTICAL_MACHINE: 'ELLIPTICAL_MACHINE',
  INDOOR_EXERCISE_SPIN_BIKE: 'INDOOR_EXERCISE_SPIN_BIKE',
  ROWING_MACHINE: 'ROWING_MACHINE',
  RACUMBENT_EXERCISE_BIKE: 'RACUMBENT_EXERCISE_BIKE',
  VERTICAL_CLIMBER: 'VERTICAL_CLIMBER',
  DUMBELLS: 'DUMBELLS',
  BARBELL: 'BARBELL',
  DIP_BARS: 'DIP_BARS',
  PUSHUP_BARS: 'PUSHUP_BARS',
  PULLUP_BARS: 'PULLUP_BARS',
  SUSPENSION_TRAINER_TRX: 'SUSPENSION_TRAINER_TRX',
  BATTLE_ROPES: 'BATTLE_ROPES',
  KETTLEBELL: 'KETTLEBELL',
  FOAM_ROLLER: 'FOAM_ROLLER',
  RESISTANCE_BAND: 'RESISTANCE_BAND',
  PLYOMETRIC_BOX: 'PLYOMETRIC_BOX',
  JUMP_ROPES: 'JUMP_ROPES',
  AB_ROLLER: 'AB_ROLLER',
  STABILITY_BALL: 'STABILITY_BALL',
  GYM_MATS: 'GYM_MATS',
  MEDICINE_BALL: 'MEDICINE_BALL',
  AGILITY_LADDER: 'AGILITY_LADDER',
  ROPE_CLIMBING: 'ROPE_CLIMBING',
  GYMNASTIC_RINGS: 'GYMNASTIC_RINGS'
}
export const SPORTTYPES_TYPES = {
  ANIMAL: 'ANIMAL',
  AEROBIC: 'AEROBIC',
  AEROBIC_STEP: 'AEROBIC_STEP',
  AERIAL_FITNESS: 'AERIAL_FITNESS',
  ACTIVE_RECOVERY: 'ACTIVE_RECOVERY',
  BALET: 'BALET',
  BODYBUILDING: 'BODYBUILDING',
  BOXING: 'BOXING',
  BOOTCAMP: 'BOOTCAMP',
  CIRCUIT_TRAINING: 'CIRCUIT_TRAINING',
  CALISTHENICS: 'CALISTHENICS',
  CYCLING: 'CYCLING',
  CROSSFIT: 'CROSSFIT',
  DANCE: 'DANCE',
  EMS_TRAINING: 'EMS_TRAINING',
  FOOTBALL: 'FOOTBALL',
  GYMNASTICS: 'GYMNASTICS',
  HORSE_RIDING: 'HORSE_RIDING',
  HOME_CYCLING: 'HOME_CYCLING',
  HYPOXI_VACUUM_FITNESS: 'HYPOXI_VACUUM_FITNESS',
  HIKING: 'HIKING',
  INTERVAL_TRAINING: 'INTERVAL_TRAINING',
  INDOOR_CYCLING: 'INDOOR_CYCLING',
  KANGOO_JUMP: 'KANGOO_JUMP',
  KICKBOXING: 'KICKBOXING',
  KINESIOTHERAPY: 'KINESIOTHERAPY',
  MEDITATION: 'MEDITATION',
  MULTILEVEL_GROUP_TRAINING: 'MULTILEVEL_GROUP_TRAINING',
  MARTIAL_ARTS: 'MARTIAL_ARTS',
  NUTRITION: 'NUTRITION',
  PERSONAL_TRAINING: 'PERSONAL_TRAINING',
  PILATES: 'PILATES',
  POLE_FITNESS: 'POLE_FITNESS',
  POSTURAL_ALIGNMENT: 'POSTURAL_ALIGNMENT',
  PHYSIOTHERAPY: 'PHYSIOTHERAPY',
  PREGNANT_TRAINING: 'PREGNANT_TRAINING',
  RUNNING: 'RUNNING',
  SWIMMING: 'SWIMMING',
  STRENGTH_CONDITION: 'STRENGTH_CONDITION',
  SPARTAN_RACE: 'SPARTAN_RACE',
  SUSPENSION_TRAINING: 'SUSPENSION_TRAINING',
  TENNIS: 'TENNIS',
  TRAIL_RUNNING: 'TRAIL_RUNNING',
  VOLLEYBALL: 'VOLLEYBALL',
  WEIGHT_TRAINING: 'WEIGHT_TRAINING',
  WORKSHOP: 'WORKSHOP',
  YOGA: 'YOGA',
  ZUMBA: 'ZUMBA',
}
export const ACTION_TYPES = {
  BOOK: 'BOOK',
  UN_BOOK: 'UN_BOOK',
  EDIT_EVENT: 'EDIT_EVENT',
  CHECK_IN: 'CHECK_IN',
  RETURN_CREDITS_EVENT: 'RETURN_CREDITS_EVENT',
  RETURN_CREDITS_SERVICE: 'RETURN_CREDITS_SERVICE'
}
export const OUTCOMES = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED'
}
export const FAVORITE_OPTIONS = {
  SHOW_ALL: "show_all",
  SHOW_FAVORITES: "show_favorites"
}
export const CALENDAR_SERVICES_TYPES = {
  BASKETBALL: 'BASKETBALL',
  BOWLING: 'BOWLING',
  FOTBAL: 'FOTBAL',
  KARTING: 'KARTING',
  MASSAGE: 'MASSAGE',
  ONLINE: 'ONLINE',
  OTHERS: 'OTHERS',
  PADEL: 'PADEL',
  PAINTBALL: 'PAINTBALL',
  SQUASH: 'SQUASH',
  SNOOKER: 'SNOOKER',
  TENNIS_CLAY: 'TENNIS_CLAY',
  TENNIS_GRASS: 'TENNIS_GRASS',
  TENNIS_TABLE: 'TENNIS_TABLE',
  DIETICIAN: 'DIETICIAN',
  HORSE: 'HORSE',
  HYPOXI_VACUUM_FITNESS: 'HYPOXI_VACUUM_FITNESS',
  INDOOR_CLIMB: 'INDOOR_CLIMB',
  PHYSIO: 'PHYSIO',
  SHOOTING: 'SHOOTING',
  AEROBIC: 'AEROBIC',
  AERIAL_FITNESS: 'AERIAL_FITNESS',
  EMS_TRAINING: 'EMS_TRAINING',
  HORSE_RIDING: 'HORSE_RIDING',
  HIKING: 'HIKING',
  INDOOR_CYCLING: 'INDOOR_CYCLING',
  MEDITATION: 'MEDITATION',
  NUTRITION: 'NUTRITION',
  PERSONAL_TRAINING: 'PERSONAL_TRAINING',
  PILATES: 'PILATES',
  POLE_FITNESS: 'POLE_FITNESS',
  PHYSIOTHERAPY: 'PHYSIOTHERAPY',
  SPARTAN_RACE: 'SPARTAN_RACE',
  TENNIS: 'TENNIS',
  TRAIL_RUNNING: 'TRAIL_RUNNING',
  VOLLEYBALL: 'VOLLEYBALL',
  YOGA: 'YOGA',
  ZUMBA: 'ZUMBA',
}
export const ROLE_USER_OPTIONS = [
  // {
  //   name: 'explore',
  //   active: true,
  //   navigate: '/'
  // },
  {
    name: 'dashboard',
    active: false,
    navigate: '/dashboard'
  },
  {
    name: 'calendar',
    active: false,
    navigate: '/'
  },
  {
    name: 'measurements',
    active: false,
    navigate: '/measurements'
  }
]
export const ROLE_TRAINER_OPTIONS = [
  // {
  //   name: 'price',
  //   active: false,
  //   navigate: '/price'
  // },
  // {
  //   name: 'explore',
  //   active: false,
  //   navigate: '/'
  // },
  {
    name: 'dashboard',
    active: true,
    navigate: '/'
  },
  {
    name: 'activities',
    active: true,
    navigate: '/activities'
  },
  {
    name: 'measurements',
    active: false,
    navigate: '/measurements'
  },
  {
    name: 'calendar',
    active: false,
    navigate: '/'
  },
  // {
  //   name: 'reports',
  //   active: false,
  //   navigate: '/'
  // },
  {
    name: 'clients',
    active: false,
    navigate: '/clients'
  }
]
export const ROLE_BUSINESS_EMPLOYEE_OPTIONS = [
  // {
  //   name: 'explore',
  //   active: false,
  //   navigate: '/explore'
  // },
  {
    name: 'dashboard',
    active: true,
    navigate: '/dashboard'
  },
  {
    name: 'calendar',
    active: false,
    navigate: '/'
  },
  {
    name: 'activities',
    active: true,
    navigate: '/activities'
  },
  {
    name: 'checkin',
    active: false,
    navigate: '/'
  },
  {
    name: 'clients',
    active: false,
    navigate: '/clients'
  }
]
export const ROLE_BUSINESS_ADMINISTRATOR_OPTIONS = [
  // {
  //   name: 'explore',
  //   active: false,
  //   navigate: '/explore'
  // },
  {
    name: 'dashboard',
    active: true,
    navigate: '/dashboard'
  },
  {
    name: 'calendar',
    active: false,
    navigate: '/'
  },
  {
    name: 'activities',
    active: true,
    navigate: '/activities'
  },
  {
    name: 'checkin',
    active: false,
    navigate: '/'
  },
  {
    name: 'clients',
    active: false,
    navigate: '/clients'
  },
]
export const BOTTOMBAR_OPTIONS = [
  {
    name: 'calendar',
    active: false,
    navigate: '/calendar'
  }
]
export const MAIN_MEASUREMENTS = {
  FAT_PERCENTAGE: 'FAT_PERCENTAGE',
  MEASUREMENT: 'MEASUREMENT',
  WEIGHT: 'WEIGHT',
  CUSTOM: 'CUSTOM'
}
export const EVENT_COLORS = [
  '#0277BD',
  '#2E7D32',
  '#D84315',
  '#37474F',
  '#8E24AA',
  '#EF6C00',
  '#00838F'
]