import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Container, Grid, Slide, useMediaQuery } from '@mui/material'
import moment from 'moment/min/moment-with-locales'
import { useParams, useSearchParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { toast } from 'react-toastify'
import CircularProgress from '@mui/material/CircularProgress';
import * as CALENDAR_REQUESTS from '../../api/calendar'
import * as MESSAGE_REQUESTS from '../../api/message'
import * as USER_REQUESTS from '../../api/user'
import * as TRANSACTION_INFORMATION_REQUESTS from '../../api/transactionInformations'
import * as COMPANY_REQUESTS from '../../api/company'
import * as TRAINER_REQUESTS from '../../api/trainer'
import { BUSINESS_ROLES, ROLE_BUSINESS_ADMINISTRATOR, ROLE_TRAINER, ROLE_USER, SUBSCRIPTION_PAYMENT_STATUS } from '../../utils/constants'
import { useAuthentication } from '../../context/auth-context'

import { CalendarHeader, CalendarDays, CalendarCreateEvent, CalendarEventDetailsModal, WaitingListModal, PaymentEventModal, CalendarEditEvent, ListOfTrainersModal, SubscriptionSelectorModal, CalendarDaysList } from './subcomponents'
import DeleteEventDialog from './subcomponents/DeleteEventModal'
import MessageCompanyDialog from '../PublicBusinessProfile/subcomponents/subcomponents/subcomponents/MesssageDialog'
import { getParameterByName, insertParam, removeParams } from '../../utils/helpers/url'
import { useTranslation } from 'react-i18next'
import CalendarCreateService from './subcomponents/CalendarCreateService'

const Calendar = () => {
  const { t, i18n } = useTranslation()
  const { user } = useAuthentication()
  const theme = useTheme()
  const matchesPhone = useMediaQuery(theme.breakpoints.down('sm'))

  const isTrainer = user && user.role && user.role.toLowerCase() === ROLE_TRAINER.toLowerCase()
  const isClub =
    user && user.role && user.role.toLowerCase() === ROLE_BUSINESS_ADMINISTRATOR.toLowerCase()
  const isUser = user && user.role && user.role.toLowerCase() === ROLE_USER.toLowerCase()
  const isEmployee =
    user && user.role && user.role.toLowerCase() === BUSINESS_ROLES.BUSINESS_EMPLOYEE.toLowerCase()

  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const [selectedFavorites, setSelectedFavorites] = useState([])
  const [showEmptyServices, setShowEmptyServices] = useState(false)

  const [loadingEvents, setLoadingEvents] = useState(true)
  const [loadingHeaderAndDays, setLoadingHeaderAndDays] = useState(true); // New loading state
  const [loadingServices, setLoadingServices] = useState(true)
  const [customDate, setCustomDate] = useState(moment())
  const [loadingBookUnbook, setLoadingBookUnbook] = useState(false)
  const [view, setView] = useState('grid')
  const [untouchedDaysArr, setUntouchedDaysArr] = useState([])
  const [untouchedEvents, setUntouchedEvents] = useState([])
  const [untouchedServices, setUntouchedServices] = useState([])
  const [favourites, setFavourites] = useState([])
  const [onlyMine, setOnlyMine] = useState(false)
  const [showPastEvents, setShowPastEvents] = useState(false)
  const [userSubscriptions, setUserSubscriptions] = useState('unset')
  const [days, setDays] = useState([])
  const [daysServices, setDaysServices] = useState([])
  const [daysLoaded, setDaysLoaded] = useState(false)
  const [lastDay, setLastDay] = useState()
  const [search, setSearch] = useState('')
  const [currency, setCurrency] = useState('RON')
  const [createEventModal, setCreateEventModal] = useState({
    open: false
  })
  const [editEventModal, setEditEventModal] = useState({
    open: false,
    eventId: null
  })
  const [eventDetailsModal, setEventDetailsModal] = useState({
    open: false,
    eventId: null
  })
  const [deleteEventModal, setDeleteEventModal] = useState({
    open: false,
    eventId: null
  })
  const [messageModal, setMessageModal] = useState({
    open: false,
    name: '',
    type: '',
    id: null
  })
  const [waitingListModal, setWaitingListModal] = useState({
    open: false,
    event: null,
    availablePlaces: 0
  })
  const [paymentModal, setPaymentModal] = useState({
    open: false,
    eventId: null,
    type: '',
    price: 0
  })
  const [trainersModal, setTrainersModal] = useState({
    open: false,
    trainers: []
  })
  const [subscriptionSelector, setSubscriptionSelector] = useState({
    open: false,
    eventSubscriptions: [],
    event: null
  })

  useEffect(() => {
    setLoadingHeaderAndDays(true);
    if (isTrainer || isClub || isEmployee) {
      setUserSubscriptions([])
      isClub ? getCompanyCurrency() : isTrainer ? getTrainerCurrency() : ''
      setLoadingHeaderAndDays(false);
      return
    }

    handleGetUserSubscriptions().finally(() => setLoadingHeaderAndDays(false)); // Finish loading after data is fetched
  }, [isClub, isTrainer])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    const sessionId = params.get('showPaymentDoneMessage')

    if (sessionId) {
      params.delete('showPaymentDoneMessage')
      setSearchParams(params)

      TRANSACTION_INFORMATION_REQUESTS.checkStripePaymentStatus(sessionId)
        .then((response) => {
          if (response === 'succeeded') {
            toast.success('You successfully bought online', { position: 'bottom-right' })
          } else {
            toast.error('Something went wrong', { position: 'bottom-right' })
          }
        })
        .catch(() => {
          toast.error('Something went wrong', { position: 'bottom-right' })
        })
    }
  }, [window.location.search])


  const handleGetUserSubscriptions = useCallback(async () => {
    try {
      if (!user) return setUserSubscriptions([])
      const subscriptionsResponse = await USER_REQUESTS.getSubscriptions({
        userId: user.id
      })
      setUserSubscriptions(subscriptionsResponse)
    } catch (e) {
      console.error(e)
    }
  }, [user])

  const getCompanyCurrency = useCallback(async () => {
    try {
      if (!isClub) return null;

      const companyCurrency = await COMPANY_REQUESTS.getCompanyCurrency()

      setCurrency(companyCurrency.currency)
    } catch (error) {
      console.error('Error getting company currency: ', error)
    }
  }, [isClub])

  const getTrainerCurrency = useCallback(async () => {
    try {
      if (!isTrainer) return null;

      const trainerCurrency = await TRAINER_REQUESTS.getTrainerCurrency();

      setCurrency(trainerCurrency);
    } catch (error) {
      console.error(`Error getting trainer currency: ${error}`)
    }
  }, [isTrainer])

  useEffect(() => {
    const queryParamsEventId = searchParams.get('eventId')
    if (searchParams && queryParamsEventId) {
      setEventDetailsModal({
        open: true,
        eventId: queryParamsEventId
      })
    }
  }, [searchParams])

  useEffect(() => {
    if (!isUser) return
    if (userSubscriptions === 'unset') return
    if (params.domain) {
      handleGetCalendarForDomain(params.domain, null, userSubscriptions, false, null, true)
    }
    else {
      const favouritesParam = getParameterByName('favourites')
      if (favouritesParam) {
        setSelectedFavorites(favouritesParam.split(','))
        const favouritesToSet = favouritesParam.split(',').map(el => ({
          type: el.split('-')[1],
          id: el.split('-')[0]
        }))
        setFavourites(favouritesToSet)
        handleCurrentPeriod(null, userSubscriptions, onlyMine, favouritesToSet, true)
      }
      else {
        handleCurrentPeriod(null, userSubscriptions, onlyMine, [], true)
      }
    }
  }, [params, isUser, userSubscriptions, matchesPhone])

  useEffect(() => {
    if (isUser) return
    if (userSubscriptions === 'unset') return
    if (params.domain) {
      handleGetCalendarForDomain(params.domain, null, userSubscriptions, false, null, true)
    }
    else {
      handleCurrentPeriod(lastDay, userSubscriptions)
    }
  }, [params, isUser, isClub, isTrainer, userSubscriptions, matchesPhone, showPastEvents])


  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })

  const computeStatus = (subscription, eventStartDate) => {
    let currentTime = new Date().getTime()
    const subscriptionStart = moment(subscription.validFrom).utc()
    const subscriptionEnd = moment(subscription.validUntil).utc()
    const subscriptionIsBetween = moment.utc(eventStartDate).isBetween(subscriptionStart, subscriptionEnd, '[]')

    const paymentDeadline = {
      hasPaymentDeadline: !subscription.Subscription.confirmation && !!subscription.Subscription.paymentDeadline && subscription.Subscription.paymentDeadline > 0
    }

    if (paymentDeadline.hasPaymentDeadline) {
      const deadlinePayment = moment(subscriptionStart, 'YYYY-MM-DD').add(Number(subscription.Subscription.paymentDeadline), 'days').set('hours', 23).set('minutes', 59)

      paymentDeadline.isBetween = moment().utc().isBetween(subscriptionStart, deadlinePayment, '[]')
    }

    const pauseData = {
      hasPause: !!subscription.Pause
    }

    if (pauseData.hasPause) {
      const startPause = moment(subscription.Pause.startDate, 'YYYY-MM-DD').set('hours', 0).set('minutes', 1)
      const endPause = moment(subscription.Pause.endDate, 'YYYY-MM-DD').set('hours', 23).set('minutes', 59)

      pauseData.isBetween = moment().utc().isBetween(startPause, endPause, '[]')
    }

    const paidSubscription = subscription.status === SUBSCRIPTION_PAYMENT_STATUS.CONFIRMED

    const activeSubscription = subscriptionIsBetween && (subscription.Subscription.confirmation ? paidSubscription : (!paidSubscription ? (paymentDeadline.hasPaymentDeadline && paymentDeadline.isBetween) : paidSubscription)) && (subscription.Pause ? !pauseData.isBetween : true) && (subscription.limitedActivationTotal ? subscription.limitedActivationUsed < subscription.limitedActivationTotal : true)

    if (eventStartDate) {
      if (subscription.Pause && (moment(eventStartDate).utc().isBetween(moment(subscription.Pause.startDate).utc(), moment(subscription.Pause.endDate).utc(), 'day', '[]'))) {
        return "PAUSED"
      }
    }

    if (!eventStartDate && subscription.Pause && (currentTime >= new Date(subscription.Pause.startDate).getTime() && currentTime <= new Date(subscription.Pause.endDate).getTime())) {
      return "PAUSED"
    }

    if (moment(eventStartDate ? eventStartDate : null).utc().isAfter(moment(subscription.validUntil).utc())) {
      return "EXPIRED"
    }

    if (!activeSubscription) {
      return "INACTIVE"
    }

    return "ACTIVE"
  }

  const mapAndSetServices = (servicesToBeUsed, daysArray, userSubscriptionsToUse, showPastEventsToUse) => {
    if (userSubscriptionsToUse === 'unset' || servicesToBeUsed.length === 0) {
      setDaysServices(
        daysArray.map((day) => ({
          dayName: day.locale(String(i18n.language)).format('dddd').charAt(0).toUpperCase()
            + day.locale(String(i18n.language)).format('dddd').slice(1),
          dayDate: day.format('MM/DD/YYYY'),
          dayItems: []
        }))
      )
      setLoadingServices(false)
      return
    }

    let servicesPerDays = []
    servicesToBeUsed.forEach(service => {
      const daysWithDuplicates = service.Slots.map(slot => ({
        day: moment(slot.to).format('YYYY-MM-DD'),
        fullDay: moment(slot.to)
      })).sort((elA, elB) => moment(elA.fullDay).isBefore(moment(elB.fullDay)) ? 1 : -1)
      const days = daysWithDuplicates.filter((obj, index) => {
        return index === daysWithDuplicates.findIndex(o => obj.day === o.day)
      })
      days.forEach(day => {
        servicesPerDays = servicesPerDays.concat({ ...service, start: day.day, fullStart: day.fullDay })
      })
    })

    const servicesToUse = servicesPerDays.map(el => {
      return ({
        ...el,
        id: el.id,
        name: el.name,
        transparency: moment().format('YYYY-MM-DD') !== moment(el.fullStart).format('YYYY-MM-DD') ? moment().isAfter(moment(el.fullStart)) : moment().hour() > moment(el.fullStart).hour() ? true : moment().hour() === moment(el.fullStart).hour() ? moment().minute() > moment(el.fullStart).minute() ? true : false : false,
        availability: el.availability,
        costs: el.costs,
        paymentMethods: el.payment,
        valueRon: el.valueRon,
        valueCredits: el.valueCredits,
        location: el.location,
        sportType: el.sportType,
        start: moment(el.fullStart),
        trainerId: el.trainerId,
        companyId: el.companyId,
        userSubscriptions: el.userSubscriptions,
        firstChip: el.tags ? el.tags.sort((a, b) => a.length < b.length ? 1 : -1)[0] : null,
        secondChip: el.tags ? el.tags.sort((a, b) => a.length < b.length ? 1 : -1)[1] : null,
        thirdChip: el.tags ? el.tags.sort((a, b) => a.length < b.length ? 1 : -1)[2] : null,
        slots: el.Slots.filter(slot => moment(slot.from).isSame(moment(el.start), 'day')).sort((a, b) => a.from > b.from ? 1 : -1).map(slot => {
          return {
            index: slot.id,
            start: moment(slot.from).format('HH:mm'),
            end: moment(slot.to).format('HH:mm'),
            available: el.maxParticipants - slot.CSParticipants.length,
            participants: slot.CSParticipants.filter(el => !el.isInWaitingList),
            waitingList: slot.CSParticipants.filter(el => el.isInWaitingList)
          }
        }),
        type: 'service',
        trainerNames: el.CSTrainers.map(tr => `${tr.firstName}${tr.lastName}`).filter(el => el).join(','),
        participantNames: el.Slots.filter(el => el.CSParticipants.length > 0).map(slot => slot.CSParticipants.map(el => `${el.firstName} ${el.lastName}`)).filter(el => el).join(','),
        subscriptionNames: el.CSSubscriptions.map(el => el.name).filter(el => el).join(','),
        equipmentNames: el.equipment ? el.equipment.filter(el => el).join(',') : '',
        tags: el.tags ? el.tags.filter(el => el).join(',') : '',
        notes: el.notes,
        currency: el?.Company?.currency || el?.Trainer?.trainerCurrency || 'RON'
      })
    })

    setDaysServices(
      daysArray.map((day) => ({
        dayName: day.locale(String(i18n.language)).format('dddd').charAt(0).toUpperCase()
          + day.locale(String(i18n.language)).format('dddd').slice(1),
        dayDate: day.format('MM/DD/YYYY'),
        dayItems: servicesToUse
          .filter(service => {
            return moment(service.start).format('MM/DD/YYYY') === day.format('MM/DD/YYYY')
          })
          .filter(service => showPastEventsToUse ?
            isUser ?
              day.isAfter(moment()) ? true :
                moment().hour() < moment(service.fullStart).hour() ?
                  true :
                  moment().hour() === moment(service.fullStart).hour() ?
                    moment().minute() < moment(service.fullStart).minute() ?
                      onlyMine ?
                        false :
                        true :
                      onlyMine ?
                        true :
                        false :
                    onlyMine && service.CSParticipants.map(el => el.Participant.id).includes(user.id) ?
                      true :
                      false :
              true :
            moment(day).isAfter(moment()) ?
              true :
              moment().format('YYYY-MM-DD') !== moment(service.fullStart).format('YYYY-MM-DD') ?
                false :
                (moment().hour() < moment(service.fullStart).hour() ?
                  true :
                  moment().hour() === moment(service.fullStart).hour() ?
                    moment().minute() < moment(service.fullStart).minute() ?
                      true :
                      false :
                    false))
          .sort((elA, elB) => moment(elA.fullStart).isBefore(moment(elB.fullStart)) ? -1 : 1)
          .map(el => ({ ...el }))
      }))
    )
    setLoadingServices(false)
  }

  const newMapAndSetEvents = (days) => {
    const daysForDisplay = days.map(day => ({
      dayName: moment(day.day).locale(String(i18n.language)).format('dddd').charAt(0).toUpperCase()
        + moment(day.day).locale(String(i18n.language)).format('dddd').slice(1),
      dayDate: moment(day.day).format('MM/DD/YYYY'),
      dayItems: day.dayItems
    }))

    setDays(daysForDisplay)
    setLoadingEvents(false)

    if (loadingBookUnbook) {
      setLoadingBookUnbook(false)
    }
  }

  useEffect(() => {
    if (untouchedEvents.length > 0 && untouchedServices.length > 0) return
    newMapAndSetEvents(untouchedEvents || [])
    mapAndSetServices(untouchedServices || [], untouchedDaysArr, userSubscriptions, showPastEvents)
  }, [untouchedEvents, untouchedServices, untouchedDaysArr, showPastEvents])

  useEffect(() => {
    if (untouchedEvents.length === 0 || untouchedServices.length === 0 || untouchedDaysArr.length === 0) return
    newMapAndSetEvents(untouchedEvents)
    mapAndSetServices(untouchedServices, untouchedDaysArr, userSubscriptions, showPastEvents)
  }, [untouchedEvents, untouchedServices, untouchedDaysArr, showPastEvents])

  const handleGetCalendarForDomain = async (domain, startDay, userSubscriptionsToUse, onlyMineToUse, favouritesToUse, showFromToday) => {
    let daysArray = []
    let firstDay, secondDay, thirdDay
    setLoadingEvents(true)
    setLoadingServices(true)

    if (showFromToday) {
      startDay = lastDay ? moment(lastDay).subtract(matchesPhone ? 0 : 2, 'days').format('MM/DD/YYYY') : null
    }

    /* Case when first rendering the calendar without filters */
    if (!startDay) {
      firstDay = moment()
      secondDay = moment().add(1, 'days')
      thirdDay = moment().add(2, 'days')
    }
    else {
      firstDay = moment(startDay)
      secondDay = moment(startDay).add(1, 'days')
      thirdDay = moment(startDay).add(2, 'days')
    }

    if (matchesPhone) {
      daysArray = [firstDay]
    }
    else {
      daysArray = [firstDay, secondDay, thirdDay]
    }

    try {
      const daysForRequest = daysArray.map(day => day.format('MM/DD/YYYY'))

      const daysWithEvents = await CALENDAR_REQUESTS.getCalendarForDomain(domain, firstDay.format('MM/DD/YYYY'), thirdDay.format('MM/DD/YYYY'), onlyMineToUse, favouritesToUse, daysForRequest, showPastEvents, isUser, user?.id, user?.companyId, isTrainer, isClub)

      const { rows: calendarServices } = await CALENDAR_REQUESTS.getCalendarServicesByDaysForDomain(firstDay.format('MM/DD/YYYY'), thirdDay.format('MM/DD/YYYY'), domain, onlyMineToUse, favouritesToUse)

      setUntouchedEvents(daysWithEvents)
      setUntouchedServices(calendarServices)
      setUntouchedDaysArr(daysArray)
      newMapAndSetEvents(daysWithEvents)
      mapAndSetServices(calendarServices, daysArray, userSubscriptionsToUse, showPastEvents)
      setCustomDate(firstDay)
      setLastDay(matchesPhone ? firstDay : thirdDay)
      setDaysLoaded(true)
    } catch (e) {
      console.error(e)
    }
  }

  const handleGetCalendar = async (startDay, userSubscriptionsToUse, onlyMineToUse, favouritesToUse) => {
    const getFor = isTrainer ? 'trainer' : isClub ? 'company' : isEmployee ? 'company' : 'individual'
    let daysArray = []
    let firstDay, secondDay, thirdDay
    setLoadingEvents(true)
    setLoadingServices(true)

    /* Case when first rendering the calendar without filters */
    if (!startDay) {
      startDay = moment().format('MM/DD/YYYY')
    }
    firstDay = moment(startDay)
    secondDay = moment(startDay).add(1, 'days')
    thirdDay = moment(startDay).add(2, 'days')

    if (matchesPhone) {
      daysArray = [firstDay]
    }
    else {
      daysArray = [firstDay, secondDay, thirdDay]
    }

    try {
      let eventsToUse = []
      let calendarServicesToUse = []
      const daysForRequest = daysArray.map(day => day.format('MM/DD/YYYY'))

      if (getFor === 'individual') {
        const daysWithEvents = await CALENDAR_REQUESTS.getCalendarForIndividual(firstDay.format('MM/DD/YYYY'), thirdDay.format('MM/DD/YYYY'), onlyMineToUse, favouritesToUse || [], daysForRequest, showPastEvents, isUser, user.id, user.companyId, isTrainer, isClub)

        const { rows: calendarServices } = await CALENDAR_REQUESTS.getCalendarServicesByDaysForIndividual(firstDay.format('MM/DD/YYYY'), thirdDay.format('MM/DD/YYYY'), onlyMineToUse, favouritesToUse || [])

        eventsToUse = daysWithEvents
        calendarServicesToUse = calendarServices
      }
      else {
        const daysWithEvents = await CALENDAR_REQUESTS.getEventsByDays(firstDay.format('MM/DD/YYYY'), thirdDay.format('MM/DD/YYYY'), getFor, user.companyId, onlyMineToUse, favouritesToUse || [], daysArray, showPastEvents, isUser, user.id, user.companyId, isTrainer, isClub)

        const { rows: calendarServices } = await CALENDAR_REQUESTS.getCalendarServicesByDay(firstDay.format('MM/DD/YYYY'), thirdDay.format('MM/DD/YYYY'), getFor, user.companyId)

        eventsToUse = daysWithEvents
        calendarServicesToUse = calendarServices
      }

      setUntouchedServices(calendarServicesToUse)
      setUntouchedEvents(eventsToUse)
      setUntouchedDaysArr(daysArray)
      newMapAndSetEvents(eventsToUse)
      mapAndSetServices(calendarServicesToUse, daysArray, userSubscriptionsToUse, showPastEvents)
      setCustomDate(firstDay)
      setLastDay(matchesPhone ? firstDay : thirdDay)
      setDaysLoaded(true)
    } catch (e) {
      console.error(e)
    }
  }

  const handleOpenCreateEvent = useCallback((type) => {
    setCreateEventModal({
      open: true,
      type
    })
  }, [])

  const handleOnDayChanged = useCallback((day) => {
    handleGetCalendar(moment(day).format('MM/DD/YYYY'), userSubscriptions, onlyMine, favourites)
  }, [userSubscriptions, onlyMine, favourites])

  const handleNextPeriod = useCallback(() => {
    if (params.domain) {
      handleGetCalendarForDomain(params.domain, moment(lastDay).add(1, 'day').format('MM/DD/YYYY'), userSubscriptions, onlyMine, favourites)
    }
    else {
      handleGetCalendar(moment(lastDay).add(1, 'day').format('MM/DD/YYYY'), userSubscriptions, onlyMine, favourites)
    }
  }, [lastDay, userSubscriptions, matchesPhone])

  const handlePrevPeriod = useCallback(() => {
    if (params.domain) {
      handleGetCalendarForDomain(params.domain, moment(lastDay).subtract(matchesPhone ? 1 : 5, 'days').format('MM/DD/YYYY'), userSubscriptions, onlyMine, favourites)
    }
    else {
      handleGetCalendar(moment(lastDay).subtract(matchesPhone ? 1 : 5, 'days').format('MM/DD/YYYY'), userSubscriptions, onlyMine, favourites)
    }
  }, [lastDay, userSubscriptions, matchesPhone])

  const handleToday = useCallback(() => {
    if (params.domain) {
      handleGetCalendarForDomain(params.domain, moment().format('MM/DD/YYYY'), userSubscriptions, onlyMine, favourites)
    }
    else {
      handleGetCalendar(moment().format('MM/DD/YYYY'), userSubscriptions, onlyMine, favourites)
    }
  }, [lastDay, userSubscriptions])

  const handleCustomDate = useCallback((customDate) => {
    if (params.domain) {
      handleGetCalendarForDomain(params.domain, moment(customDate).format('MM/DD/YYYY'), userSubscriptions, onlyMine, favourites)
    }
    else {
      handleGetCalendar(moment(customDate).format('MM/DD/YYYY'), userSubscriptions, onlyMine, favourites)
    }
  }, [lastDay, userSubscriptions])

  const handleCurrentPeriod = useCallback((lastDayToUse, userSubscriptionsToUse, onlyMineToUse, favourtiesToUse, customLastDay) => {
    if (params.domain) {
      handleGetCalendarForDomain(params.domain, customLastDay && lastDay ? moment(lastDay).subtract(matchesPhone ? 0 : 2, 'days').format('MM/DD/YYYY') : lastDayToUse ? moment(lastDayToUse).subtract(matchesPhone ? 0 : 2, 'days').format('MM/DD/YYYY') : null, userSubscriptionsToUse, onlyMineToUse, favourtiesToUse)
    }
    else {
      handleGetCalendar(customLastDay && lastDay ? moment(lastDay).subtract(matchesPhone ? 0 : 2, 'days').format('MM/DD/YYYY') : lastDayToUse ? moment(lastDayToUse).subtract(matchesPhone ? 0 : 2, 'days').format('MM/DD/YYYY') : null, userSubscriptionsToUse, onlyMineToUse, favourtiesToUse)
    }
  }, [params, lastDay, matchesPhone, showPastEvents])

  const handleGetEventsByFavourites = useCallback((lastDayToUse, userSubscriptionsToUse, onlyMineToUse, favouritesToUse, customLastDay) => {
    if (params.domain) {
      handleGetCalendarForDomain(params.domain, customLastDay && lastDay ? moment(lastDay).subtract(matchesPhone ? 0 : 2, 'days').format('MM/DD/YYYY') : lastDayToUse ? moment(lastDayToUse).subtract(matchesPhone ? 0 : 2, 'days').format('MM/DD/YYYY') : null, userSubscriptionsToUse, onlyMineToUse, favouritesToUse || [])
    }
    else {
      handleGetCalendar(customLastDay && lastDay ? moment(lastDay).subtract(matchesPhone ? 0 : 2, 'days').format('MM/DD/YYYY') : lastDayToUse ? moment(lastDayToUse).subtract(matchesPhone ? 0 : 2, 'days').format('MM/DD/YYYY') : null, userSubscriptionsToUse, onlyMineToUse, favouritesToUse || [])
    }
  }, [lastDay, matchesPhone])

  const handleOnSearch = useCallback((newSearch) => {
    setSearch(newSearch)
  }, [])

  const renderCalendarHeader = useMemo(() => {
    return <CalendarHeader
      handleShowEmptyServices={() => setShowEmptyServices(prevValue => !prevValue)}
      showEmptyServices={showEmptyServices}
      selectedFavorites={selectedFavorites}
      handleSetSelectedFavourites={data => {
        removeParams()
        const parameterValues = data.join(',')
        insertParam('favourites', parameterValues)
        setSelectedFavorites(data)
      }}
      handleGetEvents={data => {
        if (data.length > 0) {
          setFavourites(data)
          handleGetEventsByFavourites(lastDay, userSubscriptions, onlyMine, data)
        }
        else {
          setFavourites([])
          handleCurrentPeriod(lastDay, userSubscriptions, onlyMine)
        }
      }
      }
      onlyMine={onlyMine}
      handleOnlyMine={() => {
        setOnlyMine(prevValue => !prevValue)
        handleCurrentPeriod(lastDay, userSubscriptions, !onlyMine, favourites)
      }}
      showListView={view === 'list'}
      handleShowListView={() => view === 'list' ? setView('grid') : setView('list')}
      showPastEvents={showPastEvents}
      handleShowPastEvents={() => {
        setShowPastEvents(prevValue => !prevValue)
      }}
      customDate={customDate}
      handleToday={handleToday}
      handleCustomDate={handleCustomDate}
      handleNextPeriod={handleNextPeriod}
      handlePrevPeriod={handlePrevPeriod}
      handleOpenCreateEvent={handleOpenCreateEvent}
      handleOnSearch={handleOnSearch}
    />
  }, [handleOpenCreateEvent, customDate, showEmptyServices, view, favourites, selectedFavorites, showPastEvents, handleOnSearch, handleToday, handleNextPeriod, handlePrevPeriod, onlyMine])

  const filteredDays = useCallback(() => {
    // Function to check if any of the event properties match the search term
    const matchesSearch = (event, searchTerm) => {
      const fieldsToCheck = [
        'title', 'name', 'trainerNames', 'participantNames',
        'subscriptionNames', 'sportType', 'equipmentNames', 'tags', 'notes'
      ];

      return fieldsToCheck.some(field =>
        event[field] && event[field].toLowerCase().includes(searchTerm)
      );
    };

    // Lowercase the search term once to avoid repetitive lowercasing
    const lowerCaseSearch = search?.toLowerCase() ?? '';

    if (!search) {
      return days.map((el, indx) => ({
        ...el,
        dayItems: daysServices[indx] ? el.dayItems.concat(daysServices[indx].dayItems) : el.dayItems
      }));
    }

    return days.map((day, indx) => ({
      ...day,
      dayItems: day.dayItems.concat(daysServices[indx].dayItems)
        .filter(event => matchesSearch(event, lowerCaseSearch))
    }));
  }, [search, daysServices, days]);

  const handleEventDetails = useCallback((eventDetails) => {
    setEventDetailsModal({
      open: true,
      eventId: eventDetails.id
    })
  }, [])

  const handleOpenDelete = useCallback(eventId => {
    setDeleteEventModal({
      open: true,
      eventId
    })
  }, [])

  const handleOpenMessage = useCallback((name, id, type, trainers, directly, company) => {
    setEventDetailsModal({
      open: false,
      eventId: null
    })
    if (directly) {
      setMessageModal({
        open: true,
        name,
        id,
        type
      })
    }
    else {
      setTrainersModal({
        open: true,
        type: 'message',
        title: t('calendar.sendMessageTo'),
        trainers: trainers,
        company
      })
    }
  }, [])

  const handleOpenBuyACoffee = useCallback((trainers, paymentLink, directly) => {
    setEventDetailsModal({
      open: false,
      eventId: null
    })
    if (directly) {
      window.open(paymentLink)
    }
    else {
      setTrainersModal({
        open: true,
        type: 'coffee',
        title: t('calendar.sendCoffeeTo'),
        trainers: trainers
      })
    }
  }, [])

  const handleDelete = useCallback(async () => {
    try {
      await CALENDAR_REQUESTS.deleteEvent(deleteEventModal.eventId)
      setEventDetailsModal({ open: false, eventId: null })
      setDeleteEventModal({ open: false, eventId: null })
      handleCurrentPeriod(lastDay, userSubscriptions)
    } catch (e) {
      setDeleteEventModal({ open: false, eventId: null })
      console.error(e)
    }
  }, [deleteEventModal, lastDay, userSubscriptions])

  const handleEdit = useCallback(eventId => {
    setEditEventModal({
      open: true,
      eventId
    })
    setEventDetailsModal({
      open: false,
      eventId: null
    })
  }, [])

  const handleOpenWaitingList = useCallback(event => {
    setWaitingListModal({
      open: true,
      event: event,
      availablePlaces: event.maxWaitingList - (event.inWaitingList ? event.inWaitingList : event.EventsParticipants.filter(el => el.isInWaitingList).length)
    })
  })

  const handleOpenPayment = useCallback(event => {
    setPaymentModal({
      open: true,
      eventId: event.id,
      type: event.payment,
      price: event.value,
      currency: event?.Company?.currency || event?.company?.currency || 'RON'
    })
  })

  const handleSendMessage = async (title, message) => {
    try {
      if (messageModal.type === 'company') {
        await MESSAGE_REQUESTS.sendMessageToCompany(messageModal.id, { title, message })
      }
      else {
        await MESSAGE_REQUESTS.sendMessageToUser(messageModal.id, { title, message })
      }
      setMessageModal({
        open: true,
        name: '',
        id: null,
        type: ''
      })
      toast.success(t('calendar.messageSent'), { position: 'bottom-right' })
    } catch (e) {
      toast.error(t('calendar.messageError'), { position: 'bottom-right', autoClose: false })
      setMessageModal({
        open: true,
        name: '',
        id: null,
        type: ''
      })
      console.error(e)
    }
  }

  const handleBook = useCallback(async (eventId, refetch, userSubscriptionId) => {
    try {
      setLoadingBookUnbook(eventId)
      await CALENDAR_REQUESTS.bookEvent(eventId, userSubscriptionId)
      // if (refetch) {
      //   handleCurrentPeriod(lastDay, userSubscriptions)
      // }
      await handleGetUserSubscriptions()
      toast.success(t('calendar.bookSuccess'), { position: 'bottom-right' })
    } catch (e) {
      setLoadingBookUnbook(false)
      toast.error(t('calendar.bookError'), { position: 'bottom-right', autoClose: false })
      console.error(e)
    }
  }, [params, lastDay, userSubscriptions])

  const handleUnbook = useCallback(async (eventId, refetch) => {
    try {
      setLoadingBookUnbook(eventId)
      await CALENDAR_REQUESTS.unbookEvent(eventId)
      // if (refetch) {
      //   handleCurrentPeriod(lastDay, userSubscriptions)
      // }
      await handleGetUserSubscriptions()
      toast.warn(t('calendar.unbookSuccess'), { position: 'bottom-right' })
    } catch (e) {
      setLoadingBookUnbook(false)
      toast.error(t('calendar.unbookError'), { position: 'bottom-right', autoClose: false })
      console.error(e)
    }
  }, [params, lastDay, userSubscriptions])

  const handleBookEventWithOneSubscription = useCallback(async (eventToUse, userSubscriptionToUse) => {
    const userSubscription = userSubscriptionToUse.filter(el => {
      const subscriptionStatus = computeStatus(el, eventToUse.fullStart || eventToUse.start)

      return subscriptionStatus !== 'EXPIRED' && subscriptionStatus !== 'PAUSED' && subscriptionStatus !== 'INACTIVE'
    }).sort((a, b) => {
      return moment(a.validUntil).diff(moment(b.validUntil)) > 0 ? 1 : -1
    }).find(el => el.Subscription.id === eventToUse.EventSubscriptions[0].id)
    if (!userSubscription || (userSubscription.limitedActivationTotal && (userSubscription.limitedActivationUsed + eventToUse.value > userSubscription.limitedActivationTotal))) {
      toast.error(t('calendar.notEnoughCredits'), { position: 'bottom-right', autoClose: false })
      return
    }

    await handleBook(eventToUse.id, true, userSubscription.id)

  }, [])

  const handleOpenSubscriptionSelector = useCallback((eventToUse, eventSubscriptions) => {
    const availableSubscriptionsToUse = userSubscriptions.filter(el => eventSubscriptions.map(sub => sub.id).includes(el.Subscription.id))
    if (availableSubscriptionsToUse.length === 1) {
      handleBook(eventToUse.id, true, availableSubscriptionsToUse[0].id)
    }
    else {
      setSubscriptionSelector({
        open: true,
        eventSubscriptions,
        event: eventToUse
      })
    }
  }, [userSubscriptions])

  const renderCalendarDays = useMemo(() => {
    const updatedDays = filteredDays();

    return <CalendarDays
      loadingServices={loadingServices}
      loadingEvents={loadingEvents}
      loadingItems={(loadingServices || loadingEvents) && !loadingBookUnbook}
      loadingBookUnbook={loadingBookUnbook}
      showPastEvents={showPastEvents}
      handleOpenWaitingList={handleOpenWaitingList}
      handleOpenPayment={handleOpenPayment}
      handleBook={handleBook}
      handleUnbook={handleUnbook}
      handleEventDetails={handleEventDetails}
      handleOpenSubscriptionSelector={handleOpenSubscriptionSelector}
      handleBookEventWithOneSubscription={(eventToUse) => handleBookEventWithOneSubscription(eventToUse, userSubscriptions)}
      days={updatedDays} />
  }, [filteredDays, loadingServices, loadingEvents, loadingBookUnbook, showPastEvents, handleBook, handleUnbook, userSubscriptions])

  const renderCalendarList = useMemo(() => {
    return (
      <CalendarDaysList userSubscriptions={userSubscriptions} showEmptyServices={showEmptyServices} handleEventDetails={handleEventDetails} handleOnDayChanged={handleOnDayChanged} days={filteredDays()} />
    )
  }, [handleOnDayChanged, showEmptyServices, userSubscriptions, filteredDays])

  const renderCreateEventModal = useMemo(() => {
    if (!createEventModal.open || !createEventModal.type) return null
    if (createEventModal.type === 'event') {
      return (
        <CalendarCreateEvent
          handleClose={() => {
            setCreateEventModal({ open: false, type: '' })
            handleGetCalendar(null, userSubscriptions)
          }}
          Transition={Transition}
          currency={currency}
        />
      )
    }

    return (
      <CalendarCreateService
        handleClose={() => {
          setCreateEventModal({ open: false, type: '' })
          handleGetCalendar(null, userSubscriptions)
        }}
        Transition={Transition}
        currency={currency}
      />
    )

  }, [createEventModal, userSubscriptions])

  const renderEditEventModal = useMemo(() => {
    if (!editEventModal.open || !editEventModal.eventId) return null
    return (
      <CalendarEditEvent
        handleClose={() => {
          setEditEventModal({ open: false, eventId: null })
          handleCurrentPeriod(lastDay, userSubscriptions)
        }}
        eventId={editEventModal.eventId}
        Transition={Transition}
        currency={currency}
      />
    )
  }, [editEventModal, lastDay, userSubscriptions])

  const renderEventDetailsModal = useMemo(() => {
    if (!eventDetailsModal.open || !eventDetailsModal.eventId) return null
    return (
      <CalendarEventDetailsModal
        loadingBookUnbook={loadingBookUnbook}
        eventId={eventDetailsModal.eventId}
        userSubscriptionsToUse={userSubscriptions}
        handleClose={() => {
          setEventDetailsModal({ open: false, eventId: null })
          if (isClub || isTrainer || isEmployee) {
            handleCurrentPeriod(lastDay, userSubscriptions, onlyMine, favourites)
          }
        }}
        showPastEvents={showPastEvents}
        handleOpenSubscriptionSelector={handleOpenSubscriptionSelector}
        handleBookEventWithOneSubscription={(eventToUse) => handleBookEventWithOneSubscription(eventToUse, userSubscriptions)}
        handleDelete={handleOpenDelete}
        handleEdit={handleEdit}
        handleMessage={handleOpenMessage}
        handleBuyACoffee={handleOpenBuyACoffee}
        handleOpenPayment={handleOpenPayment}
        handleOpenWaitingList={handleOpenWaitingList}
        handleBook={handleBook}
        handleUnbook={handleUnbook}
      />
    )
  }, [eventDetailsModal, loadingBookUnbook, isClub, isTrainer, isEmployee, lastDay, showPastEvents, onlyMine, favourites, lastDay, userSubscriptions, params])

  const renderDeleteEventModal = useMemo(() => {
    if (!deleteEventModal.open || !deleteEventModal.eventId) return null
    return (
      <DeleteEventDialog
        handleDelete={handleDelete}
        handleClose={() => {
          setDeleteEventModal({ open: false, eventId: null })
        }}
      />
    )
  }, [deleteEventModal])

  const renderMessageModal = useMemo(() => {
    if (!messageModal.open || !messageModal.id || !messageModal.name || !messageModal.type) return null
    return (
      <MessageCompanyDialog
        handleSend={handleSendMessage}
        handleClose={() => setMessageModal({
          open: false,
          name: '',
          id: null,
          type: ''
        })}
        companyName={`${messageModal.name}`}
      />
    )
  }, [messageModal])

  const renderWaitingListModal = useMemo(() => {
    if (!waitingListModal.open || !waitingListModal.event) return null
    return (
      <WaitingListModal
        availablePlaces={waitingListModal.availablePlaces}
        handleConfirm={() => {
          if (waitingListModal.event.cost === "CREDITS") {
            if (waitingListModal.event.EventSubscriptions.length > 1) {
              handleOpenSubscriptionSelector(waitingListModal.event, waitingListModal.event.EventSubscriptions)
            }
            else {
              handleBookEventWithOneSubscription(waitingListModal.event, userSubscriptions)
            }
          }
          else {
            handleBook(waitingListModal.event.id, true)
          }
          setWaitingListModal({
            open: false,
            event: null,
            availablePlaces: 0
          })
          setEventDetailsModal({
            open: false,
            eventId: null
          })
        }}
        handleClose={() => setWaitingListModal({
          open: false,
          event: null,
          availablePlaces: 0
        })}
      />
    )
  }, [waitingListModal, userSubscriptions])


  const renderPaymentModal = useMemo(() => {
    if (!paymentModal.open || !paymentModal.eventId) return null
    return (
      <PaymentEventModal
        type={paymentModal.type}
        price={paymentModal.price}
        handleConfirm={() => {
          handleBook(paymentModal.eventId, true)
          setPaymentModal({
            open: false,
            eventId: null,
            type: '',
            price: 0
          })
          setEventDetailsModal({
            open: false,
            eventId: null
          })
        }}
        handleClose={() => setPaymentModal({
          open: false,
          eventId: null,
          type: '',
          price: 0
        })}
        currency={paymentModal.currency}
      />
    )
  }, [paymentModal])

  const renderTrainersModal = useMemo(() => {
    if (!trainersModal.open) return null
    return (
      <ListOfTrainersModal
        title={trainersModal.title}
        trainers={trainersModal.trainers}
        company={trainersModal.company}
        type={trainersModal.type}
        handleConfirm={(name, id, type, paymentLink) => {
          setTrainersModal({
            open: false,
            title: '',
            type: '',
            trainers: [],
            company: null
          })
          if (trainersModal.type === 'coffee') {
            window.open(paymentLink)
          }
          else {
            setMessageModal({
              open: true,
              name,
              id,
              type
            })
          }
        }}
        handleClose={() => setTrainersModal({
          open: false,
          title: '',
          type: '',
          trainers: [],
          company: null
        })}
      />
    )
  }, [trainersModal])


  const renderSubscriptionSelectorModal = useMemo(() => {
    if (!subscriptionSelector.open || !subscriptionSelector.event) return null
    return (
      <SubscriptionSelectorModal
        subscriptions={userSubscriptions.filter(el => subscriptionSelector.eventSubscriptions.map(sub => sub.id).includes(el.Subscription.id))}
        event={subscriptionSelector.event}
        handleConfirm={(userSubscriptionId) => {
          handleBook(subscriptionSelector.event.id, true, userSubscriptionId)
          if (eventDetailsModal.open) {
            setEventDetailsModal({
              open: false,
              eventId: null
            })
          }
          setSubscriptionSelector({
            open: false,
            eventSubscriptions: [],
            event: null
          })
        }}
        handleClose={() => setSubscriptionSelector({
          open: false,
          eventSubscriptions: [],
          event: null
        })}
      />
    )
  }, [subscriptionSelector, eventDetailsModal, userSubscriptions])

  if (loadingHeaderAndDays || !daysLoaded) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      <Container maxWidth="xl" sx={{ paddingLeft: 0, paddingRight: 0 }}>
        {renderMessageModal}
        {renderDeleteEventModal}
        {renderWaitingListModal}
        {renderPaymentModal}
        {renderEventDetailsModal}
        {renderCreateEventModal}
        {renderEditEventModal}
        {renderTrainersModal}
        {renderSubscriptionSelectorModal}
        <Grid container display="flex" flexDirection="column" pt={8} pb={8}>
          <Grid item lg={12} xs={12} pl={3} pr={3}>
            {renderCalendarHeader}
          </Grid>
          {view === 'list' ? renderCalendarList : <Grid item lg={12} xs={12}>
            {renderCalendarDays}
          </Grid>}
        </Grid>
      </Container>
    </>
  )
}

export default Calendar
