import { InputAdornment, TextField, Typography } from "@mui/material";
import { PRIMARY_COLOR } from "../../../utils/constants";

const InputField = ({ label, type = "text", value, onChange, name, placeholder, endAdornment, disabled }) => (
    <div>
        <Typography pb={0.5}>{label}</Typography>
        <TextField
            disabled={disabled}
            fullWidth
            type={type}
            value={value}
            onChange={onChange}
            name={name}
            placeholder={placeholder}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="start" sx={{ paddingRight: 1 }}>
                        <Typography variant="p" fontWeight="bold" color={PRIMARY_COLOR}>
                            {endAdornment}
                        </Typography>
                    </InputAdornment>
                )
            }}
        />
    </div>
);

export default InputField;