import React, { useState, useEffect } from 'react'
import { Dialog, IconButton, Button, Typography, Grid, TextField, Avatar, Box, Divider, Chip } from '@mui/material'
import { Close } from '@mui/icons-material'

import { GRAY_TEXT_COLOR, LIGHT_GRAY_BORDER_COLOR } from '../../../../utils/constants'

import * as ECREDITS_ASSOCIATION_REQUESTS from '../../../../api/ecreditsAssociationRequests'

const AssociationModal = ({
    onCancel,
    onConfirm,
}) => {
    const [search, setSearch] = useState('')
    const [users, setUsers] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [loading, setLoading] = useState(false)

    const getUsers = () => {
        return ECREDITS_ASSOCIATION_REQUESTS.getUsers({ from: 0, limit: 10, search })
            .then(setUsers)
            .catch(console.error)
    }

    const onCreateHandler = () => {
        setLoading(true)

        return ECREDITS_ASSOCIATION_REQUESTS.bulkCreate(selectedUsers.map(user => ({ userId: user.id })))
            .finally(() => {
                onConfirm()
                onCancel()
                setLoading(false)
            })
    }

    useEffect(() => {
        if (!search) return

        getUsers()
    }, [search])

    const selectedUsersIds = selectedUsers.map(el => el.id)

    return (
        <Dialog open maxWidth="sm" fullWidth>
            <Grid container pt={2}>
                <Grid item xs={12} pl={3} pr={3}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item
                            xs={10}
                            display="flex"
                        >
                            <Typography variant="h5" fontWeight="500">
                                Link User
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" flexDirection="row" justifyContent={"flex-end"}>
                            <IconButton onClick={onCancel}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item pt={3} xs={12} pr={3} pl={3}>
                    <Typography >Select Users</Typography>
                    <TextField
                        fullWidth
                        placeholder='Ana Popescu'
                        value={search}
                        onChange={event => setSearch(event.target.value)}
                    />
                    {!search ? <Typography pt={1} variant="body2" color={GRAY_TEXT_COLOR}>Type Users Name, Email or Phone Number</Typography> : null}
                    <Grid container spacing={1} pt={2} maxHeight={400}>
                        {users.map(user => {
                            const isSelected = selectedUsersIds.includes(user.id)

                            return (
                                <Grid item xs={12} key={user.id} flexDirection="row" display="flex" alignItems="center">
                                    <Avatar variant="rounded" src={user.avatarPhotoSrc}>
                                        {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                                    </Avatar>
                                    <Box ml={1}>
                                        <Typography variant="body2" color={GRAY_TEXT_COLOR} fontWeight="500">{user.firstName} {user.lastName}</Typography>
                                        <Typography variant="body2" fontWeight="500" color="primary">{user.email}</Typography>
                                    </Box>
                                    <Box sx={{ marginLeft: 'auto' }}>
                                        <Button onClick={() => {
                                            if (!isSelected) {
                                                return setSelectedUsers(prevValue => prevValue.concat([user]))
                                            }

                                            const newSelectedUsers = selectedUsers.filter(el => el.id !== user.id)

                                            setSelectedUsers(newSelectedUsers)
                                        }} variant={isSelected ? 'contained' : 'outlined'}>{isSelected ? 'Unselect' : 'Select'}</Button>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12} pr={3} pl={3} pt={2}>
                    <Divider />
                </Grid>
                <Grid item pb={3} xs={12} pr={3} pl={3} pt={2}>
                    <Typography gutterBottom>Summary</Typography>
                    <Grid container spacing={0.5}>
                        {selectedUsers.map(user => {
                            return (
                                <Grid item key={user.id}>
                                    <Chip
                                        avatar={<Avatar alt="Natacha" src={user.avatarPhotoSrc} >{user.firstName.charAt(0)} {user.lastName.charAt(0)}</Avatar>}
                                        label={user.firstName + ' ' + user.lastName}
                                        variant="contained"
                                        color="primary"
                                        onDelete={() => {
                                            const newSelectedUsers = selectedUsers.filter(el => el.id !== user.id)

                                            setSelectedUsers(newSelectedUsers)
                                        }}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Typography pt={1} variant="body2" color={GRAY_TEXT_COLOR}>All this users will be invited to join this company, after they accept the invitation you can add them credits</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="row" sx={{ height: 60 }}>
                        <Grid item xs={6} lg={6} sx={{ height: 60 }} display="flex" justifyContent="center" alignItems="center" borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
                            <Button style={{ height: '100%', fontWeight: 'bold' }} onClick={onCancel} fullWidth variant="text" color="error">
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                sx={{ height: 60 }}
                                style={{ height: '100%', fontWeight: 'bold' }}
                                onClick={() => {
                                    onCreateHandler()
                                }}
                                disabled={loading}
                                fullWidth
                                color={'primary'}
                                variant="text"
                            >
                                Link
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default AssociationModal
