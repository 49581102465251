import React, { useEffect, useState } from 'react'
import { Grid, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { HeatMap } from '../../../../common'

import * as COMPANY_REQUESTS from '../../../../../api/company'

export default () => {
    const { domain } = useParams()

    const [data, setData] = useState({})

    useEffect(() => {
        if (!domain) return
        COMPANY_REQUESTS.getBusinessPopularHoursByDomain(domain)
            .then(res => {
                setData(res)
            })
    }, [domain])

    return (
        <Grid item xs={12}>
            <Box pt={2} pb={3} height={250}>
                <HeatMap data={data} />
            </Box>
        </Grid>
    )
}