import { Chip, Grid, Tooltip, Typography } from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { InputGenerator, Toggle } from '../../common'
import { CARD_TEXT_COLOR, CHIP_BLUE_COLOR, ERROR_COLOR, LIGHT_PRIMARY_COLOR, RED_CHIP_COLOR } from '../../../utils/constants'
import { Info } from '@mui/icons-material'

const CalendarServiceDetails = ({
    inputs,
    disabled,
    handleOnDetailsInputChanged,
    handleOnDeleteCustomSelect,
    handleEnterPressed,
    eventParticipants,
}) => {

    const { t } = useTranslation()

    const getInputData = useCallback((inputName, field, fromArray, position) => {
        const inputPosition = inputs.findIndex((el) => el?.name === inputName)
        if (inputPosition < 0) return ''

        return fromArray ? inputs[inputPosition][field][position] : inputs[inputPosition][field]
    }, [inputs])

    const createLabelAndInfo = useCallback(
        (inputName, withoutPadding) => {
            const inputPosition = inputs.findIndex((el) => el?.name === inputName)
            if (inputPosition < 0) return null

            return (
                <Grid container display="flex" flexDirection="row" pb={withoutPadding ? 0 : 1}>
                    <Grid item pr={0.5}>
                        <Typography fontWeight={500} variant="p">
                            {inputs[inputPosition].label}
                        </Typography>
                    </Grid>
                    {inputs[inputPosition].mandatory ? (
                        <Grid item pr={0.5}>
                            <Typography fontWeight={500} variant="p" color="primary">
                                *
                            </Typography>
                        </Grid>
                    ) : null}
                    {inputs[inputPosition].infoMessage ? (
                        <Grid item pl={0.5} pt={0.3}>
                            <Tooltip title={inputs[inputPosition].infoMessage} arrow placement="top-start">
                                <Info sx={{ color: CARD_TEXT_COLOR }} fontSize="14px" />
                            </Tooltip>
                        </Grid>
                    ) : null}
                </Grid>
            )
        },
        [inputs]
    )

    const computeRenderValue = useCallback((selectedValues, label) => {
        return !selectedValues.length ? <Typography color={"#9CB2CD"}>{t('calendar.selectAValue')}</Typography> : `${selectedValues.length} ${label} selected`
    }, [])

    const createSimpleInput = useCallback(
        (inputName) => {
            const inputPosition = inputs.findIndex((el) => el?.name === inputName)
            if (inputPosition < 0) return null
            let maxParticipantsError = false

            const errorBasedOnAlreadyExistingParticipants = (Number(inputs[inputPosition].value) && eventParticipants && (eventParticipants.map(el => el.Events_Participants).filter(el => !el.isInWaitingList).length > Number(inputs[inputPosition].value)))
            if (inputName === 'maxParticipants') {
                maxParticipantsError = errorBasedOnAlreadyExistingParticipants
            }

            let inputToUse = {
                name: inputName,
                type: inputs[inputPosition].type,
                placeholder: inputs[inputPosition].placeholder,
                value: inputs[inputPosition].value,
                defaultValue: inputs[inputPosition].value,
                maxLength: inputs[inputPosition].maxLength,
                values: inputs[inputPosition].values,
                disabled: inputs[inputPosition].disabled || (disabled && (inputName === 'availability' || inputName === 'access' || inputName === 'cost' || inputName === 'subscriptions' || inputName === 'value' || inputName === 'payment')),
                multiple: inputs[inputPosition].multiple,
                endAdornmentComponent: inputs[inputPosition].endAdornmentComponent,
                displayEmpty: true
            }

            if (inputs[inputPosition].multiple) inputToUse = {
                ...inputToUse,
                renderValue: (selectedValues) => computeRenderValue(selectedValues, inputs[inputPosition].label)
            }

            if (inputs[inputPosition]?.name === 'value') inputToUse = {
                ...inputToUse,
                inputProps: {
                    pattern: "[0-9]*",
                }
            }

            return (
                <InputGenerator
                    handleEnterPressed={handleEnterPressed}
                    handleOnChange={handleOnDetailsInputChanged}
                    handleOnChangeGoogle={(googleData) =>
                        handleOnDetailsInputChanged({ target: { name: inputName, value: googleData.label } })
                    }
                    error={inputName === 'maxParticipants' && maxParticipantsError}
                    helperText={maxParticipantsError ? `${t('calendar.cantBeLower')} ${eventParticipants.map(el => el.Events_Participants).filter(el => !el.isInWaitingList).length}` : ''}
                    input={inputToUse}
                />
            )
        },
        [handleOnDetailsInputChanged, handleEnterPressed, disabled, eventParticipants, computeRenderValue, inputs]
    )

    const createToggleInput = useCallback(
        (inputName) => {
            const inputPosition = inputs.findIndex((el) => el?.name === inputName)
            if (inputPosition < 0) return null

            return (
                <Grid
                    container
                    display="flex"
                    flexDirection="row"
                    sx={{ backgroundColor: LIGHT_PRIMARY_COLOR, borderRadius: '4px' }}
                    p={1.3}
                >
                    <Grid item>
                        <Typography>{createLabelAndInfo(inputName, true)}</Typography>
                    </Grid>
                    <Grid item ml={'auto'}>
                        <Toggle
                            color="primary"
                            defaultChecked={inputs[inputPosition].value}
                            onClick={() =>
                                handleOnDetailsInputChanged({
                                    target: { name: inputName, value: !inputs[inputPosition].value }
                                })
                            }
                        />
                    </Grid>
                </Grid>
            )
        },
        [inputs, disabled]
    )


    const createCustomSelectValues = useCallback(
        (inputName) => {
            const inputPosition = inputs.findIndex((el) => el?.name === inputName)
            if (inputPosition < 0) return null

            return (
                <Grid container display="flex" flexDirection="row" pt={2}>
                    {inputs[inputPosition].values.map((el) => (
                        <Grid item>
                            <Chip
                                sx={{
                                    backgroundColor: CHIP_BLUE_COLOR,
                                    '& .MuiChip-deleteIcon': {
                                        color: ERROR_COLOR,
                                        '&:hover': {
                                            color: RED_CHIP_COLOR
                                        }
                                    }
                                }}
                                label={el}
                                onDelete={() => handleOnDeleteCustomSelect(inputName, el)}
                            />
                        </Grid>
                    ))}
                </Grid>
            )
        },
        [handleOnDetailsInputChanged, inputs]
    )

    const renderLeftInputs = useMemo(() => {
        return (
            <Grid item lg={6} xs={12} pr={{ lg: 3, xs: 0 }}>
                <Grid container display="flex" flexDirection="row" pb={{ lg: 6, xs: 2 }}>
                    <Grid item lg={12} xs={12}>
                        {createLabelAndInfo('name')}
                        {createSimpleInput('name')}
                    </Grid>
                </Grid>
                <Grid container display="flex" flexDirection="row" pb={{ lg: 6, xs: 2 }}>
                    <Grid item lg={12} xs={12}>
                        {createLabelAndInfo('sportType')}
                        {createSimpleInput('sportType')}
                    </Grid>

                </Grid>
                <Grid container display="flex" flexDirection="row" pb={{ lg: 6, xs: 2 }}>
                    <Grid item lg={12} xs={12}>
                        {createLabelAndInfo('equipment')}
                        {createSimpleInput('equipment')}
                    </Grid>
                </Grid>
                <Grid container display="flex" flexDirection="row" pb={{ lg: 6, xs: 2 }}>
                    <Grid item lg={12} xs={12}>
                        {createLabelAndInfo('location')}
                        {createSimpleInput('location')}
                    </Grid>
                </Grid>
                <Grid container display="flex" flexDirection="row" pb={{ lg: 6, xs: 2 }}>
                    <Grid item lg={12} xs={12} pt={{ xs: 1, lg: 0 }} height={75}>
                        {createLabelAndInfo('maxParticipants')}
                        {createSimpleInput('maxParticipants')}
                    </Grid>
                </Grid >

            </Grid >
        )
    }, [inputs])

    const renderRightInputs = useMemo(() => {
        return (
            <Grid item lg={6} xs={12} pl={{ lg: 3, xs: 0 }}>
                <Grid container display="flex" flexDirection="row" pb={{ lg: 6, xs: 2 }}>
                    <Grid item lg={12} xs={12} pt={{ xs: 1, lg: 0 }}>
                        {createLabelAndInfo('access')}
                        {createSimpleInput('access')}
                    </Grid>
                </Grid>
                <Grid container display="flex" flexDirection="row" pb={{ lg: 6, xs: 2 }}>
                    <Grid item lg={12} xs={12} pt={{ xs: 1, lg: 0 }}>
                        {createLabelAndInfo('subscriptions')}
                        {createSimpleInput('subscriptions')}
                    </Grid>
                </Grid >
                <Grid container display="flex" flexDirection="row" pb={{ lg: 6, xs: 2 }}>
                    <Grid item lg={12} xs={12} pt={{ xs: 1, lg: 0 }}>
                        {createLabelAndInfo('cost')}
                        {createSimpleInput('cost')}
                    </Grid>
                </Grid >
                <Grid container display="flex" flexDirection="row" pb={{ lg: 6, xs: 2 }}>
                    <Grid item lg={12} xs={12} pt={{ xs: 1, lg: 0 }}>
                        {createLabelAndInfo('valueCredits')}
                        {createSimpleInput('valueCredits')}
                    </Grid>
                </Grid >
                <Grid container display="flex" flexDirection="row" pb={{ lg: 6, xs: 2 }}>
                    <Grid item lg={12} xs={12}>
                        {createLabelAndInfo('tags')}
                        {createSimpleInput('tags')}
                        {createCustomSelectValues('tags')}
                    </Grid>
                </Grid>
            </Grid >
        )
    }, [inputs])

    return (
        <Grid container display="flex" flexDirection="column" pt={{ lg: 6, xs: 2 }} pb={2} minHeight={'65vh'}>
            <Grid item lg={12} xs={12}>
                <Grid container display="flex" flexDirection="row">
                    {renderLeftInputs}
                    {renderRightInputs}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CalendarServiceDetails