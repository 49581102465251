import React, { useEffect, useState } from 'react'
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Button,
  Checkbox
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { InputGenerator } from '../../../../common'

import { SPORTTYPES_TYPES } from '../../../../../utils/constants'
import * as SPORTTYPES_REQUESTS from '../../../../../api/sportTypes'

const Equipments = ({ userDefaultSportType, updateDefaultSportType }) => {
  const { t } = useTranslation()

  const [sportTypesText, setSportTypesText] = useState('')
  const [sportTypes, setSportTypes] = useState([])
  const [defaultSportType, setDefaultSporType] = useState(userDefaultSportType)

  const sportTypesTypes = Object.keys(SPORTTYPES_TYPES).map((key) => ({
    label: t(`sportTypesOptions.${SPORTTYPES_TYPES[key]}`),
    value: SPORTTYPES_TYPES[key]
  }))

  const getSportTypes = () =>
    SPORTTYPES_REQUESTS.trainerFindAll().then((response) => {
      setSportTypes(response)
    })

  const removeSportType = (id) => SPORTTYPES_REQUESTS.trainerRemove(id).then(getSportTypes)

  const addSportType = (title) => {
    return SPORTTYPES_REQUESTS.trainercreate({ title }).then(getSportTypes)
  }

  useEffect(() => {
    setDefaultSporType(userDefaultSportType)
  }, [userDefaultSportType])

  useEffect(() => {
    getSportTypes()
  }, [])

  const sportTypesTitles = sportTypes.map((el) => el.title)

  return (
    <Grid container p={4} pr={{ xs: 0, lg: 0 }} pl={{ xs: 0, lg: 0 }}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <InputGenerator
              handleOnChange={(event) => setSportTypesText(event.target.value)}
              input={{
                type: 'text',
                label: '',
                placeholder: t('businessEditProfile.searchSportType'),
                value: sportTypesText
              }}
            />
            <List sx={{ width: '100%' }} dense>
              {sportTypesTypes
                .filter((el) => el.value.toLowerCase().includes(sportTypesText.toLowerCase()))
                .map((key, index) => {
                  const isDefault = key.value === defaultSportType
                  const isChecked = sportTypesTitles.includes(key.value)

                  return (
                    <ListItem divider sx={{ paddingLeft: 0, paddingRight: 0 }} key={index}>
                      <ListItemIcon onClick={() =>
                        isChecked
                          ? removeSportType(key.value)
                          : addSportType(key.value)
                      }>
                        <Checkbox
                          edge="start"
                          checked={isChecked}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText>{key.label}</ListItemText>
                      <Button disabled={isDefault} variant={isDefault ? "contained" : "outlined"} onClick={() => updateDefaultSportType(key.value)}>
                        {isDefault ? t('helper.selected') : t('helper.setDefault')}
                      </Button>
                    </ListItem>
                  )
                })}
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Equipments
