import axios from './axios'

export const follow = ({ followerUserId, followerCompanyId }) => {
  return axios.post('/followers', { followerUserId, followerCompanyId }).then((res) => res.data)
}

export const unfollow = ({ followerUserId, followerCompanyId }) => {
  return axios.patch('/followers', { followerUserId, followerCompanyId }).then((res) => res.data)
}

export const isFollowingTrainer = (domain) => {
  return axios.get('/followers/trainers/' + domain).then((res) => res.data)
}

export const isFollowingCompany = (domain) => {
  return axios.get('/followers/companies/' + domain).then((res) => res.data)
}
