import React, { useMemo } from 'react'
import { Grid, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import LOGIN_BG from '../../../assets/login.png'
import { ReactComponent as EmailSvg } from '../../../assets/email.svg'
import { BLACK } from '../../../utils/constants'

const ResetEmailSent = ({ email, handleSendLink }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const renderSentEmail = useMemo(() => {
    return (
      <Grid item xs={12} lg={6} p={{ xs: 4, lg: 10 }}>
        <Grid container height="100%" alignItems="center">
          <Grid container direction="column">
            <Grid item width="100%">
              <EmailSvg width={70} height={70} />
            </Grid>
            <Grid item width="100%">
              <Typography fontWeight="bold" variant="h4">
                {t('helper.checkInbox')}
              </Typography>
            </Grid>
            <Grid pt={2}>
              <Typography fontWeight={500} variant="h5" color="primary">
                {email}
              </Typography>
            </Grid>
            <Grid pt={2}>
              <Typography fontWeight={500} variant="p">
                {t('helper.sentEmailReset')}
              </Typography>
            </Grid>
            <Grid pt={4} container alignItems="center">
              <Grid item xs={12} lg={5}>
                <Typography
                  onClick={handleSendLink}
                  fontWeight={600}
                  variant="p"
                  color="primary"
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  {t('helper.resendLink')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={7} paddingTop={{ xs: 2, lg: 0 }}>
                <Button
                  onClick={() => navigate('/login')}
                  style={{ backgroundColor: BLACK, color: '#fff' }}
                  variant="contained"
                  fullWidth
                >
                  {t('button.backToLogin')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }, [t])

  const renderImage = useMemo(() => {
    return (
      <Grid
        item
        alignItems="center"
        display={{ xs: 'none', sm: 'none', lg: 'block' }}
        lg={6}
        maxHeight={'98vh'}
      >
        <img
          style={{ position: 'relative', left: '3%', top: '-30%', objectFit: 'cover' }}
          height={'150%'}
          src={LOGIN_BG}
          width={1200}
        />
      </Grid>
    )
  }, [])

  return (
    <Grid container style={{ padding: 0, overflowX: 'hidden' }} height="100%">
      {renderSentEmail}
      {renderImage}
    </Grid>
  )
}

export default ResetEmailSent
