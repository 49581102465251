import axios from './axios'
import FileSaver from 'file-saver'
import { decode } from 'base64-arraybuffer'

export const getCompanyOverview = () => {
  return axios.get(`/dashboard/company/overview`).then((res) => res.data)
}

export const getReportCompanyActiveUsers = () => {
  return axios.get(`/dashboard/reports/company/active-users`).then((res) => res.data)
}

export const getReportCompanyTrainersCredits = (data) => {
  return axios.get(`/dashboard/reports/company/trainers-credits?start=${data.start}&end=${data.end}`).then((res) => res.data)
}

export const getReportCompanyTrainersCreditsServices = (data) => {
  return axios.get(`/dashboard/reports/company/trainers-credits/services?start=${data.start}&end=${data.end}`).then((res) => res.data)
}

export const getTrainerOverview = () => {
  return axios.get(`/dashboard/trainer/overview`).then((res) => res.data)
}

export const getUserOverview = () => {
  return axios.get(`/dashboard/user/overview`).then((res) => res.data)
}

export const getReportsCompanyOverview = () => {
  return axios.get(`/dashboard/company/reports/overview`).then((res) => res.data)
}
export const getReportsCompanyOverviewYear = () => {
  return axios.get(`/dashboard/company/reports/overview/year`).then((res) => res.data)
}
export const getCompanySubscriptionsBarChart = () => {
  return axios.get(`/dashboard/company/subscriptionsBarChart`).then((res) => res.data)
}

export const getReportCompanySubscriptionsBarChart = () => {
  return axios.get(`/dashboard/reports/company/subscriptionsBarChart`).then((res) => res.data)
}

export const downloadCompanySoldSubscriptionsPDF = (start, end) => {
  return axios.post(`/dashboard/reports/company/subscriptionsBarChart`, { start, end }).then((res) => {
    const outputFilename = `SportIn_Report_Sold_Subscriptions_${start}_${end}.pdf`;

    FileSaver.saveAs(new Blob([decode(res.data.file)]), outputFilename)
    return res.data
  })
}

export const downloadCompanyCheckinsPDF = (start, end) => {
  return axios.post(`/dashboard/reports/company/traffic`, { start, end }).then((res) => {
    const outputFilename = `SportIn_Report_CheckIns_${start}_${end}.pdf`;

    FileSaver.saveAs(new Blob([decode(res.data.file)]), outputFilename)
    return res.data
  })
}

export const getTopUsers = () => {
  return axios.get(`/dashboard/reports/company/users`).then((res) => res.data)
}

export const downloadTopUsersPdf = (start, end) => {
  return axios.post(`/dashboard/reports/company/users`, { start, end }).then((res) => {
    const outputFilename = `xTrainer_Report_Top_Clients_${start}_${end}.pdf`;

    FileSaver.saveAs(new Blob([decode(res.data.file)]), outputFilename)
    return res.data
  })
}

export const getCompanyReportsTraffic = () => {
  return axios.get(`/dashboard/reports/company/traffic`).then((res) => res.data)
}

export const getTrainerSubscriptionsBarChart = () => {
  return axios.get(`/dashboard/trainer/subscriptionsBarChart`).then((res) => res.data)
}
