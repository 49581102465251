import React from 'react'
import { Grid, Typography, Button, Checkbox } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { GRAY_BORDER_COLOR, PRIMARY_COLOR } from '../../../utils/constants'
import { numberFomatter } from '../../../utils/helpers/numberFomatter'

const ConditionsAndSubmit = ({
  setTermsAccepted,
  termsAccepted,
  disclaimerAccepted,
  setDisclaimerAccepted,
  handleCheckout,
  startFrom,
  service,
  selectedSubscriptions,
  computeSubtotal,
  computeVAT,
  voucher,
  paymentType,
  loading,
  company,
  acceptedCustomPrivacyPolicyLink,
  setAcceptedCustomPrivacyPolicyLink,
  operationalServicesFee,
  trainerCurrency
}) => {
  const { t } = useTranslation()

  const total = Number(Number(computeSubtotal()) + Number(computeVAT()) + operationalServicesFee).toFixed(2)

  const voucherTotal = voucher ? voucher.hasFixedDiscount ? Number(Number(total) - Number(voucher.discount)).toFixed(2) : Number((Number(total) - operationalServicesFee) - (Number(total) - operationalServicesFee) * voucher.discount / 100 + operationalServicesFee).toFixed(2) : 0

  return (
    <Grid
      item
      lg={12}
      sx={{ borderTop: `1px solid ${GRAY_BORDER_COLOR}` }}
      pb={{ lg: 2, xs: 4 }}
      pt={2}
    >
      <Grid container display="flex" flexDirection="row" pb={2.5}>
        <Grid item lg={6}>
          <Typography variant="h6">{voucher ? <s>Total</s> : 'Total'}</Typography>
        </Grid>
        <Grid item lg={6} ml={{ xs: 'auto' }}>
          <Typography sx={{ float: 'right' }} color="primary" fontWeight={600} variant="h5">
            {voucher ? <s> {company?.currency || trainerCurrency} {numberFomatter(total)}</s> : <> {company?.currency || trainerCurrency} {numberFomatter(total)}</>}
          </Typography>
        </Grid>
      </Grid>
      {voucher ? <Grid container display="flex" flexDirection="row" pb={2.5}>
        <Grid item lg={6}>
          <Typography variant="h6">Total</Typography>
        </Grid>
        <Grid item lg={6} ml={{ xs: 'auto' }}>
          <Typography sx={{ float: 'right' }} color="primary" fontWeight={600} variant="h5">
            {company?.currency || trainerCurrency} {numberFomatter(voucherTotal)}
          </Typography>
        </Grid>
      </Grid> : null}
      {company && company.customPrivacyPolicyLink && company.customTermsAndConditionsLink ? <Grid container flexDirection="row" alignItems="center" pt={1}>
        <Grid item>
          <Checkbox
            onChange={(event) => setAcceptedCustomPrivacyPolicyLink(event.target.checked)}
            checked={acceptedCustomPrivacyPolicyLink}
          />
        </Grid>
        <Grid lg={10} xs={10} item sx={{ position: 'relative', top: -2 }}>
          <Typography variant="p" fontSize={14}>
            {t('helper.agreeWith')}
            <Typography
              variant="p"
              color={PRIMARY_COLOR}
              fontWeight={600}
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => window.open(company.customPrivacyPolicyLink)}
            >
              &nbsp;{t('helper.privacyPolicy')}&nbsp;
            </Typography>{' '}
            {t('helper.asWell')}
            <Typography
              variant="p"
              fontWeight={600}
              color={PRIMARY_COLOR}
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => window.open(company.customTermsAndConditionsLink)}
            >
              &nbsp;{t('helper.termsAndConditions')}&nbsp;
            </Typography>{' '}
            {t('helper.of')} {company.name}
          </Typography>
        </Grid>
      </Grid> : null}
      <Grid container flexDirection="row" alignItems="center" pt={1}>
        <Grid item>
          <Checkbox
            onChange={(event) => setTermsAccepted(event.target.checked)}
            checked={termsAccepted}
          />
        </Grid>
        <Grid lg={10} xs={10} item sx={{ position: 'relative', top: -2 }}>
          <Typography variant="p" fontSize={14}>
            {t('helper.agreeWith')}
            <Typography
              variant="p"
              color={PRIMARY_COLOR}
              fontWeight={600}
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => window.open('https://sportin.io/privacy-policy/')}
            >
              &nbsp;{t('helper.privacyPolicy')}&nbsp;
            </Typography>{' '}
            {t('helper.asWell')}
            <Typography
              variant="p"
              fontWeight={600}
              color={PRIMARY_COLOR}
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => window.open('https://sportin.io/privacy-policy/')}
            >
              &nbsp;{t('helper.termsAndConditions')}&nbsp;
            </Typography>{' '}
            {t('helper.ofSportIn')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container flexDirection="row" alignItems="center" pt={1} pb={2}>
        <Grid item>
          <Checkbox
            onChange={(event) => setDisclaimerAccepted(event.target.checked)}
            checked={disclaimerAccepted}
          />
        </Grid>
        <Grid lg={10} xs={10} item sx={{ position: 'relative', top: -2 }}>
          <Typography variant="p" fontSize={14}>
            {t('checkout.iHaveRead')}{' '}
            <Typography
              variant="p"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              fontWeight={600}
              color="primary"
              onClick={() => window.open('https://www.dropbox.com/s/cq36i0lav0h6eov/Termify-1676881246.pdf?dl=0')}
            >
              {t('checkout.disclaimerOfLiability')}
            </Typography>
            , {t('checkout.acceptDisclaimer')}
          </Typography>
        </Grid>
      </Grid>
      <Button
        onClick={handleCheckout}
        disabled={
          loading ||
          !startFrom ||
          !termsAccepted ||
          !disclaimerAccepted ||
          (service &&
            service.Subscriptions &&
            service.Subscriptions.length &&
            !selectedSubscriptions.length) ||
          (company && company.customPrivacyPolicyLink && company.customTermsAndConditionsLink ? !acceptedCustomPrivacyPolicyLink : false)
        }
        color="primary"
        variant="contained"
        fullWidth
      >
        {paymentType === 'card' ? t('checkout.continue') : t('checkout.sendRequest')}
      </Button>
    </Grid>
  )
}

export default ConditionsAndSubmit
