import * as React from 'react'
import { Box, Drawer, List, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, IconButton } from '@mui/material'
import { Menu, Dashboard, Info, LocalPhone, LiveHelp, LogoutOutlined, People, Assignment } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ReactComponent as Logo } from '../../../assets/logo.svg'
import { PRIMARY_COLOR } from '../../../utils/constants'
import { useAuthentication } from '../../../context/auth-context'

const STACK = [
    { path: '/', name: 'Dashboard', icon: (props = {}) => <Dashboard {...props} /> },
    { path: '/users', name: 'Users', icon: (props = {}) => <People {...props} /> },
    { path: '/associations', name: 'Associeri', icon: (props = {}) => <Assignment {...props} /> }
]

export default () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { handleToken, handleLoggedIn, handleUser } = useAuthentication()

    const [isOpen, setIsOpen] = React.useState(false)

    const toggleDrawer = () => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setIsOpen(prevState => !prevState)
    }

    const handleLogout = () => {
        toggleDrawer()
        localStorage.removeItem('token')
        localStorage.removeItem("newLanguage")
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('email')
        localStorage.removeItem('companyId')
        handleUser(null)
        handleLoggedIn(false)
        handleToken('')
        navigate('/')
    }

    const Options = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer()}
            onKeyDown={toggleDrawer()}
        >
            <List>
                <ListItem sx={{ paddingBottom: 3, paddingTop: 1 }}>
                    <ListItemIcon>
                        <Logo />
                    </ListItemIcon>
                </ListItem>
                {STACK.map((item) => (
                    <ListItem key={item.name} disablePadding style={{ ...(window.location.pathname === item.path ? { backgroundColor: PRIMARY_COLOR, color: 'white' } : {}) }}>
                        <ListItemButton onClick={() => navigate(item.path)}>
                            <ListItemIcon color={{}}>
                                {item.icon(window.location.pathname === item.path ? { sx: { color: 'white' } } : {})}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
                <Divider />
                <ListItem disablePadding>
                    <ListItemButton onClick={() => {
                        window.open(`https://help.xtrainer.fit/en`)
                    }}>
                        <ListItemIcon>
                            <Info fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('navbar.userManual')}</ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={handleLogout}>
                        <ListItemIcon>
                            <LogoutOutlined />
                        </ListItemIcon>
                        <ListItemText>{t('navbar.logout')}</ListItemText>
                    </ListItemButton>
                </ListItem>

            </List>
        </Box>
    )

    return (
        <>
            <IconButton onClick={toggleDrawer()}>
                <Menu />
            </IconButton>
            <Drawer
                anchor={'left'}
                open={isOpen}
                onClose={toggleDrawer()}
            >
                {Options()}
            </Drawer>
        </>
    )
}