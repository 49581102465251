import React, { useState, useCallback, useEffect } from 'react'
import {
  Grid,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  TextField,
  Container
} from '@mui/material'
import { geocodeByAddress, getLatLng, geocodeByLatLng } from 'react-google-places-autocomplete'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { Search, Map } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import { GOOGLE_API_KEY } from '../../../utils/config'
import {
  PRIMARY_COLOR,
  LIGHT_PRIMARY_COLOR,
  WHITE_COLOR,
  GRAY_TEXT_COLOR
} from '../../../utils/constants'
import { useAuthentication } from '../../../context/auth-context'
import { ReactComponent as Logo } from '../../../assets/logo-simple.svg'

const SearchSection = ({ onSearch }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const matchesTablet = useMediaQuery(theme.breakpoints.down('md'))
  const { user } = useAuthentication()
  const navigate = useNavigate()

  const [searchField, setSearchField] = useState('')
  const [locationLat, setLocationLat] = useState()
  const [locationLng, setLocationLng] = useState()
  const [locationName, setLocationName] = useState('')

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      geocodeByLatLng({ lat: position.coords.latitude, lng: position.coords.longitude }).then(
        (result) => {
          if (!result.length) return
          const locationName = result[0].formatted_address

          setLocationName(locationName)
          setLocationLat(position.coords.latitude)
          setLocationLng(position.coords.longitude)
          onSearch({
            locationName,
            locationLat: position.coords.latitude,
            locationLng: position.coords.longitude
          })
        }
      )
    })
  }, [])

  const handleChangeSearchField = useCallback(
    (event) => {
      setSearchField(event.target.value)
    },
    [searchField]
  )

  const handleSearchButtonPress = () => {
    onSearch({ search: searchField, locationLat, locationLng, locationName })
  }

  const handleOnChangeGoogle = (googleData) => {
    setLocationName(googleData.label)
    geocodeByAddress(googleData.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setLocationLat(lat)
        setLocationLng(lng)
      })
  }

  return (
    <Grid item style={{
      backgroundImage: matchesTablet
        ? `url(${require('../../../assets/banner.png')})`
        : `url(${require('../../../assets/banner.png')})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }} xs={12} mt={user ? 2.5 : 0}>
      {!user && <Grid
        sx={{ display: 'flex', flexDirection: 'row' }}
        container
        height={100}
        paddingTop={4}
        paddingBottom={4}
        paddingLeft={3}
        paddingRight={{ xs: 2, lg: 6 }}
      >
        <Grid>
          <Logo height={56} width={65} />
        </Grid>
        {!user && (
          <Grid
            sx={{
              flexDirection: 'row',
              display: 'flex',
              marginLeft: 'auto'
            }}
          >
            <Grid item pr={1}>
              <Button
                onClick={() => navigate('/login')}
                fullWidth
                variant="outlined"
                style={{ borderColor: 'white', backgroundColor: 'transparent', color: 'white' }}
              >
                {t('button.login')}
              </Button>
            </Grid>
            <Grid item width={{ lg: 150, xs: 120 }}>
              <Button
                fullWidth
                onClick={() => navigate('/signup')}
                color="primary"
                variant="contained"
              >
                {t('button.signup')}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>}
      <Grid
        container
        p={{ lg: 10, xs: 1 }}
        pl={{ xs: 0, lg: 10 }}
        pr={{ xs: 0, lg: 10 }}
        pb={{ lg: 14, xs: 1 }}
        sx={{
          display: 'flex',
          minHeight: 600,
          alignItems: 'center',
          justifyContent: 'flex-end',
          flexDirection: 'column',
        }}
      >
        <Container maxWidth="xl">
          <Grid item xs={12}>
            <Grid container marginTop="auto" pb={3}>
              <Grid
                item
                xs={12}
                pl={{ lg: 5, md: 0, xs: 0 }}
                display="flex"
                justifyContent="center"
              >
                <Typography textAlign={"center"} alignSelf="center" fontWeight="bold" variant="h2" color="white">
                  {t('helper.titleExplore')}
                </Typography>
              </Grid>
              <Grid item xs={12} pt={1} display="flex" justifyContent="center">
                <Typography textAlign={"center"} fontWeight="bold" variant="h5" color="white">
                  {t('helper.subtitleExplore')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            width="100%"
            item
            xs={12}
            sx={{ display: 'flex', flexDirection: 'row' }}
            pr={{ lg: 2, xs: 0 }}
            pb={{ xs: 1, lg: 0 }}
          >
            <Grid
              container
              width="100%"
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item lg={5.5} xs={12}>
                <TextField
                  fullWidth
                  placeholder={t('helper.searchAnything')}
                  onChange={handleChangeSearchField}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      onSearch({ search: searchField, locationLat, locationLng, locationName })
                    }
                  }}
                  InputProps={{
                    style: {
                      color: '#000',
                      height: 50,
                      borderTopLeftRadius: 4,
                      borderBottomLeftRadius: 4,
                      borderTopRightRadius: matchesTablet ? 4 : 0,
                      borderBottomRightRadius: matchesTablet ? '4px' : '0px'
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Search style={{ color: GRAY_TEXT_COLOR }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <Map style={{ color: GRAY_TEXT_COLOR }} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  InputLabelProps={{
                    style: {
                      root: {
                        color: GRAY_TEXT_COLOR
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item lg={2.5} xs={12} mt={{ xs: 1, lg: 0 }}>
                <GooglePlacesAutocomplete
                  apiKey={GOOGLE_API_KEY}
                  selectProps={{
                    onChange: handleOnChangeGoogle,
                    placeholder: locationName ? locationName.slice(0, 25) : 'Select City',
                    styles: {
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: LIGHT_PRIMARY_COLOR,
                        padding: 4,
                        height: 50,
                        borderWidth: 0,
                        borderTopLeftRadius: matchesTablet ? 4 : 0,
                        borderBottomLeftRadius: matchesTablet ? 4 : 0
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: GRAY_TEXT_COLOR,
                        fontWeight: 200,
                        fontSize: 14,
                        fontFamily: 'Quicksand'
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        marginTop: 4,
                        borderRadius: 4,
                        border: `1px solid ${PRIMARY_COLOR}`
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        padding: 14,
                        backgroundColor: state.isSelected ? WHITE_COLOR : '',
                        color: state.isSelected ? PRIMARY_COLOR : '',
                        fontFamily: 'Quicksand',
                        '&:hover': {
                          backgroundColor: `${LIGHT_PRIMARY_COLOR} !important`
                        }
                      })
                    }
                  }}
                />
              </Grid>
              <Grid item lg={1} pl={{ xs: 0, lg: 1 }} xs={12} mt={{ xs: 1, lg: 0 }}>
                <Button
                  style={{ height: 50 }}
                  fullWidth
                  onClick={handleSearchButtonPress}
                  color="primary"
                  variant="contained"
                >
                  <Search style={{ color: 'white', fontWeight: 'bold' }} fontSize="large" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  )
}

export default SearchSection