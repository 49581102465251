import React, { useEffect, useState } from 'react'
import { Typography, Grid, Card, Box, IconButton, Popper, Fade, Paper, TextField, Button, Avatar } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { toast } from 'react-toastify';
import moment from 'moment'

import { useAuthentication } from '../../../context/auth-context'
import { ROLE_BUSINESS_ADMINISTRATOR, GRAY_TEXT_COLOR, GREEN_COLOR } from '../../../utils/constants'
import * as DASHBOARD_REQUESTS from '../../../api/dashboard'
import { Table } from '../../common'
import { FileDownloadOutlined } from '@mui/icons-material';
import { numberFomatter } from '../../../utils/helpers/numberFomatter';

export default () => {
    const { t } = useTranslation()
    const { user } = useAuthentication()

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()
    const [slice, setSlice] = useState(1)
    const [anchorEl, setAnchorEl] = useState(null)
    const [open, setOpen] = useState(false)
    const [placement, setPlacement] = useState()
    const [exportStart, setExportStart] = useState(moment().startOf('month'))
    const [exportEnd, setExportEnd] = useState(moment().endOf('month'))

    useEffect(() => {
        if (!user || !user.role === ROLE_BUSINESS_ADMINISTRATOR) return

        DASHBOARD_REQUESTS.getTopUsers()
            .then(response => {
                setData(response)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [user])

    const handleOpenPopper = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget)
        setOpen((prev) => placement !== newPlacement || !prev)
        setPlacement(newPlacement)
    }

    const handleExport = async () => {
        try {
            toast.info(t('reports.infoGenerate'), { position: 'bottom-right' })
            await DASHBOARD_REQUESTS.downloadTopUsersPdf(moment(exportStart).format('YYYY-MM-DD'), moment(exportEnd).format('YYYY-MM-DD'))
            toast.success(t('reports.successGenerate'), { position: 'bottom-right' })
        }
        catch (e) {
            toast.error(t('reports.errorGenerate'), { position: 'bottom-right' })
        }
        setAnchorEl()
        setOpen(false)
    }

    if (loading || !data) return null

    return (
        <>
            <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <Box p={2} border={`1px solid rgba(0,0,0,0.1)`}>
                                <Typography pb={2} variant="subtitle1">{t('reports.selectTime')}</Typography>
                                <Box display="flex" flexDirection="row">
                                    <Box pr={1}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                inputFormat="MM/dd/yyyy"
                                                value={exportStart}
                                                onChange={(newValue) => setExportStart(newValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        sx={{
                                                            '.MuiIconButton-root': { marginRight: 1 }
                                                        }}
                                                        {...params}
                                                    />
                                                )} />
                                        </LocalizationProvider>
                                    </Box>
                                    <Box pl={1}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                inputFormat="MM/dd/yyyy"
                                                value={exportEnd}
                                                onChange={(newValue) => setExportEnd(newValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        sx={{
                                                            '.MuiIconButton-root': { marginRight: 1 }
                                                        }}
                                                        {...params}
                                                    />
                                                )} />
                                        </LocalizationProvider>
                                    </Box>
                                </Box>
                                <Box pt={2}>
                                    <Button onClick={handleExport} variant="outlined" color="primary">Export</Button>
                                </Box>
                            </Box>
                        </Paper>
                    </Fade>
                )}
            </Popper>
            <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column">
                        <Typography variant="p" fontWeight={'bold'} fontSize={20} pl={1}>
                            {t('reports.topUsers')}
                        </Typography>
                        <Typography variant="p" pb={1} color={GRAY_TEXT_COLOR} pl={1}>{t('reports.last12months')}</Typography>
                    </Box>
                    <Box ml="auto">
                        <IconButton onClick={handleOpenPopper('bottom-start')}><FileDownloadOutlined /></IconButton>
                    </Box>
                </Box>
                <Grid container component={Card} sx={{
                    boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
                    border: '1px solid #F0F0F0',
                    borderRadius: 2
                }} p={2} mt={1}>
                    <Grid item xs={12}>
                        <Table
                            size="small"
                            headCells={[
                                'id',
                                'name',
                                'paymentTotal'
                            ].map((key) => t(`reports.tableHead.${key}`))}
                            rows={data.slice(0, slice).map(el => {
                                return {
                                    id: (
                                        <Typography
                                            variant="subtitle1"
                                            color="primary"
                                            fontWeight="bold"
                                        >
                                            {el.User.id}
                                        </Typography>
                                    ),
                                    nume: (
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Avatar src={el.User.avatarPhotoSrc} />
                                            <Typography
                                                pl={1.5}
                                                variant="subtitle1"
                                                color="primary"
                                                fontWeight="bold"
                                            >
                                                {el.User.firstName} {el.User.lastName}
                                            </Typography>
                                        </Box>
                                    ),
                                    paymentTotal: (
                                        <Typography variant="subtitle1" color={GREEN_COLOR} fontWeight="bold">
                                            {numberFomatter(el.totalPayment)}
                                        </Typography>
                                    ),
                                }
                            })}
                        />
                    </Grid>
                    {slice < data.length ? <Grid pt={2} display="flex" alignItems="center" justifyContent="center" item xs={12} onClick={() => setSlice(slice + 1)}>
                        <Typography sx={{ cursor: 'pointer' }}>
                            {t('reports.showMore')}
                        </Typography>
                    </Grid> : null}
                </Grid>
            </Box>
        </>
    )
}