import React, { useMemo } from 'react'
import { Grid, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

import BG_IMAGE from '../../../assets/signUp.png'

import { TypeCard } from './'
import { BLACK, PRIMARY_COLOR, TYPE_COMPANY, TYPE_ORG, TYPE_TRAINER } from '../../../utils/constants'
import { BootstrapButton } from '../styles'

const Type = ({ handleOnSelect, handleNext, handlePrev, type }) => {
  const { t } = useTranslation()

  const types = [
    {
      name: t(`type.${TYPE_TRAINER.toLowerCase()}`),
      subtitle: t('signup.type.profesional.subtitle'),
      content: t('signup.type.profesional.content')
    },
    {
      name: t(`type.${TYPE_COMPANY.toLowerCase()}`),
      subtitle: t('signup.type.club.subtitle'),
      content: t('signup.type.club.content')
    },
    {
      name: t(`type.${TYPE_ORG.toLowerCase()}`),
      subtitle: t('signup.type.company.subtitle'),
      content: t('signup.type.company.content')
    }
  ]
  const renderContent = (content, index) => {
    const textArray = content.split(' ');

    let wordsToStyle;
    if (index === 0) {
      wordsToStyle = [14, 15, 16, 17, 20, 21, 22, 23, 24, 25, 26];
    } else if (index === 1) {
      wordsToStyle = [18, 19, 20, 21, 24, 25, 26, 27, 28, 29, 30, 31];
    } else if (index === 2) {
      wordsToStyle = [3, 7, 15, 16];
    } else {
      wordsToStyle = [];
    }

    return (
      <Typography fontWeight="bold" variant="subtitle1">
        {textArray.map((word, i) => (
          <span key={i} style={{ color: wordsToStyle.includes(i) ? BLACK : '#aaa' }}>
            {word}{' '}
          </span>
        ))}
      </Typography>
    );
  };

  const renderForm = useMemo(() => {
    return (
      <Grid item xs={12} lg={6} p={{ xs: 4, lg: 10 }} pt={{ xs: 4, lg: 0 }} pr={{ xs: 2, lg: 0 }}>
        <Grid container height="100%" alignItems="center">
          <Grid container direction="column" justifyContent="center" rowSpacing={4}>
            <Grid item display="flex" direction="column" justifyContent={'space-between'} mb={'6%'}>
              <Typography fontWeight="bold" variant="h4" style={{ fontSize: 32 }}>
                <span style={{ color: BLACK }}>{t('title.title1').split(' ')[0]} </span>
                <span style={{ color: '#bbb' }}>
                  {t('title.title1')
                    .split(' ')
                    .slice(1, -1)
                    .join(' ')}
                </span>
                <span style={{ color: BLACK }}>
                  {' '}{t('title.title1').split(' ').slice(-1)}
                </span>
              </Typography>

              <Grid item display="flex"
                direction="row" justifyContent={'space-between'}>
                <Typography
                  fontWeight="bold"
                  variant="subtitle1"
                  style={{ color: '#aaa', wordBreak: 'break-word' }}>
                  {t('signup.subtitle')}
                  <Typography
                    variant="p"
                    fontWeight={600}
                    color={PRIMARY_COLOR}
                    sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => window.open('https://linktr.ee/sportin.io')}
                  >
                    &nbsp;{t('signup.here')}&nbsp;
                  </Typography>{' '}
                </Typography>

              </Grid>
            </Grid>
            {types.map((el, index) => {
              return (
                <Grid key={index} item xs={12}>
                  <TypeCard
                    onSelect={handleOnSelect}
                    selected={type}
                    type={el.name}
                    subtitle={el.subtitle}
                    content={renderContent(el.content, index)}
                  />
                </Grid>
              )
            })}
            <Grid item display="flex" flexDirection="row">
              <Grid container display="flex" flexDirection="row">
                <Grid item paddingRight={{ lg: 4, xs: 1 }} xs={6} lg={4}>
                  <BootstrapButton fullWidth onClick={handlePrev} style={{
                    color: BLACK
                  }} variant="outlined">
                    {t('button.cancel')}
                  </BootstrapButton>
                </Grid>
                <Grid item paddingLeft={{ lg: 4, xs: 1 }} xs={6} lg={8}>
                  <Button
                    disabled={!type}
                    fullWidth
                    onClick={handleNext}
                    style={{ backgroundColor: BLACK, color: '#fff' }}
                    variant="contained"
                  >
                    {t('button.next')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }, [types, type, handleOnSelect, handleNext, handlePrev, t])

  const renderImage = useMemo(() => {
    return (
      <Grid item xs={12} display="flex"
        direction="row" alignItems='center' lg={6} height={{ lg: '90%', xs: '50%' }}>
        <Grid item display="flex"
          height={'100%'}
          direction="column" alignItems='center' justifyContent={{ md: 'start', lg: 'center' }}>
          <Grid
            item
            width={'100%'}
            display="flex" justifyContent="center">
            <img
              style={{ position: 'relative', objectFit: 'cover' }}
              height={'100%'}
              src={BG_IMAGE}
              width={'90%'}
            />
          </Grid>
          <Grid item display="flex" alignItems={'flex-end'} justifyContent="center" >
            <Grid item width={'80%'}>
              <Typography
                fontWeight="bold"
                variant="h4"
                style={{ color: BLACK, textAlign: 'center', wordBreak: 'break-word' }}>
                {t('title.youSignUp')}
              </Typography>
            </Grid>
          </Grid >
        </Grid >
      </Grid >
    )
  }, [])

  return (
    <Grid container style={{ padding: 0, overflowX: 'hidden', overflowY: 'hidden' }}>
      {renderForm}
      {renderImage}
    </Grid>
  )
}

export default Type
