import React, { useState, useEffect } from 'react'
import { Grid, Card } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { LIGHT_GRAY_BORDER_COLOR } from '../../../utils/constants'

import * as COMPANY_REQUESTS from '../../../api/company'
import * as TRAINER_REQUESTS from '../../../api/trainer'
import * as ORGANIZATION_REQUESTS from '../../../api/organization'

import { useAuthentication } from '../../../context/auth-context'
import FilterDeals from './subcomponents/FilterDeals'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
    flexGrow: 1
  }
})

const Deals = ({ type, trainer }) => {
  const classes = useStyles()
  const { user } = useAuthentication()

  const [deals, setDeals] = useState([])
  const [typeComponent, setTypeComponent] = useState()

  useEffect(() => {
    if (type)
      setTypeComponent(type)
  }, [type])



  const handleGetAllDeals = () => {
    typeComponent && typeComponent === 'Company' ? COMPANY_REQUESTS.getDeals(user.companyId).then((res) => {
      setDeals(res)
    })
      : typeComponent === "Organization" ? ORGANIZATION_REQUESTS.getDeals(user.organizationId).then((res) => {
        setDeals(res)

      }) : typeComponent === 'Trainer' ?
        TRAINER_REQUESTS.getDeals(trainer.id).then((res) => {
          setDeals(res)

        }) : null

  }
  useEffect(() => {
    handleGetAllDeals()
  }, [typeComponent])

  return (
    <FilterDeals
      deals={deals}
      type={typeComponent}
      handleGetAllDeals={handleGetAllDeals}
    />
  )
}

export default Deals
