import { Grid, Container } from '@mui/material'

import { Footer } from '../common'
import Reports from '../UserProfile/subcomponents/subcomponents/Report'

const Measurements = () => {
  return (
    <Container maxWidth="xl">
      <Grid container pt={5} pb={5}>
        <Reports />
      </Grid>
    </Container>
  )
}

export default Measurements
