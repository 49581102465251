import React, { useEffect, useState } from 'react'
import { Grid, Container } from '@mui/material'

import BusinessActivity from './subcomponents/BusinessActivity'

import * as COMPANY_REQUESTS from '../../api/company'
import { refetchBusinessProfileObservable } from '../../utils/observables'
import { useAuthentication } from '../../context/auth-context'

const BusinessProfile = ({ type }) => {
  const refetchBusinessProfile = refetchBusinessProfileObservable.useRefetchBusinessProfile()
  const { user } = useAuthentication()

  const [company, setCompany] = useState()


  const getCompany = () => {
    type === 'Company' ? COMPANY_REQUESTS.getBusiness(user?.companyId).then(setCompany) : setCompany(user);
  }

  useEffect(() => {
    getCompany()
  }, [user, type])

  useEffect(() => {
    if (!refetchBusinessProfile) return
    getCompany()
  }, [refetchBusinessProfile])

  if (!company) return

  return (
    <Container maxWidth="xl">
      <Grid container pt={5} pb={{ xs: 8 }} display="flex" justifyContent='center' flexDirection="row" width="100%">
        <Grid item lg={11} xs={12} md={12} sm={12} p={2} pr={{ xs: 0 }} pl={{ xs: 0 }}>
          <Grid container height="100%">
            <BusinessActivity type={type} trainer={company} />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default BusinessProfile
