import React from 'react'
import { Grid, Typography, Box } from '@mui/material'

import { PRIMARY_COLOR } from '../../../utils/constants'
import { InputGenerator } from '../../common'
import { useTranslation } from 'react-i18next'

const PaymentInputs = ({
  paymentType,
  paymentInputs,
  wrongPaymentInputs,
  computePaymentDeadline,
  computePhoneNumber,
  computeEmail,
  computeHelperText,
  filterPaymentInputs,
  handleOnChangeDate,
  handleOnChangePayment
}) => {

  const { t } = useTranslation()

  if (paymentType === 'transfer')
    return (
      <Grid item lg={12} pb={2} pt={2.5}>
        <Grid container display="flex" flexDirection="column">
          <Grid height={'min-content'} pb={0.5} container display="flex" flexDirection="row">
            <Grid item xs={0.4} pt={0.8}>
              <Box sx={{ height: 8, width: 8, borderRadius: '50%', background: PRIMARY_COLOR }} />
            </Grid>
            <Grid item xs={11.6}>
              <Typography sx={{ padding: 0, margin: 0 }} variant="p" color={'#9CB2CD'}>
                {t('checkout.youWillReceive')}
              </Typography>
            </Grid>
          </Grid>
          <Grid height={'min-content'} pb={0.5} container display="flex" flexDirection="row">
            <Grid item xs={0.4} pt={0.8}>
              <Box sx={{ height: 8, width: 8, borderRadius: '50%', background: PRIMARY_COLOR }} />
            </Grid>
            <Grid item xs={11.6}>
              <Typography variant="p" color={'#9CB2CD'}>
                {t('checkout.theInvoice')}
              </Typography>
            </Grid>
          </Grid>
          <Grid height={'min-content'} pb={0.5} container display="flex" flexDirection="row">
            <Grid item xs={0.4} pt={0.8}>
              <Box sx={{ height: 8, width: 8, borderRadius: '50%', background: PRIMARY_COLOR }} />
            </Grid>
            <Grid item xs={11.6}>
              <Typography variant="p" color={'#9CB2CD'}>
                {t('checkout.youCanSend')} {computeEmail()}.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )

  if (paymentType === 'cash') {
    const paymentDeadline = computePaymentDeadline()
    return (
      <Grid item lg={12} pb={2} pt={2.5}>
        <Grid container display="flex" flexDirection="column">
          <Grid height={'min-content'} pb={0.5} container display="flex" flexDirection="row">
            <Grid item xs={0.4} pt={0.8}>
              <Box sx={{ height: 8, width: 8, borderRadius: '50%', background: PRIMARY_COLOR }} />
            </Grid>
            <Grid item xs={11.6}>
              <Typography sx={{ padding: 0, margin: 0 }} variant="p" color={'#9CB2CD'}>
                {t('checkout.youHave')} {paymentDeadline && Array.isArray(paymentDeadline) ? paymentDeadline.map(el => {
                  return <><Typography fontSize={16} color={'#9CB2CD'} fontWeight={400} variant="h6">{el.hours * 24} {t('checkout.hoursFor')} {el.name}</Typography>{'\n'}</>
                }) : paymentDeadline} {Array.isArray(paymentDeadline) ? '' : 'hours'} {t('checkout.toPay')}
              </Typography>
            </Grid>
          </Grid>
          <Grid height={'min-content'} pb={0.5} container display="flex" flexDirection="row">
            <Grid item xs={0.4} pt={0.8}>
              <Box sx={{ height: 8, width: 8, borderRadius: '50%', background: PRIMARY_COLOR }} />
            </Grid>
            <Grid item xs={11.6}>
              <Typography variant="p" color={'#9CB2CD'}>
                {t('checkout.ifQuestions')} {computeEmail()} {t('checkout.orCall')} +
                {computePhoneNumber()} .
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid item lg={12} pt={2}>
      <Grid container display="flex" flexDirection="column">
        <Grid height={'min-content'} pb={0.5} container display="flex" flexDirection="row">
          <Grid item xs={0.4} pt={0.8}>
            <Box sx={{ height: 8, width: 8, borderRadius: '50%', background: PRIMARY_COLOR }} />
          </Grid>
          <Grid item xs={11.6}>
            <Typography sx={{ padding: 0, margin: 0 }} variant="p" color={'#9CB2CD'}>
              {t('checkout.cardYouWillHaveToDo')}
            </Typography>
          </Grid>
        </Grid>
        <Grid height={'min-content'} pb={0.5} container display="flex" flexDirection="row">
          <Grid item xs={0.4} pt={0.8}>
            <Box sx={{ height: 8, width: 8, borderRadius: '50%', background: PRIMARY_COLOR }} />
          </Grid>
          <Grid item xs={11.6}>
            <Typography variant="p" color={'#9CB2CD'}>
              {t('checkout.cardYouWillRecieve')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PaymentInputs
