import axios from './axios'

export const generatePresignedUrl = ({ name, extension }) => {
  return axios
    .get(`/documents/generatePresignedUrl?name=${name}&extension=${extension}`)
    .then((res) => res.data)
}

export const uploadDocumentS3 = (presignedUrl, file) => {
  return fetch(presignedUrl, {
    method: 'PUT',
    body: file
  })
}

export const createDocument = (payload) => {
  return axios.post(`/documents`, payload).then((res) => res.data)
}

export const deleteDocument = (documentId) => {
  return axios.delete(`/documents/${documentId}`).then((res) => res.data)
}
