import React, { useEffect, useState, useMemo } from 'react'
import { Grid, Container, CircularProgress, Box } from '@mui/material'

import BusinessActivity from './subcomponents/BusinessActivity'
import BusinessDetails from './subcomponents/BusinessDetails'

import * as COMPANY_REQUESTS from '../../api/company'
import { refetchBusinessProfileObservable } from '../../utils/observables'
import { useAuthentication } from '../../context/auth-context'

const BusinessProfile = () => {
  const refetchBusinessProfile = refetchBusinessProfileObservable.useRefetchBusinessProfile()
  const { user } = useAuthentication()

  const [company, setCompany] = useState()
  const [loading, setLoading] = useState(true)

  const getCompany = () => {
    setLoading(true)
    COMPANY_REQUESTS.getBusiness(user.companyId)
      .then((companyData) => {
        setCompany(companyData)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getCompany()
  }, [user])

  useEffect(() => {
    if (!refetchBusinessProfile) return
    getCompany()
    refetchBusinessProfileObservable.setRefetchBusinessProfile(false)
  }, [refetchBusinessProfile])

  const renderBusinessDetails = useMemo(() => {
    return <BusinessDetails company={company} onEditCloseHandler={getCompany} />
  }, [company])

  const renderBusinessActivity = useMemo(() => {
    return <BusinessActivity company={company} />
  }, [company])

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <CircularProgress />
      </Box>
    )
  }

  if (!company) return null

  return (
    <Container maxWidth="xl">
      <Grid container pt={5} pb={{ xs: 8 }} display="flex" flexDirection="row" width="100%">
        <Grid item lg={3.5} xs={12} md={12} sm={12} p={2} pr={{ xs: 0, lg: 2 }} pl={{ xs: 0 }}>
          <Grid container>{renderBusinessDetails}</Grid>
        </Grid>
        <Grid item lg={8.5} xs={12} md={12} sm={12} p={2} pr={{ xs: 0 }} pl={{ xs: 0 }}>
          <Grid container height="100%">
            {renderBusinessActivity}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default BusinessProfile
