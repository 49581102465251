import React, { useEffect, useState } from 'react'
import {
  Grid,
  Typography,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  IconButton,
  Card
} from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Delete, Edit } from '@mui/icons-material'

import { LineChart, InputGenerator, BarChart } from '../../../../common'
import DeleteMeasurement from '../../../../UserMeasurements/subcomponents/DeleteMeasurement'
import DeleteMeasurementType from '../../../../UserMeasurements/subcomponents/DeleteMeasurementType'
import EditMeasurement from '../../../../UserMeasurements/subcomponents/EditMeasurement'
import EditMeasurementType from '../../../../UserMeasurements/subcomponents/EditMeasurementType'
import CreateMeasurement from '../../../../UserMeasurements/subcomponents/CreateMeasurement'
import CreateMeasurementType from '../../../../UserMeasurements/subcomponents/CreateMeasurementType'

import { BLUE_CHART, MAIN_MEASUREMENTS, ROLE_TRAINER } from '../../../../../utils/constants'
import * as MEASUREMENTS_REQUEST from '../../../../../api/measurements'
import * as MEASUREMENT_TYPES_REQUEST from '../../../../../api/measurementType'
import { useAuthentication } from '../../../../../context/auth-context'

const Weight = ({ userId }) => {
  const [data, setData] = useState([])
  const [types, setTypes] = useState([])

  const [from, setFrom] = useState(moment().subtract(3, 'months'))
  const [upTo, setUpTo] = useState()
  const [chartType, setChartType] = useState('line')
  const [measurements, setMeasurements] = useState('')
  const [showRecords, setShowRecords] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showAdd, setShowAdd] = useState(false)
  const [showDeleteType, setShowDeleteType] = useState(false)
  const [showEditType, setShowEditType] = useState(false)
  const [showAddType, setShowAddType] = useState(false)
  const [showManageTypes, setShowManageTypes] = useState(false)
  const [selectedItem, setSelectedItem] = useState()
  const [selectedType, setSelectedType] = useState()

  const { t } = useTranslation()
  const { user } = useAuthentication()
  const isTrainer = user && user.role && user.role.toLowerCase() === ROLE_TRAINER.toLowerCase()

  const handleGetMeasurements = async () => {
    try {
      const response = await MEASUREMENTS_REQUEST.getMeasurements({
        mainType: MAIN_MEASUREMENTS.CUSTOM,
        secondaryType: measurements,
        from: moment(from).format('YYYY-MM-DD'),
        upTo: moment(upTo).format('YYYY-MM-DD'),
        userId
      })
      setData(
        response.map((el) => ({
          date: moment(el.from).format('YYYY-MM-DD'),
          value: el.value,
          type: el.secondaryType,
          id: el.id
        }))
      )
    } catch (e) {
      console.error(e)
    }
  }

  const handleGetMeasurementTypes = async () => {
    try {
      const response = await MEASUREMENT_TYPES_REQUEST.getMeasurementsTypes({}, userId)
      setTypes(response.map((el) => ({ value: el.name, label: el.name, id: el.id })))
      if (response && response.length && !measurements) {
        setMeasurements(response[0].name)
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (isTrainer && !userId) return
    handleGetMeasurementTypes()
    if (measurements) {
      handleGetMeasurements()
    }
  }, [from, upTo, measurements, showManageTypes, userId, isTrainer])

  const handleShowEdit = (id) => {
    setSelectedItem(id)
    setShowEdit(true)
  }

  const handleShowDelete = (id) => {
    setSelectedItem(id)
    setShowDelete(true)
  }

  const handleShowEditType = (id) => {
    setSelectedType(id)
    setShowEditType(true)
  }

  const handleShowDeleteType = (id) => {
    setSelectedType(id)
    setShowDeleteType(true)
  }

  const handleDelete = async () => {
    try {
      await MEASUREMENTS_REQUEST.deleteMeasurement(selectedItem)
      setSelectedItem()
      setShowDelete(false)
      handleGetMeasurements()
    } catch (e) {
      console.error(e)
      setSelectedItem()
      setShowDelete(false)
    }
  }

  const handleDeleteType = async () => {
    try {
      await MEASUREMENT_TYPES_REQUEST.deleteMeasurementType(selectedType)
      setSelectedType()
      setShowDeleteType(false)
      handleGetMeasurementTypes()
    } catch (e) {
      console.error(e)
      setSelectedItem()
      setShowDeleteType(false)
    }
  }

  const handleEdit = async (data) => {
    try {
      await MEASUREMENTS_REQUEST.updateMeasurement(selectedItem, data)
      setSelectedItem()
      setShowEdit(false)
      handleGetMeasurements()
    } catch (e) {
      console.error(e)
      setSelectedItem()
      setShowEdit(false)
    }
  }

  const handleEditType = async (data) => {
    try {
      await MEASUREMENT_TYPES_REQUEST.updateMeasurementType(selectedType, data)
      setSelectedType()
      setShowEditType(false)
      handleGetMeasurementTypes()
    } catch (e) {
      console.error(e)
      setSelectedItem()
      setShowEditType(false)
    }
  }

  const handleCreateMeasurement = async (data) => {
    try {
      await MEASUREMENTS_REQUEST.createMeasurement(data, userId)
      setShowAdd(false)
      handleGetMeasurements()
    } catch (e) {
      console.error(e)
      setShowAdd(false)
    }
  }

  const handleCreateMeasurementType = async (data) => {
    try {
      await MEASUREMENT_TYPES_REQUEST.createMeasurementType(data, userId)
      setShowAddType(false)
      handleGetMeasurementTypes()
    } catch (e) {
      console.error(e)
      setShowAddType(false)
    }
  }

  const renderManageProgress = () => {
    if (!showRecords) return null
    return (
      <Grid container display="flex" flexColumn pt={4}>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight={600}>
            {t('userProfile.manageProgress')}
          </Typography>
        </Grid>
        <Grid item xs={12} pt={2}>
          <Grid container>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('userProfile.measurementType')}</TableCell>
                      <TableCell align="right">{t('userProfile.value')}</TableCell>
                      <TableCell align="right">{t('userProfile.options')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((el, index) => (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {el.type}
                        </TableCell>
                        <TableCell align="right">{el.value}</TableCell>
                        <TableCell align="right">
                          <IconButton mr={1} onClick={() => handleShowEdit(el.id)}>
                            <Edit />
                          </IconButton>
                          <IconButton ml={1} onClick={() => handleShowDelete(el.id)}>
                            <Delete color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderManageTypes = () => {
    if (!showManageTypes) return null

    return (
      <Grid pt={3} container display="flex" flexDirection="column">
        <Grid item>
          <Grid container display="flex" flexDirection="row" alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h6">{t('userProfile.manageYour')}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item pt={3} pr={1}>
                  <Button onClick={() => setShowAddType(true)} fullWidth variant="contained">
                    {t('userProfile.addMeasurementType')}
                  </Button>
                </Grid>
                <Grid item pt={3} pl={1}>
                  <Button
                    onClick={() => setShowManageTypes((prevValue) => !prevValue)}
                    fullWidth
                    variant="outlined"
                  >
                    {showManageTypes ? t('userProfile.hideTypes') : t('userProfile.manageTypes')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {types && types.length ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('userProfile.measurementType')}</TableCell>
                    <TableCell align="right">{t('userProfile.options')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {types.map((el, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {el.value}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton mr={1} onClick={() => handleShowEditType(el.id)}>
                          <Edit />
                        </IconButton>
                        <IconButton ml={1} onClick={() => handleShowDeleteType(el.id)}>
                          <Delete color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography pl={1} color="#979797" fontWeight={500} fontSize={17}>
              {t('helper.noResultsFound')}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          {renderManageProgress()}
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {showDelete && selectedItem ? (
        <DeleteMeasurement
          handleDelete={handleDelete}
          handleClose={() => {
            setSelectedItem()
            setShowDelete(false)
          }}
        />
      ) : null}
      {showDeleteType && selectedType ? (
        <DeleteMeasurementType
          handleDelete={handleDeleteType}
          handleClose={() => {
            setSelectedType()
            setShowDeleteType(false)
          }}
        />
      ) : null}
      {showEdit && !showDelete && selectedItem ? (
        <EditMeasurement
          id={selectedItem}
          type={MAIN_MEASUREMENTS.CUSTOM}
          handleEditMeasurement={handleEdit}
          handleClose={() => {
            setSelectedItem()
            showEdit(false)
          }}
        />
      ) : null}
      {showEditType && !showDeleteType && selectedType ? (
        <EditMeasurementType
          id={selectedType}
          handleEditMeasurementType={handleEditType}
          handleClose={() => {
            setSelectedType()
            showEditType(false)
          }}
        />
      ) : null}
      {showAdd && (
        <CreateMeasurement
          userId={userId}
          type={MAIN_MEASUREMENTS.CUSTOM}
          handleCreateMeasurement={handleCreateMeasurement}
          handleClose={() => setShowAdd(false)}
        />
      )}
      {showAddType && (
        <CreateMeasurementType
          handleCreateMeasurementType={handleCreateMeasurementType}
          handleClose={() => setShowAddType(false)}
        />
      )}

      {showManageTypes ? (
        renderManageTypes()
      ) : (
        <Grid pt={3} container display="flex" flexDirection="column">
          <Grid item>
            <Grid
              container
              display="flex"
              flexDirection={{ xl: 'row', lg: 'column' }}
              pb={2}
              alignItems={{ xl: 'center', lg: 'flex-start' }}
            >
              <Grid
                flexWrap={'wrap'}
                item
                xl={8}
                lg={12}
                md={12}
                display="flex"
                flexDirection="row"
              >
                <Grid item md={3} xs={6} pr={2} pb={{ xl: 0, xs: 2 }}>
                  <Grid container display="flex" flexDirection="column">
                    <Grid pb={1} item>
                      {
                        <Typography fontWeight={500} variant="p">
                          {t('userProfile.from')}
                        </Typography>
                      }
                    </Grid>
                  </Grid>
                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        inputFormat="MM/dd/yyyy"
                        value={from}
                        onChange={(newValue) => setFrom(newValue)}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              '.MuiIconButton-root': { marginRight: 1 }
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid item md={3} xs={6} pr={{ xl: 2, xs: 0, md: 2 }} pb={{ xl: 0, xs: 2 }}>
                  <Grid container display="flex" flexDirection="column">
                    <Grid pb={1} item>
                      {
                        <Typography fontWeight={500} variant="p">
                          {t('userProfile.upTo')}
                        </Typography>
                      }
                    </Grid>
                  </Grid>
                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        inputFormat="MM/dd/yyyy"
                        value={upTo}
                        onChange={(newValue) => setUpTo(newValue)}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              '.MuiIconButton-root': { marginRight: 1 }
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid item md={3} xs={6} pr={2}>
                  <Grid container display="flex" flexDirection="column">
                    <Grid item>
                      <InputGenerator
                        handleOnChange={(event) => setMeasurements(event.target.value)}
                        input={{
                          type: 'select',
                          label: t('userProfile.measurements'),
                          placeholder: t('userProfile.selectMeasurements'),
                          value: measurements,
                          values: types
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3} xs={6}>
                  <Grid container display="flex" flexDirection="column">
                    <Grid item>
                      <InputGenerator
                        handleOnChange={(event) => setChartType(event.target.value)}
                        input={{
                          type: 'select',
                          label: t('userProfile.chartType'),
                          placeholder: t('userProfile.selectChartType'),
                          value: chartType,
                          values: [
                            { value: 'line', label: t('userProfile.lineChart') },
                            { value: 'bar', label: t('userProfile.barChart') }
                          ]
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xl={4}
                lg={12}
                xs={12}
                pr={{ xl: chartType === 'line' ? 4 : 0, xs: 0 }}
                pl={{ md: 0, lg: 2 }}
                pt={0.4}
              >
                <Grid
                  container
                  display="flex"
                  flexDirection="row"
                  justifyContent={{ xl: 'flex-end', xs: 'flex-start' }}
                  alignItems="flex-end"
                >
                  <Grid xs={6} item pt={3} pr={{ lg: 1, xs: 0.3 }}>
                    <Button onClick={() => setShowAdd(true)} fullWidth variant="contained">
                      {t('userProfile.activity.addMeasurements')}
                    </Button>
                  </Grid>
                  <Grid xs={4} item pt={3} pl={{ lg: 1, xs: 0.3 }} pr={{ lg: 1, xs: 0.3 }}>
                    <Button
                      onClick={() => setShowRecords((prevValue) => !prevValue)}
                      fullWidth
                      variant="outlined"
                    >
                      {showRecords ? t('userProfile.hide') : t('userProfile.progress')}
                    </Button>
                  </Grid>
                  <Grid xs={2} item pt={3} pl={{ lg: 1, xs: 0.3 }}>
                    <Button
                      onClick={() => setShowManageTypes((prevValue) => !prevValue)}
                      fullWidth
                      variant="outlined"
                    >
                      {showManageTypes ? t('userProfile.hideTypes') : t('userProfile.types')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container component={Card} sx={{
              boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
              border: '1px solid #F0F0F0',
              borderRadius: 2
            }} p={2}>
              {data && data.length ? (
                chartType === 'line' ? (
                  <LineChart maintainAspectRatio={true} data={{
                    labels: data.map((el) => el.date),
                    datasets: [
                      {
                        label: measurements,
                        data: data.map((el) => el.value),
                        borderColor: BLUE_CHART,
                        backgroundColor: BLUE_CHART
                      }
                    ]
                  }} />
                ) : (
                  <BarChart type={measurements} values={data} />
                )
              ) : (
                <Typography pl={1} color="#979797" fontWeight={500} fontSize={17}>
                  {t('helper.noResultsFound')}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {renderManageProgress()}
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default Weight
