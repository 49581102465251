import { Box, TableCell, Typography } from '@mui/material';

const AmountOfPaymentCell = ({ index, paymentAmount, feesAmount, payoutsAmount }) => (
    <TableCell sx={{ display: 'flex', paddingLeft: 1.2, paddingRight: 0, border: 'none !important', width: '24%' }} key={`amountOfPayment-${index}`}>
        <Box sx={{ display: 'flex', width: '100%'}}>
            <Box sx={{ width: '41%', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '1.1rem', fontWeight: '700' }}>{paymentAmount}</Typography>
            </Box>
            <Box sx={{ width: '45%', justifyContent: 'center', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '1.1rem', fontWeight: '700', color: '#CB0000' }}>-{feesAmount}</Typography>
            </Box>
            <Box sx={{ width: '7%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '1.1rem', fontWeight: '700', color: '#0664F2' }}>{payoutsAmount}</Typography>
            </Box>
        </Box>
    </TableCell>
);

export default AmountOfPaymentCell;