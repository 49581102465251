import React, { useEffect, useState, useMemo } from 'react'
import { Container, Typography, Grid, Box, Card, Button, CircularProgress } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { Money } from '@mui/icons-material'
import moment from 'moment'
import ReactGA from 'react-ga'

import Error from './Error'

import { GRAY_TEXT_COLOR, SUBSCRIPTION_PAYMENT_STATUS, BLACK_BACKGROUND_COLOR, DATE_FORMAT, DATE_TIME_FORMAT, GREEN_COLOR, PRIMARY_COLOR, ROLE_USER, ROLE_TRAINER } from '../../utils/constants'
import { useAuthentication } from '../../context/auth-context'
import * as COMPANY_REQUESTS from '../../api/company'
import * as USER_REQUESTS from '../../api/user'
import * as CHECKIN_REQUESTS from '../../api/checkin'
import { getStatus } from '../../utils/helpers/userSubscription'

import MONSTER from '../../assets/monster.gif'
import ROCKET from '../../assets/rocket.gif'
import { useTranslation } from 'react-i18next'

const SelfInUser = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { user } = useAuthentication()
    const { domain } = useParams()
    const [company, setCompany] = useState()
    const [error, setError] = useState()
    const [errorSelfIn, setErrorSelfIn] = useState(false)
    const [personSubscriptions, setPersonSubscriptions] = useState()
    const [events, setEvents] = useState()
    const [eventValue, setEventValue] = useState()
    const [userSubscriptionValue, setUserSubscriptionValue] = useState()
    const [personSubscriptionsLoading, setPersonSubscriptionsLoading] = useState(true)
    const [eventsLoading, setEventsLoading] = useState(true)
    const [activeCheckIns, setActiveCheckIns] = useState()
    const [relayId, setRelayId] = useState()

    const renderSubscriptionList = useMemo(() => {
        if (!personSubscriptions) return null

        return personSubscriptions.map(userSubscription => {
            const isPaid = SUBSCRIPTION_PAYMENT_STATUS.CONFIRMED === userSubscription.status

            return (
                <Box key={userSubscription.id} component={Card} onClick={() => {
                    setUserSubscriptionValue(userSubscription.id)
                    setEventValue(null)
                }} style={{ padding: 20, marginBottom: 15, cursor: 'pointer', border: `3px solid ${userSubscription.id === userSubscriptionValue ? PRIMARY_COLOR : 'transparent'}` }}>
                    <Typography variant="h6" fontWeight="bold" color={userSubscription.id === userSubscriptionValue ? PRIMARY_COLOR : 'black'} gutterBottom>
                        {userSubscription.Subscription.name}
                    </Typography>

                    <Typography variant="p" fontWeight="bold" color={GRAY_TEXT_COLOR} gutterBottom>
                        {t('selfin.validFrom')} {moment(userSubscription.validFrom).format(DATE_FORMAT)} until {moment(userSubscription.validUntil).format(DATE_FORMAT)}
                    </Typography>
                    <Box style={{ display: 'flex', flexDirection: 'row', mt: 3, alignItems: 'center' }}>
                        <Money style={{ color: isPaid ? GREEN_COLOR : 'red' }} />
                        <Typography variant="p" pl={1} fontWeight="bold" color={isPaid ? GREEN_COLOR : 'red'}>
                            {isPaid ? t('selfin.paid') : t('selfin.notPaid')}
                        </Typography>
                    </Box>
                    {userSubscription.limitedActivationTotal ? <Box style={{ display: 'flex', flexDirection: 'row', mt: 3, alignItems: 'center' }}>
                        <Typography variant="p" fontWeight="bold" color={GRAY_TEXT_COLOR} >
                            {t('selfin.limActivUsed')} {userSubscription.limitedActivationUsed}/{userSubscription.limitedActivationTotal}
                        </Typography>
                    </Box> : null}
                </Box>
            )
        })
    }, [personSubscriptions, userSubscriptionValue])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)

        const relayIdQuery = params.get('relayId')

        if (relayIdQuery) {
            setRelayId(relayIdQuery)
        }
    }, [window.location.search])

    useEffect(() => {
        if (personSubscriptionsLoading || eventsLoading || !company || !user) return

        CHECKIN_REQUESTS.create({
            description: 'CheckIn Error',
            userId: user.id,
            companyId: company.id,
            failed: true
        })
    }, [personSubscriptionsLoading, eventsLoading, company, user])

    const renderEventsList = useMemo(() => {
        if (!events || !events.length) return null

        return events.map(el => {
            return (
                <Box key={el.id} component={Card} onClick={() => {
                    setEventValue(el.Event.id)
                    setUserSubscriptionValue(null)
                }} style={{ padding: 20, marginBottom: 15, cursor: 'pointer', border: `3px solid ${el.id === eventValue ? PRIMARY_COLOR : 'transparent'}` }}>
                    <Typography variant="h6" fontWeight="bold" color={el.id === eventValue ? PRIMARY_COLOR : 'black'} gutterBottom>
                        {el.Event.name}
                    </Typography>

                    <Typography variant="p" fontWeight="bold" color={GRAY_TEXT_COLOR} gutterBottom>
                        {t('selfin.startsAt')} {moment(el.Event.start).utc().format(DATE_TIME_FORMAT)}
                    </Typography>
                </Box>
            )
        })
    }, [events, eventValue])

    const onCheckInHandler = () => {
        return CHECKIN_REQUESTS.create({
            userId: user.id,
            userSubscriptionId: userSubscriptionValue,
            companyId: company.id,
            eventId: eventValue,
            ...(relayId ? { relayId } : {})
        })
            .then(() => {
                ReactGA.event({
                    category: 'POST_LOGIN',
                    action: 'SELF_IN',
                    label: 'SUCCESS'
                })

                const extraQuery = relayId ? `&relayId=${relayId}` : ''

                return navigate('/dashboard?selfIn=show' + extraQuery, { replace: true })
            })
            .catch(() => {
                setErrorSelfIn(true)
                ReactGA.event({
                    category: 'POST_LOGIN',
                    action: 'SELF_IN',
                    label: 'ERROR'
                })
            })
    }

    useEffect(() => {
        COMPANY_REQUESTS.getBusinessByDomain(domain)
            .then(async companyResponse => {
                const aCheckIns = await CHECKIN_REQUESTS.getActive(companyResponse.id)
                setActiveCheckIns(aCheckIns)
                setCompany(companyResponse)
            })
            .catch(err => {
                setError(err)
            })
    }, [domain])

    useEffect(() => {
        if (!activeCheckIns || !activeCheckIns.length) return

        CHECKIN_REQUESTS.update(activeCheckIns[0].id, { checkedOut: true, companyId: company.id, ...(relayId ? { relayId } : {}) })
            .then(() => {
                const extraQuery = relayId ? `&relayId=${relayId}` : ''

                return navigate('/dashboard?selfOut=show' + extraQuery, { replace: true })

            })
    }, [activeCheckIns, company, user, relayId])

    useEffect(() => {
        if (activeCheckIns && activeCheckIns.length) return
        if (!user || !company || !events || !activeCheckIns) return

        USER_REQUESTS.getSubscriptions({
            companyId: company.id,
            userId: user.id
        }).then(async response => {
            const statusPersonSubscription = response.map(el => ({ ...el, subscriptionStatus: getStatus(el) })).filter(el => el.subscriptionStatus.active)

            if (statusPersonSubscription.length === 1 && !activeCheckIns.length && events.length === 0) {
                await CHECKIN_REQUESTS.create({
                    userId: user.id,
                    userSubscriptionId: statusPersonSubscription[0].id,
                    companyId: company.id,
                    ...(relayId ? { relayId } : {})
                })

                const extraQuery = relayId ? `&relayId=${relayId}` : ''

                return navigate('/dashboard?selfIn=show' + extraQuery, { replace: true })
            }
            setPersonSubscriptions(statusPersonSubscription)
        })
            .finally(() => {
                setPersonSubscriptionsLoading(false)
            })
    }, [events, company, user, activeCheckIns, relayId])

    useEffect(() => {
        if (activeCheckIns && activeCheckIns.length) return
        if (!company || !user || !activeCheckIns) return

        USER_REQUESTS.getUpcomingEvents(company.id)
            .then(async response => {
                if (response.length > 0 && !activeCheckIns.length) {
                    const currentEvent = response[0]

                    if (currentEvent && currentEvent.Event && currentEvent.Event.start) {
                        await CHECKIN_REQUESTS.create({
                            userId: user.id,
                            userSubscriptionId: userSubscriptionValue,
                            companyId: company.id,
                            eventId: currentEvent.Event.id,
                            ...(relayId ? { relayId } : {})
                        })

                        const extraQuery = relayId ? `&relayId=${relayId}` : ''

                        return navigate('/dashboard?selfIn=show' + extraQuery, { replace: true })
                    }
                }

                setEvents(response)
            })
            .finally(() => {
                setEventsLoading(false)
            })
    }, [company, user, activeCheckIns, relayId])

    if (error) {
        return (
            <Container maxWidth="md">
                <Grid container pt={5} pb={10}>
                    <Grid item xs={12} pt={4}>
                        <Typography variant="h5" fontWeight="bold">
                            {t('selfin.selfinError')}
                        </Typography>
                        <Typography variant="p" fontWeight="bold" color="error">
                            {t('selfin.invalidURL')}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    if (personSubscriptionsLoading || eventsLoading) {
        return (
            <Container maxWidth="xs" style={{ height: '100%' }}>
                <Box style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <img height={'70%'} width={'100%'} src={ROCKET} />
                </Box>
            </Container>
        )
    }

    if (errorSelfIn) {
        return <Container maxWidth="xs">
            <Grid item xs={12} pt={5} pb={5} mt={5} component={Card} style={{ display: 'flex', justifyContent: 'center' }}>
                <Error />
            </Grid>
        </Container>
    }

    return (
        <>
            <Container maxWidth="md">
                <Grid container p={4} pb={0} mt={5} mb={20} component={Card}>
                    <Grid item xs={12} pb={4} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                        <img height={150} width={150} src={MONSTER} />
                        {personSubscriptions && personSubscriptions.length > 1 ? <Typography variant="h6" style={{ display: 'flex', flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                            <strong>{t('selfin.hmm')}</strong> {t('selfin.looksLike')} {company.name}. {t('selfin.pleaseSelect')}
                        </Typography> : null}
                        {personSubscriptions && !personSubscriptions.length ? <>
                            <Typography variant="h6"><strong>Oops!...</strong></Typography>
                            <Typography variant="h6" style={{ display: 'flex', flexDirection: 'row' }} inline>
                                {t('selfin.youDontSeem')}
                            </Typography>
                            <Typography variant="h6" fontWeight="bold" onClick={() => navigate('/' + company.domain, { replace: true })} color={PRIMARY_COLOR} style={{ textDecoration: 'underline', display: 'flex', flexDirection: 'row' }}>{company.name}</Typography></> : null}
                    </Grid>

                    <Grid item xs={12} mb={6}>
                        <Grid container>
                            {personSubscriptions && personSubscriptions.length ? <Grid item xs={12} pt={5}>
                                <Typography variant="h5">{t('selfin.subs')}</Typography>
                                {renderSubscriptionList}
                            </Grid> : null}
                            {events && events.length ? <Grid item xs={12} pt={5}>
                                <Typography variant="h5">{t('selfin.events')}</Typography>
                                {renderEventsList}
                            </Grid> : null}
                            <Grid item xs={12} pt={5} display="flex" flexDirection="column" justifyContent="flex-end">
                                <Button disabled={!userSubscriptionValue && !eventValue} onClick={personSubscriptions && events && !personSubscriptions.length && !events.length ? () => navigate('/dashboard', { replace: true }) : onCheckInHandler} style={{ backgroundColor: !personSubscriptions.length ? PRIMARY_COLOR : BLACK_BACKGROUND_COLOR }} fullWidth variant="contained">{!personSubscriptions.length ? t('button.goToDashboard') : 'Self-In'}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

const SelfInTrainer = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { user } = useAuthentication()
    const { domain } = useParams()
    const [company, setCompany] = useState()
    const [error, setError] = useState(false)

    const onCheckInHandler = (relayId) => {
        return CHECKIN_REQUESTS.createTrainer({
            userId: user.id,
            companyId: company.id,
            ...(relayId ? { relayId } : {})
        })
            .then(response => {
                ReactGA.event({
                    category: 'POST_LOGIN',
                    action: 'SELF_IN',
                    label: 'SUCCESS'
                })

                if (!response.checkedOut) {

                    const extraQuery = relayId ? `&relayId=${relayId}` : ''

                    return navigate('/dashboard?selfIn=show' + extraQuery, { replace: true })
                }

                const extraQuery = relayId ? `&relayId=${relayId}` : ''

                return navigate('/dashboard?selfOut=show' + extraQuery, { replace: true })
            })
            .catch(() => {
                setError(true)
                ReactGA.event({
                    category: 'POST_LOGIN',
                    action: 'SELF_IN',
                    label: 'ERROR'
                })
            })
    }

    useEffect(() => {
        COMPANY_REQUESTS.getBusinessByDomain(domain)
            .then(response => {
                setCompany(response)
            })
            .catch(err => {
                console.error('A aparut o eroare.')
            })
    }, [domain])

    useEffect(() => {
        if (!company) return

        const params = new URLSearchParams(window.location.search)

        const relayIdQuery = params.get('relayId')

        if (relayIdQuery) {
            onCheckInHandler(relayIdQuery)
        } else {
            onCheckInHandler()
        }
    }, [window.location.search, company])

    if (error) {
        return (
            <Container maxWidth="md">
                <Grid container pt={5} pb={10}>
                    <Grid item xs={12} pt={4}>
                        <Typography variant="h5" fontWeight="bold">
                            {t('selfin.selfinError')}
                        </Typography>
                        <Typography variant="p" fontWeight="bold" color="error">
                            {t('selfin.invalidTrainerDescription')}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    return (
        <Container maxWidth='xl'>
            <Grid container pt={5}>
                <Grid item xs={12} display="flex" flexDirection="row" justifyContent="center">
                    <CircularProgress />
                </Grid>
            </Grid>
        </Container>
    )
}

export default props => {
    const { user } = useAuthentication()

    return user.role === ROLE_USER ? <SelfInUser /> : user.role === ROLE_TRAINER ? <SelfInTrainer /> : null
}