import React from 'react';
import { Typography } from '@mui/material';

const CustomTypography = ({ children, style, ...props }) => {
    return (
        <Typography {...props} style={{ font: 'normal normal 500 Lato', ...style }}>
            {children}
        </Typography>
    );
};

export default CustomTypography;
