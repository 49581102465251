import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { computeTVA } from '../utils/helpers/computeTVA'

export const useCheckoutVisualDetail = (service, subscription, selectedSubscriptions) => {
  const computePhoneNumber = useCallback(() => {
    if (service) {
      if (service.companyId) return service.Company.phoneNumber
      else if (service.trainerId) return service.Trainer.phoneNumber
      return '+400 000 000'
    } else if (subscription) {
      if (subscription.companyId) return subscription.Company.phoneNumber
      else if (subscription.trainerId) return subscription.Trainer.phoneNumber
      return '+400 000 000'
    }
    return '+400 000 000'
  }, [service, subscription])

  const computeEmail = useCallback(() => {
    if (service) {
      if (service.companyId) return service.Company.email
      else if (service.trainerId) return service.Trainer.email
      return 'contact@info.com'
    } else if (subscription) {
      if (subscription.companyId) return subscription.Company.email
      else if (subscription.trainerId) return subscription.Trainer.email
      return 'contact@info.com'
    }
    return 'contact@info.com'
  }, [service, subscription])

  const computePaymentDeadline = useCallback(() => {
    if (service) {
      return !selectedSubscriptions.length ? 48 : selectedSubscriptions.map(id => ({
        hours: service.Subscriptions.find(el => el.Subscription.id === id).Subscription.paymentDeadline || 0,
        name: service.Subscriptions.find(el => el.Subscription.id === id).Subscription.name
      }))
    }
    else if (subscription) {
      return subscription.paymentDeadline ? subscription.paymentDeadline * 24 : 48
    }
    return 48
  }, [service, subscription, selectedSubscriptions])

  const computeOwnerName = useCallback(() => {
    if (service) {
      if (service.companyId) return `${service.Company.domain}`
      else if (service.trainerId) return `${service.Trainer.firstName} ${service.Trainer.lastName}`
      return 'Owner'
    } else if (subscription) {
      if (subscription.companyId) return `${subscription.Company.domain}`
      else if (subscription.trainerId)
        return `${subscription.Trainer.firstName} ${subscription.Trainer.lastName}`
      return 'Owner'
    }
    return 'Owner'
  }, [service, subscription])

  const computeDomain = useCallback(() => {
    if (service) {
      if (service.companyId) return `${service.Company.domain}`
      else if (service.trainerId) return `${service.Trainer.domain}`
      return 'Owner'
    } else if (subscription) {
      if (subscription.companyId) return `${subscription.Company.domain}`
      else if (subscription.trainerId) return `${subscription.Trainer.domain}`
      return 'Owner'
    }
    return 'Owner'
  }, [service, subscription])

  const computeSubtotal = useCallback(() => {
    if (subscription) {
      if (!subscription.Company || !subscription.Company.vat) return Number(subscription.priceRon).toFixed(2)
      return Number(subscription.priceRon - computeVAT() || 0).toFixed(2)
    }
    else if (service && (!service.Subscriptions || !service.Subscriptions.length)) {
      if (!service.Company || !service.Company.vat) return Number(service.priceRon).toFixed(2)
      return Number(service.priceRon - computeVAT() || 0).toFixed(2)
    }
    else if (service && service.Subscriptions && service.Subscriptions.length > 0) {
      if (!service.Company || !service.Company.vat) return Number(
        service.Subscriptions.filter((sub) => selectedSubscriptions.includes(sub.subscriptionId))
          .map((e) => e.Subscription.priceRon)
          .reduce((prev, current) => prev + current, 0)
      ).toFixed(2)
      return Number(
        service.Subscriptions.filter((sub) => selectedSubscriptions.includes(sub.subscriptionId))
          .map((e) => e.Subscription.priceRon)
          .reduce((prev, current) => prev + current, 0) - computeVAT()
      ).toFixed(2)
    }
  }, [service, subscription, selectedSubscriptions])

  const computeVAT = useCallback(() => {
    if (subscription) {
      if (!subscription.Company || !subscription.Company.vat) return 0
      return Number(computeTVA(subscription.priceRon)).toFixed(2)
    }
    else if (service && (!service.Subscriptions || !service.Subscriptions.length)) {
      if (!service.Company || !service.Company.vat) return 0
      return Number(computeTVA(service.priceRon)).toFixed(2)
    }
    else if (service && service.Subscriptions && service.Subscriptions.length > 0) {
      if (!service.Company || !service.Company.vat) return 0
      return Number(
        computeTVA((service.Subscriptions.filter((sub) => selectedSubscriptions.includes(sub.subscriptionId))
          .map((e) => e.Subscription.priceRon)
          .reduce((prev, current) => prev + current, 0) || 0))
      ).toFixed(2)
    }
  }, [service, subscription, selectedSubscriptions])

  const computeName = useCallback(() => {
    if (subscription) return subscription.name
    else if (service && (!service.Subscriptions || !service.Subscriptions.length))
      return service.title
    else if (service && service.Subscriptions && service.Subscriptions.length > 0)
      return service.Subscriptions.filter((sub) => selectedSubscriptions.includes(sub.subscriptionId))
        .map((e) => e.Subscription.name)
        .join(',')
  }, [service, subscription, selectedSubscriptions])

  return {
    computeName,
    computeVAT,
    computeSubtotal,
    computeDomain,
    computeOwnerName,
    computeEmail,
    computePhoneNumber,
    computePaymentDeadline
  }
}

export const useCheckoutInputsError = (
  wrongPaymentInputs,
  wrongBillingInputs,
  wrongCompanyInputs,
  paymentInputs,
  billingInputs,
  companyInputs
) => {
  const { t } = useTranslation()

  const computeHelperText = (input) => {
    if (wrongPaymentInputs.map((el) => el.name).includes(input.name)) {
      if (input.value === '') return `${input.label} ${t('signup.cantBeEmpty')}`
      return t(`input.error.${input.name}`)
    }
  }

  const computeBillingHelperText = (input) => {
    if (wrongBillingInputs.includes(input.name)) {
      if (input.value === '') return `${input.label} ${t('signup.cantBeEmpty')}`
      return t(`input.error.${input.name}`)
    }
  }

  const computeCompanyHelperText = (input) => {
    if (wrongCompanyInputs.includes(input.name)) {
      if (input.value === '') return `${input.label} ${t('signup.cantBeEmpty')}`
      return t(`input.error.${input.name}`)
    }
  }

  const filterPaymentInputs = useCallback(
    (level) => paymentInputs.filter((input) => input.level === level),
    [paymentInputs]
  )

  const filterBillingInputs = useCallback(
    (level) => billingInputs.filter((input) => input.level === level),
    [billingInputs]
  )

  const filterCompanyInputs = useCallback(
    (level) => companyInputs.filter((input) => input.level === level),
    [companyInputs]
  )

  return {
    computeHelperText,
    computeBillingHelperText,
    computeCompanyHelperText,
    filterPaymentInputs,
    filterBillingInputs,
    filterCompanyInputs
  }
}
