import { Checkbox, Grid, Typography } from "@mui/material"
import Subscription from "../../../TrainerProfile/subcomponents/subcomponents/subcomponents/Subscription"
import { useTranslation } from "react-i18next"

function AvailableSubscriptions({ availableSubscriptions, handleExpand, handleSelectSubscription, selectedSubscriptions }) {
    const { t } = useTranslation()

    return (
        <Grid container display="flex" flexDirection="column">
            <Grid item xs={12}>
                {availableSubscriptions?.length > 0 ? (
                    <Grid container display="flex" flexDirection="column">
                        {availableSubscriptions.map((el, index) => {
                            return (
                                <Grid
                                    key={index}
                                    item
                                    xs={12}
                                    pb={2}
                                    maxHeight={{ lg: !el.expand ? 110 : '', xs: !el.expand ? 150 : '' }}
                                >
                                    <Grid container display="flex" flexDirection="row" alignItems="center">
                                        <Grid item xs={2} lg={0.5} md={0.5}>
                                            <Checkbox
                                                checked={selectedSubscriptions.includes(el.id)}
                                                onChange={(event) => handleSelectSubscription(event, el.id)}
                                            />
                                        </Grid>
                                        <Grid item xs={10} lg={11.5} md={11.5}>
                                            <Subscription
                                                view
                                                name={el.name}
                                                description={el.description}
                                                duration={el.duration}
                                                price={el.priceRon}
                                                Trainers={el.SubscriptionsTrainers.map((el) => el.Trainer)}
                                                expand={el.expand || false}
                                                handleExpand={handleExpand}
                                                id={el.id}
                                                currency={el.Company?.currency || el.Trainer.trainerCurrency}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                ) : (
                    <Typography color="#979797" fontWeight={500} fontSize={17}>
                        {t('helper.noResultsFound')}
                    </Typography>
                )}
            </Grid>
        </Grid>
    )
}
export default AvailableSubscriptions;