import React, { useEffect, useState, useCallback } from 'react'
import { Grid, Rating, Chip, Typography, Slider } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash.debounce'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import * as AUTH_REQUESTS from "../../../api/auth"

import {
  PRIMARY_COLOR,
  CHIP_BLUE_COLOR,
  LIGHT_GRAY_BORDER_COLOR,
  EQUIPMENTS_TYPES,
  SPORTTYPES_TYPES
} from '../../../utils/constants'


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const useStyles = makeStyles(() => ({
  popupIndicator: {
    color: PRIMARY_COLOR
  }
}))

const types = ['Clubs', 'Trainers', 'Events', 'Services']

const marks = [
  {
    value: 0,
    label: '0km'
  },
  {
    value: 100000,
    label: '100km'
  },
  {
    value: 200000,
    label: '200km'
  },
  {
    value: 300000,
    label: '300km'
  },
  {
    value: 400000,
    label: '400km'
  },
  {
    value: 500000,
    label: '500km'
  },
  {
    value: 600000,
    label: '600km'
  },
  {
    value: 700000,
    label: '700km'
  },
  {
    value: 800000,
    label: '800km'
  },
  {
    value: 900000,
    label: '900km'
  },
  {
    value: 1000000,
    label: 'Max'
  },
]


const marksMobile = [
  {
    value: 0,
    label: '0km'
  },
  {
    value: 200000,
    label: '200km'
  },
  {
    value: 400000,
    label: '400km'
  },
  {
    value: 600000,
    label: '600km'
  },
  {
    value: 800000,
    label: '800km'
  },
  {
    value: 1000000,
    label: 'Max'
  },
]

const reviews = [
  { value: 5, title: <Rating value={5} disabled sx={{ color: PRIMARY_COLOR, fontSize: 15 }} /> },
  { value: 4, title: <Rating value={4} disabled sx={{ color: PRIMARY_COLOR, fontSize: 15 }} /> },
  { value: 3, title: <Rating value={3} disabled sx={{ color: PRIMARY_COLOR, fontSize: 15 }} /> },
  { value: 2, title: <Rating value={2} disabled sx={{ color: PRIMARY_COLOR, fontSize: 15 }} /> },
  { value: 1, title: <Rating value={1} disabled sx={{ color: PRIMARY_COLOR, fontSize: 15 }} /> }
]

const Filter = ({
  onFiltersChange,
  onShowClubChange,
  onShowTrainerChange,
  onShowEventChange,
  onShowServiceChange,
  onDistanceChange
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const matchesPhone = useMediaQuery(theme.breakpoints.down('sm'))

  const [inputTypes, setInputTypes] = useState(types)
  const [inputReviews, setInputReviews] = useState([reviews[4]])
  const [sportTypes, setSportTypes] = useState([])
  const [inputFavorites, setInputFavorites] = useState([])
  const [changeFavoritesInput, setChangeFavoritesInput] = useState(false)
  const [equipments, setEquipments] = useState([])
  const [distance, setDistance] = useState(50000)

  const SPORT_TYPES_OPTIONS = Object.keys(SPORTTYPES_TYPES).map((key) => ({
    value: SPORTTYPES_TYPES[key],
    label: t(`sportTypesOptions.${SPORTTYPES_TYPES[key]}`)
  }))

  const EQUIPMENTS_OPTIONS = Object.keys(EQUIPMENTS_TYPES).map((key) => ({
    value: EQUIPMENTS_TYPES[key],
    label: t(`equipmentsOptions.${EQUIPMENTS_TYPES[key]}`)
  }))

  useEffect(() => {
    onShowClubChange(inputTypes.includes('Clubs'))
    onShowTrainerChange(inputTypes.includes('Trainers'))
    onShowEventChange(inputTypes.includes('Events'))
    onShowServiceChange(inputTypes.includes('Services'))
  }, [inputTypes])

  useEffect(() => {
      if (localStorage.getItem('favorites')) {
          const newFilters = {}

          newFilters.favorites = localStorage.getItem('favorites')
          if (localStorage.getItem('favorites') === "show_all") {
              setInputFavorites(t('favoritesOptions.SHOW_ALL'))
          } else {
              setInputFavorites(t('favoritesOptions.SHOW_FAVORITES'))
          }

          onFiltersChange(newFilters)
      } else {
          AUTH_REQUESTS.getMe().then(function (user){
              if (!changeFavoritesInput && user && user.associatedGyms.length > 0) {
                  setInputFavorites("Show Favorites")
                  const newFilters = {}
                  newFilters.favorites = "show_favorites"

                  onFiltersChange(newFilters)
              }
          })
      }
  }, [inputFavorites])

  const debouncedChangeHandler = useCallback(
    debounce((event) => {
      onDistanceChange(event.target.value)
    }, 300),
    [onDistanceChange]
  )

  return (
    <Grid container pb={3} borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
      <Grid item xs={12} pb={2} display="flex" flexDirection="column">
        <Typography display="flex" justifyContent="center" variant="p" fontWeight={500}>
          {t('explore.searchArea')}
        </Typography>
        <Grid container display="flex" justifyContent="center">
          <Grid item display="flex" xs={11} pl={{ xs: 2 }} pr={{ xs: 2 }}>
            <Slider
              onChange={(event) => {
                setDistance(event.target.value)
                debouncedChangeHandler(event)
              }}
              value={distance}
              getAriaValueText={() => ''}
              step={2000}
              min={0}
              max={1000000}
              marks={matchesPhone ? marksMobile : marks}
              size="medium"
              color="primary"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid p={2} pr={0} pl={0} container width="100%" display="flex" flexDirection="row">
          <Grid item lg={2.4} xs={12} pr={{ lg: 1, xs: 0 }} pl={{ xs: 0, lg: 0 }}>
            <Grid container>
              <Grid item pb={0.5} xs={12}>
                <Typography variant="p" fontWeight={500}>
                  {t('filterExplore.first')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  onChange={(_, newValue) => {
                    setInputTypes(newValue)
                  }}
                  classes={{
                    clearIndicatorDirty: classes.clearIndicator,
                    popupIndicator: classes.popupIndicator
                  }}
                  multiple
                  value={inputTypes}
                  id="checkboxes-tags-demo"
                  options={types}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} style={{ fontSize: 'small' }}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8, color: PRIMARY_COLOR }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={inputTypes.length ? '' : t('filterExplore.first')}
                    />
                  )}
                  renderTags={(value) => {
                    return (
                      <Grid marginLeft={1}>
                        <Chip
                          sx={{
                            fontSize: 13,
                            backgroundColor: CHIP_BLUE_COLOR,
                            border: `1px solid ${PRIMARY_COLOR}`
                          }}
                          color="primary"
                          variant="outlined"
                          label={`${value.length} ${t('explore.filters.selected')}`}
                        />
                      </Grid>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item mt={{ xs: 2, lg: 0 }} lg={2.4} xs={12} pr={{ lg: 1, xs: 0 }}>
            <Grid container>
              <Grid item xs={12} pb={0.5}>
                <Typography variant="p" fontWeight={500}>
                  {t('filterExplore.four')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  onChange={(event, newValue) => {
                    if (newValue) {
                      const prevValue = inputReviews[0]
                      const newReviewValue = prevValue
                        ? newValue.filter((el) => el.value !== prevValue.value)
                        : newValue
                      setInputReviews(newReviewValue)
                      onFiltersChange(
                        newReviewValue.length ? { reviewsFrom: newReviewValue[0].value } : {}
                      )
                    } else setInputReviews([])
                  }}
                  multiple
                  classes={{
                    clearIndicatorDirty: classes.clearIndicator,
                    popupIndicator: classes.popupIndicator
                  }}
                  id="checkboxes-tags-demo"
                  options={reviews}
                  value={inputReviews}
                  disableCloseOnSelect
                  isOptionEqualToValue={(option, value) => {
                    return option.value === value.value
                  }}
                  getOptionLabel={(option) => ''}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.value} style={{ fontSize: 'small' }}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8, color: PRIMARY_COLOR }}
                        checked={selected}
                      />
                      {option.title}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={inputReviews.length ? null : t('filterExplore.four')}
                    />
                  )}
                  renderTags={(value) => {
                    return (
                      <Grid marginLeft={1}>
                        <Chip
                          sx={{
                            fontSize: 13,
                            backgroundColor: CHIP_BLUE_COLOR,
                            border: `1px solid ${PRIMARY_COLOR}`
                          }}
                          color="primary"
                          variant="outlined"
                          label={`${t('filterExplore.from')} ${value[0].value} ${t('explore.filters.star')}`}
                        />
                      </Grid>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item mt={{ xs: 2, lg: 0 }} lg={2.4} xs={12} pr={{ lg: 1, xs: 0 }}>
            <Grid container>
              <Grid item xs={12} pb={0.5}>
                <Typography variant="p" fontWeight={500}>
                  {t('explore.filters.equipments')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  onChange={(event, newValue) => {
                    if (!newValue) return

                    setEquipments(newValue)
                    onFiltersChange({
                      equipments: newValue.map((el) => el.value)
                    })
                  }}
                  multiple
                  classes={{
                    clearIndicatorDirty: classes.clearIndicator,
                    popupIndicator: classes.popupIndicator
                  }}
                  id="checkboxes-tags-demo"
                  options={EQUIPMENTS_OPTIONS}
                  value={equipments}
                  disableCloseOnSelect
                  isOptionEqualToValue={(option, value) => {
                    return option.value === value.value
                  }}
                  getOptionLabel={(option) => ''}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.value} style={{ fontSize: 'small' }}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8, color: PRIMARY_COLOR }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={equipments.length ? null : t('explore.filters.selectEquipments')}
                    />
                  )}
                  renderTags={() => {
                    return (
                      <Grid marginLeft={1}>
                        <Chip
                          sx={{
                            fontSize: 13,
                            backgroundColor: CHIP_BLUE_COLOR,
                            border: `1px solid ${PRIMARY_COLOR}`
                          }}
                          color="primary"
                          variant="outlined"
                          label={`${equipments.length} ${t(
                            'explore.filters.equipments'
                          ).toLowerCase()}`}
                        />
                      </Grid>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item mt={{ xs: 2, lg: 0 }} lg={2.4} xs={12} pr={{ lg: 1, xs: 0 }}>
            <Grid container>
              <Grid item xs={12} pb={0.5}>
                <Typography variant="p" fontWeight={500}>
                  {t('explore.filters.sportTypes')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  onChange={(event, newValue) => {
                    if (!newValue) return

                    setSportTypes(newValue)
                    onFiltersChange({
                      sportTypes: newValue.map((el) => el.value)
                    })
                  }}
                  multiple
                  classes={{
                    clearIndicatorDirty: classes.clearIndicator,
                    popupIndicator: classes.popupIndicator
                  }}
                  id="checkboxes-tags-demo"
                  options={SPORT_TYPES_OPTIONS}
                  value={sportTypes}
                  disableCloseOnSelect
                  isOptionEqualToValue={(option, value) => {
                    return option.value === value.value
                  }}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.value} style={{ fontSize: 'small' }}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8, color: PRIMARY_COLOR }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={sportTypes.length ? null : t('explore.filters.selectSports')}
                    />
                  )}
                  renderTags={() => {
                    return (
                      <Grid marginLeft={1}>
                        <Chip
                          sx={{
                            fontSize: 13,
                            backgroundColor: CHIP_BLUE_COLOR,
                            border: `1px solid ${PRIMARY_COLOR}`
                          }}
                          color="primary"
                          variant="outlined"
                          label={`${sportTypes.length} ${t('explore.filters.sports')}`}
                        />
                      </Grid>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item mt={{ xs: 2, lg: 0 }} lg={2.4} xs={12} pr={{ lg: 1, xs: 0 }}>
            <Grid container>
              <Grid item xs={12} pb={0.5}>
                <Typography variant="p" fontWeight={500}>
                  {t('explore.filters.favorites')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                    onChange={(event, newValue) => {
                      setInputFavorites(newValue)
                      setChangeFavoritesInput(true)

                      const newFilters = {}

                      if (newValue && newValue.includes('Show All')) {
                        newFilters.favorites = "show_all"
                        localStorage.setItem('favorites', "show_all")
                      } else {
                        newFilters.favorites = "show_favorites"
                        localStorage.setItem('favorites', "show_favorites")
                      }

                      onFiltersChange(newFilters)
                    }}
                    classes={{
                      clearIndicatorDirty: classes.clearIndicator,
                      popupIndicator: classes.popupIndicator
                    }}
                    value={inputFavorites}
                    id="checkboxes-tags-demo"
                    options= {[t('favoritesOptions.SHOW_ALL'), t('favoritesOptions.SHOW_FAVORITES')]}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                        <li {...props} style={{ fontSize: 'small' }}>
                          <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8, color: PRIMARY_COLOR }}
                              checked={selected}
                          />
                          {option}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={inputFavorites && inputFavorites.length ? '' : t('filterExplore.favorites')}
                        />
                    )}
                    renderTags={(value) => {
                      return (
                          <Grid marginLeft={1}>
                            <Chip
                                sx={{
                                  fontSize: 13,
                                  backgroundColor: CHIP_BLUE_COLOR,
                                  border: `1px solid ${PRIMARY_COLOR}`
                                }}
                                color="primary"
                                variant="outlined"
                                label={`${value.length} ${t('explore.filters.favorites')}`}
                            />
                          </Grid>
                      )
                    }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Filter
