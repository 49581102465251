import { Divider, Grid } from "@mui/material"
import Sections from "./Sections"
import { useEffect, useState } from "react";
import { cards } from "../data";

const YourPlan = ({ selectValue, selectedIndices }) => {
    const [price, setPrice] = useState(0);
    const [title, setTitle] = useState('Below');
    const [vat, setVat] = useState(0);
    const [ads, setAds] = useState(0);
    useEffect(() => {
        const numericValue = parseFloat(selectValue.replace('K', '')) * 1000;
        if (numericValue <= 1000) {
            setPrice(0);
            setTitle('Below')
        } else if (numericValue > 1000 && numericValue <= 6000) {
            setPrice(numericValue * 0.01);
            setTitle('Grower')
        } else if (numericValue > 6000 && numericValue <= 15000) {
            setPrice(numericValue * 0.008);
            setTitle('Professional')
        } else if (numericValue > 15000 && numericValue <= 30000) {
            setPrice(numericValue * 0.007);
            setTitle('Expert')
        } else if (numericValue > 30000 && numericValue <= 60000) {
            setPrice(numericValue * 0.006);
            setTitle('Business')
        }
    }, [selectValue]);

    useEffect(() => {
        setAds(0)
        selectedIndices.map(el => {
            if (cards[el].price !== "Free") {
                setAds((prev) => prev + Number(cards[el].price))
            }
        });
    }, [selectedIndices]);

    useEffect(() => {
        setVat(price * 0.19);
    }, [price])
    return (
        <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
        >
            <Grid item xs={8}>
                <Sections title={'Your section'} subtitle={`Starter ${title} ${selectValue}`} content={price === 0 ? 'Free' : `${price} €`} colorContent={price === 0 ? '#00A83A' : '#00000'} />
                <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
                <Sections title={'Ads-ons'} multipleContent={selectedIndices.map((el) => {
                    return {
                        subtitle: cards[el].title,
                        content: cards[el].price !== 'Free' ? `${cards[el].price} €` : cards[el].price
                    }
                })} colorContent={'#000000'} />
            </Grid>
            <Grid item xs={4} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
                <Divider sx={{ marginBottom: '20px' }} />
                <Sections title={'Subtotal'} subtitle={'Vat'} content={`${price} €`} colorContent={'#000000'} colorTitle={'#000000'} secondContent={`${(vat).toFixed(2)} €`} colorSubtitle={'#00000'} />
                <Sections title={'Total'} content={`${(price + vat + ads).toFixed(2)} €`} boldSection={true} />
            </Grid>
        </Grid>
    )
}
export default YourPlan