import { Grid, Dialog, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'


const ConfirmNoSavingModal = ({ onClose, onConfirm }) => {

    const { t } = useTranslation()

    const handleCancelModal = () => {
        onClose()
    }

    const handleConfirm = () => {
        onConfirm()
    }

    return (
        <Dialog open={true} PaperProps={{ sx: { width: '100%' } }}>
            <Grid
                p={4}
                container
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
            >
                <Grid item sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography
                        p={2}
                        alignSelf="center"
                        flex={1}
                        id="alert-dialog-title"
                        fontWeight="bold"
                        variant="h4"
                        color="#292838"
                    >
                        {t('helper.confirmClose')}
                    </Typography>
                </Grid>
                <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography flex={1} variant="p" fontSize={18} pl={2.5}>
                        {t('helper.unsavedData')}
                    </Typography>
                </Grid>
                <Grid
                    paddingTop={8}
                    container
                    sx={{
                        flexDirection: {
                            xs: 'column-reverse',
                            md: 'row'
                        }
                    }}
                    spacing={2}
                >
                    <Grid xs={12} lg={6} item>
                        <Button onClick={handleCancelModal} fullWidth color="primary" variant="outlined">
                            {t('helper.no')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Button onClick={handleConfirm} fullWidth color="primary" variant="contained">
                            {t('helper.yes')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default ConfirmNoSavingModal
