import React from 'react'
import { Grid, Card, Typography, Button, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Instagram, Facebook, Done } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import Linkify from 'react-linkify'

import {
  GRAY_TEXT_COLOR,
  GREEN_COLOR,
  LIGHT_GRAY_BORDER_COLOR,
  ROLE_TRAINER,
  ROLE_BUSINESS_ADMINISTRATOR,
  BUSINESS_ROLES,
  PRIMARY_COLOR
} from '../../../../../utils/constants'
import { useAuthentication } from '../../../../../context/auth-context'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
    cursor: 'pointer'
  }
})

const Service = ({
  title,
  description,
  interestedLoading,
  isAvailable,
  interestedByLoggedUser,
  price,
  id,
  handleInterested,
  isFree,
  Trainer,
  Trainers,
  isInterested,
  ...restOfService
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { user } = useAuthentication()

  const isTrainer = user && user.role && user.role.toLowerCase() === ROLE_TRAINER.toLowerCase()
  const isBusinessAdministrator =
    user && user.role && user.role.toLowerCase() === ROLE_BUSINESS_ADMINISTRATOR.toLowerCase()
  const isClubAdministrator =
    user && user.role && user.role.toLowerCase() === BUSINESS_ROLES.BUSINESS_EMPLOYEE.toLowerCase()

  return (
    <Grid
      onClick={() => {
        if (!user) return
        navigate(`/services/${id}`)
      }}
      container
      component={Card}
      className={classes.content}
    >
      <Grid
        container
        disply="flex"
        flexDirection="row"
        borderBottom={{ xs: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`, md: 'none', lg: 'none' }}
      >
        <Grid
          borderBottom={{ xs: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }}
          p={{ xs: 3, md: 0, lg: 0 }}
          pl={{ xs: 3, md: 3, lg: 3 }}
          item
          xs={12}
          md={7}
          lg={7}
          display="flex"
          alignItems="center"
          flexWrap="noWrap"
        >
          <Typography variant="h6" fontWeight={500}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={4} md={2} lg={2}>
          <Grid
            container
            height="100%"
            borderBottom={{
              xs: 'none',
              md: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
              lg: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
            }}
            borderLeft={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
            borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            {!isFree && price ? (
              <Typography variant="body2" color={GRAY_TEXT_COLOR}>
                {t('gymProfile.service.from')}
              </Typography>
            ) : null}
            <Typography
              variant="h6"
              fontWeight="bold"
              color={isFree ? GREEN_COLOR : !price ? 'primary' : 'primary'}
            >
              {isFree ? t('calendar.free') : !price ? 'N/A' : price}
            </Typography>
            {!isFree && price ? (
              <Typography variant="body2" color={GRAY_TEXT_COLOR}>
                {restOfService.Company?.currency}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={8} md={3} lg={3}>
          <Grid
            borderBottom={{
              xs: 'none',
              lg: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
              md: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
            }}
            container
            display="flex"
            flexDirection="row"
            alignItems="center"
            pr={3}
            pt={2}
          >
            <Grid xs={12} item display="flex" flexDirection="column" pt={!isFree && price ? 0 : 1}>
              <Typography
                variant="subtitle2"
                display="flex"
                justifyContent="flex-end"
                color={GRAY_TEXT_COLOR}
              >
                {t('gymProfile.service.trainer').toUpperCase()}
              </Typography>
              {!Trainer && !Trainers.length ? <Box pb={2} display="flex" flexDirection="column" alignItems="flex-end">
                <Typography fontWeight={500} sx={{ textDecoration: 'underline', color: PRIMARY_COLOR }}>N / A</Typography>
              </Box> : null}
              {Trainer ? <Link
                style={{ textDecoration: 'none' }}
                to={
                  Trainer
                    ? '/' + Trainer.domain
                    : ''
                }
              >
                <Typography
                  display="flex"
                  justifyContent="flex-end"
                  sx={{
                    textDecoration: Trainer || Trainers.length ? 'underline' : 'none',
                    textAlign: 'right'
                  }}
                  pb={2}
                  fontWeight={600}
                  color="primary"
                >
                  {Trainer
                    ? `${Trainer.firstName} ${Trainer.lastName}`
                    : ''}
                </Typography>
              </Link> : null}
              {Trainers && Trainers.length > 0 ? <Box pb={2} display="flex" flexDirection="column" alignItems="flex-end">
                {Trainers.map(el => (
                  <Typography fontWeight={500} onClick={() => navigate(`/${el.Trainer.domain}`)} sx={{ textDecoration: 'underline', color: PRIMARY_COLOR }}>{el.Trainer.firstName} {el.Trainer.lastName}</Typography>
                ))}
              </Box> : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container display="flex" flexDirection="row">
        <Grid item xs={12} lg={7} md={7}>
          <Grid container p={2} display="flex" flexDirection="column">
            <Grid item xs={12}>
              <Typography variant="subtitle1" color={GRAY_TEXT_COLOR}>
                {t('gymProfile.service.description').toUpperCase()}
              </Typography>
            </Grid>

            <Grid item xs={12} flexWrap="noWrap">
              <Linkify>
                <Typography variant="p" fontWeight={500}>
                  {description}
                </Typography>
              </Linkify>
            </Grid>

            <Grid
              item
              xs={12}
              display="flex"
              flexDirection={{ lg: 'row', xs: 'column' }}
              alignItems={{ lg: 'flex-end', xs: 'flex-start' }}
              mt={{ xs: 1, lg: 0 }}
            >
              <Grid item xs={12} lg={6} display="flex" flexDirection="row" alignItems="center">
                <Typography variant="subtitle2" pr={1}>
                  {t('gymProfile.service.shareWith')}
                </Typography>
                <a href="https://instagram.com" rel="noreferrer" target="_blank">
                  <Instagram color="primary" />
                </a>
                <a href="https://facebook.com" rel="noreferrer" target="_blank">
                  <Facebook color="primary" />
                </a>
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
              >
                <Typography variant="subtitle1" color={isAvailable ? GRAY_TEXT_COLOR : 'error'}>
                  {isAvailable
                    ? t('gymProfile.service.available')
                    : t('gymProfile.service.unavailable')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          pb={{ xs: 2 }}
          item
          xs={12}
          lg={5}
          md={5}
          pt={2}
          borderLeft={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          {isInterested && (
            <Grid
              item
              width="80%"
              pb={1}
              xs={12}
              display="flex"
              justifyContent={isAvailable ? 'flex-end' : 'center'}
              alignItems="center"
              flexDirection="column"
            >
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  handleInterested(id, interestedByLoggedUser)
                }}
                fullWidth
                disabled={
                  !user ||
                  interestedLoading ||
                  isTrainer ||
                  isBusinessAdministrator ||
                  isClubAdministrator
                }
                variant="outlined"
              >
                {interestedLoading ? (
                  'Sending data ...'
                ) : interestedByLoggedUser ? (
                  <Done />
                ) : (
                  t('gymProfile.service.imIntrested')
                )}
              </Button>
            </Grid>
          )}
          {isAvailable && (
            <Grid
              item
              width="80%"
              pt={1}
              xs={12}
              display="flex"
              justifyContent={isInterested ? 'flex-start' : 'center'}
              alignItems="center"
              flexDirection="column"
            >
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  navigate(`/checkout?service=${id}`)
                }}
                disabled={!user || isTrainer || isBusinessAdministrator || isClubAdministrator || !restOfService.Subscriptions.length}
                fullWidth
                variant="contained"
              >
                {t('gymProfile.service.buy')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Service
