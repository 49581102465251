import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Grid, Typography, Button, ButtonGroup, Container, CircularProgress, Card } from '@mui/material'
import { ArrowLeft, Money, CreditCard, AccountBalance, Percent } from '@mui/icons-material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga'
import { toast } from 'react-toastify'

import * as PAYMENT_REQUESTS from '../../api/payments'
import * as SERVICE_REQUESTS from '../../api/services'
import * as SUBSCRIPTION_REQUESTS from '../../api/subscriptions'
import * as VOUCHER_REQUESTS from '../../api/voucher'
import * as TRANSACTION_INFORMATIONS_REQUESTS from '../../api/transactionInformations'
import * as AUTH_REQUESTS from '../../api/auth'
import * as COMPANY_REQUESTS from '../../api/company'
import { GRAY_TEXT_COLOR, PRIMARY_COLOR, VOUCHER_TYPES, DATE_FORMAT } from '../../utils/constants'

import ErrorCheckout from './subcomponents/Error'
import SuccessCheckout from './subcomponents/Success'

import { useAuthentication } from '../../context/auth-context'
import { useCheckoutVisualDetail, useCheckoutInputsError } from '../../hooks/useCheckout'
import PaymentInputs from './subcomponents/PaymentInputs'
import BillingInputs from './subcomponents/BillingInputs'
import CheckoutService from './subcomponents/Service'
import CheckoutServiceWithSubscriptions from './subcomponents/ServiceWithSubscriptions'
import CheckoutSubscription from './subcomponents/Subscription'
import DatePicketStartDate from './subcomponents/DatePicketStartDate'
import ConditionsAndSubmit from './subcomponents/ConditionsAndSubmit'
import PriceComputation from './subcomponents/PriceComputation'
import RecurringPaymentModal from './subcomponents/RecurringPaymentModal'
import PayRevolutModal from './subcomponents/PayRevolut'
import StripeCheckoutForm from './subcomponents/StripeCheckout'

const Checkout = () => {
  const { t } = useTranslation()

  const PAYMENT_INPUTS = [
    {
      name: 'cardName',
      placeholder: t('checkout.nameOnCard'),
      label: t('checkout.nameOnCard'),
      value: '',
      type: 'text',
      level: 1,
      mandatory: true
    },
    {
      name: 'cardNumber',
      placeholder: t('checkout.cardNumber'),
      label: t('checkout.cardNumber'),
      value: '',
      type: 'text',
      level: 1,
      mandatory: true
    },
    {
      name: 'cardExpireDate',
      placeholder: t('checkout.expireDate'),
      label: t('checkout.expireDate'),
      value: '',
      type: 'date',
      level: 2,
      mandatory: true
    },
    {
      name: 'cardCVV',
      placeholder: 'e.g 123',
      label: t('checkout.cvv'),
      value: '',
      type: 'text',
      level: 2,
      mandatory: true
    }
  ]

  const BILLING_INPUTS = [
    {
      name: 'billingFullName',
      placeholder: t('checkout.fullName'),
      label: t('checkout.fullName'),
      value: '',
      type: 'text',
      level: 1,
      mandatory: true
    },
    {
      name: 'billingPhoneNumber',
      placeholder: t('checkout.phoneNumber'),
      label: t('checkout.phoneNumber'),
      value: '',
      type: 'phone',
      level: 1,
      mandatory: true
    },
    {
      name: 'billingAddress',
      label: t('checkout.address'),
      placeholder: t('checkout.address'),
      value: '',
      defaultValue: '',
      type: 'string',
      level: 2,
      mandatory: true
    },
    {
      name: 'billingAddressPostalCode',
      label: t('checkout.postalCode'),
      placeholder: t('checkout.postalCode'),
      value: '',
      defaultValue: '',
      type: 'string',
      level: 3,
      mandatory: true
    },
    {
      name: 'billingAddressCity',
      label: t('checkout.city'),
      placeholder: t('checkout.city'),
      value: '',
      defaultValue: '',
      type: 'string',
      level: 3,
      mandatory: true
    }
  ]

  const COMPANY_INPUTS = [
    {
      name: 'billingCompanyName',
      placeholder: t('checkout.companyName'),
      label: t('checkout.companyName'),
      value: '',
      type: 'text',
      level: 1,
      mandatory: true
    },
    {
      name: 'billingCompanyCUI',
      placeholder: t('checkout.companyCUI'),
      label: t('checkout.companyCUI'),
      value: '',
      type: 'text',
      level: 2,
      mandatory: true
    },
    {
      name: 'billingCompanyTradeRegisterNumber',
      label: t('checkout.tradeRegisterNumber'),
      placeholder: 'Ex. J22/2542/2023',
      value: '',
      type: 'text',
      level: 2,
      mandatory: true
    },
    {
      name: 'billingCompanyBankName',
      label: t('checkout.companyBankName'),
      placeholder: t('checkout.companyBankName'),
      value: '',
      type: 'text',
      level: 3
    },
    {
      name: 'billingCompanyBankAccount',
      label: t('checkout.companyBankAccount'),
      placeholder: t('checkout.companyBankAccount'),
      value: '',
      type: 'text',
      level: 4
    },
    {
      name: 'billingAddress',
      label: t('checkout.address'),
      placeholder: t('checkout.address'),
      value: '',
      defaultValue: '',
      type: 'string',
      level: 5,
      mandatory: true
    },
    {
      name: 'billingAddressPostalCode',
      label: t('checkout.postalCode'),
      placeholder: t('checkout.postalCode'),
      value: '',
      defaultValue: '',
      type: 'string',
      level: 6,
      mandatory: true
    },
    {
      name: 'billingAddressCity',
      label: t('checkout.city'),
      placeholder: t('checkout.city'),
      value: '',
      defaultValue: '',
      type: 'string',
      level: 6,
      mandatory: true
    }
  ]

  const navigate = useNavigate()
  const { user } = useAuthentication()
  const [searchParams] = useSearchParams()

  const [showPayRevolutModal, setShowRevolutModal] = useState()
  // const [paymentInformation, setPaymentInformation] = useState()
  const [isPaymentInformationLoading, setIsPaymentInformationLoading] = useState(true)
  const [paymentInputs, setPaymentInputs] = useState([])
  const [wrongPaymentInputs, setWrongPaymentInputs] = useState([])
  const [billingInputs, setBillingInputs] = useState(BILLING_INPUTS)
  const [wrongBillingInputs, setWrongBillingInputs] = useState([])
  const [companyInputs, setCompanyInputs] = useState([])
  const [wrongCompanyInputs, setWrongCompanyInputs] = useState([])
  const [ownResponsabilityAccepted, setOwnResponsabilityAccepted] = useState(false)
  const [selectedVoucher, setSelectedVoucher] = useState()
  const [vouchers, setVouchers] = useState([])
  const [userVouchers, setUserVouchers] = useState([])
  const [selectedUserVoucher, setSelectedUserVoucher] = useState()
  const [company, setCompany] = useState()
  const [acceptedCustomPrivacyPolicyLink, setAcceptedCustomPrivacyPolicyLink] = useState(false)

  const [paymentType, setPaymentType] = useState('cash')
  const [billingType, setBillingType] = useState('individual')

  const [service, setService] = useState()
  const [subscription, setSubscription] = useState()
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false)
  const [selectedSubscriptions, setSelectedSubscriptions] = useState([])
  const [expandSelectTrainer, setExpandSelectTrainer] = useState(false)
  const [selectedTrainer, setSelectedTrainer] = useState()
  const [startFrom, setStartFrom] = useState()
  const [openStartFrom, setOpenStartFrom] = useState(false)
  const [startAnchorEl, setStartAnchorEl] = useState()
  const [success, setSuccess] = useState(undefined)
  const [showRecurringPaymentModal, setShowRecurringPaymentModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [trainerCurrency, setTrainerCurrency] = useState('RON')

  // To do aici daca e voucher atunci aplica l la aceste calcule!
  const {
    computeDomain,
    computeEmail,
    computeName,
    computeOwnerName,
    computePhoneNumber,
    computeSubtotal,
    computePaymentDeadline,
    computeVAT
  } = useCheckoutVisualDetail(service, subscription, selectedSubscriptions)

  const OPERATIONAL_SERVICES_FEE = 1.99;

  const {
    computeBillingHelperText,
    computeCompanyHelperText,
    computeHelperText,
    filterBillingInputs,
    filterCompanyInputs,
    filterPaymentInputs
  } = useCheckoutInputsError(
    wrongPaymentInputs,
    wrongBillingInputs,
    wrongCompanyInputs,
    paymentInputs,
    billingInputs,
    companyInputs
  )

  useEffect(() => {
    setBillingInputs((prevValue) => {
      const prevValueCopy = [...prevValue]
      const nameIndex = prevValueCopy.findIndex((el) => el.name === 'billingFullName')
      const phoneIndex = prevValueCopy.findIndex((el) => el.name === 'billingPhoneNumber')
      if (nameIndex >= 0) {
        prevValueCopy[nameIndex].value = `${user.firstName} ${user.lastName}`
      }
      if (phoneIndex >= 0) {
        prevValueCopy[phoneIndex].value = user.phoneNumber
      }
      return prevValueCopy
    })
  }, [user])

  const handleGetSubscription = async (subscriptionId) => {
    try {
      const subscriptionResult = await SUBSCRIPTION_REQUESTS.findById(subscriptionId)
      setSelectedSubscriptions([subscriptionResult.id])
      if (
        subscriptionResult &&
        subscriptionResult.SubscriptionsTrainers &&
        subscriptionResult.SubscriptionsTrainers.length === 1
      ) {
        setSelectedTrainer(subscriptionResult.SubscriptionsTrainers[0].Trainer.id)
      }
      setSubscription(subscriptionResult)
    } catch (e) {
      console.error(e)
    }
  }

  const handleGetService = async (serviceId) => {
    try {
      const serviceResult = await SERVICE_REQUESTS.getServiceById(serviceId)
      setService(serviceResult)
      if (serviceResult && serviceResult.Subscriptions && serviceResult.Subscriptions.length) {
        const sub = serviceResult.Subscriptions[0].Subscription
        setSelectedSubscriptions([sub.id])
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    if (urlSearchParams.get('service')) {
      handleGetService(urlSearchParams.get('service'))
    } else if (urlSearchParams.get('subscription')) {
      handleGetSubscription(urlSearchParams.get('subscription'))
    }
  }, [location])

  useEffect(() => {
    setPaymentInputs(PAYMENT_INPUTS)
    setCompanyInputs(COMPANY_INPUTS)
  }, [])

  useEffect(() => {
    if (!subscription && !service) return

    if ((subscription && subscription.trainerId) || (service && service.trainerId)) {
      setTrainerCurrency((subscription || service).Trainer.trainerCurrency)
      setIsPaymentInformationLoading(false)
      return
    }

    let companyId = (subscription || service).companyId

    Promise.all([
      // TRANSACTION_INFORMATIONS_REQUESTS.getByCompanyId(companyId),
      AUTH_REQUESTS.getMe(),
      COMPANY_REQUESTS.getBusinessById(companyId)
    ]).then(([userMe, company]) => {
      // setPaymentInformation(data)
      setCompany(company)
      if (userMe && userMe.billingCity && userMe.billingAddress && userMe.billingPostalCode) {
        const newBillingInputs = [...billingInputs]

        let indexOfCity = newBillingInputs.findIndex(el => el.name === 'billingAddressCity')
        let indexOfPostalCode = newBillingInputs.findIndex(el => el.name === 'billingAddressPostalCode')
        let indexOfAddress = newBillingInputs.findIndex(el => el.name === 'billingAddress')

        if (indexOfCity > -1) {
          newBillingInputs[indexOfCity].value = userMe.billingCity
        }

        if (indexOfPostalCode > -1) {
          newBillingInputs[indexOfPostalCode].value = userMe.billingPostalCode
        }

        if (indexOfAddress > -1) {
          newBillingInputs[indexOfAddress].value = userMe.billingAddress
        }
      }

      if (selectedSubscriptions.length > 0) {
        // if (data && selectedSubscriptions.length > 0) {
        if (subscription && subscription.paymentMoney && company.stripeOnboardingCompleted) {
          setPaymentType('card')
        }
        else if (service) {
          const selectedSub = service.Subscriptions.find(el => el.Subscription.id === selectedSubscriptions[0])
          if (selectedSub && selectedSub.Subscription && selectedSub.Subscription.paymentMoney && company.stripeOnboardingCompleted) {
            setPaymentType('card')
          }
          else {
            setPaymentType('cash')
          }
        }
      }
    })
      .finally(() => setIsPaymentInformationLoading(false))
  }, [subscription, service, selectedSubscriptions])

  const handleOnChangePayment = (event) => {
    setWrongPaymentInputs((prevValues) => [
      ...prevValues.filter((el) => el.name !== event.target.name)
    ])
    setPaymentInputs((prevValues) => {
      const inputPos = prevValues.findIndex((f) => f.name === event.target.name)
      if (inputPos < 0) return [...prevValues]

      const prevValuesCopy = [...prevValues]
      prevValuesCopy[inputPos].value = event.target.value

      return prevValuesCopy
    })
  }

  const handleOnChangeBilling = (event) => {
    setWrongBillingInputs((prevValues) => [...prevValues.filter((el) => el !== event.target.name)])
    setBillingInputs((prevValues) => {
      const inputPos = prevValues.findIndex((f) => f.name === event.target.name)
      if (inputPos < 0) return [...prevValues]

      const prevValuesCopy = [...prevValues]
      prevValuesCopy[inputPos].value = event.target.value

      return prevValuesCopy
    })
  }

  const handleOnChangeBillingCompany = (event) => {
    setWrongCompanyInputs((prevValues) => [...prevValues.filter((el) => el !== event.target.name)])
    setCompanyInputs((prevValues) => {
      const inputPos = prevValues.findIndex((f) => f.name === event.target.name)
      if (inputPos < 0) return [...prevValues]

      const prevValuesCopy = [...prevValues]
      prevValuesCopy[inputPos].value = event.target.value

      return prevValuesCopy
    })
  }

  const handleOnChangePhone = (phone, name) => {
    setBillingInputs((prevValues) => {
      const inputPos = prevValues.findIndex((f) => f.name === name)
      if (inputPos < 0) return [...prevValues]

      const prevValuesCopy = [...prevValues]
      prevValuesCopy[inputPos].value = phone

      return prevValuesCopy
    })
  }

  const handleSelectSubscription = (subscriptionId) => {
    setSelectedVoucher(null)
    setSelectedSubscriptions(() => {
      let prevValueCopy = []

      prevValueCopy.push(subscriptionId)

      return prevValueCopy
    })
  }

  const handleOnChangeDate = (value, name) => {
    setPaymentInputs((prevValue) => {
      let prevValueCopy = [...prevValue]
      const nameIndex = prevValueCopy.findIndex((el) => el.name === name)
      if (nameIndex < 0) return
      prevValueCopy[nameIndex].value = value
      return prevValueCopy
    })
  }

  const handleCheckout = async (recurringPaymentChecked, recurringVoucher) => {
    setLoading(true)
    const bodyToUse = {}
    let error = false

    paymentInputs.forEach((inp) => {
      bodyToUse[inp.name] = inp.value
    })

    if (billingType === 'individual') {
      billingInputs.forEach((inp) => {
        if (!inp.value) {
          setWrongBillingInputs((prevValues) => prevValues.concat(inp.name))
          error = inp.name
        }
        if (inp.name === 'billingPhoneNumber') {
          if (inp.value && inp.value.substring(0, 2) === '40' && inp.value.length !== 11) {
            error = 'phoneLength'
          }
        }
        bodyToUse[inp.name] = inp.value
      })
    }
    else {
      billingInputs.forEach((inp) => {
        if (!inp.value) {
          setWrongBillingInputs((prevValues) => prevValues.concat(inp.name))
          error = inp.name
        }
        if (inp.name === 'billingPhoneNumber') {
          if (inp.value.substring(0, 2) === '40' && inp.value.length !== 11) {
            error = 'phoneLength'
          }
          else {
            bodyToUse[inp.name] = inp.value
          }
        }
      })
      companyInputs.forEach((inp) => {
        if (!inp.value && inp.mandatory && billingType !== 'individual') {
          setWrongCompanyInputs((prevValues) => prevValues.concat(inp.name))
          error = inp.name
        }
        bodyToUse[inp.name] = inp.value
      })
    }

    if (startFrom && moment(startFrom).startOf('day').valueOf() < moment().startOf('day').valueOf()) {
      toast.error(t('checkout.activationDate'), { position: 'bottom-right' })
      setLoading(false)
      return
    }
    bodyToUse['subscriptionIds'] = selectedSubscriptions
    bodyToUse['validFrom'] = startFrom ? startFrom : moment()
    bodyToUse['type'] = paymentType === 'cash' ? 'CASH' : paymentType === 'card' ? 'CARD' : 'BANK_TRANSFER'
    bodyToUse['currency'] = company?.currency || trainerCurrency

    if (selectedVoucher) {
      bodyToUse['voucherId'] = selectedVoucher.id
    }

    if (selectedUserVoucher) {
      bodyToUse['userVoucherId'] = selectedUserVoucher.id
    }

    if (recurringVoucher && recurringVoucher.recurringVoucherId) {
      bodyToUse['recurringVoucherId'] = recurringVoucher.recurringVoucherId
    }

    if (error) {
      toast.error(t(`checkout.${error}`), { position: 'bottom-right', autoClose: false })
      setLoading(false)
      return
    }
    if (paymentType === 'card') {
      // if (paymentType === 'card' && paymentInformation) {
      setShowRevolutModal({
        subscriptionIds: bodyToUse.subscriptionIds,
        postalCode: bodyToUse.billingAddressPostalCode,
        address: bodyToUse.billingAddress + ' ' + bodyToUse.billingAddressCity,
        ...(bodyToUse.voucherId ? { voucherId: bodyToUse.voucherId } : {}),
        ...(bodyToUse.userVoucherId ? { userVoucherId: bodyToUse.userVoucherId } : {}),
        startDate: bodyToUse.validFrom,

        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        locale: 'ro',
        billingAddress: {
          countryCode: 'RO',
          city: bodyToUse.billingAddressCity,
          postcode: bodyToUse.billingAddressPostalCode,
          streetLine1: bodyToUse.billingAddress
        }
      })

      return setLoading(false)
    }

    try {
      await PAYMENT_REQUESTS.checkout(bodyToUse)
      if (searchParams.get('fromEvent')) {
        toast.success("You have successfully bought the subscription!", { position: 'bottom-right' })
        navigate(`/calendar?eventId=${searchParams.get('fromEvent')}`)
      }
      else {
        setSuccess(true)
      }
      ReactGA.event({
        category: 'POST_LOGIN',
        action: 'CHECKOUT',
        label: 'SUCCESS'
      })
    } catch (e) {
      toast.error('Ups. Looks like something went wrong!', { position: 'bottom-right' })
      setSuccess(false)
      ReactGA.event({
        category: 'POST_LOGIN',
        action: 'CHECKOUT',
        label: 'ERROR'
      })
    }

    setLoading(false)
  }

  const renderPaymentInputs = useMemo(() => {
    return (
      <PaymentInputs
        paymentType={paymentType}
        paymentInputs={paymentInputs}
        wrongPaymentInputs={wrongPaymentInputs}
        computePhoneNumber={computePhoneNumber}
        computeEmail={computeEmail}
        computeHelperText={computeHelperText}
        computePaymentDeadline={computePaymentDeadline}
        filterPaymentInputs={filterPaymentInputs}
        handleOnChangeDate={handleOnChangeDate}
        handleOnChangePayment={handleOnChangePayment}
      />
    )
  }, [
    paymentType,
    paymentInputs,
    wrongPaymentInputs,
    computePaymentDeadline,
    computeHelperText,
    filterPaymentInputs,
    computePhoneNumber,
    handleOnChangePayment,
    handleOnChangeDate,
    computeEmail
  ])

  const renderPaymentSection = useMemo(() => {
    if (!service && !subscription && !company) return null
    let sub

    if (subscription) {
      sub = subscription
    } else if (service.Subscriptions && selectedSubscriptions.length) {
      sub = service.Subscriptions.map(el => el.Subscription).find(el => el.id === selectedSubscriptions[0])
    }

    if (!sub) return null

    const allowedPyamentTypes = {
      paymentCash: sub.paymentCash,
      paymentBankTransfer: sub.paymentBankTransfer,
      paymentMoney: sub.paymentMoney
    }

    return (
      <Grid item lg={12}>
        <Grid container display="flex" flexDirection="column">
          <Grid item lg={12}>
            <Typography variant="h6" fontWeight={600} color="primary">
              {t('checkout.selectPaymentType')}
            </Typography>
          </Grid>
          <Grid item lg={12} pt={1} maxHeight={{ lg: 80, xs: 100 }}>
            <ButtonGroup fullWidth variant="outlined" aria-label="outlined button group">
              {allowedPyamentTypes.paymentMoney && company?.stripeOnboardingCompleted ? <Button onClick={() => setPaymentType('card')} variant={paymentType === 'card' ? 'contained' : 'outlined'}><CreditCard sx={{ mr: 1 }} /> Card</Button> : null}
              {/* {paymentInformation && allowedPyamentTypes.paymentMoney ? <Button onClick={() => setPaymentType('card')} variant={paymentType === 'card' ? 'contained' : 'outlined'}><CreditCard sx={{ mr: 1 }} /> Card</Button> : null} */}
              {allowedPyamentTypes.paymentCash ? <Button
                onClick={() => setPaymentType('cash')}
                variant={paymentType === 'cash' ? 'contained' : 'outlined'}
              >
                <Money sx={{ mr: 1 }} />{t('checkout.cash')}
              </Button> : null}
              {allowedPyamentTypes.paymentBankTransfer ? <Button
                onClick={() => setPaymentType('transfer')}
                variant={paymentType === 'transfer' ? 'contained' : 'outlined'}
              >
                <AccountBalance sx={{ mr: 1 }} /> {t('checkout.bank')}
              </Button> : null}
            </ButtonGroup>
          </Grid>
          {renderPaymentInputs}
        </Grid>
      </Grid>
    )
  }, [paymentType, renderPaymentInputs, subscription, service, selectedSubscriptions, company])
// }, [paymentType, renderPaymentInputs, paymentInformation, subscription, service, selectedSubscriptions])

  const renderBillingInputs = useMemo(() => {
    return (
      <BillingInputs
        billingInputs={billingInputs}
        wrongBillingInputs={wrongBillingInputs}
        wrongCompanyInputs={wrongCompanyInputs}
        companyInputs={companyInputs}
        billingType={billingType}
        filterBillingInputs={filterBillingInputs}
        filterCompanyInputs={filterCompanyInputs}
        computeBillingHelperText={computeBillingHelperText}
        computeCompanyHelperText={computeCompanyHelperText}
        handleOnChangePhone={handleOnChangePhone}
        handleOnChangeBilling={handleOnChangeBilling}
        handleOnChangeBillingCompany={handleOnChangeBillingCompany}
      />
    )
  }, [
    billingInputs,
    wrongBillingInputs,
    wrongCompanyInputs,
    companyInputs,
    billingType,
    filterBillingInputs,
    filterCompanyInputs,
    handleOnChangeBilling,
    handleOnChangePhone,
    computeBillingHelperText,
    handleOnChangeBillingCompany,
    computeCompanyHelperText
  ])

  const renderBillingSection = useMemo(() => {
    return (
      <Grid item lg={12}>
        <Grid container display="flex" flexDirection="column">
          <Grid item lg={12}>
            <Typography variant="h6" fontWeight={600} color="primary">
              {t('checkout.billing')}
            </Typography>
          </Grid>
          <Grid item lg={12} pt={1}>
            <ButtonGroup fullWidth variant="outlined" aria-label="outlined button group">
              <Button
                onClick={() => setBillingType('individual')}
                variant={billingType === 'individual' ? 'contained' : 'outlined'}
              >
                {t('checkout.individual')}
              </Button>
              <Button
                onClick={() => setBillingType('company')}
                variant={billingType === 'company' ? 'contained' : 'outlined'}
              >
                {t('checkout.company')}
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item lg={12}>
            {renderBillingInputs}
          </Grid>
        </Grid>
      </Grid>
    )
  }, [billingType, renderBillingInputs])

  useEffect(() => {
    if ((!selectedSubscriptions.length && !subscription) || !user) return

    VOUCHER_REQUESTS.getAll({ subscriptionId: subscription ? subscription.id : selectedSubscriptions[0] })
      .then(data => {
        setVouchers(data)
      })

    VOUCHER_REQUESTS.getAllUsers({ userId: user.id, subscriptionId: subscription ? subscription.id : selectedSubscriptions[0] })
      .then(data => {
        setUserVouchers(data)
      })
  }, [selectedSubscriptions, user])

  const renderService = useMemo(() => {
    if (!service) return null
    if (!service.Subscriptions || !service.Subscriptions.length) {
      return <CheckoutService service={service} />
    } else if (service.Subscriptions) {
      return (
        <CheckoutServiceWithSubscriptions
          service={service}
          selectedSubscriptions={selectedSubscriptions}
          handleSelectSubscription={handleSelectSubscription}
          currency={company?.currency || trainerCurrency}
        />
      )
    }
  }, [service, selectedSubscriptions, handleSelectSubscription])

  const computeSelectedTrainerName = useCallback(() => {
    if (!selectedTrainer) return ''
    const trainerIndex = subscription.SubscriptionsTrainers.findIndex(
      (el) => el.Trainer.id === selectedTrainer
    )

    if (trainerIndex < 0) return ''

    return `${subscription.SubscriptionsTrainers[trainerIndex].Trainer.firstName} ${subscription.SubscriptionsTrainers[trainerIndex].Trainer.lastName}`
  }, [selectedTrainer, subscription])

  const renderShowRecurringPaymentModal = useMemo(() => {
    if (!showRecurringPaymentModal || !user || (!subscription && !service)) return null
    const recurringVoucher = vouchers.find(el => el.type === VOUCHER_TYPES.RECURRING)

    if (!recurringVoucher || !recurringVoucher.discount || !company) return null

    return <RecurringPaymentModal
      firstName={user.firstName}
      lastName={user.lastName}
      vouchers={vouchers}
      onClose={() => setShowRecurringPaymentModal(false)}
      onAcceptWithRecurring={(data) => handleCheckout(true, data)}
      onAcceptWithoutRecurring={() => handleCheckout(false, {})}
      company={company}
    />
  }, [showRecurringPaymentModal, user, subscription, service, vouchers, company])

  const renderSubscription = useMemo(() => {
    if (!subscription || !company) return null
    return (
      <CheckoutSubscription
        subscription={subscription}
        currency={company?.currency || trainerCurrency}
        expandSelectTrainer={expandSelectTrainer}
        selectedTrainer={selectedTrainer}
        handleSetSelectedTrainer={setSelectedTrainer}
        computeSelectedTrainerName={computeSelectedTrainerName}
        setExpandSelectTrainer={setExpandSelectTrainer}
      />
    )
  }, [
    subscription,
    expandSelectTrainer,
    setSelectedTrainer,
    setExpandSelectTrainer,
    selectedTrainer,
    computeSelectedTrainerName,
    company
  ])

  const renderVouchers = useMemo(() => {
    if (!vouchers.length && !userVouchers.length) return null

    const availableVouchers = vouchers.filter(el => el.type == VOUCHER_TYPES.DISCOUNT)

    return (
      <Grid container>
        {availableVouchers.length || userVouchers.length ? <Grid item xs={12}>
          <Typography variant="h6" fontWeight={600} color={PRIMARY_COLOR}>
            {t('checkout.vouchers.title')}
          </Typography>
        </Grid> : null}
        <Grid pt={1} item xs={12}>
          <Grid container>
            {availableVouchers.map(el => {
              const isSelected = selectedVoucher && selectedVoucher.id === el.id

              return <Grid item component={Card} mt={2}
                onClick={() => {
                  if (selectedVoucher && el.id === selectedVoucher.id) {
                    setSelectedVoucher(null)
                  } else {
                    setSelectedVoucher(el)
                  }

                  setSelectedUserVoucher()
                }}
                sx={{ borderRadius: 2, backgroundColor: isSelected ? PRIMARY_COLOR : 'white', border: isSelected ? `3px solid ${PRIMARY_COLOR}` : '1px solid rgba(0,0,0,0.1)', cursor: 'pointer' }} xs={12} p={1} pr={2} pt={el.expiresAt ? 1 : 2.5} pb={el.expiresAt ? 1 : 2.5} key={el.id}>
                <Grid container display="flex" flexDirection="row">
                  <Grid item xs={8} display="flex" alignItems="center" pl={1}>
                    <Typography variant="h6" fontWeight={isSelected ? 'bold' : 500} color={isSelected ? 'white' : PRIMARY_COLOR}>{el.name}</Typography>
                  </Grid>
                  <Grid item lg={4} display="flex" flexDirection="column" ml={{ xs: 'auto' }}>
                    <Typography
                      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}
                      fontWeight={isSelected ? 'bold' : 600}
                      variant="h6"
                      color={isSelected ? 'white' : PRIMARY_COLOR}
                    >{el.discount} {el.hasFixedDiscount ? company?.currency : <Percent />}</Typography>
                    {el.expiresAt ? <Typography
                      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}
                      fontWeight={isSelected ? 'bold' : 600}
                      variant="body2"
                      color={isSelected ? 'white' : GRAY_TEXT_COLOR}
                    >Valid Until {moment(el.expiresAt).format(DATE_FORMAT)}</Typography> : null}
                  </Grid>
                </Grid>
              </Grid>
            })}
            {userVouchers.map(el => {
              const isSelected = selectedUserVoucher && selectedUserVoucher.id === el.id

              return <Grid item component={Card} mt={2}
                onClick={() => {
                  if (selectedUserVoucher && el.id === selectedUserVoucher.id) {
                    setSelectedUserVoucher(null)
                  } else {
                    setSelectedUserVoucher(el)
                  }
                  setSelectedVoucher()
                }}
                sx={{ borderRadius: 2, backgroundColor: isSelected ? PRIMARY_COLOR : 'white', border: isSelected ? `3px solid ${PRIMARY_COLOR}` : '1px solid rgba(0,0,0,0.1)', cursor: 'pointer' }} xs={12} p={1} pr={2} pt={el.Voucher.expiresAt ? 1 : 3} pb={el.Voucher.expiresAt ? 1 : 3} key={el.id}>
                <Grid container display="flex" flexDirection="row">
                  <Grid item xs={8} display="flex" alignItems="center" pl={1}>
                    <Typography variant="h6" fontWeight={isSelected ? 'bold' : 500} color={isSelected ? 'white' : PRIMARY_COLOR}>{el.Voucher.name}</Typography>
                  </Grid>
                  <Grid item lg={4} display="flex" flexDirection="column" ml={{ xs: 'auto' }}>
                    <Typography
                      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}
                      fontWeight={600}
                      variant="h6"
                      color={isSelected ? 'white' : PRIMARY_COLOR}
                    >{el.Voucher.discount} {el.Voucher.hasFixedDiscount ? company?.currency : <Percent />}</Typography>
                    {el.Voucher.expiresAt ? <Typography
                      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}
                      fontWeight={600}
                      variant="body2"
                      color={isSelected ? 'white' : GRAY_TEXT_COLOR}
                    >Valid Until {moment(el.Voucher.expiresAt).format(DATE_FORMAT)}</Typography> : null}
                  </Grid>
                </Grid>
              </Grid>
            })}
          </Grid>
        </Grid>
      </Grid>
    )
  }, [selectedSubscriptions, selectedVoucher, vouchers, userVouchers, selectedUserVoucher, company])

  const renderItem = () => {
    if (service) return renderService
    else if (subscription) return renderSubscription

    return null
  }

  if (!service && !subscription) return null

  if (success)
    return (
      <SuccessCheckout
        computeEmail={computeEmail}
        computeName={computeName}
        computeOwnerName={computeOwnerName}
        computeDomain={computeDomain}
        computePhoneNumber={computePhoneNumber}
      />
    )

  if (!success && success !== undefined) {
    return (
      <ErrorCheckout
        computeEmail={computeEmail}
        computeName={computeName}
        computeOwnerName={computeOwnerName}
        computeDomain={computeDomain}
        computePhoneNumber={computePhoneNumber}
      />
    )
  }

  if (isPaymentInformationLoading) {
    return (
      <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Container>
    )
  }

  return (
    <>
      {/* {showPayRevolutModal && selectedSubscriptions.length ? <PayRevolutModal operationalServicesFee={OPERATIONAL_SERVICES_FEE} startFrom={startFrom} selectedSubscriptionId={selectedSubscriptions[0]} voucher={selectedVoucher ? selectedVoucher : selectedUserVoucher ? selectedUserVoucher.Voucher : null}
        onCancel={() => setShowRevolutModal()} company={company} data={showPayRevolutModal} /> : null} */}
      {showPayRevolutModal && selectedSubscriptions.length ? 
        <StripeCheckoutForm data={showPayRevolutModal} operationalServicesFee={OPERATIONAL_SERVICES_FEE} startFrom={startFrom} onCancel={() => setShowRevolutModal()} company={company} subscription={subscription}
        />
        : null
      }
      {renderShowRecurringPaymentModal}
      <Container maxWidth="xl">
        <Grid
          container
          display="flex"
          flexDirection="column"
          pt={6}
          pb={6}
        >
          <Grid item lg={10} pl={{ lg: 9, xs: 1 }}>
            <Button onClick={() => navigate(-1)} variant="outlined">
              <ArrowLeft sx={{ position: 'relative', left: -5 }} />
              {t('checkout.back')}
            </Button>
          </Grid>
          <Grid item lg={12} pt={4}>
            <Grid
              container
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h3" fontWeight={600}>
                {t('checkout.checkout')}
              </Typography>
              <Typography variant="h6" fontWeight={300} pt={2} textAlign="center">
                {t('checkout.pleaseFill')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={12} pt={6}>
            <Grid container display="flex" flexDirection="row" justifyContent="center">
              <Grid item lg={5.5} sm={8} pr={{ lg: 4, xs: 0 }}>
                <Grid container display="flex" flexDirection="column">
                  {renderPaymentSection}
                  {renderBillingSection}
                </Grid>
              </Grid>
              <Grid item lg={5.5} sm={8} pl={{ lg: 4, xs: 0 }}>
                <Grid container display="flex" flexDirection="column">
                  <Grid item lg={12} pb={2}>
                    {renderItem()}
                  </Grid>
                  <DatePicketStartDate
                    startFrom={startFrom}
                    openStartFrom={openStartFrom}
                    startAnchorEl={startAnchorEl}
                    setStartAnchorEl={setStartAnchorEl}
                    setOpenStartFrom={setOpenStartFrom}
                    setStartFrom={setStartFrom}
                  />
                  <Grid item lg={12} pb={2}>
                    {renderVouchers}
                  </Grid>
                  <PriceComputation
                      computeSubtotal={computeSubtotal}
                      computeVAT={computeVAT}
                      operationalServicesFee={OPERATIONAL_SERVICES_FEE}
                      currency={company?.currency || trainerCurrency}
                      selectedPaymentType={paymentType}
                  />
                  <ConditionsAndSubmit
                    company={company}
                    loading={loading}
                    acceptedCustomPrivacyPolicyLink={acceptedCustomPrivacyPolicyLink}
                    setAcceptedCustomPrivacyPolicyLink={setAcceptedCustomPrivacyPolicyLink}
                    // paymentInformation={paymentInformation}
                    setTermsAccepted={setTermsAccepted}
                    voucher={selectedVoucher ? selectedVoucher : selectedUserVoucher ? selectedUserVoucher.Voucher : null}
                    termsAccepted={termsAccepted}
                    disclaimerAccepted={disclaimerAccepted}
                    setDisclaimerAccepted={setDisclaimerAccepted}
                    ownResponsabilityAccepted={ownResponsabilityAccepted}
                    setOwnResponsabilityAccepted={setOwnResponsabilityAccepted}
                    handleCheckout={() => {
                      if (selectedSubscriptions.length === 1 && paymentType === 'card') {
                        // if (selectedSubscriptions.length === 1 && paymentType === 'card' && paymentInformation.recurringPaymentActive) {
                        const recurringVoucher = vouchers.find(el => el.type === VOUCHER_TYPES.RECURRING)

                        if (recurringVoucher && recurringVoucher.discount) {
                          return setShowRecurringPaymentModal(true)
                        }
                      }

                      return handleCheckout(false)
                    }}
                    startFrom={startFrom}
                    service={service}
                    paymentType={paymentType}
                    selectedSubscriptions={selectedSubscriptions}
                    computeSubtotal={computeSubtotal}
                    computeVAT={computeVAT}
                    operationalServicesFee={paymentType === 'card' ? OPERATIONAL_SERVICES_FEE : 0}
                    trainerCurrency={trainerCurrency}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Checkout
