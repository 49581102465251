import axios from './axios'
import queryString from 'query-string'

export const getUsers = (options = {}) => {
    return axios.get('/ecredits/usersBusinesses/users?' + queryString.stringify(options)).then((res) => res.data)
}

export const getRequests = (options = {}) => {
    return axios.get('/ecredits/usersBusinesses?' + queryString.stringify(options)).then((res) => res.data)
}

export const bulkCreate = (payloads) => {
    return axios.post('/ecredits/usersBusinesses', payloads).then((res) => res.data)
}