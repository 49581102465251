import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)

const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            grid: {
                display: false,
            },
        },
        y: {
            grid: {
                display: false,
            },
        },
    },
    plugins: {
        legend: {
            position: 'top',
            display: false
        },
        title: {
            display: false,
            text: '',
        },
    },
}

const horizontalOptions = {
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    scales: {
        x: {
            grid: {
                display: false,
            },
        },
        y: {
            grid: {
                display: false,
            },
        },
    },
    plugins: {
        legend: {
            position: 'right',
        },
        title: {
            display: false,
            text: ''
        },
    }
}

const VerticalBarChart = ({ data, maintainAspectRatio = false, horizontal, ...restOfProps }) => {
    return <Bar options={{ ...(horizontal ? horizontalOptions : options), maintainAspectRatio }} data={data}  {...restOfProps} />
}

export default VerticalBarChart
