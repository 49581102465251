import React from 'react'
import { Grid, Card, Typography, Button } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowRight, Delete, ContentCopy } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Linkify from 'react-linkify'

import { useAuthentication } from '../../../../../context/auth-context'
import {
  LIGHT_GRAY_BORDER_COLOR,
  CARD_TEXT_COLOR,
  GREEN_COLOR,
  ROLE_BUSINESS_ADMINISTRATOR,
  PRIMARY_COLOR
} from '../../../../../utils/constants'
import { toast } from 'react-toastify'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  }
})

const Subscription = ({
  name,
  description,
  handleOnDelete,
  duration,
  isFree,
  credits,
  priceRon,
  currency,
  expand,
  id,
  handleExpand,
  domain,
  Trainers,
  onEdit,
  isPartTime,
  startTime,
  endTime,
  createdByAdmin
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useAuthentication()

  if (!expand)
    return (
      <Grid
        container
        component={Card}
        className={classes.content}
        display="flex"
        flexDirection="row"
        alignItems="center"
        onClick={() => handleExpand(id)}
        pt={3}
        pb={3}
      >
        <Grid item xs={0.3} lg={0.3} pl={2} pt={1}>
          <Typography fontSize="large" color="primary">
            <KeyboardArrowRight />
          </Typography>
        </Grid>
        <Grid item xs={11} lg={9.2}>
          <Typography
            sx={{ cursor: 'pointer' }}
            pl={3}
            variant="h6"
            fontWeight={500}
            display="flex"
            flexDirection="row"
          >
            {name}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={2.5} pr={2} pl={{ lg: 2, xs: 3 }} display="flex" flexDirection="row" alignItems="center">
          <Typography color={isFree ? GREEN_COLOR : 'primary'} fontWeight="bold" variant="h6">
            {priceRon || 'FREE'} {!isFree ? currency : ''}
          </Typography>
          <ContentCopy sx={{ color: PRIMARY_COLOR, marginLeft: 'auto', marginRight: 4, cursor: 'pointer' }} onClick={(e) => {
            e.stopPropagation()
            navigator.clipboard.writeText(`${window.location.origin}/${domain}?active_option=2&subscriptionId=${id}`);
            toast.info("Successfully copied to clipboard", { position: 'bottom-right' })
          }} />
        </Grid>
      </Grid>
    )

  return (
    <Grid container component={Card} className={classes.content}>
      <Grid
        xs={12}
        item
        borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
        display="flex"
        flexDirection={{ lg: 'row', xs: 'column' }}
        width="100%"
      >
        <Grid
          item
          onClick={() => handleExpand(id)}
          lg={7}
          borderRight={{
            xs: 'none',
            lg: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
          }}
          borderBottom={{
            xs: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
            lg: 'none'
          }}
        >
          <Grid container>
            <Grid item xs={12} lg={9} display="flex" alignItems="center" flexWrap="noWrap">
              <KeyboardArrowDown color="primary" sx={{ paddingLeft: 2 }} />
              <Typography
                sx={{ cursor: 'pointer' }}
                p={3}
                variant="h6"
                fontWeight={500}
                display="flex"
                flexDirection="row"
              >
                {name}
              </Typography>
            </Grid>
            {isPartTime && (
              <Grid
                item
                xs={12}
                lg={3}
                pr={{ xs: 0, lg: 4 }}
                pl={{ xs: 3, lg: 0 }}
                display="flex"
                alignItems={{ xs: 'flex-start', lg: 'flex-end' }}
                borderTop={{ xs: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`, lg: 'none' }}
                flexDirection="column"
                flexWrap="noWrap"
              >
                <Typography pt={2} color={CARD_TEXT_COLOR}>
                  {t('userProfile.activity.trainerProgramComponent.access')}
                </Typography>
                <Typography pb={2} fontWeight={500} color="primary">
                  {startTime + '-' + endTime}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item lg={5}>
          <Grid container display="flex" alignItems={{ xs: 'flex-start', lg: 'flex-end' }}>
            <Grid xs={6} lg={6} item borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                pl={{ lg: 3, xs: 3 }}
                alignItems="flex-start"
              >
                <Typography pt={2} color={CARD_TEXT_COLOR}>
                  {t('userProfile.activity.trainerProgramComponent.duration')}
                </Typography>
                <Typography pb={2} fontWeight={500} color="primary">
                  {duration}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={6} lg={6} item>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                pr={{ lg: 3, xs: 3 }}
                alignItems="flex-end"
              >
                <Typography pt={2} color={CARD_TEXT_COLOR}>
                  {t('userProfile.activity.trainerProgramComponent.credits').toUpperCase()}
                </Typography>
                <Typography pb={2} fontWeight={500} color="primary">
                  {credits ? credits : 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item display="flex" flexDirection={{ xs: 'column', lg: 'row' }} width="100%">
        <Grid lg={7} item borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
          <Grid flexWrap="noWrap" p={3} display="flex" flexDirection="column">
            <Typography color={CARD_TEXT_COLOR}>
              {t('userProfile.activity.trainerProgramComponent.description')}
            </Typography>
            <Linkify>
              <Typography>{description}</Typography>
            </Linkify>
            <ContentCopy sx={{ color: PRIMARY_COLOR, marginTop: 2, cursor: 'pointer' }} onClick={() => {
              navigator.clipboard.writeText(`${window.location.origin}/${domain}?active_option=2&subscriptionId=${id}`);
              toast.info("Successfully copied to clipboard", { position: 'bottom-right' })
            }} />
          </Grid>
        </Grid>
        <Grid item lg={5} pt={1} pl={3} pr={3}>
          <Grid container display="flex" flexDirection="column">
            <Grid container width="100%" display="flex" flexDirection="row">
              <Grid item xs={6} display="flex" justifyContent="flex-start" flexDirection="column">
                <Typography color={CARD_TEXT_COLOR}>
                  {t('userProfile.activity.trainerProgramComponent.price')}
                </Typography>
                <Typography pb={2} fontWeight={500} color={isFree ? GREEN_COLOR : 'primary'}>
                  {(isFree ? 'FREE' : priceRon ? priceRon : 'N/A') +
                    ' ' +
                    (isFree ? '' : currency)}
                </Typography>
              </Grid>
              <Grid item xs={6} display="flex" flexDirection="column">
                <Typography display="flex" justifyContent="flex-end" color={CARD_TEXT_COLOR}>
                  {t('userProfile.activity.trainerProgramComponent.trainer').toUpperCase()}
                </Typography>
                {Trainers && Trainers.length
                  ? Trainers.map(({ firstName, lastName, domain }, index) => {
                    return (
                      <Link key={index} style={{ textDecoration: 'none' }} to={'/' + domain}>
                        <Typography
                          display="flex"
                          justifyContent="flex-end"
                          sx={{ textDecoration: 'underline' }}
                          fontWeight={600}
                          color="primary"
                        >{`${firstName} ${lastName}`}</Typography>
                      </Link>
                    )
                  })
                  : null}
                {!Trainers || (Trainers && !Trainers.length) ? (
                  <Typography
                    display="flex"
                    justifyContent="flex-end"
                    pb={2}
                    fontWeight={600}
                    color="primary"
                  >
                    N / A
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
            <Grid
              pt={{ xs: 2, lg: 2 }}
              pb={{ xs: 2, lg: 2 }}
              item
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                sx={{ minWidth: 120 }}
                variant="outlined"
                disabled={createdByAdmin && !(user && user.role === ROLE_BUSINESS_ADMINISTRATOR)}
                onClick={onEdit}
              >
                {t('button.edit')}
              </Button>
              <Button
                sx={{ minWidth: 70, marginLeft: 1 }}
                disabled={createdByAdmin && !(user && user.role === ROLE_BUSINESS_ADMINISTRATOR)}
                onClick={() => handleOnDelete(id)}
                variant="outlined"
                color="error"
              >
                <Delete />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Subscription
