import axios from './axios'

export const create = (payload) => {
  return axios.post(`/subscriptions`, payload).then((res) => res.data)
}

export const findById = (id) => {
  return axios.get(`/subscriptions/${id}`).then((res) => res.data)
}

export const update = (id, payload) => {
  return axios.patch(`/subscriptions/${id}`, payload).then((res) => res.data)
}
