import { Grid } from "@mui/material"
import ReactQuill from 'react-quill'

const RenderRTE = ({ longDescription, setLongDescription }) => {
    return (
        <Grid item xs={12}>
            <ReactQuill
                defaultValue={longDescription}
                onChange={(newContent) => {
                    setLongDescription(newContent)
                }}
            >
            </ReactQuill>
        </Grid>

    )
}
export default RenderRTE