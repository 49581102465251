import axios from './axios'

export const findAndCountAll = ({ companyId, from, limit, checkedOut, failed }) => {
  return axios
    .get(`/checkins?companyId=${companyId}&from=${from}&limit=${limit}&checkedOut=${checkedOut}&failed=${failed}`)
    .then((res) => res.data)
}

export const getActive = (companyId) => {
  return axios.get('/checkins/active/' + companyId)
    .then(res => res.data)
}

export const getById = id => {
  return axios
    .get(`/checkins/${id}`)
    .then((res) => res.data)
}

export const createTrainer = (payload) => {
  return axios.post(`/checkins/trainer`, { ...payload }).then((res) => res.data)
}

export const create = (payload) => {
  return axios.post(`/checkins`, { ...payload }).then((res) => res.data)
}

export const remove = (id) => {
  return axios.delete(`/checkins/${id}`).then((res) => res.data)
}

export const update = (id, payload) => {
  return axios.patch(`/checkins/${id}`, { ...payload }).then((res) => res.data)
}
