import { Close } from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Grid,
  Button
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PRIMARY_COLOR } from '../../../../../utils/constants'

const MessageCompanyDialog = ({ companyName, handleClose, handleSend }) => {
  const { t } = useTranslation()

  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth={'sm'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="p" fontWeight={500}>
          {t('title.sendAMessageTo')}&nbsp;
          <Typography
            variant="p"
            color="primary"
            fontWeight={600}
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            {companyName}
          </Typography>
        </Typography>
        <Close onClick={handleClose} sx={{ marginLeft: 'auto', cursor: 'pointer' }} />
      </DialogTitle>
      <DialogContent>
        <Grid container flexDirection="column" mt={2}>
          <Grid pb={1} item display="flex" flexDirection="row" alignItems="center">
            <Typography fontSize={14} fontWeight={500} variant="p">
              {t('input.label.title')}
            </Typography>
            <Typography variant="span" color={PRIMARY_COLOR}>
              *
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              value={title}
              placeholder={t('input.placeholder.title')}
              onChange={(e) => setTitle(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container flexDirection="column" mt={4}>
          <Grid pb={1} item display="flex" flexDirection="row" alignItems="center">
            <Typography fontSize={14} fontWeight={500} variant="p">
              {t('input.label.yourMessage')}
            </Typography>
            <Typography variant="span" color={PRIMARY_COLOR}>
              *
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              value={message}
              rows={8}
              multiline
              placeholder={t('input.placeholder.yourMessage')}
              onChange={(e) => setMessage(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container mt={4} display="flex" flexDirection="row" justifyContent="center">
          <Grid item xs={4}>
            <Button
              fullWidth
              disabled={!title || !message}
              variant="contained"
              onClick={() => handleSend(title, message)}
            >
              {t('button.send')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default MessageCompanyDialog
