import axios from './axios'
import queryString from 'query-string'

export const getSubscriptionIdsWithVouchers = (domain) => {
  return axios.get('/public/vouchers/' + domain)
    .then(res => res.data)
}

export const getAll = (filters = {}) => {
  return axios.get('/vouchers?' + queryString.stringify(filters)).then((res) => res.data)
}

export const bulkCreate = async (payloads) => {
  const req = await axios.post('/vouchers', payloads);
  const data = await req.data;
  
  return data;
}

export const bulkCreateUserVouchers = payloads => {
  return axios.post('/vouchers/users', payloads).then(res => res.data)
}

export const update = (id, payload) => {
  return axios.patch('/vouchers/' + id, payload).then(res => res.data)
}

export const remove = id => {
  return axios.delete('/vouchers/' + id).then(res => res.data)
}

export const removeUserVoucher = id => {
  return axios.delete('/vouchers/users/' + id).then(res => res.data)
}

export const getAllUsers = (filters = {}) => {
  return axios.get('/vouchers/users?' + queryString.stringify(filters)).then((res) => res.data)
}
