import React, { useEffect } from 'react'
import { Dialog, IconButton, Button, Typography, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Close } from '@mui/icons-material'
import { QrReader as QrCodeReader } from 'react-qr-reader'
import { useNavigate } from 'react-router-dom'

import WelcomeAudio from '../../assets/welcome.mp4'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
    scanner: {
        width: 300,
        height: 300
    }
}));

export default ({
    onCancel
}) => {
    const navigate = useNavigate()
    const classes = useStyles()
    const { t } = useTranslation()

    const errorHandler = (err) => {
        console.error(err)
    }

    const onResultHandler = result => {
        if (!result || !result.text) return

        const welcomeAudio = new Audio(WelcomeAudio)
        welcomeAudio.play()

        const { text } = result
        navigate(text)
        onCancel()
    }

    return (
        <Dialog open maxWidth="sm" fullWidth>
            <Grid container p={4}>
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item
                            xs={10}
                            md={10}
                            lg={10}
                            pr={{ xs: 0, md: 0, lg: 2 }}
                            pl={{ xs: 0, md: 0, lg: 2 }}>
                            <Typography variant="h4" fontWeight={"bold"} color={'black'}>
                                {t('helper.scanQr')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                            <IconButton onClick={onCancel}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} display="flex" pt={5} style={{ height: 200 }} justifyContent="center" alignItems="center">
                    <QrCodeReader
                        delay={1000}
                        constraints={{ facingMode: 'environment' }}
                        onError={errorHandler}
                        onResult={onResultHandler}
                        onScan={() => { }}
                        style={{ width: "300px" }}
                        className={classes.scanner}
                    />
                </Grid>
                <Grid item xs={12} display="flex" pt={5} justifyContent="center" alignItems="center">
                    <Button variant="contained" sx={{ backgroundColor: 'black' }} onClick={onCancel} fullWidth>Cancel</Button>
                </Grid>
            </Grid>
        </Dialog>
    )
}