import axios from './axios'
import queryString from 'query-string'

export const generateInvoice = userSubscriptionId => {
  return axios.patch(`/payments/generate-invoice/${userSubscriptionId}`, {})
    .then(response => response.data)
}

export const checkout = (payload) => {
  return axios.post(`/payments`, payload).then((res) => res.data)
}

export const getPayments = (companyId, filters = {}) => {
  return axios.get(`/transactionInformations/${companyId}/orders?${queryString.stringify(filters)}`).then(res => res.data)
}

export const getPaymentsUser = (filters = {}) => {
  return axios.get(`/transactionInformations/orders?${queryString.stringify(filters)}`).then(res => res.data)
}