import React, { useEffect, useState } from 'react'
import {
  Grid,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  Slide
} from '@mui/material'
import { Search } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import Subscription from './subcomponents/Subscription'
import SubscriptionModal from './subcomponents/SubscriptionModal'
import DeleteSubscriptionDialog from './subcomponents/DeleteSubscriptionDialog'

const Subscriptions = ({
  Subscriptions,
  handleOnDelete,
  handleSearch,
  handleFilters,
  getSubscriptions,
  companyId,
  domain,
  currency,
  filters
}) => {
  const { t } = useTranslation()

  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
  const [selectedSubscription, setSelectedSubscription] = useState()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })

  const handleDelete = (id) => {
    setOpenDeleteDialog(true)
    setSelectedSubscription(id)
  }

  return (
    <>
      {showSubscriptionModal && (
        <SubscriptionModal
          getSubscriptions={getSubscriptions}
          subscriptionId={selectedSubscription}
          companyId={companyId}
          currency={currency}
          handleClose={() => {
            setSelectedSubscription()
            setShowSubscriptionModal(false)
          }}
          Transition={Transition}
        />
      )}
      {openDeleteDialog ? (
        <DeleteSubscriptionDialog
          handleDelete={() => {
            handleOnDelete(selectedSubscription)
            setOpenDeleteDialog(false)
            setSelectedSubscription(null)
          }}
          handleClose={() => {
            setOpenDeleteDialog(false)
            setSelectedSubscription(null)
          }}
        />
      ) : null}
      <Grid container display="flex" p={3} pt={4}>
        <Grid container pb={2} display="flex" flexDirection="row">
          <Grid item xs={12} lg={10}>
            <Grid container display="flex" flexDirection={{ lg: 'row', xs: 'column' }}>
              <Grid lg={6} xs={12} pr={{ lg: 2, xs: 0 }} item>
                <TextField
                  onChange={handleSearch}
                  fullWidth
                  placeholder="Search for services"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <Search style={{ color: '#C6C6C6' }} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                ></TextField>
              </Grid>
              <Grid item lg={6} xs={12} pt={{ lg: 0, xs: 1 }}>
                <Grid
                  container
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent={{ lg: 'center', xs: 'flex-start' }}
                >
                  <Grid item xs={6} pr={{ lg: 2, xs: 2 }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filters.visibility !== undefined ? filters.visibility : 'all'}
                      onChange={(e) => handleFilters('visibility', e.target.value, 'subscription')}
                      fullWidth
                    >
                      <MenuItem value={'all'}>{t('helper.all')}</MenuItem>
                      <MenuItem value={'PUBLIC'}>{t('helper.public')}</MenuItem>
                      <MenuItem value={'PRIVATE'}>{t('helper.private')}</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={6} pr={{ lg: 2, xs: 0 }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filters.public !== undefined ? filters.public : 'all'}
                      onChange={(e) => handleFilters('public', e.target.value, 'subscription')}
                      fullWidth
                    >
                      <MenuItem value={'all'}>{t('helper.all')}</MenuItem>
                      <MenuItem value={true}>{t('helper.available')}</MenuItem>
                      <MenuItem value={false}>{t('helper.unavailable')}</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid lg={2} xs={12} display="flex" justifyContent="flex-end" item pt={{ lg: 0, xs: 1 }}>
            <Button
              fullWidth
              variant="contained"
              color="success"
              onClick={() => {
                setSelectedSubscription()
                setShowSubscriptionModal(true)
              }}
            >
              {' '}
              {t('userProfile.addProgram')}
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          {Subscriptions.length > 0 ? (
            Subscriptions.map((subscription, index) => {
              return (
                <Grid key={index} xs={12} item pt={2} pb={2}>
                  <Subscription
                    {...subscription}
                    onEdit={() => {
                      setSelectedSubscription(subscription.id)
                      setShowSubscriptionModal(true)
                    }}
                    isPartTime={subscription.isPartTime}
                    startTime={subscription.startTime}
                    endTime={subscription.endTime}
                    Trainers={subscription.SubscriptionsTrainers.map((el) => el.Trainer)}
                    name={`${subscription.name}`}
                    description={subscription.description}
                    priceRon={subscription.priceRon}
                    currency={currency}
                    expand={subscription.expand || false}
                    handleExpand={subscription.handleExpand}
                    credits={subscription.inHouseCredits}
                    handleOnDelete={handleDelete}
                    domain={domain}
                    isFree={subscription.priceRon == 0 ? true : false}
                  />
                </Grid>
              )
            })
          ) : (
            <Typography pl={1} color="#979797" fontWeight={500} fontSize={17}>
              {t('helper.noResultsFound')}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default Subscriptions
