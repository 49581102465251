import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, Button, Typography, Grid } from '@mui/material'
import { ReportProblem } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { GRAY_TEXT_COLOR } from '../../utils/constants'

const FollowModal = ({ onCancel, onConfirm }) => {
  const { t } = useTranslation()

  return (
    <Dialog open maxWidth="md" fullWidth>
      <Grid container p={4}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <img lazy src={require('../../assets/subscribe.gif')} height={200} width={300} />
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="Center"
          pr={{ xs: 0, md: 0 }}
          pl={{ xs: 0, md: 0 }}
        >
          <Typography variant="h5" fontWeight={'bold'}>
            {t('followModal.header')}
          </Typography>
        </Grid>
        <Grid item pt={2} xs={12} display="flex" justifyContent="center">
          <Typography variant="h6" justifyContent="center" display="flex">
            {t('followModal.title')}
          </Typography>
        </Grid>
        <Grid item pt={4} pb={2} xs={12} display="flex" justifyContent="center" alignItems="center">
          <ReportProblem fontSize="small" color="warning" />{' '}
          <Typography variant="h6" color={GRAY_TEXT_COLOR}>
            {t('helper.youCanFollow')}
          </Typography>
        </Grid>
        <Grid item xs={12} pt={3}>
          <Grid container display="flex" flexDirection="row" spacing={3}>
            <Grid item xs={12} md={12} lg={6}>
              <Button style={{ minHeight: 45 }} onClick={onCancel} fullWidth variant="outlined">
                {t('button.cancel')}
              </Button>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Button
                style={{ minHeight: 45 }}
                onClick={() => {
                  onConfirm()
                  onCancel()
                }}
                fullWidth
                color={'primary'}
                variant="contained"
              >
                {t('button.follow')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}

FollowModal.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
}

export default FollowModal
