import React, { useMemo, useState } from 'react'
import { Grid, Typography, Button, Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'

import BG_IMAGE from '../../../assets/login.png'
import RO_FLAG from '../../../assets/ro-flag.png'
import UK_FLAG from '../../../assets/uk-flag.jpeg'

import {
  BLACK,
  CARD_TEXT_COLOR,
  LANGUAGE_RO,
  SECONDARY_TEXT_COLOR,
  WHITE_COLOR
} from '../../../utils/constants'

import { SendingButton as SendingButtonChild } from '../../common'
import { useSearchParams } from 'react-router-dom'

const Welcome = ({ handleLogin, handleDashboard, sending, registerCompany }) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const [language, setLanguage] = useState(LANGUAGE_RO)

  const handleChangeLanguage = (newLanguage) => setLanguage(newLanguage)

  const renderWelcome = useMemo(() => {
    return (
      <Grid item xs={12} lg={6} p={{ xs: 4, lg: 10 }} pr={{ xs: 2 }}>
        <Grid container height="100%" alignItems="center">
          <Grid container direction="column" rowSpacing={4}>
            <Grid item>
              <Typography fontWeight="bold" variant="h4" style={{ color: BLACK }}>
                {t('title.welcome')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography fontWeight={500} color={SECONDARY_TEXT_COLOR} variant="p">
                {t('helper.registerSuccess')}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container flexDirection="column">
                <Grid pb={2} item>
                  <Typography variant="p">{t('helper.interfaceLanguage')}</Typography>
                </Grid>
                <Grid pb={1} item>
                  <Grid container flexDirection="row">
                    <Grid xs={5} pr={2} item>
                      <Card
                        onClick={() => handleChangeLanguage('RO')}
                        sx={{
                          borderRadius: 2,
                          cursor: 'pointer',
                          border: `${language.toLowerCase() === LANGUAGE_RO.toLowerCase()
                            ? `1px solid ${BLACK}`
                            : `1px solid ${WHITE_COLOR}`
                            }`
                        }}
                      >
                        <CardContent>
                          <Grid
                            container
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid item>
                              <img
                                style={{ objectFit: 'cover' }}
                                src={RO_FLAG}
                                alt="ro flag"
                                height={40}
                                width={40}
                              />
                            </Grid>
                            <Grid item>
                              <Typography
                                color={`${language.toLowerCase() === LANGUAGE_RO.toLowerCase()
                                  ? BLACK
                                  : ''
                                  }`}
                                variant="p"
                                fontWeight="bold"
                              >
                                Romana
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid xs={5} pl={2} pr={2} item>
                      <Card
                        onClick={() => handleChangeLanguage('ENG')}
                        sx={{
                          borderRadius: 2,
                          cursor: 'pointer',
                          border: `${language.toLowerCase() === 'eng'
                            ? `1px solid ${BLACK}`
                            : `1px solid ${WHITE_COLOR}`
                            }`
                        }}
                      >
                        <CardContent>
                          <Grid
                            container
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid item>
                              <img
                                style={{ objectFit: 'cover' }}
                                src={UK_FLAG}
                                alt="ro flag"
                                height={40}
                                width={40}
                              />
                            </Grid>
                            <Grid item>
                              <Typography
                                color={`${language.toLowerCase() === 'eng'
                                  ? BLACK
                                  : ''
                                  }`}
                                variant="p"
                                fontWeight="bold"
                              >
                                English
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography color={CARD_TEXT_COLOR} variant="subtitle2">
                    {t('helper.changeAnytime')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item display="flex" flexDirection="row">
              <Grid container display="flex" flexDirection="row">
                <Grid item xs={12} lg={10} pr={1.5}>
                  <Button
                    fullWidth
                    onClick={() => registerCompany ? handleDashboard(language, registerCompany) : handleDashboard(language)}
                    style={{ backgroundColor: BLACK, color: '#fff' }}
                    variant="contained"
                    disabled={sending}
                  >
                    {!sending ? searchParams.get('serviceId') ? 'Go to service' : t('button.goToDashboard') : <SendingButtonChild />}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }, [language, sending, handleDashboard, handleLogin, handleChangeLanguage])

  const renderImage = useMemo(() => {
    return (
      <Grid
        item
        alignItems="center"
        display={{ xs: 'none', sm: 'none', lg: 'block' }}
        lg={6}
        maxHeight={'98vh'}
      >
        <img
          style={{ position: 'relative', left: '3%', top: '-30%', objectFit: 'cover' }}
          height={'150%'}
          src={BG_IMAGE}
          width={1200}
        />
      </Grid>
    )
  }, [])

  return (
    <Grid container style={{ padding: 0, overflowX: 'hidden' }} height="100%">
      {renderWelcome}
      {renderImage}
    </Grid>
  )
}

export default Welcome
