import React from 'react'
import { Container, Grid, Typography } from '@mui/material'

import { ECreditsNavbar } from '../../common'
import { UserTable } from '../common'

import { PRIMARY_COLOR, GREEN_COLOR, ORANGE_COLOR, GRAY_TEXT_COLOR } from '../../../utils/constants'

export default () => {
    return (
        <>
            <ECreditsNavbar title="Angajati" />
            <Container maxWidth='xl'>
                <Grid container pt={5} spacing={2}>
                    <Grid item xs={12}>
                        <Typography fontWeight="bold" variant="h6">
                            Users
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <UserTable />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}