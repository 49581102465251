import React, { useEffect, useMemo, useState } from 'react'
import { Grid, Button, Typography, ImageList, ImageListItem, Container, Card, Box, Avatar } from '@mui/material'
import { Done, ArrowLeft } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import Linkify from 'react-linkify'
import { Facebook, Instagram } from '@mui/icons-material'
import moment from 'moment'

import { BASE_URL } from '../../../utils/config'
import { AvatarNoImage, Footer } from '../../common'
import TrainerDetails from '../../PublicTrainerProfile/subcomponents/TrainerDetails'
import BusinessDetails from '../../PublicBusinessProfile/subcomponents/BusinessDetails'

import {
  CARD_TEXT_COLOR,
  LIGHT_GRAY_BORDER_COLOR,
  ROLE_TRAINER,
  ROLE_BUSINESS_ADMINISTRATOR,
  BUSINESS_ROLES,
  PRIMARY_COLOR
} from '../../../utils/constants'
import {
  refetchPublicTrainerProfileObservable,
  refetchPublicBusinessProfileObservable
} from '../../../utils/observables'

import * as SERVICE_REQUESTS from '../../../api/services'
import * as INTERESTED_REQUESTS from '../../../api/interested'
import * as COMPANY_REQUESTS from '../../../api/company'
import * as TRAINER_REQUESTS from '../../../api/trainer'
import * as FOLLOWER_REQUESTS from '../../../api/follower'
import { useAuthentication } from '../../../context/auth-context'
import GalleryImageDialog from '../../common/GalleryImageDialog'

const ServiceDetails = () => {
  const refetchProfile = refetchPublicTrainerProfileObservable.useRefetchPublicTrainerProfile()
  const refetchProfileCompany =
    refetchPublicBusinessProfileObservable.useRefetchPublicBusinessProfile()

  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()
  const { user: loggedUser } = useAuthentication()

  const isAuthenticated = !!loggedUser

  const isTrainer =
    loggedUser && loggedUser.role && loggedUser.role.toLowerCase() === ROLE_TRAINER.toLowerCase()
  const isBusinessAdministrator =
    loggedUser &&
    loggedUser.role &&
    loggedUser.role.toLowerCase() === ROLE_BUSINESS_ADMINISTRATOR.toLowerCase()
  const isClubAdministrator =
    loggedUser &&
    loggedUser.role &&
    loggedUser.role.toLowerCase() === BUSINESS_ROLES.BUSINESS_EMPLOYEE.toLowerCase()

  const [service, setService] = useState()
  const [loadingInterested, setLoadingInterested] = useState(false)

  const [user, setUser] = useState()
  const [company, setCompany] = useState()
  const [isFollowing, setIsFollowing] = useState(false)
  const [galleryDialog, setGalleryDialog] = useState({
    open: false,
    image: null
  })

  const getCompany = (domain) => COMPANY_REQUESTS.getBusinessByDomain(domain).then(setCompany)

  const getTrainer = (domain) =>
    TRAINER_REQUESTS.getTrainerByDomain(domain).then((user) => {
      setUser({ ...user, createdAt: moment(new Date(user.createdAt)).format('MMMM, YY') })
      FOLLOWER_REQUESTS.isFollowingTrainer(domain)
        .then((res) => {
          setIsFollowing(!!res)
        })
        .catch(() => {
          setIsFollowing(false)
        })
    })

  useEffect(() => {
    if (!refetchProfile && !refetchProfileCompany) return
    if (service && service.Trainer) {
      getTrainer(service.Trainer.domain)
    } else if (service && service.Company) {
      getCompany(service.Company.domain)
      FOLLOWER_REQUESTS.isFollowingCompany(service.Company.domain)
        .then((res) => {
          setIsFollowing(!!res)
        })
        .catch(() => {
          setIsFollowing(false)
        })
    }
  }, [service, refetchProfile, refetchProfileCompany])

  const handleGetService = async (id) => {
    try {
      const serviceDetails = isAuthenticated ? (await SERVICE_REQUESTS.getServiceById(id)) : (await SERVICE_REQUESTS.getPublicServiceDetails(id))

      setService(serviceDetails)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    handleGetService(params.id)
  }, [params, isAuthenticated])

  const handleInterested = () => {
    if (service.interestedByLoggedUser) {
      INTERESTED_REQUESTS.removeInterestedInService(service.id).then(() =>
        handleGetService(params.id)
      )
    } else {
      setLoadingInterested(true)
      INTERESTED_REQUESTS.interestedInService(service.id)
        .then(() => {
          handleGetService(params.id)
          setLoadingInterested(false)
        })
        .catch(() => {
          setLoadingInterested(false)
        })
    }
  }

  const renderDetails = useMemo(() => {
    if (!service) return null
    return (
      <Grid container display="flex" flexDirection="column">
        <Grid item xs={12} mb={3}>
          <Typography variant="h5" fontWeight={600}>
            {service.title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="span" color={CARD_TEXT_COLOR}>
            {t('service.description')}
          </Typography>
          <Linkify>
            <Typography variant="body1" pt={0} mt={0}>
              {parse(service.longDescription)}
            </Typography>
          </Linkify>
        </Grid>
        {service && service.Trainers && service.Trainers.length > 0 ? (
          <Grid item xs={12}>
            <Typography variant="span" color={CARD_TEXT_COLOR}>
              {t('service.trainers').toUpperCase()}
            </Typography>
            <Box display="flex" flexDirection="row">
              {service.Trainers.map((item) => (
                <Box onClick={() => navigate(`/${item.Trainer.domain}`)} sx={{ cursor: 'pointer' }} display="flex" flexDirection="row" alignItems="center" pr={2} pt={1.5}>
                  {item.Trainer.avatarPhotoSr ? (
                    <Avatar
                      src={item.Trainer.avatarPhotoSr}
                      style={{ width: 50, height: 50 }}
                    />
                  ) : (
                    <AvatarNoImage
                      firstName={item.Trainer.firstName}
                      lastName={item.Trainer.lastName}
                      width={50}
                      height={50}
                      typographyVariant="subtitle1"
                    />
                  )}
                  <Typography fontWeight={500} pl={1} sx={{ textDecoration: 'underline', color: PRIMARY_COLOR }}>{item.Trainer.firstName} {item.Trainer.lastName}</Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        ) : null}
        {service && service.Documents && service.Documents.length > 0 ? (
          <Grid item xs={12} pt={2}>
            <Typography variant="span" color={CARD_TEXT_COLOR}>
              {t('service.photoGallery').toUpperCase()}
            </Typography>
            <ImageList sx={{ width: '100%' }} cols={6} rowHeight={90}>
              {service.Documents.map((item) => (
                <ImageListItem sx={{ width: 120, height: 120 }} key={item.src}>
                  <img
                    onClick={() => setGalleryDialog({ open: true, image: item.src })}
                    src={`${item.src}`}
                    srcSet={`${item.src}`}
                    alt={'img'}
                    style={{ objectFit: 'contain' }}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
        ) : null}
        <Grid pt={4} container display="flex" flexDirection="row" alignItems="center">
          <Grid item sx={{ position: 'relative', top: -2 }} pr={2}>
            <Typography color={CARD_TEXT_COLOR}>SHARE</Typography>
          </Grid>
          <a
            style={{ marginRight: 8 }}
            href="https://instagram.com"
            rel="noreferrer"
            target="_blank"
          >
            <Instagram color="primary" />
          </a>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${BASE_URL}/services/${service.id}`}
            rel="noreferrer"
            target="_blank"
          >
            <Facebook color="primary" />
          </a>
        </Grid>
      </Grid>
    )
  }, [service])

  const renderButtons = useMemo(() => {
    if (!service) return null
    return (
      <Grid
        container
        display="flex"
        flexDirection={{ lg: 'row', xs: 'column' }}
        alignItems={{ lg: 'center', xs: '' }}
        justifyContent={{ lg: 'center', xs: 'center' }}
        mt={5}
        height={{ lg: 75, xs: '100%' }}
      >
        <Grid item xs={12} lg={4} mr={{ lg: 2, xs: 0 }} mb={{ lg: 0, xs: 1 }}>
          <Button
            onClick={handleInterested}
            disabled={
              loadingInterested || isTrainer || isBusinessAdministrator || isClubAdministrator || !isAuthenticated || (!service.isInterested && !service.interestedByLoggedUser)
            }
            fullWidth
            variant="outlined"
            color="primary"
          >
            {loadingInterested ? (
              'Sending Data ...'
            ) : service.interestedByLoggedUser ? (
              <Done />
            ) : (
              t('gymProfile.service.imIntrested')
            )}
          </Button>
        </Grid>
        <Grid item xs={12} lg={4} ml={{ lg: 2, xs: 0 }} mt={{ lg: 0, xs: 1 }}>
          <Button
            disabled={isTrainer || isClubAdministrator || isBusinessAdministrator || !isAuthenticated || !service.Subscriptions.length || !service.isActive}
            onClick={() => navigate(`/checkout?service=${params.id}`)}
            fullWidth
            variant="contained"
            color="primary"
          >
            {service.priceRon ? t('service.buyFor') : ''}{' '}
            {service.priceRon || t('service.buy')} {service.priceRon ? service?.Company?.currency || service.Trainer.trainerCurrency : ''}{' '}
          </Button>
        </Grid>
      </Grid>
    )
  }, [service, loadingInterested, isTrainer, isClubAdministrator, isBusinessAdministrator])

  const renderTrainerDetails = useMemo(() => {
    if (!user) return null
    return (
      <TrainerDetails
        user={user}
        isFollowing={isFollowing}
        handleGoToReview={() => { }}
        forServiceDetails={true}
      />
    )
  }, [user, isFollowing])

  const renderBusinessDetails = useMemo(() => {
    if (!company) return null
    return (
      <BusinessDetails
        company={company}
        onEditCloseHandler={() => { }}
        isFollowing={isFollowing}
        handleGoToReview={() => { }}
      />
    )
  }, [company, isFollowing])

  if (!service) return null

  return (
    <Container maxWidth="xl">
      {galleryDialog.open && galleryDialog.image ? (
        <GalleryImageDialog
          image={galleryDialog.image}
          onClose={() =>
            setGalleryDialog({
              open: false,
              image: null
            })
          }
        />
      ) : null}
      <Grid container pt={5} display="flex" flexDirection="row" width="100%">
        {service && service.Trainer ? (
          <Grid
            item
            lg={3}
            xs={12}
            md={12}
            sm={12}
            p={2}
            pt={0}
            pr={{ xs: 0, lg: 2 }}
            pl={{ xs: 0 }}
          >
            {renderTrainerDetails}
          </Grid>
        ) : null}
        {service && service.Company ? (
          <Grid
            item
            lg={3}
            xs={12}
            md={12}
            sm={12}
            p={2}
            pt={0}
            pr={{ xs: 0, lg: 2 }}
            pl={{ xs: 0 }}
          >
            {renderBusinessDetails}
          </Grid>
        ) : null}
        <Grid
          item
          lg={9}
          xs={12}
          p={{ xs: 2 }}
          md={12}
          sm={12}
          component={Card}
          sx={{
            borderRadius: 2,
            border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
            flexGrow: 1
          }}
        >
          <Grid
            sx={{ borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }}
            item
            pb={2}
            pl={{ lg: 3.5, sm: 1 }}
          >
            <Button onClick={() => navigate(-1)} variant="outlined">
              <ArrowLeft sx={{ position: 'relative', left: -5 }} />
              {t('service.back')}
            </Button>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="column"
            height="100%"
            p={{ xs: 1, lg: 4 }}
            pb={{ xs: 1, lg: 2 }}
            width={'100%'}
          >
            <Grid item>{renderDetails}</Grid>
            <Grid item>{renderButtons}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ServiceDetails
