import React, { useMemo } from 'react'
import { Grid, Container, Typography, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import LOGIN_BG from '../../../assets/login.png'

const useStyles = makeStyles({
  content: {
    paddingTop: '3rem'
  },
  item: {
    padding: '0px !important'
  },
  createAccountText: {
    cursor: 'pointer'
  }
})

const PasswordUpdated = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const renderPasswordUpdated = useMemo(() => {
    return (
      <Grid item xs={12} sm={12} lg={6} display="flex" flexDirection="column" alignItems="center">
        <Grid container height="100%" alignItems="center">
          <Container maxWidth="sm">
            <Grid className={classes.content} container direction="column" rowSpacing={5}>
              <Grid item>
                <Typography fontWeight="bold" variant="h4" color="primary">
                  {t('helper.passwordUpdated')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight={500} variant="h6">
                  {t('helper.passwordUpdateSuccessfully')}
                </Typography>
              </Grid>
              <Grid item display="flex" flexDirection="row">
                <Grid container display="flex" flexDirection="row">
                  <Grid
                    item
                    paddingRight={{ lg: 1, xs: 1 }}
                    xs={12}
                    lg={8}
                    onClick={() => navigate('/login')}
                  >
                    <Button fullWidth color="primary" variant="contained">
                      {t('button.backToLogin')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    )
  }, [t])

  const renderImage = useMemo(() => {
    return (
      <Grid
        item
        alignItems="center"
        display={{ xs: 'none', sm: 'none', lg: 'block' }}
        lg={6}
        maxHeight={'98vh'}
      >
        <img
          style={{ position: 'relative', left: '3%', top: '-30%', objectFit: 'cover' }}
          height={'150%'}
          src={LOGIN_BG}
          width={1200}
        />
      </Grid>
    )
  }, [])

  return (
    <Grid container height="100%" style={{ padding: 0, overflowX: 'hidden' }} spacing={2}>
      {renderPasswordUpdated}
      {renderImage}
    </Grid>
  )
}

export default PasswordUpdated
