import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { TableContainer, Table, Typography, TableHead, TableBody, Card, TableRow, TableCell, Paper, Checkbox, Box, IconButton, Grid, Tooltip, TextField, Button, Select, MenuItem } from '@mui/material'
import { Edit, ArrowBack, East } from '@mui/icons-material'
import ReactQuill from 'react-quill'
import jwt_decode from 'jwt-decode'
import 'react-quill/dist/quill.snow.css'
import * as SUPER_ADMIN_REQUESTS from '../../api/superAdmin'
import { InputGenerator } from '../common'
import { useAuthentication } from '../../context/auth-context'
import { GRAY_TEXT_COLOR } from '../../utils/constants'
import { useTranslation } from 'react-i18next'

const SuperAdminCompanies = ({ search }) => {
    const { handleLoggedIn, handleToken, handleUser } = useAuthentication()
    const { i18n } = useTranslation()

    const [companies, setCompanies] = useState([])
    const [editing, setEditing] = useState()
    const [message, setMessage] = useState('')

    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [company, setCompany] = useState()
    const [paymentData, setPaymentData] = useState({
        swiftCode: '',
        bankCountry: 'RO',
        city: '',
    })

    const onRevolutMerchantAccountSaveHandler = () => {
        return SUPER_ADMIN_REQUESTS.updateCompany(company.id, paymentData)
            .then(response => {
                console.log(response)
            })
    }

    const handleGetCompanies = useCallback(async () => {
        try {
            const companiesResp = await SUPER_ADMIN_REQUESTS.getCompanies(search)
            setCompanies(companiesResp)

        } catch (e) {
            console.error(e)
        }
    }, [search])

    useEffect(() => {
        handleGetCompanies()
    }, [handleGetCompanies, search])

    const handleDelete = useCallback(async (e, clubId) => {
        try {
            await SUPER_ADMIN_REQUESTS.updateCompany(clubId, { isDeleted: e.target.checked })
            handleGetCompanies()
        } catch (e) {
            console.error(e)
        }
    }, [])

    const handleAccepted = useCallback(async (e, clubId) => {
        try {
            await SUPER_ADMIN_REQUESTS.updateCompany(clubId, { isAccepted: e.target.checked })
            handleGetCompanies()
        } catch (e) {
            console.error(e)
        }
    }, [])

    const handleDeleteProfileImage = useCallback(async (clubId) => {
        try {
            await SUPER_ADMIN_REQUESTS.updateCompany(clubId, { identityCardSrc: null, avatarPhotoSrc: null })
            handleGetCompanies()
            console.error("Successfully removed")
        } catch (e) {
            console.error(e)
            console.error("Error removing avatar")
        }
    }, [])

    const handleUpdate = useCallback(async (clubId) => {
        try {
            const toUpdate = {}
            if (email) toUpdate['email'] = email
            if (phoneNumber) toUpdate['phoneNumber'] = phoneNumber
            await SUPER_ADMIN_REQUESTS.updateCompany(clubId, {
                ...toUpdate
            })
            handleGetCompanies()
            console.error("Successfully updated")
        } catch (e) {
            console.error(e)
        }
    }, [email, phoneNumber])

    const handleSendMessage = useCallback(async (clubId) => {
        try {
            await SUPER_ADMIN_REQUESTS.sendEmail({ message, type: 'company', entityId: clubId })
            handleGetCompanies()
            alert("Successfully sent")
        } catch (e) {
            console.error(e)
            alert("Error sending!")
        }
    }, [email, phoneNumber])

    useEffect(() => {
        if (editing) {
            setEditing(prevValue => companies.filter(el => el.id === prevValue.id)[0])
        }
    }, [editing, companies])

    const handleApproveIdentityCard = useCallback(async (e, clubId) => {
        try {
            if (e.target.value === 'denied') {
                await SUPER_ADMIN_REQUESTS.updateCompany(clubId, { identityCardApproved: false, identityCardSrc: null })
            }
            else {
                await SUPER_ADMIN_REQUESTS.updateCompany(clubId, { identityCardApproved: e.target.value === 'approved' ? true : false })
            }
            await handleGetCompanies()
            alert("Successfully updated")
        } catch (e) {
            console.error(e)
            alert("Error updating!")
        }
    }, [companies])

    const handleApproveRegistrationCertificate = useCallback(async (e, clubId) => {
        try {
            if (e.target.value === 'denied') {
                await SUPER_ADMIN_REQUESTS.updateCompany(clubId, { registrationCertificateApproved: false, registrationCertificateSrc: null })
            }
            else {
                await SUPER_ADMIN_REQUESTS.updateCompany(clubId, { registrationCertificateApproved: e.target.value === 'approved' ? true : false })
            }
            await handleGetCompanies()
            alert("Successfully updated")
        } catch (e) {
            console.error(e)
            alert("Error updating!")
        }
    }, [companies])

    const getLabelForKey = key => {
        if (key === 'email') return "Email"
        if (key === 'phoneNumber') return "Phone Number"
        return ""
    }

    const loginToClientHandler = organizationId => {
        SUPER_ADMIN_REQUESTS.loginToOrganization(organizationId)
            .then(response => {
                const { token, refreshToken, user } = response

                handleLoggedIn(true)
                handleToken(token)
                handleUser(user)
                const tokenDecoded = jwt_decode(token)
                const userLanguage = tokenDecoded && tokenDecoded.role === 'ORGANIZATION' ? tokenDecoded.locale === 'ENG' ? 'en' : tokenDecoded.locale.toLowerCase() : tokenDecoded.language ? tokenDecoded.language === 'ENG' ? 'en' : tokenDecoded.language.toLowerCase() : tokenDecoded.user.language ? tokenDecoded.user.language === 'EN' ? 'en' : tokenDecoded.user.language.toLowerCase() : 'en'
                i18n.changeLanguage(userLanguage)
                localStorage.setItem('token', token)
                localStorage.setItem('refreshToken', refreshToken)
                localStorage.setItem('email', user.email)
            })
    }

    const getValueForKey = key => {
        if (key === 'email') return email
        else if (key === 'phoneNumber') return phoneNumber
    }

    const renderQuill = useMemo(() => {
        return (
            <Grid paddingTop={2.8}>
                <ReactQuill
                    defaultValue={message}
                    modules={{
                        clipboard: {
                            matchVisual: false
                        }
                    }}
                    onChange={(newContent) => {
                        setMessage(newContent)
                    }}
                >
                    <div style={{ minHeight: 250 }} />
                </ReactQuill>
            </Grid>
        )
    }, [message])

    if (editing) return (
        <Grid container display="flex" flexDirection="column">
            <Grid item xs={12}>
                <IconButton onClick={() => {
                    setEditing()
                    setCompany()
                    setPaymentData({
                        swiftCode: '',
                        bankCountry: 'RO',
                        addressLine1: '',
                        city: '',
                        postalCode: '',
                    })
                }}><ArrowBack /></IconButton>
            </Grid>
            <Grid item xs={12} pt={2} pl={1.5}>
                <Grid container display="flex" flexDirection="row">
                    <Grid item xs={6}>
                        <Grid container display="flex" flexDirection="column">
                            {['email', 'phoneNumber'].map(key => {
                                return (
                                    <Grid container display="flex" flexDirection="column" pt={0.5} pb={0.5} pr={2}>
                                        <Grid item xs={12} pb={0.5}>
                                            <Typography fontWeight={500} variant="p">
                                                {getLabelForKey(key)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField onChange={e => {
                                                if (key === 'email') setEmail(e.target.value)
                                                else if (key === 'phoneNumber') setPhoneNumber(e.target.value)
                                            }} value={getValueForKey(key)} fullWidth />
                                        </Grid>
                                    </Grid>
                                )
                            })}
                            <Grid container display="flex" flexDirection="column" pt={0.5} pb={0.5} pr={2}>
                                <Grid item xs={12}>
                                    Do you want to remove profile image?
                                </Grid>
                                <Grid item xs={12} pt={2}>
                                    <Button onClick={() => handleDeleteProfileImage(editing.id)} variant="contained" color="error">Remove</Button>
                                </Grid>
                            </Grid>
                            <Grid container display="flex" flexDirection="column" pt={0.5} pb={0.5} pr={2}>
                                <Grid item xs={12} pt={2}>
                                    <Button onClick={() => handleUpdate(editing.id)} variant="contained" color="primary">Save your changes</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} >
                        {renderQuill}
                        <Box pt={2}>
                            <Button onClick={() => handleSendMessage(editing.id)} variant="contained">Send Message</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            {company ? <Grid item xs={6} pt={2} pl={1.5}>
                <Grid container p={2} component={Card} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" fontWeight={500}>
                            Configurare plata
                        </Typography>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="column">
                        <Typography variant="p">IBAN</Typography>
                        <TextField
                            disabled
                            placeholder='IBAN'
                            value={company.bankAccount}
                        />
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="column">
                        <Typography variant="p">Swift Code</Typography>
                        <TextField
                            placeholder='Swift Code'
                            value={paymentData.swiftCode}
                            onChange={event => setPaymentData({ ...paymentData, swiftCode: event.target.value })}
                        />
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="column">
                        <Typography variant="p">City</Typography>
                        <TextField
                            placeholder='City'
                            onChange={event => setPaymentData({ ...paymentData, city: event.target.value })}
                            value={paymentData.city}
                        />
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="column">
                        <Typography variant="p">Bank Country</Typography>
                        <Select
                            value={paymentData.bankCountry}
                            onChange={event => setPaymentData({ ...paymentData, bankCountry: event.target.value })}
                        >
                            <MenuItem value={'RO'}>Romania</MenuItem>
                        </Select>
                    </Grid>

                    <Grid item xs={12}>
                        <Button fullWidth variant='contained' onClick={onRevolutMerchantAccountSaveHandler}>Save changes</Button>
                    </Grid>
                </Grid>
            </Grid> : null}
            <Grid item xs={12} pt={2} pl={1.5}>
                <Grid container display="flex" flexDirection="column">
                    <Grid item xs={12}>
                        <Typography variant="h6">Identity Card</Typography>
                    </Grid>
                    {editing.identityCardSrc ? <Grid item xs={12} display="flex" flexDirection="row" alignItems="center" pt={0.5} pb={1}>
                        <Grid item xs={4} pt={2}>
                            {<Typography sx={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => window.open(editing.identityCardSrc, '_blank', 'noreferrer')}>Visualize Identity Card</Typography>}
                        </Grid>
                        <Grid item xs={3.5}>
                            {<InputGenerator
                                handleOnChange={(event) => {
                                    handleApproveIdentityCard(event, editing.id)
                                }}
                                input={{
                                    type: 'select',
                                    value: editing.identityCardApproved ? 'approved' : 'pending',
                                    values: [
                                        {
                                            label: 'Approved',
                                            value: 'approved'
                                        },
                                        {
                                            label: 'Pending',
                                            value: 'pending'
                                        },
                                        {
                                            label: 'Denied',
                                            value: 'denied'
                                        }]
                                }}
                            />}
                        </Grid>
                    </Grid> : <Typography>There are no documents uploaded</Typography>}
                </Grid>
            </Grid>
            <Grid item xs={12} pt={2} pl={1.5}>
                <Grid container display="flex" flexDirection="column">
                    <Grid item xs={12}>
                        <Typography variant="h6">Registration Certificate</Typography>
                    </Grid>
                    {editing.registrationCertificateSrc ? <Grid item xs={12} display="flex" flexDirection="row" alignItems="center" pt={0.5} pb={1}>
                        <Grid item xs={4} pt={2}>
                            {<Typography sx={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => window.open(editing.registrationCertificateSrc, '_blank', 'noreferrer')}>Visualize Registration Certificate</Typography>}
                        </Grid>
                        <Grid item xs={3.5}>
                            {<InputGenerator
                                handleOnChange={(event) => {
                                    handleApproveRegistrationCertificate(event, editing.id)
                                }}
                                input={{
                                    type: 'select',
                                    value: editing.registrationCertificateApproved ? 'approved' : 'pending',
                                    values: [
                                        {
                                            label: 'Approved',
                                            value: 'approved'
                                        },
                                        {
                                            label: 'Pending',
                                            value: 'pending'
                                        },
                                        {
                                            label: 'Denied',
                                            value: 'denied'
                                        }]
                                }}
                            />}
                        </Grid>
                    </Grid> : <Typography>There are no documents uploaded</Typography>}
                </Grid>
            </Grid>
        </Grid>
    )

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table dense sx={{ minWidth: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>Country</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell align='right'>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {companies.map((row) => (
                            <TableRow
                                key={row.email}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>{row.email}</TableCell>
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>{row.phoneNumber}</TableCell>
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>{row.country ? row.country : '-'}</TableCell>
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>{row.city ? row.city : '-'}</TableCell>
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }} align='right'>
                                    <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                        <Box pl={1} pr={1}>
                                            <Checkbox checked={row.isDeleted} onChange={e => handleDelete(e, row.id)} /> Deleted
                                        </Box>
                                        <Box pl={1} pr={1}>
                                            <Checkbox checked={row.isAccepted} onChange={e => handleAccepted(e, row.id)} /> Public
                                        </Box>
                                        <Box pl={1} pr={1}>
                                            <IconButton onClick={() => {
                                                setEditing(row)
                                                setPaymentData({
                                                    swiftCode: row.swiftCode,
                                                    bankCountry: row.bankCountry,
                                                    // addressLine1: row.addressLine1,
                                                    city: row.locationName,
                                                    // postalCode: row.postalCode,
                                                    currency: row.currency
                                                })
                                                setCompany(row)
                                                setEmail(row.email)
                                                setPhoneNumber(row.phoneNumber)
                                            }}><Edit /></IconButton>
                                        </Box>
                                        <Box pl={1} pr={1}>
                                            <Tooltip title="Login to this client">
                                                <IconButton onClick={() => loginToClientHandler(row.id)}><East /></IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default SuperAdminCompanies