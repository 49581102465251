import React, { useEffect, useState } from 'react'
import { Dialog, IconButton, Button, Typography, Grid, TextField, Autocomplete, Box, Avatar, Chip, Select, MenuItem, Tooltip } from '@mui/material'
import { Close, Money, Receipt, InfoOutlined, CreditCard } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'

import { Toggle } from '../../common'

import { useAuthentication } from '../../../context/auth-context'
import {
    GRAY_BG_COLOR,
    GRAY_TEXT_COLOR,
    SECONDARY_TEXT_COLOR,
    ROLE_USER,
    USER_SUBSCRIPTION_STATUS,
    PAYMENT_METHOD,
    USER_SUBSCRIPTION_PAYMENT_STATUS,
    LIGHT_GRAY_BORDER_COLOR
} from '../../../utils/constants'
import * as COMPANY_REQUESTS from '../../../api/company'
import * as USER_REQUESTS from '../../../api/user'
import * as VOUCHER_REQUESTS from '../../../api/voucher'
import moment from 'moment'

const AddClient = ({
    onCancel,
    onConfirm,
    data
}) => {
    const theme = useTheme()
    const matchesTablet = useMediaQuery(theme.breakpoints.down('md'))
    const { t } = useTranslation()
    const { user } = useAuthentication()

    const [filters, setFilters] = useState({
        selectedUsers: [],
        selectedVouchers: [],
        selectedSubscriptions: [],
        selectedPaymentMethods: Object.values(PAYMENT_METHOD),
        minPrice: null,
        maxPrice: null,
        minCreditsUsed: null,
        maxCreditsUsed: null,
        startBookInFuture: null,
        endBookInFuture: null,
        startCreatedAt: null,
        endCreatedAt: null,
        status: null,
        payment: null,
        showClientsWithNoBook: false
    })

    const [filter, setFilter] = useState()
    const [users, setUsers] = useState([])
    const [subscriptions, setSubscriptions] = useState([])
    const [vouchers, setVouchers] = useState([])

    const getAllUsers = (filter) => {
        const query = {}

        if (filter) {
            query.search = filter
        }

        if (!filter) {
            setUsers([])
            return
        }

        return USER_REQUESTS.getAllUsers({ ...query, role: ROLE_USER }).then((response) => {
            setUsers(response)
        })
    }

    const getAllCompanySubscriptions = () => {
        return COMPANY_REQUESTS.getAllSubscriptions(user.companyId).then((response) => {
            setSubscriptions(response)
        })
    }

    const getAllVouchers = () => {
        return VOUCHER_REQUESTS.getAll()
            .then(response => {
                setVouchers(response)
            })
    }

    useEffect(() => {
        if (data && Object.keys(data).length) {
            setFilters({
                ...data,
                ...(data.selectedUsers ? { selectedUsers: data.selectedUsers } : { selectedUsers: [] }),
                ...(data.selectedSubscriptions ? { selectedSubscriptions: data.selectedSubscriptions } : { selectedSubscriptions: [] }),
                ...(data.selectedPaymentMethods ? { selectedPaymentMethods: data.selectedPaymentMethods } : { selectedPaymentMethods: [] }),
                ...(data.selectedVouchers ? { selectedVouchers: data.selectedVouchers } : { selectedVouchers: [] })
            })
        }
    }, [data])

    useEffect(() => {
        getAllCompanySubscriptions()
        getAllVouchers()
    }, [user])

    useEffect(() => {
        getAllUsers(filter)
    }, [filter])

    const onSubmitHandler = () => {
        onConfirm(filters)
    }

    return (
        <Dialog open maxWidth="sm" fullScreen={matchesTablet} fullWidth PaperProps={{ ...(matchesTablet ? { sx: { borderRadius: 0 } } : {}) }}>
            <Grid container>
                <Grid item xs={12} pt={2} pr={4} pl={4}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item
                            xs={10}
                            md={10}
                            lg={10}>
                            <Typography variant="h4" fontWeight={500} color={'black'}>
                                {t('clients.advancedFiltersModal.title')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                            <IconButton onClick={onCancel}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    pt={1}
                    pb={1}
                    xs={12}
                    pr={{ xs: 4, md: 4, lg: 4 }}
                    pl={{ xs: 4, md: 4, lg: 4 }}
                >
                    <Grid item xs={12}>
                        <Typography variant="p" fontWeight={500} gutterBottom >
                            {t('employees.selectUser')}
                        </Typography>
                        <Autocomplete
                            options={users.map((el) => ({
                                label: `${el.firstName} ${el.lastName}`,
                                avatar: el.avatarPhotoSrc,
                                phoneNumber: el.phoneNumber,
                                value: el.id,
                                id: el.id,
                                firstName: el.firstName,
                                lastName: el.lastName
                            }))}
                            isOptionEqualToValue={(option, value) => {
                                return option.value === value.value
                            }}
                            autoHighlight
                            renderOption={(props, option) => {
                                return (
                                    <Box
                                        {...props}
                                        sx={{ display: 'flex', flexDirection: 'row' }}
                                        key={option.value}
                                    >
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {option.avatar ? (
                                                <Avatar style={{ width: 50, height: 50 }} src={option.avatar} />
                                            ) : (
                                                <Box
                                                    sx={{
                                                        width: 50,
                                                        height: 50,
                                                        backgroundColor: GRAY_BG_COLOR,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        borderRadius: 25
                                                    }}
                                                >
                                                    <Typography fontWeight="bold" color={GRAY_TEXT_COLOR}>
                                                        {option.firstName.charAt(0)} {option.lastName.charAt(0)}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 1 }}>
                                            <Typography fontWeight={500}>{option.label}</Typography>
                                            <Typography fontWeight={500}>{option.phoneNumber}</Typography>
                                        </Box>
                                    </Box>
                                )
                            }}
                            filterOptions={(options, state) => options}
                            onInputChange={(event) => {
                                setFilter(event.target.value)
                            }}
                            onChange={(_, newValue) => {
                                if (newValue) {
                                    const exists = filters.selectedUsers.map(user => user.id).includes(newValue.id)

                                    if (exists) return

                                    setFilters(prevFilters => ({ ...prevFilters, selectedUsers: prevFilters.selectedUsers.concat([newValue]) }))
                                }
                                setFilter('')
                            }}
                            renderInput={(params) => (
                                <TextField
                                    placeholder={t('businessEditProfile.searchTrainer')}
                                    {...params}
                                    InputProps={{ ...params.InputProps, endAdornment: null }}
                                    inputProps={{
                                        ...params.inputProps,
                                        style: {
                                            marginLeft: 6,
                                            padding: 11.5,
                                            color: SECONDARY_TEXT_COLOR,
                                            fontWeight: 500
                                        }
                                    }}
                                />
                            )}
                        />
                        <Box>
                            <Grid container spacing={0.5} pt={1}>
                                {filters.selectedUsers.map((el) => {
                                    return <Grid item index={el.value}>
                                        <Chip
                                            label={el.firstName + el.lastName}
                                            onDelete={() => {
                                                const prevUsers = filters.selectedUsers.filter(user => user.value !== el.value)

                                                setFilters(prevValue => ({ ...prevValue, selectedUsers: prevUsers }))
                                            }}
                                        />
                                    </Grid>
                                })}
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} pt={1}>
                        <Typography variant="p" fontWeight={500} gutterBottom>
                            {t('clients.advancedFiltersModal.subscriptions')}
                        </Typography>
                        <Autocomplete
                            disablePortal
                            onChange={(_, newValue) => {
                                if (newValue) {
                                    const exists = filters.selectedSubscriptions.map(sub => sub.id).includes(newValue.id)

                                    if (exists) return

                                    setFilters(prevFilters => ({ ...prevFilters, selectedSubscriptions: prevFilters.selectedSubscriptions.concat([newValue]) }))
                                }
                            }}
                            options={subscriptions.map(el => ({ id: el.id, label: el.name }))}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <Box>
                            <Grid container spacing={0.5} pt={1}>
                                {filters.selectedSubscriptions.map((el) => {
                                    return <Grid item index={el.id}>
                                        <Chip
                                            label={el.label}
                                            onDelete={() => {
                                                const prevSubscriptions = filters.selectedSubscriptions.filter(user => user.id !== el.id)

                                                setFilters(prevValue => ({ ...prevValue, selectedSubscriptions: prevSubscriptions }))
                                            }}
                                        />
                                    </Grid>
                                })}
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} pt={1}>
                        <Typography variant="p" fontWeight={500} gutterBottom>
                            {t('clients.advancedFiltersModal.vouchers')}
                        </Typography>
                        <Autocomplete
                            disablePortal
                            onChange={(_, newValue) => {
                                if (newValue) {
                                    const exists = filters.selectedVouchers.map(voucher => voucher.id).includes(newValue.id)

                                    if (exists) return

                                    setFilters(prevFilters => ({ ...prevFilters, selectedVouchers: prevFilters.selectedVouchers.concat([newValue]) }))
                                }
                            }}
                            options={vouchers.map(el => ({ id: el.id, label: el.name + (el.Subscription ? '-' + el.Subscription.name : '') }))}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <Box>
                            <Grid container spacing={0.5} pt={1}>
                                {filters.selectedVouchers.map((el) => {
                                    return <Grid item index={el.id}>
                                        <Chip
                                            label={el.label}
                                            onDelete={() => {
                                                const prevVouchers = filters.selectedVouchers.filter(voucher => voucher.id !== el.id)

                                                setFilters(prevValue => ({ ...prevValue, selectedVouchers: prevVouchers }))
                                            }}
                                        />
                                    </Grid>
                                })}
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={6} pt={1} pr={0.5}>
                        <Typography variant="p" fontWeight={500} gutterBottom>
                            {t('clients.advancedFiltersModal.minPrice')}
                        </Typography>
                        <TextField
                            fullWidth
                            value={filters.minPrice}
                            onChange={event => setFilters({ ...filters, minPrice: event.target.value })}
                            placeholder="90"
                        />
                    </Grid>
                    <Grid item xs={6} pt={1} pl={0.5}>
                        <Typography variant="p" fontWeight={500} gutterBottom>
                            {t('clients.advancedFiltersModal.maxPrice')}
                        </Typography>
                        <TextField
                            onChange={event => setFilters({ ...filters, maxPrice: event.target.value })}
                            fullWidth
                            value={filters.maxPrice}
                            placeholder="1100"
                        />
                    </Grid>
                    <Grid item xs={6} pt={1} pr={0.5}>
                        <Typography variant="p" fontWeight={500} gutterBottom>
                            {t('clients.advancedFiltersModal.minCreditsUsed')} <Tooltip title={t('clients.advancedFiltersModal.creditsFilterDescription')}><InfoOutlined color="primary" sx={{ width: 14, height: 14 }} /></Tooltip>
                        </Typography>
                        <TextField
                            fullWidth
                            value={filters.minCreditsUsed}
                            onChange={event => setFilters({ ...filters, minCreditsUsed: event.target.value })}
                            placeholder="1"
                        />
                    </Grid>
                    <Grid item xs={6} pt={1} pl={0.5}>
                        <Typography variant="p" fontWeight={500} gutterBottom>
                            {t('clients.advancedFiltersModal.maxCreditsUsed')} <Tooltip title={t('clients.advancedFiltersModal.creditsFilterDescription')}><InfoOutlined color="primary" sx={{ width: 14, height: 14 }} /></Tooltip>
                        </Typography>
                        <TextField
                            fullWidth
                            value={filters.maxCreditsUsed}
                            onChange={event => setFilters({ ...filters, maxCreditsUsed: event.target.value })}
                            placeholder="30"
                        />
                    </Grid>

                    <Grid item xs={6} pt={1} pr={0.5}>
                        <Typography variant="p" fontWeight={500} gutterBottom> {t('clients.advancedFiltersModal.validFrom')}</Typography>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                inputFormat="dd/MM/yyyy"
                                value={moment(filters.startCreatedAt)}
                                onChange={(newValue) => {
                                    if (Date.parse(newValue)) {
                                        setFilters({ ...filters, startCreatedAt: moment(newValue).format('YYYY-MM-DD') })
                                    }
                                }}
                                renderInput={(params) => <TextField
                                    fullWidth
                                    sx={{
                                        '.MuiIconButton-root': { marginRight: 1 }
                                    }}
                                    {...params}
                                />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6} pt={1} pl={0.5}>
                        <Typography variant="p" fontWeight={500} gutterBottom> {t('clients.advancedFiltersModal.validUntil')} <Tooltip title={t('clients.advancedFiltersModal.validFilterDescription')}><InfoOutlined color="primary" sx={{ width: 14, height: 14 }} /></Tooltip></Typography>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                inputFormat="dd/MM/yyyy"
                                value={moment(filters.endCreatedAt)}
                                onChange={(newValue) => {
                                    if (Date.parse(newValue)) {
                                        setFilters({ ...filters, endCreatedAt: moment(newValue).format('YYYY-MM-DD') })
                                    }
                                }}
                                renderInput={(params) => <TextField
                                    fullWidth
                                    sx={{
                                        '.MuiIconButton-root': { marginRight: 1 }
                                    }}
                                    {...params}
                                />}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} pt={1}>
                        <Typography variant="p" fontWeight={500} gutterBottom>{t('clients.paymentStatus')}</Typography>
                        <Select
                            fullWidth
                            value={filters.payment}
                            onChange={event => setFilters(prevValue => ({ ...prevValue, payment: event.target.value }))}
                        >
                            <MenuItem value="">
                                <em>{t('clients.none')}</em>
                            </MenuItem>
                            <MenuItem value={USER_SUBSCRIPTION_PAYMENT_STATUS.PAID}>{t('clients.paid')}</MenuItem>
                            <MenuItem value={USER_SUBSCRIPTION_PAYMENT_STATUS.UNPAID}>{t('clients.unpaid')}</MenuItem>
                        </Select>
                    </Grid>

                    <Grid item xs={6} pt={1} pr={0.5}>
                        <Typography variant="p" fontWeight={500} gutterBottom> {t('clients.advancedFiltersModal.bookFrom')}</Typography>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                inputFormat="dd/MM/yyyy"
                                value={moment(filters.startBookInFuture)}
                                onChange={(newValue) => {
                                    if (Date.parse(newValue)) {
                                        setFilters({ ...filters, startBookInFuture: moment(newValue).format('YYYY-MM-DD') })
                                    }
                                }}
                                renderInput={(params) => <TextField
                                    fullWidth
                                    disabled
                                    sx={{
                                        '.MuiIconButton-root': { marginRight: 1 }
                                    }}
                                    {...params}
                                    error={false}
                                />}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={6} pt={1} pl={0.5}>
                        <Typography variant="p" fontWeight={500} gutterBottom> {t('clients.advancedFiltersModal.bookUntil')}</Typography>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                inputFormat="dd/MM/yyyy"
                                value={moment(filters.endBookInFuture)}
                                onChange={(newValue) => {
                                    if (Date.parse(newValue)) {
                                        setFilters({ ...filters, endBookInFuture: moment(newValue).format('YYYY-MM-DD') })
                                    }
                                }}
                                renderInput={(params) => <TextField
                                    fullWidth
                                    sx={{
                                        '.MuiIconButton-root': { marginRight: 1 }
                                    }}
                                    {...params}
                                    error={false}
                                />}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} pt={1}>
                        <Typography variant="p" fontWeight={500} gutterBottom>{t('clients.advancedFiltersModal.bookDatesConfig')} <Tooltip title={t('clients.advancedFiltersModal.bookDatesConfigDescription')}><InfoOutlined color="primary" sx={{ width: 14, height: 14 }} /></Tooltip></Typography>
                        <Box display="flex" flexDirection="row">
                            <Toggle defaultChecked={filters.showClientsWithNoBook}
                                onClick={() => setFilters(prevValue => ({ ...prevValue, showClientsWithNoBook: !prevValue.showClientsWithNoBook }))} />
                            <Typography pl={1}>{filters.showClientsWithNoBook ? t('clients.advancedFiltersModal.noBooks') : t('clients.advancedFiltersModal.withBooks')}</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} pt={1}>
                        <Typography variant="p" fontWeight={500} gutterBottom>{t('clients.statuses')}</Typography>
                        <Select
                            fullWidth
                            value={filters.status}
                            onChange={event => setFilters(prevValue => ({ ...prevValue, status: event.target.value }))}
                        >
                            <MenuItem value="">
                                <em>{t('clients.none')}</em>
                            </MenuItem>
                            <MenuItem value={USER_SUBSCRIPTION_STATUS.ACTIVE}>{t('clients.active')}</MenuItem>
                            <MenuItem value={USER_SUBSCRIPTION_STATUS.INACTIVE}>{t('clients.inactive')}</MenuItem>
                            <MenuItem value={USER_SUBSCRIPTION_STATUS.PAUSED}>{t('clients.paused')}</MenuItem>
                            <MenuItem value={'EXPIRED'}> {t('clients.advancedFiltersModal.expired')}</MenuItem>
                            <MenuItem value={'CANCELED'}> {t('clients.advancedFiltersModal.canceled')}</MenuItem>
                        </Select>
                    </Grid>

                    <Grid item xs={12} pt={1}>
                        <Typography variant="p" fontWeight={500} gutterBottom>{t('clients.advancedFiltersModal.paymentMethod')}</Typography>
                        <Grid container spacing={1} pt={0.5}>
                            {Object.values(PAYMENT_METHOD).map((el) => {
                                if (el === PAYMENT_METHOD.CASH) {
                                    return (
                                        <Grid item key={el}>
                                            <Chip

                                                icon={<Money />}
                                                sx={{
                                                    fontWeight: 500,
                                                }}
                                                onClick={() => {
                                                    const hasCash = filters.selectedPaymentMethods.includes(PAYMENT_METHOD.CASH)
                                                    let newPaymentMethos = filters.selectedPaymentMethods

                                                    if (hasCash) {
                                                        newPaymentMethos = newPaymentMethos.filter(method => method !== PAYMENT_METHOD.CASH)
                                                    } else {
                                                        newPaymentMethos = newPaymentMethos.concat([PAYMENT_METHOD.CASH])
                                                    }

                                                    setFilters({ ...filters, selectedPaymentMethods: newPaymentMethos })
                                                }}
                                                color="primary"
                                                variant={filters.selectedPaymentMethods.includes(PAYMENT_METHOD.CASH) ? "contained" : "outlined"}
                                                label={t('subscriptionModal.cash')}
                                            />
                                        </Grid>
                                    )
                                }

                                if (el === PAYMENT_METHOD.BANK_TRANSFER) {
                                    return (
                                        <Grid item key={el}>
                                            <Chip
                                                icon={<Receipt />}
                                                sx={{
                                                    fontWeight: 500,
                                                }}
                                                onClick={() => {
                                                    const hasCash = filters.selectedPaymentMethods.includes(PAYMENT_METHOD.BANK_TRANSFER)
                                                    let newPaymentMethos = filters.selectedPaymentMethods

                                                    if (hasCash) {
                                                        newPaymentMethos = newPaymentMethos.filter(method => method !== PAYMENT_METHOD.BANK_TRANSFER)
                                                    } else {
                                                        newPaymentMethos = newPaymentMethos.concat([PAYMENT_METHOD.BANK_TRANSFER])
                                                    }

                                                    setFilters({ ...filters, selectedPaymentMethods: newPaymentMethos })
                                                }}
                                                color="primary"
                                                variant={filters.selectedPaymentMethods.includes(PAYMENT_METHOD.BANK_TRANSFER) ? "contained" : "outlined"}
                                                label={t('subscriptionModal.bankTransfer')}
                                            />
                                        </Grid>
                                    )
                                }

                                if (el === PAYMENT_METHOD.CARD) {
                                    return (
                                        <Grid item key={el}>
                                            <Chip
                                                icon={<Avatar variant="square" src={require('../../../assets/pos.png')} style={{ width: 20, height: 20 }} />}
                                                onClick={() => {
                                                    const hasCash = filters.selectedPaymentMethods.includes(PAYMENT_METHOD.CARD)
                                                    let newPaymentMethos = filters.selectedPaymentMethods

                                                    if (hasCash) {
                                                        newPaymentMethos = newPaymentMethos.filter(method => method !== PAYMENT_METHOD.CARD)
                                                    } else {
                                                        newPaymentMethos = newPaymentMethos.concat([PAYMENT_METHOD.CARD])
                                                    }

                                                    setFilters({ ...filters, selectedPaymentMethods: newPaymentMethos })
                                                }}
                                                sx={{
                                                    fontWeight: 500,
                                                }}
                                                color="primary"
                                                variant={filters.selectedPaymentMethods.includes(PAYMENT_METHOD.CARD) ? "contained" : "outlined"}
                                                label={t('subscriptionModal.pos')}
                                            />
                                        </Grid>
                                    )
                                }

                                if (el === PAYMENT_METHOD.ONLINE) {
                                    return (
                                        <Grid item key={el}>
                                            <Chip
                                                icon={<CreditCard />}
                                                onClick={() => {
                                                    const hasOnline = filters.selectedPaymentMethods.includes(PAYMENT_METHOD.ONLINE)
                                                    let newPaymentMethos = filters.selectedPaymentMethods

                                                    if (hasOnline) {
                                                        newPaymentMethos = newPaymentMethos.filter(method => method !== PAYMENT_METHOD.ONLINE)
                                                    } else {
                                                        newPaymentMethos = newPaymentMethos.concat([PAYMENT_METHOD.ONLINE])
                                                    }

                                                    setFilters({ ...filters, selectedPaymentMethods: newPaymentMethos })
                                                }}
                                                sx={{
                                                    fontWeight: 500,
                                                }}
                                                color="primary"
                                                variant={filters.selectedPaymentMethods.includes(PAYMENT_METHOD.ONLINE) ? "contained" : "outlined"}
                                                label={t('subscriptionModal.card')}
                                            />
                                        </Grid>
                                    )
                                }
                            })}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} pt={3}>
                    <Grid container display="flex" flexDirection="row" borderTop={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
                        <Grid item xs={12} md={12} lg={6} borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
                            <Button onClick={onCancel} sx={{ height: 60 }} fullWidth variant="text" color="error">
                                {t('clients.cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <Button
                                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 60 }}
                                fullWidth
                                onClick={() => {
                                    onSubmitHandler()
                                }}
                                variant="text"
                            >
                                {t('clients.save')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default AddClient
