import React, { useState } from 'react'
import { Grid, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Review from './subcomponents/Review'
import { CreateReview } from '../../../common'

import { useAuthentication } from '../../../../context/auth-context'
import * as TRAINER_REQUESTS from '../../../../api/trainer'
import * as COMPANY_REQUESTS from '../../../../api/company'
import * as USER_REQUESTS from '../../../../api/user'

const Details = ({ Reviews, handleGetMe }) => {
  const { t } = useTranslation()
  const [showCreateReview, setShowCreateReview] = useState(false)

  const { user } = useAuthentication()

  const deleteReviewHandler = id => {
    return USER_REQUESTS.removeReview(id)
      .then(handleGetMe)
  }

  const onSaveReview = async (stars, comment, id, type, domain) => {
    try {
      if (type === 'trainer') {
        await TRAINER_REQUESTS.createTrainerReview(domain, {
          reviewTrainerId: id,
          userId: user.id,
          comment,
          stars
        })
      } else {
        await COMPANY_REQUESTS.createReview(domain, {
          reviewCompanyId: id,
          userId: user.id,
          comment,
          stars
        })
      }
      handleGetMe()
      setShowCreateReview(false)
    } catch (e) {
      console.error(e)
      setShowCreateReview(false)
    }
  }

  return (
    <>
      {showCreateReview && (
        <CreateReview
          onCancel={() => setShowCreateReview(false)}
          fromUserProfile={true}
          onSaveHandler={onSaveReview}
        />
      )}
      <Grid
        container
        width="100%"
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }}
        pt={2}
        pb={4}
      >
        <Grid p={3} lg={12} xs={12} item>
          <Grid pb={1.5} item xs={12} display="flex" flexDirection="row">
            <Typography fontWeight={500} color="black" fontSize={20}>
              {t('userProfile.activity.detailComponent.reviews')}
            </Typography>
            <Button
              sx={{ marginLeft: 'auto' }}
              variant="contained"
              color="success"
              onClick={() => setShowCreateReview(true)}
            >
              {' '}
              {t('button.addReview')}
            </Button>
          </Grid>
          <Grid display="flex" item flexDirection={{ xs: 'column', lg: 'column', xl: 'column' }}>
            {Reviews.length > 0 ? (
              Reviews.filter((el) => el.ReviewTrainer || el.ReviewGym).map((el, index) => {
                let name = ''

                if (el.ReviewTrainer) {
                  name += `${el.ReviewTrainer.firstName} ${el.ReviewTrainer.lastName}`
                } else {
                  name += el.ReviewGym.name
                }

                return (
                  <Grid key={index} pt={3}>
                    <Link
                      style={{ textDecoration: 'none', cursor: 'pointer', color: 'inherit' }}
                      to={`/${el.ReviewTrainer ? el.ReviewTrainer.domain : el.ReviewGym.domain}`}
                    >
                      <Review
                        onDelete={() => deleteReviewHandler(el.id)}
                        comment={el.comment}
                        name={name}
                        stars={el.stars}
                        createdAt={el.createdAt}
                      />
                    </Link>
                  </Grid>
                )
              })
            ) : (
              <Typography color="#979797" fontWeight={500} fontSize={17}>
                {t('helper.noInformation')}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Details
