import React, { useEffect, useState } from 'react'
import {
  Grid,
  Typography,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  IconButton,
  Card
} from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Delete, Edit } from '@mui/icons-material'

import { LineChart, InputGenerator, BarChart } from '../../../../common'
import DeleteMeasurement from '../../../../UserMeasurements/subcomponents/DeleteMeasurement'
import EditMeasurement from '../../../../UserMeasurements/subcomponents/EditMeasurement'
import CreateMeasurement from '../../../../UserMeasurements/subcomponents/CreateMeasurement'

import { BLUE_CHART, MAIN_MEASUREMENTS, ROLE_TRAINER } from '../../../../../utils/constants'
import * as MEASUREMENTS_REQUEST from '../../../../../api/measurements'
import { useAuthentication } from '../../../../../context/auth-context'

const Weight = ({ userId }) => {
  const [data, setData] = useState([])

  const [from, setFrom] = useState(moment().subtract(3, 'months'))
  const [upTo, setUpTo] = useState()
  const [chartType, setChartType] = useState('line')
  const [measurements, setMeasurements] = useState('weight')
  const [showRecords, setShowRecords] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showAdd, setShowAdd] = useState(false)
  const [selectedItem, setSelectedItem] = useState()

  const { t } = useTranslation()
  const { user } = useAuthentication()
  const isTrainer = user && user.role && user.role.toLowerCase() === ROLE_TRAINER.toLowerCase()

  const handleGetMeasurements = async () => {
    try {
      const response = await MEASUREMENTS_REQUEST.getMeasurements({
        mainType: MAIN_MEASUREMENTS.WEIGHT,
        secondaryType: measurements,
        from: moment(from).format('YYYY-MM-DD'),
        upTo: moment(upTo).format('YYYY-MM-DD'),
        userId
      })
      setData(
        response.map((el) => ({
          date: moment(el.from).format('YYYY-MM-DD'),
          value: el.value,
          type: el.secondaryType,
          id: el.id
        }))
      )
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (isTrainer && !userId) return
    handleGetMeasurements()
  }, [from, upTo, measurements, userId, isTrainer])

  const handleShowEdit = (id) => {
    setSelectedItem(id)
    setShowEdit(true)
  }

  const handleShowDelete = (id) => {
    setSelectedItem(id)
    setShowDelete(true)
  }

  const handleDelete = async () => {
    try {
      await MEASUREMENTS_REQUEST.deleteMeasurement(selectedItem)
      setSelectedItem()
      setShowDelete(false)
      handleGetMeasurements()
    } catch (e) {
      console.error(e)
      setSelectedItem()
      setShowDelete(false)
    }
  }

  const handleEdit = async (data) => {
    try {
      await MEASUREMENTS_REQUEST.updateMeasurement(selectedItem, data)
      setSelectedItem()
      setShowEdit(false)
      handleGetMeasurements()
    } catch (e) {
      console.error(e)
      setSelectedItem()
      setShowEdit(false)
    }
  }

  const handleCreateMeasurement = async (data) => {
    try {
      await MEASUREMENTS_REQUEST.createMeasurement(data, userId)
      setShowAdd(false)
      handleGetMeasurements()
    } catch (e) {
      console.error(e)
      setShowAdd(false)
    }
  }

  const renderManageProgress = () => {
    if (!showRecords) return null
    return (
      <Grid container display="flex" flexColumn pt={4}>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight={600}>
            {t('userProfile.manageProgress')}
          </Typography>
        </Grid>
        <Grid item xs={12} pt={2}>
          <Grid container>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('userProfile.measurementType')}</TableCell>
                      <TableCell align="right">{t('userProfile.value')}</TableCell>
                      <TableCell align="right">{t('userProfile.options')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((el, index) => (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {el.type}
                        </TableCell>
                        <TableCell align="right">{el.value}</TableCell>
                        <TableCell align="right">
                          <IconButton mr={1} onClick={() => handleShowEdit(el.id)}>
                            <Edit />
                          </IconButton>
                          <IconButton ml={1} onClick={() => handleShowDelete(el.id)}>
                            <Delete color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {showDelete && selectedItem ? (
        <DeleteMeasurement
          handleDelete={handleDelete}
          handleClose={() => {
            setSelectedItem()
            setShowDelete(false)
          }}
        />
      ) : null}
      {showEdit && !showDelete && selectedItem ? (
        <EditMeasurement
          id={selectedItem}
          type={MAIN_MEASUREMENTS.WEIGHT}
          handleEditMeasurement={handleEdit}
          handleClose={() => {
            setSelectedItem()
            showEdit(false)
          }}
        />
      ) : null}
      {showAdd && (
        <CreateMeasurement
          type={MAIN_MEASUREMENTS.WEIGHT}
          handleCreateMeasurement={handleCreateMeasurement}
          handleClose={() => setShowAdd(false)}
        />
      )}

      <Grid pt={3} container display="flex" flexDirection="column">
        <Grid container display="flex" flexDirection="row" pb={2} alignItems="center">
          <Grid
            item
            xl={8}
            lg={8}
            md={12}
            xs={12}
            flexWrap={{ xs: 'wrap' }}
            display="flex"
            flexDirection="row"
          >
            <Grid item md={3} xs={6} pr={2}>
              <Grid container display="flex" flexDirection="column">
                <Grid pb={1} item>
                  {
                    <Typography fontWeight={500} variant="p">
                      {t('userProfile.from')}
                    </Typography>
                  }
                </Grid>
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    inputFormat="MM/dd/yyyy"
                    value={from}
                    onChange={(newValue) => setFrom(newValue)}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          '.MuiIconButton-root': { marginRight: 1 }
                        }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item md={3} xs={6} pr={{ xs: 0, md: 2 }}>
              <Grid container display="flex" flexDirection="column">
                <Grid pb={1} item>
                  {
                    <Typography fontWeight={500} variant="p">
                      {t('userProfile.upTo')}
                    </Typography>
                  }
                </Grid>
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    inputFormat="MM/dd/yyyy"
                    value={upTo}
                    onChange={(newValue) => setUpTo(newValue)}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          '.MuiIconButton-root': { marginRight: 1 }
                        }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item md={3} xs={6} pr={2}>
              <Grid container display="flex" flexDirection="column">
                <Grid item>
                  <InputGenerator
                    handleOnChange={(event) => setMeasurements(event.target.value)}
                    input={{
                      type: 'select',
                      label: 'Measurements',
                      placeholder: 'Select Measurements',
                      value: measurements,
                      values: [{ value: 'weight', label: 'Weight' }]
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3} xs={6}>
              <Grid container display="flex" flexDirection="column">
                <Grid item>
                  <InputGenerator
                    handleOnChange={(event) => setChartType(event.target.value)}
                    input={{
                      type: 'select',
                      label: 'Chart Type',
                      placeholder: 'Select chart type',
                      value: chartType,
                      values: [
                        { value: 'line', label: 'Line Chart' },
                        { value: 'bar', label: 'Bar Chart' }
                      ]
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={12}
            sm={12}
            xs={12}
            pl={{ md: 0, lg: 2 }}
            pr={{ lg: chartType === 'line' ? 4 : 0, md: 0, xs: 0 }}
            pt={1.4}
          >
            <Grid
              container
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Grid xs={12} md={6} item pt={{ md: 2, xs: 2 }} pr={{ xs: 0, md: 1 }}>
                <Button onClick={() => setShowAdd(true)} fullWidth variant="contained">
                  {t('userProfile.activity.addMeasurements')}
                </Button>
              </Grid>
              <Grid xs={12} md={6} item pt={{ md: 2, xs: 2 }} pl={{ xs: 0, md: 1 }}>
                <Button
                  onClick={() => setShowRecords((prevValue) => !prevValue)}
                  fullWidth
                  variant="outlined"
                >
                  {showRecords ? t('userProfile.hide') : t('userProfile.progress')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container component={Card} sx={{
            boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
            border: '1px solid #F0F0F0',
            borderRadius: 2
          }} p={2}>
            {data && data.length ? (
              chartType === 'line' ? (
                <LineChart maintainAspectRatio={true} data={{
                  labels: data.map((el) => el.date),
                  datasets: [
                    {
                      label: measurements,
                      data: data.map((el) => el.value),
                      borderColor: BLUE_CHART,
                      backgroundColor: BLUE_CHART
                    }
                  ]
                }} />
              ) : (
                <BarChart type={measurements} values={data} />
              )
            ) : (
              <Typography pl={1} color="#979797" fontWeight={500} fontSize={17}>
                {t('helper.noResultsFound')}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {renderManageProgress()}
        </Grid>
      </Grid>
    </>
  )
}

export default Weight
