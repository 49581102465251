import { TableCell } from '@mui/material';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import usePayouts from '../../../../../hooks/usePayouts';
import { toast } from 'react-toastify';
import FileSaver from 'file-saver';
import { decode } from 'base64-arraybuffer';

const ActionCell = ({ paymentId, index }) => {
    const { downloadPaymentInvoice } = usePayouts();

    const handleInvoice = async () => {
        const response = await downloadPaymentInvoice(paymentId);
        const outputFilename = `Payment_Invoice_SportIn.pdf`;

        if (!response?.file) return toast.error(response, { position: 'bottom-right' });

        FileSaver.saveAs(new Blob([decode(response.file)]), outputFilename);
    };

    return (
        <TableCell sx={{ border: 'none !important', width: '2.5%' }} key={`action-${index}`}>
            <CloudDownloadOutlinedIcon onClick={handleInvoice} sx={{ cursor: 'pointer', width: '1.2em', height: '1.2em' }} />
        </TableCell>
    );
};

export default ActionCell;