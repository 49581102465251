import React from 'react'
import { Container, Box, Typography, Grid } from '@mui/material'
import { People, CreditScore, Check, Pause } from '@mui/icons-material'

import { Footer, ECreditsNavbar } from '../../common'
import { StatisticsCard } from '../common'
import Description from './Description'

import { PRIMARY_COLOR, GREEN_COLOR, ORANGE_COLOR, GRAY_TEXT_COLOR } from '../../../utils/constants'

export default () => {
    return (
        <>
            <ECreditsNavbar title="Pagina Principala" />
            <Container maxWidth='xl'>
                <Grid container pt={5} spacing={2}>
                    <Grid item xs={12}>
                        <Typography fontWeight="bold" variant="h6">
                            Dashboard
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} lg={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <StatisticsCard
                                            backgroundColor={PRIMARY_COLOR}
                                            textColor='white'
                                            icon={<People fontSize='large' sx={{ color: 'white' }} />}
                                            title='Employees'
                                            subtitle={'1600 employees'}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <StatisticsCard
                                            backgroundColor={GREEN_COLOR}
                                            textColor={'white'}
                                            icon={<CreditScore fontSize='large' sx={{ color: 'white' }} />}
                                            title='eCredits'
                                            subtitle={'500 eCredits available'}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <StatisticsCard
                                            textColor={PRIMARY_COLOR}
                                            icon={<Check fontSize='large' sx={{ color: PRIMARY_COLOR }} />}
                                            title='Active Employees'
                                            subtitle={'300 active'}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <StatisticsCard
                                            backgroundColor={ORANGE_COLOR}
                                            textColor={'white'}
                                            icon={<Pause fontSize='large' sx={{ color: 'white' }} />}
                                            title='Inactive Employees'
                                            subtitle={'1300 inactive'}
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Description />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}