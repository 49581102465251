import React, { useEffect, useState } from 'react'
import {
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    Link,
    TextField,
    Box,
    IconButton,
    Autocomplete
} from '@mui/material'
import { KeyboardArrowLeft, Delete, Close } from '@mui/icons-material'
import { COMPANIES_TRAINERS_REQUEST_STATUS, GRAY_BG_COLOR, GRAY_TEXT_COLOR, SECONDARY_TEXT_COLOR } from '../../../utils/constants'
import FavouritTrainer from '../../BusinessProfile/subcomponents/subcomponents/subcomponents/FavouritTrainer'
import { useAuthentication } from '../../../context/auth-context'

import * as COMPANY_REQUESTS from '../../../api/company'
import { useTranslation } from 'react-i18next'

const CalendarEventTrainers = ({ trainers, handleDeleteTrainer, handleAddTrainer, handleClose }) => {
    const { t } = useTranslation()
    const { user } = useAuthentication()

    const [users, setUsers] = useState([])

    useEffect(() => {
        handleGetTrainers()
    }, [])

    const handleGetTrainers = async () => {
        try {
            const company = await COMPANY_REQUESTS.getBusiness(user.companyId)
            setUsers(
                company.Users.filter(
                    (el) =>
                        el.Companies &&
                        el.Companies[el.Companies.findIndex(comp => comp.id === company.id)] &&
                        el.Companies[el.Companies.findIndex(comp => comp.id === company.id)].Companies_Trainers.status ===
                        COMPANIES_TRAINERS_REQUEST_STATUS.APPROVED
                )
            )
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <DialogTitle
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                p={4}
                pl={{ xs: 2, md: 2, lg: 3 }}
            >
                <Grid container display="flex" flexDirection="row">
                    <Grid item lg={10} xs={10} display="flex" flexDirection="row" alignItems="center">
                        <IconButton onClick={handleClose}> <KeyboardArrowLeft fontSize="medium" /> </IconButton>
                        <Typography variant="h5" fontWeight="bold" pl={2}>
                            {t('calendar.manageTrainers')}
                        </Typography>
                    </Grid>
                    <Grid item lg={2} xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                        <IconButton style={{ color: 'black' }} onClick={handleClose}>
                            <Close fontSize="medium" color="inherit" />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid pt={2} pb={8} item lg={12}>
                    <Grid item xs={12} pb={4}>
                        <Grid container display="flex" flexDirection="column">
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={users
                                        .filter((el) => !trainers.map(par => par.id).includes(el.id))
                                        .map((el) => ({
                                            label: `${el.firstName} ${el.lastName}`,
                                            avatar: el.avatarPhotoSrc,
                                            phoneNumber: el.phoneNumber,
                                            value: el.id,
                                            firstName: el.firstName,
                                            lastName: el.lastName
                                        }))}
                                    autoHighlight
                                    placeholder={t('calendar.searchTrainers')}
                                    renderOption={(props, option) => {
                                        return (
                                            <Box {...props} sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    {option.avatar ? (
                                                        <img
                                                            style={{ borderRadius: 25 }}
                                                            width={50}
                                                            height={50}
                                                            src={option.avatar}
                                                        />
                                                    ) : (
                                                        <Box
                                                            sx={{
                                                                width: 50,
                                                                height: 50,
                                                                backgroundColor: GRAY_BG_COLOR,
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                borderRadius: 25
                                                            }}
                                                        >
                                                            <Typography fontWeight="bold" color={GRAY_TEXT_COLOR}>
                                                                {option.firstName.charAt(0)} {option.lastName.charAt(0)}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 1 }}>
                                                    <Typography fontWeight={500}>{option.label}</Typography>
                                                    <Typography color={GRAY_TEXT_COLOR}>{option.phoneNumber}</Typography>
                                                </Box>
                                            </Box>
                                        )
                                    }}
                                    onChange={(_, newValue) => {
                                        if (!newValue) return

                                        return handleAddTrainer(newValue.value)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            placeholder={'Search trainers'}
                                            {...params}
                                            InputProps={{ ...params.InputProps, endAdornment: null }}
                                            inputProps={{
                                                ...params.inputProps,
                                                style: {
                                                    marginLeft: 6,
                                                    padding: 11.5,
                                                    color: SECONDARY_TEXT_COLOR,
                                                    fontWeight: 500
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item pt={4} xs={12}>
                                <Grid container flexWrap="wrap" display="flex">
                                    {trainers.map((el, index) => {
                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                                lg={6}
                                                md={6}
                                                pb={2}
                                                key={index}
                                                display="flex"
                                                flexDirection="row"
                                            >
                                                <Grid xs={12} md={12} lg={6} item>
                                                    <Link
                                                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                                                        to={'/' + el.domain}
                                                    >
                                                        <FavouritTrainer
                                                            firstName={el.firstName}
                                                            lastName={el.lastName}
                                                            email={el.email}
                                                            phoneNumber={el.phoneNumber}
                                                            type={'participant'}
                                                            avatarPhotoSrc={el.avatarPhotoSrc}
                                                        />
                                                    </Link>
                                                </Grid>

                                                <Grid
                                                    md={4}
                                                    xs={4}
                                                    lg={3}
                                                    item
                                                    display="flex"
                                                    flexDirection="row"
                                                    justifyContent="flex-end"
                                                    pr={3}
                                                >
                                                    <IconButton onClick={() => handleDeleteTrainer(el.id)} variant="text">
                                                        <Delete sx={{ color: 'black' }} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    )
}

export default CalendarEventTrainers