import React from 'react'
import { Grid, Card, Typography, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { KeyboardArrowDown, KeyboardArrowRight, Delete } from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom'
import Linkify from 'react-linkify'

import {
  LIGHT_GRAY_BORDER_COLOR,
  CARD_TEXT_COLOR,
  GREEN_COLOR
} from '../../../../../utils/constants'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  }
})

const Subscription = ({
  name,
  forServiceDetails,
  description,
  duration,
  price,
  expand,
  handleExpand,
  id,
  credits,
  isFree,
  view,
  onEdit,
  Trainers,
  handleOnDelete,
  isPartTime,
  startTime,
  endTime,
  fromCompany,
  currency
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  if (!expand)
    return (
      <Grid
        component={Card}
        className={classes.content}
        display="flex"
        flexDirection="row"
        alignItems="center"
        onClick={() => handleExpand(id)}
        pt={3}
        pb={3}
      >
        <Grid item xs={0.3} lg={0.3} pl={2} pt={1}>
          <Typography fontSize="large" color="primary">
            <KeyboardArrowRight />
          </Typography>
        </Grid>
        <Grid item xs={11} lg={9.2}>
          <Typography
            sx={{ cursor: 'pointer' }}
            pl={3}
            variant="h6"
            fontWeight={500}
            display="flex"
            flexDirection="row"
          >
            {name}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={2.5} pr={2} pl={{ lg: 2, xs: 3 }}>
          <Typography color={isFree || price === 0 ? GREEN_COLOR : 'primary'} fontWeight="bold" variant="h6">
            {isFree || price === 0 ? 'FREE' : price ? `${price} ${currency}` : 'N/A'}
          </Typography>
        </Grid>
      </Grid>
    )

  return (
    <Grid container component={Card} className={classes.content}>
      <Grid
        xs={12}
        item
        borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
        display="flex"
        flexDirection={{ lg: 'row', xs: 'column' }}
        width="100%"
      >
        <Grid
          onClick={() => handleExpand(id)}
          item
          lg={7}
          borderRight={{
            xs: 'none',
            lg: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
          }}
          borderBottom={{
            xs: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
            lg: 'none'
          }}
          display="flex"
          alignItems="center"
          flexWrap="noWrap"
        >
          <Grid item xs={12} lg={9} display="flex" alignItems="center" flexWrap="noWrap">
            <KeyboardArrowDown color="primary" sx={{ paddingLeft: 2 }} />
            <Typography
              sx={{ cursor: 'pointer' }}
              p={1.5}
              pl={0}
              variant="h6"
              fontWeight={500}
              display="flex"
              flexDirection="row"
            >
              {name}
            </Typography>
          </Grid>
          {isPartTime && (
            <Grid
              item
              xs={12}
              lg={3}
              pr={{ xs: 0, lg: 4 }}
              pl={{ xs: 3, lg: 0 }}
              display="flex"
              alignItems={{ xs: 'flex-start', lg: 'flex-end' }}
              borderTop={{ xs: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`, lg: 'none' }}
              flexDirection="column"
              flexWrap="noWrap"
            >
              <Typography pt={2} color={CARD_TEXT_COLOR}>
                {t('userProfile.activity.trainerProgramComponent.access')}
              </Typography>
              <Typography pb={2} fontWeight={500} color="primary">
                {startTime + '-' + endTime}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item lg={5}>
          <Grid container display="flex" alignItems={{ xs: 'flex-start', lg: 'flex-end' }}>
            <Grid xs={12} lg={6} item borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
              <Grid
                xs={12}
                item
                display="flex"
                flexDirection="column"
                pl={{ lg: 3, xs: 3 }}
                alignItems={{ lg: 'flex-start', xs: 'flex-start' }}
                justifyContent="center"
              >
                <Typography pt={2} color={CARD_TEXT_COLOR}>
                  {t('userProfile.activity.trainerProgramComponent.duration')}
                </Typography>
                <Typography pb={2} fontWeight={500} color="primary">
                  {duration}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={12} lg={6} item borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
              <Grid
                xs={12}
                item
                display="flex"
                flexDirection="column"
                pr={{ lg: 3, xs: 3 }}
                pl={{ xs: 3, lg: 0 }}
                alignItems={{ lg: 'flex-end', xs: 'flex-start' }}
                justifyContent="center"
              >
                <Typography pt={2} color={CARD_TEXT_COLOR}>
                  {t('userProfile.activity.trainerProgramComponent.credits').toUpperCase()}
                </Typography>
                <Typography pb={2} fontWeight={500} color="primary">
                  {credits ? credits : 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item display="flex" flexDirection={{ xs: 'column', lg: 'row' }} width="100%">
        <Grid lg={7} item borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
          <Grid flexWrap="noWrap" p={3} display="flex" flexDirection="column">
            <Typography color={CARD_TEXT_COLOR}>{t('userProfile.activity.description')}</Typography>
            <Linkify>
              <Typography fontWeight={500}>{description}</Typography>
            </Linkify>
          </Grid>
        </Grid>
        <Grid
          lg={5}
          pr={{ lg: 3, xs: 3 }}
          pl={{ lg: 3, xs: 3 }}
          pb={{ xs: 3, lg: 0 }}
          pt={1}
          item
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid container display="flex" flexDirection="row" pb={3}>
            <Grid xs={12} lg={6} item>
              <Typography color={CARD_TEXT_COLOR}>{t('userProfile.activity.price')}</Typography>
              <Typography pb={2} fontWeight={500} color={isFree || price === 0 ? GREEN_COLOR : 'primary'}>
                {(isFree || price === 0 ? 'FREE' : price ? price + ' ' + currency : 'N/A')}
              </Typography>
            </Grid>
            <Grid
              xs={12}
              lg={6}
              item
              display="flex"
              flexDirection="column"
              alignItems={{ lg: 'flex-end' }}
            >
              <Typography color={CARD_TEXT_COLOR}>{t('userProfile.activity.trainer')}</Typography>
              {Trainers && Trainers.length
                ? Trainers.map(({ firstName, lastName, domain }, index) => {
                  return (
                    <Link key={index} style={{ textDecoration: 'none' }} to={'/' + domain}>
                      <Typography
                        display="flex"
                        justifyContent={{ lg: 'flex-end', xs: 'flex-start' }}
                        sx={{ textDecoration: 'underline', textAlign: 'right' }}
                        fontWeight={600}
                        color="primary"
                      >{`${firstName} ${lastName}`}</Typography>
                    </Link>
                  )
                })
                : null}
              {!Trainers || (Trainers && !Trainers.length) ? (
                <Typography
                  display="flex"
                  justifyContent={{ lg: 'flex-end', xs: 'flex-start' }}
                  pb={2}
                  fontWeight={600}
                  color="primary"
                >
                  N / A
                </Typography>
              ) : null}
            </Grid>
          </Grid>
          {!view ? (
            <Grid
              container
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="flex-end"
              height="100%"
              pb={2}
            >
              {!fromCompany ? (
                <Button sx={{ minWidth: 120 }} variant="outlined" onClick={onEdit}>
                  {t('userProfile.activity.trainerProgramComponent.edit')}
                </Button>
              ) : null}
              {!fromCompany ? (
                <Button
                  sx={{ minWidth: 70, marginLeft: 1 }}
                  onClick={() => handleOnDelete(id)}
                  variant="outlined"
                  color="error"
                >
                  <Delete />
                </Button>
              ) : null}
            </Grid>
          ) : (
            <Grid
              container
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="flex-end"
              height="100%"
              pb={2}
            >
              {forServiceDetails ? null : (
                <Typography
                  variant="p"
                  color="primary"
                  fontWeight={600}
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  Go to Subscriptions
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Subscription
