/* eslint-disable no-unused-vars */
import { Close, Coffee, Message } from '@mui/icons-material'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    Box,
    IconButton,
    Avatar
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { PRIMARY_COLOR } from '../../../utils/constants'


const ListOfTrainersModal = ({ handleClose, handleConfirm, trainers, title, type, company }) => {
    const navigate = useNavigate()

    const trainersToUse = type === 'coffee' ? trainers.filter(el => el.paymentLink) : trainers

    return (
        <Dialog
            open={true}
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                p={4}
            >
                <Grid container display="flex" flexDirection="row">
                    <Grid item xs={10}>
                        <Typography variant="h6" fontWeight={600}>{title}</Typography>
                    </Grid>
                    <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                        <IconButton style={{ color: 'black' }} onClick={handleClose}>
                            <Close fontSize="medium" color="inherit" />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column' }} pb={2}>
                    {trainersToUse ? trainersToUse.map((trainer, index) => {
                        return (
                            <Grid container display="flex" flexDirection="row" key={index} mb={2}>
                                <Grid item xs={1}>
                                    <Avatar src={trainer.avatarPhotoSrc}>{trainer.firstName.charAt(0).toUpperCase()} {trainer.lastName.charAt(0).toUpperCase()}</Avatar>
                                </Grid>
                                <Grid item xs={10} pl={2} display="flex" alignItems="center">
                                    <Typography fontWeight={600} sx={{ cursor: 'pointer', textDecoration: 'underline', color: PRIMARY_COLOR }} onClick={() => navigate(`/${trainer.domain}`)} variant="body1">{trainer.firstName} {trainer.lastName}</Typography>
                                </Grid>
                                <Grid item xs={1} ml="auto">
                                    {type === "coffee" ? <IconButton style={{ color: 'black' }} onClick={() => {
                                        handleConfirm(null, null, null, trainer.paymentLink)
                                    }}>
                                        <Coffee fontSize="medium" color="primary" />
                                    </IconButton> : <IconButton style={{ color: 'black' }} onClick={() => {
                                        handleConfirm(`${trainer.firstName} ${trainer.lastName}`, trainer.id, 'trainer', null)
                                    }}>
                                        <Message fontSize="medium" color="primary" />
                                    </IconButton>}
                                </Grid>
                            </Grid>
                        )
                    }) : <Typography color="#979797" fontWeight={500} fontSize={17}>
                        No results found
                    </Typography>}
                    {type !== "coffee" && company && <Grid container display="flex" flexDirection="row" mb={2}>
                        <Grid item xs={1}>
                            <Avatar src={company.avatarPhotoSrc}>CO</Avatar>
                        </Grid>
                        <Grid item xs={10} pl={2} display="flex" alignItems="center">
                            <Typography fontWeight={600} sx={{ cursor: 'pointer', textDecoration: 'underline', color: PRIMARY_COLOR }} onClick={() => navigate(`/${company.domain}`)} variant="body1">{company.name}</Typography>
                        </Grid>
                        <Grid item xs={1} ml="auto">
                            <IconButton style={{ color: 'black' }} onClick={() => {
                                handleConfirm(company.name, company.id, 'company', null)
                            }}>
                                <Message fontSize="medium" color="primary" />
                            </IconButton>
                        </Grid>
                    </Grid>}
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default ListOfTrainersModal
