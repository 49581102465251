import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import {Grid, Container, Typography, Card, CardContent} from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useProductFruitsApi } from 'react-product-fruits'

import {
  BUSINESS_ROLES,
  ROLE_BUSINESS_ADMINISTRATOR,
  ROLE_TRAINER,
  ROLE_USER
} from '../../utils/constants'
import {
  AssociationRequests
} from './subcomponents'
import {Confirm, VerticalBarChart} from '../../components/common'
import { Overview, MembershipsSold, UserSubscriptions, Events } from './subcomponents'
import CheckIn from '../ChckInOut/subcomponents/CheckIn'
import SelfInOut from '../Selfin/Success'

import { useAuthentication } from '../../context/auth-context'
import * as TRAINER_REQUESTS from '../../api/trainer'
import * as CHECKIN_REQUESTS from '../../api/checkin'

const Dashboard = () => {
  const { user } = useAuthentication()
  const { t } = useTranslation()

  const isTrainer = user && user.role && user.role.toLowerCase() === ROLE_TRAINER.toLowerCase()
  const isClubAdministrator =
    user && user.role && user.role.toLowerCase() === BUSINESS_ROLES.BUSINESS_EMPLOYEE.toLowerCase()
  const isUser = user && user.role && user.role.toLowerCase() === ROLE_USER.toLowerCase()
  const isClub =
    user && user.role && user.role.toLowerCase() === ROLE_BUSINESS_ADMINISTRATOR.toLowerCase()

  const [associationRequests, setAssociationRequests] = useState([])
  const [activeCheckIns, setActiveCheckIns] = useState([])
  const [selectedCheckIn, setSelectedCheckIn] = useState()
  const [showConfirmCheckOut, setShowConfirmCheckOut] = useState(false)
  const [showSelfIn, setShowSelfIn] = useState(false)
  const [showSelfOut, setShowSelfOut] = useState(false)
  const [relayId, setRelayId] = useState()

  const checklistRef = useRef(null);


  const getAssociatedGyms = () => {
    return TRAINER_REQUESTS.getAssociatedGyms().then((associatedGyms) => {
      setAssociationRequests(associatedGyms)
    })
  }

  const getActiveCheckIns = () => {
    // TODO: This param needs to be refactored, companyId not mandatory anymore
    return CHECKIN_REQUESTS.getActive('12121')
      .then(response => {
        setActiveCheckIns(response)

      })
      .catch(e => {
        console.error(e)
      })
  }

  const onCheckOutHandler = useCallback((checkInId) => {
    return () => {
      return CHECKIN_REQUESTS.update(checkInId, { checkedOut: true }).then(getActiveCheckIns)
    }
  }, [])

  useEffect(() => {
    if (user.role.toLowerCase() === ROLE_TRAINER.toLowerCase()) {
      getAssociatedGyms()
    }

    if (user.role.toLowerCase() === ROLE_USER.toLowerCase()) {
      getActiveCheckIns()
    }
  }, [user])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (window.productFruits && window.productFruits.api) {
  //       clearInterval(interval);
  //       window.elem = checklistRef.current;
  //       debugger;
  //       window.productFruits.api.checklists.injectToElement(3166, checklistRef.current);
  //     }
  //   }, 1000);
  //
  //   return () => clearInterval(interval);
  // }, []);

  useProductFruitsApi(api => {
    if (api.checklists.length > 0) {
      api.checklists.injectToElement(3166, checklistRef.current);
    }
  }, []);


  const onUpdateAssociationRequestStatusHandler = (id, status) => {
    return TRAINER_REQUESTS.updateAssociationStatus(id, status).then(getAssociatedGyms)
  }

  const renderDashboard = useMemo(() => {
    const isTrainer = user && user.role === ROLE_TRAINER
    const isLoggedInUser = user && user.role === ROLE_USER

    return (
      <Grid container height="100%" width="100%" flexDirection="column">
        <Grid item xs={12} paddingBottom={5}>
          <Overview isTrainer={isTrainer} isClub={isClub || isClubAdministrator} isUser={isUser} />
        </Grid>
        {(isClub || isTrainer || isClubAdministrator) ? (
          <Grid container display="flex" flexDirection="row" paddingBottom={{ xs: 9, lg: 4 }} >
            <Grid xs={12} md={6} lg={6} pr={{ xs: 0, md: 0, lg: isTrainer ? 1 : 0 }}>
              <MembershipsSold />
            </Grid>
            <Grid xs={12} md={12} lg={6} paddingLeft={1.5}>
              <Grid item xs={12} paddingBottom={1.5}>
                <Typography variant="p" fontWeight={'bold'} fontSize={20}>
                  {t('dashboard.overview.gettingStarted')}
                </Typography>
              </Grid>
              <Card
                  sx={{
                    boxShadow: '0 13px 33px 0 rgba(41, 40, 56, 0.08)',
                    border: '1px solid #F0F0F0',
                    borderRadius: 2
                  }}
              >
                <CardContent
                    ref={checklistRef}
                    id="product-fruits-getting-started"
                    sx={{
                      minHeight: 250,
                      overflow: 'scroll'
                    }}
                >

                </CardContent>
              </Card>
            </Grid>
            {isTrainer ? <Grid xs={12} md={12} lg={6} pl={{ xs: 0, md: 0, lg: isTrainer ? 1 : 0 }} pt={{ xs: 9, lg: 0, md: 0 }}>
              <AssociationRequests
                data={associationRequests}
                onUpdateStatusHandler={onUpdateAssociationRequestStatusHandler}
              />
            </Grid> : null}
          </Grid>
        ) : null}
        {isLoggedInUser ? (
          <Grid container display="flex" flexDirection="row" paddingBottom={{ xs: 9, lg: 4 }} >
            <Grid item xs={12} md={12} lg={12} pr={{ xs: 0, md: 0, lg: isUser ? 2 : 0 }}>
              {activeCheckIns.length ? <Grid container pb={4}>
                <Grid item xs={12} paddingBottom={1}>
                  <Typography variant="p" fontWeight={'bold'} fontSize={20}>
                    {t('helper.activeCheckIn')}
                  </Typography>
                </Grid>
                {activeCheckIns.map((el, index) => {
                  const checkedInTime = moment(new Date(el.createdAt))
                  const currentTime = moment(new Date())
                  const duration = moment.duration(currentTime.diff(checkedInTime)).asHours()
                  const name = user ? `${user.firstName} ${user.lastName}` : '-'

                  if (!el.Event) {
                    return (
                      <Grid item xs={12} key={index}>
                        <CheckIn
                          name={name}
                          warning={!el.checkedOut && duration > 1}
                          limitedActivationTotal={el.UserSubscription.limitedActivationTotal}
                          limitedActivationUsed={el.limitedActivation}
                          time={moment(el.createdAt).format('HH:mm')}
                          keyNumber={el.keyNumber}
                          checkedOutAt={el.checkedOutAt}
                          subscriptionName={el.UserSubscription.Subscription.name}
                          informations={el.description}
                          phoneNumber={el.UserSubscription.User.phoneNumber}
                          onCheckOut={() => {
                            setSelectedCheckIn({ id: el.id, name })
                            setShowConfirmCheckOut(true)
                          }}
                          checkedOut={el.checkedOut}
                          noDelete
                          disabledCheckOut={duration < 1}
                        />
                      </Grid>
                    )
                  }

                  return (
                    <Grid item xs={12} key={index}>
                      <CheckIn
                        name={name}
                        warning={!el.checkedOut && duration > 1}
                        time={moment(el.createdAt).format('HH:mm')}
                        keyNumber={el.keyNumber}
                        isEvent
                        limitedActivationTotal={el.UserSubscription ? el.UserSubscription.limitedActivationTotal : ''}
                        limitedActivationUsed={el.limitedActivation}
                        checkedOutAt={el.checkedOutAt}
                        subscriptionName={el.Event ? el.Event.name : '-'}
                        informations={el.description}
                        phoneNumber={el.User ? el.User.phoneNumber : '-'}
                        onCheckOut={() => {
                          setSelectedCheckIn({ id: el.id, name })
                          setShowConfirmCheckOut(true)
                        }}
                        checkedOut={el.checkedOut}
                        noDelete
                        disabledCheckOut={duration < 1}
                      />
                    </Grid>
                  )
                })}
              </Grid> : null}
            </Grid>
            <Grid container display="flex" flexDirection="row" paddingBottom={{ xs: 9, lg: 4 }} >
              <Grid item xs={12} md={12} lg={7} pr={{ xs: 0, md: 0, lg: isUser ? 2 : 0 }}>
                <UserSubscriptions />
              </Grid>
              <Grid item xs={12} md={12} lg={5} pl={{ xs: 0, md: 0, lg: isUser ? 2 : 0 }}>
                <Events />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    )
  }, [isTrainer, isUser, isClub, isClubAdministrator, associationRequests, user, activeCheckIns])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    setShowSelfIn(params.get('selfIn') === 'show')
    setShowSelfOut(params.get('selfOut') === 'show')

    setTimeout(() => {
      setShowSelfIn(false)
      setShowSelfOut(false)
    }, 10000)

    const notParsedRelayId = params.get('relayId')

    if (notParsedRelayId) {
      setRelayId(Number(notParsedRelayId))
    }
  }, [window.location.search])

  const renderSelfInModals = useMemo(() => {
    if (!isUser && !isTrainer) return null

    return (
      <>
        {showSelfIn ? <SelfInOut
          selfIn
          relayId={relayId}
          onCancel={() => setShowSelfIn(false)}
        /> : null}
        {showSelfOut ? <SelfInOut
          selfOut
          relayId={relayId}
          onCancel={() => setShowSelfOut(false)}
        /> : null}
      </>
    )
  }, [showSelfIn, showSelfOut, relayId, isUser, isTrainer])

  return (
    <>
      {renderSelfInModals}
      {showConfirmCheckOut && (
        <Confirm
          titleText={t('checkInFilters.confirmCheckOutTitle')}
          description={t('checkInFilters.confirmCheckOut', { name: selectedCheckIn.name })}
          cancelText={t('button.cancel')}
          confirmButtonColor="primary"
          confirmText={t('checkInFilters.confirmCheckOutButton')}
          onCancel={() => {
            setShowConfirmCheckOut(false)
            setSelectedCheckIn()
          }}
          onConfirm={onCheckOutHandler(selectedCheckIn.id)}
        />
      )}
      <Container maxWidth="xl">
        <Grid
          container
          pt={8}
          pb={8}
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <Grid item>
            {renderDashboard}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Dashboard
