import React, { useEffect, useState } from 'react'
import { Container, Grid, Typography, Card, Box, TextField, InputAdornment } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DisplaySettings, Timer, VolumeUp } from '@mui/icons-material'

import { Toggle } from '../common'
import { GRAY_TEXT_COLOR, PRIMARY_COLOR } from '../../utils/constants'

import * as COMPANY_REQUESTS from '../../api/company'

export default () => {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(true)
    const [configurations, setConfigurations] = useState()
    const [isTouched, setIsTouched] = useState(false)

    useEffect(() => {
        COMPANY_REQUESTS.getConfigurations()
            .then(response => {
                setConfigurations(response)
                setLoading(false)
            })
    }, [])

    const onConfigurationChangHandler = () => {
        const { id, ...payload } = configurations
        return COMPANY_REQUESTS.updateConfiguration(id, payload)
            .then(() => {
                setIsTouched(false)
            })
    }

    useEffect(() => {
        if (configurations && isTouched) {
            onConfigurationChangHandler()
        }
    }, [configurations, isTouched])

    if (loading) {
        return null
    }

    return (
        <>
            <Container maxWidth="md">
                <Grid container pt={5} spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5" fontWeight="bold">
                            {t('configurations.title')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="p" color={GRAY_TEXT_COLOR}>
                            {t('configurations.subtitle')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container component={Card} p={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <DisplaySettings fontSize="large" /> <Typography pl={1} variant="h6" fontWeight="Bold">{t('configurations.detailedSelfin')}</Typography>
                                    </Box>
                                    <Typography pt={1} variant="p" color={GRAY_TEXT_COLOR}>
                                        {t('configurations.detailedSelfinDescription')}
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} pt={1}>
                                        <Toggle
                                            defaultChecked={configurations.detailedSelfinOn}
                                            onClick={() => {
                                                setConfigurations({ ...configurations, detailedSelfinOn: !configurations.detailedSelfinOn })
                                                setIsTouched(true)
                                            }}
                                        />
                                        <Typography pl={1} variant="h6" fontWeight="bold" color={configurations.detailedSelfinOn ? 'primary' : GRAY_TEXT_COLOR}>{configurations.detailedSelfinOn ? t('configurations.disable') : t('configurations.enable')}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container component={Card} p={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Timer fontSize="large" /> <Typography pl={1} variant="h6" fontWeight="Bold">{t('configurations.autoSelfOut')}</Typography>
                                    </Box>
                                    <Typography pt={1} variant="p" color={GRAY_TEXT_COLOR}>
                                        {t('configurations.autoSelfOutDescription')}
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} pt={1}>
                                        <Toggle
                                            defaultChecked={configurations.autoCheckOutCronOn}
                                            onClick={() => {
                                                setIsTouched(true)
                                                setConfigurations({ ...configurations, autoCheckOutCronOn: !configurations.autoCheckOutCronOn })
                                            }}
                                        />
                                        <Typography pl={1} variant="h6" fontWeight="bold" color={configurations.autoCheckOutCronOn ? 'primary' : GRAY_TEXT_COLOR}>{configurations.autoCheckOutCronOn ? t('configurations.disable') : t('configurations.enable')}</Typography>
                                    </Box>
                                    {configurations.autoCheckOutCronOn && (
                                        <Box display="flex" flexDirection="column" pt={1}>
                                            <Typography pb={1} fontWeight={500}>Checkout After</Typography>
                                            <TextField InputProps={{
                                                endAdornment: <InputAdornment position="start">
                                                    <Typography variant="p" fontWeight="bold" color={PRIMARY_COLOR}>
                                                        {t('calendar.hours')}
                                                    </Typography>
                                                </InputAdornment>
                                            }} name="autoCheckOutHours" defaultValue={configurations.checkoutAfterHours} onChange={e => {
                                                setIsTouched(true)
                                                setConfigurations({
                                                    ...configurations, checkoutAfterHours: Number(e.target.value)
                                                })
                                            }} />
                                        </Box>
                                    )}
                                </Grid>

                            </Grid>

                            <Grid item xs={12}>
                                <Grid container component={Card} p={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <VolumeUp fontSize="large" /> <Typography pl={1} variant="h6" fontWeight="Bold">{t('configurations.volume')}</Typography>
                                    </Box>
                                    <Typography pt={1} variant="p" color={GRAY_TEXT_COLOR}>
                                        {t('configurations.volumeDescription')}
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} pt={1}>
                                        <Toggle
                                            defaultChecked={configurations.wlecomeGoodbySoundOn}
                                            onClick={() => {
                                                setIsTouched(true)
                                                setConfigurations({ ...configurations, wlecomeGoodbySoundOn: !configurations.wlecomeGoodbySoundOn })
                                            }}
                                        />
                                        <Typography pl={1} variant="h6" fontWeight="bold" color={configurations.wlecomeGoodbySoundOn ? 'primary' : GRAY_TEXT_COLOR}>{configurations.wlecomeGoodbySoundOn ? t('configurations.disable') : t('configurations.enable')}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container >
        </>
    )
}