import React, { useEffect, useState } from 'react'
import {
    Grid,
    Card,
    Typography,
    TextField,
    Tooltip,
    Button,
    Container,
    FormControl,
    Select,
    MenuItem,
    CircularProgress,
    Box
} from '@mui/material'
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'
import validator from 'validator'

import { InputGenerator, Toggle } from '../common'
import * as USER_REQUESTS from '../../api/auth'
import * as customValidators from '../../utils/helpers/validator'
import * as ORGANIZATION_REQUESTS from '../../api/organization'

import { useAuthentication } from '../../context/auth-context'
import { LIGHT_GRAY_BORDER_COLOR, DEFAULT_MAX_LENGTH, ROLE_ORGANIZATION } from '../../utils/constants'
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';

const OrganizationSettings = () => {
    const { t } = useTranslation()
    const { user: authUser } = useAuthentication()
    const [user, setUser] = useState()
    const [registrationCertificateApproved, setRegistrationCertificateApproved] = useState()
    const [identityCardApproved, setIdentityCardApproved] = useState()
    const [organization, setOrganization] = useState()
    const [showChangePassword, setShowChangePassword] = useState(false)
    const [loading, setLoading] = useState(true)
    const [passwordFields, setPasswordFields] = useState({
        currentPassword: {
            value: null,
            hasError: false,
            isTouched: false,
            errorText: t('settings.errorTextPassword')
        },
        password: {
            value: null,
            hasError: false,
            isTouched: false,
            errorText: t('settings.errorTextPassword')
        },
        confirmPassword: {
            value: null,
            hasError: false,
            isTouched: false,
            errorText: t('settings.errorTextPassword')
        }
    })

    const onPasswordChangeHandler = (name, value) => {
        setPasswordFields({
            ...passwordFields,
            [name]: {
                ...passwordFields[name],
                isTouched: true,
                value,
                hasError: value.length < 6 || value === user.email.value
            }
        })
    }

    const onPasswordSaveHandler = () => {
        return USER_REQUESTS.changePassword(
            passwordFields.currentPassword.value,
            passwordFields.confirmPassword.value,
            passwordFields.password.value
        )
            .then(() => {
                setShowChangePassword(false)
                setPasswordFields({
                    currentPassword: {
                        value: null,
                        hasError: false,
                        isTouched: false
                    },
                    password: {
                        value: null,
                        hasError: false,
                        isTouched: false
                    },
                    confirmPassword: {
                        value: null,
                        hasError: false,
                        isTouched: false
                    }
                })
                toast.success(t('helper.passwordUpdatedSuccessfully'), { position: 'bottom-right' })
            })
            .catch(() => {
                toast.error(t('helper.passwordNotUpdated'), { position: 'bottom-right', autoClose: false })
            })
    }

    const isPasswordDisabled = () => {
        return (
            !Object.keys(passwordFields).some((key) => passwordFields[key].isTouched) ||
            Object.keys(passwordFields).some((key) => passwordFields[key].hasError)
        )
    }

    const getMe = () => {
        return USER_REQUESTS.getMe().then((res) =>
            setUser({
                firstName: {
                    value: res.firstName,
                    isTouched: false,
                    hasError: false,
                    errorText: t('settings.errorEmpty')
                },
                lastName: {
                    value: res.lastName,
                    isTouched: false,
                    hasError: false,
                    errorText: t('settings.errorEmpty')
                },
                email: {
                    value: res.email,
                    isTouched: false,
                    hasError: false,
                    errorText: t('settings.errorEmail')
                },
                phoneNumber: {
                    value: res.phoneNumber,
                    isTouched: false,
                    hasError: false,
                    errorText: t('settings.errorPhoneNumber')
                },
                calendarReminder: {
                    value: res.calendarReminder,
                    isTouched: false
                },
                newsletter: {
                    value: res.newsletter,
                    isTouched: false
                },
                newMessages: {
                    value: res.newsletter,
                    isTouched: false
                }
            })
        )
    }

    const isDisabled = () => {
        let userError =
            Object.keys(user).every((key) => user[key].isTouched === false) ||
            Object.keys(user).some((key) => user[key].hasError === true)
        let organizationError =
            Object.keys(organization).every((key) => organization[key].isTouched === false) ||
            Object.keys(organization).some((key) => organization[key].hasError === true)
        let emptyError = !user.phoneNumber.value || !user.email.value || !user.firstName.value || !user.lastName.value

        if (emptyError) return true

        return !userError || !organizationError ? false : true
    }

    const updateHandler = async () => {
        const userData = {}
        const organizationData = {}

        Object.keys(user).forEach((key) => {
            if (user[key].isTouched && user[key].value) {
                userData[key] = user[key].value
            }
        })

        Object.keys(organization).forEach((key) => {
            if (organization[key].isTouched && organization[key].value) {
                organizationData[key] = organization[key].value
            }
        })

        if (organizationData.city || organizationData.country) {
            organizationData.locationName = `${organization.city.value}, ${organization.country.value}`

            try {
                await geocodeByAddress(organizationData.locationName)
                    .then(result => {
                        getLatLng(result[0])
                            .then(({ lat, lng }) => {
                                organizationData['locationLat'] = String(lat);
                                organizationData['locationLng'] = String(lng);
                            })
                    })
            } catch (error) {
                return toast.error(t('helper.countryOrCityNotValid'), { position: 'bottom-right', autoClose: false })
            }
        }

        return await Promise.all([
            USER_REQUESTS.updateUser(userData),
            ORGANIZATION_REQUESTS.updateOrganization(authUser.id, organizationData)
        ])
            .then(() => {
                toast.success(t('helper.settingsUpdateSuccessfully'), { position: 'bottom-right' })
            })
            .catch((e) => {
                console.error(e)
                getMe()
                getOrganization()
                toast.error(t('helper.settingsNotUpdated'), { position: 'bottom-right', autoClose: false })
            })
    }

    const onChangeHandler = (name, value) => {
        const computeError = (name) => {
            if (name === 'firstName' || name === 'lastName' || name === 'domain') {
                return !customValidators.isValidText(value)
            } else if (name === 'email') {
                return !customValidators.isValidEmail(value)
            } else if (name === 'phoneNumber') {
                return !customValidators.isValidPhone(value)
            }

            return false
        }

        setUser({
            ...user,
            [name]: {
                ...user[name],
                isTouched: true,
                value,
                hasError: computeError(name)
            }
        })
    }

    const onOrganizationChangeHandler = async (name, value, placeId) => {
        const computeError = (name) => {
            if (name === 'brandName' || name === 'name') {
                return !customValidators.isValidText(value)
            }

            if (name === 'bankAccount') {
                return !validator.isIBAN(value)
            }

            return false
        }

        if (name === 'country') {
            let countryCode;
            await geocodeByPlaceId(placeId)
              .then(result => countryCode = result[0].address_components[0].short_name)
            
            if (!countryCode) return;
      
            return setOrganization({
              ...organization,
              [name]: {
                isTouched: true,
                value,
                hasError: false
              },
              ['city']: {
                isTouched: true,
                value: '',
                countryCode
              }
            })
          }

        setOrganization({
            ...organization,
            [name]: {
                ...organization[name],
                isTouched: true,
                value,
                hasError: computeError(name)
            }
        })
    }

    const renderPaymentInfo = () => {
        if (authUser.role !== ROLE_ORGANIZATION || !organization) {
            return null
        }

        return (
            <>
                <Grid item pt={6}>
                    <Typography variant="h6" fontWeight="bold">
                        {t('helper.paymentInformation')}
                    </Typography>
                </Grid>

                <Grid display="flex" flexDirection="column" container pt={3} width="100%">
                    <Grid item xs={12}>
                        <Grid container display="flex" flexDirection={'row'}>
                            <Grid item xs={12} lg={4} pr={{ xs: 0, lg: 2 }} pb={{ xs: 2, lg: 0 }}>
                                <FormControl fullWidth>
                                    <Typography pb={0.5} fontWeight={500}>{t('checkout.companyBankName')}</Typography>
                                    <Select
                                        value={organization.bankName.value}
                                        placeholder={t('checkout.companyBankName')}
                                        onChange={(e) => onOrganizationChangeHandler('bankName', e.target.value)}
                                    >
                                        <MenuItem value={'Banca Transilvania'}>Banca Transilvania</MenuItem>
                                        <MenuItem value={'Banca Comerciala Romana'}>Banca Comerciala Romana</MenuItem>
                                        <MenuItem value={'BRD - Groupe Societe Generale'}>BRD - Groupe Societe Generale</MenuItem>
                                        <MenuItem value={'ING Bank'}>ING Bank</MenuItem>
                                        <MenuItem value={'Raiffeisen Bank'}>Raiffeisen Bank</MenuItem>
                                        <MenuItem value={'UniCredit Bank'}>UniCredit Bank</MenuItem>
                                        <MenuItem value={'CEC Bank'}>CEC Bank</MenuItem>
                                        <MenuItem value={'Alpha Bank'}>Alpha Bank</MenuItem>
                                        <MenuItem value={'OTP Bank'}>OTP Bank</MenuItem>
                                        <MenuItem value={'EximBank'}>EximBank</MenuItem>
                                        <MenuItem value={'Citibank'}>Citibank</MenuItem>
                                        <MenuItem value={'Garanti BBVA'}>Garanti BBVA</MenuItem>
                                        <MenuItem value={'Libra Internet Bank'}>Libra Internet Bank</MenuItem>
                                        <MenuItem value={'First Bank'}>First Bank</MenuItem>
                                        <MenuItem value={'Banca Romaneasca'}>Banca Romaneasca</MenuItem>
                                        <MenuItem value={'Intensa Sanpaolo'}>Intensa Sanpaolo</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={8} pl={{ xs: 0, lg: 2 }}>
                                <Typography pb={0.5} fontWeight={500}>{t('checkout.companyBankAccount')}</Typography>
                                <TextField
                                    inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                                    value={organization.bankAccount.value}
                                    error={organization.bankAccount.hasError}
                                    placeholder={t('checkout.companyBankAccount')}
                                    onChange={(e) =>
                                        onOrganizationChangeHandler('bankAccount', e.target.value)
                                    }
                                    fullWidth
                                    type="text"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid display="flex" flexDirection="column" container width="100%">
                    <Grid item xs={12} pb={3} pt={3}>
                        <InputGenerator
                            handleOnChange={(e) => onOrganizationChangeHandler('billingAddress', e.target.value)}
                            input={{
                                type: 'text',
                                label: t('helper.billingAddress'),
                                placeholder: t('helper.billingAddress'),
                                value: organization.billingAddress ? organization.billingAddress.value : ''
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container display="flex" flexDirection={'row'}>
                            <Grid item xs={6} pr={2}>
                                <InputGenerator
                                    handleOnChange={(e) => onOrganizationChangeHandler('cui', e.target.value)}
                                    input={{
                                        type: 'text',
                                        label: t('helper.CUI'),
                                        placeholder: t('helper.CUI'),
                                        value: organization.cui.value
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={6}
                                item
                                alignItems="center"
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                                pt={3}
                                pl={2}
                            >
                                <Grid item pr={1}>
                                    <Toggle
                                        color="primary"
                                        defaultChecked={organization.vat.value}
                                        onClick={() => onOrganizationChangeHandler('vat', !organization.vat.value)}
                                    />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography fontWeight={600} variant="p">
                                        {t('helper.vatPayer')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </>
        )
    }

    const getOrganization = async () => {
        return await ORGANIZATION_REQUESTS.getOrganization(authUser.organizationId).then(async (res) => {
            setRegistrationCertificateApproved(
                res.registrationCertificateSrc || res.registrationCertificateApproved
            )
            setIdentityCardApproved(res.identityCardSrc || res.identityCardApproved)
            setOrganization({
                name: {
                    value: res.name,
                    isTouched: false,
                    hasError: false,
                    errorText: t('settings.errorEmpty')
                },
                brandName: {
                    value: res.brandName,
                    isTouched: false,
                    hasError: false,
                    errorText: t('settings.errorEmpty')
                },
                instagramProfileUrl: {
                    value: res.instagramProfileUrl,
                    isTouched: false
                },
                facebookProfileUrl: {
                    value: res.facebookProfileUrl,
                    isTouched: false
                },
                city: {
                    value: res.city,
                    isTouched: false,
                    countryCode: res.country ? await geocodeByAddress(res.country).then(result => result[0].address_components[0].short_name) : ''
                },
                country: {
                    value: res.country,
                    isTouched: false
                },
                billingAddress: {
                    value: res.billingAddress,
                    isTouched: false
                },
                domain: {
                    value: res.domain,
                    isTouched: false,
                    hasError: false,
                    errorText: t('settings.errorEmpty')
                },
                bankName: {
                    value: res.bankName,
                    isTouched: false
                },
                bankAccount: {
                    value: res.bankAccount,
                    isTouched: false
                },
                cui: {
                    value: res.cui,
                    isTouched: false
                },
                vat: {
                    value: res.vat,
                    isTouched: false
                },
                customPrivacyPolicyLink: {
                    value: res.customPrivacyPolicyLink,
                    isTouched: false
                },
                customTermsAndConditionsLink: {
                    value: res.customTermsAndConditionsLink,
                    isTouched: false
                }
            })
            setLoading(false)
        })
    }

    useEffect(() => {
        getMe()
        getOrganization()
    }, [authUser])

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="50vh"
            >
                <CircularProgress />
            </Box>
        )
    }
    if (user) {
        return (
            <Container maxWidth="xl">
                <Grid
                    container
                    pt={5}
                    pb={5}
                    paddingLeft={{ xs: 0, lg: 12 }}
                    paddingRight={{ xs: 0, lg: 12 }}
                    display="flex"
                    flexDirection="column"
                    width="100%"
                >
                    <Grid
                        mt={{ xs: 4, lg: 0, md: 4 }}
                        mb={{ xs: 4, lg: 0, md: 0 }}
                        p={4}
                        component={Card}
                        container
                        border={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
                        flexDirection={{ xs: 'column' }}
                    >
                        <Grid container display="flex" flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}>
                            <Grid item xs={12} md={6} lg={6} pr={{ xs: 0, md: 8, lg: 8 }}>
                                <Grid container display="flex" flexDirection="column">
                                    <Grid item>
                                        <Typography variant="h6" fontWeight="bold">
                                            {t('settings.generalInformation')}
                                        </Typography>
                                    </Grid>

                                    <Grid container pt={3} width="100%">
                                        <Grid xs={12} md={6} lg={6} item pr={{ xs: 0, md: 2, lg: 2 }}>
                                            <Grid xs={12} item>
                                                <Typography fontWeight={500} variant="p">
                                                    {t('settings.firstName')}
                                                </Typography>
                                            </Grid>
                                            <Grid xs={12} pt={1} item>
                                                <TextField
                                                    inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                                                    error={user.firstName.hasError}
                                                    helperText={user.firstName.hasError && user.firstName.errorText}
                                                    value={user.firstName.value}
                                                    onChange={(e) => onChangeHandler('firstName', e.target.value)}
                                                    fullWidth
                                                    placeholder="John"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12} pt={{ xs: 2, md: 0, lg: 0 }} md={6} lg={6} item>
                                            <Grid xs={12} item>
                                                <Typography fontWeight={500} variant="p">
                                                    {t('settings.lastName')}
                                                </Typography>
                                            </Grid>
                                            <Grid xs={12} pt={1} item>
                                                <TextField
                                                    inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                                                    error={user.lastName.hasError}
                                                    helperText={user.lastName.hasError && user.lastName.errorText}
                                                    onChange={(e) => onChangeHandler('lastName', e.target.value)}
                                                    value={user.lastName.value}
                                                    fullWidth
                                                    placeholder="Doe"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container pt={{ xs: 2, lg: 3, md: 3 }}>
                                        <Grid xs={12} md={6} lg={6} item pr={2}>
                                            <Grid xs={12} item>
                                                <Typography fontWeight={500} variant="p">
                                                    {t('settings.email')}
                                                </Typography>
                                            </Grid>
                                            <Grid xs={12} pt={1} item>
                                                <TextField
                                                    inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                                                    error={user.email.hasError}
                                                    helperText={user.email.hasError && user.email.errorText}
                                                    value={user.email.value}
                                                    onChange={(e) => onChangeHandler('email', e.target.value)}
                                                    fullWidth
                                                    placeholder="John"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12} md={6} lg={6} item pt={{ xs: 2, md: 0, lg: 0 }}>
                                            <Grid xs={12} item>
                                                <InputGenerator
                                                    handleOnChangePhone={(value) => onChangeHandler('phoneNumber', value)}
                                                    input={{
                                                        type: 'phone',
                                                        label: t('settings.phoneNumber'),
                                                        error: user.phoneNumber.hasError,
                                                        helperText: user.phoneNumber.hasError && user.phoneNumber.errorText,
                                                        placeholder: '0728391121',
                                                        value: user.phoneNumber.value
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {!showChangePassword && (
                                        <Grid item pt={{ xs: 3, md: 6, lg: 6 }}>
                                            <Grid xs={12} lg={6} md={6} item>
                                                <Button
                                                    fullWidth
                                                    variant="outlined"
                                                    onClick={() => setShowChangePassword(true)}
                                                >
                                                    {t('settings.changePassword')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {showChangePassword && (
                                        <>
                                            <Grid
                                                mt={3}
                                                mb={3}
                                                borderRadius={3}
                                                p={3}
                                                display="flex"
                                                flexDirection="column"
                                                border={`2px solid ${LIGHT_GRAY_BORDER_COLOR}`}
                                            >
                                                <Grid item>
                                                    <Typography variant="h6" fontWeight="bold">
                                                        {t('settings.changePassword')}
                                                    </Typography>
                                                </Grid>

                                                <Grid container pt={3}>
                                                    <Grid xs={12} item>
                                                        <Grid xs={12} item>
                                                            <Typography fontWeight={500} variant="p">
                                                                {t('settings.password')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={12} pt={1} item>
                                                            <TextField
                                                                inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                                                                value={passwordFields.currentPassword.value}
                                                                error={passwordFields.currentPassword.hasError}
                                                                helperText={
                                                                    passwordFields.currentPassword.hasError &&
                                                                    passwordFields.currentPassword.errorText
                                                                }
                                                                onChange={(e) =>
                                                                    onPasswordChangeHandler('currentPassword', e.target.value)
                                                                }
                                                                fullWidth
                                                                type="password"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid container pt={{ xs: 2, lg: 3, md: 3 }}>
                                                    <Grid xs={12} md={6} lg={6} item pr={{ xs: 0, lg: 2, md: 2 }}>
                                                        <Grid xs={12} item>
                                                            <Typography fontWeight={500} variant="p">
                                                                {t('settings.newPassword')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={12} pt={1} item>
                                                            <TextField
                                                                inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                                                                value={passwordFields.password.value}
                                                                error={passwordFields.password.hasError}
                                                                helperText={
                                                                    passwordFields.password.hasError &&
                                                                    passwordFields.password.errorText
                                                                }
                                                                onChange={(e) => onPasswordChangeHandler('password', e.target.value)}
                                                                fullWidth
                                                                type="password"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid xs={12} md={6} lg={6} pt={{ xs: 2, lg: 0, md: 0 }} item>
                                                        <Grid xs={12} item>
                                                            <Typography fontWeight={500} variant="p">
                                                                {t('settings.confirmPassword')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={12} pt={1} item>
                                                            <TextField
                                                                inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                                                                value={passwordFields.confirmPassword.value}
                                                                error={passwordFields.confirmPassword.hasError}
                                                                helperText={
                                                                    passwordFields.confirmPassword.hasError &&
                                                                    passwordFields.confirmPassword.errorText
                                                                }
                                                                onChange={(e) =>
                                                                    onPasswordChangeHandler('confirmPassword', e.target.value)
                                                                }
                                                                fullWidth
                                                                type="password"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid container pb={{ xs: 1, lg: 0, md: 0 }} width="100%">
                                                    <Grid item xs={12} md={12} lg={12}>
                                                        <Grid
                                                            container
                                                            display="flex"
                                                            flexDirection={{ xs: 'row-reverse', lg: 'column', md: 'column' }}
                                                        >
                                                            <Grid
                                                                item
                                                                pt={{ xs: 2, lg: 3, md: 3 }}
                                                                xs={12}
                                                                md={6}
                                                                lg={6}
                                                                pr={{ xs: 0, lg: 2, md: 2 }}
                                                            >
                                                                <Button
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    onClick={() => {
                                                                        setShowChangePassword(false)
                                                                        setPasswordFields({
                                                                            currentPassword: {
                                                                                value: null,
                                                                                hasError: false,
                                                                                isTouched: false
                                                                            },
                                                                            password: {
                                                                                value: null,
                                                                                hasError: false,
                                                                                isTouched: false
                                                                            },
                                                                            confirmPassword: {
                                                                                value: null,
                                                                                hasError: false,
                                                                                isTouched: false
                                                                            }
                                                                        })
                                                                    }}
                                                                >
                                                                    {t('settings.cancel')}
                                                                </Button>
                                                            </Grid>
                                                            <Grid item pt={{ xs: 2, lg: 3, md: 3 }} xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    variant="contained"
                                                                    onClick={onPasswordSaveHandler}
                                                                    disabled={isPasswordDisabled()}
                                                                >
                                                                    {t('settings.changePassword')}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Grid container display="flex" flexDirection="column">
                                    <Grid item>
                                        <Typography variant="h6" fontWeight="bold">
                                            {t('helper.businessDetails')}
                                        </Typography>
                                    </Grid>

                                    <Grid container pt={3}>
                                        <Grid xs={12} md={6} lg={6} item pr={{ xs: 0, md: 2, lg: 2 }}>
                                            <Grid xs={12} item>
                                                <Typography fontWeight={500} variant="p">
                                                    {t('settings.businessName')}
                                                </Typography>
                                            </Grid>
                                            <Grid xs={12} pt={1} item>
                                                <TextField
                                                    inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                                                    error={organization.name.hasError}
                                                    helperText={organization.name.hasError && organization.name.errorText}
                                                    value={organization.name.value}
                                                    onChange={(e) => onOrganizationChangeHandler('name', e.target.value)}
                                                    fullWidth
                                                    placeholder="John"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12} md={6} lg={6} item pt={{ xs: 2, lg: 0, md: 0 }}>
                                            <Grid xs={12} item>
                                                <Typography fontWeight={500} variant="p">
                                                    {t('settings.businessLegalName')}
                                                </Typography>
                                            </Grid>
                                            <Grid xs={12} pt={1} item>
                                                <Tooltip title={t('settings.businessDisabled')} arrow>
                                                    <TextField
                                                        inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                                                        disabled={registrationCertificateApproved || identityCardApproved}
                                                        error={organization.brandName.hasError}
                                                        helperText={organization.brandName.hasError && organization.brandName.errorText}
                                                        onChange={(e) => onOrganizationChangeHandler('brandName', e.target.value)}
                                                        value={organization.brandName.value}
                                                        fullWidth
                                                        placeholder="Doe"
                                                    />
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container pt={{ xs: 1, md: 3, lg: 3 }}>
                                        <Grid xs={12} md={6} lg={6} pt={1} pr={{ xs: 0, md: 2, lg: 2 }} item>
                                            <InputGenerator
                                                handleOnChangeGoogle={onOrganizationChangeHandler}
                                                input={{
                                                    name: 'country',
                                                    placeholder: t('settings.searchCountryPlaceholder'),
                                                    type: 'autocomplete',
                                                    label: t('settings.country'),
                                                    value: organization.country.value
                                                }}
                                            />
                                        </Grid>

                                        <Grid xs={12} md={6} lg={6} pt={1} item>
                                            <InputGenerator
                                                handleOnChangeGoogle={onOrganizationChangeHandler}
                                                input={{
                                                    name: 'city',
                                                    placeholder: t('settings.searchCityPlaceholder'),
                                                    type: 'autocomplete',
                                                    label: t('settings.city'),
                                                    value: organization.city.value,
                                                    countryCode: organization.city.countryCode
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    {renderPaymentInfo()}

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            p={3}
                            pt={{ xs: 2, lg: 3, md: 3 }}
                            width="100%"
                        >
                            <Grid xs={12} md={3} lg={3} pt={{ xs: 3, md: 6, lg: 6 }} item>
                                <Button variant="contained" disabled={isDisabled()} fullWidth onClick={async () => await updateHandler()}>
                                    {t('settings.saveChanges')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

export default OrganizationSettings
