import React, { useEffect, useState, useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Grid, Container } from '@mui/material'
import moment from 'moment'

import TrainerActivity from './subcomponents/TrainerActivity'
import TrainerDetails from './subcomponents/TrainerDetails'

import * as TRAINER_REQUESTS from '../../api/trainer'
import * as FOLLOWER_REQUESTS from '../../api/follower'

import { Footer } from '../common'
import { refetchPublicTrainerProfileObservable } from '../../utils/observables'

const PublicTrainerProfile = () => {
  const refetchProfile = refetchPublicTrainerProfileObservable.useRefetchPublicTrainerProfile()
  const { domain } = useParams()
  const navigate = useNavigate()

  const [user, setUser] = useState()
  const [isFollowing, setIsFollowing] = useState(false)
  const [goToReview, setGoToReview] = useState(false)

  useEffect(() => {
    if (!refetchProfile) return
    TRAINER_REQUESTS.getTrainerByDomain(domain).then((user) => {
      setUser({ ...user, createdAt: moment(new Date(user.createdAt)).format('MMMM, YY') })
      FOLLOWER_REQUESTS.isFollowingTrainer(domain)
        .then((res) => {
          setIsFollowing(!!res)
          refetchPublicTrainerProfileObservable.setRefetchPublicTrainerProfile(false)
        })
        .catch(() => {
          setIsFollowing(false)
        })
    })
  }, [domain, refetchProfile])

  const renderTrainerDetails = useMemo(() => {
    return (
      <TrainerDetails
        user={user}
        isFollowing={isFollowing}
        handleGoToReview={() => {
          navigate(`/${domain}?active_option=3`)
          setGoToReview(true)
        }}
      />
    )
  }, [user, domain, isFollowing])

  const renderTrainerActivity = useMemo(() => {
    return (
      <TrainerActivity
        user={user}
        isFollowing={isFollowing}
        goToReview={goToReview}
        resetGoToReview={() => setGoToReview(false)}
      />
    )
  }, [user, goToReview, isFollowing])

  if (!user) return

  return (
    <Container maxWidth="xl">
      <Grid container pt={4} display="flex" flexDirection="row" width="100%">
        <Grid item lg={3} xs={12} md={12} sm={12} p={2} pr={{ xs: 0, lg: 2 }} pl={{ xs: 0 }}>
          {renderTrainerDetails}
        </Grid>
        <Grid item lg={9} xs={12} md={12} sm={12} p={2} pr={{ xs: 0 }} pl={{ xs: 0 }}>
          {renderTrainerActivity}
        </Grid>
      </Grid>
    </Container>
  )
}

export default PublicTrainerProfile
