import React, { useEffect, useState } from 'react'
import { Box, Typography, Grid, Container, Avatar, CircularProgress, Divider, Button, } from '@mui/material'
import { CreditCard } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import RevolutCheckout from '@revolut/checkout'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import { numberFomatter } from '../../utils/helpers/numberFomatter'
import { GRAY_TEXT_COLOR } from '../../utils/constants'
import * as TRANSACTION_INFORMATIONS_REQUESTS from '../../api/transactionInformations'
import { REVOLUT_PUBLIC_KEY } from '../../utils/config'
import StripeCheckoutForm from '../Checkout/subcomponents/StripeCheckout'
import { useAuthentication } from '../../context/auth-context'
const OPERATIONAL_SERVICES_FEE = 1.99;

const PayRevolut = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { user } = useAuthentication()
    const [loading, setLoading] = useState(true)
    const [publicId, setPublicId] = useState()
    const [orderId, setOrderId] = useState()
    const [value, setValue] = useState()
    const [currency, setCurrency] = useState()
    const [company, setCompany] = useState()
    const [slotId, setSlotId] = useState()
    const [calendarServiceName, setCalendarServiceName] = useState()
    const [price, setPrice] = useState()
    const [startHour, setStartHour] = useState()
    const [endHour, setEndHour] = useState()
    const [showPayRevolutModal, setShowRevolutModal] = useState()
    const [data, setData] = useState()
    const [date, setDate] = useState()
    const [address, setAddress] = useState()
    const [postalCode, setPostalCode] = useState()

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)

        const queryData = {
            name: params.get('companyName'),
            calendarServiceId: Number(params.get('calendarServiceId')),
            slotId: Number(params.get('slotId')),
            avatar: params.get('companyAvatar'),
            locationName: params.get('companyLocationName'),
            calendarServiceName: params.get('calendarServiceName'),
            date: params.get('date'),
            startHour: params.get('startHour'),
            endHour: params.get('endHour'),
            valueRon: params.get('price'),
            address: params.get('address'),
            postalCode: params.get('postalCode')
        }
        setData(queryData)
        setAddress(queryData.address)
        setPostalCode(queryData.postalCode)
        setDate(queryData.date)
        setPrice(queryData.valueRon)
        setStartHour(queryData.startHour)
        setEndHour(queryData.endHour)
        setCompany({
            name: queryData.name,
            avatarPhotoSrc: queryData.avatar,
            locationName: queryData.locationName
        })
        setCalendarServiceName(queryData.calendarServiceName)
        setSlotId(queryData.slotId)
    }, [window.location.search])

    const getPubliIdAndOrderIdHandler = () => {
        return TRANSACTION_INFORMATIONS_REQUESTS.createRevolutOrder({
            slotId,
            postalCode: postalCode,
            address,
            validFrom: new Date()
        }).then(({ public_id, orderId, order_amount: { value, currency } }) => {
            return [public_id, orderId, value, currency]
        })
    }

    const onSuccessHandler = async () => {
        try {
            await TRANSACTION_INFORMATIONS_REQUESTS.completeSlot({
                orderId
            })
            navigate('/calendar?showPaymentDoneMessage=show')
        } catch (e) {
            setLoading(false)
        }
    }

    useEffect(() => {

    }, [])


    useEffect(() => {
        if (!slotId || !address || !postalCode) return
        if (publicId && orderId) return

        getPubliIdAndOrderIdHandler().then(result => {
            setPublicId(result[0])
            setOrderId(result[1])
            setValue(result[2])
            setCurrency(result[3])
        })
    }, [publicId, orderId, slotId, address, postalCode])

    useEffect(() => {
        if (!publicId || !orderId || !value || !currency) return

        const handleRevolutPay = async () => {
            const { revolutPay } = await RevolutCheckout.payments({
                locale: 'en',
                mode: 'prod',
                publicToken: REVOLUT_PUBLIC_KEY
            })

            const paymentOptions = {
                currency,
                totalAmount: value,
                buttonStyle: {
                    radius: 'small'
                },
                createOrder: async () => {
                    return { publicId: publicId }
                }
            }
            revolutPay.mount(document.getElementById('payRevolutButton'), paymentOptions)

            revolutPay.on('payment', (event) => {
                switch (event.type) {
                    case 'cancel': {
                        break
                    }

                    case 'success':
                        onSuccessHandler()
                        break

                    case 'error':
                        console.error('Something went wrong. Please try again.')
                        break
                }
            })
        }
        const handleAppleGooglePay = async () => {
            return RevolutCheckout(publicId).then((instance) => {
                const paymentRequest = instance.paymentRequest({
                    target: document.getElementById('payAppleGoogleButton'),
                    requestShipping: false,
                    buttonStyle: {
                        radius: 'small'
                    },
                    onSuccess() {
                        onSuccessHandler()
                    },
                    onError(error) {
                        console.error('Something went wrong. Please try again.')
                        setLoading(false)
                    },
                })

                paymentRequest.canMakePayment().then((result) => {
                    if (result) {
                        paymentRequest.render()
                    } else {
                        paymentRequest.destroy()
                    }
                })
            })
        }
        handleAppleGooglePay()
        handleRevolutPay()
        setLoading(false)
    }, [publicId, orderId, value, currency])

    const onPayWithPopupHandler = async () => {
        setShowRevolutModal({
            startDate: moment.tz(date, "Europe/Bucharest").format('YYYY-MM-DD'),
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            slotId: slotId,
            locale: 'ro',
            address: address,
            postalCode: postalCode
        }

        )
    }


    const calculatePrice = () => {
        return numberFomatter(price)
    }

    return (
        <>
            {showPayRevolutModal ?
                <StripeCheckoutForm data={showPayRevolutModal} operationalServicesFee={OPERATIONAL_SERVICES_FEE} startFrom={date} onCancel={() => setShowRevolutModal()} company={company}
                />
                : null
            }
            <Container maxWidth='sm'>
                <Grid container pt={2} spacing={2}>
                    <Grid item xs={12}>
                        <Grid container display="flex" flexDirection="row">
                            <Grid item
                                xs={10}
                                md={10}
                                lg={10}>
                                <Typography variant="h5" fontWeight="bold" color={'black'}>
                                    {t('checkoutPayment.title')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {loading ? <Grid item xs={12} mt={6} mb={6} display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Grid> : null}
                    {!loading && company ? <>
                        <Grid item xs={12} display="flex" alignItems="center" mt={6}>
                            <Avatar src={company.avatarPhotoSrc} sx={{ mr: 1, height: 60, width: 60 }} />
                            <Box>
                                <Typography fontWeight="500" variant="h4">{company.name}</Typography>
                                <Typography fontWeight="500" variant="p">{company.locationName}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">Buy <strong>{calendarServiceName}</strong> from <strong>{company.name}</strong> with a cost of <strong>{`${calculatePrice()} RON`}</strong>.
                                Booking in <strong>{date}</strong> between <strong>{startHour}</strong> and <strong>{endHour}</strong>.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        {price ? <Grid item xs={12} display="flex" flexDirection="column" mb={6}>
                            <Typography color={GRAY_TEXT_COLOR} variant="h6">Total</Typography>
                            <Typography variant="h6" fontWeight="bold">{calculatePrice() + ' RON'}</Typography>

                        </Grid> : null}
                    </> : null}
                    <Grid item xs={12}>
                        <Button disabled={loading} variant="contained" fullWidth onClick={onPayWithPopupHandler}>
                            <CreditCard sx={{ pr: 2 }} /> Card
                        </Button>
                    </Grid>
                </Grid>
            </Container>

        </>
    )
}

export default PayRevolut
