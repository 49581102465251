import validator from 'validator'

export const isValidPassword = (input) => (!input ? true : input.length >= 6 && /\d/.test(input))
export const isValidEmail = (input) =>
  !input ? true : validator.isEmail(input) && !validator.isEmpty(input)
export const isValidText = (input) => {
  if (typeof input !== 'string') return false
  // return validator.isAscii(input) && !validator.isEmpty(input)
  return !validator.isEmpty(input)
}

export const isValidPhone = (input) =>
  !input ? true : validator.isMobilePhone(input) && !validator.isEmpty(input) && input.length >= 10

export const computeArrayOfInputsWithError = (inputs) => {
  let inputsWithError = []

  inputs.forEach((input) => {
    if (input && input.name === 'confirmPassword') {
      const passwordPosition = inputs.findIndex((f) => f.name === 'password')
      if (passwordPosition >= 0) {
        if (inputs[passwordPosition].value !== input.value) {
          inputsWithError = inputsWithError.concat({
            name: input.name,
            messageKey: 'password_match'
          })
        }
      }
    } else if (input && input.type === 'password' && !isValidPassword(input.value))
      inputsWithError = inputsWithError.concat({ name: input.name, messageKey: input.name })
    else if (
      input &&
      input.type === 'text' &&
      (input.name === 'email' || input.name === 'businessEmail') &&
      !isValidEmail(input.value)
    )
      inputsWithError = inputsWithError.concat({ name: input.name, messageKey: input.name })
    else if (input && input.type === 'text' && !isValidText(input.value))
      inputsWithError = inputsWithError.concat({ name: input.name, messageKey: input.name })
    else if (input && input.type === 'phone' && !isValidPhone(input.value))
      inputsWithError = inputsWithError.concat({ name: input.name, messageKey: input.name })
  })

  return inputsWithError
}
