import React, { useMemo, useState } from 'react'
import { Grid, Typography, Button, Skeleton, Paper, IconButton, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ArrowForwardIos } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { Facebook, Instagram, Check } from '@mui/icons-material'
import LanguageIcon from '@mui/icons-material/Language'

import { GRAY_TEXT_COLOR, LIGHT_GRAY_BORDER_COLOR } from '../../../utils/constants'
import * as INTERESTED_REQUESTS from '../../../api/interested'
import { useAuthentication } from '../../../context/auth-context'
import { ROLE_USER } from '../../../utils/constants'

const Service = ({ services, loading, count, onNextPage, setServices }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useAuthentication()

  const [loadingIntrested, setLoadingIntrested] = useState()

  const renderContent = useMemo(() => {
    return services.map((row, key) => {
      const text = row.text
      const isIntrested = row.isIntrested
      const intrestedLastCreatedAt = new Date(row.intrestedLastCreatedAt)
      const currentDate = new Date()
      const intrestedInSameDay = (intrestedLastCreatedAt.getDate() === currentDate.getDate()
        && intrestedLastCreatedAt.getMonth() === currentDate.getMonth()
        && intrestedLastCreatedAt.getFullYear() === currentDate.getFullYear())
      const currency = row.Company?.currency || row.Trainer.trainerCurrency

      return (
        <Grid item xs={12} lg={3} key={key}>
          <Grid
            onClick={() => navigate('/services/' + row.id)}
            container
            component={Paper}
            p={1}
            sx={{
              cursor: 'pointer',
              flexDirection: 'column',
              display: 'flex',
              boxShadow: '0 13px 33px 0 rgb(41 40 56 / 8%)'
            }}
          >
            <Grid
              p={1}
              display="flex"
              flexDirection="row"
              sx={{ borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }}
            >
              <Typography
                alignSelf="center"
                variant="h6"
                fontWeight="bold"
                noWrap
                style={{ maxWidth: 300 }}
              >
                {row.services}
              </Typography>
            </Grid>
            <Grid display="flex" xs={12} flexDirection="column" pt={2}>
              <Grid container display="flex" flexDirection="row">
                <Grid item xs={12}>
                  <Box display="flex" pl={1}>
                    <Typography variant="p" fontSize="small" color="#B0B0B0">
                      {row.fromCompany
                        ? t('explore.services.club')
                        : t('explore.services.trainer')}
                    </Typography>
                  </Box>
                  <Box display="flex" pl={1}>
                    <Typography variant="p" color="primary" fontWeight="bold">
                      {row.club}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid display="flex" p={2} pl={1} pr={1} flexDirection="column">
              <Grid display="flex">
                <Typography variant="p" fontSize="small" color="#B0B0B0">
                  {t('explore.services.description')}
                </Typography>
              </Grid>
              <Typography variant="p" wrap height={100}>
                {text.length > 150 ? text.slice(0, 150) + '...' : text}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Grid container pr={1} pl={1}>
                <Grid xs={12} item display="flex" pb={1}>
                  <Button fullWidth color="primary" disabled={intrestedInSameDay || loadingIntrested === row.id || !user || (user && user.role !== ROLE_USER)} sx={{ height: 50 }} variant="outlined" onClick={event => {
                    if (!user) return
                    event.stopPropagation()
                    setLoadingIntrested(row.id)
                    if (!isIntrested) {
                      return INTERESTED_REQUESTS.interestedInService(row.id)
                        .then(() => {
                          setServices(services.map(service => ({
                            ...service,
                            ...(service.id === row.id ? { isIntrested: true, intrestedLastCreatedAt: new Date().toISOString() } : {})
                          })))
                        })
                        .finally(() => setLoadingIntrested())
                    }
                    return INTERESTED_REQUESTS.removeInterestedInService(row.id)
                      .then(() => {
                        setServices(services.map(service => ({
                          ...service,
                          ...(service.id === row.id ? { isIntrested: false } : {})
                        })))
                      })
                      .finally(() => setLoadingIntrested())
                  }}>
                    {loadingIntrested === row.id ? t('loading') : isIntrested ? <Check /> : t('explore.services.intrested')}
                  </Button>
                </Grid>
                <Grid xs={12} pt={1} item display="flex">
                  <Button fullWidth color="primary" sx={{ height: 50 }} variant="contained" disabled={!user || (user && user.role !== ROLE_USER) || !row.price} onClick={event => {
                    if (!user) return
                    event.stopPropagation()
                    navigate('/checkout?service=' + row.id)
                  }}>
                    {row.price ? t('explore.services.buyFor', {
                      price: row.price,
                      currency
                    }) : t('button.buy')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              pt={2}
            >
              <Typography fontSize="small" variant="p">
                {t('explore.services.share')}
              </Typography>
              <Grid item ml={1}>
                <Facebook
                  onClick={() => window.open(row.fbLink)}
                  fontSize="small"
                  color="primary"
                />
                <Instagram
                  onClick={() => window.open(row.inLink)}
                  fontSize="small"
                  color="primary"
                />
                <LanguageIcon
                  onClick={() => window.open(row.website)}
                  fontSize="small"
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    })
  }, [services, setServices, loadingIntrested])

  const renderMore = () => {
    return (
      <Grid item xs={12} lg={3} onClick={onNextPage}>
        <Grid
          container
          component={Paper}
          elevation={0}
          style={{ height: 415, cursor: 'pointer', boxShadow: '0 13px 33px 0 rgb(41 40 56 / 8%)' }}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <IconButton>
            <ArrowForwardIos fontSize="medium" style={{ color: GRAY_TEXT_COLOR }} />
          </IconButton>
          <Typography variant="subtitle1" fontWeight={500} color={GRAY_TEXT_COLOR}>
            {t('explore.showMore')}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid item xs={12}>
      <Grid display="flex" flexDirection="row" container>
        <Grid item xs={12} lg={12}>
          <Typography fontWeight="bold" variant="h5">
            {services.length && count ? count : null} {t('userProfile.services')}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ flexWrap: 'wrap' }}
        display="flex"
        flexDirection="row"
        pb={3}
        pt={1}
        spacing={2}
      >
        {!loading && !services.length ? (
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight={500} color={GRAY_TEXT_COLOR}>
              {t('explore.noResult')}
            </Typography>
          </Grid>
        ) : null}
        {!loading && services.length ? renderContent : null}
        {loading ? (
          <Grid item xs={12} lg={3}>
            <Skeleton variant="rectangular" component={Paper} elevation={3} sx={{ height: 415 }} />
          </Grid>
        ) : null}
        {loading ? (
          <Grid item xs={12} lg={3}>
            <Skeleton variant="rectangular" component={Paper} elevation={3} sx={{ height: 415 }} />
          </Grid>
        ) : null}
        {loading ? (
          <Grid item xs={12} lg={3}>
            <Skeleton variant="rectangular" component={Paper} elevation={3} sx={{ height: 415 }} />
          </Grid>
        ) : null}
        {loading ? (
          <Grid item xs={12} lg={3}>
            <Skeleton variant="rectangular" component={Paper} elevation={3} sx={{ height: 415 }} />
          </Grid>
        ) : null}
        {loading ? (
          <Grid item xs={12} lg={3}>
            <Skeleton variant="rectangular" component={Paper} elevation={3} sx={{ height: 415 }} />
          </Grid>
        ) : null}
        {loading ? (
          <Grid item xs={12} lg={3}>
            <Skeleton variant="rectangular" component={Paper} elevation={3} sx={{ height: 415 }} />
          </Grid>
        ) : null}
        {services.length && services.length < count ? renderMore() : null}
      </Grid>
    </Grid>
  )
}

export default Service
