import axios from './axios'

export const getAll = () => {
  return axios.get(`/accessControlRelays`).then((res) => res.data)
}

export const create = payload => {
  return axios.post(`/accessControlRelays`, { ...payload }).then((res) => res.data)
}

export const openDoor = relayId => {
  return axios.put(`/checkins/open-door`, { relayId }).then((res) => res.data)
}