import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Form from './components'
import { useAuthentication } from '../../context/auth-context';
import { CHALLENGES_URL } from '../../utils/config';

const DealsChallenges = () => {
    const { token } = useAuthentication()
    const navigate = useNavigate()
    const [type, setType] = useState('Challenges')
    const handleOnSelect = (newType) => setType(newType)


    const handleNext = () => {
        if (type === 'Deals' || type === "Oferte") {
            navigate('/activities');
        } else if (token) {
            window.location.href = `${CHALLENGES_URL}${token}`;
        }
    };
    return (
        <Grid paddingTop={4} container height="100%" spacing={2}>
            <Form
                handleNext={handleNext}
                type={type}
                handleOnSelect={handleOnSelect}
            />
        </Grid>
    )
}

export default DealsChallenges
