import axios from 'axios'

import { BASE_URL } from '../utils/config'

let axiosInstance = axios.create({
  baseURL: `${BASE_URL}/api`
})

axiosInstance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token')

  config.headers.Authorization = `Bearer ${token}`

  return config
}, (error) => {
  Promise.reject(error)
})

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err && err.response && err.response.status && err.response.status === 403 && localStorage.getItem("token")) {
      localStorage.removeItem("token")
      localStorage.removeItem("refreshToken")
      localStorage.removeItem("email")
      localStorage.removeItem('companyId')
      window.location.pathname = "/login"
      return Promise.reject(err.response.data);
    }

    if (err && err.response && err.response.status && err.response.status === 401 && !originalConfig._retry && localStorage.getItem("token")) {
      if (err.response.data.includes('Account deleted')) {
        localStorage.removeItem("token")
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("email")
        localStorage.removeItem('companyId')
        window.location.pathname = "/login"
        return Promise.reject(err.response.data);
      }
      originalConfig._retry = true;

      const refreshTokenResponse = await axiosInstance.post("/auth/refresh", {
        email: localStorage.getItem("email"),
        companyId: localStorage.getItem('companyId'),
        refreshToken: localStorage.getItem('refreshToken'),
      });
      const { token } = refreshTokenResponse.data;
      window.localStorage.setItem("token", token);
      axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      return axiosInstance(originalConfig);
    }

    return Promise.reject(err);
  }
);

export default axiosInstance
