/* eslint-disable no-unused-vars */
import { Close } from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
  DialogActions,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Container,
} from '@mui/material'
import ReactGA from 'react-ga';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createFilterOptions } from '@mui/material/Autocomplete'
import 'react-quill/dist/quill.snow.css'

import { SendingButton } from '../../common'
import { useAuthentication } from '../../../context/auth-context'
import {
  COMPANIES_TRAINERS_REQUEST_STATUS
} from '../../../utils/constants'
import * as TRAINER_REQUESTS from '../../../api/trainer'
import * as COMPANY_REQUESTS from '../../../api/company'
import * as COURSE_REQUEST from '../../../api/course'
import * as DOCUMENTS_REQUESTS from '../../../api/documents'
import DeleteCourseDialog from '../Delete/DeleteCourseDialog'
import DetailsStep from './components/DetailsStep'
import SubscriptionsStep from './components/SubscriptionsStep'
import OptionsStep from './components/OptionsStep'
import { BUCKET_BASE_URL } from '../../../utils/config';

const CreateCourseDialog = ({ handleClose, type, Transition }) => {
  const { t } = useTranslation()
  const { user } = useAuthentication()
  const [steps, setSteps] = useState([])
  const [activeStep, setActiveStep] = useState(0)
  const [serviceName, setServiceName] = useState('')
  const [shortDescription, setShortDescription] = useState('')
  const [longDescription, setLongDescription] = useState('')
  const [photoLoading, setPhotoLoading] = useState(false)
  const [searchSubscription, setSearchSubscription] = useState('')
  const [selectedSubscriptions, setSelectedSubscriptions] = useState([])
  const [availableSubscriptions, setAvailableSubscriptions] = useState([])
  const [selectedTrainers, setSelectedTrainers] = useState([])
  const [availableTrainers, setAvailabeTrainers] = useState([])
  const [isPublic, setIsPublic] = useState(true)
  const [lesson, setLesson] = useState([])
  const [open, setOpen] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [selectedEl, setSelectedEl] = useState('')
  const [profilePhoto, setProfilePhoto] = useState('')

  const handleOpenDelete = (index) => {
    setSelectedEl(index)
    setOpenDeleteDialog(true)
  }
  const handleDelete = () => {
    if (selectedEl >= 0 && selectedEl < lesson.length) {
      const updatedLessons = [...lesson];

      updatedLessons.splice(selectedEl, 1);

      setLesson(updatedLessons);
      setOpenDeleteDialog(false)
    } else {
      console.error("Indexul specificat este în afara limitelor array-ului.");
    }
  };

  const [loading, setLoading] = useState(false)

  const handleGetSubscriptions = async (searchSubscription) => {
    try {
      let subscriptions = []

      if (type === 'Trainer') {
        subscriptions = await TRAINER_REQUESTS.getTrainerSubscriptions(searchSubscription)
      } else {
        subscriptions = await COMPANY_REQUESTS.getAllSubscriptions(
          user.companyId,
          searchSubscription
        )
      }
      setAvailableSubscriptions(subscriptions)
    } catch (e) {
      setAvailableSubscriptions([])
      console.error(e)
    }
  }

  const handleGetTeam = async () => {
    try {
      const result = await COMPANY_REQUESTS.getAllMembers(user.companyId)
      setAvailabeTrainers(
        result.filter((member) => member.status === COMPANIES_TRAINERS_REQUEST_STATUS.APPROVED)
      )
    } catch (e) {
      setAvailabeTrainers([])
      console.error(e)
    }
  }

  useEffect(() => {
    if (!user) return
    if (activeStep === 1) {
      handleGetSubscriptions(searchSubscription)
    } else if (activeStep === 0 && type === 'Company') {
      handleGetTeam()
    }
  }, [activeStep, type, searchSubscription, user])

  useEffect(() => {
    setSteps([{ label: t('course.details') }, { label: t('course.subs') }, { label: t('course.options') }])
  }, [])

  const handleNextStep = () => setActiveStep((prevValue) => prevValue + 1)

  const handlePrevStep = () => setActiveStep((prevValue) => prevValue - 1)

  const handleSubmit = async () => {
    try {
      setLoading(true)
      let minPrice

      if (selectedSubscriptions && selectedSubscriptions?.length > 0) {
        minPrice = availableSubscriptions
          .filter((el) => selectedSubscriptions.includes(el.id))
          .map((e) => e.priceRon)
          .reduce((prev, current) => (prev < current ? prev : current), selectedSubscriptions[0].priceRon)
      }

      try {
        if (profilePhoto && profilePhoto.file) {

          const presignedUrlResponse = await DOCUMENTS_REQUESTS.generatePresignedUrl({ name: profilePhoto.name, extension: profilePhoto.extension });

          await DOCUMENTS_REQUESTS.uploadDocumentS3(presignedUrlResponse, profilePhoto.file);

          const documentResponse = await DOCUMENTS_REQUESTS.createDocument({
            src: `${BUCKET_BASE_URL}/${profilePhoto.name}.${profilePhoto.extension}`,
            lessonId: lesson.length,
            isPhoto: true
          });
          ReactGA.event({
            category: 'POST_LOGIN',
            action: 'CREATE_SERVICE',
            label: 'SUCCESS'
          });
          const serviceToAdd = {
            title: serviceName,
            lessons: lesson,
            description: shortDescription,
            profilePhoto: documentResponse.src,
            longDescription,
            isPublic,
            subscriptionIds: selectedSubscriptions,
            trainerIds: selectedTrainers.map((el) => el.id),
            priceRon: minPrice ? minPrice : 0
          }

          if (type === 'Company') {
            await COURSE_REQUEST.createCourseForCompany(user.companyId, serviceToAdd)
          } else {
            await COURSE_REQUEST.createCourseForTrainer(serviceToAdd)
          }
        } else {
          const serviceToAdd = {
            title: serviceName,
            lessons: lesson,
            description: shortDescription,
            profilePhoto: '',
            longDescription,
            isPublic,
            subscriptionIds: selectedSubscriptions,
            trainerIds: selectedTrainers.map((el) => el.id),
            priceRon: minPrice ? minPrice : 0
          }

          if (type === 'Company') {
            await COURSE_REQUEST.createCourseForCompany(user.companyId, serviceToAdd)
          } else {
            await COURSE_REQUEST.createCourseForTrainer(serviceToAdd)
          }
        }
        setLoading(false)
        handleClose()
      } catch (error) {
        console.error("Eroare la încărcarea fotografiei:", error);
        ReactGA.event({
          category: 'POST_LOGIN',
          action: 'CREATE_SERVICE',
          label: 'ERROR'
        });
      }

    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }

  const handleExpand = (id) => {
    setAvailableSubscriptions((prevValue) => {
      let prevValueCopy = [...prevValue]
      const idPosition = prevValueCopy.findIndex((f) => f.id === id)

      if (idPosition < 0) return prevValueCopy

      prevValueCopy[idPosition].expand = !prevValueCopy[idPosition].expand

      return prevValueCopy
    })
  }

  const handleSelectSubscription = (event, id) => {
    setSelectedSubscriptions((prevValue) => {
      if (!event.target.checked) return [...prevValue.filter((el) => el !== id)]

      return prevValue.includes(id) ? [...prevValue] : [...prevValue.concat(id)]
    })
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.label + option.phoneNumber + option.email
  })

  const computeSubmitButtonDisabled = () => {
    if (!longDescription || !serviceName || !shortDescription || (activeStep === 2 && (lesson.length <= 0))) return true

    return false
  }

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={'xl'}
      fullScreen={true}
      PaperProps={{
        style: {
          borderRadius: 0,
          paddingTop: '4%'
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Container maxWidth="xl">
        <DialogTitle
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          p={4}
          pl={{ xs: 2, md: 2, lg: 7 }}
          pr={{ xs: 2, md: 2, lg: 7 }}
        >
          <Grid container display="flex" flexDirection="row">
            <Grid item xs={10}>
              <Typography variant="h5" fontWeight="bold">
                {t('course.createCourse')}
              </Typography>
            </Grid>
            <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
              <IconButton style={{ color: 'black' }} onClick={handleClose}>
                <Close fontSize="medium" color="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container display="flex" flexDirection="column" p={{ lg: 4, xs: 0 }}>
            {openDeleteDialog ? (
              <DeleteCourseDialog
                title={t('course.deleteCourseLesson')}
                text={t('course.confirmDeleteLesson')}
                handleDelete={handleDelete}
                handleClose={() => {
                  setOpenDeleteDialog(false)
                }}
              />
            ) : null}
            <Grid item xs={12}>
              <Stepper activeStep={activeStep}>
                {steps.map((step, index) => {
                  return (
                    <Step key={index}>
                      <StepLabel>{step.label}</StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
            </Grid>
            <Grid item xs={12} pt={4}>
              {activeStep === 0 && <DetailsStep serviceName={serviceName} setServiceName={setServiceName} shortDescription={shortDescription} setShortDescription={setShortDescription} longDescription={longDescription} setLongDescription={setLongDescription} />}
              {activeStep === 1 && <SubscriptionsStep handleExpand={handleExpand} searchSubscription={searchSubscription} setSearchSubscription={setSearchSubscription} availableSubscriptions={availableSubscriptions} selectedSubscriptions={selectedSubscriptions} handleSelectSubscription={handleSelectSubscription} />}
              {activeStep === 2 && <OptionsStep profilePhoto={profilePhoto} setProfilePhoto={setProfilePhoto} lesson={lesson} setLesson={setLesson} filterOptions={filterOptions} type={type} open={open} setOpen={setOpen} photoLoading={photoLoading} handleOpenDelete={handleOpenDelete} availableTrainers={availableTrainers} selectedTrainers={selectedTrainers} setSelectedTrainers={setSelectedTrainers} isPublic={isPublic} setIsPublic={setIsPublic} setPhotoLoading={setPhotoLoading} />}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: 8 }}>
          <Grid
            container
            display="flex"
            flexDirection={{ lg: 'row', xs: 'column' }}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item pr={{ xs: 0, lg: 1 }}>
              <Button
                sx={{ minWidth: 250 }}
                variant="outlined"
                color="primary"
                onClick={() => (activeStep === 0 ? handleClose() : handlePrevStep())}
              >
                {activeStep === 0 ? t('course.cancel') : t('course.prev')}
              </Button>
            </Grid>
            <Grid item pl={{ xs: 0, lg: 1 }} mt={{ lg: 0, xs: 1 }}>
              <Button
                disabled={computeSubmitButtonDisabled() || loading}
                sx={{ minWidth: 250 }}
                variant="contained"
                color="primary"
                onClick={() =>
                  activeStep === 2 ? handleSubmit() : handleNextStep()
                }
              >
                {activeStep === 2 ? loading ? <SendingButton /> : t('course.submit') : t('course.next')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default CreateCourseDialog
