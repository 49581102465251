import React, { useEffect, useState } from 'react'
import {
  Grid,
  Card,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Container,
  Radio,
  InputAdornment,
  Button,
  Tooltip,
  FormControl,
  MenuItem,
  Select,
  CircularProgress,
  Box
} from '@mui/material'
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete'
import { useTranslation } from 'react-i18next'
import validator from 'validator'
import { toast } from 'react-toastify';

import { InputGenerator, Toggle } from '../common'
import * as USER_REQUESTS from '../../api/auth'
import * as customValidators from '../../utils/helpers/validator'

import { useAuthentication } from '../../context/auth-context'
import { LIGHT_GRAY_BORDER_COLOR, GRAY_TEXT_COLOR, DEFAULT_MAX_LENGTH } from '../../utils/constants'

const TrainerSettings = () => {
  const { t } = useTranslation()
  const { handleToken, handleUser } = useAuthentication()

  const [user, setUser] = useState()
  const [identityCardSrc, setIdentityCardSrc] = useState()
  const [isApproved, setIsApproved] = useState()
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [loading, setLoading] = useState(true)
  const [passwordFields, setPasswordFields] = useState({
    currentPassword: {
      value: null,
      hasError: false,
      isTouched: false,
      errorText: t('settings.errorTextPassword')
    },
    password: {
      value: null,
      hasError: false,
      isTouched: false,
      errorText: t('settings.errorTextPassword')
    },
    confirmPassword: {
      value: null,
      hasError: false,
      isTouched: false,
      errorText: t('settings.errorTextPassword')
    }
  })

  const onPasswordChangeHandler = (name, value) => {
    setPasswordFields({
      ...passwordFields,
      [name]: {
        ...passwordFields[name],
        isTouched: true,
        value,
        hasError: value.length < 6 || value === user.email.value
      }
    })
  }

  const onPasswordSaveHandler = () => {
    return USER_REQUESTS.changePassword(
      passwordFields.currentPassword.value,
      passwordFields.confirmPassword.value,
      passwordFields.password.value
    )
      .then(() => {
        setShowChangePassword(false)
        setPasswordFields({
          currentPassword: {
            value: null,
            hasError: false,
            isTouched: false
          },
          password: {
            value: null,
            hasError: false,
            isTouched: false
          },
          confirmPassword: {
            value: null,
            hasError: false,
            isTouched: false
          }
        })
        toast.success(t('helper.passwordUpdatedSuccessfully'), { position: 'bottom-right' })
      })
      .catch(() => {
        toast.error(t('helper.passwordNotUpdated'), { position: 'bottom-right', autoClose: false })
      })
  }

  const isPasswordDisabled = () => {
    return (
      !Object.keys(passwordFields).some((key) => passwordFields[key].isTouched) ||
      Object.keys(passwordFields).some((key) => passwordFields[key].hasError)
    )
  }

  const getMe = async () => {
    return await USER_REQUESTS.getMe().then(async (res) => {
      setIdentityCardSrc(res.identityCardSrc)
      setIsApproved(res.isApproved)
      
      setUser({
        firstName: {
          value: res.firstName,
          isTouched: false,
          hasError: false,
          errorText: t('settings.errorEmpty')
        },
        lastName: {
          value: res.lastName,
          isTouched: false,
          hasError: false,
          errorText: t('settings.errorEmpty')
        },
        email: {
          value: res.email,
          isTouched: false,
          hasError: false,
          errorText: t('settings.errorEmail')
        },
        gender: {
          value: res.gender,
          isTouched: false
        },
        phoneNumber: {
          value: res.phoneNumber,
          isTouched: false,
          hasError: false,
          errorText: t('settings.errorPhoneNumber')
        },
        city: {
          value: res.city,
          isTouched: false,
          countryCode: res.country ? await geocodeByAddress(res.country).then(result => result[0].address_components[0].short_name) : ''
        },
        country: {
          value: res.country,
          isTouched: false,
        },
        bankAccount: {
          value: res.bankAccount,
          isTouched: false
        },
        bankName: {
          value: res.bankName,
          isTouched: false
        },
        domain: {
          value: res.domain,
          isTouched: false,
          hasError: false,
          errorText: t('settings.errorEmpty')
        },
        calendarReminder: {
          value: res.calendarReminder,
          isTouched: false
        },
        newsletter: {
          value: res.newsletter,
          isTouched: false
        },
        newMessages: {
          value: res.newsletter,
          isTouched: false
        },
        facebookProfileUrl: {
          value: res.facebookProfileUrl,
          isTouched: false
        },
        instagramProfileUrl: {
          value: res.instagramProfileUrl,
          isTouched: false
        }
      })
      setLoading(false)
    })
  }

  const isDisabled = () => {
    let emptyError = !user.phoneNumber.value || !user.email.value || !user.firstName.value || !user.lastName.value

    if (emptyError) return true
    return (
      Object.keys(user).every((key) => user[key].isTouched === false) ||
      Object.keys(user).some((key) => user[key].hasError === true)
    )
  }

  const updateMeHandler = async () => {
    const data = {}

    Object.keys(user).forEach((key) => {
      if (user[key].isTouched && user[key].value) {
        data[key] = user[key].value
      }
    })

    if (data.city || data.country) {
      data.locationName = `${user.city.value}, ${user.country.value}`

      try {
        await geocodeByAddress(data.locationName)
          .then(result => {
            getLatLng(result[0])
              .then(({ lat, lng }) => {
                data['locationLat'] = String(lat);
                data['locationLng'] = String(lng);
              })
          })
      } catch (error) {
        return toast.error(t('helper.countryOrCityNotValid'), { position: 'bottom-right', autoClose: false })
      }
    }

    return USER_REQUESTS.updateUser(data)
      .then(() => {
        return USER_REQUESTS.regenerateToken()
      })
      .then((response) => {
        handleToken(response.token)
        handleUser(response.user)
        localStorage.setItem('token', response.token)

        getMe()
        toast.success(t('helper.settingsUpdateSuccessfully'), { position: 'bottom-right' })
      })
      .catch(() => {
        getMe()
        toast.error(t('helper.settingsNotUpdated'), { position: 'bottom-right', autoClose: false })
      })
  }

  const onChangeHandler = async (name, value, placeId) => {
    const computeError = (name) => {
      if (name === 'firstName' || name === 'lastName' || name === 'domain') {
        return !customValidators.isValidText(value)
      } else if (name === 'email') {
        return !customValidators.isValidEmail(value)
      } else if (name === 'phoneNumber') {
        return !customValidators.isValidPhone(value)
      } else if (name === 'bankAccount') {
        return !validator.isIBAN(String(value))
      }

      return false
    }

    if (name === 'country') {
      let countryCode;
      await geocodeByPlaceId(placeId)
        .then(result => countryCode = result[0].address_components[0].short_name)
      
      if (!countryCode) return;

      return setUser({
        ...user,
        [name]: {
          isTouched: true,
          value,
          hasError: false
        },
        ['city']: {
          isTouched: true,
          value: '',
          countryCode
        }
      })
    }

    setUser({
      ...user,
      [name]: {
        ...user[name],
        isTouched: true,
        value,
        hasError: computeError(name)
      }
    })
  }

  useEffect(() => {
    getMe()
  }, [])

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Container maxWidth="xl">
      <Grid
        container
        pt={5}
        pb={5}
        paddingLeft={{ xs: 0 }}
        paddingRight={{ xs: 0 }}
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Grid
          p={4}
          mt={{ xs: 4, lg: 4, md: 4 }}
          mb={{ xs: 4, lg: 4, md: 4 }}
          component={Card}
          container
          border={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
          flexDirection={{ xs: 'column' }}
        >
          <Grid container display="flex" flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}>
            <Grid item xs={12} md={6} lg={6} pr={{ xs: 0, md: 8, lg: 8 }}>
              <Grid container display="flex" flexDirection="column">
                <Grid item>
                  <Typography variant="h6" fontWeight="bold">
                    {t('settings.generalInformation')}
                  </Typography>
                </Grid>

                <Grid container pt={3}>
                  <Grid xs={12} md={6} lg={6} item pr={{ xs: 0, lg: 2, md: 2 }}>
                    <Grid item xs={12}>
                      <Typography fontWeight={500} variant="p">
                        {t('settings.firstName')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} pt={1}>
                      <Tooltip title={t('trainerProfile.editModal.firstNameValidated')} arrow>
                        <TextField
                          inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                          disabled={isApproved || (identityCardSrc ? true : false)}
                          error={user.firstName.hasError}
                          helperText={user.firstName.hasError && user.firstName.errorText}
                          value={user.firstName.value}
                          onChange={(e) => onChangeHandler('firstName', e.target.value)}
                          fullWidth
                          placeholder="John"
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={6} lg={6} item pt={{ xs: 2, lg: 0, md: 0 }}>
                    <Grid xs={12} item>
                      <Typography fontWeight={500} variant="p">
                        {t('settings.lastName')}
                      </Typography>
                    </Grid>
                    <Grid xs={12} pt={1} item>
                      <Tooltip title={t('trainerProfile.editModal.lastNameVlidated')} arrow>
                        <TextField
                          inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                          disabled={isApproved || (identityCardSrc ? true : false)}
                          error={user.lastName.hasError}
                          helperText={user.lastName.hasError && user.lastName.errorText}
                          onChange={(e) => onChangeHandler('lastName', e.target.value)}
                          value={user.lastName.value}
                          fullWidth
                          placeholder="Doe"
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container pt={{ xs: 2, lg: 3, md: 3 }}>
                  <Grid xs={12} item>
                    <Typography fontWeight={500} variant="p">
                      {t('helper.gender')}
                    </Typography>
                  </Grid>
                  <Grid xs={12} item>
                    <RadioGroup row>
                      <FormControlLabel
                        value="male"
                        control={
                          <Radio
                            onClick={() => onChangeHandler('gender', 'MALE')}
                            checked={user.gender.value === 'MALE'}
                          />
                        }
                        label={t('settings.MALE')}
                      />
                      <FormControlLabel
                        value="female"
                        control={
                          <Radio
                            onClick={() => onChangeHandler('gender', 'FEMALE')}
                            checked={user.gender.value === 'FEMALE'}
                          />
                        }
                        label={t('settings.FEMALE')}
                      />
                      <FormControlLabel
                        value="other"
                        control={
                          <Radio
                            onClick={() => onChangeHandler('gender', 'OTHER')}
                            checked={user.gender.value === 'OTHER'}
                          />
                        }
                        label={t('settings.OTHER')}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>

                <Grid container pt={{ xs: 2, md: 3, lg: 3 }}>
                  <Grid xs={12} md={6} lg={6} item pr={{ xs: 0, md: 2, lg: 2 }}>
                    <Grid xs={12} item>
                      <Typography fontWeight={500} variant="p">
                        {t('settings.email')}
                      </Typography>
                    </Grid>
                    <Grid xs={12} pt={1} item>
                      <TextField
                        inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                        error={user.email.hasError}
                        helperText={user.email.hasError && user.email.errorText}
                        onChange={(e) => onChangeHandler('email', e.target.value)}
                        value={user.email.value}
                        fullWidth
                        placeholder="john.doe@somemail.com"
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={6} lg={6} item>
                    <InputGenerator
                      handleOnChangePhone={(value) => onChangeHandler('phoneNumber', value)}
                      input={{
                        type: 'phone',
                        label: t('settings.phoneNumber'),
                        error: user.phoneNumber.hasError,
                        helperText: user.phoneNumber.hasError && user.phoneNumber.errorText,
                        placeholder: '0728391121',
                        value: user.phoneNumber.value
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container pt={{ xs: 1, md: 3, lg: 3 }}>
                <Grid xs={12} md={6} lg={6} pt={1} pr={{ xs: 0, md: 2, lg: 2 }} item>
                  <InputGenerator
                    handleOnChangeGoogle={onChangeHandler}
                    input={{
                      name: 'country',
                      placeholder: t('settings.searchCountryPlaceholder'),
                      type: 'autocomplete',
                      label: t('settings.country'),
                      value: user.country.value
                    }}
                  />
                </Grid>

                  <Grid xs={12} md={6} lg={6} pt={1} item>
                    <InputGenerator
                      handleOnChangeGoogle={onChangeHandler}
                      input={{
                        name: 'city',
                        placeholder: t('settings.searchCityPlaceholder'),
                        type: 'autocomplete',
                        label: t('settings.city'),
                        value: user.city.value,
                        countryCode: user.city.countryCode
                      }}
                    />
                  </Grid>
              </Grid>

              <Grid container pt={3}>
                <Grid xs={12} md={6} lg={6} item pr={{ xs: 0, lg: 2, md: 2 }}>
                  <Grid xs={12} item>
                    <Typography fontWeight={500} variant="p">
                      {t('trainerProfile.editModal.facebookUrl')}
                    </Typography>
                  </Grid>
                  <Grid xs={12} pt={1} item>
                    <TextField
                      inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                      error={user.facebookProfileUrl.hasError}
                      helperText={
                        user.facebookProfileUrl.hasError && user.facebookProfileUrl.errorText
                      }
                      value={user.facebookProfileUrl.value}
                      onChange={(e) => onChangeHandler('facebookProfileUrl', e.target.value)}
                      fullWidth
                      placeholder="https://facebook.com/john"
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} md={6} lg={6} item>
                  <Grid xs={12} item>
                    <Typography fontWeight={500} variant="p">
                      {t('trainerProfile.editModal.instagramUrl')}
                    </Typography>
                  </Grid>
                  <Grid xs={12} pt={1} item>
                    <TextField
                      inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                      error={user.instagramProfileUrl.hasError}
                      helperText={
                        user.instagramProfileUrl.hasError && user.instagramProfileUrl.errorText
                      }
                      value={user.instagramProfileUrl.value}
                      onChange={(e) => onChangeHandler('instagramProfileUrl', e.target.value)}
                      fullWidth
                      placeholder="https://instagram.com/john"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container pt={{ xs: 2, md: 3, lg: 3 }}>
                <Grid xs={12} item>
                  <Typography fontWeight={500} variant="p">
                    {t('settings.domain')}
                  </Typography>
                </Grid>
                <Grid xs={12} pt={1} item>
                  <Typography fontWeight={500} color={GRAY_TEXT_COLOR} variant="subtitle2">
                    {t('settings.domainSubtitle')}
                  </Typography>
                </Grid>
                <Grid xs={12} pt={1} item>
                  <TextField
                    inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                    error={user.domain.hasError}
                    helperText={user.domain.hasError && user.domain.errorText}
                    onChange={(e) =>
                      onChangeHandler('domain', e.target.value.trim().replace(' ', ''))
                    }
                    value={user.domain.value}
                    placeholder="johndoe"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography variant="p" color="primary">
                            sportin.io/
                          </Typography>
                        </InputAdornment>
                      )
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={6} pt={{ xs: 3, md: 0, lg: 0 }}>
              <Grid container display="flex" flexDirection="column">
                <Grid item>
                  <Typography variant="h6" fontWeight="bold">
                    {t('settings.emailNotification')}
                  </Typography>
                </Grid>

                <Grid container pt={{ xs: 2, md: 3, lg: 3 }}>
                  <Grid xs={12} item>
                    <Grid xs={12} alignItems="center" display="flex" flexDirection="row" item>
                      <Grid item pr={1}>
                        <Toggle
                          color="primary"
                          defaultChecked={user.calendarReminder.value}
                          onClick={() =>
                            onChangeHandler('calendarReminder', !user.calendarReminder.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography fontWeight={600} variant="p">
                          {t('settings.calendarReminder')}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      xs={12}
                      pt={2}
                      alignItems="center"
                      display="flex"
                      flexDirection="row"
                      item
                    >
                      <Grid item pr={1}>
                        <Toggle
                          color="primary"
                          defaultChecked={user.newsletter.value}
                          onClick={() => onChangeHandler('newsletter', !user.newsletter.value)}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography fontWeight={600} variant="p">
                          {t('settings.newsletters')}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      xs={12}
                      pt={2}
                      alignItems="center"
                      display="flex"
                      flexDirection="row"
                      item
                    >
                      <Grid item pr={1}>
                        <Toggle
                          color="primary"
                          defaultChecked={user.newMessages.value}
                          onClick={() => onChangeHandler('newMessages', !user.newMessages.value)}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography fontWeight={600} variant="p">
                          {t('settings.newMessages')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item pt={6}>
                  <Typography variant="h6" fontWeight="bold">
                    {t('helper.paymentInfo')}
                  </Typography>
                </Grid>

                <Grid display="flex" flexDirection="column" container pt={3} width="100%">
                  <Grid item xs={12}>
                    <Grid container display="flex" flexDirection={'row'}>
                      <Grid item xs={4} pr={2}>
                        <FormControl fullWidth>
                          <Typography pb={0.5} fontWeight={500}>{t('checkout.companyBankName')}</Typography>
                          <Select
                            value={user.bankName.value}
                            placeholder={t('checkout.companyBankName')}
                            onChange={(e) => onChangeHandler('bankName', e.target.value)}
                          >
                            <MenuItem value={'Banca Transilvania'}>Banca Transilvania</MenuItem>
                            <MenuItem value={'Banca Comerciala Romana'}>Banca Comerciala Romana</MenuItem>
                            <MenuItem value={'BRD - Groupe Societe Generale'}>BRD - Groupe Societe Generale</MenuItem>
                            <MenuItem value={'ING Bank'}>ING Bank</MenuItem>
                            <MenuItem value={'Raiffeisen Bank'}>Raiffeisen Bank</MenuItem>
                            <MenuItem value={'UniCredit Bank'}>UniCredit Bank</MenuItem>
                            <MenuItem value={'CEC Bank'}>CEC Bank</MenuItem>
                            <MenuItem value={'Alpha Bank'}>Alpha Bank</MenuItem>
                            <MenuItem value={'OTP Bank'}>OTP Bank</MenuItem>
                            <MenuItem value={'EximBank'}>EximBank</MenuItem>
                            <MenuItem value={'Citibank'}>Citibank</MenuItem>
                            <MenuItem value={'Garanti BBVA'}>Garanti BBVA</MenuItem>
                            <MenuItem value={'Libra Internet Bank'}>Libra Internet Bank</MenuItem>
                            <MenuItem value={'First Bank'}>First Bank</MenuItem>
                            <MenuItem value={'Banca Romaneasca'}>Banca Romaneasca</MenuItem>
                            <MenuItem value={'Intensa Sanpaolo'}>Intensa Sanpaolo</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={8} pl={2}>
                        <Typography pb={0.5} fontWeight={500}>{t('checkout.companyBankAccount')}</Typography>
                        <TextField
                          inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                          value={user.bankAccount.value}
                          error={user.bankAccount.hasError}
                          placeholder={t('checkout.companyBankAccount')}
                          onChange={(e) =>
                            onChangeHandler('bankAccount', e.target.value)
                          }
                          fullWidth
                          type="text"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {!showChangePassword && (
                <Grid item pt={{ xs: 3, lg: 6, md: 6 }}>
                  <Grid xs={12} item>
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={() => setShowChangePassword(true)}
                    >
                      {t('settings.changePassword')}
                    </Button>
                  </Grid>
                </Grid>
              )}
              {showChangePassword && (
                <>
                  <Grid
                    mt={3}
                    borderRadius={3}
                    p={3}
                    display="flex"
                    flexDirection="column"
                    border={`2px solid ${LIGHT_GRAY_BORDER_COLOR}`}
                  >
                    <Grid item>
                      <Typography variant="h6" fontWeight="bold">
                        {t('settings.changePassword')}
                      </Typography>
                    </Grid>

                    <Grid container pt={3}>
                      <Grid xs={12} item>
                        <Grid xs={12} item>
                          <Typography fontWeight={500} variant="p">
                            {t('settings.password')}
                          </Typography>
                        </Grid>
                        <Grid xs={12} pt={1} item>
                          <TextField
                            inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                            value={passwordFields.currentPassword.value}
                            error={passwordFields.currentPassword.hasError}
                            helperText={
                              passwordFields.currentPassword.hasError &&
                              passwordFields.currentPassword.errorText
                            }
                            onChange={(e) =>
                              onPasswordChangeHandler('currentPassword', e.target.value)
                            }
                            fullWidth
                            type="password"
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container pt={{ xs: 2, md: 3, lg: 3 }}>
                      <Grid xs={12} md={6} lg={6} item pr={{ xs: 0, lg: 2, md: 2 }}>
                        <Grid xs={12} item>
                          <Typography fontWeight={500} variant="p">
                            {t('settings.newPassword')}
                          </Typography>
                        </Grid>
                        <Grid xs={12} pt={1} item>
                          <TextField
                            inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                            value={passwordFields.password.value}
                            error={passwordFields.password.hasError}
                            helperText={
                              passwordFields.password.hasError && passwordFields.password.errorText
                            }
                            onChange={(e) => onPasswordChangeHandler('password', e.target.value)}
                            fullWidth
                            type="password"
                          />
                        </Grid>
                      </Grid>
                      <Grid xs={12} md={6} lg={6} item pt={{ xs: 2, md: 0, lg: 0 }}>
                        <Grid xs={12} item>
                          <Typography fontWeight={500} variant="p">
                            {t('settings.confirmPassword')}
                          </Typography>
                        </Grid>
                        <Grid xs={12} pt={1} item>
                          <TextField
                            inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                            value={passwordFields.confirmPassword.value}
                            error={passwordFields.confirmPassword.hasError}
                            helperText={
                              passwordFields.confirmPassword.hasError &&
                              passwordFields.confirmPassword.errorText
                            }
                            onChange={(e) =>
                              onPasswordChangeHandler('confirmPassword', e.target.value)
                            }
                            fullWidth
                            type="password"
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container pb={3} width="100%">
                      <Grid item xs={12} md={12} lg={12}>
                        <Grid
                          container
                          display="flex"
                          flexDirection={{ xs: 'row', md: 'column', lg: 'column' }}
                        >
                          <Grid item pt={6} xs={12} pr={{ xs: 0, ld: 2, md: 2 }}>
                            <Button
                              fullWidth
                              variant="outlined"
                              onClick={() => {
                                setShowChangePassword(false)
                                setPasswordFields({
                                  currentPassword: {
                                    value: null,
                                    hasError: false,
                                    isTouched: false
                                  },
                                  password: {
                                    value: null,
                                    hasError: false,
                                    isTouched: false
                                  },
                                  confirmPassword: {
                                    value: null,
                                    hasError: false,
                                    isTouched: false
                                  }
                                })
                              }}
                            >
                              {t('settings.cancel')}
                            </Button>
                          </Grid>
                          <Grid item pt={{ xs: 3, md: 6, lg: 6 }} xs={12}>
                            <Button
                              fullWidth
                              variant="contained"
                              onClick={onPasswordSaveHandler}
                              disabled={isPasswordDisabled()}
                            >
                              {t('settings.changePassword')}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item display="flex" alignItems="center" justifyContent="center" p={3} width="100%">
            <Grid xs={12} item md={3} lg={3} pt={6}>
              <Button
                variant="contained"
                disabled={isDisabled()}
                fullWidth
                onClick={async () => await updateMeHandler()}
              >
                {t('settings.saveChanges')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default TrainerSettings
