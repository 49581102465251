import React from 'react'
import { Grid, Typography, Box, Avatar } from '@mui/material'
import moment from 'moment'

import { AvatarNoImage } from '../../common'
import { PRIMARY_COLOR } from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'

const Notification = ({
  handleCloseMenuNotifications,
  firstName,
  index,
  lastName,
  name,
  serviceId,
  read,
  message,
  forUser,
  avatarSrc,
  createdAt,
  authorId,
  trainerDomain,
  companyDomain,
  forTrainer,
  handleReadNotification
}) => {
  const navigate = useNavigate()

  return (
    <Grid
      onClick={() => {
        if (!read) handleReadNotification()
        if (message.toLowerCase().includes('collaboration request')) navigate(`/dashboard`)
      }}
      container
      display="flex"
      flexDirection="row"
      alignItems="center"
      sx={{ cursor: 'pointer', paddingTop: index !== 0 ? 1.5 : 1 }}
    >
      <Grid container display="flex" flexDirection="row" alignItems="center">
        <Grid item xs={2} pl={1} display="flex" flexDirection="row" alignItems="flex-end">
          {avatarSrc ? (
            <Avatar
              src={avatarSrc}
              style={{ width: 55, height: 55, objectFit: 'contain' }}
            />
          ) : (
            <AvatarNoImage
              name={name}
              firstName={firstName}
              lastName={lastName}
              width={55}
              height={55}
              typographyVariant="subtitle1"
            />
          )}
        </Grid>
        <Grid
          item
          display="flex"
          xs={9}
          alignItems="flex-start"
          pr={1}
          pl={2}
          flexDirection="column"
        >
          <Typography
            onClick={() => {
              if (serviceId) {
                handleCloseMenuNotifications()
                navigate(`/services/${serviceId}`)
              }
            }}
            alignItems="flex-start"
            flexDirection="row"
            variant="subtitle2"
          >
            <span
              onClick={(e) => {
                e.stopPropagation()
                handleCloseMenuNotifications()
                if (authorId) {
                  if (forUser) navigate(`/athlete/${authorId}`)
                  else navigate(`/${firstName}${lastName}${authorId}`)
                }
                else if (companyDomain && forTrainer) navigate(`/${companyDomain}`)
                else if (trainerDomain) navigate(`/${trainerDomain}`)
              }}
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                color: PRIMARY_COLOR,
                fontWeight: 600
              }}
            >
              {name ? name : `${firstName} ${lastName}`}
            </span>{' '}
            {message}
          </Typography>
          <Typography alignItems="flex-start" flexDirection="row" variant="caption">
            {moment(createdAt).format('LL')}
          </Typography>
        </Grid>
        {
          <Grid
            item
            xs={1}
            pl={1}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent={'center'}
          >
            {!read ? (
              <Box
                sx={{ width: 10, height: 10, backgroundColor: PRIMARY_COLOR, borderRadius: '50%' }}
              />
            ) : null}
          </Grid>
        }
      </Grid>
    </Grid>
  )
}

export default Notification
