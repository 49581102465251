import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, Container, Typography, TextField, Button } from '@mui/material'
import { useParams, useLocation } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

import LOGIN_BG from '../../../assets/login.png'
import PasswordUpdated from './PasswordUpdated'
import { SendingButton as SendingButtonChild } from '../../common'

import { isValidPassword } from '../../../utils/helpers/validator'
import * as AUTH_REQUESTS from '../../../api/auth'
import { useError } from '../../../context/error-context'

const useStyles = makeStyles({
  content: {
    paddingTop: '3rem'
  },
  forgottenContainer: {
    paddingTop: '0.4rem'
  },
  forgottenText: {
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight: 600
  },
  createAccountText: {
    cursor: 'pointer'
  }
})

const CreateNewPassword = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { handleError } = useError()
  const params = useParams()
  const location = useLocation()

  const [wrongInputs, setWrongInputs] = useState([])
  const [password, setPassword] = useState('')
  const [code, setCode] = useState()
  const [userData, setUserData] = useState()
  const [confirmPassword, setConfirmPassword] = useState('')
  const [success, setSuccess] = useState(false)
  const [sending, setSending] = useState(false)

  useEffect(() => {
    setCode(params.id)
  }, [params])

  const handleChangePassword = useCallback((event) => {
    setWrongInputs((prevValues) => [...prevValues.filter((el) => el !== 'password')])
    setPassword(event.target.value)
  }, [])

  const handleChangeConfirmPassword = useCallback((event) => {
    setWrongInputs((prevValues) => [...prevValues.filter((el) => el !== 'password')])
    setConfirmPassword(event.target.value)
  }, [])

  const createNewPassword = async () => {
    try {
      handleError('')
      let wrong = false
      if (!isValidPassword(password) && !isValidPassword(confirmPassword)) {
        setWrongInputs((prevValues) => [...prevValues, 'password'])
        if (confirmPassword !== password) wrong = true
      }
      if (wrong) return

      setSending(true)
      const result = await AUTH_REQUESTS.createPassword(password, confirmPassword, code)
      setSending(false)
      setUserData(result)
      setSuccess(true)
    } catch (e) {
      handleError(t('snackbar.error.EXPIRED'))
      setSending(false)
      console.error(e)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      createNewPassword()
    }
  }

  const renderCreateNewPassword = useMemo(() => {
    const URLQuery = new URLSearchParams(location.search)
    return (
      <Grid item xs={12} sm={12} lg={6} display="flex" flexDirection="column" alignItems="center">
        <Grid container height="100%" alignItems="center">
          <Container maxWidth="xs">
            <Grid className={classes.content} container direction="column">
              <Grid item>
                <Typography fontWeight="bold" variant="h4" color="primary">
                  {t('helper.createNewPassword')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight={500} variant="h6" pt={1} pb={1}>
                  {t('helper.yourPasswordMust')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  fontWeight={500}
                  variant="p"
                  color={`${password.length < 6 ? 'error' : 'default'}`}
                >
                  - {t('helper.atLeast6')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  fontWeight={500}
                  variant="p"
                  color={`${URLQuery.get('email').includes(password) || password === ''
                    ? 'error'
                    : 'default'
                    }`}
                >
                  - {t('helper.notSame')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  fontWeight={500}
                  variant="p"
                  color={`${!/\d/.test(password) ? 'error' : 'default'}`}
                >
                  - {t('helper.atLeastOne')}
                </Typography>
              </Grid>
              <Grid item pb={2}>
                <Typography
                  fontWeight={500}
                  variant="p"
                  color={`${password !== confirmPassword || password.length === 0 ? 'error' : 'default'
                    }`}
                >
                  - {t('helper.match')}
                </Typography>
              </Grid>
              <Grid maxHeight={80} height={80} item pt={2}>
                <TextField
                  onKeyDown={handleKeyDown}
                  onChange={handleChangePassword}
                  defaultValue={password}
                  placeholder={t('input.error.newPassword')}
                  type="password"
                  fullWidth
                  variant="outlined"
                  error={wrongInputs.includes('password')}
                  helperText={wrongInputs.includes('password') ? t('input.error.newPassword') : ''}
                />
              </Grid>
              <Grid maxHeight={80} height={80} item pt={2}>
                <TextField
                  onKeyDown={handleKeyDown}
                  onChange={handleChangeConfirmPassword}
                  defaultValue={confirmPassword}
                  type="password"
                  placeholder={t('input.error.confirmNewPassword')}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item display="flex" flexDirection="row" pt={2}>
                <Grid container display="flex" justifyContent="center" flexDirection="row">
                  <Grid item xs={8}>
                    <Button
                      onClick={createNewPassword}
                      color="primary"
                      variant="contained"
                      fullWidth
                      disabled={
                        sending ||
                        password.length < 6 ||
                        URLQuery.get('email') === password ||
                        !/\d/.test(password) ||
                        password !== confirmPassword ||
                        password.length === 0
                      }
                    >
                      {!sending ? t('button.submit') : <SendingButtonChild />}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    )
  }, [
    classes.forgottenContainer,
    classes.createAccountText,
    classes.forgottenText,
    classes.content,
    password,
    createNewPassword,
    sending,
    wrongInputs,
    confirmPassword,
    t
  ])

  const renderImage = useMemo(() => {
    return (
      <Grid
        item
        alignItems="center"
        display={{ xs: 'none', sm: 'none', lg: 'block' }}
        lg={6}
        maxHeight={'98vh'}
      >
        <img
          style={{ position: 'relative', left: '3%', top: '-30%', objectFit: 'cover' }}
          height={'150%'}
          src={LOGIN_BG}
          width={1200}
        />
      </Grid>
    )
  }, [])

  return (
    <Grid container style={{ padding: 0, overflowX: 'hidden' }} height="100%">
      {success && userData ? <PasswordUpdated /> : renderCreateNewPassword}
      {success ? null : renderImage}
    </Grid>
  )
}

export default CreateNewPassword
