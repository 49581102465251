import React, { useState, useEffect } from 'react'
import {
  Dialog,
  Typography,
  Grid,
  IconButton,
  Box,
  Autocomplete,
  TextField,
  Button,
  Avatar
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import {
  GRAY_BG_COLOR,
  GRAY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
  BUSINESS_ROLES
} from '../../../utils/constants'

import * as USER_REQUESTS from '../../../api/user'
import * as COMPANIES_REQUESTS from '../../../api/company'
import * as EMPLOYEE_REQUESTS from '../../../api/employees'
import { useAuthentication } from '../../../context/auth-context'

const AddEmployeeDialog = ({ onClose, onSave, id }) => {
  const { user } = useAuthentication()
  const { t } = useTranslation()

  const [users, setUsers] = useState([])
  const [filter, setFilter] = useState()
  const [selectedUser, setSelectedUser] = useState()
  const [companies, setCompanies] = useState([])
  const [selectedCompany, setSelectedCompany] = useState()
  const [usersLoading, setUsersLoading] = useState(true)
  const [companiesLoading, setCompaniesLoading] = useState(true)

  const getAllUsers = () => {
    setUsersLoading(true)

    const query = {}

    if (filter) {
      query.search = filter
    }

    if (!filter) {
      setUsers([])
      setUsersLoading(false)
      return
    }

    return USER_REQUESTS.getAllUsers({ ...query }).then((response) => {
      setUsers(response.filter((el) => el.id !== user.id))
      setUsersLoading(false)
    })
  }

  const getAllCompanies = () => {
    setCompaniesLoading(true)
    return COMPANIES_REQUESTS.getAll({ businessOwnerId: user.id }).then((res) => {
      setCompanies(res)
      setCompaniesLoading(false)
    })
  }

  useEffect(() => {
    if (!id) return
    EMPLOYEE_REQUESTS.findById(id).then((response) => {
      setSelectedUser({
        label: `${response.Employee.firstName} ${response.Employee.lastName}`,
        avatar: response.Employee.avatarPhotoSrc,
        phoneNumber: response.Employee.phoneNumber,
        value: response.Employee.id,
        firstName: response.Employee.firstName,
        lastName: response.Employee.lastName
      })
      setSelectedCompany({
        value: response.Company.id,
        label: response.Company.locationName,
        avatar: response.Company.avatarPhotoSrc,
        phoneNumber: response.Company.name
      })
    })
  }, [id])

  useEffect(() => {
    getAllUsers()
  }, [filter, user])

  useEffect(() => {
    getAllCompanies()
  }, [user])

  if (companiesLoading) {
    return null
  }

  return (
    <Dialog open={true} maxWidth="xs" fullWidth>
      <Grid
        p={4}
        pr={{ xs: 2, lg: 4, md: 2 }}
        pl={{ xs: 2, lg: 4, md: 2 }}
        container
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
      >
        <Grid item xs={12}>
          <Grid container display="flex" flexDirection="row">
            <Grid item xs={1}></Grid>
            <Grid item xs={10} display="flex" alignItems="center" justifyContent="center">
              <Typography variant="h5" fontWeight="bold">
                {!id ? t('employees.addEmployee') : t('employees.editEmployee')}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <Typography variant="p" fontWeight={500}>
            {!id ? t('employees.descriptionAdd') : t('employees.editEmployeeDescription')}
          </Typography>
        </Grid>

        <Grid item xs={12} pt={5}>
          <Grid container>
            <Grid item xs={12} pb={1}>
              <Typography variant="p" fontWeight={500}>
                {t('employees.selectUser')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                options={users.map((el) => ({
                  label: `${el.firstName} ${el.lastName}`,
                  avatar: el.avatarPhotoSrc,
                  phoneNumber: el.phoneNumber,
                  value: el.id,
                  id: el.id,
                  firstName: el.firstName,
                  lastName: el.lastName
                }))}
                isOptionEqualToValue={(option, value) => {
                  return option.value === value.value
                }}
                autoHighlight
                value={selectedUser}
                renderOption={(props, option) => {
                  return (
                    <Box
                      {...props}
                      sx={{ display: 'flex', flexDirection: 'row' }}
                      key={option.value}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {option.avatar ? (
                          <Avatar style={{ width: 50, height: 50 }} src={option.avatar} />
                        ) : (
                          <Box
                            sx={{
                              width: 50,
                              height: 50,
                              backgroundColor: GRAY_BG_COLOR,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: 25
                            }}
                          >
                            <Typography fontWeight="bold" color={GRAY_TEXT_COLOR}>
                              {option.firstName.charAt(0)} {option.lastName.charAt(0)}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 1 }}>
                        <Typography fontWeight={500}>{option.label}</Typography>
                        <Typography fontWeight={500}>{option.phoneNumber}</Typography>
                      </Box>
                    </Box>
                  )
                }}
                filterOptions={(options, state) => options}
                onInputChange={(event) => {
                  setFilter(event.target.value)
                }}
                onChange={(_, newValue) => {
                  if (newValue) {
                    setSelectedUser({ ...newValue })
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    placeholder={t('businessEditProfile.searchTrainer')}
                    {...params}
                    InputProps={{ ...params.InputProps, endAdornment: null }}
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        marginLeft: 6,
                        padding: 11.5,
                        color: SECONDARY_TEXT_COLOR,
                        fontWeight: 500
                      }
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} pt={3}>
          <Grid container>
            <Grid item xs={12} pb={1}>
              <Typography variant="p" fontWeight={500}>
                {t('employees.companyRole')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                placeholder="Rolul Userului"
                value={t(`roles.${BUSINESS_ROLES.BUSINESS_EMPLOYEE}`)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} pt={3}>
          <Grid container>
            <Grid item xs={12} pb={1}>
              <Typography variant="p" fontWeight={500}>
                {t('employees.companyLocation')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                options={companies.map((el) => ({
                  label: el.name,
                  avatar: el.avatarPhotoSrc,
                  phoneNumber: el.locationName,
                  value: el.id
                }))}
                autoHighlight
                value={selectedCompany}
                noOptionsText={t('employees.companyLocation')}
                renderOption={(props, option) => {
                  return (
                    <Box
                      {...props}
                      sx={{ display: 'flex', flexDirection: 'row' }}
                      key={option.value}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {option.avatar ? (
                          <Avatar style={{ width: 50, height: 50 }} src={option.avatar} />
                        ) : (
                          <Box
                            sx={{
                              width: 50,
                              height: 50,
                              backgroundColor: GRAY_BG_COLOR,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: 25
                            }}
                          >
                            <Typography fontWeight="bold" color={GRAY_TEXT_COLOR}>
                              {option.label.charAt(0)}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 1 }}>
                        <Typography fontWeight={500}>{option.label}</Typography>
                        <Typography color={GRAY_TEXT_COLOR}>{option.phoneNumber}</Typography>
                      </Box>
                    </Box>
                  )
                }}
                filterOptions={(options, state) => options}
                onChange={(_, newValue) => {
                  if (newValue) {
                    setSelectedCompany({ ...newValue })
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    placeholder={t('employees.searchCompany')}
                    {...params}
                    InputProps={{ ...params.InputProps, endAdornment: null }}
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        marginLeft: 6,
                        padding: 11.5,
                        color: SECONDARY_TEXT_COLOR,
                        fontWeight: 500
                      }
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} pt={4}>
          <Grid container display="flex" flexDirection="row" spacing={2}>
            <Grid item xs={6}>
              <Button fullWidth variant="outlined" color="primary" onClick={onClose}>
                {t('button.cancel')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={usersLoading || companiesLoading || !selectedUser || !selectedCompany}
                onClick={() => {
                  onSave({
                    employeeId: selectedUser.value,
                    companyId: selectedCompany.value,
                    role: BUSINESS_ROLES.BUSINESS_EMPLOYEE
                  })
                  onClose()
                }}
              >
                {!id ? t('button.save') : t('button.edit')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default AddEmployeeDialog
