import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Container, Grid, Step, Stepper, Typography } from "@mui/material";
import Transactions from "./Transactions";
import { CustomConnector, CustomStepIcon, CustomStepLabel, StyledContainer, StyledGrid } from './styled';
import YourPlan from './YourPlan';
import { useTranslation } from 'react-i18next';
import AdsOns from './AdsOns';
import Payments from './Payments';
import { cards } from './data';
import { LIGHT_GRAY_BORDER_COLOR } from '../../utils/constants';

const Price = () => {
    const { t } = useTranslation();
    const [steps, setSteps] = useState([]);
    const [activeStep, setActiveStep] = useState(1);
    const [selectValue, setSelectValue] = useState("0");
    const [selectedIndices, setSelectedIndices] = useState([]);

    const handleNextStep = () => setActiveStep((prevValue) => prevValue + 1);

    const handlePrevStep = () => setActiveStep((prevValue) => prevValue - 1);

    useEffect(() => {
        setSteps([{ label: t('price.transactions') }, { label: t('price.ads') }, { label: t('price.payment') }]);
    }, [t]);

    return (
        <Container maxWidth="xl">
            <Grid container pt={5} pb={{ xs: 8 }} display="flex" justifyContent='center' flexDirection="row" width="100%">
                <Grid item lg={11} xs={12} md={12} sm={12} p={2} pr={{ xs: 0 }} pl={{ xs: 0 }}>
                    <Grid container height="100%">
                        <Grid container justifyContent="flex-end" height="100%">
                            <Grid sx={{
                                borderRadius: 3,
                                border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
                                flexGrow: 1,
                                display: 'flex',
                                padding: 5,
                                flexDirection: { lg: 'row', md: 'column', xs: 'column' },
                            }} component={Card} item sm={12} xl={12}>
                                <Grid item lg={8} md={12} xs={12}>
                                    <Stepper sx={{ marginTop: '50px' }} activeStep={activeStep} alternativeLabel connector={<CustomConnector />}>
                                        {steps.map((step, index) => (
                                            <Step key={index}>
                                                <CustomStepLabel StepIconComponent={(props) => <CustomStepIcon {...props} icon={index + 1} />}>
                                                    {step.label}

                                                </CustomStepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                    <Box height={'93%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
                                        <Box flexGrow={1}>
                                            {activeStep === 1 ? <Transactions setSelectValue={setSelectValue} selectValue={selectValue} /> : activeStep === 2 ? <AdsOns cards={cards} selectedIndices={selectedIndices} setSelectedIndices={setSelectedIndices} /> : <Payments />}
                                        </Box>
                                        <Grid pl={{ lg: '110px', md: '20px' }} pr={{ lg: '110px', md: '55px' }} pb={{ lg: '0px', md: '20px', xs: '20px' }} display={'flex'} justifyContent={'space-between'}>
                                            <Box >
                                                {activeStep !== 1 &&
                                                    <Button
                                                        sx={{
                                                            minWidth: '147px',
                                                            backgroundColor: '#fff',
                                                            opacity: 1,
                                                            color: '#000',
                                                            padding: '0 15px',
                                                            border: '1px solid #707070',
                                                            marginRight: '9px',
                                                            '&:hover': {
                                                                backgroundColor: '#fff',
                                                                borderColor: '#000000'
                                                            }
                                                        }}
                                                        onClick={handlePrevStep}
                                                        variant="contained"
                                                    >
                                                        {t('price.back')}

                                                    </Button>

                                                }
                                                {
                                                    activeStep !== 3 &&

                                                    <Button
                                                        sx={{
                                                            minWidth: '147px',
                                                            backgroundColor: '#000000',
                                                            opacity: 1,
                                                            color: '#ffffff',
                                                            padding: '0 15px',
                                                            borderColor: '#000000',
                                                            '&:hover': {
                                                                backgroundColor: '#000000',
                                                                borderColor: '#000000'
                                                            }
                                                        }}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleNextStep}
                                                    >
                                                        {t('price.next')}
                                                    </Button>}
                                            </Box>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <StyledGrid item lg={4} md={12} xs={12}>
                                    <YourPlan selectedIndices={selectedIndices} selectValue={selectValue} />
                                </StyledGrid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Price;
