import React from 'react'
import { Grid, Card, Typography, Button, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Linkify from 'react-linkify'
import { ContentCopy, LocalOffer } from '@mui/icons-material'

import {
  LIGHT_GRAY_BORDER_COLOR,
  CARD_TEXT_COLOR,
  ROLE_TRAINER,
  ROLE_BUSINESS_ADMINISTRATOR,
  BUSINESS_ROLES,
  GREEN_COLOR,
  PRIMARY_COLOR
} from '../../../../../utils/constants'
import { useAuthentication } from '../../../../../context/auth-context'
import { toast } from 'react-toastify'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  }
})

const Subscription = ({
  name,
  description,
  duration,
  id,
  credits,
  Trainers,
  priceRon,
  currency,
  isAvailable,
  limitedActivation,
  isPartTime,
  startTime,
  endTime,
  limitedActivationUsed,
  hasVoucher
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useAuthentication()
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const isTrainer = user && user.role && user.role.toLowerCase() === ROLE_TRAINER.toLowerCase()
  const isBusinessAdministrator =
    user && user.role && user.role.toLowerCase() === ROLE_BUSINESS_ADMINISTRATOR.toLowerCase()
  const isClubAdministrator =
    user && user.role && user.role.toLowerCase() === BUSINESS_ROLES.BUSINESS_EMPLOYEE.toLowerCase()
  const isSubscriptionAvailable = isAvailable
  const hasLimitedActivation = limitedActivation !== null

  const computeColor = () => {
    if (hasLimitedActivation && limitedActivation === 0) return 'error'
    if (hasLimitedActivation && (limitedActivationUsed <= 0)) return 'error'
    if (!isSubscriptionAvailable) return 'error'
    if (hasLimitedActivation && limitedActivationUsed > 0) return 'primary'

    return 'primary'
  }

  const computeText = () => {
    if (hasLimitedActivation && limitedActivation === 0) return t('userProfile.activity.no')
    if (hasLimitedActivation && (limitedActivationUsed <= 0)) return t('userProfile.activity.no')
    if (!isSubscriptionAvailable) return t('userProfile.activity.no')
    if (hasLimitedActivation && limitedActivationUsed > 0) return `${limitedActivationUsed}`
    if (isSubscriptionAvailable && (hasLimitedActivation ? limitedActivationUsed > 0 : true)) return t('userProfile.activity.yes')
    if (!isSubscriptionAvailable && (hasLimitedActivation ? limitedActivationUsed == 0 : true)) return t('userProfile.activity.no')

    return t('userProfile.activity.no')
  }

  return (
    <Grid id={`subscription-${id}`} container component={Card} className={classes.content} sx={{
      border: searchParams.get('subscriptionId') && String(id) === String(searchParams.get('subscriptionId')) ? `2px solid ${GREEN_COLOR} !important` : '2px solid transparent'
    }}>
      <Grid
        xs={12}
        item
        borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
        display="flex"
        flexDirection={{ lg: 'row', xs: 'column' }}
        width="100%"
      >
        <Grid
          item
          lg={7}
          borderRight={{
            xs: 'none',
            lg: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
          }}
          borderBottom={{
            xs: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
            lg: 'none'
          }}
          display="flex"
          alignItems="center"
          flexWrap="noWrap"
        >
          <Grid container>
            <Grid item xs={12} lg={9} display="flex" alignItems="center" flexWrap="noWrap" flexDirection="row">
              <Typography
                sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', flexDirection: 'row' }}
                p={3}
                variant="h6"
                fontWeight={500}
                display="flex"
                flexDirection="row"
              >
                {name}
                {hasVoucher ? <LocalOffer sx={{ pl: 1 }} color='primary' /> : null}
              </Typography>
            </Grid>
            {isPartTime && (
              <Grid
                item
                xs={12}
                lg={3}
                pr={{ xs: 0, lg: 4 }}
                pl={{ xs: 3, lg: 0 }}
                display="flex"
                alignItems={{ xs: 'flex-start', lg: 'flex-end' }}
                borderTop={{ xs: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`, lg: 'none' }}
                flexDirection="column"
                flexWrap="noWrap"
              >
                <Typography pt={2} color={CARD_TEXT_COLOR}>
                  {t('userProfile.activity.trainerProgramComponent.access')}
                </Typography>
                <Typography pb={2} fontWeight={500} color="primary">
                  {startTime + '-' + endTime}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item lg={5}>
          <Grid
            container
            display="flex"
            alignItems={{ xs: 'flex-start', lg: 'flex-end' }}
            flexDirection="row"
          >
            <Grid xs={6} lg={6} item borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
              <Grid
                xs={12}
                item
                display="flex"
                flexDirection="column"
                pl={{ lg: 3, xs: 3 }}
                alignItems="flex-start"
              >
                <Typography pt={2} color={CARD_TEXT_COLOR}>
                  {t('userProfile.activity.trainerProgramComponent.duration')}
                </Typography>
                <Typography pb={2} fontWeight={500} color="primary">
                  {duration}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={6} lg={6} item>
              <Grid
                xs={12}
                item
                display="flex"
                flexDirection="column"
                pr={{ lg: 3, xs: 3 }}
                alignItems="flex-end"
              >
                <Typography pt={2} color={CARD_TEXT_COLOR}>
                  {t('userProfile.activity.trainerProgramComponent.credits').toUpperCase()}
                </Typography>
                <Typography pb={2} fontWeight={500} color="primary">
                  {credits}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item display="flex" flexDirection={{ xs: 'column', lg: 'row' }} width="100%">
        <Grid lg={7} item borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
          <Grid flexWrap="noWrap" p={3} display="flex" flexDirection="column">
            <Typography color={CARD_TEXT_COLOR}>
              {t('userProfile.activity.trainerProgramComponent.description').toUpperCase()}
            </Typography>
            <Linkify>
              <Typography>{description}</Typography>
            </Linkify>
            <ContentCopy sx={{ color: PRIMARY_COLOR, marginTop: 2, cursor: 'pointer' }} onClick={() => {
              navigator.clipboard.writeText(`${window.location.href}&subscriptionId=${id}`);
              toast.info("Successfully copied to clipboard", { position: 'bottom-right' })
            }} />
          </Grid>
        </Grid>
        <Grid item lg={5} pt={1} pl={3} pr={3}>
          <Grid container display="flex" flexDirection="column">
            <Grid container width="100%" display="flex" flexDirection="row">
              <Grid item xs={6} display="flex" justifyContent="flex-start" flexDirection="column">
                <Typography color={CARD_TEXT_COLOR}>
                  {t('userProfile.activity.trainerProgramComponent.available').toUpperCase()}
                </Typography>
                <Typography
                  color={computeColor()}
                >
                  {computeText()}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                display="flex"
                flexDirection="column"
                alignItems={{ lg: 'flex-end', xs: 'flex-end' }}
              >
                <Typography color={CARD_TEXT_COLOR}>
                  {t('userProfile.activity.trainerProgramComponent.trainer').toUpperCase()}
                </Typography>
                {Trainers?.length
                  ? Trainers.map(({ firstName, lastName, domain }, index) => {
                    return (
                      <Link key={index} style={{ textDecoration: 'none' }} to={'/' + domain}>
                        <Typography
                          display="flex"
                          justifyContent="flex-end"
                          sx={{ textDecoration: 'underline', textAlign: 'right' }}
                          fontWeight={600}
                          color="primary"
                        >{`${firstName} ${lastName}`}</Typography>
                      </Link>
                    )
                  })
                  : null}
                {!Trainers?.length ? (
                  <Typography
                    display="flex"
                    justifyContent="flex-end"
                    sx={{ textAlign: 'right' }}
                    alignItems={'flex-end'}
                    pb={2}
                    fontWeight={600}
                    color="primary"
                  >
                    N / A
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid pt={{ xs: 2, lg: 2 }} pb={{ xs: 2, lg: 2 }} item width="100%">
            <Button
              disabled={
                !user || !isAvailable || isTrainer || isBusinessAdministrator ||
                isClubAdministrator ||
                (hasLimitedActivation && limitedActivationUsed <= 0) ||
                (hasLimitedActivation && limitedActivation === 0)
              }
              onClick={() => {
                navigate(`/checkout?subscription=${id}`)
              }}
              fullWidth
              variant="contained"
              color={priceRon === 0 ? 'success' : 'primary'}
            >
              {priceRon === 0
                ? t('userProfile.activity.trainerProgramComponent.free')
                : `${t('userProfile.activity.trainerProgramComponent.buyFor')} ${(priceRon || '')} ${currency}`}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Subscription
