import axios from './axios'

export const getTrainerByDomain = (domain) => {
  return axios.get('/public/trainers/' + domain).then((res) => res.data)
}

export const getTrainersForReview = () => {
  return axios.get('/users/reviews/trainers').then((res) => res.data)
}

export const getTrainerById = (id) => {
  return axios.get(`/users/${id}`).then((res) => res.data)
}

export const createQualification = (payload) => {
  return axios.post('/trainers/qualifications', payload).then((res) => res.data)
}

export const deleteQualification = (id) => {
  return axios.delete('/trainers/qualifications/' + id).then((res) => res.data)
}

export const assignToCompany = (companyId) => {
  return axios.post('/trainers/companies/' + companyId).then((res) => res.data)
}

export const unassignToCompany = (companyId) => {
  return axios.delete('/trainers/companies/' + companyId).then((res) => res.data)
}

export const createLicence = ({ documentSrc, description }) => {
  return axios.post('/trainers/licences', { documentSrc, description }).then((res) => res.data)
}

export const updateLicence = (licenceId, { documentSrc, description }) => {
  return axios
    .patch('/trainers/licences/' + licenceId, { documentSrc, description })
    .then((res) => res.data)
}

export const deleteLicence = (licenceId) => {
  return axios.delete('/trainers/licences/' + licenceId).then((res) => res.data)
}

export const getTrainersClients = (userId) => {
  return axios.get('/trainers/clients', { userId }).then((res) => res.data)
}

export const deleteTrainerClients = (userId) => {
  return axios.delete('/trainers/clients/' + userId).then((res) => res.data)
}

export const getTrainerServices = () => {
  return axios.get('/trainers/services/all').then((res) => res.data)
}

export const getTrainerCourses = () => {
  return axios.get('/trainers/courses/all').then((res) => res.data)
}

export const getTrainerServicesByDomain = (domain) => {
  return axios.get('/public/trainers/services/all/' + domain).then((res) => res.data)
}

export const getCompanies = () => {
  return axios.get('/trainers/companies').then((res) => res.data)
}

export const getTrainerSubscriptions = (search = '') => {
  return axios.get(`/trainers/subscriptions/all?search=${search}`).then((res) => res.data)
}

export const getTrainerSubscriptionsByDomain = (domain) => {
  return axios.get('/public/trainers/subscriptions/all/' + domain).then((res) => res.data)
}

export const getTrainerReviews = () => {
  return axios.get('/trainers/reviews/all').then((res) => res.data)
}

export const getTrainerReviewsByDomain = (domain, userId) => {
  return axios.get(`/public/trainers/reviews/all/${domain}/${userId}`).then((res) => res.data)
}

export const createTrainerReview = (domain, payload) => {
  return axios.post('/trainers/reviews/' + domain, payload).then((res) => res.data)
}

export const deleteService = (id) => {
  return axios.delete(`/trainers/services/${id}`).then((res) => res.data)
}
export const deleteCourse = (id) => {
  return axios.delete(`/trainers/courses/${id}`).then((res) => res.data)
}

export const deleteSubscription = (id) => {
  return axios.delete(`/subscriptions/${id}`).then((res) => res.data)
}

export const isTrainer = (domain) => {
  return axios.get('/public/trainers/domainExists/' + domain).then((res) => res.data)
}

export const getAssociatedGyms = () => {
  return axios.get('/trainers/associated-gyms').then((res) => res.data)
}

export const updateAssociationStatus = (id, status) => {
  return axios.patch('/companies-trainers/' + id, { status }).then((res) => res.data)
}

export const getTrainerCurrency = () => {
  return axios.get('/trainers/currency').then((res) => res.data)
}
export const addDeal = (domain, payload) => {
  return axios.post(`/trainers/deals/${domain}`, payload).then((res) => res.data)
}

export const deleteDeal = (dealId) => {
  return axios.delete(`/trainers/deals/${dealId}`).then((res) => res.data)
}

export const updateDeal = (dealId, payload) => {
  return axios.patch(`/trainers/deals/${dealId}`, payload).then((res) => res.data)
}
export const getDeal = (dealId) => {
  return axios.get(`/trainers/deals/${dealId}`).then((res) => res.data)
}
export const getDeals = (trainerId) => {
  return axios.get(`/trainers/deals/allTrainer/${trainerId}`).then((res) => res.data)
}
