import axios from './axios'

export const getEventsByDays = (
    startDay, 
    endDay, 
    type, 
    companyId, 
    onlyMine,
    favourites,
    daysArray,
    showPastEvents,
    isUser,
    userId,
    userCompanyId,
    isTrainer,
    isClub,) => {
    return axios
        .get(`/calendar/events/${type}?start=${startDay}&end=${endDay}&companyId=${companyId}&onlyMine=${
            onlyMine ? 'yes' : 'no'
          }&trainers=${
            favourites &&
            favourites
              .filter((el) => el.type === 'trainer')
              .map((el) => el.id.split('-')[0])
              .join(',')
          }&companies=${
            favourites &&
            favourites
              .filter((el) => el.type === 'company')
              .map((el) => el.id.split('-')[0])
              .join(',')
          }${daysArray.map(day => `&daysArray=${day}`).join("")}&showPastEvents=${showPastEvents}&isUser=${isUser}&userId=${userId}&userCompanyId=${userCompanyId}&isTrainer=${isTrainer}&isClub=${isClub}`)
        .then((res) => res.data)
}

export const getEventsByDaysForIndividual = (startDay, endDay, onlyMine, favourites) => {
    return axios
        .get(`/calendar/events/individual?start=${startDay}&end=${endDay}&onlyMine=${onlyMine ? 'yes' : 'no'}&trainers=${favourites && favourites.filter(el => el.type === 'trainer').map(el => el.id.split('-')[0]).join(',')}&companies=${favourites && favourites.filter(el => el.type === 'company').map(el => el.id.split('-')[0]).join(',')}`)
        .then((res) => res.data)
}

export const getEventsByDaysForDomain = (startDay, endDay, domain, onlyMine, favourites) => {
    return axios
        .get(`public/calendar/events/${domain}?start=${startDay}&end=${endDay}&onlyMine=${onlyMine ? 'yes' : 'no'}&trainers=${favourites && favourites.filter(el => el.type === 'trainer').map(el => el.id.split('-')[0]).join(',')}&companies=${favourites && favourites.filter(el => el.type === 'company').map(el => el.id.split('-')[0]).join(',')}`)
        .then((res) => res.data)
}
// New calendar
export const getCalendarForDomain = async (
    domain,
    startDay,
    endDay,
    onlyMine,
    favourites,
    daysArray,
    showPastEvents,
    isUser,
    userId,
    userCompanyId,
    isTrainer,
    isClub,
  ) => {
    return await axios.get(
      `public/new-calendar/events/${domain}?start=${startDay}&end=${endDay}&onlyMine=${
        onlyMine ? 'yes' : 'no'
      }&trainers=${
        favourites &&
        favourites
          .filter((el) => el.type === 'trainer')
          .map((el) => el.id.split('-')[0])
          .join(',')
      }&companies=${
        favourites &&
        favourites
          .filter((el) => el.type === 'company')
          .map((el) => el.id.split('-')[0])
          .join(',')
      }${daysArray.map(day => `&daysArray=${day}`).join("")}&showPastEvents=${showPastEvents}&isUser=${isUser}&userId=${userId}&userCompanyId=${userCompanyId}&isTrainer=${isTrainer}&isClub=${isClub}`
    )
    .then(res => res.data)
  }

export const getCalendarForIndividual = async (
    startDay,
    endDay,
    onlyMine,
    favourites,
    daysArray,
    showPastEvents,
    isUser,
    userId,
    userCompanyId,
    isTrainer,
    isClub,
    ) => {
    return await axios.get(
        `/calendar/events/new-individual?start=${startDay}&end=${endDay}&onlyMine=${
            onlyMine ? 'yes' : 'no'
        }&trainers=${
            favourites &&
            favourites
              .filter((el) => el.type === 'trainer')
              .map((el) => el.id.split('-')[0])
              .join(',')
          }&companies=${
            favourites &&
            favourites
              .filter((el) => el.type === 'company')
              .map((el) => el.id.split('-')[0])
              .join(',')
          }${daysArray.map(day => `&daysArray=${day}`).join("")}&showPastEvents=${showPastEvents}&isUser=${isUser}&userId=${userId}&userCompanyId=${userCompanyId}&isTrainer=${isTrainer}&isClub=${isClub}`
    )
    .then(res => res.data)
}

export const getCalendarServicesByDaysForDomain = (startDay, endDay, domain, onlyMine, favourites) => {
    return axios
        .get(`public/calendar/calendar-services/${domain}?start=${startDay}&end=${endDay}&onlyMine=${onlyMine ? 'yes' : 'no'}&trainers=${favourites && favourites.filter(el => el.type === 'trainer').map(el => el.id.split('-')[0]).join(',')}&companies=${favourites && favourites.filter(el => el.type === 'company').map(el => el.id.split('-')[0]).join(',')}`)
        .then((res) => res.data)
}

export const getEventById = (eventId) => {
    return axios
        .get(`/public/events/${eventId}`)
        .then((res) => res.data)
}

export const getCheckinsForEvent = (eventId) => {
    return axios
        .get(`/public/events/${eventId}/checkins`)
        .then((res) => res.data)
}

export const getCheckinsForEvents = (eventIds) => {
    return axios
        .get(`/public/events/checkins?eventIds=${eventIds}`)
        .then((res) => res.data)
}

export const createEvent = (event, type, companyId) => {
    return axios
        .post(`/calendar/events/${type}`, { event, companyId })
        .then((res) => res.data)
}

export const editEvent = (event, type, id) => {
    return axios
        .put(`/calendar/events/${type}/${id}`, event)
        .then((res) => res.data)
}

export const getSavedSchedules = (type, companyId) => {
    return axios
        .get(`/calendar/schedules/${type}?companyId=${companyId}`)
        .then((res) => res.data)
}


export const createSchedule = (schedule, type, companyId) => {
    return axios
        .post(`/calendar/schedules/${type}`, { schedule, companyId })
        .then((res) => res.data)
}

export const deleteEvent = (eventId) => {
    return axios
        .delete(`/calendar/events/${eventId}`)
        .then((res) => res.data)
}

export const bookEvent = (eventId, userSubscriptionId) => {
    return axios
        .post(`/calendar/events/${eventId}/book`, { userSubscriptionId })
        .then((res) => res.data)
}

export const unbookEvent = (eventId) => {
    return axios
        .post(`/calendar/events/${eventId}/unbook`)
        .then((res) => res.data)
}


export const addParticipantToEvent = (eventId, participantId, userSubscriptionId) => {
    return axios
        .post(`/calendar/events/${eventId}/participants/${participantId}`, { userSubscriptionId })
        .then((res) => res.data)
}

export const removeParticipantFromEvent = (eventId, participantId) => {
    return axios
        .delete(`/calendar/events/${eventId}/participants/${participantId}`)
        .then((res) => res.data)
}

export const addTrainerToEvent = (eventId, trainerId) => {
    return axios
        .post(`/calendar/events/${eventId}/trainers/${trainerId}`)
        .then((res) => res.data)
}

export const removeTrainerFromEvent = (eventId, trainerId) => {
    return axios
        .delete(`/calendar/events/${eventId}/trainers/${trainerId}`)
        .then((res) => res.data)
}

export const confirmPresenceToEvent = (eventId, userId) => {
    return axios
        .post(`/checkins/events/${eventId}`, { userId })
        .then((res) => res.data)
}

export const createCalendarService = (payload) => {
    return axios
        .post(`/calendar/calendar-services`, { ...payload })
        .then((res) => res.data)
}

export const editCalendarService = (id, payload) => {
    return axios
        .put(`/calendar/calendar-services/${id}`, { ...payload })
        .then((res) => res.data)
}

export const deleteCalendarService = (id, day) => {
    return axios
        .delete(`/calendar/calendar-services/${id}?day=${day}`)
        .then((res) => res.data)
}

export const getCalendarServicesByDomain = (domain) => {
    return axios
        .get(`/calendar/calendar-services/domain/${domain}`)
        .then((res) => res.data)
}

export const getCalendarServicesByDay = (startDay, endDay, type, companyId) => {
    return axios
        .get(`/calendar/calendar-services?start=${startDay}&end=${endDay}&companyId=${companyId}`)
        .then((res) => res.data)
}

export const getCalendarServicesByDaysForIndividual = (startDay, endDay, onlyMine, favourites) => {
    return axios
        .get(`/calendar/calendar-services/individual?start=${startDay}&end=${endDay}&onlyMine=${onlyMine ? 'yes' : 'no'}&trainers=${favourites && favourites.filter(el => el.type === 'trainer').map(el => el.id.split('-')[0]).join(',')}&companies=${favourites && favourites.filter(el => el.type === 'company').map(el => el.id.split('-')[0]).join(',')}`)
        .then((res) => res.data)
}

export const getCalendarServiceById = (id, day) => {
    return axios
        .get(`/calendar/calendar-services/${id}?day=${day}`)
        .then((res) => res.data)
}

export const getPublicCalendarServiceById = (id, day) => {
    return axios
        .get(`/public/calendar-services/${id}?day=${day}`)
        .then((res) => res.data)
}

export const getPublicCalendarByDomain = (domain, isTrainer) => {
    return axios
        .get(`/public/calendar-services/domain/${domain}?isTrainer=${isTrainer}`)
        .then((res) => res.data)
}

export const bookCalendarService = (payload) => {
    return axios
        .post(`/calendar/calendar-services/${payload.calendarServiceId}/book`, { ...payload })
        .then((res) => res.data)
}

export const unbookCalendarService = (payload) => {
    return axios
        .post(`/calendar/calendar-services/${payload.calendarServiceId}/unbook`, { ...payload })
        .then((res) => res.data)
}

export const addTrainerToService = (payload) => {
    return axios
        .post(`/calendar/calendar-services/${payload.calendarServiceId}/trainers/${payload.trainerId}`)
        .then((res) => res.data)
}

export const removeTrainerFromService = (payload) => {
    return axios
        .delete(`/calendar/calendar-services/${payload.calendarServiceId}/trainers/${payload.trainerId}`)
        .then((res) => res.data)
}