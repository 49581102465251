import { Button, ButtonGroup, Container, Grid, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Stack from '@mui/material/Stack';

import SuperAdminClubs from './SuperAdminClubs'
import SuperAdminIndividuals from './SuperAdminIndividuals'
import SuperAdminLicenses from './SuperAdminLicenses'
import SuperAdminProfessionals from './SuperAdminProfessionals'

import * as SUPER_ADMIN_REQUESTS from '../../api/superAdmin'
import SuperAdminPayments from './SuperAdminPayments'
import SuperAdminHistory from './SuperAdminHistory'
import SuperAdminTransactions from './SuperAdminTransactions'
import SuperAdminDeals from './SuperAdminDeals'
import SuperAdminCompanies from './SuperAdminCompanies'
import { AntSwitch } from './components';

const SuperAdmin = () => {
    const theme = useTheme()

    const [active, setActive] = useState('individuals')
    const [search, setSearch] = useState('')
    const [app, setApp] = useState('sportin')
    const [counts, setCounts] = useState({})

    const matchesPhone = useMediaQuery(theme.breakpoints.down('sm'))

    const handleGetCounts = useCallback(async () => {
        try {
            const countsResponse = await SUPER_ADMIN_REQUESTS.getCounts()
            setCounts(countsResponse)
        } catch (e) {
            console.error(e)
        }
    }, [])

    useEffect(() => {
        handleGetCounts()
    }, [])

    const renderContent = useMemo(() => {
        if (active === 'individuals') return <SuperAdminIndividuals search={search} app={app} />
        if (active === 'deals') return <SuperAdminDeals />
        if (active === 'professionals') return <SuperAdminProfessionals search={search} />
        if (active === 'clubs') return <SuperAdminClubs search={search} />
        if (active === 'companies') return <SuperAdminCompanies search={search} />
        if (active === 'licenses') return <SuperAdminLicenses />
        if (active === 'payments') return <SuperAdminPayments />
        if (active === 'history') return <SuperAdminHistory search={search} />
        if (active === 'transactions') return <SuperAdminTransactions />
    }, [active, search, app])

    return (
        <Container maxWidth={`xl`}>
            <Grid
                container
                pt={8}
                pb={8}
                display="flex"
                flexDirection="column"
                width="100%"
            >
                <Grid item xs={12}>
                    <Typography variant='h6'>Super Admin Dashboard</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle2'>Adrian, here you are able to do whatever you want. Enjoy, but be careful!</Typography>
                </Grid>
                <Grid item xs={12} pt={3}>
                    <Grid container display="flex" flexDirection={{ sx: 'column', md: 'row' }}>
                        <Grid item xs={12} md={12} fullWidth pb={{ xs: 2, md: 0 }}>
                            <ButtonGroup fullWidth aria-label="outlined primary button group" orientation={matchesPhone ? 'vertical' : 'horizontal'} >
                                <Button fullWidth sx={{ minWidth: 200 }} onClick={() => setActive('individuals')} variant={active === 'individuals' ? 'contained' : 'outlined'}>Individuals ({counts.individualsCount})</Button>
                                <Button sx={{ minWidth: 200 }} onClick={() => setActive('professionals')} variant={active === 'professionals' ? 'contained' : 'outlined'}>Professionals ({counts.trainersCount})</Button>
                                <Button sx={{ minWidth: 200 }} onClick={() => setActive('clubs')} variant={active === 'clubs' ? 'contained' : 'outlined'}>Clubs ({counts.clubsCount})</Button>
                                <Button sx={{ minWidth: 200 }} onClick={() => setActive('companies')} variant={active === 'companies' ? 'contained' : 'outlined'}>Companies ({counts.companiesCount})</Button>
                                <Button sx={{ minWidth: 200 }} onClick={() => setActive('deals')} variant={active === 'deals' ? 'contained' : 'outlined'}>Deals </Button>
                                <Button sx={{ minWidth: 200 }} onClick={() => setActive('licenses')} variant={active === 'licenses' ? 'contained' : 'outlined'}>Licenses</Button>
                                <Button sx={{ minWidth: 200 }} onClick={() => setActive('payments')} variant={active === 'payments' ? 'contained' : 'outlined'}>Payments</Button>
                                <Button sx={{ minWidth: 200 }} onClick={() => setActive('history')} variant={active === 'history' ? 'contained' : 'outlined'}>History</Button>
                                <Button sx={{ minWidth: 200 }} onClick={() => setActive('transactions')} variant={active === 'transactions' ? 'contained' : 'outlined'}>Club Transactions</Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid
                            container
                            display='flex'
                            flexDirection='row'
                            justifyContent='space-between'
                            alignItems="center"
                        >
                            {active !== 'licenses' && active !== 'deals' && active !== 'transactions' && (
                                <Grid item xs={12} md={6}>
                                    <TextField fullWidth placeholder={`Search for ${active}`} value={search} onChange={e => setSearch(e.target.value)} />
                                </Grid>
                            )}
                            {active === 'individuals' && (
                                <Grid item xs={12} md={6} display="flex" justifyContent="flex-end">
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography variant="subtitle1">Challenges ({counts.individualsChallenges})</Typography>
                                        <AntSwitch checked={app == 'sportin'} onChange={() => setApp((prev) => prev === 'sportin' ? 'sportin-challenges' : 'sportin')} inputProps={{ 'aria-label': 'ant design' }} />
                                        <Typography variant="subtitle1">Sportin ({counts.individualsSportin})</Typography>
                                    </Stack>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} pt={3}>
                    {renderContent}
                </Grid>
            </Grid>
        </Container >
    )
}

export default SuperAdmin