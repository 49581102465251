import React, { useCallback, useEffect, useState } from 'react'
import { Avatar, Box, Container, Divider, Grid, ButtonGroup, Typography, Button, Chip, Tooltip, TextField } from '@mui/material'
import { ArrowLeft, CheckCircle } from '@mui/icons-material'
import moment from 'moment'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { toast } from 'react-toastify'
import parse from 'html-react-parser'

import * as CALENDAR_REQUESTS from '../../../api/calendar'
import * as USER_REQUESTS from '../../../api/user'
import * as VOUCHER_REQUESTS from '../../../api/voucher'
import { useAuthentication } from '../../../context/auth-context'

import { getStatus } from '../../../utils/helpers/userSubscription'
import { CARD_TEXT_COLOR, ERROR_COLOR, ORANGE_COLOR, PRIMARY_COLOR, ROLE_TRAINER, WHITE_COLOR } from '../../../utils/constants'
import { useTranslation } from 'react-i18next'
import Subscription from '../../PublicBusinessProfile/subcomponents/subcomponents/subcomponents/Subscription'

const ERRORS_MAPPING = {
    'CURRENT_TIME_LOWER_THAN_CANCEL_TIME': 'Looks like the current time is lower than maximum cancel time admitted.',
    'BUFFER_BEFORE_EXCEEDED': 'Looks like the before buffer was exceeded. Please contact the service administrator!',
    'ALREADY_IN_PARTICIPANTS_LIST': 'Looks like you are already in the participants list',
    'CURRENT_TIME_HIGHER_THAN_BOOK_TIME': 'Looks like current time si higher than minimum book time',
    'MAX_NUMBER_OF_PARTICIPANTS_AND_NO_WAITING_LIST': 'Looks like maximum number of participants has been reached',
    'MAX_NUMBER_OF_PARTICIPANTS_WITH_WAITING_LIST': 'Looks like maximum number of participants from waiting list has been reached',
    'USER_SUBSCRIPTION_NOT_EXISTS': 'Looks like your subscription does\'nt exists',
    'NOT_ENOUGH_CREDITS': 'Looks like you don\'t have enough credits',
}

const CalendarService = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const { user } = useAuthentication()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const params = useParams()
    const isTrainer = user && user.role && user.role.toLowerCase() === ROLE_TRAINER.toLowerCase()

    const [loading, setLoading] = useState(false)
    const [queryChecked, setQueryChecked] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [service, setService] = useState()
    const [reservations, setReservations] = useState([])
    const [day, setDay] = useState()
    const [selectedSlot, setSelectedSlot] = useState()
    const [selectedCost, setSelectedCost] = useState()
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState()
    const [selectedSubscription, setSelectedSubscription] = useState()
    const [userSubscriptions, setUserSubscriptions] = useState([])
    const [postalCode, setPostalCode] = useState()
    const [address, setAddress] = useState()
    const [subscriptionVoucherIds, setSubscriptionVoucherIds] = useState([])


    const handleGetCalendarServiceById = useCallback(async () => {
        try {
            if (!searchParams.get('day')) return
            const serviceResponse = await CALENDAR_REQUESTS.getPublicCalendarServiceById(params.ID, searchParams.get('day'))
            setService(serviceResponse)
            if (serviceResponse.slots?.length === 1) {
                setSelectedSlot(serviceResponse.slots[0])
            }
            setDay(moment(serviceResponse.start))
            if (user) {
                setReservations(serviceResponse.slots.filter(el => el.participants?.map(p => p.id).includes(user.id) || el.waitingList?.map(p => p.id).includes(user.id))?.map(el => el.index))
            }
            setLoaded(true)
        } catch (e) {
            console.error(e)
        }
    }, [params, user])
    useEffect(() => {
        if (!service?.companyDomain) return
        VOUCHER_REQUESTS.getSubscriptionIdsWithVouchers(service?.companyDomain)
            .then(response => {
                setSubscriptionVoucherIds(response)
            })
    }, [service])

    const handleGetUserSubscriptions = useCallback(async () => {
        try {
            if (!user) return setUserSubscriptions([])
            const subscriptionsResponse = await USER_REQUESTS.getSubscriptions({
                userId: user.id
            })
            setUserSubscriptions(subscriptionsResponse.filter(el => {
                const slotFrom = moment(searchParams.get('day')).set('hours', selectedSlot.start.split(':')[0]).set('minutes', selectedSlot.start.split(':')[1])
                return service.subscriptions?.map(s => s.id).includes(el.Subscription.id) && !getStatus(el, slotFrom).notStarted && (getStatus(el, slotFrom).active || getStatus(el, slotFrom).paymentDeadlineActive)
            }))
        } catch (e) {
            console.error(e)
        }
    }, [user, service, selectedSlot])

    useEffect(() => {
        handleGetCalendarServiceById()
    }, [params])

    useEffect(() => {
        if (!service || !selectedSlot) return
        handleGetUserSubscriptions()

    }, [service, selectedSlot])

    useEffect(() => {
        if (searchParams && service && !queryChecked) {
            const querySelectedSlot = searchParams.get('selectedSlot')
            if (querySelectedSlot && querySelectedSlot !== 'unset') setSelectedSlot(findSelectedSlot(Number(querySelectedSlot)))

            const querySelectedCost = searchParams.get('selectedCost')
            if (querySelectedCost && querySelectedCost !== 'unset') setSelectedCost(querySelectedCost)

            const querySelectedPaymentMethod = searchParams.get('selectedPaymentMethod')
            if (querySelectedPaymentMethod && querySelectedPaymentMethod !== 'unset') setSelectedPaymentMethod(querySelectedPaymentMethod)

            const querySelectedSubscription = searchParams.get('selectedSubscription')
            if (querySelectedSubscription && querySelectedSubscription !== 'unset') setSelectedSubscription(querySelectedSubscription)

            const querySuccessRegister = searchParams.get('successRegister')
            if (querySuccessRegister && !queryChecked) {
                toast.success("Congrats! Your account was successfully created. You have one more step in order to book your service!", { position: 'bottom-right' })
            }
            setQueryChecked(true)
        }
        const queryDay = searchParams.get('day')
        if (queryDay && queryDay !== 'unset') setDay(moment(queryDay))
    }, [searchParams, queryChecked, service])

    const findSelectedSlot = (slotId) => {
        if (!slotId || !service) return
        const slotPosition = service.slots.findIndex(el => el.index === slotId)
        return service.slots[slotPosition]
    }

    const handleCreateQueryParams = useCallback(() => {
        return `?serviceId=${service.id}&selectedSlot=${selectedSlot ? selectedSlot.index : 'unset'}&selectedSubscription=${selectedSubscription || 'unset'}&selectedCost=${selectedCost || 'unset'}&selectedPaymentMethod=${selectedPaymentMethod || 'unset'}&day=${day || 'unset'}`
    }, [selectedSlot, service, selectedCost, selectedPaymentMethod, selectedSubscription, day])

    const handleSubmit = async () => {
        const slotId = findSelectedSlot(selectedSlot.index).index

        if (selectedPaymentMethod === 'ONLINE') {
            return navigate(`/pay-card?address=${address}&postalCode=${postalCode}&calendarServiceId=${service.id}&slotId=${slotId}&companyName=${service.companyName}&companyAvatar=${service.companyAvatar}&price=${service.valueRon}&calendarServiceName=${service.name}&companyLocationName=${service.companyLocationName}&date=${moment(service.start).format('YYYY-MM-DD')}&startHour=${findSelectedSlot(selectedSlot.index).start}&endHour=${findSelectedSlot(selectedSlot.index).end}`)
        }

        const payload = {}

        if (selectedSubscription && selectedCost === 'CREDITS') {
            payload['subscriptionId'] = selectedSubscription
        }
        payload['userId'] = user.id
        payload['slotId'] = slotId
        payload['calendarServiceId'] = service.id
        payload['paymentMethod'] = selectedPaymentMethod

        try {
            setLoading(true)
            const response = await CALENDAR_REQUESTS.bookCalendarService(payload)
            if (response.isInWaitingList) {
                toast.warning('You have been successfully added into the waiting list of this service. More information will be received via email!')
            }
            else {
                toast.success("You have successfully booked this service!", { position: 'bottom-right' })
            }
            setSelectedSlot()
            setSelectedCost()
            setSelectedPaymentMethod()
            setSelectedSubscription()
            handleGetCalendarServiceById()
            setLoading(false)
        }
        catch (e) {
            let valid = false
            if (e && e.response && e.response.data) {
                if (e.response.data.split('\n')?.length > 0) {
                    if (e.response.data.split('\n')[0].split('Error: ')?.length > 1) {
                        toast.error(ERRORS_MAPPING[e.response.data.split('\n')[0].split('Error: ')[1]], { position: 'bottom-right' })
                        valid = true
                    }
                }
            }

            if (!valid) toast.error("Ups! Something went wrong", { position: 'bottom-right' })
            setLoading(false)
        }
    }

    const handleDeleteParticipant = async (slotId) => {
        const payload = {}

        payload['userId'] = user.id
        payload['slotId'] = slotId
        payload['calendarServiceId'] = service.id

        try {
            setLoading(true)
            await CALENDAR_REQUESTS.unbookCalendarService(payload)
            toast.success("You have successfully removed client from this service!", { position: 'bottom-right' })
            navigate(`/calendar/services/${service.id}?day=${searchParams.get('day')}`)
            setLoading(false)
        }
        catch (e) {
            let valid = false
            if (e && e.response && e.response.data) {
                if (e.response.data.split('\n')?.length > 0) {
                    if (e.response.data.split('\n')[0].split('Error: ')?.length > 1) {
                        toast.error(ERRORS_MAPPING[e.response.data.split('\n')[0].split('Error: ')[1]], { position: 'bottom-right' })
                        valid = true
                    }
                }
            }

            if (!valid) toast.error("Ups! Something went wrong", { position: 'bottom-right' })
            setLoading(false)
        }
    }

    const renderServiceDetails = useCallback(() => {
        return (
            <Grid container display="flex" flexDirection="column">
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item xs={12}>
                            <Grid container display="flex" flexDirection="column">
                                <Grid item xs={12}>
                                    <Avatar src={service.companyAvatar} sx={{ cursor: 'pointer', width: 90, height: 90, border: `2px solid ${WHITE_COLOR}` }}>AA</Avatar>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="row" alignItems={"center"}>
                                        <Typography sx={{ textDecoration: 'underline', color: PRIMARY_COLOR, cursor: 'pointer' }} onClick={() => navigate(`/${service.companyDomain}`)} pr={.5} fontWeight={"bold"} variant="h6">{service.companyName}</Typography>
                                        <CheckCircle sx={{ fontSize: 20 }} color="primary" />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography fontWeight={"bold"} variant="subtitle2" color={CARD_TEXT_COLOR}>Club</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="column">
                        <Grid item xs={12}>
                            <Typography variant="h6" fontWeight={"bold"}>{service.name}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" flexDirection="row">
                                <Typography variant="subtitle1" fontWeight={"bold"} pr={1}>Duration:</Typography>
                                <Typography variant="subtitle1">{service.duration} {service.duration ? 'min' : '-'}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" flexDirection="row">
                                <Typography variant="subtitle1" fontWeight={"bold"} pr={1}>Availability:</Typography>
                                <Typography variant="subtitle1">{service.access.join(', ')}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} pt={2.5}>
                    <Grid container display="flex" flexDirection="column">
                        <Grid item xs={12} pb={1}>
                            <Divider textAlign='left'>
                                <Typography variant="subtitle1" fontWeight={"bold"} color={CARD_TEXT_COLOR}>ACCESS</Typography>
                            </Divider>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="subtitle1" fontWeight={"bold"} pr={1}>Subscription:</Typography>
                                <Box display="flex" flexDirection={"row"} flexWrap="wrap">
                                    {service.subscriptions?.map((el, index) => <><Typography sx={{
                                        textDecoration: 'underline',
                                        color: PRIMARY_COLOR,
                                        fontWeight: 500,
                                        cursor: 'pointer'
                                    }} onClick={() => navigate(`/${service.companyDomain}?active_option=2&subscriptionId=${el.id}`)} variant="subtitle1">{el.name}{index === service.subscriptions?.length - 1 ? '' : ','}</Typography>&nbsp;</>)}
                                </Box>

                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box display="flex" flexDirection="row">
                                <Typography variant="subtitle1" fontWeight={"bold"} pr={1}>Cost:</Typography>
                                <Typography variant="subtitle1"> {service.valueCredits} Credits</Typography>
                            </Box>
                        </Grid>
                        {service.trainers?.length > 0 ? <Grid item xs={12}>
                            <Box display="flex" flexDirection="row">
                                <Typography variant="subtitle1" fontWeight={"bold"} pr={1}>Trainers:</Typography>
                                <Box>
                                    <Grid item xs={12} display="flex" flexDirection="row">
                                        {service.trainers?.map(trainer => (
                                            <Tooltip title={trainer.name}>
                                                <Avatar src={trainer.avatarPhotoSrc} sx={{ cursor: 'pointer', width: 25, height: 25, border: `2px solid ${WHITE_COLOR}` }}><Typography variant="caption" fontWeight="bold">{trainer.firstName.charAt(0).toUpperCase()}{trainer.lastName.charAt(0).toUpperCase()}</Typography></Avatar>
                                            </Tooltip>

                                        ))}
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid> : null}
                    </Grid>
                </Grid>
                <Grid item xs={12} pt={2.5}>
                    <Grid container display="flex" flexDirection="column">
                        <Grid item xs={12} pb={1}>
                            <Divider textAlign='left'>
                                <Typography variant="subtitle1" fontWeight={"bold"} color={CARD_TEXT_COLOR}>LOCATION</Typography>
                            </Divider>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" sx={{ textDecoration: 'underline' }}>{service.location}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} pt={2.5}>
                    <Grid container display="flex" flexDirection="column">
                        <Grid item xs={12}>
                            <Divider textAlign='left'>
                                <Typography variant="subtitle1" fontWeight={"bold"} color={CARD_TEXT_COLOR}>DESCRIPTION</Typography>
                            </Divider>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">{parse(service.notes || '')}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        )
    }, [service])

    const renderCalendar = useCallback(() => {
        return (
            <Grid container display="flex" flexDirection="column" justifyContent="center" boxShadow={" rgba(0, 0, 0, 0.04) 0px 3px 5px;"}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateCalendar disablePast shouldDisableDate={date => {
                        return service.availableDates.includes(moment(date).format('YYYY-MM-DD')) ? false : true
                    }} value={day} onChange={(newValue) => {
                        setSelectedSlot()
                        navigate(`/calendar/services/${service.id}?day=${moment(newValue).format('MM/DD/YYYY')}`)
                    }} />

                </LocalizationProvider>
            </Grid>
        )
    }, [day, service])

    // TODO: Remove filter for ONLINe
    const renderAvailableSpots = useCallback(() => {
        const queryParams = handleCreateQueryParams()
        if (selectedSlot) {
            return (
                <Grid container display="flex" flexDirection="column">
                    <Grid item xs={12} sx={{
                        boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px;',
                        cursor: 'pointer',
                        border: `2px solid ${PRIMARY_COLOR}`,
                    }} p={2} mb={2} onClick={() => setSelectedSlot()}>
                        <Box display="flex" flexDirection="row">
                            <Typography variant="subtitle2" fontWeight="bold">{findSelectedSlot(selectedSlot.index).start} - {findSelectedSlot(selectedSlot.index).end}</Typography>
                            <Box ml="auto">
                                <CheckCircle sx={{ fontSize: 20 }} color="primary" />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} pb={2}>
                        <Grid container display="flex" flexDirection="column">
                            <Grid item xs={12} pb={1}>
                                <Divider textAlign='left'>
                                    <Typography variant="subtitle1" fontWeight={"bold"} color={CARD_TEXT_COLOR}>HOW DO YOU WANT TO PAY?</Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">{service.paymentMethods?.length === 0 ? `This service is free, enjoy!` : `This service can be paid with one of the following options.`}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} pb={3}>
                        <ButtonGroup fullWidth aria-label="outlined primary button group">
                            {service.costs?.map((cost) => (
                                <Button onClick={() => {
                                    setSelectedCost(cost)
                                    setSelectedPaymentMethod()
                                    setSelectedSubscription()
                                }} variant={cost === selectedCost ? 'contained' : 'outlined'}>{cost}</Button>
                            ))}
                        </ButtonGroup>
                    </Grid>
                    {selectedCost === service.companyCurrency && <Grid item xs={12} pb={3}>
                        <Grid container display="flex" flexDirection="column">
                            <Grid item xs={12} pb={1}>
                                <Divider textAlign='left'>
                                    <Typography variant="subtitle1" fontWeight={"bold"} color={CARD_TEXT_COLOR}>PAYMENT METHOD</Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">Due to the fact that you choose {service.companyCurrency}, please select one of the following payment method.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>}
                    {selectedCost === service.companyCurrency && <Grid item xs={12} pb={3}>
                        <ButtonGroup fullWidth aria-label="outlined primary button group">
                            {service.paymentMethods?.map((pm) => (
                                <Button onClick={() => {
                                    setSelectedPaymentMethod(pm)
                                }} variant={pm === selectedPaymentMethod ? 'contained' : 'outlined'}>{pm === 'ONLINE' ? 'CARD' : pm}</Button>
                            ))}
                        </ButtonGroup>
                    </Grid>}
                    {selectedCost === 'CREDITS' && <Grid item xs={12} pb={1}>
                        {userSubscriptions?.length > 0 ? <Grid item xs={12} pb={3}>
                            <Grid container display="flex" flexDirection="column">
                                <Grid item xs={12} pb={1}>
                                    <Divider textAlign='left'>
                                        <Typography variant="subtitle1" fontWeight={"bold"} color={CARD_TEXT_COLOR}>CHOOSE SUBSCRIPTION</Typography>
                                    </Divider>
                                </Grid>
                                <Grid item xs={12} pt={2}>
                                    {userSubscriptions?.map(sub => (
                                        <Chip
                                            onClick={() => {
                                                setSelectedSubscription(sub.id)
                                            }}
                                            sx={{ fontWeight: 'bold', marginRight: 1, ':hover': { borderColor: PRIMARY_COLOR }, cursor: 'pointer' }}
                                            label={`${sub.Subscription.name} (${sub.limitedActivationUsed || 0} / ${sub.limitedActivationTotal || 0} Credits)`}
                                            color={selectedSubscription === sub.id ? 'primary' : 'default'}
                                            variant={selectedSubscription === sub.id ? "filled" : "outlined"}
                                        />
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid> : <Grid container>
                            {service.subscriptions?.filter((el) => el !== null).length > 0 ? (
                                service.subscriptions?.filter((el) => el !== null)?.map((subscription, index) => {
                                    return (
                                        <Grid key={index} xs={12} item pt={2} pb={2}>
                                            <Subscription
                                                {...subscription}
                                                isAvailable={subscription.Subscription.Subscription.isAvailable}
                                                duration={subscription.Subscription.Subscription.duration}
                                                credits={subscription.Subscription.Subscription.inHouseCredits}
                                                description={subscription.Subscription.Subscription.description}
                                                Trainers={subscription.Subscription.Subscription.Trainers}
                                                limitedActivation={subscription.Subscription.Subscription.limitedActivation}
                                                limitedActivationUsed={subscription.Subscription.Subscription.limitedActivationUsed}
                                                priceRon={subscription.Subscription.Subscription.priceRon}
                                                hasVoucher={subscriptionVoucherIds.includes(subscription.id)}
                                                currency={service?.companyCurrency}
                                            />
                                        </Grid>
                                    )
                                })
                            ) : (
                                <Typography pl={1} color="#979797" fontWeight={500} fontSize={17}>
                                    {t('helper.noResultsFound')}
                                </Typography>
                            )}
                        </Grid>
                        }
                    </Grid>}
                    {(selectedPaymentMethod && selectedPaymentMethod === 'ONLINE' && selectedCost === service.companyCurrency) ? <Grid item xs={12} pt={1}>
                        {user ? <>
                            <Grid container display="flex" flexDirection="column">
                                <Grid item xs={12} pb={1}>
                                    <TextField
                                        value={postalCode}
                                        fullWidth
                                        placeholder="Postal Code"
                                        name={'postalCode'}
                                        onChange={(event) => setPostalCode(event.target.value)}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} pt={1} pb={2}>
                                    <TextField
                                        value={address}
                                        fullWidth
                                        placeholder="Address"
                                        name={'address'}
                                        onChange={(event) => setAddress(event.target.value)}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </> :
                            null}
                    </Grid> : null}
                    {(selectedPaymentMethod && selectedCost === service.companyCurrency) || (selectedCost === 'CREDITS') ? <Grid item xs={12} pb={3}>
                        <Grid container display="flex" flexDirection="column">
                            <Grid item xs={12} pb={1}>
                                <Divider textAlign='left'>
                                    <Typography variant="subtitle1" fontWeight={"bold"} color={CARD_TEXT_COLOR}>SUMMARY</Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">You are almost ready to book <strong>{service.name}</strong> from <strong>{service.companyName}</strong> with a cost of <strong>{selectedCost === service.companyCurrency ? `${service.valueRon} ${service.companyCurrency}` : `${service.valueCredits} Credits`}</strong>.
                                    Your book will be on <strong>{moment(service.start).format('DD/MM/YYYY')}</strong> between <strong>{findSelectedSlot(selectedSlot.index).start}</strong> and <strong>{findSelectedSlot(selectedSlot.index).end}</strong>.</Typography>
                            </Grid>
                            {selectedSlot.participants?.length === service.maxParticipants && service.withWaitingList ? <Grid display="flex" flexDirection="row" sx={{ fontStyle: 'italic' }} item xs={12} pt={2}>
                                <Typography variant="subtitle2">Maximum number of participants for this slot has been reached. However, by proceeding with the book you will be added into the waiting list and when a free spot
                                    appears you will be automatically assigned and informed via email at {user.email}.</Typography>
                            </Grid> : null}
                        </Grid>
                    </Grid> : null}
                    {(selectedPaymentMethod && selectedCost === service.companyCurrency) || (selectedCost === 'CREDITS') ? <Grid item xs={12} pt={1}>
                        {user ? <Button disabled={((selectedPaymentMethod && selectedPaymentMethod === 'ONLINE' && selectedCost === service.companyCurrency) ? !(postalCode && address) : false) || (selectedCost === 'CREDITS' && userSubscriptions?.length === 0 ? true : selectedCost === 'CREDITS' && !selectedSubscription ? true : false) || loading} onClick={handleSubmit} variant="contained" color="success" fullWidth>{selectedCost === 'CREDITS' ? 'Book' : selectedPaymentMethod === 'ONLINE' ? `Pay ${service.valueRon} ${service.companyCurrency}` : 'Book'}</Button> :
                            <>
                                <Typography variant="body2">Opss! It looks like you are not logged in. In order to proceed, please {' '}
                                    <span onClick={() => navigate(`/login${queryParams}`)} style={{ color: PRIMARY_COLOR, textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }}>Login with an existing account</span> or {' '}
                                    <span onClick={() => navigate(`/signup${queryParams}`)} style={{ color: PRIMARY_COLOR, textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }}>Create a new account</span>. Thank you!</Typography>
                            </>}
                    </Grid> : null}
                    {service.costs?.length === 0 && service.paymentMethods?.length === 0 ? <Grid item xs={12} pt={1}>
                        {user ? <Button disabled={loading} onClick={handleSubmit} variant="contained" color="success" fullWidth>Book</Button> :
                            <>
                                <Typography variant="body2">Opss! It looks like you are not logged in. In order to proceed, please {' '}
                                    <span onClick={() => navigate(`/login${queryParams}`)} style={{ color: PRIMARY_COLOR, textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }}>Login with an existing account</span> or {' '}
                                    <span onClick={() => navigate(`/signup${queryParams}`)} style={{ color: PRIMARY_COLOR, textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }}>Create a new account</span>. Thank you!</Typography>
                            </>}
                    </Grid> : null}
                </Grid>
            )
        }
        return (
            <Grid container display="flex" flexDirection="column">
                {service.slots?.length > 0 ? service.slots?.map((spot) => {
                    return (
                        <Grid item xs={12} sx={{
                            boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px;',
                            cursor: spot.available <= 0 && spot.availableWaitingList <= 0 ? 'not-allowed' : 'pointer',
                            border: '2px solid transparent',
                            ':hover': {
                                border: `2px solid ${spot.available <= 0 && spot.availableWaitingList <= 0 ? ERROR_COLOR : spot.availableWaitingList > 0 && spot.available <= 0 ? ORANGE_COLOR : reservations.includes(findSelectedSlot(spot.index).index) ? ORANGE_COLOR : PRIMARY_COLOR}`
                            }
                        }} p={2} mb={2} onClick={() => {
                            if (spot.available <= 0 && spot.availableWaitingList <= 0 || reservations.includes(findSelectedSlot(spot.index).index)) return
                            if (isTrainer) return
                            setSelectedSlot(findSelectedSlot(spot.index))
                        }}>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Typography variant="subtitle2" fontWeight="bold">{spot.start} - {spot.end}</Typography>
                                <Box ml="auto" display="flex" flexDirection="row" alignItems="center">
                                    <Box pr={2} display="flex" flexDirection="row">
                                        {spot.participants?.map(participant => <Avatar src={participant.avatarPhotoSrc} sx={{ cursor: 'pointer', width: 25, height: 25, border: `2px solid ${WHITE_COLOR}` }}><Typography variant="caption" fontWeight="bold">{participant.firstName.charAt(0).toUpperCase()}{participant.lastName.charAt(0).toUpperCase()}</Typography></Avatar>)}
                                    </Box>
                                    {reservations.includes(findSelectedSlot(spot.index).index) ? <Button disabled={loading} onClick={(e) => {
                                        handleDeleteParticipant(spot.index)
                                        e.stopPropagation()
                                    }} sx={{ maxHeight: 30, minHeight: 30 }} size="small" variant="contained" color="warning">Unbook</Button> : <Typography variant="subtitle2" fontWeight="bold" color={spot.available <= 0 && spot.availableWaitingList <= 0 ? ERROR_COLOR : spot.availableWaitingList > 0 && spot.available <= 0 ? ORANGE_COLOR : PRIMARY_COLOR}>{spot.available <= 0 && spot.availableWaitingList <= 0 ? 'Full Slot' : `${spot.available > 0 ? `${spot.available} spots left` : `${spot.availableWaitingList} waiting list spots left`}`}</Typography>}
                                </Box>
                            </Box>
                        </Grid>
                    )
                }) : <Typography textAlign={"center"} pt={1} color="#979797" fontWeight={500} fontSize={17}>
                    Unfortunately, {service.name} has no available slots in the selected day.
                </Typography>}
            </Grid>
        )
    }, [service, postalCode, address, isTrainer, loading, reservations, userSubscriptions, selectedSlot, selectedSubscription, selectedCost, selectedPaymentMethod])

    if (!loaded) return null

    return (
        <Container maxWidth="xl" sx={{ paddingLeft: 0, paddingRight: 0 }}>
            <Grid
                container
                pt={6}
                pb={8}
                display="flex"
                flexDirection="column"
                width="100%"
            >
                <Grid item pl={{ xs: 3, lg: 0 }} xs={12}>
                    <Button onClick={() => searchParams.get('lastPage') || !user ? navigate(-1) : navigate('/calendar')} variant="outlined">
                        <ArrowLeft sx={{ position: 'relative', left: -5 }} />
                        {t('service.back')}
                    </Button>
                </Grid>
                <Grid item xs={12} pt={5} pl={{ xs: 3, md: 3, lg: 4 }} pr={{ xs: 3, md: 3, lg: 4 }}>
                    <Grid container display="flex" flexDirection="row" width="100%">
                        <Grid item xs={12} md={12} lg={3.5}>
                            {renderServiceDetails()}
                        </Grid>
                        <Grid item pt={{ xs: 2, md: 2, lg: 0 }} xs={12} md={12} lg={4} pl={{ xs: 0, md: 0, lg: 3.5 }} pr={{ xs: 0, md: 0, lg: 3.5 }}>
                            {renderCalendar()}
                        </Grid>
                        <Grid item pt={{ xs: 2, md: 2, lg: 0 }} xs={12} md={12} lg={4.5}>
                            {renderAvailableSpots()}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default CalendarService