import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

import { LIGHT_GRAY_BORDER_COLOR } from '../../utils/constants'

const BasicTable = (props) => {
  return (
    <TableContainer>
      <Table size={props.size ? props.size : 'medium'} sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            {props.headCells.map((cell, index) => {
              return (
                <TableCell sx={{
                  border: 'none !important', paddingBottom: props.dense ? 1.3 : '',
                  paddingTop: props.dense ? 1.3 : '',
                }} key={index} align={!props.headCells[index + 1] ? 'right' : 'left'}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {cell}
                  </Typography>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) => {
            return (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                style={{ backgroundColor: index % 2 === 0 && !props.noGray ? LIGHT_GRAY_BORDER_COLOR : 'white' }}
                key={index}
              >
                {Object.keys(row).map((key, index) => {
                  return (
                    <TableCell sx={{
                      paddingBottom: props.dense ? 1.3 : '',
                      paddingTop: props.dense ? 1.3 : '',
                      fontWeight: 500,
                      border: 'none !important'
                    }} key={index} align={!Object.keys(row)[index + 1] ? 'right' : 'left'}>
                      {row[key]}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BasicTable
