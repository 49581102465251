import axios from './axios'

import { queryString } from '../utils/helpers/queryParams'

export const getMeasurements = (options = {}) => {
  return axios.get(`/measurements?${queryString({ ...options })}`).then((res) => res.data)
}

export const getOneMeasurement = (id) => {
  return axios.get(`/measurements/${id}`).then((res) => res.data)
}

export const createMeasurement = (payload, userId) => {
  return axios.post('/measurements', { ...payload, userId }).then((res) => res.data)
}

export const deleteMeasurement = (id) => {
  return axios.delete(`/measurements/${id}`).then((res) => res.data)
}

export const updateMeasurement = (id, data) => {
  return axios.patch(`/measurements/${id}`, { ...data }).then((res) => res.data)
}
