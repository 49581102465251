import React from 'react'
import { Grid, Typography, Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { VerticalBarChart } from '../../../common'

import { useAuthentication } from '../../../../context/auth-context'
import * as DASHBOARD_REQUESTS from '../../../../api/dashboard'
import { ROLE_BUSINESS_ADMINISTRATOR, ROLE_TRAINER, BUSINESS_ROLES } from '../../../../utils/constants'

const MembershipsSold = () => {
  const { t } = useTranslation()
  const { user } = useAuthentication()
  const [data, setData] = React.useState({})

  React.useEffect(() => {
    if (!user) return

    if (user.role === ROLE_BUSINESS_ADMINISTRATOR || user.role === BUSINESS_ROLES.BUSINESS_EMPLOYEE) {
      DASHBOARD_REQUESTS.getCompanySubscriptionsBarChart()
        .then(response => {
          setData(response)
        })
    } else if (user.role === ROLE_TRAINER) {
      DASHBOARD_REQUESTS.getTrainerSubscriptionsBarChart()
        .then(response => {
          setData(response)
        })
    }
  }, [user])

  return (
    <Grid container height="100%">
      <Grid item xs={12} paddingBottom={1.5}>
        <Typography variant="p" fontWeight={'bold'} fontSize={20}>
          {t('dashboard.overview.subscriptionsSold')}
        </Typography>
      </Grid>
      <Grid item xs={12} width="90%">
        <Card
          sx={{
            boxShadow: '0 13px 33px 0 rgba(41, 40, 56, 0.08)',
            border: '1px solid #F0F0F0',
            borderRadius: 2,
            height: '100%'
          }}
        >
          <CardContent sx={{minHeight: 250}}>
            <VerticalBarChart data={{
              labels: Object.keys(data).map(el => {
                const labelArr = el.split('-')
                return `${labelArr[1]}/${labelArr[2]}`
              }),
              datasets: [
                {
                  borderColor: '#ffffff',
                  label: t('helper.membershipsSold'),
                  data: Object.keys(data).map(key => data[key]),
                  backgroundColor: 'rgba(33, 150, 243, 1)',
                }
              ]
            }} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default MembershipsSold
