// DetailsStep.js
import React from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import {
    PRIMARY_COLOR,
    EXTRA_LONG_DEFAULT_MAX_LENGTH,
    LONG_DEFAULT_MAX_LENGTH
} from '../../../../utils/constants'
import RenderRTE from './renderRTE';
import { useTranslation } from 'react-i18next';

const DetailsStep = ({ serviceName, setServiceName, shortDescription, setShortDescription, longDescription, setLongDescription }) => {
    const { t } = useTranslation()

    return (
        <Grid container display="flex" flexDirection="column">
            <Grid item xs={12} pt={2}>
                <Grid container flexDirection="column">
                    <Grid pb={1} item>
                        <Typography fontWeight={500} variant="p">
                            {t('course.name')}{' '}
                            <Typography variant="span" color={PRIMARY_COLOR}>
                                *
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            inputProps={{ maxLength: 30 }}
                            value={serviceName}
                            fullWidth
                            placeholder={t('course.addName')}
                            name={'serviceName'}
                            onChange={(event) => setServiceName(event.target.value)}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" color={serviceName.length >= 30 ? 'red' : ''}>{serviceName.length} / {30}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} pt={6}>
                <Grid container flexDirection="column">
                    <Grid pb={1} item>
                        <Typography fontWeight={500} variant="p">
                            {t('course.short')}{' '}
                            <Typography variant="span" color={PRIMARY_COLOR}>
                                *
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            inputProps={{ maxLength: LONG_DEFAULT_MAX_LENGTH }}
                            value={shortDescription}
                            fullWidth
                            placeholder={t('course.thisWillAppear')}
                            name={'shortDescription'}
                            onChange={(event) => setShortDescription(event.target.value)}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" color={shortDescription?.length >= LONG_DEFAULT_MAX_LENGTH ? 'red' : ''}>{shortDescription?.length} / {LONG_DEFAULT_MAX_LENGTH}</Typography>
                    </Grid>
                    {shortDescription?.length >= LONG_DEFAULT_MAX_LENGTH ?
                        <Typography variant="caption" color={"red"}>{t('helper.reduceChars')}</Typography>
                        : null}
                </Grid>
            </Grid>
            <Grid item xs={12} pt={6}>
                <Grid container display="flex" flexDirection="column">
                    <Grid item xs={12} pb={1}>
                        <Typography fontWeight={500} variant="p">
                            {t('course.long')}{' '}
                            <Typography variant="span" color={PRIMARY_COLOR}>
                                *
                            </Typography>
                        </Typography>
                    </Grid>
                    <RenderRTE longDescription={longDescription} setLongDescription={setLongDescription} />
                    <Grid item>
                        <Typography variant="caption" color={longDescription?.length >= EXTRA_LONG_DEFAULT_MAX_LENGTH ? 'red' : ''}>{longDescription?.length} / {EXTRA_LONG_DEFAULT_MAX_LENGTH}</Typography>
                    </Grid>
                    {longDescription?.length >= EXTRA_LONG_DEFAULT_MAX_LENGTH ?
                        <Typography variant="caption" color={"red"}>{t('helper.reduceChars')}</Typography>
                        : null}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DetailsStep;
