import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';

const CardAdsOns = ({ title, price, subtitle, content, index, selectedIndices, setSelectedIndices }) => {
    const isSelected = selectedIndices.includes(index);

    const handleSelect = () => {
        if (isSelected) {
            setSelectedIndices(selectedIndices.filter(i => i !== index));
        } else {
            setSelectedIndices([...selectedIndices, index]);
        }
    };

    return (
        <Card
            sx={{
                borderRadius: '25px',
                maxHeight: '201px',
                minHeight: '201px',
                background: '#F5F5F5 0% 0% no-repeat padding-box',
                border: isSelected ? '2px solid #707070' : '2px solid #fff',
                opacity: 1,
                boxShadow: 'none',
            }}
        >
            <CardHeader
                sx={{ padding: '8px 8px 0 16px' }}
                action={
                    <Checkbox
                        checked={isSelected}
                        onChange={handleSelect}
                        sx={{
                            color: '#707070',
                            '&.Mui-checked': {
                                color: '#000',
                            },
                        }}
                    />
                }
                title={
                    <Typography sx={{ fontWeight: 'bold', marginBottom: '0px' }} variant="h6" gutterBottom>
                        {title}
                    </Typography>
                }
                subheader={
                    <>
                        <Typography sx={{ marginBottom: '0px' }} color={price === 'Free' ? '#00A83A' : '#000000'} variant="subtitle1" gutterBottom>
                            {price === 'Free' ? price : `€${price} per month$`}
                        </Typography>
                        <Typography sx={{ marginBottom: '0px' }} color={'#707070'} variant="body2" gutterBottom>
                            {subtitle}
                        </Typography>
                    </>
                }
            />
            <CardContent sx={{ padding: '0px 16px' }}>
                <Divider sx={{ margin: '8px 0px' }} />
                <Typography variant="body2" color={'#707070'}>
                    {content}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default CardAdsOns;
