import React, { useCallback, useEffect, useState } from 'react'
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Checkbox, Box, Grid } from '@mui/material'
import * as SUPER_ADMIN_REQUESTS from '../../api/superAdmin'
import CardDeals from '../NewDeals/subcomponents/subcomponents/CradDeals'

const SuperAdminDeals = () => {
    const [deals, setDeals] = useState([])

    const handleGetDeals = useCallback(async () => {
        try {
            const individualsResp = await SUPER_ADMIN_REQUESTS.getDeals()
            setDeals(individualsResp)
        } catch (e) {
            console.error(e)
        }
    }, [])

    useEffect(() => {
        handleGetDeals()
    }, [handleGetDeals])

    const handleDelete = useCallback(async (id, accept) => {
        try {
            await SUPER_ADMIN_REQUESTS.accept(id, accept)
            handleGetDeals()
        } catch (e) {
            console.error(e)
        }
    }, [])
    return (
        <Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Author</TableCell>
                            <TableCell>Link</TableCell>
                            <TableCell>Image</TableCell>
                            <TableCell align='right'>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {deals.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>
                                    {row.Company ? row.Company.name : row.Organization ? row.Organization.name : row.Trainer ? `${row.Trainer.firstName} ${row.Trainer.lastName}` : 'Unknown'}
                                </TableCell>
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>
                                    <Box
                                        onClick={() => window.open(row.link, "_blank", "noreferrer")}
                                        display="flex"
                                        flexDirection="row"
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        {row.link}
                                    </Box>
                                </TableCell>
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>
                                    <Grid item xs={11} lg={7} md={9} sm={9}>
                                        <CardDeals
                                            image={row.photo}
                                            height={200}
                                            width={350}
                                        />
                                    </Grid>
                                </TableCell>
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }} >
                                    <Box display="flex" flexDirection="row" justifyContent="flex-end" >
                                        <Box pl={1} pr={1}>
                                            <Checkbox checked={row.isAccepted} onChange={() => handleDelete(row.id, row.isAccepted)} /> Approve
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default SuperAdminDeals
