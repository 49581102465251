import React, { useState } from 'react'
import {
  Grid,
  Button,
  Typography,
  TextField,
  Select,
  InputAdornment,
  IconButton,
  MenuItem,
  Slide
} from '@mui/material'
import { Search } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import Service from './subcomponents/Service'
import CreateServiceDialog from '../../../Services/Create/CreateServiceDialog'
import EditServiceDialog from '../../../Services/Edit/EditServiceDialog'
import DeleteServiceDialog from '../../../Services/Delete/DeleteServiceDialog'

const Services = ({
  Services,
  handleSearch,
  handleFilters,
  handleGetAllServices,
  handleOnDelete,
  filters,
}) => {
  const { t } = useTranslation()

  const [openCreateDialog, setOpenCreateDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [selectedServiceId, setSelectedServiceId] = useState()

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })

  const handleOnOpenDelete = (serviceId) => {
    setSelectedServiceId(serviceId)
    setOpenDeleteDialog(true)
  }

  const handleOnOpenEdit = (serviceId) => {
    setSelectedServiceId(serviceId)
    setOpenEditDialog(true)
  }

  const handleDelete = () => {
    handleOnDelete(selectedServiceId)
    setOpenDeleteDialog(false)
    handleGetAllServices()
    setSelectedServiceId(null)
  }

  return (
    <>
      {openEditDialog && selectedServiceId ? (
        <EditServiceDialog
          type="company"
          handleClose={() => {
            setOpenEditDialog(false)
            setSelectedServiceId()
            handleGetAllServices()
          }}
          serviceId={selectedServiceId}
          Transition={Transition}
        />
      ) : null}
      {openCreateDialog ? (
        <CreateServiceDialog
          type="company"
          handleClose={() => {
            setOpenCreateDialog(false)
            handleGetAllServices()
          }}
          Transition={Transition}
        />
      ) : null}
      {openDeleteDialog ? (
        <DeleteServiceDialog
          handleDelete={handleDelete}
          handleClose={() => {
            setOpenDeleteDialog(false)
            setSelectedServiceId(null)
          }}
        />
      ) : null}
      <Grid container display="flex" p={3} pt={4} pb={1} flexDirection="row">
        <Grid item xs={12} lg={10}>
          <Grid container display="flex" flexDirection={{ lg: 'row', xs: 'column' }}>
            <Grid lg={6} xs={12} pr={{ lg: 2, xs: 0 }} item>
              <TextField
                onChange={handleSearch}
                fullWidth
                placeholder={t('helper.searchForServices')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <Search style={{ color: '#C6C6C6' }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              ></TextField>
            </Grid>
            <Grid item lg={6} xs={12} pt={{ lg: 0, xs: 1 }}>
              <Grid
                container
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent={{ lg: 'center', xs: 'flex-start' }}
              >
                <Grid item xs={6} pr={{ lg: 2, xs: 2 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filters.active !== undefined ? filters.active : 'all'}
                    onChange={(e) => handleFilters('active', e.target.value)}
                    fullWidth
                  >
                    <MenuItem value={'all'}>{t('helper.all')}</MenuItem>
                    <MenuItem value={true}>{t('helper.active')}</MenuItem>
                    <MenuItem value={false}>{t('helper.inactive')}</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={6} pr={{ lg: 2, xs: 0 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filters.public !== undefined ? filters.public : 'all'}
                    onChange={(e) => handleFilters('public', e.target.value)}
                    fullWidth
                  >
                    <MenuItem value={'all'}>{t('helper.all')}</MenuItem>
                    <MenuItem value={true}>{t('helper.public')}</MenuItem>
                    <MenuItem value={false}>{t('helper.private')}</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid lg={2} xs={12} display="flex" justifyContent="flex-end" item pt={{ lg: 0, xs: 1 }}>
          <Button
            onClick={() => setOpenCreateDialog(true)}
            fullWidth
            variant="contained"
            color="success"
          >
            {t('userProfile.activity.trainerMembershipComponent.add')}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} display="flex" flexDirection="row">
        <Grid pt={1} container pr={3} pl={3} pb={3}>
          {Services.length > 0 ? (
            Services.map((service, index) => {
              return (
                <Grid key={index} xs={12} item pt={2} pb={2}>
                  <Service
                    name={service.title}
                    description={service.description}
                    duration={service.duration}
                    Reviews={service.Reviews}
                    Trainers={service.Trainers}
                    isAvailable={service.isAvailable}
                    priceRon={service.priceRon}
                    currency={service?.currency}
                    expand={service.expand || false}
                    handleExpand={service.handleExpand}
                    handleOnOpenEdit={handleOnOpenEdit}
                    handleOnDelete={handleOnOpenDelete}
                    {...service}
                  />
                </Grid>
              )
            })
          ) : (
            <Typography pl={1} color="#979797" fontWeight={500} fontSize={17}>
              {t('helper.noResultsFound')}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default Services
