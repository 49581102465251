import React, { useEffect, useState } from 'react'
import { Grid, Typography, Button, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useParams } from 'react-router-dom'

import Review from './subcomponents/Review'
import { CreateReview } from '../../../common'
import { useAuthentication } from '../../../../context/auth-context'

import {
  LIGHT_GRAY_BORDER_COLOR,
  ROLE_BUSINESS_ADMINISTRATOR,
  ROLE_TRAINER,
  COMPANIES_TRAINERS_REQUEST_STATUS
} from '../../../../utils/constants'
import * as COMPANY_REQUESTS from '../../../../api/company'
import * as TRAINER_REQUESTS from '../../../../api/trainer'
import { refetchPublicBusinessProfileObservable } from '../../../../utils/observables'
import RenderReviews from '../../../../utils/renderReviews'

const Reviews = ({ companyName, companyPhotoSrc, companyId, reviewsAverage }) => {
  const { t } = useTranslation()
  const { domain } = useParams()
  const { user } = useAuthentication()
  const [showCreateReview, setShowCreateReview] = useState(false)
  const [reviews, setReviews] = useState([])
  const [loading, setLoading] = useState(true)
  const [showAddReview, setShowAddReview] = useState(false)
  const [givenReview, setGivenReview] = useState(true)

  const getReviews = () => {
    setLoading(true)
    // if (user.id) {
    COMPANY_REQUESTS.getAllReviewsByDomain(domain, user?.id)
      .then((response) => {
        setReviews(response.array)
        setGivenReview(response.givenReview)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
    // }

  }

  useEffect(() => {
    if (user && user.role === ROLE_TRAINER) {
      TRAINER_REQUESTS.getAssociatedGyms().then((associatedGyms) => {
        const member = associatedGyms.find(
          (el) => el.id == companyId && el.status === COMPANIES_TRAINERS_REQUEST_STATUS.APPROVED
        )

        setShowAddReview(!!member)
      })
    } else if (user) {
      setShowAddReview(user.role !== ROLE_BUSINESS_ADMINISTRATOR)
    }

    getReviews()
  }, [domain, user])

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    })
    const showModal = params['show_modal']
    if (showModal) setShowCreateReview(true)

  }, [])

  if (loading) {
    return (
      <Grid
        container
        display="flex"
        disply="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
        p={3}
        pt={5}
      >
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {showCreateReview && (
        <CreateReview
          title={companyName}
          photoSrc={companyPhotoSrc}
          onCancel={() => setShowCreateReview(false)}
          onSaveHandler={(stars, comment) => {
            COMPANY_REQUESTS.createReview(domain, {
              reviewCompanyId: companyId,
              userId: user.id,
              comment,
              stars
            }).then(() => {
              setShowCreateReview(false)
              getReviews()
              refetchPublicBusinessProfileObservable.setRefetchPublicBusinessProfile(true)
            })
          }}
        />
      )}
      <Grid
        container
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }}
        p={3}
        pt={5}
      >
        <Grid item xs={12} display="flex" flexDirection="row">
          <Typography fontWeight={500} color="black" fontSize={20}>
            {t('userProfile.activity.reviews')}
          </Typography>
          {showAddReview && !givenReview && (
            <Button
              sx={{ marginLeft: 'auto' }}
              variant="contained"
              color="success"
              onClick={() => setShowCreateReview(true)}
            >
              {' '}
              {t('button.addReview')}
            </Button>
          )}
        </Grid>
        <Grid pt={5} item xs={12} display="flex" alignItems="center" flexDirection="row">
          <RenderReviews reviews={reviews} reviewsAverage={Math.ceil(reviewsAverage)} />
        </Grid>
        <Grid item pt={5} xs={12}>
          <Grid container display="flex">
            {reviews.length > 0 ? (
              reviews.map((el, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    pt={2}
                    pb={2}
                    width="100%"
                    borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
                  >
                    <Review
                      domain={el.User?.domain}
                      name={el.User?.firstName + ' ' + el.User?.lastName}
                      comment={el.comment}
                      createdAt={moment(el.createdAt).format('YYYY-MM-DD')}
                      stars={el.stars}
                    />
                  </Grid>
                )
              })
            ) : (
              <Typography pl={1} color="#979797" fontWeight={500} fontSize={17}>
                {t('helper.noResultsFound')}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid >
    </>
  )
}

export default Reviews
