import React from 'react'
import { Grid, Card, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

import { LIGHT_GRAY_BORDER_COLOR } from '../../../utils/constants'
import { ReactComponent as Workout } from '../../../assets/workout.svg'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
    flexGrow: 1
  }
})

const UserActivity = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container sm={12} xl={12} justifyContent="flex-end">
      <Grid
        className={classes.content}
        component={Card}
        item
        sm={12}
        xl={12}
        p={6}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Workout height={500} width={500} />
        <Typography mt={2} fontWeight={500} variant="h5">
          {t('userProfile.ups')}
        </Typography>
        <Typography mt={1} variant="p">
          {t('userProfile.justToTrain')}
        </Typography>
        {/* <Details bio={user.bio} Favourites={user.Favourites} Reviews={user.Reviews} /> */}
      </Grid>
    </Grid>
  )
}

export default UserActivity
