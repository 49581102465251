import React, { useState, useMemo, useEffect } from 'react'
import { Dialog, IconButton, Box, Typography, Grid, Container, Avatar, Button, Tooltip, Divider, Card } from '@mui/material'
import { Close, CreditCard, AccountBalance, Money, Percent, Receipt, CreditScore, PendingActions } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import _ from 'lodash'
import { CSVLink } from 'react-csv'

import { Table } from '../common'

import { DATE_FORMAT, GRAY_TEXT_COLOR } from '../../utils/constants'
import { numberFomatter } from '../../utils/helpers/numberFomatter'
import { PRIMARY_COLOR, PAYMENT_METHOD, } from '../../utils/constants'
import * as SUPER_ADMIN_REQUESTS from '../../api/superAdmin'

const PayRevolut = ({
    onCancel,
    payments,
    startDate,
    endDate,
    companyIds
}) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [validPayments, setValidPayments] = useState([])
    const [isPayoutDone, setIsPayoutDone] = useState(false)

    useEffect(() => {
        setValidPayments(payments.filter(el => !el.bankTransferDone || !el.success))
    }, [payments])

    const onSuccessHandler = async () => {
        try {
            await SUPER_ADMIN_REQUESTS.transferMoney(startDate, endDate, companyIds)
            setIsPayoutDone(true)

        } catch (e) {
            setLoading(false)
            onCancel()
        }
    }


    const renderPaymentMethod = (method, isBt) => {
        if (method === PAYMENT_METHOD.CARD && !isBt) {
            return <Tooltip arrow title={t('subscriptionModal.pos')}><Avatar variant="square" src={require('../../assets/pos.png')} style={{ width: 25, height: 25, paddingRight: 8 }} /></Tooltip>

        }

        if (method === PAYMENT_METHOD.CARD && isBt) {
            return <Tooltip arrow title={t('subscriptionModal.card')}><CreditCard sx={{ paddingRight: 1 }} color='success' /></Tooltip>
        }


        if (method === PAYMENT_METHOD.BANK_TRANSFER) {
            return <Tooltip arrow title={t('subscriptionModal.BANK_TRANSFER')}><AccountBalance sx={{ paddingRight: 1 }} color="success" /></Tooltip>
        }

        return <Tooltip arrow title={t('subscriptionModal.cash')}> <Money sx={{ paddingRight: 1 }} color="success" /></Tooltip>
    }
    const paymentsCompaniesMapping = useMemo(() => {
        return _.groupBy(validPayments, 'Company.name')
    }, [validPayments])

    const csvData = useMemo(() => {
        if (!validPayments) return null

        const headers = ['Name', 'Recipient type', 'IBAN', 'BIC', 'Recipient bank country', 'Currency', 'Amount', 'Payment reference', 'Recipient country', 'Address line 1', 'Address line 2', 'City', 'Postal code']
        const data = [headers]

        Object.keys(paymentsCompaniesMapping).forEach(key => {
            if (paymentsCompaniesMapping[key].length && paymentsCompaniesMapping[key][0].Company) {
                const company = paymentsCompaniesMapping[key][0].Company

                const paymentsCompany = paymentsCompaniesMapping[key].filter(el => !el.bankTransferDone || !el.success)

                const total = _.sum(paymentsCompany.map(el => el.value))
                const brutCommission = Number(Number(paymentsCompany.reduce((prevValue, payment) => {
                    const companyCommission = payment.Company ? payment.Company.commission : 2;
                    return prevValue + Number(payment.value) * companyCommission / 100 + 1.99
                }, 0)).toFixed(2))
                const toBeTransfered = Number(total - (brutCommission))

                data.push([
                    company.legalName,
                    'COMPANY',
                    typeof company.bankAccount === 'string' ? company.bankAccount.replace(" ", "") : company.bankAccount,
                    company.swiftCode,
                    company.bankCountry,
                    company.currency,
                    Number(toBeTransfered),
                    `xTrainer. Details https://app.sportin.io/payouts`,
                    company.bankCountry,
                    company.addressLine1,
                    '',
                    company.city,
                    company.postalCode
                ])
            }
        })

        return data

    }, [paymentsCompaniesMapping])

    const downloadCSV = (data, filename = 'export.csv') => {
        // Convert the data array to a CSV string
        let csvContent = "data:text/csv;charset=utf-8,";
        data.forEach(rowArray => {
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", filename);
        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);
    };

    const handleDownload = () => {
        downloadCSV(csvData);
    };

    const totalCommissions = useMemo(() => {
        const allPayments = validPayments
        const total = _.sum(validPayments.map(el => el.value))
        const commissionRevolut = Number(Number(total) * 1 / 100 + validPayments.length * 1)
        const brutCommission = Number(Number(allPayments.reduce((prevValue, payment) => {
            const companyCommission = payment.Company ? payment.Company.commission : 2;
            return prevValue + Number(payment.value) * companyCommission / 100 + 1.99
        }, 0)).toFixed(2))
        const netCommission = Number(brutCommission - commissionRevolut)
        const toBeTransfered = Number(total - brutCommission)

        return {
            total,
            commissionRevolut,
            brutCommission,
            netCommission,
            toBeTransfered
        }
    }, [validPayments])

    return (
        <Dialog PaperProps={{ style: { borderRadius: 0 } }} open fullScreen>
            <Container maxWidth='xl'>
                <Grid container pt={2} spacing={2}>
                    <Grid item xs={12}>
                        <Grid container display="flex" flexDirection="row">
                            <Grid item
                                xs={10}
                                md={10}
                                lg={10}>
                                <Typography variant="h4" fontWeight={500} color={'black'}>
                                    {t('checkoutPayment.title')}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                                <IconButton onClick={onCancel}>
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} display="flex" alignItems="center">
                        <Typography color={GRAY_TEXT_COLOR} variant="p">
                            Virament bancar catre {companyIds.length > 0 ? companyIds.length + " sali" : "toate salile"} a platilor din data de {moment(startDate).format(DATE_FORMAT)} pana in data de {moment(endDate).format(DATE_FORMAT)}
                        </Typography>
                    </Grid>
                    {Object.keys(paymentsCompaniesMapping).map((key, index) => {
                        const paymentsCompany = paymentsCompaniesMapping[key].filter(el => !el.bankTransferDone || !el.success)

                        const total = _.sum(paymentsCompany.map(el => el.value))
                        const commissionRevolut = Number(Number(total) * 1 / 100 + paymentsCompany.length * 1)
                        const brutCommission = Number(Number(paymentsCompany.reduce((prevValue, payment) => {
                            const companyCommission = payment.Company ? payment.Company.commission : 2
                            return prevValue + Number(payment.value) * companyCommission / 100
                        }, 0)).toFixed(2))
                        const netCommission = Number(brutCommission - commissionRevolut)
                        const toBeTransfered = Number(total - brutCommission)

                        return <>
                            <Grid item xs={12} key={index}>
                                <Typography variant="h5" fontWeight="bold">{key}</Typography>
                                <Table
                                    dense
                                    headCells={[
                                        'company',
                                        'paymentOrderNumber',
                                        'username',
                                        'subscription',
                                        'status',
                                        'paymentMethod',
                                        'createdAt',
                                        'amount',
                                        'voucher',
                                        'commission',
                                        'amountCommission',
                                        'moneySend'
                                    ].map((key) => t(`payments.${key}`))}
                                    rows={paymentsCompaniesMapping[key].filter(el => !el.bankTransferDone || !el.success).map(payment => {
                                        const amount = Number(payment.value)
                                        const valueWithCommission = payment.paymentOrderId && payment.Company ? amount - (amount * (payment.Company.commission) / 100) : amount
                                        const status = payment.paymentState === 'DEPOSITED' ? 'Paid' : payment.paymentState

                                        return {
                                            company: <Typography fontWeight={"bold"} color={PRIMARY_COLOR}>{payment.Company ? payment.Company.name : 'Trainer'}</Typography>,
                                            paymentOrderNumber: payment.paymentOrderNumber || 0,
                                            username: payment.User ? payment.User.firstName + ' ' + payment.User.lastName : '-',
                                            subscription: payment.Subscription ? payment.Subscription.name : payment.Slot && payment.Slot.CalendarService && payment.Slot.CalendarService.name ? payment.Slot.CalendarService.name : '-',
                                            status: <Typography variant="body2" fontWeight="bold" color={status === 'Paid' ? 'green' : 'red'}>{status}</Typography>,
                                            paymentMethod: renderPaymentMethod(payment.paymentMethod, payment.paymentOrderId),
                                            createdAt: moment(payment.createdAt).format('DD/MM/YYYY HH:mm'),
                                            amount: `${numberFomatter(Number(payment.value).toFixed(2))} RON`,
                                            voucher: <>
                                                {
                                                    payment.Voucher ? <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        {!payment.Voucher.hasFixedDiscount && <Percent color="primary" fontSize='small' />}
                                                        <Typography fontWeight="bold" variant="body2" color="primary">
                                                            {payment.Voucher.discount} {' '}
                                                            {payment.Voucher.hasFixedDiscount && `RON`}
                                                        </Typography>
                                                    </Box> : '-'
                                                }
                                            </>,
                                            commission: `${payment.Company && payment.paymentMethod === 'CARD' ? Number(payment.Company.commission).toFixed(2) : 0}%`,
                                            amountCommission: `${numberFomatter(Number(valueWithCommission).toFixed(2))} RON`,
                                            moneySend: (payment.paymentMethod === PAYMENT_METHOD.CARD && payment.paymentOrderId) ? <Tooltip arrow title={payment.bankTransferDone ? t('payments.moneySendDescription') : t('payments.noMoneySendDescription')}>{payment.bankTransferDone ? <CreditScore fontSize='small' color='success' /> : <PendingActions color='warning' fontSize='small' />}</Tooltip> : ''
                                        }
                                    })
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container pt={2} spacing={2}>
                                    <Grid item xs={2} p={2} component={Card} display="flex" flexDirection="column">
                                        <Typography variant="p" color="primary">Total</Typography>
                                        <CreditCard fontSize='small' color="primary" />
                                        <Typography fontWeight='bold' variant="body1" color="primary">
                                            {numberFomatter(total)} RON
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} p={2} display="flex" flexDirection="column">
                                        <Typography variant="p">Revolut Commission</Typography>
                                        <Percent fontSize='small' />
                                        <Typography fontWeight='bold' variant="body1">
                                            {numberFomatter(commissionRevolut)} RON
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} p={2} component={Card} display="flex" flexDirection="column">
                                        <Typography variant="p" color="primary">Comision Brut</Typography>
                                        <Percent fontSize='small' color="primary" />
                                        <Typography fontWeight='bold' variant="body1" color="primary">
                                            {numberFomatter(brutCommission)} RON
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} p={2} display="flex" flexDirection="column">
                                        <Typography variant="p" color="success">Commission Net</Typography>
                                        <Percent fontSize='small' color="success" />
                                        <Typography fontWeight='bold' variant="body1" color="success">
                                            {numberFomatter(netCommission)} RON
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} p={2} display="flex" flexDirection="column">
                                        <Typography variant="p" color="success">De virat</Typography>
                                        <Receipt fontSize='small' color="success" />
                                        <Typography fontWeight='bold' variant="body1" color="success">
                                            {numberFomatter(toBeTransfered)} RON
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </>
                    })}
                    <Grid item xs={12} display="flex" flexDirection="column" mb={6}>
                        <Typography variant="h6">Sumar Plata</Typography>
                        <Typography gutterBottom variant="p" color={GRAY_TEXT_COLOR}>Acesta este un sumar al tuturor viramentelor. Reprezinta comisionaele pentru toate viramentele catre toate salile din perioada selectata</Typography>
                        <Grid container pt={1} spacing={2}>
                            <Grid item xs={2} p={2} component={Card} display="flex" flexDirection="column">
                                <Typography variant="p" color="primary">Total</Typography>
                                <CreditCard fontSize='small' color="primary" />
                                <Typography fontWeight='bold' variant="body1" color="primary">
                                    {numberFomatter(totalCommissions.total)} RON
                                </Typography>
                            </Grid>
                            <Grid item xs={2} p={2} display="flex" flexDirection="column">
                                <Typography variant="p">Revolut Commission</Typography>
                                <Percent fontSize='small' />
                                <Typography fontWeight='bold' variant="body1">
                                    {numberFomatter(totalCommissions.commissionRevolut)} RON
                                </Typography>
                            </Grid>
                            <Grid item xs={2} p={2} component={Card} display="flex" flexDirection="column">
                                <Typography variant="p" color="primary">Comision Brut</Typography>
                                <Percent fontSize='small' color="primary" />
                                <Typography fontWeight='bold' variant="body1" color="primary">
                                    {numberFomatter(totalCommissions.brutCommission)} RON
                                </Typography>
                            </Grid>
                            <Grid item xs={2} p={2} display="flex" flexDirection="column">
                                <Typography variant="p" color="success">Commission Net</Typography>
                                <Percent fontSize='small' color="success" />
                                <Typography fontWeight='bold' variant="body1" color="success">
                                    {numberFomatter(totalCommissions.netCommission)} RON
                                </Typography>
                            </Grid>
                            <Grid item xs={3} p={2} display="flex" flexDirection="column">
                                <Typography variant="p" color="success">De virat</Typography>
                                <Receipt fontSize='small' color="success" />
                                <Typography fontWeight='bold' variant="body1" color="success">
                                    {numberFomatter(totalCommissions.toBeTransfered)} RON
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} pb={3} display="flex" flexDirection={"row"} alignItems="center">
                        <Button disabled={loading || isPayoutDone} variant="contained" fullWidth onClick={onSuccessHandler}>
                            <CreditCard sx={{ pr: 2 }} /> Salveaza instanta plata
                        </Button>
                    </Grid>
                    <Grid item xs={6} pb={3} display="flex" flexDirection={"row"} alignItems="center">
                        <Button onClick={handleDownload} variant="outlined" fullWidth>
                            Download CSV Payout
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Dialog >
    )
}

export default PayRevolut
