import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Service from './subcomponents/Service'

import * as INTERESTED_REQUESTS from '../../../../api/interested'
import { useAuthentication } from '../../../../context/auth-context'

const Services = ({ handleGetServices, services }) => {
  const { t } = useTranslation()
  const { user } = useAuthentication()

  const [interestLoading, setInterestLoading] = useState(false)

  const handleInterested = (serviceId, interestedByLoggedUser) => {
    if (interestedByLoggedUser) {
      INTERESTED_REQUESTS.removeInterestedInService(serviceId).then(() => {
        handleGetServices()
        setInterestLoading(false)
      })
    } else {
      setInterestLoading(true)
      INTERESTED_REQUESTS.interestedInService(serviceId).then(() => {
        handleGetServices()
        setInterestLoading(false)
      })
    }
  }

  return (
    <Grid container display="flex" p={3}>
      <Grid pt={2} container>
        {services.length > 0 ? (
          services.map((service, index) => {
            return (
              <Grid key={index} xs={12} item pt={2} pb={2}>
                <Service
                  {...service}
                  interestLoading={interestLoading}
                  id={service.id}
                  price={service.priceRon}
                  currency={service?.Trainer?.trainerCurrency || service.Company?.currency}
                  name={service.title}
                  description={service.description}
                  duration={service.duration}
                  trainerName={
                    service.Trainer
                      ? `${service.Trainer.firstName} ${service.Trainer.lastName}`
                      : null
                  }
                  trainerDomain={
                    service && service.Trainer && service.Trainer.domain
                      ? service.Trainer.domain
                      : ''
                  }
                  isInterested={service.isInterested}
                  handleInterested={handleInterested}
                  isAvailable={service.isAvailable}
                  interestedByLoggedUser={
                    user && service.UserInterests
                      ? service.UserInterests.findIndex((el) => el.userId === user.id) >= 0
                      : false
                  }
                />
              </Grid>
            )
          })
        ) : (
          <Typography color="#979797" fontWeight={500} fontSize={17}>
            {t('helper.noResultsFound')}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default Services
