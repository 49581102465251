import React, { useEffect, useState } from 'react'
import { Typography, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import * as CALENDAR_REQUESTS from '../../../../api/calendar'
import CalendarItem from '../../../Calendar/subcomponents/CalendarItem'
import { GRAY_TEXT_COLOR } from '../../../../utils/constants'

export default () => {
    const { t } = useTranslation()

    const [events, setEvents] = useState([])
    const [calendarServices, setCalendarServices] = useState([])

    useEffect(() => {
        const startDate = moment().format('MM/DD/YYYY')

        CALENDAR_REQUESTS.getEventsByDaysForIndividual(startDate, null, true, [])
            .then(response => {
                setEvents(response.rows.map(el => {
                    return {
                        id: el.id,
                        transparency: moment().format('YYYY-MM-DD') !== moment(el.start).utc().format('YYYY-MM-DD') ? moment().isAfter(moment(el.start).utc()) : moment().hour() > moment(el.start).utc().hour() ? true : moment().hour() === moment(el.start).utc().hour() ? moment().minute() > moment(el.start).utc().minute() ? true : false : false,
                        title: el.name || '-',
                        firstChip: el.tags.split(',').sort((a, b) => a.length < b.length ? 1 : -1)[0] || null,
                        secondChip: el.tags.split(',').sort((a, b) => a.length < b.length ? 1 : -1)[1] || null,
                        thirdChip: el.tags.split(',').sort((a, b) => a.length < b.length ? 1 : -1)[2] || null,
                        fullStart: el.start,
                        onlineBooking: el.onlineBooking,
                        book: el.book,
                        cancel: el.cancel,
                        start: moment(el.start).utc().format('HH:mm'),
                        duration: el.duration,
                        custom: el.type === 'DETAILED',
                        backgroundColor: el.backgroundColor || '#0277bd',
                        participants: el.EventsParticipants.filter(participant => !participant.isInWaitingList),
                        maxParticipants: el.maxParticipants,
                        minParticipants: el.minParticipants,
                        withWaitingList: el.withWaitingList,
                        maxWaitingList: el.maxWaitingList,
                        inWaitingList: false,
                        public: el.access,
                        trainers: el.EventsTrainers,
                        trainer: el.Trainer,
                        company: el.Company,
                        value: el.value,
                        booked: true,
                        EventsParticipants: el.EventsParticipants.map(el => el.Participant),
                        EventSubscriptions: el.EventSubscriptions.map(el => el.Subscription),
                        cost: el.cost,
                        showParticipantsName: el.showParticipantsName,
                        payment: el.payment,
                        cannotOpenDetailsDialog: true,
                        hasValidSubscription: true,
                        avatar:
                            el.type !== 'DETAILED'
                                ? 'https://i.kinja-img.com/gawker-media/image/upload/t_original/ijsi5fzb1nbkbhxa2gc1.png'
                                : '',
                        backgroundImage: el.sportType,
                        type: 'event'
                    }
                }))
            })

        CALENDAR_REQUESTS.getCalendarServicesByDaysForIndividual(startDate, null, true, [])
            .then(response => {
                setCalendarServices(response.rows.map(el => {
                    return {
                        id: el.id,
                        name: el.name,
                        availability: el.availability,
                        costs: el.costs,
                        paymentMethods: el.payment,
                        valueRon: el.valueRon,
                        valueCredits: el.valueCredits,
                        location: el.location,
                        sportType: el.sportType,
                        start: moment(el.start),
                        firstChip: el.tags ? el.tags.sort((a, b) => a.length < b.length ? 1 : -1)[0] : null,
                        secondChip: el.tags ? el.tags.sort((a, b) => a.length < b.length ? 1 : -1)[1] : null,
                        thirdChip: el.tags ? el.tags.sort((a, b) => a.length < b.length ? 1 : -1)[2] : null,
                        Slots: el.Slots,
                        slots: el.Slots.map(slot => {
                            return {
                                index: slot.id,
                                start: moment(slot.from).format('HH:mm'),
                                end: moment(slot.to).format('HH:mm'),
                                available: el.maxParticipants - slot.CSParticipants.length,
                                participants: slot.CSParticipants.filter(el => !el.CS_Participants.isInWaitingList),
                                waitingList: slot.CSParticipants.filter(el => el.CS_Participants.isInWaitingList)
                            }
                        }),
                        type: 'service'
                    }
                }))
            })

    }, [])

    return (
        <Grid container>
            <Grid item xs={12} paddingBottom={1}>
                <Typography variant="p" fontWeight={'bold'} fontSize={20}>
                    {t('dashboard.overview.upcomingEvents')}
                </Typography>
            </Grid>
            <Grid item xs={12} pb={1}>
                <Grid container display="flex" flexDirection="column" justifyContent="flex-start">
                    {events.length ? events.map((event, index) => {
                        return (
                            <Grid item xs={12} key={index}>
                                <CalendarItem
                                    item={event}
                                    isDashboard
                                />
                            </Grid>
                        )
                    }) : null}
                    {!events.length ? <Typography variant="p" color={GRAY_TEXT_COLOR}>{t('dashboard.overview.noEvents')}</Typography> : null}
                </Grid>
            </Grid>
            <Grid item xs={12} paddingBottom={1}>
                <Typography variant="p" fontWeight={'bold'} fontSize={20}>
                    Active Services
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container display="flex" flexDirection="column" justifyContent="flex-start">
                    {calendarServices.map((service, index) => {
                        return (
                            <Grid item xs={12} key={index}>
                                <CalendarItem
                                    item={service}
                                    day={service.start.format('YYYY-MM-DD')}
                                    isDashboard
                                />
                            </Grid>
                        )
                    })}
                    {!calendarServices.length ? <Typography variant="p" color={GRAY_TEXT_COLOR}>{t('dashboard.overview.noEvents')}</Typography> : null}
                </Grid>
            </Grid>
        </Grid>
    )
}