import React from 'react'
import { Box, Avatar, Typography, Card } from '@mui/material'
import { Check, Pause, Close } from '@mui/icons-material'

import { Table } from '../../common'

import AvatarPhoto from '../../../assets/avatar.png'
import { GREEN_COLOR, LIGHT_GRAY_BORDER_COLOR } from '../../../utils/constants'

const USERS = [
    {
        id: 1,
        name: 'Gicu Grozav',
        active: false,
        creditsUsed: 22,
        creditsUsedThisMonth: 2,
        activeSince: '2022-03-05'
    },
    {
        id: 1,
        name: 'Gicu Grozav',
        active: true,
        creditsUsed: 22,
        creditsUsedThisMonth: 2,
        activeSince: '2022-03-05'
    },
    {
        id: 1,
        name: 'Gicu Grozav',
        active: true,
        creditsUsed: 22,
        creditsUsedThisMonth: 2,
        activeSince: '2022-03-05'
    },
    {
        id: 1,
        name: 'Gicu Grozav',
        active: false,
        creditsUsed: 22,
        creditsUsedThisMonth: 2,
        activeSince: '2022-03-05'
    },
    {
        id: 1,
        name: 'Gicu Grozav',
        active: false,
        creditsUsed: 22,
        creditsUsedThisMonth: 2,
        activeSince: '2022-03-05'
    },
    {
        id: 1,
        name: 'Gicu Grozav',
        active: false,
        creditsUsed: 22,
        creditsUsedThisMonth: 2,
        activeSince: '2022-03-05'
    },
    {
        id: 1,
        name: 'Gicu Grozav',
        active: false,
        creditsUsed: 22,
        creditsUsedThisMonth: 2,
        activeSince: '2022-03-05'
    },
    {
        id: 1,
        name: 'Gicu Grozav',
        active: false,
        creditsUsed: 22,
        creditsUsedThisMonth: 2,
        activeSince: '2022-03-05'
    },
    {
        id: 1,
        name: 'Gicu Grozav',
        active: false,
        creditsUsed: 22,
        creditsUsedThisMonth: 2,
        activeSince: '2022-03-05'
    },
    {
        id: 1,
        name: 'Gicu Grozav',
        active: false,
        creditsUsed: 22,
        creditsUsedThisMonth: 2,
        activeSince: '2022-03-05'
    },
    {
        id: 1,
        name: 'Gicu Grozav',
        active: false,
        creditsUsed: 22,
        creditsUsedThisMonth: 2,
        activeSince: '2022-03-05'
    }
]

export default () => {
    return (
        <Box border={`1.5px solid ${LIGHT_GRAY_BORDER_COLOR}`} borderRadius={2}>
            <Table
                noGray
                headCells={[
                    'Id',
                    'Avatar',
                    'Nume',
                    'Activ',
                    'Credite Folosite',
                    'Credite Folosite Mai',
                    'Activ Din'
                ]}
                dense
                rows={USERS.map(user => {
                    return {
                        id: <Typography variant="span">{user.id}</Typography>,
                        avatar: <Avatar variant="rounded" src={AvatarPhoto} />,
                        name: <Typography variant="span">{user.name}</Typography>,
                        active: user.active ? <Check /> : <Pause />,
                        creditsUsed: <Typography variant="span">{user.creditsUsed}</Typography>,
                        creditsUsedThisMonth: <Typography variant="span">{user.creditsUsedThisMonth}</Typography>,
                        activeSince: <Typography variant="span">{user.activeSince}</Typography>,
                    }
                })}
            />
        </Box>
    )
}