import React, { useRef, useState } from 'react';
import { Grid, Typography, TextField, Button } from '@mui/material';
import {
    PRIMARY_COLOR
} from '../../../utils/constants';
import { useTranslation } from 'react-i18next';
import * as DOCUMENTS_REQUESTS from '../../../api/documents'
import ReactGA from 'react-ga';
import { BUCKET_BASE_URL } from '../../../utils/config';
import { getImageSize } from 'react-image-size';
import CardDeals from '../subcomponents/subcomponents/CradDeals'
const Form = ({ linkDeal, setLinkDeal, errorLink, setErrorLink, setProfilePhoto, profilePhoto }) => {
    const { t } = useTranslation();
    const photoFileInputRef = useRef();
    const [loading, setLoading] = useState(false)
    const onPhotoChangeHandler = async (event) => {
        try {
            setLoading(true)
            const file = event.target.files[0];
            if (!file) return;

            const { width, height } = await getImageSize(URL.createObjectURL(file));
            const aspectRatio = width / height;
            if (Math.abs(aspectRatio - 16 / 9) > 0.3) {
                alert(`${t('deals.alert')}`);
                setLoading(false)
                return null
            }

            const name = Date.now();
            const extension = file.type.split('/')[1];

            const presignedUrlResponse = await DOCUMENTS_REQUESTS.generatePresignedUrl({ name, extension });
            await DOCUMENTS_REQUESTS.uploadDocumentS3(presignedUrlResponse, file);

            const documentResponse = await DOCUMENTS_REQUESTS.createDocument({
                src: `${BUCKET_BASE_URL}/${name}.${extension}`,
                isPhoto: true
            });

            setProfilePhoto(documentResponse.src);
            ReactGA.event({
                category: 'POST_LOGIN',
                action: 'CREATE_SERVICE',
                label: 'SUCCESS'
            });
            setLoading(false)

        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Grid container display="flex" flexDirection="column">
            <Grid item xs={12} pt={2}>
                <Grid container flexDirection="column">
                    <Grid pb={1} item>
                        <Typography fontWeight={500} variant="p">
                            {t('deals.link')}{' '}
                            <Typography variant="span" color={PRIMARY_COLOR}>
                                *
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            value={linkDeal}
                            fullWidth
                            error={errorLink !== ''}
                            helperText={errorLink}
                            placeholder={t('deals.linkInput')}
                            name={'dealName'}
                            onChange={(event) => {
                                setErrorLink('')
                                setLinkDeal(event.target.value)
                            }
                            }
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} display="flex" flexDirection="row" justifyContent={'center'} pt={7}>
                <input
                    type="file"
                    ref={photoFileInputRef}
                    onChangeCapture={async (event) => await onPhotoChangeHandler(event)}
                    accept="image/*"
                    style={{ display: 'none' }}
                />
                <CardDeals loading={loading} select onClick={() => photoFileInputRef.current.click()} title={profilePhoto ? `${t('deals.changePhoto')}` : `${t('deals.photo')}`} image={profilePhoto} height={400}
                    width={350} />
            </Grid>
        </Grid>
    );
};

export default Form;
