import React, { useEffect, useState } from 'react'
import { Grid, Typography, Button, CircularProgress, Dialog, DialogContent, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { CARD_TEXT_COLOR, GRAY_TEXT_COLOR, PRIMARY_COLOR } from '../../utils/constants'
import { BASE_URL } from '../../utils/config'
import { useAuthentication } from '../../context/auth-context'
import * as COMPANY_REQUESTS from '../../api/company'
import * as TRANSACTION_REQUESTS from '../../api/transactionInformations'

import CHECKOUT_SUCCESS from '../../assets/checkout-success.gif'
import CHECKOUT_ERROR from '../../assets/error.gif'
import QUOTE from '../../assets/quote.png'

const useStyles = makeStyles({
    dialogPaper: {
        borderRadius: 0
    }
})

const SuccessPayment = () => {
    const classes = useStyles()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { companyId } = useParams()
    const [searchParams] = useSearchParams()

    const { user } = useAuthentication()
    const [subscriptions, setSubscriptions] = useState([])
    const [company, setCompany] = useState()
    const [error, setError] = useState()
    const [doPayment, setDoPayment] = useState(false)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; // Required for Chrome

            // Display the confirmation message
            const confirmationMessage = 'Please do not leave this page until you get a success message.';
            event.returnValue = confirmationMessage; // For browsers that support it
            return confirmationMessage; // For newer browsers
        };

        window.onbeforeunload = handleBeforeUnload;

        return () => {
            // Cleanup by removing the event handler when the component unmounts
            window.onbeforeunload = null;
        };
    }, []);

    useEffect(() => {
        if (!user) return

        TRANSACTION_REQUESTS.getLastOrder(companyId)
            .then(async result => {
                if (result.success) {
                    return navigate('/calendar')
                }
                if (result.bt?.paymentAmountInfo?.paymentState !== 'DEPOSITED') {
                    setError(result.bt)
                }
                const [resultSubscriptions, resultCompany] = await Promise.all([
                    TRANSACTION_REQUESTS.getSubscriptions(),
                    COMPANY_REQUESTS.getBusinessById(companyId)
                ])
                setSubscriptions(resultSubscriptions.map(el => el.name))
                setCompany(resultCompany)
                setDoPayment(true)
            })
            .catch(async () => {
                const result = await TRANSACTION_REQUESTS.getLastOrder(companyId)

                setError(result.bt)
            })

    }, [user, searchParams])

    useEffect(() => {
        if (!doPayment) return
        const queryParams = window.location.search.replace(/&amp;/g, '&').replace(/^./, "")
        const paramsMap = new Map()
        const splitByAnd = queryParams.split('&')
        splitByAnd.forEach(el => {
            const elSplit = el.split('=')
            paramsMap.set(elSplit[0], elSplit[1])
        })

        let body = {}

        for (let [key, value] of paramsMap.entries()) {
            if (key === 'validFrom') body['startDate'] = new Date(value).toISOString()
            else body[key] = value
        }
        setLoading(true)
        TRANSACTION_REQUESTS.complete(body)
            .catch(async () => {
                const result = await TRANSACTION_REQUESTS.getLastOrder(companyId)
                setError(result.bt)
            })
            .finally(() => {
                setTimeout(() => {
                    setLoading(false)
                    window.onbeforeunload = null;
                }, 3000)
            })
    }, [doPayment])

    if (loading) {
        return (
            <Grid
                container
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100%"
            >
                <Box pb={3}>
                    <CircularProgress />
                </Box>
                <Typography variant="h6" fontWeight={500}>Transaction in progress...</Typography>
                <Typography variant="h5" fontWeight={500}>Please do not leave this page until you see the success message!</Typography>
            </Grid>
        )
    }

    return (
        <Dialog open fullScreen classes={{ paper: classes.dialogPaper }}>
            <DialogContent>
                <Grid
                    container
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    pt={6}
                    pb={6}
                    pl={3}
                    pr={3}
                >
                    <Grid item xs={12}>
                        {error ? <img height={200} width={200} src={CHECKOUT_ERROR} /> : <img height={200} width={200} src={CHECKOUT_SUCCESS} />}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" fontWeight={'bold'} color="primary">
                            {error ? t('checkout.errorPaymentTitle') : t('checkout.congrats')}
                        </Typography>
                    </Grid>
                    {error ? <Grid item xs={12} display="flex" alignItems="center" flexDirection="column">
                        <Typography variant="body1" pt={3} fontWeight={500}>
                            {t('checkout.errorPayment')}
                        </Typography>
                        <Typography variant="body2" color={GRAY_TEXT_COLOR} fontWeight={'bold'} pt={1}>
                            {t('checkout.errorPaymentReason')}
                        </Typography>
                        <Typography variant="body1" style={{ textDecoration: 'underline' }} fontWeight="bold" pt={1}>
                            {error.actionCodeDescription}
                        </Typography>
                    </Grid> : null}
                    {!error ? <>
                        <Grid item xs={12}>
                            <Typography variant="body1" pt={3}>
                                {t('checkout.weSuccessfully')} {subscriptions.toString()} {t('checkout.from')} {company.name} {t('checkout.toYourAccount')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                {t('checkout.allEvents')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} pt={3}>
                            <Typography variant="h6">{t('checkout.needHelp')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                {t('checkout.ifYouHave')}{' '}
                                <a href={`${BASE_URL}/${company.domain}`}>{company.name}</a> {t('checkout.at')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                {company.email || ''} {t('checkout.or')} {company.phoneNumber || ''}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} pt={3}>
                            <img height={50} width={50} src={QUOTE} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" color={CARD_TEXT_COLOR}>
                                {t('checkout.dontWait')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" color={CARD_TEXT_COLOR}>
                                {t('checkout.beProud')}
                            </Typography>
                        </Grid>
                    </> : null}
                    <Grid item xs={12} pt={2} width={{ xs: '100%', md: '25%' }}>
                        <Button onClick={() => navigate(`/calendar/${company.domain}`)} fullWidth variant="contained" color="primary">
                            {t('checkout.goToCalendar')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} pt={9}>
                        <Typography variant="body1">{t('checkout.thanks')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <a href="https://www.facebook.com/groups/xtrainer/" style={{ color: PRIMARY_COLOR }}>
                            {t('checkout.join')}
                        </a>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default SuccessPayment