import { Avatar, Box, Chip, Container, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { uniq } from 'lodash'

import * as TRAINER_REQUESTS from '../../api/trainer'
import * as COMPANY_REQUESTS from '../../api/company'
import * as CALENDAR_REQUESTS from '../../api/calendar'
import { useNavigate, useParams } from 'react-router-dom'
import { GREEN_COLOR, LIGHT_GRAY_BORDER_COLOR, PRIMARY_COLOR, WHITE_COLOR } from '../../utils/constants'
import { getBackgroundImage } from '../../utils/helpers/bgImages'
import moment from 'moment'
import { CheckCircle } from '@mui/icons-material'

const CompanyCalendarServices = () => {

    const params = useParams()
    const navigate = useNavigate()

    const [company, setCompany] = useState()
    const [services, setServices] = useState([])
    const [selectedSportTypes, setSelectedSportTypes] = useState([])

    useEffect(() => {
        if (window.location.pathname.includes('/calendar-services/trainer')) {
            handleGetTrainer()
        }
        else {
            handleGetCompany()
        }
        handleGetServices()

    }, [])

    const handleGetCompany = async () => {
        try {
            const response = await COMPANY_REQUESTS.getBusinessByDomain(params.domain)
            setCompany(response)
        } catch (e) {
            console.error(e)
        }
    }

    const handleGetTrainer = async () => {
        try {
            const response = await TRAINER_REQUESTS.getTrainerByDomain(params.domain)
            setCompany(response)
        } catch (e) {
            console.error(e)
        }
    }

    const handleGetServices = async () => {
        try {
            const response = await CALENDAR_REQUESTS.getPublicCalendarByDomain(params.domain, window.location.pathname.includes('/calendar-services/trainer'))
            setServices(response.filter(el => moment(el.start).endOf('day').isSameOrAfter(moment().startOf('day'))))
        }
        catch (e) {
            console.error(e)
        }
    }

    const handleChipClicked = sportType => {
        setSelectedSportTypes(prevValue => {
            let prevValueCopy = [...prevValue]
            if (prevValueCopy.includes(sportType)) {
                prevValueCopy = prevValueCopy.filter(el => el !== sportType)
            }
            else {
                prevValueCopy = prevValueCopy.concat(sportType)
            }
            return prevValueCopy
        })
    }

    if (!company) return null

    return (
        <Container maxWidth="xl" sx={{ paddingLeft: 0, paddingRight: 0 }}>
            <Grid container display="flex" flexDirection="column" pt={8} pb={8}>
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="column" alignItems="center">
                        <Grid item xs={12} pb={2}>
                            <Avatar src={company.avatarPhotoSrc} sx={{ cursor: 'pointer', width: 75, height: 75, border: `2px solid ${WHITE_COLOR}` }}>AA</Avatar>
                        </Grid>
                        <Grid item xs={12} pb={1} display="flex" flexDirection="row" alignItems="center">
                            <Typography sx={{ textDecoration: 'underline', color: PRIMARY_COLOR, cursor: 'pointer' }} onClick={() => navigate(`/${company.domain}`)} pr={.5} fontWeight={"bold"} variant="h6">{window.location.pathname.includes('/calendar-services/trainer') ? `${company.firstName} ${company.lastName}` : company.name}</Typography>
                            <CheckCircle sx={{ fontSize: 20 }} color="primary" />
                        </Grid>
                        <Grid item lg={5} md={5} sm={10} xs={10} textAlign="center">
                            {window.location.pathname.includes('/calendar-services/trainer') ? <Typography>
                                Below is a detailed list of what I offer. If you have any questions or need more information, please reach out at <strong>{company.phoneNumber}</strong> or email me directly at <strong>{company.email}</strong>.
                            </Typography> : <Typography>
                                Below you will be able to visualize all services that are available in this club. If you have further questions, please contact the club at <strong>{company.phoneNumber}</strong> or <strong>{company.email}</strong>.
                            </Typography>}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} pt={2}>
                    <Grid container display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                        <Grid item>
                            {uniq(services.map(service => service.sportType)).map(sportType => <Chip
                                color="primary"
                                variant={selectedSportTypes.includes(sportType) ? 'contained' : 'outlined'}
                                onClick={() => handleChipClicked(sportType)}
                                sx={{ marginRight: 0.5, marginLeft: 0.5, cursor: 'pointer', marginBottom: .5 }} label={sportType} />)}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} pt={3}>
                    <Grid container display="flex" flexDirection="row" justifyContent="center" gap={3}>
                        {services.filter(el => selectedSportTypes.length > 0 ? selectedSportTypes.includes(el.sportType) : true).map(service => {
                            return (
                                <Box>
                                    <Grid
                                        onClick={() => navigate(`/calendar/services/${service.id}?day=${moment().format('YYYY-MM-DD')}&lastPage=true`)}
                                        container
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        justifyContent="center"
                                        pl={2}
                                        pr={2}
                                        pt={1}
                                        pb={1}
                                        mb={2}
                                        minWidth={385}
                                        maxWidth={385}
                                        sx={{
                                            cursor: 'pointer',
                                            border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
                                            borderRadius: 3,
                                            color: WHITE_COLOR,
                                            boxShadow: '3px 7px 8px 0px #CFCFCF',
                                            height: 225,
                                            backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.6)),url(${getBackgroundImage(service.sportType || '')})`,
                                            backgroundSize: `385px 225px`,
                                            backgroundRepeat: 'no-repeat',
                                        }}
                                    >
                                        <Box>
                                            <Typography variant="body2" fontWeight={"bold"} pt={10} sx={{ textShadow: '3px 3px 2px rgba(0, 0, 0, 0.63)' }}>
                                                {service.name}
                                            </Typography>
                                        </Box>
                                        <Grid container pb={12} lg={12} display="flex" flexDirection="row" justifyContent="center" columnSpacing={0.3} rowSpacing={0.2}>
                                            {service.tags && service.tags[0] ? <Grid item width="min-content">
                                                <Chip
                                                    sx={{
                                                        fontWeight: 500,
                                                        width: '100%',
                                                        fontSize: 9,
                                                        height: 20,
                                                        color: service.tags[0] === 'FREE' ? GREEN_COLOR : '',
                                                        backgroundColor: 'rgba(255,255,255,0.8)'
                                                    }}
                                                    label={service.tags[0]}
                                                />
                                            </Grid> : null}
                                            {services.tags && service.tags[1] ? <Grid item width="min-content">
                                                <Chip
                                                    sx={{
                                                        fontWeight: 600,
                                                        width: '100%',
                                                        fontSize: 9,
                                                        height: 20,
                                                        color: service.tags[1] === 'FREE' ? GREEN_COLOR : '',
                                                        backgroundColor: 'rgba(255,255,255,0.8)'
                                                    }}
                                                    label={service.tags[1]}
                                                />
                                            </Grid> : null}
                                            {service.tags && service.tags[2] ? (
                                                <Grid item width="min-content">
                                                    <Chip
                                                        sx={{
                                                            fontWeight: 500,
                                                            width: '100%',
                                                            fontSize: 9,
                                                            height: 20,
                                                            color: service.tags[2] === 'FREE' ? GREEN_COLOR : '',
                                                            backgroundColor: 'rgba(255,255,255,0.8)'
                                                        }}
                                                        label={service.tags[2]}
                                                    />
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                    </Grid >
                                </Box>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default CompanyCalendarServices