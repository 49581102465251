import axios from './axios'

import { queryString } from '../utils/helpers/queryParams'

export const getMeasurementsTypes = (options = {}, userId) => {
  return axios.get(`/measurementTypes?${queryString({...options, userId})}`).then((res) => res.data)
}

export const getOneMeasurementType = (id) => {
  return axios.get(`/measurementTypes/${id}`).then((res) => res.data)
}

export const createMeasurementType = (payload, userId) => {
  return axios.post('/measurementTypes', { ...payload, userId }).then((res) => res.data)
}

export const deleteMeasurementType = (id) => {
  return axios.delete(`/measurementTypes/${id}`).then((res) => res.data)
}

export const updateMeasurementType = (id, data) => {
  return axios.patch(`/measurementTypes/${id}`, { ...data }).then((res) => res.data)
}
