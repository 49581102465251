import { Grid, Dialog, DialogContent, Typography, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useEffect, useMemo, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import * as AUTH_REQUESTS from '../../../../api/auth'

import OnBoardingImage1 from '../../../../assets/onboarding1.png'
import OnBoardingImage2 from '../../../../assets/onboarding2.png'
import OnBoardingImage3 from '../../../../assets/onboarding3.png'

const useStyles = makeStyles({
  dialogPaper: {
    minWidth: '50%'
  }
})

const DialogOnboarding = ({ onClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [step, setStep] = useState(1)

  const handleNextStep = async () => {
    setStep(step + 1)
  }

  const handlePreviousStep = () => {
    setStep(step - 1)
  }

  const handleUpdateUser = useCallback(() => {
    ; (async () => {
      let result = await AUTH_REQUESTS.updateUser({ onboardingCompleted: true })
      if (result) onClose()
    })()
  }, [])

  useEffect(() => {
    if (step > 3) {
      handleUpdateUser()
    }
  }, [step])

  const renderDialog = useMemo(() => {
    return (
      <Dialog
        open={true}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Grid
            p={{ lg: 6, xs: 2 }}
            container
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 400 }}
          >
            <Grid flex={4} display="flex" flexDirection="column">
              <Typography
                pb={2}
                flex={1}
                id="alert-dialog-title"
                fontSize={25}
                fontWeight="bold"
                variant="p"
                color="#292838"
              >
                {step === 1
                  ? t('helper.onboardingFirstText')
                  : step === 2
                    ? t('helper.onboardingSecondText')
                    : t('helper.onboardingThirdText')}
              </Typography>
              <Typography
                id="alert-dialog-description"
                fontWeight={500}
                fontSize={14}
                variant="p"
                color="InfoText"
              >
                {step === 1
                  ? t('helper.onboardingFirstTextDescription')
                  : step === 2
                    ? t('helper.onboardingSecondTextDescription')
                    : t('helper.onboardingThirdTextDescription')}
              </Typography>
              <Grid sx={{ paddingLeft: 0 }} p={1}>
                <Typography variant="p" fontWeight={400} fontSize={14} color="primary">
                  {step === 4 ? '' : `${step}/3`}
                </Typography>
              </Grid>

              <Grid pt={3} item display="flex" flexDirection="row">
                <Grid container display="flex" flexDirection="row">
                  {step > 1 && (
                    <Grid
                      item
                      paddingBottom={{ xs: 2, lg: 0 }}
                      paddingRight={{ xs: 0, lg: 2 }}
                      xs={12}
                      lg={4}
                    >
                      <Button
                        onClick={handlePreviousStep}
                        fullWidth
                        color="primary"
                        variant="outlined"
                      >
                        {t('button.back')}
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12} lg={8} paddingLeft={{ xs: 0 }}>
                    <Button onClick={handleNextStep} fullWidth color="primary" variant="contained">
                      {step === 3 ? t('button.explore') : t('button.next')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid flex={3} display={{ xs: 'none', sm: 'none', lg: 'block' }}>
              <img
                src={
                  step === 1 ? OnBoardingImage1 : step === 2 ? OnBoardingImage2 : OnBoardingImage3
                }
                alt="SportIn management pentru antrenor, sala de fitness si client"
                height={'100%'}
                width={'100%'}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }, [t, step, handleNextStep])

  return renderDialog
}

export default DialogOnboarding
