import { Grid, Dialog, Typography, Button, Avatar } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Image from '../../../../../assets/no-result.gif'

const DenyModal = ({ onClose, title, text, onDelete }) => {
  const { t } = useTranslation()

  const handleCancelModal = () => {
    onClose()
  }

  const handleDelete = () => {
    onDelete()
  }

  return (
    <Dialog open={true} PaperProps={{ sx: { width: '100%' } }}>
      <Grid
        p={4}
        container
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
      >
        <Grid
          item
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Avatar style={{ width: 150, height: 150 }} src={Image} />
          <Typography
            p={2}
            pt={0}
            alignSelf="center"
            flex={1}
            id="alert-dialog-title"
            fontWeight="bold"
            variant="h5"
            color="#292838"
          >
            {title}
          </Typography>
        </Grid>
        <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography flex={1} alignSelf="center" variant="p" fontSize={18}>
            {text}
          </Typography>
        </Grid>
        <Grid
          paddingTop={8}
          container
          sx={{
            flexDirection: {
              xs: 'column-reverse',
              md: 'row'
            }
          }}
          spacing={2}
        >
          <Grid xs={12} lg={6} item>
            <Button onClick={handleCancelModal} fullWidth color="primary" variant="outlined">
              {t('button.cancel')}
            </Button>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Button onClick={handleDelete} fullWidth color="error" variant="contained">
              {t('collaborationRequests.delete')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default DenyModal
