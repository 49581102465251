import React, { useState, useEffect } from 'react'
import {
  Grid,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  Slide
} from '@mui/material'
import { Search } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import Service from './subcomponents/Service'
import CreateServiceDialog from '../../../Services/Create/CreateServiceDialog'
import EditServiceDialog from '../../../Services/Edit/EditServiceDialog'
import DeleteServiceDialog from '../../../Services/Delete/DeleteServiceDialog'
import * as TRAINER_REQUESTS from '../../../../api/trainer'

const Services = ({
  Services,
  handleSearch,
  handleFilters,
  handleGetAllServices,
  handleOnDelete,
  filters
}) => {
  const { t } = useTranslation()

  const [openCreateDialog, setOpenCreateDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [selectedServiceId, setSelectedServiceId] = useState()
  const [currency, setCurrency] = useState('RON')

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })

  useEffect(() => {
    getTrainerCurrency()
  }, [])

  const handleOnOpenEdit = (serviceId) => {
    setSelectedServiceId(serviceId)
    setOpenEditDialog(true)
  }

  const handleOnOpenDelete = (serviceId) => {
    setSelectedServiceId(serviceId)
    setOpenDeleteDialog(true)
  }

  const handleDelete = () => {
    handleOnDelete(selectedServiceId)
    setOpenDeleteDialog(false)
    handleGetAllServices()
    setSelectedServiceId(null)
  }

  const getTrainerCurrency = async () => {
    try {
      const currency = await TRAINER_REQUESTS.getTrainerCurrency();
      
      setCurrency(currency)
    } catch (error) {
      console.error(`Error getting trainer currency: ${error}`)
    }
  }

  return (
    <>
      {openEditDialog && selectedServiceId ? (
        <EditServiceDialog
          type="trainer"
          Transition={Transition}
          handleClose={() => {
            setOpenEditDialog(false)
            setSelectedServiceId()
            handleGetAllServices()
          }}
          serviceId={selectedServiceId}
        />
      ) : null}
      {openCreateDialog ? (
        <CreateServiceDialog
          type="trainer"
          Transition={Transition}
          handleClose={() => {
            setOpenCreateDialog(false)
            handleGetAllServices()
          }}
          currency={currency}
        />
      ) : null}
      {openDeleteDialog ? (
        <DeleteServiceDialog
          type="trainer"
          handleDelete={handleDelete}
          handleClose={() => {
            setOpenDeleteDialog(false)
            setSelectedServiceId(null)
          }}
        />
      ) : null}
      <Grid container display="flex" p={3} pt={4}>
        <Grid container display="flex" flexDirection="row">
          <Grid item xs={12} lg={10}>
            <Grid container display="flex" flexDirection={{ lg: 'row', xs: 'column' }}>
              <Grid lg={6} xs={12} pr={{ lg: 2, xs: 0 }} item>
                <TextField
                  onChange={handleSearch}
                  fullWidth
                  placeholder="Search for services"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <Search style={{ color: '#C6C6C6' }} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                ></TextField>
              </Grid>
              <Grid item lg={6} xs={12} pt={{ lg: 0, xs: 1 }}>
                <Grid
                  container
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent={{ lg: 'center', xs: 'flex-start' }}
                >
                  <Grid item xs={6} pr={{ lg: 2, xs: 2 }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filters.active !== undefined ? filters.active : 'all'}
                      onChange={(e) => handleFilters('active', e.target.value)}
                      fullWidth
                    >
                      <MenuItem value={'all'}>{t('helper.all')}</MenuItem>
                      <MenuItem value={true}>{t('helper.active')}</MenuItem>
                      <MenuItem value={false}>{t('helper.inactive')}</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={6} pr={{ lg: 2, xs: 0 }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filters.public !== undefined ? filters.public : 'all'}
                      onChange={(e) => handleFilters('public', e.target.value)}
                      fullWidth
                    >
                      <MenuItem value={'all'}>{t('helper.all')}</MenuItem>
                      <MenuItem value={true}>{t('helper.public')}</MenuItem>
                      <MenuItem value={false}>{t('helper.private')}</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid lg={2} xs={12} display="flex" justifyContent="flex-end" item pt={{ lg: 0, xs: 1 }}>
            <Button
              onClick={() => setOpenCreateDialog(true)}
              fullWidth
              variant="contained"
              color="success"
            >
              {t('userProfile.activity.trainerMembershipComponent.add')}
            </Button>
          </Grid>
        </Grid>
        <Grid pt={2} container>
          {Services.length > 0 ? (
            Services.map((service, index) => {
              return (
                <Grid key={index} xs={12} item pt={2} pb={2}>
                  <Service
                    {...service}
                    name={service.name}
                    description={service.description}
                    price={service.priceRon}
                    currency={currency}
                    Reviews={service.Reviews}
                    trainerName={service.trainerName}
                    expand={service.expand}
                    handleExpand={service.handleExpand}
                    handleOnOpenEdit={handleOnOpenEdit}
                    handleOnDelete={handleOnOpenDelete}
                    id={service.id}
                  />
                </Grid>
              )
            })
          ) : (
            <Typography color="#979797" fontWeight={500} fontSize={17}>
              {t('helper.noResultsFound')}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default Services
