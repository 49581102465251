import { Box, CircularProgress, IconButton, InputAdornment, TextField, Typography, Button } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { ExpandLess, ExpandMore, Search } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';
import ExpendedTableDetails from "./ExpendedMonthDetails";
import TableHeaderItems from "./data.json";
import useAuditLogs from "../../../hooks/useAuditLogs";
import { useAuthentication } from '../../../context/auth-context';
import _ from 'lodash';

const AuditLogsTable = () => {
    const { t } = useTranslation();
    const { user } = useAuthentication();
    const { getLogs, logs, loading } = useAuditLogs();
    const [expended, setExpended] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [visibleLogs, setVisibleLogs] = useState([]);
    const [visibleCount, setVisibleCount] = useState(10);

    const handleExpendTable = () => {
        setExpended((prev) => !prev);
        if (!expended) getLogs(user.companyId);
    };

    const debouncedSetSearchQuery = useCallback(
        _.debounce((value) => {
            setSearchQuery(value);
        }, 1000),
        []
    );

    const handleQueryChange = (event) => {
        debouncedSetSearchQuery(event.target.value);
    };

    useEffect(() => {
        if (expended) {
            getLogs(user.companyId, searchQuery);
        }
    }, [searchQuery, expended, user.companyId, getLogs]);

    useEffect(() => {
        if (logs.length > 0) {
            setVisibleLogs(logs.slice(0, visibleCount));
        }
    }, [logs, visibleCount]);

    const loadMore = () => {
        setVisibleCount((prevCount) => prevCount + 10);
    };

    const loadLess = () => {
        setVisibleCount((prevCount) => (prevCount - 10 > 10 ? prevCount - 10 : 10));
    };

    return (
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                width: '100%', 
                maxWidth: '1350px', 
                height: 'fit-content', 
                background: '#FFFFFF 0% 0% no-repeat padding-box', 
                boxShadow: "5px 12px 30px #00000010", 
                borderRadius: 3, 
                overflow: 'hidden' 
            }}
        >
            <Box 
                sx={{ 
                    padding: '2%', 
                    display: 'flex', 
                    justifyContent: { xs: 'center', md: 'flex-start' }  // Center on mobile, left on larger screens
                }}
            >
                <TextField
                    onChange={handleQueryChange}
                    placeholder={t('helper.searchInsideColumns')}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    <Search style={{ color: '#C6C6C6' }} />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    sx={{
                        width: { xs: '100%', md: 'auto' }  // Full width on mobile, auto on larger screens
                    }}
                />
            </Box>
            <Box sx={{ width: '100%', overflowX: 'auto' }}>
                <Box sx={{ minWidth: '1350px' }}>
                    <Box 
                        sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            minHeight: '7vh', 
                            cursor: 'pointer', 
                            backgroundColor: '#dbdce0' 
                        }} 
                        onClick={handleExpendTable}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '70px' }} >
                            {expended ? <ExpandLess sx={{ fontSize: '2.5rem' }} /> : <ExpandMore sx={{ fontSize: '2.5rem' }} />}
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                            {TableHeaderItems.map((item, index) => (
                                <Box 
                                    key={index} 
                                    sx={{ 
                                        display: 'flex', 
                                        justifyContent: 'center', 
                                        width: item.width, 
                                        textAlign: item.textCenter ? "center" : "start", 
                                        backgroundColor: item.bg ? "red" : "none" 
                                    }}
                                >
                                    <Typography fontSize={'1rem'} fontWeight={'bold'}>{item.text}</Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    {loading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
                            <CircularProgress />
                        </Box>
                    )}
                    {!loading && expended && (
                        <>
                            <ExpendedTableDetails data={visibleLogs} />
                            {logs.length > visibleLogs.length && (
                                <Box sx={{ display: 'flex', justifyContent: 'center', margin: 2 }}>
                                    <Button onClick={loadMore}>{t('button.loadMore')}</Button>
                                </Box>
                            )}
                            {visibleCount > 10 && (
                                <Box sx={{ display: 'flex', justifyContent: 'center', margin: 2 }}>
                                    <Button onClick={loadLess}>{t('button.loadLess')}</Button>
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default AuditLogsTable;
