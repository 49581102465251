import React, { useEffect, useState } from 'react'
import { Dialog, IconButton, Button, Typography, Grid, TextField } from '@mui/material'
import { Close } from '@mui/icons-material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'

import * as USER_SUBSCRIPTION_REQUESTS from '../../../../api/userSubscriptions'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const ChangeStartEndDate = ({
    onCancel,
    onConfirm,
    userSubscription
}) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState(moment())
    const [endDate, setEndDate] = useState(moment())

    const confirmUpdate = () => {
        setLoading(true)
        return USER_SUBSCRIPTION_REQUESTS.update(userSubscription.id, { validFrom: startDate, validUntil: moment(endDate).utc().endOf('day').toISOString() })
            .catch(() => {
                console.error('Something went wrong')
            })
            .finally(() => {
                setLoading(false)
                onConfirm()
                onCancel()
            })
    }

    useEffect(() => {
        if (!userSubscription) return

        setStartDate(moment(userSubscription.validFrom))
        setEndDate(moment(userSubscription.validUntil).subtract(2, 'hours'))
    }, [userSubscription])

    if (!userSubscription) return null

    return (
        <Dialog open maxWidth="sm" fullWidth>
            <Grid container p={4}>
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item
                            xs={10}
                            md={10}
                            lg={10}
                            pr={{ xs: 0, md: 0, lg: 2 }}
                            pl={{ xs: 0, md: 0, lg: 2 }}>
                            <Typography variant="h4" fontWeight={500} color={'black'}>
                                {t('clients.subscriptionDates')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                            <IconButton onClick={onCancel}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    pt={4}
                    pb={4}
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}
                >
                    <Typography variant="h6">{t('clients.youCanStartEdit')} {userSubscription.Subscription.name}.</Typography>
                </Grid>
                <Grid
                    item
                    pt={4}
                    pb={4}
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}
                >
                    <Grid container display='flex' flexDirection="row" spacing={1}>
                        <Grid item xs={6}>
                            <Typography variant="p">{t('clients.validFrom')}</Typography>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DesktopDatePicker
                                    inputFormat="dd/MM/yyyy"
                                    disabled={loading}
                                    value={startDate}
                                    onChange={(newValue) => {
                                        setStartDate(newValue)
                                    }}
                                    renderInput={(params) => <TextField
                                        fullWidth
                                        sx={{
                                            '.MuiIconButton-root': { marginRight: 1 }
                                        }}
                                        {...params}
                                    />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="p">{t('clients.validUntil')}</Typography>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DesktopDatePicker
                                    inputFormat="dd/MM/yyyy"
                                    disabled={loading}
                                    value={endDate}
                                    onChange={(newValue) => {
                                        setEndDate(newValue)
                                    }}
                                    renderInput={(params) => <TextField
                                        fullWidth
                                        sx={{
                                            '.MuiIconButton-root': { marginRight: 1 }
                                        }}
                                        {...params}
                                    />}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} pt={3}>
                    <Grid container display="flex" flexDirection="row" spacing={3}>
                        <Grid item xs={12} md={12} lg={6}>
                            <Button onClick={onCancel} fullWidth variant="outlined">
                                {t('clients.cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <Button
                                onClick={confirmUpdate}
                                fullWidth
                                disabled={loading}
                                variant="contained"
                            >
                                {t('clients.save')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default ChangeStartEndDate