import React, { useEffect, useState } from 'react'
import { Dialog, IconButton, Button, Typography, Grid, TextField } from '@mui/material'
import { Close, Pause } from '@mui/icons-material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import moment from 'moment'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import * as PAUSE_REQUESTS from '../../../../api/pause'
import { useTranslation } from 'react-i18next'

const PauseSubscriptionDialog = ({
    onCancel,
    userSubscription,
    onConfirm
}) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const matchesTablet = useMediaQuery(theme.breakpoints.down('md'))
    const initialStartDate = moment()
    const initialEndDate = moment(initialStartDate).set('date', initialStartDate.getDate() + userSubscription.Subscription.minimumPauseDays)
    const initialMaxEndDate = moment(initialEndDate).set('date', moment(initialEndDate).getDate() + userSubscription.Subscription.maximumPauseDays)
    const maxInitialStartDate = moment(userSubscription.validUntil).set('date', moment(userSubscription.validUntil).getDate() - 1)
    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState(initialStartDate)
    const [endDate, setEndDate] = useState(initialEndDate)
    const [maxEndDate, setMaxEndDate] = useState(initialMaxEndDate)

    const disableCreate = !!userSubscription.Pause

    useEffect(() => {
        const minEndDate = moment(startDate)
        setEndDate(minEndDate.set('date', startDate.getDate() + userSubscription.Subscription.minimumPauseDays))
        setMaxEndDate(moment(minEndDate).set('date', moment(minEndDate).getDate() + userSubscription.Subscription.maximumPauseDays))
    }, [startDate, userSubscription])

    const onPauseSave = () => {
        setLoading(true)

        const data = {
            userSubscriptionId: userSubscription.id,
            startDate: moment(startDate).toISOString(),
            endDate: moment(endDate).toISOString()
        }

        return PAUSE_REQUESTS.create(data)
            .then(onConfirm)
            .finally(() => {
                setLoading(false)
                onCancel()
            })
    }

    const onPauseDeleteHandler = () => {
        const confirmed = window.confirm(t('clients.deletePauseConfirm'))

        if (!confirmed) return

        setLoading(true)

        return PAUSE_REQUESTS.remove(userSubscription.id)
            .then(onConfirm)
            .finally(() => {
                onCancel()
                setLoading(false)
            })
    }

    if (!userSubscription) return null

    return (
        <Dialog open maxWidth="sm" fullScreen={matchesTablet} fullWidth PaperProps={{ ...(matchesTablet ? { sx: { borderRadius: 0 } } : {}) }}>
            <Grid container p={4}>
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item
                            xs={10}
                            md={10}
                            lg={10}
                            pr={{ xs: 0, md: 0, lg: 2 }}
                            pl={{ xs: 0, md: 0, lg: 2 }}>
                            <Typography variant="h4" fontWeight={500} color={'black'}>
                                {t('clients.pauseSub')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                            <IconButton onClick={onCancel}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    pt={4}
                    pb={4}
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}
                >
                    <Typography variant="h6">1. {t('clients.youCanPause')} <b>{userSubscription.Subscription.name}</b> {t('clients.onlyOneTime')}</Typography>
                    <Typography variant="h6">2. {t('clients.thePause')} <b>{t('clients.min')}</b> {t('clients.of')} <b>{userSubscription.Subscription.minimumPauseDays} {t('clients.days')}</b>  {t('clients.andA')} <b>{t('clients.max')}</b> {t('clients.of')} <b>{userSubscription.Subscription.maximumPauseDays} {t('clients.days')}</b>.</Typography>
                    <Typography variant="h6">3. {t('clients.youCanRemove')}</Typography>
                </Grid>
                <Grid
                    item
                    pt={4}
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}
                >
                    {userSubscription.pauseEnabled ? <Grid container spacing={1} display="flex" flexDirection="row">
                        <Grid item xs={12} md={12} lg={6}>
                            <Grid item xs={12} pb={0.5}>
                                <Typography variant="p" fontWeight={500}>{t('clients.minPause')}</Typography>
                            </Grid>
                            <TextField
                                fullWidth
                                disabled
                                type="number"
                                value={userSubscription.Subscription.minimumPauseDays}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <Grid item xs={12} pb={0.5} pt={{ xs: 1, md: 1, lg: 0 }}>
                                <Typography variant="p" fontWeight={500}>{t('clients.maxPause')}</Typography>
                            </Grid>
                            <TextField
                                fullWidth
                                disabled
                                type="number"
                                value={userSubscription.Subscription.maximumPauseDays}
                            />
                        </Grid>
                    </Grid> : null}
                </Grid>
                <Grid
                    item
                    pt={{ xs: 2, md: 2, lg: 2 }}
                    pb={6}
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}
                >
                    <Grid container display="flex" flexDirection="row" spacing={1}>
                        <Grid item xs={12} md={12} lg={6}>
                            <Grid item xs={12} pb={0.5}>
                                <Typography variant="p" fontWeight={500}>{t('clients.selectStart')}</Typography>
                            </Grid>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DesktopDatePicker
                                    inputFormat="dd/MM/yyyy"
                                    value={startDate}
                                    minDate={moment()}
                                    maxDate={userSubscription.pauseEnabled ? maxInitialStartDate : null}
                                    disabled={disableCreate}
                                    onChange={(newValue) => {
                                        setStartDate(moment(newValue))
                                    }}
                                    renderInput={(params) => <TextField
                                        fullWidth
                                        sx={{
                                            '.MuiIconButton-root': { marginRight: 1 }
                                        }}
                                        {...params}
                                    />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <Grid item xs={12} pb={0.5} pt={{ xs: 1, md: 1, lg: 0 }}>
                                <Typography variant="p" fontWeight={500}>{t('clients.selectEnd')}</Typography>
                            </Grid>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DesktopDatePicker
                                    inputFormat="dd/MM/yyyy"
                                    value={endDate}
                                    minDate={endDate}
                                    maxDate={userSubscription.pauseEnabled ? maxEndDate : null}
                                    disabled={disableCreate}
                                    onChange={(newValue) => {
                                        setEndDate(moment(newValue))
                                    }}
                                    renderInput={(params) => <TextField
                                        fullWidth
                                        sx={{
                                            '.MuiIconButton-root': { marginRight: 1 }
                                        }}
                                        {...params}
                                        error={false}
                                    />}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    pt={4}
                    pb={4}
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}
                >
                    <Typography variant="h6">{t('clients.onceYou')} {t('clients.startingFrom')} {moment(moment(startDate).toISOString()).format('DD/MM/YYYY')} {t('clients.until')} {moment(moment(endDate).toISOString()).format('DD/MM/YYYY')}.</Typography>
                </Grid>
                {userSubscription.Pause ? <>
                    <Grid
                        item
                        pt={4}
                        pb={4}
                        xs={12}
                        display="flex"
                        flexDirection="column"
                        pr={{ xs: 0, md: 0, lg: 2 }}
                        pl={{ xs: 0, md: 0, lg: 2 }}
                    >
                        <Typography variant="h6" sx={{ textDecoration: 'underline' }}>
                            {t('clients.theUserHasCreated')}
                        </Typography>

                    </Grid>
                </> : null}
                <Grid item xs={12} pt={3} pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}>
                    <Grid container display="flex" flexDirection="row" spacing={3}>
                        <Grid item xs={12} md={12} lg={6}>
                            <Button onClick={onCancel} fullWidth variant="outlined">
                                {t('clients.cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <Button
                                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                onClick={userSubscription.Pause ? onPauseDeleteHandler : onPauseSave}
                                fullWidth
                                disabled={loading}
                                color={userSubscription.Pause ? 'success' : 'warning'}
                                variant="contained"
                            >
                                {userSubscription.Pause ? t('clients.activate') : <>{t('clients.pauseNormal')} <Pause fontSize="small" /></>}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default PauseSubscriptionDialog