import * as React from 'react'
import { AppBar, Box, Toolbar, IconButton, Typography, } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

import Drawer from './Drawer'

import { ReactComponent as Logo } from '../../../assets/logo-simple.svg'
import { CARD_TEXT_COLOR, GRAY_TEXT_COLOR } from '../../../utils/constants'

import { useAuthentication } from '../../../context/auth-context'

export default () => {
  const { user } = useAuthentication()

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color='transparent' elevation={0}>
        <Toolbar>
          <Box mr={1}>
            <Drawer />
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
            <Typography variant="h6" fontWeight="bold" color="primary" mr={1}>{user.firstName} {user.lastName}</Typography>   <Typography variant="span" fontWeight="500" color={GRAY_TEXT_COLOR}>{user.businessName}</Typography>
          </Box>

          <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
            <Typography pr={1} fontWeight="bold" variant="h6" color="primary">
              eCredits
            </Typography>
            <Typography pr={1} color={CARD_TEXT_COLOR}>
              powered by
            </Typography>
            <Logo />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}