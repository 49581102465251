/* eslint-disable no-unused-vars */
import { Close } from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
  DialogActions,
  IconButton
} from '@mui/material'
import { useTranslation } from 'react-i18next'

const DeleteSubscriptionDialog = ({ handleClose, handleDelete }) => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth={'sm'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        p={4}
      >
        <Grid container display="flex" flexDirection="row">
          <Grid item xs={10}>
            <Typography variant="h6">{t('userProfile.cancelSubscription')}</Typography>
          </Grid>
          <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
            <IconButton style={{ color: 'black' }} onClick={handleClose}>
              <Close fontSize="medium" color="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography variant="p">{t('userProfile.confirmCancelSub')}</Typography>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: 4 }}>
        <Grid
          container
          display="flex"
          flexDirection={{ lg: 'row', xs: 'column' }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item pr={{ xs: 0, lg: 2 }}>
            <Button sx={{ minWidth: 260 }} variant="outlined" color="primary" onClick={handleClose}>
              {t('userProfile.cancel')}
            </Button>
          </Grid>
          <Grid item pl={{ xs: 0, lg: 2 }} mt={{ lg: 0, xs: 1 }}>
            <Button sx={{ minWidth: 260 }} variant="contained" color="error" onClick={handleDelete}>
              {t('userProfile.delete')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteSubscriptionDialog
