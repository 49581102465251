import React, { useState, useEffect, useMemo } from 'react'
import { Dialog, IconButton, Button, Typography, Grid, TextField, MenuItem, FormControl, Select, Box, Chip, FormHelperText } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { toast } from 'react-toastify'
import { Toggle } from '../../../components/common'
import { useAuthentication } from '../../../context/auth-context'
import { LIGHT_GRAY_BORDER_COLOR, VOUCHER_TYPES, DATE_FORMAT } from '../../../utils/constants'
import * as COMPANY_REQUESTS from '../../../api/company'
import * as VOUCHER_REQUESTS from '../../../api/voucher'
import moment from 'moment'
import InputField from '../InputField/index'

const CreateModal = ({
    onCancel,
    onConfirm,
    payload,
    edit,
    currency
}) => {
    const { t } = useTranslation()
    const { user } = useAuthentication()

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        type: VOUCHER_TYPES.DISCOUNT,
        name: '',
        discount: 0,
        minimumBuys: 1,
        maximumDelayInDays: 1,
        status: '',
        expiresAt: '',
        hasFixedDiscount: false,
        amount: null,
        showInDeals: false
    })
    const [subscriptions, setSubscriptions] = useState([])
    const [selectedSubscriptions, setSelectedSubscriptions] = useState([])

    useEffect(() => {
        if (!edit || !payload) return
        const newData = { ...data }

        Object.keys(newData).forEach(key => {
            newData[key] = payload[key]
        })

        if (payload.showInDeals) {
            newData.amount = payload.DealsVoucher.amount
        }

        setSelectedSubscriptions([payload.Subscription.id])
        setData(newData)
    }, [edit, payload])

    useEffect(() => {
        if (!user.companyId) return
        getSubscriptions()
    }, [user])

    const getSubscriptions = () => {
        COMPANY_REQUESTS.getAllSubscriptions(user.companyId)
            .then(response => {
                setSubscriptions(response)
            })
    }

    const setDataField = (field, value) => {
        setData(prevData => ({
            ...prevData,
            [field]: value
        }))
    }

    const toggleSubscription = subscriptionId => {
        const newSelectedSubscriptions = selectedSubscriptions.includes(subscriptionId)
            ? selectedSubscriptions.filter(id => id !== subscriptionId)
            : [...selectedSubscriptions, subscriptionId]
        setSelectedSubscriptions(newSelectedSubscriptions)
    }

    const handleChange = event => {
        const { name, value } = event.target
        setDataField(name, value)
    }

    const handleToggle = () => {
        setData(prevData => ({ ...prevData, hasFixedDiscount: !prevData.hasFixedDiscount }))
    }

    const handleVoucherAmount = () => {
        setData(prevData => ({ ...prevData, showInDeals: !prevData.showInDeals, amount: null }))
    }

    const handleSubmit = async () => {
        setLoading(true)

        return await (edit ? VOUCHER_REQUESTS.update(payload.id, data) : VOUCHER_REQUESTS.bulkCreate(selectedSubscriptions.map(subscriptionId => {
            const { expiresAt, ...restOfData } = data

            return {
                subscriptionId,
                ...restOfData,
                status: 'ACTIVE',
                ...(expiresAt ? { expiresAt } : {})
            }
        }))).then(createdVouchers => {
            if (!createdVouchers.length) {
                toast.warning(t('vouchers.warningCreate'), { position: 'bottom-right' })
            }
        }).finally(() => {
            onConfirm()
        })
    }

    const renderInputs = useMemo(() => {
        switch (data.type) {
            case VOUCHER_TYPES.RECURRING:
            case VOUCHER_TYPES.DISCOUNT:
                return (
                    <>
                        <Grid item xs={6}>
                            <InputField
                                label={t('vouchers.createModal.name')}
                                value={data.name}
                                onChange={handleChange}
                                name="name"
                                placeholder={t('vouchers.createModal.namePlaceholder')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputField
                                label={t('vouchers.createModal.discount')}
                                type="number"
                                value={data.discount}
                                onChange={handleChange}
                                name="discount"
                                placeholder={t('vouchers.createModal.discountPlaceholder')}
                                endAdornment={data.hasFixedDiscount ? currency : '%'}
                            />
                        </Grid>
                    </>
                )
            case VOUCHER_TYPES.CUSTOM:
                return (
                    <>
                        <Grid item xs={6}>
                            <InputField
                                label={t('vouchers.createModal.customVoucher')}
                                value={data.name}
                                onChange={handleChange}
                                name="name"
                                placeholder={t('vouchers.createModal.customVoucherPlaceholder')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputField
                                label={t('vouchers.createModal.discount')}
                                type="number"
                                value={data.discount}
                                onChange={handleChange}
                                name="discount"
                                placeholder={t('vouchers.createModal.discountPlaceholder')}
                                endAdornment={data.hasFixedDiscount ? currency : '%'}
                            />
                        </Grid>
                    </>
                )
            case VOUCHER_TYPES.CONSECUTIVE_BUYS:
                return (
                    <>
                        <Grid item xs={6}>
                            <InputField
                                label={t('vouchers.createModal.name')}
                                value={data.name}
                                onChange={handleChange}
                                name="name"
                                placeholder={t('vouchers.createModal.namePlaceholder')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputField
                                label={t('vouchers.createModal.discount')}
                                type="number"
                                value={data.discount}
                                onChange={handleChange}
                                name="discount"
                                placeholder={t('vouchers.createModal.discountPlaceholder')}
                                endAdornment={data.hasFixedDiscount ? currency : '%'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputField
                                label={t('vouchers.createModal.minimumBuys')}
                                type="number"
                                value={data.minimumBuys}
                                onChange={handleChange}
                                name="minimumBuys"
                                placeholder={t('vouchers.createModal.minimumBuysPlaceholder')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputField
                                label={t('vouchers.createModal.maximumDelayInDays')}
                                type="number"
                                value={data.maximumDelayInDays}
                                onChange={handleChange}
                                name="maximumDelayInDays"
                                placeholder={t('vouchers.createModal.maximumDelayInDaysPlaceholder')}
                                endAdornment={t('subscriptionModal.days')}
                            />
                        </Grid>
                    </>
                )
            default:
                return null
        }
    }, [data])

    const renderHelperText = useMemo(() => {
        switch (data.type) {
            case VOUCHER_TYPES.RECURRING:
                return t('vouchers.createModal.recurringDescription')
            case VOUCHER_TYPES.DISCOUNT:
                return t('vouchers.createModal.discountDescription')
            case VOUCHER_TYPES.CONSECUTIVE_BUYS:
                return t('vouchers.createModal.consecutiveBuysDescription')
            default:
                return ''
        }
    }, [data.type])

    return (
        <Dialog open maxWidth="sm" fullWidth>
            <Grid container pt={4}>
                <Grid item xs={12} pl={5} pr={5}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item xs={10}>
                            <Typography variant="h5" fontWeight="bold" color={'black'}>
                                {edit ? t('vouchers.createModal.editTitleText', { name: payload.name }) : t('vouchers.createModal.title')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" flexDirection="row" justifyContent={"flex-end"}>
                            <IconButton onClick={onCancel}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    pt={4}
                    pb={5}
                    xs={12}
                    borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
                >
                    <Grid container pl={5} pr={5} spacing={2}>
                        <Grid item xs={12}>
                            <Typography pb={0.5}>{t('vouchers.createModal.type')}</Typography>
                            <FormControl fullWidth>
                                <Select
                                    disabled={edit}
                                    value={data.type}
                                    onChange={handleChange}
                                    name="type"
                                >
                                    {Object.keys(VOUCHER_TYPES).map(key => <MenuItem key={key} value={VOUCHER_TYPES[key]}>{t(`vouchers.createModal.types.${key}`)}</MenuItem>)}
                                </Select>
                                <FormHelperText>
                                    {renderHelperText}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography pb={0.5}>{t('vouchers.createModal.expiresAt')}</Typography>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    value={moment(data.expiresAt)}
                                    onChange={(newValue) => setDataField('expiresAt', newValue ? newValue.format(DATE_FORMAT) : '')}
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant='outlined'
                                            error={false}
                                            fullWidth
                                            sx={{
                                                '.MuiIconButton-root': { marginRight: 1 },
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <FormHelperText>
                                {t('vouchers.createModal.expiresAtDescription')}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography pb={0.5}>{t('vouchers.createModal.fixedDiscount', { currency })}</Typography>
                            <Toggle
                                disabled={edit}
                                defaultChecked={data.hasFixedDiscount}
                                onClick={handleToggle}
                            />
                            <FormHelperText>
                                {t('vouchers.createModal.fixedDiscountDescription', { currency })}
                            </FormHelperText>
                        </Grid>

                        {/* Vouchers in Mobile Deals - available only for Discount and Custom type */}
                        {(data.type === VOUCHER_TYPES.DISCOUNT || data.type === VOUCHER_TYPES.CUSTOM) &&
                            <Grid item xs={12} minHeight={90}>

                                {/* Choose if voucher shows in deals */}
                                <Grid display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                                    <Grid>
                                        <Typography pb={0.5}>{t('vouchers.createModal.showInDeals')}</Typography>

                                        <Grid display={"flex"} flexDirection={"row"}>
                                            <Typography pr={0.5}>NO</Typography>
                                            <Toggle
                                                disabled={edit}
                                                defaultChecked={data.showInDeals}
                                                onClick={handleVoucherAmount}
                                            />
                                            <Typography pl={0.5}>YES</Typography>
                                        </Grid>
                                    </Grid>

                                    {/* How many vouchers are available for Individuals */}
                                    <Grid item xs={5.8} >
                                        {data.showInDeals && 
                                            <InputField
                                                disabled={edit}
                                                label={t('vouchers.createModal.available')}
                                                type='number'
                                                value={data.amount}
                                                onChange={handleChange}
                                                name="amount"
                                                placeholder={t('vouchers.createModal.availablePlaceholder')}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        }

                        {edit &&
                            <Grid item xs={12}>
                                <Typography pb={0.5}>{t('vouchers.createModal.status')}</Typography>
                                <FormControl fullWidth>
                                    <Select
                                        value={data.status}
                                        onChange={handleChange}
                                        name="status"
                                    >
                                        <MenuItem value={'ACTIVE'}>{t(`vouchers.ACTIVE`)}</MenuItem>
                                        <MenuItem value={'PAUSED'}>{t(`vouchers.PAUSED`)}</MenuItem>
                                        <MenuItem value={'PRIVATE'}>{t(`vouchers.PRIVATE`)}</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormHelperText>
                                    {t('vouchers.createModal.statusDescription')}
                                </FormHelperText>
                            </Grid>
                        }
                        {renderInputs}
                        <Grid item xs={12}>
                            <Typography pb={0.5}>{t('vouchers.createModal.subscriptions')}</Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {subscriptions.map((subscription) => (
                                    <Chip
                                        variant={selectedSubscriptions.includes(subscription.id) ? 'contained' : 'outlined'}
                                        color={'primary'}
                                        key={subscription.id}
                                        label={`${subscription.name} - ${subscription.priceRon} ${subscription.Company.currency}`}
                                        sx={{
                                            height: 'auto',
                                            '& .MuiChip-label': {
                                                display: 'block',
                                                whiteSpace: 'normal',
                                            },
                                            cursor: 'pointer',
                                            whiteSpace: 'normal',
                                            wordBreak: 'break-word',
                                            maxWidth: '100%',
                                            lineHeight: 1.2,
                                            padding: '6px'
                                        }}

                                        disabled={edit}
                                        onClick={() => toggleSubscription(subscription.id)}
                                    />
                                ))}
                            </Box>
                        </Grid>
                        {(data.type === VOUCHER_TYPES.CONSECUTIVE_BUYS || data.type === VOUCHER_TYPES.RECURRING) &&
                            <Grid item xs={12}>
                                <FormHelperText>
                                    {t('vouchers.warningCreateDouble')}
                                </FormHelperText>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="row" sx={{ height: 60 }}>
                        <Grid item xs={6} lg={6} sx={{ height: 60 }} display="flex" justifyContent="center" alignItems="center" borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
                            <Button style={{ height: '100%', fontWeight: 'bold' }} onClick={onCancel} fullWidth variant="text" color="error">
                                {t('vouchers.createModal.cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <Button
                                disabled={loading || (!data.name || data.discount <= 0 || !data.type || !selectedSubscriptions.length)}
                                sx={{ height: 60 }}
                                style={{ height: '100%', fontWeight: 'bold' }}
                                onClick={handleSubmit}
                                fullWidth
                                color={'primary'}
                                variant="text"
                            >
                                {t('vouchers.createModal.submit')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default CreateModal;