import React, { useState } from 'react'
import {
  Card,
  Grid,
  Typography,
  Button,
  Tooltip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
  Avatar,
  Box
} from '@mui/material'
import { Link } from 'react-router-dom'
import { PendingActionsOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import DenyModal from './DenyModal/Deny'
import ConfirmModal from './Confirm/Confirm'

import { COMPANIES_TRAINERS_REQUEST_STATUS, CARD_TEXT_COLOR } from '../../../../utils/constants'

const Request = ({
  avatarPhotoSrc,
  name,
  domain,
  status,
  id,
  address,
  onAssociationDataChange,
  onShowDisplayDeny,
  onShowConfirm
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'collaborationRequests'
  })

  const isPending = status === COMPANIES_TRAINERS_REQUEST_STATUS.PENDING
  const isDenied = status === COMPANIES_TRAINERS_REQUEST_STATUS.DENIED
  const isAccepted = status === COMPANIES_TRAINERS_REQUEST_STATUS.APPROVED

  const computeText = () => {
    if (isPending)
      return (
        <Typography
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          {' '}
          {t('isPendingDescription')}&nbsp;
          <Link to={'/' + domain} style={{ textDecoration: 'none' }}>
            <Typography
              component={'h6'}
              sx={{ textDecoration: 'underline' }}
              fontWeight="bold"
              color="primary"
            >
              {name}
            </Typography>
          </Link>
        </Typography>
      )

    return (
      <Link to={'/' + domain} style={{ textDecoration: 'none' }}>
        <Typography
          component={'h6'}
          sx={{ textDecoration: 'underline' }}
          fontWeight={'bold'}
          color="primary"
        >
          {name}
        </Typography>
      </Link>
    )
  }

  return (
    <ListItem sx={{ paddingRight: 0, paddingLeft: 0, paddingTop: 0, marginBottom: 2 }}>
      <Grid
        container
        component={Card}
        sx={{
          minHeight: 70,
          boxShadow: '0 13px 33px 0 rgba(41, 40, 56, 0.08)',
          border: '1px solid #F0F0F0',
          borderRadius: 2
        }}
        pl={1.5}
        display="flex"
        flexDirection="row"
      >
        <Grid xs={2} lg={1} item display="flex" justifyContent="center" alignItems="center">
          {!isPending ? (
            <ListItemAvatar>
              <Avatar sx={{ width: 40, height: 40 }} src={avatarPhotoSrc} />
            </ListItemAvatar>
          ) : (
            <ListItemIcon>
              <PendingActionsOutlined color="primary" fontSize="large" />
            </ListItemIcon>
          )}
        </Grid>
        <Grid item xs={10} lg={7} pb={{ xs: 1, lg: 0 }} display="flex" alignItems="center">
          <ListItemText primary={computeText()} secondary={isPending ? null : address} />
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          alignItems="center"
          flexDirection="row"
          display="flex"
          justifyContent={{ lg: 'flex-end', xs: 'flex-start' }}
        >
          {(isPending || isDenied) && (
            <Tooltip title={t('acceptDescription')} arrow>
              <Button
                onClick={() => {
                  onAssociationDataChange({ id, clubName: name })
                  onShowConfirm()
                }}
                fullWidth
                variant="contained"
                color="success"
                style={{ marginRight: 5 }}
              >
                {t('accept')}
              </Button>
            </Tooltip>
          )}
          {isPending && (
            <Tooltip title={t('deniedDescription')} arrow>
              <Button
                fullWidth
                onClick={() => {
                  onAssociationDataChange({ id, clubName: name })
                  onShowDisplayDeny()
                }}
                variant="text"
                color="error"
              >
                {t('deny')}
              </Button>
            </Tooltip>
          )}
          {isAccepted && (
            <Tooltip title={t('deniedDescription')} arrow>
              <Button
                style={{ width: '50%' }}
                variant="text"
                color="error"
                onClick={() => {
                  onAssociationDataChange({ id, clubName: name })
                  onShowDisplayDeny()
                }}
              >
                {t('unlink')}
              </Button>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </ListItem>
  )
}

const AssociationRequests = ({ data, onUpdateStatusHandler }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'collaborationRequests'
  })

  const [associationData, setAssocationData] = useState()
  const [showDenyModal, setShowDenyModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const associationRequests = data.filter((el) => {
    return el.status !== COMPANIES_TRAINERS_REQUEST_STATUS.DENIED
  })

  return (
    <>
      {showConfirmModal && (
        <ConfirmModal
          onClose={() => {
            setAssocationData(null)
            setShowConfirmModal(false)
          }}
          text={t('associationDescription', { club: associationData.clubName })}
          title={t('associationAgreement')}
          onConfirm={() => {
            onUpdateStatusHandler(associationData.id, COMPANIES_TRAINERS_REQUEST_STATUS.APPROVED)
            setAssocationData(null)
            setShowConfirmModal(false)
          }}
        />
      )}
      {showDenyModal && (
        <DenyModal
          onClose={() => {
            setAssocationData(null)
            setShowDenyModal(false)
          }}
          text={t('confirmUnlink', { club: associationData.clubName })}
          title={t('unlinkAccount')}
          onDelete={() => {
            onUpdateStatusHandler(associationData.id, COMPANIES_TRAINERS_REQUEST_STATUS.DENIED)
            setAssocationData(null)
            setShowDenyModal(false)
          }}
        />
      )}
      <Grid container>
        <Grid item xs={12} paddingBottom={0.5}>
          <Typography variant="p" fontWeight={'bold'} fontSize={20}>
            {t('title')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            lg={{ border: '1px solid #F0F0F0', borderRadius: 2, height: 500 }}
            xs={{ height: 100 }}
          >
            {!associationRequests.length ? (
              <Grid item p={3} pl={0}>
                <Typography variant="subtitle1" color={CARD_TEXT_COLOR}>
                  {t('noAssociationRequests')}
                </Typography>
              </Grid>
            ) : null}
            {associationRequests.length ? (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <List style={{ width: '100%' }}>
                  {associationRequests.map((company) => {
                    return (
                      <Request
                        key={company.id}
                        updateStatusHandler={onUpdateStatusHandler}
                        id={company.companiesTrainerId}
                        status={company.status}
                        domain={company.domain}
                        avatarPhotoSrc={company.avatarPhotoSrc}
                        name={company.name}
                        address={company.address}
                        onAssociationDataChange={(data) => setAssocationData({ ...data })}
                        onShowDisplayDeny={() => setShowDenyModal(true)}
                        onShowConfirm={() => setShowConfirmModal(true)}
                      />
                    )
                  })}
                </List>
              </Box>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default AssociationRequests
