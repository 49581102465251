import axios from './axios'

export const sendMessageToCompany = (companyId, payload) => {
  return axios.post(`/messages/business/${companyId}`, { ...payload }).then((res) => res.data)
}

export const sendMessageToUser = (userId, payload) => {
  return axios.post(`/messages/user/${userId}`, { ...payload }).then((res) => res.data)
}

export const getMessagesForCompany = (companyId) => {
  return axios.get(`/messages/business/${companyId}`).then((res) => res.data)
}

export const getMessagesForUser = (userId) => {
  return axios.get(`/messages/user/${userId}`).then((res) => res.data)
}
