import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Router from './components/Router'

import globalMui from './utils/mui/globalMui'

import ErrorProvider from './context/error-context'
import AuthenticationProvider from './context/auth-context'

import ReactGA from 'react-ga';
const TRACKING_ID = "UA-152413164-1";
ReactGA.initialize(TRACKING_ID);

const App = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={globalMui}>
          <StyledEngineProvider injectFirst>
            <AuthenticationProvider>
              <ErrorProvider>
                <Router />
                <ToastContainer />
              </ErrorProvider>
            </AuthenticationProvider>
          </StyledEngineProvider>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
