import React from 'react'
import { Grid, Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { CARD_TEXT_COLOR, GRAY_BG_COLOR, GRAY_TEXT_COLOR } from '../../../../../utils/constants'

const FavouritTrainer = ({ name, trainerAt, avatarPhotoSrc }) => {
  const { t } = useTranslation()

  return (
    <Grid display="flex" flexDirection="row">
      <Grid pr={{ xl: 2, lg: 1, xs: 2 }}>
        {avatarPhotoSrc ? (
          <img
            src={avatarPhotoSrc}
            alt="avatar"
            style={{ borderRadius: 25, objectFit: 'fill' }}
            height={50}
            width={50}
          />
        ) : (
          <Box
            sx={{
              width: 50,
              height: 50,
              backgroundColor: GRAY_BG_COLOR,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 50
            }}
          >
            <Typography variant="p" color={GRAY_TEXT_COLOR} fontWeight="bold">
              {name.charAt(0)} {name.charAt(1)}
            </Typography>
          </Box>
        )}
      </Grid>
      <Grid display="flex" flexDirection="column" justifyContent="flex-start">
        <Typography variant="p" justifyContent="flex-start" fontWeight="bold">
          {name}
        </Typography>
        <Typography
          variant="subtitle2"
          color={CARD_TEXT_COLOR}
          display="flex"
          justifyContent="flex-start"
          flexDirection="row"
        >
          {t('userProfile.activity.detailComponent.favouriteTrainer.trainerAt')}&nbsp;
          <Typography
            sx={{ textDecoration: 'underline' }}
            fontWeight={600}
            variant="subtitle2"
            color="primary"
          >
            {trainerAt ? trainerAt : ''}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default FavouritTrainer
