import { createTheme } from '@mui/material/styles'

const typographyTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Lato !important'
        }
      }
    }
  }
})

export default typographyTheme
