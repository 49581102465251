import { Grid, TextField, InputAdornment, Select, MenuItem, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';
import _ from 'lodash';

const FilterOptions = ({ onChangeUserFilter, filterUsers, getPayoutsByMonth, expandedMonth, clubSubscriptions, filterSubscriptions, onChangeFilterSubscriptions }) => {
    const { t } = useTranslation();

    const getPayoutsFilteredByUsername = useCallback(_.debounce((month, username, subscriptionId) => {
        getPayoutsByMonth(month, username, subscriptionId);
    }, 500), [getPayoutsByMonth])

    useEffect(() => {
        if (filterUsers === 'unset') return

        getPayoutsFilteredByUsername(expandedMonth, filterUsers, filterSubscriptions);

        return () => {
            getPayoutsFilteredByUsername.cancel();
        };
    }, [filterUsers])

    useEffect(() => {
        if (filterSubscriptions === 'unset') return

        getPayoutsByMonth(expandedMonth, filterUsers, filterSubscriptions)
    }, [filterSubscriptions])

    return (
        <Grid container justifyContent={'space-between'} paddingX={2} marginBottom={4}>
            <Select
                id="payouts-select-filter"
                displayEmpty
                value={filterSubscriptions !== 'unset' ? filterSubscriptions : ''}
                onChange={(event) => onChangeFilterSubscriptions(event.target.value)}
                renderValue={(selected) => {
                    if (!selected) return <>{t('clients.selectSub')}</>

                    return clubSubscriptions.find((subscription) => subscription.id === selected).name
                }}
                sx={{
                    width: '20%'
                }}
            >
                <MenuItem key={-1} value={""}>{t('helper.all')}</MenuItem>
                {clubSubscriptions.map((item, index) => (
                    <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                ))}
            </Select>
            <TextField
                id="payouts-search-input-filter"
                placeholder={t('clients.searchClient')}
                sx={{ width: '50%' }}
                variant="outlined"
                value={filterUsers === 'unset' ? '' : filterUsers}
                onChange={(event) => onChangeUserFilter(event.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton onClick={() => onChangeUserFilter('')} >
                                <CloseIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

        </Grid>
    );
};

export default FilterOptions;