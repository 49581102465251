import React, { useState, useEffect } from 'react'
import { Container, Grid, Card, Typography, Button, CircularProgress } from '@mui/material'
import { Add } from '@mui/icons-material'

import { ECreditsNavbar } from '../../common'
import AssociationModal from './AssociationModal'
import Requests from './Requests'

import { GRAY_TEXT_COLOR } from '../../../utils/constants'
import * as ECREDITS_ASSOCIATION_REQUESTS from '../../../api/ecreditsAssociationRequests'

export default () => {
    const [showAssociationModal, setShowAssociationModal] = useState(false)
    const [associationRequests, setAssociationRequests] = useState([])
    const [loading, setLoading] = useState(true)

    const getRequestsHandler = () => {
        return ECREDITS_ASSOCIATION_REQUESTS.getRequests()
            .then(response => {
                setAssociationRequests(response.rows)
            })
    }

    useEffect(() => {
        getRequestsHandler()
            .then(() => {
                setLoading(false)
            })
    }, [])

    return (
        <>
            {showAssociationModal ? <AssociationModal
                onCancel={() => setShowAssociationModal(false)}
                onConfirm={() => getRequestsHandler()}
            /> : null}
            <ECreditsNavbar title="Associeri" />
            <Container maxWidth='xl'>
                <Grid container mt={5} spacing={2} component={Card}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={8}>
                                <Typography fontWeight="bold" variant="h6">
                                    Associations
                                </Typography>
                                <Typography variant="span" color={GRAY_TEXT_COLOR}>Manage your association requests. Here you can invite, cancel or pause association requests for your employees.</Typography>
                            </Grid>
                            <Grid item xs={4} display="flex" flexDirection="row" justifyContent="flex-end" pr={2}>
                                <Button variant="outlined" sx={{ marginLeft: 'auto', maxHeight: 45 }} onClick={() => setShowAssociationModal(true)}><Add mr={1} />User</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" pb={2} pr={2}>
                        {loading ? <CircularProgress /> : <Requests data={associationRequests} />}
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}