import React, { useState, useEffect } from 'react'
import { Container, Grid, Typography, Button, MenuItem, Menu, FormControl, Select, Chip, ButtonGroup, TextField, IconButton, Box, Divider, ListItemIcon, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Add, EventRepeat, StackedLineChart, Close, Check, ArrowBack, Percent, MoreVert, Delete } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { Table, Confirm } from '../common'
import AssignVoucher from './Assign'

import { GRAY_TEXT_COLOR, DATE_TIME_FORMAT, VOUCHER_TYPES } from '../../utils/constants'
import * as VOUCHER_REQUESTS from '../../api/voucher'
import * as COMPANY_REQUESTS from '../../api/company'

export default () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [vouchers, setVouchers] = useState([])
    const [anchorElement, setAnchorElement] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showAssignModal, setShowAssignModal] = useState(false)
    const [selectedVoucher, setSelectedVoucher] = useState()
    const [currency, setCurrency] = useState('RON')
    const [filters, setFilters] = useState({
        search: '',
        type: '',
        createdAtStart: moment().startOf('month'),
        createdAtEnd: moment().endOf('month')
    })

    const getAllUsersVouchers = () => {
        const nonEmptyFilters = {}

        Object.keys(filters).forEach(key => {
            if (filters[key]) {
                if (key === 'createdAtStart' || key === 'createdAtEnd') {
                    nonEmptyFilters[key] = moment(filters[key]).format('YYYY-MM-DD')
                } else {
                    nonEmptyFilters[key] = filters[key]
                }
            }
        })

        return VOUCHER_REQUESTS.getAllUsers(nonEmptyFilters)
            .then(response => setVouchers(response))
    }

    const deleteVoucher = id => {
        return VOUCHER_REQUESTS.removeUserVoucher(id)
            .then(() => {
                return getAllUsersVouchers()
            })
            .then(() => {
                toast.success(t('vouchers.successDelete'), { position: 'bottom-right' })
            })
    }

    const getCompanyCurrency = async () => {
        const currency = await COMPANY_REQUESTS.getCompanyCurrency();

        setCurrency(currency.currency)
    }

    useEffect(() => {
        getCompanyCurrency()
    }, [])

    useEffect(() => {
        getAllUsersVouchers()
    }, [filters])


    const renderUsed = (isUsed, userVoucher) => {
        return <Chip
            variant={isUsed ? 'outlined' : 'contained'}
            color={'primary'}
            label={isUsed ? moment(userVoucher.updatedAt).format(DATE_TIME_FORMAT) : t('userVouchers.wasNotUsed')}
            onDelete={() => { }}
            deleteIcon={isUsed ? <Close /> : <Check />}
        />
    }

    const renderType = type => {
        return <Chip
            label={t(`vouchers.createModal.types.${type}`)}
            variant="outlined"
            avatar={type === VOUCHER_TYPES.CONSECUTIVE_BUYS ? <StackedLineChart /> : type === VOUCHER_TYPES.DISCOUNT ? <Percent /> : <EventRepeat />}
        />
    }

    const renderMenuItem = (voucher) => {
        return <Box>
            <IconButton onClick={event => {
                setAnchorElement(event.currentTarget);
                setSelectedVoucher(voucher)
            }}>
                <MoreVert />
            </IconButton>
            {anchorElement ? <Menu
                anchorEl={anchorElement}
                open={selectedVoucher && selectedVoucher.id === voucher.id}
                onClose={() => {
                    setAnchorElement(null)
                    setSelectedVoucher()
                }}
            >
                <MenuItem disabled>
                    {t('clients.tableHead.actions')}
                </MenuItem>
                <Divider />
                <MenuItem style={{ minWidth: 200 }} onClick={() => {
                    setShowDeleteModal(true)
                    setAnchorElement(null)
                }}>
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    <ListItemText>
                        {t('button.remove')}
                    </ListItemText>
                </MenuItem>
            </Menu> : null}
        </Box>
    }

    return (
        <>
            {showAssignModal ? <AssignVoucher
                onCancel={() => setShowAssignModal(false)}
                onConfirm={() => {
                    return getAllUsersVouchers().finally(() => {
                        setShowAssignModal(false)
                    })
                }}
            /> : null}
            {(showDeleteModal && selectedVoucher) ? <Confirm
                isMissingInfo
                noTitle
                iconColor="error"
                Icon={Delete}
                cancelText={t('button.cancel')}
                confirmText={t('button.remove')}
                titleColor='error'
                titleText={t('button.remove')}
                description={t('vouchers.deleteUserVoucher', { voucher: selectedVoucher.Voucher.name, userName: `${selectedVoucher.User.firstName} ${selectedVoucher.User.lastName}` })}
                onCancel={() => setShowDeleteModal(false)}
                onConfirm={() => {
                    return deleteVoucher(selectedVoucher.id)
                        .finally(() => {
                            setShowDeleteModal(false)
                            setSelectedVoucher()
                        })
                }}
            /> : null}
            <Container maxWidth="xl">
                <Grid container spacing={2} pt={8}>
                    <Grid item xs={12} pb={2} display="flex" flexDirection="row">
                        <Grid item xs={12} lg={6}>
                            <Typography variant="h5" fontWeight="bold">
                                {t('navbar.voucherUsers')}
                            </Typography>
                            <Typography variant="p" color={GRAY_TEXT_COLOR} fontWeight={500}>
                                {t('userVouchers.description')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6} display="flex" flexDirection="row" justifyContent="flex-end">
                            <ButtonGroup disableElevation variant="contained" sx={{ maxHeight: 45 }}>
                                <Button variant="contained" onClick={() => setShowAssignModal(true)} >
                                    <Add />  {t('userVouchers.assign')}
                                </Button>
                                <Button variant="outlined" onClick={() => navigate('/vouchers')}>
                                    {t('userVouchers.gotoVouchers')} <ArrowBack />
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} pt={2} pb={2}>
                        <Grid container display="flex" flexDirection="row" spacing={2}>
                            <Grid item lg={3} xs={6} display="flex" flexDirection="column">
                                <Typography variant='p' gutterBottom>
                                    {t('userVouchers.search')}
                                </Typography>
                                <TextField
                                    value={filters.search}
                                    onChange={e => setFilters(prevFilters => ({ ...prevFilters, search: e.target.value }))}
                                    placeholder='Popescu Andreea'
                                />
                            </Grid>

                            <Grid item lg={3} xs={6} display="flex" flexDirection="column">
                                <Typography variant='p' gutterBottom>
                                    {t('userVouchers.allRows.voucherType')}
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        value={filters.type}
                                        onChange={e => setFilters(prevValue => ({ ...prevValue, type: e.target.value }))}
                                    >
                                        <MenuItem value="">
                                            <em>{t('vouchers.createModal.allTypes')}</em>
                                        </MenuItem>
                                        {Object.keys(VOUCHER_TYPES).map(key => {
                                            return <MenuItem value={VOUCHER_TYPES[key]} key={key}>
                                                {t(`vouchers.createModal.types.${VOUCHER_TYPES[key]}`)}
                                            </MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item lg={3} xs={6} display="flex" flexDirection="column">
                                <Typography variant='p' gutterBottom>
                                    {t('userVouchers.from')}
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        inputFormat="MM/dd/yyyy"
                                        value={moment(filters.createdAtStart)}
                                        onChange={(newValue) => setFilters(prevValue => ({ ...prevValue, createdAtStart: newValue }))}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                sx={{
                                                    '.MuiIconButton-root': { marginRight: 1 }
                                                }}
                                                {...params}
                                                error={false}
                                            />
                                        )} />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item lg={3} xs={6} display="flex" flexDirection="column">
                                <Typography variant='p' gutterBottom>
                                    {t('userVouchers.until')}
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        inputFormat="MM/dd/yyyy"
                                        value={moment(filters.createdAtEnd)}
                                        onChange={(newValue) => setFilters(prevValue => ({ ...prevValue, createdAtEnd: newValue }))}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                sx={{
                                                    '.MuiIconButton-root': { marginRight: 1 }
                                                }}
                                                {...params}
                                                error={false}
                                            />
                                        )} />
                                </LocalizationProvider>
                            </Grid>


                        </Grid>
                    </Grid>
                    <Grid item xs={12} pb={2}>
                        {vouchers.length ? <Table
                            dense
                            headCells={[
                                'user',
                                'voucherName',
                                'subscriptionName',
                                'voucherType',
                                'discount',
                                'used',
                                'availableFrom',
                                'actions'
                            ].map((key) => t(`userVouchers.allRows.${key}`))}
                            rows={vouchers.filter(el => el.Voucher).map(el => {
                                return {
                                    user: <Typography fontWeight="bold" color="primary">{el.User.firstName} {el.User.lastName}</Typography>,
                                    voucherName: <Typography fontWeight="bold">{el.Voucher ? el.Voucher.name : '-'}</Typography>,
                                    subscriptionName: <Typography fontWeight="bold">{el.Voucher && el.Voucher.Subscription ? el.Voucher.Subscription.name : '-'}</Typography>,
                                    type: renderType(el.Voucher ? el.Voucher.type : null),
                                    discount: <Typography fontWeight="bold" color="primary">{el.Voucher.discount} {el.Voucher.hasFixedDiscount ? currency : '%'}</Typography>,
                                    used: renderUsed(el.used, el),
                                    availableFrom: <Typography>{moment(el.createdAt).format(DATE_TIME_FORMAT)}</Typography>,
                                    actions: renderMenuItem(el)
                                }
                            })}
                        /> : <Typography variant='p' fontWeight={500} color={GRAY_TEXT_COLOR}>{t('vouchers.noVouchers')}</Typography>}
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}