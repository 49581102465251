import axios from "./axios"

export const getOrganization = (id) => {
    return axios.get('/organizations/' + id).then((res) => res.data)
}
export const updateOrganization = (id, payload) => {
    return axios.patch('/organizations/' + id, payload).then((res) => res.data)
}
export const openStripeBillingPortal = async () => {
    const billingPortal = await axios.get('/organizations/stripe/billing-portal').then(res => res.data);

    return billingPortal
}
export const addDeal = (domain, payload) => {
    return axios.post(`/organizations/deals/${domain}`, payload).then((res) => res.data)
}

export const deleteDeal = (dealId) => {
    return axios.delete(`/organizations/deals/${dealId}`).then((res) => res.data)
}

export const updateDeal = (dealId, payload) => {
    return axios.patch(`/organizations/deals/${dealId}`, payload).then((res) => res.data)
}

export const getDeal = (dealId) => {
    return axios.get(`/organizations/deals/${dealId}`).then((res) => res.data)
}

export const getDeals = (organizationId) => {
    return axios.get(`/organizations/deals/allOrganization/${organizationId}`).then((res) => res.data)
}