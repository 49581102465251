import React, { useCallback, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Box, Button, TextField, InputAdornment, FormGroup, FormControlLabel, IconButton, Select, MenuItem, Checkbox, Typography, ButtonGroup, Popper, Paper, ClickAwayListener, MenuList, Grow } from '@mui/material'
import { ArrowDropDown, KeyboardArrowLeft, KeyboardArrowRight, Search } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


import { BLACK_BACKGROUND_COLOR, GRAY_BORDER_COLOR, ROLE_USER, WHITE_COLOR } from '../../../utils/constants'
import { useAuthentication } from '../../../context/auth-context'

import * as USER_REQUESTS from '../../../api/user'

const CalendarHeader = ({
  handleCustomDate,
  handleOpenCreateEvent,
  handleNextPeriod,
  handlePrevPeriod,
  customDate,
  handleOnSearch,
  handleOnlyMine,
  handleShowPastEvents,
  handleShowListView,
  showListView,
  showPastEvents,
  handleGetEvents,
  onlyMine,
  handleShowEmptyServices,
  showEmptyServices,
  handleSetSelectedFavourites,
  selectedFavorites
}) => {
  const { t } = useTranslation()
  const { user } = useAuthentication()
  const { domain } = useParams()
  const theme = useTheme()

  const isUser = user && user.role && user.role.toLowerCase() === ROLE_USER.toLowerCase()

  const [followTrainers, setFollowTrainers] = useState([])
  const [followGyms, setFollowGyms] = useState([])

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const matchesPhone = useMediaQuery(theme.breakpoints.down(isUser && !domain ? 'lg' : 'sm'))


  useEffect(() => {
    if (!user) return
    USER_REQUESTS.getFollowers({ userId: user.id }).then((res) => {
      setFollowTrainers(
        res
          .filter((el) => el.FollowerUser)
          .map((el) => ({
            label: `${el.FollowerUser.firstName} ${el.FollowerUser.lastName}`,
            value: `${el.FollowerUser.id}-trainer`,
            type: 'trainer'
          }))
      )

      setFollowGyms(
        res
          .filter((el) => el.FollowerCompany)
          .map((el) => ({
            label: el.FollowerCompany.name,
            value: `${el.FollowerCompany.id}-company`,
            type: 'company'
          }))
      )
    })
  }, [user])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const computeRenderValue = useCallback((selectedValues, label) => {
    return !selectedValues.length ? <Typography variant="body2" color={"#9CB2CD"} pt={0.2}>{t('calendar.selectFav')}</Typography> : <Typography variant="body2" pt={0.2}>{`${selectedValues.length} ${label} selected`}</Typography>
  }, [])

  return (
    <Grid container display="flex" flexDirection={{ lg: 'row', md: 'row', sm: 'column', xs: 'column' }} pl={{ lg: 2, xs: 0 }} pr={{ lg: 2, xs: 0 }}>
      <Grid item pb={{ lg: 0, xs: 0.5 }} lg={isUser && !domain ? 3 : 3} xl={isUser && !domain ? 3 : 4} md={12} sm={12} xs={12} display="flex" flexDirection="row" alignItems="center">
        <TextField
          sx={{ marginLeft: matchesPhone ? 0 : 2 }}
          fullWidth={{ lg: false, md: false, sm: true }}
          onChange={event => handleOnSearch(event.target.value)}
          placeholder={t('calendar.searchForEvents')}
          InputProps={{
            style: {
              height: 37
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <Search style={{ color: '#C6C6C6' }} />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item lg={isUser && !domain ? 7.5 : 7.5} xl={isUser && !domain ? 7.5 : 6.5} md={12} sm={12} xs={12} pt={0} pb={{ lg: 0, md: 0, sm: 3, xs: 3 }} pl={isUser && !domain ? 0 : { xs: 0, sm: 2, md: 2, lg: 2 }}>
        <Grid container display="flex" flexDirection="row" justifyContent={{ lg: 'flex-end', md: isUser && !domain ? 'start' : 'flex-end', xs: 'start' }} alignItems="center">
          <Box display="flex" flexDirection="row" width="auto" pb={!(!domain && !isUser) && matchesPhone ? 1 : 0}>
            {isUser && !domain ? <FormGroup>
              <FormControlLabel control={<Checkbox onChange={handleOnlyMine} checked={onlyMine} />} label={t('calendar.myEvents')} />
            </FormGroup> : null}
            {!domain && !isUser ? <FormGroup>
              <FormControlLabel control={<Checkbox onChange={handleShowListView} checked={showListView} />} label={t('calendar.listView')} />
            </FormGroup> : null}
            {!domain && !isUser ? <FormGroup>
              <FormControlLabel control={<Checkbox onChange={handleShowPastEvents} checked={showPastEvents} />} label={t('calendar.pastEvents')} />
            </FormGroup> : null}
            {!domain && !isUser && showListView ? <FormGroup>
              <FormControlLabel control={<Checkbox onChange={handleShowEmptyServices} checked={showEmptyServices} />} label={t('calendar.emptyServices')} />
            </FormGroup> : null}
          </Box>
          <Box width={matchesPhone && !(!domain && !isUser) ? '100%' : "auto"} pr={isUser && !domain ? 1 : 0}>
            {isUser && !domain ? <Select
              labelId="select-label"
              id="select-id"
              displayEmpty
              multiple={true}
              onChange={(event) => {
                handleSetSelectedFavourites(event.target.value)
                handleGetEvents(event.target.value.map(el => ({
                  id: el,
                  type: followGyms.concat(followTrainers).find(e => e.value === el).type
                })))
              }}
              value={selectedFavorites}
              defaultValue={selectedFavorites}
              renderValue={(selectedValues) => computeRenderValue(selectedValues, 'Favorites')}
              sx={{
                height: 50,
                width: matchesPhone ? '100%' : 200
              }}
              label={null}
            >
              {followGyms.concat(followTrainers).map((el, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={el.value}
                    style={{ backgroundColor: 'white', color: 'black' }}
                  >
                    <Checkbox checked={selectedFavorites.includes(el.value)} />
                    {el.label}
                  </MenuItem>
                )
              })}
            </Select> : null}
          </Box>
          <Box ml={{ lg: 0, md: 0, sm: 'auto', xs: !(!domain && !isUser) ? 0 : 'auto' }} pt={matchesPhone && !(!domain && !isUser) ? 2 : 0} display="flex" flexDirection="row" width={{ xs: '100%', sm: '100%', md: isUser && !domain ? '100%' : 'min-content', lg: 'min-content' }}>
            <Box>
              <Button
                variant="outlined"
                onClick={handlePrevPeriod}
                sx={{
                  backgroundColor: '#F5F9FD',
                  borderColor: GRAY_BORDER_COLOR,
                  borderWidth: 1,
                  marginRight: 1,
                  minWidth: 35,
                  width: 35,
                  '&:hover': { borderWidth: 1, borderColor: GRAY_BORDER_COLOR }
                }}
              >
                <KeyboardArrowLeft sx={{ color: GRAY_BORDER_COLOR }} />
              </Button>
            </Box>
            <Box sx={{ width: '100%' }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker slotProps={{ actionBar: { actions: ['today'] }, }} sx={{
                  width: '100%', minWidth: 200
                }} value={customDate} onChange={handleCustomDate} />
              </LocalizationProvider>
            </Box>
            <Box pr={{ xs: 0, sm: 0, md: 1, lg: 1 }}>
              <Button
                onClick={handleNextPeriod}
                variant="outlined"
                sx={{
                  backgroundColor: '#F5F9FD',
                  borderColor: GRAY_BORDER_COLOR,
                  borderWidth: 1,
                  marginLeft: 1,
                  minWidth: 35,
                  width: 35,
                  '&:hover': { borderWidth: 1, borderColor: GRAY_BORDER_COLOR }
                }}
              >
                <KeyboardArrowRight sx={{ color: GRAY_BORDER_COLOR }} />
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {
        !domain && !isUser ? <Grid item lg={1.5} xl={1.5} md={12} sm={12} xs={12} pl={{ lg: 0, md: 0, sm: 2 }} justifyContent={{ lg: 'flex-end', xs: 'start' }} sx={{ width: '100%' }}>
          <React.Fragment>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
              <Button onClick={() => handleOpenCreateEvent('event')}>+ Event</Button>
              <Button
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDown />
              </Button>
            </ButtonGroup>
            <Popper
              sx={{
                zIndex: 1,
              }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper sx={{ padding: 0, margin: 0 }}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList sx={{ padding: 0, margin: 0 }} id="split-button-menu" autoFocusItem>
                        <MenuItem
                          key={'services'}
                          onClick={() => handleOpenCreateEvent('services')}
                        >
                          <Button fullWidth variant='contained'>
                            + Services
                          </Button>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </React.Fragment>
        </Grid> : <Grid item lg={1.5} md={1.5} sm={12} xs={12} justifyContent={{ lg: 'flex-end', xs: 'start' }} sx={{ width: '100%' }} />
      }
    </Grid >
  )
}

export default CalendarHeader
