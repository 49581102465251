import { Avatar, Box, Checkbox, Grid, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import React, { useState, useMemo, useEffect } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { ERROR_COLOR, GREEN_COLOR, ORANGE_COLOR, PRIMARY_COLOR, RED_CHIP_COLOR, WHITE_COLOR } from '../../../utils/constants';
import { ArrowDropDown, ArrowRight, CreditCard, Money, CardMembership, CheckBox } from '@mui/icons-material';
import { LIGHT_GRAY_CHART } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';

import * as CALENDAR_REQUESTS from '../../../api/calendar'
import { toast } from 'react-toastify';
import { getStatus } from '../../../utils/helpers/userSubscription';

const CalendarDaysList = ({ days, userSubscriptions, showEmptyServices, handleOnDayChanged, handleEventDetails }) => {

    const navigate = useNavigate()

    const [expanded, setExpanded] = useState([])
    const [day, setDay] = useState(moment())
    const [checkins, setCheckins] = useState()

    useEffect(() => {
        if (days && days.length > 0) {
            setExpanded(days.map(el => el.dayItems.map(it => ({
                id: `${it.id}-${el.dayDate}`,
                type: it.type
            }))).flat(1))
        }
    }, [days])

    useEffect(() => {
        handleGetCheckins()
    }, [])

    const isExpanded = (item, dayDate) => {
        const expandedElementsWithItemId = expanded.filter(el => `${el.id}` === `${item.id}-${dayDate}`)
        if (!expandedElementsWithItemId.length) return false

        if (expandedElementsWithItemId.length > 0) return expandedElementsWithItemId.map(el => el.type).includes(item.type)
    }

    const handleGetCheckins = async () => {
        try {
            const events = days.map(({ dayItems }) => dayItems.filter(el => el.type === 'event').map(it => it.id)).join(',')
            const checkinsResponse = await CALENDAR_REQUESTS.getCheckinsForEvents(events)
            setCheckins(checkinsResponse)
        }
        catch (e) {
            console.error(e)
            toast.error("Unable to load check-ins!", { position: 'bottom-right' })
        }
    }

    const handleConfirmPresence = async (eventId, selectedUser) => {
        try {
            await CALENDAR_REQUESTS.confirmPresenceToEvent(eventId, selectedUser)
            handleGetCheckins()
            toast.success(`Successfully confirmed presence!`, { position: 'bottom-right' })
        } catch (e) {
            toast.error("Unable to confirm presence!", { position: 'bottom-right' })
            console.error(e)
        }
    }

    const renderCalendar = useMemo(() => {
        return (
            <Grid container display="flex" flexDirection="column" justifyContent="center" boxShadow={" rgba(0, 0, 0, 0.04) 0px 3px 5px;"}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateCalendar value={day} onChange={(newValue) => {
                        setDay(newValue)
                        handleOnDayChanged(newValue)
                    }} />
                </LocalizationProvider>
            </Grid>
        )
    }, [day])

    const renderEvent = (event, dayDate) => {
        if (!isExpanded(event, dayDate)) return null
        return (
            <Box display="flex" flexDirection="row">
                {event.participants.length === 0 && !showEmptyServices ? null : <Box flex={1} display="flex" flexDirection="row" alignItems="flex-start">
                    <Box mt={0.6} height={10} width={10} bgcolor={ORANGE_COLOR} borderRadius={'50%'} />
                    <Typography pl={1} variant="caption">{event.start} - {moment().set('hour', event.start.split(':')[0]).set('minutes', event.start.split(':')[1]).add(event.duration, 'minutes').format('HH:mm')}</Typography>
                </Box>}
                <Box flex={2} display="flex" flexDirection="column">
                    {event.participants.length > 0 ? event.participants.map(participant => {
                        const userSubscriptionPaid = participant.bookWithUserSubscriptionId ? getStatus(participant.bookWithUserSubscription).paid : false
                        return (
                            <Box display="flex" flexDirection="row" alignItems="center" pb={.4}>
                                <Box flex={1} display="flex" flexDirection="row" alignItems={"center"}>
                                    <Avatar sx={{ height: 35, width: 35, borderRadius: 1, border: `2px solid ${checkins[event.id] ? checkins[event.id].map(checkin => checkin.userId).includes(participant.Participant.id) ? GREEN_COLOR : ERROR_COLOR : ERROR_COLOR}` }} src={participant.Participant.avatarPhotoSrc}>
                                        <Typography variant="caption" fontWeight="bold">{participant.Participant.firstName.charAt(0)}{participant.Participant.lastName.charAt(0)}</Typography>
                                    </Avatar>
                                    <Box pl={1} display="flex" flexDirection="column">
                                        <Typography variant="caption" fontWeight={500}>{participant.Participant.firstName} {participant.Participant.lastName}</Typography>
                                        <Typography variant="caption">{participant.Participant.phoneNumber}</Typography>
                                    </Box>
                                </Box>
                                {event.EventSubscriptions.length > 0 ? <Tooltip placement='top' title={<Typography fontWeight={"bold"} variant="caption" fontSize={10}>{`Event booked with Subscription Payment`}</Typography>}>
                                    <Box flex={1} display="flex" flexDirection="row" justifyContent="flex-end">
                                        <Box pr={.25} pt={0.2}><CardMembership sx={{ color: event.EventSubscriptions.length > 0 && userSubscriptionPaid ? GREEN_COLOR : ERROR_COLOR, fontSize: 22 }} /></Box>
                                    </Box>
                                </Tooltip> : null}
                                <Tooltip title={`Click here in order to confirm presence for ${participant.Participant.firstName} ${participant.Participant.lastName}`}>
                                    <Checkbox
                                        onClick={() => handleConfirmPresence(event.id, participant.Participant.id)}
                                        style={{ marginLeft: 2, marginBottom: 2 }}
                                        disabled={checkins[event.id] ? checkins[event.id].map(checkin => checkin.userId).includes(participant.Participant.id) : false}
                                        checked={checkins[event.id] ? checkins[event.id].map(checkin => checkin.userId).includes(participant.Participant.id) : false}
                                    />
                                </Tooltip>
                            </Box>
                        )
                    }) : !showEmptyServices ? null : <Box display="flex" flexDirection="row" alignItems="center" pb={1.5}>
                        <Box flex={1} display="flex" flexDirection="row" alignItems={"center"}>
                            <Typography variant="caption" fontWeight="bold">No participants</Typography>
                        </Box>
                        <Box flex={1} display="flex" flexDirection="row" justifyContent="flex-end" />
                    </Box>}
                </Box>
            </Box >
        )
    }

    const renderService = (service, dayDate) => {
        if (!isExpanded(service, dayDate)) return null
        return (
            <Box display="flex" flexDirection="column">
                {(service.slots || []).filter(el => showEmptyServices ? true : (el.participants.length > 0 || el.waitingList.length > 0)).map(spot => (
                    <Box display="flex" flexDirection="row" pb={2}>
                        <Tooltip placement='top' title={<Typography fontWeight={"bold"} variant="caption" fontSize={10}>{`Click in order to manage service details between ${spot.start} - ${spot.end}`}</Typography>}>
                            <Box sx={{ cursor: 'pointer' }} onClick={() => navigate(`services/${service.id}/edit?selectedSlot=${spot.index}&day=${moment(dayDate).format('YYYY/MM/DD')}`)} flex={1} display="flex" flexDirection="row" alignItems="flex-start">
                                <Box mt={0.6} height={10} width={10} bgcolor={ORANGE_COLOR} borderRadius={'50%'} />
                                <Typography pl={1} variant="caption">{spot.start} - {spot.end}</Typography>
                            </Box>
                        </Tooltip>
                        <Box flex={2} display="flex" flexDirection="column">
                            {spot.participants.length > 0 ? spot.participants.map(participant => {
                                const relatedUserSubscription = participant.CS_Participants.bookWithUserSubscriptionId ? service.userSubscriptions.find(it => it.id === participant.CS_Participants.bookWithUserSubscriptionId) : null
                                const userSubscriptionPaid = participant.CS_Participants.bookWithUserSubscriptionId && relatedUserSubscription ? getStatus(relatedUserSubscription).paid : false
                                return (
                                    <Box display="flex" flexDirection="row" alignItems="center" pb={.4}>
                                        <Box flex={1} display="flex" flexDirection="row" alignItems={"center"}>
                                            <Avatar sx={{ height: 35, width: 35, borderRadius: 1, border: `2px solid ${participant.confirmed ? GREEN_COLOR : ERROR_COLOR}` }} src={participant.avatarPhotoSrc}>
                                                <Typography variant="caption" fontWeight="bold">{participant.firstName.charAt(0)}{participant.lastName.charAt(0)}</Typography>
                                            </Avatar>
                                            <Box pl={1} display="flex" flexDirection="column">
                                                <Typography variant="caption" fontWeight={500}>{participant.firstName} {participant.lastName}</Typography>
                                                <Typography variant="caption">0726574626</Typography>
                                            </Box>
                                        </Box>
                                        <Box flex={1} display="flex" flexDirection="row" justifyContent="flex-end">
                                            <Tooltip placement='top' title={<Typography fontWeight={"bold"} variant="caption" fontSize={10}>{`Service booked with Online Payment`}</Typography>}>
                                                <Box pr={.25}><CreditCard sx={{ color: participant.CS_Participants.paymentMethod === 'ONLINE' ? GREEN_COLOR : LIGHT_GRAY_CHART }} /></Box>
                                            </Tooltip>
                                            <Tooltip placement='top' title={<Typography fontWeight={"bold"} variant="caption" fontSize={10}>{`Service booked with Cash Payment`}</Typography>}>
                                                <Box pr={.25} pl={.25}><Money sx={{ color: participant.CS_Participants.paymentMethod === 'CASH' ? GREEN_COLOR : LIGHT_GRAY_CHART }} /></Box>
                                            </Tooltip>
                                            <Tooltip placement='top' title={<Typography fontWeight={"bold"} variant="caption" fontSize={10}>{`Service booked with Subscription Payment`}</Typography>}>
                                                <Box pr={.25} pl={.25} pt={.2}><CardMembership sx={{ color: userSubscriptionPaid ? GREEN_COLOR : ERROR_COLOR, fontSize: 22 }} /></Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                )
                            }) :
                                <Box display="flex" flexDirection="row" alignItems="center" pb={1.5}>
                                    <Box flex={1} display="flex" flexDirection="row" alignItems={"center"}>
                                        <Typography variant="caption" fontWeight="bold">No participants</Typography>
                                    </Box>
                                    <Box flex={1} display="flex" flexDirection="row" justifyContent="flex-end" />
                                </Box>
                            }
                        </Box>
                    </Box>
                ))
                }
            </Box >
        )
    }

    const handleCollapse = (item, dayDate) => {
        setExpanded(prevValue => {
            let prevValueCopy = [...prevValue]
            const itemPosition = prevValueCopy.findIndex(el => `${el.id}` === `${item.id}-${dayDate}` && el.type === item.type)
            prevValueCopy.splice(itemPosition, 1)
            return prevValueCopy
        })
    }

    const handleExpand = (item, dayDate) => {
        setExpanded(prevValue => {
            let prevValueCopy = [...prevValue]
            prevValueCopy = prevValueCopy.concat({
                type: item.type,
                id: `${item.id}-${dayDate}`
            })
            return prevValueCopy
        })
    }

    const renderDayItem = (item, dayDate) => {
        return (
            <Box display="flex" flexDirection="column" pb={2}>
                <Box pb={2} display="flex" flexDirection="row" alignItems="center">
                    {isExpanded(item, dayDate) ? <ArrowDropDown onClick={() => handleCollapse(item, dayDate)} sx={{ cursor: 'pointer' }} /> : <ArrowRight onClick={() => handleExpand(item, dayDate)} sx={{ cursor: 'pointer' }} />}
                    <Typography onClick={() => item.type === 'event' ? handleEventDetails(item) : navigate(`services/${item.id}/edit?day=${moment(dayDate).format('YYYY/MM/DD')}`)} sx={{ cursor: 'pointer' }} variant="body2" fontWeight={"bold"}>{item.name || item.title}</Typography>
                </Box>
                <Box display="flex" flexDirection="column" pl={.7}>
                    {item.type === 'event' ? renderEvent(item, dayDate) : renderService(item, dayDate)}
                </Box>
            </Box>
        )
    }

    const renderDay = (day) => {
        return (
            <Grid container display="flex" flexDirection={{ lg: 'row', xs: 'column' }} pb={2}>
                <Grid item xs={12} lg={3} pb={{ lg: 0, xs: 2 }}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" sx={{ backgroundColor: PRIMARY_COLOR }} p={0.5} width={30} height={30} borderRadius={'50%'}>
                            <Typography color={WHITE_COLOR} fontWeight={"bold"}>{moment(day.dayDate).date()}</Typography>
                        </Box>
                        <Box pl={2}>
                            <Typography variant="subtitle1" fontWeight={500}>{day.dayName}</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} lg={9} pt={1} pl={{ xs: 1, lg: 0 }}>
                    <Box display="flex" flexDirection="column">
                        {day.dayItems.map(item => {
                            return (
                                renderDayItem(item, day.dayDate)
                            )
                        })}
                    </Box>
                </Grid>
            </Grid>
        )
    }

    if (!checkins) return null

    return (
        <Grid item xs={12} pl={{ lg: 8, xs: 3 }} pr={{ lg: 8, xs: 3 }} pt={3}>
            <Grid container display="flex" flexDirection="row">
                <Grid item xs={12} lg={4} pr={{ lg: 2, xs: 0 }}>
                    {renderCalendar}
                </Grid>
                <Grid item xs={12} lg={8} pl={{ lg: 4, xs: 0 }} pt={{ lg: 0, xs: 4 }}>
                    {days.map(day => {
                        return renderDay(day)
                    })}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CalendarDaysList