import { TextField, Box, Typography, Autocomplete } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GRAY_BG_COLOR, GRAY_TEXT_COLOR, SECONDARY_TEXT_COLOR } from '../../../utils/constants'

import * as USER_REQUESTS from '../../../api/user'

const SelectUser = ({ handleSelectUser }) => {

    const [users, setUsers] = useState([])

    const { t } = useTranslation()

    useEffect(() => {
        handleGetUsers()
    }, [])

    const handleGetUsers = async () => {
        const usersToSet = await USER_REQUESTS.getUsersWithYourSubscriptions()
        setUsers(usersToSet)
    }

    return (
        <Box width="100%" display="flex" flexDirection="column">
            <Box pb={2}>
                <Typography variant="subtitle1">{t('helper.selectClient')}</Typography>
            </Box>
            <Box>
                <Autocomplete
                    options={users
                        .map((el) => ({
                            label: `${el.firstName} ${el.lastName}`,
                            avatar: el.avatarPhotoSrc,
                            phoneNumber: el.phoneNumber,
                            value: el.id,
                            firstName: el.firstName,
                            lastName: el.lastName
                        }))}
                    autoHighlight
                    placeholder="Search for employee"
                    renderOption={(props, option) => {
                        return (
                            <Box {...props} sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {option.avatar ? (
                                        <img
                                            style={{ borderRadius: 25 }}
                                            width={50}
                                            height={50}
                                            src={option.avatar}
                                        />
                                    ) : (
                                        <Box
                                            sx={{
                                                width: 50,
                                                height: 50,
                                                backgroundColor: GRAY_BG_COLOR,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: 25
                                            }}
                                        >
                                            <Typography fontWeight="bold" color={GRAY_TEXT_COLOR}>
                                                {option.firstName.charAt(0)} {option.lastName.charAt(0)}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 1 }}>
                                    <Typography fontWeight={500}>{option.label}</Typography>
                                    <Typography color={GRAY_TEXT_COLOR}>{option.phoneNumber}</Typography>
                                </Box>
                            </Box>
                        )
                    }}
                    onChange={(_, newValue) => {
                        if (!newValue) return

                        return handleSelectUser(users.find(el => el.id === newValue.value))
                    }}
                    renderInput={(params) => (
                        <TextField
                            placeholder={t('calendar.searchParticipants')}
                            {...params}
                            InputProps={{ ...params.InputProps, endAdornment: null }}
                            inputProps={{
                                ...params.inputProps,
                                style: {
                                    marginLeft: 6,
                                    padding: 11.5,
                                    color: SECONDARY_TEXT_COLOR,
                                    fontWeight: 500
                                }
                            }}
                        />
                    )}
                />
            </Box>
        </Box>
    )
}

export default SelectUser