import React, { useEffect, useState } from 'react'
import { Grid, Typography, Button, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useParams } from 'react-router-dom'

import Review from './subcomponents/Review'
import { CreateReview } from '../../../common'

import {
  LIGHT_GRAY_BORDER_COLOR,
  ROLE_BUSINESS_ADMINISTRATOR,
  COMPANIES_TRAINERS_REQUEST_STATUS
} from '../../../../utils/constants'
import * as TRAINER_REQUESTS from '../../../../api/trainer'
import * as COMPANIES_REQUESTS from '../../../../api/company'
import { useAuthentication } from '../../../../context/auth-context'
import { refetchPublicTrainerProfileObservable } from '../../../../utils/observables'
import RenderReviews from '../../../../utils/renderReviews'

const Reviews = ({ userName, userPhotoSrc, userId, reviewsAverage }) => {
  const { t } = useTranslation()
  const { domain } = useParams()
  const { user } = useAuthentication()

  const [loading, setLoading] = useState(true)
  const [showCreateReview, setShowCreateReview] = useState(false)
  const [reviews, setReviews] = useState([])
  const [showAddReview, setShowAddReview] = useState(false)
  const [givenReview, setGivenReview] = useState(true)

  const getReviews = () => {
    setLoading(true)
    if (!domain) return

    TRAINER_REQUESTS.getTrainerReviewsByDomain(domain, user?.id)
      .then((res) => {
        setReviews(res.array)
        setGivenReview(res.givenReview)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (user && user.role === ROLE_BUSINESS_ADMINISTRATOR) {
      COMPANIES_REQUESTS.getAllMembers().then((members) => {
        const member = members.find(
          (el) => el.id === userId && el.status === COMPANIES_TRAINERS_REQUEST_STATUS.APPROVED
        )

        setShowAddReview(!!member)
      })
    } else if (user) {
      setShowAddReview(true)
    }

    getReviews()
  }, [domain, user])
  if (loading) {
    return (
      <Grid
        container
        display="flex"
        disply="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
        p={3}
        pt={5}
      >
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {showCreateReview && (
        <CreateReview
          title={userName}
          photoSrc={userPhotoSrc}
          onCancel={() => setShowCreateReview(false)}
          onSaveHandler={(stars, comment) => {
            TRAINER_REQUESTS.createTrainerReview(domain, {
              reviewTrainerId: userId,
              userId: user.id,
              companyId: user.companyId,
              comment,
              stars
            }).then(() => {
              setShowCreateReview(false)
              getReviews()
              refetchPublicTrainerProfileObservable.setRefetchPublicTrainerProfile(true)
            })
          }}
        />
      )}
      <Grid
        container
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }}
        p={3}
        pt={5}
      >
        <Grid item xs={12} display="flex" flexDirection="row">
          <Typography fontWeight={500} color="black" fontSize={20}>
            {t('userProfile.activity.reviews')}
          </Typography>
          {showAddReview && !givenReview && (
            <Button
              sx={{ marginLeft: 'auto' }}
              variant="contained"
              color="success"
              onClick={() => setShowCreateReview(true)}
            >
              {' '}
              {t('button.addReview')}
            </Button>
          )}{' '}
        </Grid>
        <Grid pt={2} item xs={12} display="flex" alignItems="center" flexDirection="row">
          <RenderReviews reviews={reviews} reviewsAverage={Math.ceil(reviewsAverage)} />
        </Grid>
        <Grid item xs={12} pt={5}>
          <Grid container display="flex">
            {reviews.length > 0 ? (
              reviews.map((el, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    pt={2}
                    pb={2}
                    width="100%"
                    borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
                  >
                    <Review
                      domain={el.Company ? el.Company.domain : el.User.domain}
                      name={el.User.firstName + ' ' + el.User.lastName}
                      company={el.Company ? el.Company.name : null}
                      comment={el.comment}
                      createdAt={moment(el.createdAt).format('YYYY-MM-DD')}
                      stars={el.stars}
                    />
                  </Grid>
                )
              })
            ) : (
              <Typography color="#979797" fontWeight={500} fontSize={17}>
                {t('helper.noResultsFound')}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Reviews
