import { Container, Grid, Step, StepConnector, StepLabel } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    padding: '30px',
    borderRadius: '25px',
    height: '852px',
    background: '#FFFFFF',
    boxShadow: '5px 12px 30px #00000010',
    opacity: 1,
}));


const CustomConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${StepConnector.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${StepConnector.active}`]: {
        [`& .${StepConnector.line}`]: {
            borderColor: 'black',
        },
    },
    [`&.${StepConnector.completed}`]: {
        [`& .${StepConnector.line}`]: {
            borderColor: 'black',
        },
    },
    [`& .${StepConnector.line}`]: {
        borderColor: 'grey',
        borderTopWidth: 2,
    },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    height: '100%',
    background: '#F5F5F5',
    borderRadius: '25px',
    opacity: 1,
    padding: '42px',
}));
const CustomStepIcon = (props) => {
    const { completed, icon } = props;

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: completed ? 'black' : 'transparent',
                borderRadius: '50%',
                width: 24,
                height: 24,
                color: completed ? 'white' : '#ccc',
                border: completed ? '1px solid black' : '1px solid #ccc',
            }}
        >
            {icon}
        </div>
    );
};

const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    '& .MuiStepLabel-labelContainer': {

        marginTop: '-80px'
    },
}));
export {
    StyledContainer,
    CustomConnector,
    StyledGrid,
    CustomStepLabel,
    CustomStepIcon
}