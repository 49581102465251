import React, { useState } from 'react'
import { Grid, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

import FatPercentage from './subcomponents/FatPercentage'
import Measurement from './subcomponents/Measurement'
import Weight from './subcomponents/Weight'
import Custom from './subcomponents/Custom'

import { Tabs } from '../../../common'

const Report = ({ selectedUser }) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(0)

  return (
    <Grid p={4} pr={0} pl={0} container display="flex" flexDirection="column">
      <Grid item pt={2} pb={3} display="flex" flexDirection="row">
        <Grid container display="flex" flexDirection="row">
          <Grid item xs={12} lg={9}>
            <Typography fontSize={25} variant="h1" fontWeight="bold">
              {selectedUser ? `${selectedUser.fullName} Progress` : t('userProfile.activity.yourProgress')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid width="99%">
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => {
            setActiveTab(newValue)
          }}
          options={[
            {
              label: t('userProfile.activity.fatPercentage'),
              value: <FatPercentage userId={selectedUser ? selectedUser.id : ''} />
            },
            {
              label: t('userProfile.activity.measurements'),
              value: <Measurement userId={selectedUser ? selectedUser.id : ''} />
            },
            {
              label: t('userProfile.activity.weights'),
              value: <Weight userId={selectedUser ? selectedUser.id : ''} />
            },
            {
              label: t('userProfile.activity.custom'),
              value: <Custom userId={selectedUser ? selectedUser.id : ''} />
            }
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default Report
