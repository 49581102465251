import React, { useMemo } from 'react'
import { Grid, Typography, Rating, Skeleton, Avatar, Paper, Box, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { CheckCircle, ArrowForwardIos, Star } from '@mui/icons-material'

import { GRAY_TEXT_COLOR, LIGHT_GRAY_BORDER_COLOR } from '../../../utils/constants'
import { getBackgroundImage } from '../../../utils/helpers/bgImages'

const ClubList = ({ clubs, loading, count, onNextPage }) => {
  const { t } = useTranslation()

  const renderContent = useMemo(() => {
    return clubs.map((row, key) => {
      return (
        <Grid item xs={12} lg={3} key={key}>
          <Link style={{ textDecoration: 'none' }} to={'/' + row.domain}>
            <Grid
              container
              component={Paper}
              elevation={5}
              key={key}
              sx={{
                backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.6)),url(${getBackgroundImage(row.defaultSportType)})`,
                display: 'flex',
                flexDirection: 'column',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                justifyContent: 'center',
                height: 220,
                width: '100%',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <Box display="flex" flexDirection="column" marginTop="auto" style={{ width: '100%' }}>
                <Box
                  style={{
                    height: 110,
                    display: 'flex',
                    flexDirection: 'row',
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingBottom: 10
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      width: '80%',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      flexGrow: 0
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        color="white"
                        noWrap
                        variant="h6"
                        fontWeight="bold"
                        maxWidth="80%"
                        flexDirection="row"
                        alignItems="center"
                      >
                        {row.clubName}
                      </Typography>
                      {row.verified ? (
                        <Box sx={{ paddingLeft: 0.5, display: 'flex', alignItems: 'center' }}>
                          <CheckCircle fontSize="small" sx={{ color: 'white' }} />
                        </Box>
                      ) : null}
                    </Box>

                    <Typography color="white" fontSize="p" fontWeight="500" noWrap maxWidth="90%">
                      {row.defaultSportType ? t(`sportTypesOptions.${row.defaultSportType}`) : row.address}
                    </Typography>

                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center'
                      }}
                    >
                      <Rating
                        emptyIcon={
                          <Star
                            style={{ opacity: 0.55, color: LIGHT_GRAY_BORDER_COLOR }}
                            fontSize="inherit"
                          />
                        }
                        precision={0.1}
                        sx={{ color: 'white', fontSize: 16 }}
                        disabled
                        value={Math.ceil(row.reviewsAverage > 5 ? 5 : row.reviewsAverage < 1 ? 1 : row.reviewsAverage)}
                      />
                      <Typography pl={1} color="white" variant="span" noWrap fontWeight="bold">
                        {row.reviewsCount} {t('explore.clubs.reviews')}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      width: '20%',
                      marginTop: 'auto'
                    }}
                  >
                    <Avatar
                      src={row.avatarPhotoSrc}
                      alt={row.clubName}
                      style={{ borderRadius: 6, border: `2px solid white`, height: 60, width: 60 }}
                    >
                      {row.clubName.charAt(0)}
                    </Avatar>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Link>
        </Grid>
      )
    })
  }, [clubs])

  const renderMore = () => {
    return (
      <Grid item xs={12} lg={3} onClick={onNextPage}>
        <Grid
          container
          component={Paper}
          elevation={0}
          style={{ height: 220, cursor: 'pointer', boxShadow: '0 13px 33px 0 rgb(41 40 56 / 8%)' }}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <IconButton>
            <ArrowForwardIos fontSize="medium" style={{ color: GRAY_TEXT_COLOR }} />
          </IconButton>
          <Typography variant="subtitle1" fontWeight={500} color={GRAY_TEXT_COLOR}>
            {t('explore.showMore')}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container pt={2}>
      <Grid item xs={12}>
        <Grid container display="flex" flexDirection={'row'}>
          <Grid item xs={12} lg={12}>
            <Typography alignSelf="center" fontWeight="bold" variant="h5">
              {!loading && count ? count : null} {t('helper.clubsNearYou')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid ml={0.5} item xs={12}>
        <Grid
          container
          sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
          spacing={2}
          pb={3}
          pt={1}
        >
          {!loading && !count ? (
            <Grid item xs={12}>
              <Typography variant="h6" fontWeight={500} color={GRAY_TEXT_COLOR}>
                {t('explore.noResult')}
              </Typography>
            </Grid>
          ) : null}
          {clubs.length ? <>{renderContent}</> : null}
          {loading ? (
            <Grid item xs={12} lg={3}>
              <Skeleton
                variant="rectangular"
                elevation={4}
                component={Paper}
                style={{ borderRadius: 2 }}
                height={220}
              />
            </Grid>
          ) : null}
          {loading ? (
            <Grid item xs={12} lg={3}>
              <Skeleton
                variant="rectangular"
                elevation={4}
                component={Paper}
                style={{ borderRadius: 2 }}
                height={220}
              />
            </Grid>
          ) : null}
          {loading ? (
            <Grid item xs={12} lg={3}>
              <Skeleton
                variant="rectangular"
                elevation={4}
                component={Paper}
                style={{ borderRadius: 2 }}
                height={220}
              />
            </Grid>
          ) : null}
          {loading ? (
            <Grid item xs={12} lg={3}>
              <Skeleton
                variant="rectangular"
                elevation={4}
                component={Paper}
                style={{ borderRadius: 2 }}
                height={220}
              />
            </Grid>
          ) : null}
          {loading ? (
            <Grid item xs={12} lg={3}>
              <Skeleton
                variant="rectangular"
                elevation={4}
                component={Paper}
                style={{ borderRadius: 2 }}
                height={220}
              />
            </Grid>
          ) : null}
          {loading ? (
            <Grid item xs={12} lg={3}>
              <Skeleton
                variant="rectangular"
                elevation={4}
                component={Paper}
                style={{ borderRadius: 2 }}
                height={220}
              />
            </Grid>
          ) : null}
          {clubs.length && clubs.length < count ? renderMore() : null}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ClubList
