import React, { useState, useEffect } from 'react'
import { Grid, Card } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';

import { Tabs } from '../../common'
import Details from './subcomponents/Details'
import Reviews from './subcomponents/Reviews'
import Subscriptions from './subcomponents/Subscriptions'
import Services from './subcomponents/Services'

import { LIGHT_GRAY_BORDER_COLOR } from '../../../utils/constants'

import * as COMPANY_REQUESTS from '../../../api/company'
import { useAuthentication } from '../../../context/auth-context'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
    flexGrow: 1
  }
})

const BusinessActivity = ({ company }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useAuthentication()
  const location = useLocation()
  const navigate = useNavigate()

  const [services, setServices] = useState([])
  const [servicesUntouched, setServicesUntouched] = useState([])
  const [filters, setFilters] = useState({
    active: 'all',
    public: 'all'
  })
  const [subscriptionFilters, setSubscriptionFilters] = useState({
    visibility: 'all',
    public: 'all'
  })
  const [subscriptions, setSubscriptions] = useState([])
  const [subscriptionsUntouched, setSubscriptionsUntouched] = useState([])
  const [reviews, setReviews] = useState([])
  const [activeTab, setActiveTab] = useState(undefined)

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    })
    const activeOption = params['active_option']
    if (activeOption) setActiveTab(Number(activeOption))
    else setActiveTab(0)
  }, [])

  useEffect(() => {
    setServices(() => {
      let servicesCopy = [...servicesUntouched]
      servicesCopy = servicesCopy.filter((el) => {
        if (filters.active !== 'all' && filters.public !== 'all')
          return el.isActive === filters.active && el.isPublic === filters.public
        if (filters.active !== 'all' && filters.public === 'all')
          return el.isActive === filters.active
        if (filters.active === 'all' && filters.public !== 'all')
          return el.isPublic === filters.public

        return true
      })

      return servicesCopy
    })
  }, [filters])

  useEffect(() => {
    setSubscriptions(() => {
      let subscriptionsCopy = [...subscriptionsUntouched]
      subscriptionsCopy = subscriptionsCopy.filter((el) => {
        if (subscriptionFilters.public !== 'all' && subscriptionFilters.visibility !== 'all')
          return (
            el.isAvailable === subscriptionFilters.public &&
            el.visibility === subscriptionFilters.visibility
          )
        if (subscriptionFilters.public !== 'all' && subscriptionFilters.visibility === 'all')
          return el.isAvailable === subscriptionFilters.public
        if (subscriptionFilters.public === 'all' && subscriptionFilters.visibility !== 'all')
          return el.visibility === subscriptionFilters.visibility

        return true
      })

      return subscriptionsCopy
    })
  }, [subscriptionFilters])

  const handleGetAllServices = () => {
    COMPANY_REQUESTS.getAllServices(user.companyId).then((res) => {
      const mappedServices = res.map((el) => ({
        id: el.id,
        name: el.title,
        description: el.description,
        priceRon: el.priceRon,
        isFree: el.isFree,
        trainerName: el.Trainer ? `${el.Trainer.firstName} ${el.Trainer.lastName}` : null,
        Reviews: [],
        handleExpand,
        expand: el.expand,
        isActive: el.isActive,
        isPublic: el.isPublic,
        Trainers: el.Trainers,
        currency: el.Company.currency
      }))
      setServices(mappedServices)
      setServicesUntouched(mappedServices)
    })
  }

  const handleGetAllReviews = () => {
    COMPANY_REQUESTS.getAllReviews(company.id).then((res) => {
      setReviews(res)
    })
  }

  const handleGetAllSubscriptions = () => {
    COMPANY_REQUESTS.getAllSubscriptions(company.id).then((res) => {
      const mappedSubscriptions = res
        .filter((e) => e !== null)
        .map((el) => ({
          ...el,
          id: el.id,
          name: el.name,
          description: el.description,
          isFree: el.isFree,
          price: el.priceRon,
          duration: el.duration,
          trainerName: el.Trainer ? `${el.Trainer.firstName} ${el.Trainer.lastName}` : null,
          handleExpand: handleExpandSubscriptions,
          expand: el.expand,
          visibility: el.visibility,
          isAvailable: el.isAvailable
        }))

      setSubscriptions(mappedSubscriptions)
      setSubscriptionsUntouched(mappedSubscriptions)
    })
  }

  useEffect(() => {
    if (activeTab === 1) {
      handleGetAllServices()
    } else if (activeTab === 2) {
      handleGetAllSubscriptions()
    } else if (activeTab === 3) {
      handleGetAllReviews()
    }
  }, [activeTab])

  const handleExpand = (id) => {
    setServices((prevValue) => {
      let prevValueCopy = [...prevValue]
      const idPosition = prevValueCopy.findIndex((f) => f.id === id)

      if (idPosition < 0) return prevValueCopy

      prevValueCopy[idPosition].expand = !prevValueCopy[idPosition].expand

      return prevValueCopy
    })
  }

  const handleExpandSubscriptions = (id) => {
    setSubscriptions((prevValue) => {
      let prevValueCopy = [...prevValue]
      const idPosition = prevValueCopy.findIndex((f) => f.id === id)

      if (idPosition < 0) return prevValueCopy

      prevValueCopy[idPosition].expand = !prevValueCopy[idPosition].expand

      return prevValueCopy
    })
  }

  const handleSearchSubscriptions = (event) => {
    setSubscriptions(
      subscriptionsUntouched.filter((el) =>
        el.name.toLowerCase().includes(event.target.value.toLowerCase())
      )
    )
  }

  const handleSearch = (event) => {
    setServices(
      servicesUntouched.filter((el) =>
        el.name.toLowerCase().includes(event.target.value.toLowerCase())
      )
    )
  }

  const handleFilters = (name, value, type) => {
    if (type === 'subscription') {
      setSubscriptionFilters((prevValue) => {
        const prevValueCopy = {
          ...prevValue,
          [name]: value
        }

        return prevValueCopy
      })
    } else {
      setFilters((prevValue) => {
        const prevValueCopy = {
          ...prevValue,
          [name]: value
        }

        return prevValueCopy
      })
    }
  }

  const handleOnDeleteService = (id) => {
    COMPANY_REQUESTS.deleteService(id).then(() => {
      handleGetAllServices()
    })
  }

  const handleOnDeleteSubscription = (id) => {
    COMPANY_REQUESTS.deleteSubscription(id).then(() => {
      handleGetAllSubscriptions()
    })
      .catch(e => {
        toast.error(t('userProfile.errorDeleteSub'), { position: 'bottom-right', autoClose: false })
      })
  }

  if (activeTab === undefined) return null

  return (
    <Grid container justifyContent="flex-end" height="100%">
      <Grid className={classes.content} component={Card} item sm={12} xl={12}>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => {
            const to = { pathname: location.pathname, search: `active_option=${newValue}` }
            navigate(to, { replace: true })
            setActiveTab(newValue)
          }}
          options={[
            {
              label: t('userProfile.activity.details'),
              value: <Details company={company} />
            },
            {
              label: t('userProfile.services'),
              value: (
                <Services
                  handleFilters={handleFilters}
                  handleSearch={handleSearch}
                  handleGetAllServices={handleGetAllServices}
                  handleOnDelete={handleOnDeleteService}
                  Services={services}
                  filters={filters}
                />
              )
            },
            {
              label: t('userProfile.subscriptions'),
              value: (
                <Subscriptions
                  companyId={company.id}
                  domain={company.domain}
                  currency={company.currency}
                  filters={subscriptionFilters}
                  getSubscriptions={handleGetAllSubscriptions}
                  handleFilters={handleFilters}
                  handleSearch={handleSearchSubscriptions}
                  handleOnDelete={handleOnDeleteSubscription}
                  Subscriptions={subscriptions}
                />
              )
            },
            {
              label: t('userProfile.activity.reviews'),
              value: <Reviews reviewsAverage={Math.ceil(company?.reviewsAverage > 5 ? 5 : company?.reviewsAverage < 1 ? 1 : company?.reviewsAverage)} />
            }
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default BusinessActivity
