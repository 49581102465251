import React, { useState, useMemo } from 'react'
import { Grid, Card, Typography, Button, Box, Avatar, Slide } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

import {
  LIGHT_GRAY_BORDER_COLOR,
  CARD_TEXT_COLOR,
  GRAY_TEXT_COLOR,
  GRAY_BG_COLOR
} from '../../../utils/constants'

import EditProfile from './subcomponents/EditProfile'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  avatarContainer: {
    borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  credibilityContainer: {
    borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  borderRight: {
    borderRight: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  countReviewersFollowers: {
    fontWeight: 800
  },
  performanceText: {
    color: GRAY_TEXT_COLOR
  },
  memberFrom: {
    color: CARD_TEXT_COLOR
  }
})

const UserDetails = ({
  user: {
    Followers,
    Following,
    lastName,
    firstName,
    createdAt,
    Organization,
    bio,
    performance,
    role,
    credibility,
    avatarPhotoSrc,
    Reviews
  },
  onEditCloseHandler,
  handleGetMe
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [editProfile, setEditProfile] = useState(false)

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })

  const handleOpenEditProfile = () => {
    setEditProfile(true)
  }

  const renderEditProfile = useMemo(() => {
    if (!editProfile) return null
    return (
      <EditProfile
        handleGetMe={handleGetMe}
        avatarPhotoSrc={avatarPhotoSrc}
        firstName={firstName}
        lastName={lastName}
        bioDescription={bio}
        onEditCloseHandler={onEditCloseHandler}
        onClose={() => {
          setEditProfile(false)
          handleGetMe()
        }}
        Transition={Transition}
      />
    )
  }, [editProfile, firstName, lastName, bio, avatarPhotoSrc])

  const renderUserDetails = useMemo(() => {
    return (
      <Grid container className={classes.content} component={Card} flexDirection="column">
        <Grid container pt={3} width="100%" className={classes.avatarContainer}>
          <Grid container width="100%">
            <Grid
              xs={12}
              pl={2}
              pr={2}
              item
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {avatarPhotoSrc ? (
                <Avatar src={avatarPhotoSrc} alt="avatar" style={{ height: 100, width: 100 }} />
              ) : (
                <Box
                  sx={{
                    width: 100,
                    height: 100,
                    backgroundColor: GRAY_BG_COLOR,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%'
                  }}
                >

                  <Typography variant="h4" color={GRAY_TEXT_COLOR} fontWeight={500}>
                    {firstName.charAt(0)} {lastName.charAt(0)}
                  </Typography>


                </Box>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            pt={1}
            pb={3}
            sx={{ display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column' }}
          >
            {role === 'ORGANIZATION' ?
              <Typography variant="h5" fontWeight={'bold'}>
                {Organization.name}
              </Typography>

              :
              <Typography variant="h5" fontWeight={'bold'}>
                {firstName} {lastName}
              </Typography>
            }

            <Typography variant="h6" fontWeight={500} className={classes.memberFrom}>
              {t('userProfile.memberFrom')} {createdAt}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.credibilityContainer} container>
          <Grid
            item
            xs={6}
            pl={3}
            pr={2}
            pb={2}
            pt={2}
            className={classes.borderRight}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography variant="p" className={classes.performanceText}>
              {t('userProfile.performance')}
            </Typography>
            <Grid pt={0.5} item display="flex" flexDirection="row">
              {performance ? (
                <>
                  <Typography color="primary" fontWeight={500} variant="h4">
                    {performance}
                  </Typography>
                  <Typography fontWeight={500} variant="p">
                    /10
                  </Typography>
                </>
              ) : (
                <Typography color="primary" fontWeight={500} variant="h4">
                  N/A
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            pl={3}
            pr={2}
            pb={2}
            pt={2}
            alignItems="flex-end"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            item
            xs={6}
          >
            <Typography variant="p" className={classes.performanceText}>
              {t('userProfile.credibility')}
            </Typography>
            <Grid pt={0.5} item display="flex" flexDirection="row">
              {credibility ? (
                <>
                  <Typography color={'#ffd500'} variant="h4" fontWeight={500}>
                    {credibility}
                  </Typography>
                  <Typography fontWeight={500} variant="p">
                    /10
                  </Typography>
                </>
              ) : (
                <Typography color={'#ffd500'} variant="h4" fontWeight={500}>
                  {'N/A'}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid pt={2} pb={2} item display="flex" justifyContent="center" alignItems="center">
          <Grid item xs={6}>
            <Button fullWidth onClick={handleOpenEditProfile} variant="outlined">
              {t('userProfile.editProfile')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  })

  return (
    <>
      {renderEditProfile}
      {renderUserDetails}
    </>
  )
}

export default UserDetails
