import moment from 'moment'

import { SUBSCRIPTION_PAYMENT_STATUS, DATE_FORMAT } from '../constants'

export const getStatus = (userSubscription, slotStart, eventStart) => {
    const current = moment().utc()
    const start = moment(userSubscription.validFrom).utc()
    const end = moment(userSubscription.validUntil).utc()

    const pauseData = {
        hasPause: !!userSubscription.Pause
    }
    const paymentDeadline = {
        hasPaymentDeadline: !userSubscription.Subscription.confirmation && !!userSubscription.Subscription.paymentDeadline && userSubscription.Subscription.paymentDeadline > 0
    }

    if (paymentDeadline.hasPaymentDeadline) {
        const deadlinePayment = moment(start, DATE_FORMAT).add(Number(userSubscription.Subscription.paymentDeadline), 'days').set('hours', 23).set('minutes', 59)
        paymentDeadline.isBetween = slotStart ? slotStart.isBefore(deadlinePayment, '[]') : current.isBetween(start, deadlinePayment, '[]')
    }

    if (pauseData.hasPause) {
        const startPause = moment(userSubscription.Pause.startDate, DATE_FORMAT).set('hours', 0).set('minutes', 1)
        const endPause = moment(userSubscription.Pause.endDate, DATE_FORMAT).set('hours', 23).set('minutes', 59)
        pauseData.isBetween = current.isBetween(startPause, endPause, '[]')
    }

    const isBetween = eventStart ? moment.utc(eventStart).isBetween(start, end, '[]') : current.isBetween(start, end, '[]')
    const isBefore = current.isBefore(start, '[]')
    const isAfter = current.isAfter(end, '[]')

    const paid = userSubscription.status === SUBSCRIPTION_PAYMENT_STATUS.CONFIRMED

    const active = isBetween &&
        (userSubscription.Subscription.confirmation ? paid : (!paid ? (paymentDeadline.hasPaymentDeadline && paymentDeadline.isBetween) : paid)) &&
        (userSubscription.Pause ? !pauseData.isBetween : true) &&
        (userSubscription.limitedActivationTotal ? userSubscription.limitedActivationUsed < userSubscription.limitedActivationTotal : true)

    const status = {
        paid,
        active,
        inactive: !active,
        paused: pauseData.hasPause ? pauseData.isBetween : false,
        expired: isAfter,
        notStarted: isBefore,
        isAfter,
        isBefore,
        isBetween,
        isDeleted: userSubscription.isDeleted,
        paymentDeadlineActive: paymentDeadline.hasPaymentDeadline && paymentDeadline.isBetween
    }

    return status
}