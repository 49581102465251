import { createTheme } from '@mui/material/styles'
import { PRIMARY_COLOR } from '../../constants'

const checkBoxTheme = createTheme({
  components: {
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: PRIMARY_COLOR
        }
      }
    }
  }
})

export default checkBoxTheme
