import React, { createContext, useState, useContext } from 'react'

const contextDefaultValues = {
  isLogged: false,
  user: null,
  token: '',
  handleLoggedIn: () => {},
  handleToken: () => {}
}

export const AuthenticationContext = createContext(contextDefaultValues)

const AuthenticationProvider = ({ children }) => {
  const [isLogged, setIsLogged] = useState(contextDefaultValues.isLogged)
  const [user, setUser] = useState(contextDefaultValues.user)
  const [token, setToken] = useState(contextDefaultValues.token)

  const handleLoggedIn = (value) => setIsLogged(value)

  const handleToken = (value) => {
    localStorage.setItem('token', value)
    setToken(value)
  }

  const handleUser = (value) => setUser(value)

  return (
    <AuthenticationContext.Provider
      value={{
        isLogged,
        token,
        user,
        handleToken,
        handleLoggedIn,
        handleUser
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}

export const useAuthentication = () => useContext(AuthenticationContext)

export default AuthenticationProvider
