import React from 'react'
import { Grid, Typography, Card, TextField } from '@mui/material'
import { DateRange } from '@mui/icons-material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import moment from 'moment'

import { PRIMARY_COLOR } from '../../../utils/constants'
import { useTranslation } from 'react-i18next'

const DatePickerStartDate = ({
  startFrom,
  openStartFrom,
  startAnchorEl,
  simple,
  setStartAnchorEl,
  setOpenStartFrom,
  setStartFrom
}) => {
  const { t } = useTranslation()

  return (
    <Grid item lg={12}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          sx={{
            width: '100%'
          }}
          // open={openStartFrom}
          inputFormat="MM/DD/YYYY"
          disablePast
          maxDate={moment().add(1, 'month')}
          value={startFrom}
          slotProps={{
            textField: {
              helperText: <>
                {t('checkout.selectStart')} <span style={{ color: PRIMARY_COLOR }}>*</span>&nbsp;
              </>
            }
          }}
          PopperProps={{
            anchorEl: startAnchorEl
          }}
          OpenPickerButtonProps={{
            style: {
              display: 'none'
            }
          }}
          onChange={(value) => {
            setOpenStartFrom(false)
            setStartFrom(moment(value))
          }}
        />
      </LocalizationProvider>
    </Grid>
  )
}

export default DatePickerStartDate
