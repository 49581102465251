import React from 'react'
import { Box, Typography, Button, Dialog, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CHECKIN from '../../../assets/checkout-success.gif'
import CHECKOUT from '../../../assets/checkout.gif'

export default props => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <Dialog open maxWidth="sm" fullWidth>
            <Grid container p={4}>
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item
                            xs={10}
                            md={10}
                            lg={10}
                            pr={{ xs: 0, md: 0, lg: 2 }}
                            pl={{ xs: 0, md: 0, lg: 2 }}>
                            <Typography variant="h4" fontWeight={"bold"} color={'black'}>
                                {props.selfOut ? 'Check-Out' : 'Self-In'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} display="flex" pt={5} justifyContent="center" alignItems="center" flexDirection="column">
                    <Box style={{ width: '100%', height: '100%' }}>
                        <img height={'100%'} width={'100%'} src={props.selfOut ? CHECKOUT : CHECKIN} />
                    </Box>
                    <Typography variant="h6" fontWeight={500} gutterBottom textAlign="center" maxWidth="80%">
                        {props.selfOut ? t('selfin.yourCheckOut') : `${t('selfin.horay')}\n${t('selfin.yourCheckin')}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} display="flex" pt={5} justifyContent="center" flexDirection="column" alignItems="center">
                    <Button variant="contained" onClick={props.onCancel} fullWidth>{t('button.goToDashboard')}</Button>
                </Grid>
            </Grid>
        </Dialog>
    )
}