import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Grid, Typography, Container } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import io from 'socket.io-client'
import { toast } from 'react-toastify'

import { Filters, CheckIn } from './subcomponents'
import { Tabs, SimplePagination, Confirm } from '../../components/common'
import { useAuthentication } from '../../context/auth-context'
import { GRAY_TEXT_COLOR } from '../../utils/constants'
import { BASE_URL } from '../../utils/config'

import * as CHECKIN_REQUESTS from '../../api/checkin'

const PAGE_SIZE = 30

const CheckInOut = () => {
  const { user } = useAuthentication()
  const { t } = useTranslation()

  const [activeTab, setActiveTab] = useState(0)
  const [checkIns, setCheckIns] = useState([])
  const [checkInsCount, setCheckInsCount] = useState(0)
  const [page, setPage] = useState(1)
  const [showDeleteCheckIn, setShowDeleteCheckIn] = useState(false)
  const [showConfirmCheckOut, setShowConfirmCheckOut] = useState(false)
  const [selectedCheckIn, setSelectedCheckIn] = useState()
  const [socket, setSocket] = useState()

  useEffect(() => {
    const token = localStorage.getItem('token')

    if (!token) return

    const socketInstance = io(BASE_URL, {
      query: { token }
    })
    setSocket(socketInstance)
  }, [])

  useEffect(() => {
    if (!socket || !user || !user.companyId) return

    socket.on('newCheckIn', newCheckIn => {
      if (Number(newCheckIn.companyId) !== Number(user.companyId)) return
      if (newCheckIn.failed) {
        // toast.error(`Eroare la Selfin pentru clientul ${newCheckIn.User ? `${newCheckIn.User.firstName} ${newCheckIn.User.lastName}` : ''}`, { position: 'bottom-right', autoClose: 12000 })
      }

      if (activeTab === 0 && !newCheckIn.failed) {
        const newCheckIns = [...checkIns]
        newCheckIns.push(newCheckIn)

        setCheckIns(newCheckIns)
        // toast.success(`${newCheckIn.User ? `${newCheckIn.User.firstName} ${newCheckIn.User.lastName}` : ''} a facut SelfIn`, { position: 'bottom-right' })
      }

      if (activeTab === 2) {
        const newCheckIns = [...checkIns]
        newCheckIns.push(newCheckIn)

        setCheckIns(newCheckIns)
      }

    })

    return () => {
      socket.off('connect')
      socket.off('disconnect')
      socket.off('newCheckIn')
    }
  }, [socket, user, activeTab, checkIns])

  const getAll = () => {
    return CHECKIN_REQUESTS.findAndCountAll({
      companyId: user.companyId,
      from: (page - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
      checkedOut: activeTab === 1,
      failed: activeTab === 2
    }).then((result) => {
      setCheckInsCount(result.count)
      setCheckIns(result.rows)
    })
  }

  const getAllCheckInsHandler = useCallback(() => {
    if (page === 1) return getAll()
    setPage(1)
  }, [page, activeTab, user])

  const onDeleteCheckInHandler = useCallback((checkInId) => {
    return () => {
      return CHECKIN_REQUESTS.remove(checkInId).then(getAll)
    }
  }, [activeTab, user])

  const onCheckOutHandler = useCallback((checkInId) => {
    return () => {
      return CHECKIN_REQUESTS.update(checkInId, { checkedOut: true }).then(getAll)
    }
  }, [activeTab, user])

  const onUpdateKeyNumberHandler = useCallback((checkInId, keyNumber) => {
    return CHECKIN_REQUESTS.update(checkInId, { keyNumber }).then(getAll)
  }, [activeTab, user])

  useEffect(() => {
    getAll()
  }, [page, user, activeTab])

  const renderFilters = useMemo(() => {
    return <Filters onCreate={getAllCheckInsHandler} />
  }, [activeTab])

  const renderTodayCheckIns = useMemo(() => {
    if (!checkIns.length)
      return (
        <Typography variant="h5" pt={3} color={GRAY_TEXT_COLOR}>
          {t('checkIn.noCheckIn')}
        </Typography>
      )

    return (
      <>
        {showDeleteCheckIn && (
          <Confirm
            titleText={t('checkInFilters.deleteTitle')}
            description={t('checkInFilters.confirmDeleteCheckIn')}
            cancelText={t('button.cancel')}
            confirmButtonColor="error"
            confirmText={t('checkInFilters.confirmDelete')}
            onCancel={() => {
              setShowDeleteCheckIn(false)
              setSelectedCheckIn()
            }}
            onConfirm={onDeleteCheckInHandler(selectedCheckIn.id)}
          />
        )}
        {showConfirmCheckOut && (
          <Confirm
            titleText={t('checkInFilters.confirmCheckOutTitle')}
            description={t('checkInFilters.confirmCheckOut', { name: selectedCheckIn.name })}
            cancelText={t('button.cancel')}
            confirmButtonColor="primary"
            confirmText={t('checkInFilters.confirmCheckOutButton')}
            onCancel={() => {
              setShowConfirmCheckOut(false)
              setSelectedCheckIn()
            }}
            onConfirm={onCheckOutHandler(selectedCheckIn.id)}
          />
        )}
        {checkIns && checkIns.length > 0 ? (
          <Grid container spacing={2} pt={2} pb={{ xs: 8 }}>
            {activeTab !== 3 ? checkIns.filter(el => el.UserSubscription || el.Event || (activeTab === 2 && el.failed) || el.isTrainer !== true).map((el, index) => {
              const checkedInTime = moment(new Date(el.createdAt))
              const currentTime = moment(new Date())
              const duration = moment.duration(currentTime.diff(checkedInTime)).asHours()
              if (el.failed && el.User) {
                const name = `${el.User.firstName} ${el.User.lastName}`

                return <Grid item xs={12} key={index}>
                  <CheckIn
                    failed
                    name={name}
                    avatarPhotoSrc={el.User.avatarPhotoSrc}
                    userId={el.userId}
                    time={moment(el.createdAt).format('HH:mm')}
                    keyNumber={el.keyNumber}
                    informations={el.description}
                    phoneNumber={el.User.phoneNumber}
                    onDelete={() => {
                      setSelectedCheckIn({ id: el.id, name })
                      setShowDeleteCheckIn(true)
                    }}
                    checkedOut={el.checkedOut}
                  />
                </Grid>
              }

              if (!el.Event) {
                if (!el.UserSubscription) return null
                const name = el && el.UserSubscription && el.UserSubscription.User ? `${el.UserSubscription.User.firstName} ${el.UserSubscription.User.lastName}` : '-'

                return (
                  <Grid item xs={12} key={index}>
                    <CheckIn
                      onUpdateKeyNumber={keyNumber => onUpdateKeyNumberHandler(el.id, keyNumber)}
                      name={name}
                      userId={el.userId}
                      warning={!el.checkedOut && duration > 1}
                      limitedActivationTotal={el.UserSubscription.limitedActivationTotal}
                      limitedActivationUsed={el.limitedActivation}
                      time={moment(el.createdAt).format('HH:mm')}
                      keyNumber={el.keyNumber}
                      checkedOutAt={el.checkedOutAt}
                      subscriptionName={el.UserSubscription.Subscription.name}
                      informations={el.description}
                      phoneNumber={el.UserSubscription.User.phoneNumber}
                      onDelete={() => {
                        setSelectedCheckIn({ id: el.id, name })
                        setShowDeleteCheckIn(true)
                      }}
                      onCheckOut={() => {
                        setSelectedCheckIn({ id: el.id, name })
                        setShowConfirmCheckOut(true)
                      }}
                      checkedOut={el.checkedOut}
                    />
                  </Grid>
                )
              }
              const name = el.User ? `${el.User.firstName} ${el.User.lastName}` : '-'

              return (
                <Grid item xs={12} key={index}>
                  <CheckIn
                    onUpdateKeyNumber={keyNumber => onUpdateKeyNumberHandler(el.id, keyNumber)}
                    avatarPhotoUrl={el.User ? el.User.avatarPhotoSrc : null}
                    name={name}
                    userId={el.userId}
                    warning={!el.checkedOut && duration > 1}
                    time={moment(el.createdAt).format('HH:mm')}
                    keyNumber={el.keyNumber}
                    isEvent
                    limitedActivationTotal={el && el.UserSubscription ? el.UserSubscription.limitedActivationTotal : ''}
                    limitedActivationUsed={el.limitedActivation}
                    checkedOutAt={el.checkedOutAt}
                    subscriptionName={el.Event.name}
                    informations={el.description}
                    phoneNumber={el.User ? el.User.phoneNumber : '-'}
                    onDelete={() => {
                      setSelectedCheckIn({ id: el.id, name })
                      setShowDeleteCheckIn(true)
                    }}
                    onCheckOut={() => {
                      setSelectedCheckIn({ id: el.id, name })
                      setShowConfirmCheckOut(true)
                    }}
                    checkedOut={el.checkedOut}
                  />
                </Grid>
              )
            }) : null}
            {activeTab === 3 ? checkIns.filter(el => el.isTrainer).map((el, index) => {
              const name = el.User ? `${el.User.firstName} ${el.User.lastName}` : '-'

              return (
                <Grid item xs={12} key={index}>
                  <CheckIn
                    onUpdateKeyNumber={keyNumber => onUpdateKeyNumberHandler(el.id, keyNumber)}
                    avatarPhotoUrl={el.User ? el.User.avatarPhotoSrc : null}
                    name={name}
                    userId={el.userId}
                    warning={false}
                    time={moment(el.createdAt).format('HH:mm')}
                    keyNumber={el.keyNumber}
                    isEvent
                    limitedActivationTotal={''}
                    limitedActivationUsed={''}
                    checkedOutAt={el.checkedOutAt}
                    subscriptionName={'Trainer'}
                    informations={el.description}
                    phoneNumber={el.User ? el.User.phoneNumber : '-'}
                    onDelete={() => {
                      setSelectedCheckIn({ id: el.id, name })
                      setShowDeleteCheckIn(true)
                    }}
                    onCheckOut={() => {
                      setSelectedCheckIn({ id: el.id, name })
                      setShowConfirmCheckOut(true)
                    }}
                    checkedOut={el.checkedOut}
                  />
                </Grid>
              )
            }) : null}
            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
              <SimplePagination
                count={Math.ceil(Number(checkInsCount / PAGE_SIZE))}
                onPageChange={setPage}
              />
            </Grid>
          </Grid>
        ) : null}
      </>
    )
  }, [checkIns, showDeleteCheckIn, showConfirmCheckOut, activeTab])

  return (
    <>
      <Container maxWidth="xl">
        <Grid container pt={8} pb={8}>
          <Grid item xs={12}>
            {renderFilters}
          </Grid>
          <Grid pt={4} item xs={12}>
            <Tabs
              variant="fullWidth"
              value={activeTab}
              onChange={(_, newValue) => {
                setCheckIns([])
                setActiveTab(newValue)
              }}
              options={[
                {
                  label: t('checkInFilters.today'),
                  value: renderTodayCheckIns
                },
                {
                  label: t('checkInFilters.past'),
                  value: renderTodayCheckIns
                },
                {
                  label: t('checkInFilters.failed'),
                  value: renderTodayCheckIns
                },
                {
                  label: 'Trainers',
                  value: renderTodayCheckIns
                }
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default CheckInOut
