import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { BLUE_CHART } from '../../utils/constants'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    }
  }
}

const BarChart = ({ values, type }) => {
  const data = {
    labels: values.map((el) => el.date),
    datasets: [
      {
        label: type,
        data: values.map((el) => el.value),
        borderColor: BLUE_CHART,
        backgroundColor: BLUE_CHART
      }
    ]
  }

  return <Bar height={'90%'} options={options} data={data} />
}

export default BarChart
