import React, { useCallback, useEffect, useState } from 'react'
import { TableContainer, Table, TableHead, Typography, TableBody, TableRow, TableCell, Paper, Checkbox, Box, IconButton, Grid, TextField, Button } from '@mui/material'
import { Edit, ArrowBack } from '@mui/icons-material'

import * as LICENSE_TYPE_REQUESTS from '../../api/licenseType'
import { Footer } from '../common'

const SuperAdminLicenses = () => {
    const [create, setCreate] = useState(false)
    const [licenseTypes, setLicenseTypes] = useState([])
    const [editing, setEditing] = useState()
    const [description, setDescription] = useState('')

    const handleGetLicenseTypes = useCallback(async () => {
        const response = await LICENSE_TYPE_REQUESTS.findAll()
        setLicenseTypes(response)
    }, [])

    useEffect(() => {
        handleGetLicenseTypes()
    }, [handleGetLicenseTypes])

    const handleUpdate = useCallback(async (licenseTypeId) => {
        try {
            await LICENSE_TYPE_REQUESTS.edit(licenseTypeId, { description })
            handleGetLicenseTypes()
            alert("Successfully updated")
        } catch (e) {
            console.error(e)
            alert("Error updating!")
        }
    }, [description])

    const handleCreate = useCallback(async () => {
        try {
            await LICENSE_TYPE_REQUESTS.create({ description })
            handleGetLicenseTypes()
            alert("Successfully created")
        } catch (e) {
            console.error(e)
            alert("Error creating!")
        }
    }, [description])

    const handleDelete = useCallback(async (licenseTypeId) => {
        try {
            await LICENSE_TYPE_REQUESTS.remove(licenseTypeId)
            setEditing()
            handleGetLicenseTypes()
        } catch (e) {
            console.error(e)
        }
    }, [])

    if (create) return (
        <Grid container display="flex" flexDirection="column">
            <Grid item xs={12}>
                <IconButton onClick={() => {
                    setCreate(false)
                    setDescription('')
                }}><ArrowBack /></IconButton>
            </Grid>
            <Grid item xs={12} pt={2} pl={1.5}>
                <Grid container display="flex" flexDirection="row">
                    <Grid item xs={6}>
                        <Grid container display="flex" flexDirection="column">
                            <Grid container display="flex" flexDirection="column" pt={0.5} pb={0.5} pr={2}>
                                <Grid item xs={12} pb={0.5}>
                                    <Typography fontWeight={500} variant="p">
                                        Description
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField onChange={e => setDescription(e.target.value)} value={description} fullWidth />
                                </Grid>
                            </Grid>
                            <Grid container display="flex" flexDirection="column" pt={0.5} pb={0.5} pr={2}>
                                <Grid item xs={12} pt={2}>
                                    <Button onClick={() => handleCreate()} variant="contained" color="primary">Create Type</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    if (editing) return (
        <Grid container display="flex" flexDirection="column">
            <Grid item xs={12}>
                <IconButton onClick={() => {
                    setEditing()
                    setDescription('')
                }}><ArrowBack /></IconButton>
            </Grid>
            <Grid item xs={12} pt={2} pl={1.5}>
                <Grid container display="flex" flexDirection="row">
                    <Grid item xs={6}>
                        <Grid container display="flex" flexDirection="column">
                            <Grid container display="flex" flexDirection="column" pt={0.5} pb={0.5} pr={2}>
                                <Grid item xs={12}>
                                    Description
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField onChange={e => setDescription(e.target.value)} value={description} fullWidth />
                                </Grid>
                            </Grid>
                            <Grid container display="flex" flexDirection="column" pt={0.5} pb={0.5} pr={2}>
                                <Grid item xs={12} pt={2}>
                                    <Button onClick={() => handleUpdate(editing.id)} variant="contained" color="primary">Save your changes</Button>
                                </Grid>
                            </Grid>
                            <Grid container display="flex" flexDirection="column" pt={0.5} pb={0.5} pr={2}>
                                <Grid item xs={12}>
                                    Do you want to remove this license type?
                                </Grid>
                                <Grid item xs={12} pt={2}>
                                    <Button onClick={() => handleDelete(editing.id)} variant="contained" color="error">Remove</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    return (
        <Box display="flex" flexDirection="column">
            <Box ml="auto" mb={2}>
                <Button variant="contained" onClick={() => setCreate(true)}>Add New</Button>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell align='right'>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {licenseTypes.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>
                                    {row.description}
                                </TableCell>
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }} align='right'>
                                    <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                        <Box pl={1} pr={1}>
                                            <IconButton onClick={() => {
                                                setEditing(row)
                                                setDescription(row.description)
                                            }}><Edit /></IconButton>
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default SuperAdminLicenses