import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';

const Info = () => {
    const { t } = useTranslation();

    const texts = [t('payouts.paymentsAmount'), t('payouts.feesAmount'), t('payouts.payoutsAmount')];

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', userSelect: 'none', paddingRight: '7.5%', gap: '4%' }}>
            {texts.map((item, index) => (
                <Typography key={index} fontWeight={'bold'} color={'#707070'} fontSize={'1.4rem'}>{item}</Typography>
            ))}
        </Box>
    )
}

export default Info;