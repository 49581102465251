import React, { useEffect, useState, useMemo } from 'react'
import { Grid, Container } from '@mui/material'
import { useParams } from 'react-router-dom'
import moment from 'moment'

import UserActivity from './subcomponents/UserActivity'
import UserDetails from './subcomponents/UserDetails'
import { Footer } from '../common'

import * as AUTH_REQUESTS from '../../api/auth'

const UserProfile = () => {
  const { id } = useParams()
  const [user, setUser] = useState()

  const getUser = () =>
    AUTH_REQUESTS.getPublicById(id)
      .then((user) => {
        setUser({ ...user, createdAt: moment(new Date(user.createdAt)).format('MMMM, YY') })
      })
      .catch(console.log)

  useEffect(() => {
    getUser()
  }, [id])

  const renderUserDetails = useMemo(() => {
    return <UserDetails user={user} onEditCloseHandler={getUser} />
  }, [user])

  const renderUserActivity = useMemo(() => {
    return <UserActivity user={user} />
  }, [user])

  if (!user) return

  return (
    <Container maxWidth="xl">
      <Grid container pt={5} display="flex" flexDirection="row" width="100%">
        <Grid container lg={3} xs={12} md={12} sm={12} p={2} pr={{ xs: 0, lg: 2 }} pl={{ xs: 0 }}>
          {renderUserDetails}
        </Grid>
        <Grid container lg={9} xs={12} md={12} sm={12} p={2} pr={{ xs: 0 }} pl={{ xs: 0 }}>
          {renderUserActivity}
        </Grid>
      </Grid>
    </Container>
  )
}

export default UserProfile
