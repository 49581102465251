import React, { useEffect, useState, useMemo } from 'react'
import { Grid, Container, CircularProgress, Box } from '@mui/material'
import moment from 'moment'

import TrainerActivity from './subcomponents/TrainerActivity'
import TrainerDetails from './subcomponents/TrainerDetails'
import { Footer } from '../common'

import * as AUTH_REQUESTS from '../../api/auth'
import { refetchTrainerProfileObservable } from '../../utils/observables'

const TrainerProfile = () => {
  const refetchTrainerProfile = refetchTrainerProfileObservable.useRefetchTrainerProfile()
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)

  const getMeHandler = () => {
    setLoading(true)
    AUTH_REQUESTS.getMe()
      .then((user) => {
        setUser({ ...user, createdAt: moment(new Date(user.createdAt)).format('MMMM, YY') })
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getMeHandler()
  }, [])

  useEffect(() => {
    if (!refetchTrainerProfile) return
    getMeHandler()
    refetchTrainerProfileObservable.setRefetchTrainerProfile(false)
  }, [refetchTrainerProfile])

  const renderTrainerDetails = useMemo(() => {
    return <TrainerDetails getMeHandler={getMeHandler} user={user} />
  }, [user])

  const renderTrainerActivity = useMemo(() => {
    return <TrainerActivity user={user} />
  }, [user])

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <CircularProgress />
      </Box>
    )
  }

  if (!user) return null

  return (
    <Container maxWidth="xl">
      <Grid pt={5} container flexDirection="row">
        <Grid item lg={3} xs={12} md={12} sm={12} p={2} pr={{ xs: 0, lg: 2 }} pl={{ xs: 0 }}>
          {renderTrainerDetails}
        </Grid>
        <Grid item lg={9} xs={12} md={12} sm={12} p={2} pr={{ xs: 0 }} pl={{ xs: 0 }}>
          {renderTrainerActivity}
        </Grid>
      </Grid>
    </Container>
  )
}

export default TrainerProfile
