import React, { useEffect, useState, useCallback } from 'react'
import { Grid, Typography, Checkbox } from '@mui/material'

import Subscription from '../../TrainerProfile/subcomponents/subcomponents/subcomponents/Subscription'
import { useTranslation } from 'react-i18next'

const CheckoutServiceWithSubscriptions = ({
  service,
  selectedSubscriptions,
  handleSelectSubscription,
  currency
}) => {
  const { t } = useTranslation()
  const [subscriptions, setSubscriptions] = useState([])

  useEffect(() => {
    if (service && service.Subscriptions) setSubscriptions([
      ...service.Subscriptions.filter(subscription => subscription.limitedActivation ? subscription.limitedActivationUsed > 0 : true)
        .map(serviceSubscription => ({ ...serviceSubscription, id: serviceSubscription.Subscription.id }))
    ])
  }, [service])

  const handleExpand = useCallback((id) => {
    setSubscriptions((prevValue) => {
      const prevValueCopy = [...prevValue]
      const expandIndex = prevValueCopy.findIndex((el) => el.Subscription.id === id)
      if (expandIndex < 0) return
      prevValueCopy[expandIndex].Subscription.expand =
        prevValueCopy[expandIndex].Subscription.expand === undefined
          ? true
          : !prevValueCopy[expandIndex].Subscription.expand
      return prevValueCopy
    })
  }, [])

  return (
    <Grid container display="flex" flexDirection="column">
      <Grid item lg={12} pb={0.5}>
        <Typography variant="h6" fontWeight={600} color="primary">
          {t('checkout.selectSub')}
        </Typography>
      </Grid>
      <Grid item lg={12} pt={0.5} display="flex" flexDirection="column" justifyContent="center">
        {subscriptions.map((sub, index) => {
          return (
            <Grid pb={2} key={index} item display="flex" flexDirection="row" alignItems="center">
              <Grid item xs={2} lg={0.5} md={0.5} mr={{ lg: 2, xs: 0 }}>
                <Checkbox
                  checked={selectedSubscriptions.includes(sub.id)}
                  onChange={() => handleSelectSubscription(sub.id)}
                />
              </Grid>
              <Grid item xs={10} lg={11.5} md={11.5}>
                <Subscription
                  view
                  forServiceDetails
                  credits={sub.Subscription.inHouseCredits}
                  name={sub.Subscription.name}
                  description={sub.Subscription.description}
                  duration={sub.Subscription.duration}
                  price={sub.Subscription.priceRon}
                  Trainers={sub.Subscription.SubscriptionsTrainers.map((el) => el.Trainer)}
                  expand={sub.Subscription.expand || false}
                  handleExpand={handleExpand}
                  id={sub.Subscription.id}
                  currency={currency}
                />
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default CheckoutServiceWithSubscriptions
