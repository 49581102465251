import React from 'react'
import { Grid, Typography, Box, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { CARD_TEXT_COLOR, GRAY_BG_COLOR, GRAY_TEXT_COLOR } from '../../../../../utils/constants'

const FavouritTrainer = ({ firstName, tooltipText, border, lastName, avatarPhotoSrc, type, email, phoneNumber }) => {
  const { t } = useTranslation()

  return (
    <Grid display="flex" flexDirection="row">
      <Grid pr={{ xl: 2, lg: 1, xs: 2 }}>
        {avatarPhotoSrc ? (
          <Tooltip title={tooltipText}>
            <img
              src={avatarPhotoSrc}
              alt="avatar"
              style={{ borderRadius: 25, border: border ? border : '' }}
              height="50"
              width="50"
            />
          </Tooltip>
        ) : (
          <Tooltip title={tooltipText}>
            <Box
              sx={{
                width: 50,
                height: 50,
                backgroundColor: GRAY_BG_COLOR,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 50,
                border: border ? border : ''
              }}
            >
              <Typography variant="p" color={GRAY_TEXT_COLOR} fontWeight="bold">
                {firstName.charAt(0)} {lastName.charAt(0)}
              </Typography>
            </Box>
          </Tooltip>
        )}
      </Grid>
      <Grid display="flex" flexDirection="column" justifyContent="flex-start" pt={.8}>
        <Typography
          variant="p"
          justifyContent="flex-start"
          fontWeight={600}
          sx={{ textDecoration: 'underline' }}
        >
          {firstName} {lastName}
        </Typography>
        <Typography
          variant="subtitle2"
          color={CARD_TEXT_COLOR}
          display="flex"
          justifyContent="flex-start"
          flexDirection="row"
        >
          {!type ? t('userProfile.activity.detailComponent.favouriteTrainer.trainerAt') : `${email} / ${phoneNumber}`}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default FavouritTrainer
