import React, { useState } from 'react'
import { Grid, Card, Typography, Button, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { Pause, Delete, ContentCopy } from '@mui/icons-material'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import {
  LIGHT_GRAY_BORDER_COLOR,
  CARD_TEXT_COLOR,
  GREEN_COLOR,
  PRIMARY_COLOR,
  GRAY_TEXT_COLOR,
  ORANGE_COLOR
} from '../../../../../utils/constants'
import { getStatus } from '../../../../../utils/helpers/userSubscription'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  }
})

const Subscription = ({
  dashboard,
  gymName,
  paymentFinalDate,
  gymSubscriptionDetails,
  price,
  gymSubscriptionName,
  isTrainer,
  handleOnCancel,
  id,
  pauseEnabled,
  pauseMinimumDays,
  pauseMaximumDays,
  onPauseSubscriptionHandler,
  isDeleted,
  currency,
  ...restOfSubscription
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [expanded, setExpanded] = useState(false)
  const end = moment(restOfSubscription.validUntil, 'YYYY-MM-DD')
  const currentDate = moment(new Date(), 'YYYY-MM-DD')
  const differenceInDays = Math.ceil(moment.duration(end.diff(currentDate)).asDays())
  const userSubscriptionStatus = getStatus(restOfSubscription)

  const computePaidTitle = () => {
    if (userSubscriptionStatus.paid) {
      return (
        <Typography display="flex" fontWeight={500} color={GREEN_COLOR}>
          {t('userProfile.activity.subscriptionsComponent.subscriptionComponent.yes')}
        </Typography>
      )
    }

    return (
      <Typography display="flex" fontWeight={500} color="error">
        {t('userProfile.activity.subscriptionsComponent.subscriptionComponent.no')}
      </Typography>
    )
  }

  const computeStatus = () => {
    if (userSubscriptionStatus.paused) {
      return <Typography display="flex" justifyContent="center" fontWeight={500} color={ORANGE_COLOR}>
        {t('userProfile.paused')}
      </Typography>
    }

    if (userSubscriptionStatus.isAfter) {
      return <Typography display="flex" justifyContent="center" fontWeight={500} color="error">
        {t('userProfile.expired')}
      </Typography>
    }

    if (userSubscriptionStatus.inactive) {
      return <Typography display="flex" justifyContent="center" fontWeight={500} color="error">
        {t('userProfile.inactive')}
      </Typography>
    }

    return (
      <Typography display="flex" justifyContent="center" fontWeight={500} color={GREEN_COLOR}>
        {t('userProfile.activity.subscriptionsComponent.subscriptionComponent.active')}
      </Typography>
    )
  }


  const computeButton = () => {
    if (isDeleted) {
      return <Button fullWidth color="error" variant="text" style={{ height: 45 }}>
        <Typography fontWeight="bold">
          {t('userProfile.isDeleted')}
        </Typography>
        <Delete />
      </Button>
    }

    if (userSubscriptionStatus.isAfter) {
      return (
        <Button fullWidth disabled={dashboard || !restOfSubscription.Subscription.isAvailable} onClick={() => navigate('/checkout?subscription=' + restOfSubscription.Subscription.id)} variant="contained" style={{ height: 45 }}>
          <Typography fontWeight="bold">
            {t('userProfile.rebuy')}
          </Typography>
        </Button>
      )
    }

    if (
      !userSubscriptionStatus.paid
    ) {
      return (
        <Button disabled={dashboard || restOfSubscription.limitedActivationUsed > 0} fullWidth onClick={() => handleOnCancel(id)} variant="contained" color="error" style={{ height: 45 }}>
          <Typography fontWeight="bold">
            {t(
              'userProfile.activity.subscriptionsComponent.subscriptionComponent.cancelSubscription'
            )}
          </Typography>
        </Button>
      )
    }

    if (userSubscriptionStatus.paused) {
      return <Tooltip arrow enterTouchDelay={0} title="You cannot use this subscription during the pause">
        <Button disabled={dashboard} fullWidth variant="contained" onClick={onPauseSubscriptionHandler} style={{ height: 45, cursor: 'default' }} color="warning">
          <Pause />
          <Typography sx={{ width: '100%', display: 'flex', justifyContent: 'center', textTransform: 'none' }}>
            <b>{t('userProfile.until')}&nbsp;{moment(restOfSubscription.Pause.endDate).format('DD/MM/YYYY')}</b>
          </Typography>
        </Button>
      </Tooltip>
    }

    if (userSubscriptionStatus.paid) {
      return (
        <>
          <Button disabled={dashboard} fullWidth variant="contained" style={{ height: 45, mr: pauseEnabled ? 4 : 0, backgroundColor: GRAY_TEXT_COLOR }}>
            <Typography fontWeight="bold">
              {t(
                'userProfile.activity.subscriptionsComponent.subscriptionComponent.enjoySubscription'
              )}
            </Typography>
          </Button>
          {pauseEnabled && restOfSubscription.pauseCount === 0 ? <Button variant="outlined" color="primary" onClick={onPauseSubscriptionHandler} style={{ height: 45, marginLeft: 4 }}>
            <Pause sx={{ color: PRIMARY_COLOR }} />
          </Button> : null}
        </>
      )
    }

    return (
      <Button fullWidth disabled={dashboard || restOfSubscription.limitedActivationUsed > 0} onClick={() => handleOnCancel(id)} variant="contained" color="error" style={{ height: 45 }}>
        {t(
          'userProfile.activity.subscriptionsComponent.subscriptionComponent.cancelSubscription'
        )}
      </Button>
    )
  }

  return (
    <Grid container component={Card} className={classes.content} sx={isDeleted ? { opacity: 0.6 } : {}}>
      <Grid
        item
        xs={12}
        lg={7.5}
        p={3}
        pt={3}
        pb={3}
        borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
        borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
        display="flex"
        flexDirection={{ lg: 'row', xs: 'column' }}
      >
        <Grid item xs={12} lg={8} display="flex" alignItems="center" flexWrap="noWrap">
          <Typography variant="p" fontWeight={500} fontSize={24}>
            {gymSubscriptionName}
          </Typography>
        </Grid>
        {restOfSubscription.limitedActivationTotal ? <Grid pt={{ xs: 3, md: 3, lg: 0 }} item xs={12} lg={4} display="flex" flexWrap="noWrap" flexDirection="column">
          <Typography
            display="flex"
            justifyContent={{ lg: "flex-end", md: 'flex-start', xs: 'flex-start' }}
            style={{ textTransform: 'uppercase' }}
            color={CARD_TEXT_COLOR}
          >
            {t('userProfile.credits')}
          </Typography>
          <Typography
            display="flex"
            justifyContent={{ lg: "flex-end", md: 'flex-start', xs: 'flex-start' }}
          >
            {/* {restOfSubscription.limitedActivationUsed}/{restOfSubscription.limitedActivationTotal} TODO: I changed here in case it was modified somewhere else */}
            {restOfSubscription.limitedActivationUsed < 0 ? 0 : restOfSubscription.limitedActivationTotal - restOfSubscription.limitedActivationUsed}
          </Typography>
        </Grid> : null}
      </Grid>

      <Grid
        item
        xs={4}
        lg={1.25}
        p={3}
        pt={3}
        pb={3}
        borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
        display="flex"
        flexDirection="column"
        width="100%"
        borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
      >
        <Typography display="flex" style={{ textTransform: 'uppercase' }} color={CARD_TEXT_COLOR}>
          {t('userProfile.activity.subscriptionsComponent.subscriptionComponent.paid')}
        </Typography>
        {computePaidTitle()}
      </Grid>

      <Grid
        item
        xs={4}
        lg={2}
        p={3}
        pt={3}
        pb={3}
        borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
        display="flex"
        flexDirection="column"
        width="100%"
        borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
      >
        <Typography
          display="flex"
          justifyContent="center"
          style={{ textTransform: 'uppercase' }}
          color={CARD_TEXT_COLOR}
        >
          {t('userProfile.activity.subscriptionsComponent.subscriptionComponent.status')}
        </Typography>
        {computeStatus()}
      </Grid>

      <Grid
        item
        xs={4}
        lg={1.25}
        p={3}
        pt={3}
        pb={3}
        pr={{ xs: 2, lg: 2 }}
        display="flex"
        flexDirection="column"
        width="100%"
        borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
      >
        <Typography
          display="flex"
          justifyContent="flex-end"
          style={{ textTransform: 'uppercase' }}
          color={CARD_TEXT_COLOR}
        >
          {t('userProfile.activity.subscriptionsComponent.subscriptionComponent.expires')}
        </Typography>
        <Typography
          display="flex"
          justifyContent="flex-end"
          fontWeight={500}
          color={differenceInDays <= 5 ? 'error' : 'primary'}
        >
          {differenceInDays <= 0 ? '-' : differenceInDays}
        </Typography>
      </Grid>

      <Grid xs={12} item display="flex" flexDirection={{ xs: 'column', lg: 'row' }} width="100%">
        <Grid
          item
          xs={12}
          lg={7.5}
          p={3}
          display="flex"
          borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
          justifyContent="flex-start"
          flexWrap="noWrap"
          flexDirection="column"
        >
          <Typography pb={1} color={CARD_TEXT_COLOR}>
            {t('userProfile.activity.subscriptionsComponent.subscriptionComponent.description')}
          </Typography>
          <Typography fontSize={{ lg: 16, xs: 13 }} variant="p" fontWeight={500}>
            {expanded ? gymSubscriptionDetails : gymSubscriptionDetails.slice(0, 300)} {
              gymSubscriptionDetails.length > 300 ? <Typography fontSize={{ lg: 16, xs: 13 }} onClick={() => setExpanded(prevValue => !prevValue)} color="primary" sx={{ textDecoration: 'underline', cursor: 'pointer' }} variant="p" fontWeight={500}>
                {expanded ? t('userProfile.hide') : t('userProfile.showAll')}
              </Typography> : null
            }
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          lg={4.5}
          p={3}
          display="flex"
          justifyContent="flex-start"
          flexWrap="noWrap"
          flexDirection="column"
        >
          <Grid display="flex">
            <Grid display="flex" flex={1} flexDirection="column">
              <Typography color={CARD_TEXT_COLOR}>
                {t('userProfile.activity.subscriptionsComponent.subscriptionComponent.price')}
              </Typography>
              <Typography
                fontSize={{ xs: 14, lg: 18 }}
                fontWeight={500}
                variant="p"
                color="primary"
              >
                {price}{' '}
                {currency}
              </Typography>
            </Grid>
            <Grid
              display="flex"
              flex={3}
              justifyContent="flex-end"
              flexDirection="column"
              sx={{ cursor: 'pointer' }}
              alignItems={{ xs: 'flex-end', xl: 'flex-end', lg: 'flex-end', md: 'flex-end' }}
              onClick={() => {
                const domain = isTrainer ? restOfSubscription.Subscription.Trainer.domain : restOfSubscription.Subscription.Company.domain
                navigate('/' + domain)
              }}
            >
              <Typography justifyContent="flex-end" color={CARD_TEXT_COLOR}>
                {!isTrainer
                  ? t('userProfile.activity.subscriptionsComponent.subscriptionComponent.gym')
                  : t('userProfile.activity.subscriptionsComponent.subscriptionComponent.trainer')}
              </Typography>
              <Typography
                fontSize={{ xs: 14, lg: 18 }}
                fontWeight={600}
                variant="p"
                color="primary"
                style={{ textDecoration: 'underline', textAlign: 'end' }}
              >
                {gymName}
              </Typography>
            </Grid>
          </Grid>
          <Grid pt={3} display="flex" justifyContent="center" flexDirection="row">
            {computeButton()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Subscription
