import axios from './axios'
import queryString from 'query-string'

export const getAllUsers = (options = { role: 'TRAINER' }) => {
  const query = {}

  Object.keys(options).forEach((key) => {
    if (options[key]) {
      query[key] = options[key]
    }
  })

  return axios.get('/users/all?' + queryString.stringify({ ...query })).then((res) => res.data)
}

export const removeReview = id => {
  return axios.delete('/reviews/' + id)
    .then(res => res.data)
}

export const getUpcomingEvents = (companyId, userId) => {
  return axios.get('/events?companyId=' + companyId + (userId ? `&userId=${userId}` : ''))
    .then(res => res.data)
}

export const getUsersWithSubscription = (eventId, search) => {
  return axios.get(`/users/event/${eventId}/subscriptions?search=${search}`).then((res) => res.data)
}

export const getFollowers = (options = {}) => {
  return axios.get('/followers?' + queryString.stringify({ ...options })).then((res) => res.data)
}

export const cancelSubscription = (subscriptionId) => {
  return axios.delete(`/users/subscriptions/${subscriptionId}`).then((res) => res.data)
}

export const regenerateToken = () => {
  return axios.post('/users/regenerate-token').then((res) => res.data)
}

export const getSubscriptions = (options = {}) => {
  return axios
    .get('/users/subscriptions?' + queryString.stringify({ ...options }))
    .then((res) => res.data)
}

export const getById = id => {
  return axios.get('/users/' + id).then((res) => res.data)
}

export const getUsersWithYourSubscriptions = () => {
  return axios.get(`/users/subscriptions/measurements`).then((res) => res.data)
}

export const updateLanguage = (id, language) => {
  return axios.post('/users/' + id + '/language', { language }).then((res) => res.data)
}

export const deleteUser = (userId) => {
  return axios.delete(`/users/account?userId=${userId}`).then(res => res.data)
}

export const recoverAccount = async(userId) => {
  const req = await axios.patch('/users/recoverAccount', { userId });
  const res = await req.data;

  return res;
}