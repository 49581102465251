import { Close } from '@mui/icons-material'
import { Dialog, Box } from '@mui/material'
import { WHITE_COLOR } from '../../utils/constants'

const GalleryImageDialog = ({ onClose, image }) => {
  return (
    <Dialog open={true} onClose={onClose} maxWidth={'lg'} PaperProps={{ sx: { width: '100%' } }}>
      <Box
        container
        sx={{
          width: '100%',
          height: '80vh',
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        <Close
          onClick={onClose}
          sx={{ color: 'white', float: 'right', marginRight: 3, marginTop: 3, cursor: 'pointer' }}
        />
      </Box>
    </Dialog>
  )
}

export default GalleryImageDialog
