import React, { useState, useEffect, useMemo } from 'react'
import { Container, Grid, Typography, Button, IconButton, Menu, MenuItem, Divider, ListItemIcon, FormControl, Select, ListItemText, Box, Chip, ButtonGroup } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Add, MoreVert, Delete, EventRepeat, StackedLineChart, Edit, ArrowForward, Percent } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

import { Table, Confirm } from '../common'
import CreateModal from './CreateModal'

import { GRAY_TEXT_COLOR, GREEN_COLOR, VOUCHER_TYPES } from '../../utils/constants'
import * as VOUCHER_REQUESTS from '../../api/voucher'
import * as COMPANY_REQUESTS from '../../api/company'
import { useAuthentication } from '../../context/auth-context'

export default () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { user } = useAuthentication()

    const [subscriptionOptions, setSubscriptionOptions] = useState([])
    const [selectedSubscription, setSelectedSubscription] = useState()
    const [vouchers, setVouchers] = useState([])
    const [anchorElement, setAnchorElement] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [selectedVoucher, setSelectedVoucher] = useState()
    const [currency, setCurrency] = useState('RON')

    const getAllVouchers = () => {
        const filters = {}

        if (selectedSubscription) {
            filters.subscriptionId = selectedSubscription
        }

        return VOUCHER_REQUESTS.getAll(filters)
            .then(response => setVouchers(response))
    }

    const deleteVoucher = id => {
        return VOUCHER_REQUESTS.remove(id)
            .then(() => {
                return getAllVouchers()
            })
            .then(() => {
                toast.success(t('vouchers.successDelete'), { position: 'bottom-right' })
            })
    }

    const getCompanyCurrency = async () => {
        const currency = await COMPANY_REQUESTS.getCompanyCurrency()

        setCurrency(currency.currency)
    }

    useEffect(() => {
        getAllVouchers()
    }, [selectedSubscription])

    useEffect(() => {
        if (!user || !user.companyId) return

        getCompanyCurrency()

        COMPANY_REQUESTS.getAllSubscriptions(user.companyId)
            .then(result => {
                setSubscriptionOptions(result.map(el => ({ id: el.id, name: el.name })))
            })
    }, [user])

    const renderSubscriptionDropdown = useMemo(() => {
        return (
            <>
                <Typography variant='p' gutterBottom>
                    {t('payments.subscription')}
                </Typography>
                <FormControl fullWidth>
                    <Select
                        value={selectedSubscription}
                        onChange={e => setSelectedSubscription(e.target.value)}
                    >
                        <MenuItem value="">
                            <em>{t('vouchers.createModal.allTypes')}</em>
                        </MenuItem>
                        {subscriptionOptions.map(subscription => {
                            return <MenuItem value={subscription.id} key={subscription.id}>
                                {subscription.name}
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
            </>
        )
    }, [subscriptionOptions, selectedSubscription])

    const renderMenuItem = (voucher) => {
        return <Box>
            <IconButton onClick={event => {
                setAnchorElement(event.currentTarget);
                setSelectedVoucher(voucher)
            }}>
                <MoreVert />
            </IconButton>
            {anchorElement ? <Menu
                anchorEl={anchorElement}
                open={selectedVoucher && selectedVoucher.id === voucher.id}
                onClose={() => {
                    setAnchorElement(null)
                    setSelectedVoucher()
                }}
            >
                <MenuItem disabled>
                    {t('clients.tableHead.actions')}
                </MenuItem>
                <Divider />
                <MenuItem style={{ minWidth: 200 }} onClick={() => {
                    setShowEditModal(true)
                    setAnchorElement(null)
                }}>
                    <ListItemIcon>
                        <Edit />
                    </ListItemIcon>
                    <ListItemText>
                        {t('button.edit')}
                    </ListItemText>
                </MenuItem>
                <MenuItem style={{ minWidth: 200 }} onClick={() => {
                    setShowDeleteModal(true)
                    setAnchorElement(null)
                }}>
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    <ListItemText>
                        {t('button.remove')}
                    </ListItemText>
                </MenuItem>
            </Menu> : null}
        </Box>
    }

    const renderStatus = status => {
        if (!status || status === 'PAUSED') {
            return <Typography fontWeight="bold" color="error">{t('vouchers.PAUSED')}</Typography>
        }

        if (status === 'PRIVATE') {
            return <Typography fontWeight="bold" color="primary">{t('vouchers.PRIVATE')}</Typography>
        }

        return <Typography fontWeight="bold" color={GREEN_COLOR}>{t('vouchers.ACTIVE')}</Typography>
    }

    const renderType = type => {
        return <Chip
            label={t(`vouchers.createModal.types.${type}`)}
            variant="outlined"
            avatar={type === VOUCHER_TYPES.CONSECUTIVE_BUYS ? <StackedLineChart /> : type === VOUCHER_TYPES.DISCOUNT ? <Percent /> : <EventRepeat />}
        />
    }

    return (
        <>
            {(showDeleteModal && selectedVoucher) ? <Confirm
                isMissingInfo
                noTitle
                iconColor="error"
                Icon={Delete}
                cancelText={t('button.cancel')}
                confirmText={t('button.remove')}
                titleColor='error'
                titleText={t('button.remove')}
                description={t('vouchers.deleteText', { name: selectedVoucher.name, subscription: selectedVoucher.Subscription ? selectedVoucher.Subscription.name : '-' })}
                onCancel={() => setShowDeleteModal(false)}
                onConfirm={() => {
                    return deleteVoucher(selectedVoucher.id)
                        .finally(() => {
                            setShowDeleteModal(false)
                            setSelectedVoucher()
                        })
                }}
            /> : null}
            {showCreateModal ? <CreateModal
                onCancel={() => setShowCreateModal(false)}
                onConfirm={() => getAllVouchers().then(() => {
                    setShowCreateModal(false)
                    toast.success(t('vouchers.successCreate'), { position: 'bottom-right' })
                })}
                currency={currency}
            /> : null}
            {(showEditModal && selectedVoucher) ? <CreateModal
                payload={selectedVoucher}
                edit
                onCancel={() => setShowEditModal(false)}
                onConfirm={() => getAllVouchers().then(() => {
                    setShowEditModal(false)
                    toast.success(t('vouchers.successEdit'), { position: 'bottom-right' })
                })}
                currency={currency}
            /> : null}
            <Container maxWidth="xl">
                <Grid container spacing={2} pt={8}>
                    <Grid item xs={12} pb={1} display="flex" flexDirection="row">
                        <Grid item xs={10}>
                            <Typography variant="h5" fontWeight="bold">
                                {t('navbar.vouchers')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                            <ButtonGroup disableElevation variant="contained" >
                                <Button variant="contained" onClick={() => setShowCreateModal(true)} >
                                    <Add />  {t('vouchers.create')}
                                </Button>
                                <Button variant="outlined" onClick={() => navigate('/voucher-users')}>
                                    {t('vouchers.gotoUsers')} <ArrowForward />
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={3} pb={4} >
                        {renderSubscriptionDropdown}
                    </Grid>
                    <Grid item xs={12} pb={2}>
                        {vouchers.length ? <Table
                            dense
                            headCells={[
                                'id',
                                'name',
                                'subscriptions',
                                'status',
                                'type',
                                'discount',
                                'expiresAt',
                                'createdAt',
                                'actions'
                            ].map((key) => t(`vouchers.allRows.${key}`))}
                            rows={vouchers.map(el => {
                                return {
                                    id: <Typography>{el.id}</Typography>,
                                    name: <Typography>{el.name}</Typography>,
                                    subscriptions: <Typography fontWeight="bold" color="primary">{el.Subscription ? el.Subscription.name : '-'}</Typography>,
                                    status: renderStatus(el.status),
                                    type: renderType(el.type),
                                    discount: <Typography fontWeight="bold" color="primary">{el.discount} {el.hasFixedDiscount ? currency : '%'}</Typography>,
                                    expiresAt: <Typography>{el.expiresAt ? new Date(el.expiresAt).toISOString().split('T')[0] : '-'}</Typography>,
                                    createdAt: <Typography>{new Date(el.createdAt).toISOString().split('T')[0]}</Typography>,
                                    actions: <>{renderMenuItem(el)}</>
                                }
                            })}
                        /> : <Typography variant='p' fontWeight={500} color={GRAY_TEXT_COLOR}>{t('vouchers.noVouchers')}</Typography>}
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}