import React, { useEffect, useState } from 'react'
import { Dialog, Grid, Typography, Box, Rating, TextField, Button } from '@mui/material'

import { GRAY_BG_COLOR, GRAY_TEXT_COLOR, CARD_TEXT_COLOR } from '../../utils/constants'

import InputGenerator from './InputGenerator'

import * as TRAINER_REQUESTS from '../../api/trainer'
import * as COMPANY_REQUESTS from '../../api/company'
import { useTranslation } from 'react-i18next'

const CreateReview = ({ title, photoSrc, onCancel, fromUserProfile, onSaveHandler }) => {
  const [comment, setComment] = useState()
  const [rating, setRating] = useState(5)
  const [id, setId] = useState()
  const [type, setType] = useState('trainer')
  const [domain, setDomain] = useState('')
  const [options, setOptions] = useState([])

  const { t } = useTranslation()

  const handleGetTrainersOrClubs = async (typeToUse) => {
    let response
    if (typeToUse === 'trainer') {
      response = await TRAINER_REQUESTS.getTrainersForReview()
    } else {
      response = await COMPANY_REQUESTS.getBusinessForReview()
    }

    setOptions(
      response.map((el) => ({
        value: el.id,
        label: typeToUse === 'trainer' ? `${el.firstName} ${el.lastName}` : el.name,
        domain: el.domain
      }))
    )
  }

  useEffect(() => {
    if (fromUserProfile) {
      handleGetTrainersOrClubs(type)
    }
  }, [fromUserProfile, type])

  return (
    <Dialog open={true} maxWidth="xs">
      <Grid container p={4} pl={{ xs: 2, md: 4, lg: 4 }} pr={{ xs: 2, md: 4, lg: 4 }}>
        <Grid item xs={12}>
          <Grid container display="flex" justifyContent="center" alignItems="center">
            <Typography fontWeight="bold" variant="h5">
              {t('helper.addReview')}
            </Typography>
          </Grid>
        </Grid>
        {fromUserProfile && (
          <Grid item xs={12} pt={4}>
            <InputGenerator
              handleOnChange={(event) => setType(event.target.value)}
              input={{
                type: 'select',
                label: t('helper.type'),
                placeholder: t('helper.selectType'),
                value: type,
                values: [
                  { value: 'trainer', label: t('helper.trainer') },
                  { value: 'club', label: t('helper.club') }
                ]
              }}
            />
          </Grid>
        )}
        {fromUserProfile && (
          <Grid item xs={12} pt={4}>
            <InputGenerator
              handleOnChange={(event) => {
                setId(event.target.value)
                const optionIndex = options.findIndex((el) => el.value === event.target.value)
                if (optionIndex < 0) return

                setDomain(options[optionIndex].domain)
              }}
              input={{
                type: 'select',
                label: `${t('helper.select')} ${type[0].toUpperCase() + type.slice(1)}`,
                placeholder: `${t('helper.select')} ${type}`,
                value: id,
                values: options
              }}
            />
          </Grid>
        )}
        {!fromUserProfile && (
          <Grid item xs={12} pt={4} display="flex" justifyContent="center" alignItems="center">
            {photoSrc ? (
              <>
                <img
                  width={100}
                  height={100}
                  style={{ borderRadius: '50%', objectFit: 'cover' }}
                  src={photoSrc}
                />
              </>
            ) : (
              <>
                <Box
                  sx={{
                    width: 100,
                    height: 100,
                    backgroundColor: GRAY_BG_COLOR,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 50
                  }}
                >
                  <Typography variant="h4" color={GRAY_TEXT_COLOR} fontWeight={500}>
                    {title.charAt(0)} {title.charAt(1)}
                  </Typography>
                </Box>
              </>
            )}
          </Grid>
        )}
        {!fromUserProfile && (
          <Grid
            item
            xs={12}
            pt={2}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Typography variant="h6" fontWeight="bold">
              {title}
            </Typography>
            <Typography variant="p" color={CARD_TEXT_COLOR}>
              {t('helper.leaveRating')}{title}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} pt={4} display="flex" justifyContent="center" alignItems="center">
          <Rating
            size="large"
            value={rating}
            onChange={(_, newValue) => {
              setRating(newValue)
            }}
          />
        </Grid>
        <Grid item xs={12} pt={4} display="flex" justifyContent="center" alignItems="center">
          <TextField
            fullWidth
            multiline={true}
            rows={5}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            placeholder={t('helper.leaveComment')}
          />
        </Grid>
        <Grid item xs={12} pt={4}>
          <Grid
            container
            display="flex"
            flexDirection={{ xs: 'row', md: 'row', lg: 'row' }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              pr={{ xs: 0, md: 0, lg: 1 }}
            >
              <Button
                fullWidth
                variant="outlined"
                onClick={() => {
                  onCancel()
                }}
              >
                {t('helper.cancel')}
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              pt={{ xs: 1, md: 1, lg: 0 }}
              pl={{ xs: 0, md: 0, lg: 1 }}
            >
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  onSaveHandler(rating, comment, id, type, domain)
                }}
              >
                {t('helper.saveReview')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default CreateReview
