import React from 'react'
import { Grid, Card, Typography, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Instagram, Facebook, Done } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Linkify from 'react-linkify'

import {
  LIGHT_GRAY_BORDER_COLOR,
  CARD_TEXT_COLOR,
  GRAY_TEXT_COLOR,
  ROLE_TRAINER,
  ROLE_BUSINESS_ADMINISTRATOR,
  BUSINESS_ROLES
} from '../../../../../utils/constants'
import { useAuthentication } from '../../../../../context/auth-context'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
    cursor: 'pointer'
  }
})

const Service = ({
  name,
  description,
  interestLoading,
  interestedByLoggedUser,
  id,
  handleInterested,
  price,
  currency,
  trainerName,
  isInterested,
  isAvailable,
  trainerDomain,
  ...restOfService
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { user } = useAuthentication()
  const { t } = useTranslation()

  const isTrainer = user && user.role && user.role.toLowerCase() === ROLE_TRAINER.toLowerCase()
  const isBusinessAdministrator =
    user && user.role && user.role.toLowerCase() === ROLE_BUSINESS_ADMINISTRATOR.toLowerCase()
  const isClubAdministrator =
    user && user.role && user.role.toLowerCase() === BUSINESS_ROLES.BUSINESS_EMPLOYEE.toLowerCase()

  return (
    <Grid
      onClick={() => {
        if (!user) return
        navigate(`/services/${id}`)
      }}
      container
      component={Card}
      className={classes.content}
    >
      <Grid
        xs={12}
        item
        borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
        display="flex"
        flexDirection={{ lg: 'row', xs: 'column' }}
        width="100%"
      >
        <Grid
          lg={8}
          borderRight={{
            xs: 'none',
            lg: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
          }}
          borderBottom={{
            xs: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
            lg: 'none'
          }}
          item
          display="flex"
          alignItems="center"
          flexWrap="noWrap"
        >
          <Typography p={3} variant="h6" fontWeight={500}>
            {name}
          </Typography>
        </Grid>
        <Grid item lg={4}>
          <Grid container height="100%" display="flex" alignItems={{ xs: 'flex-start' }}>
            <Grid
              xs={4}
              lg={3}
              item
              borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
              height={'100%'}
            >
              <Grid
                item
                xs={12}
                p={2}
                display="flex"
                flexDirection="column"
                pl={{ lg: 3, xs: 3 }}
                pr={{ lg: 3, xs: 3 }}
                alignItems={{ lg: 'center', xs: 'flex-start' }}
                justifyContent="center"
                height={'100%'}
              >
                {price ? (
                  <Typography color={CARD_TEXT_COLOR}>{t('userProfile.activity.from')}</Typography>
                ) : null}
                <Typography fontWeight="bold" color={'primary'}>
                  {price ? price : 'N/A'}
                </Typography>
                {price ? (
                  <Typography color={CARD_TEXT_COLOR}>{currency}</Typography>
                ) : null}
              </Grid>
            </Grid>
            <Grid xs={8} lg={9} item p={2}>
              <Grid
                container
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
                pr={{ lg: 1, xs: 3 }}
              >
                <Typography color={CARD_TEXT_COLOR}>
                  {restOfService.fromCompany ? 'Company' : t('userProfile.activity.trainer')}
                </Typography>
                <Typography
                  sx={{ cursor: 'pointer', textDecoration: 'underline', textAlign: 'right' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    navigate(
                      restOfService.fromCompany
                        ? `/${restOfService.Company.domain}`
                        : `/${trainerDomain}`
                    )
                  }}
                  fontWeight={600}
                  color="primary"
                >
                  {restOfService.fromCompany ? restOfService.Company.name : trainerName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item display="flex" flexDirection={{ xs: 'column', lg: 'row' }} width="100%">
        <Grid lg={8} item borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
          <Grid flexWrap="noWrap" p={3} display="flex" flexDirection="column">
            <Typography color={CARD_TEXT_COLOR}>{t('userProfile.activity.description')}</Typography>
            <Linkify>
              <Typography fontWeight={500}>{description}</Typography>
            </Linkify>
            <Grid pt={2} container display="flex" flexDirection="row" alignItems="center">
              <Grid item pr={1}>
                <Typography color={CARD_TEXT_COLOR}>
                  {t('userProfile.activity.trainerProgramComponent.share')}
                </Typography>
              </Grid>
              <a href="https://instagram.com" rel="noreferrer" target="_blank">
                <Instagram color="primary" />
              </a>
              <a href="https://facebook.com" rel="noreferrer" target="_blank">
                <Facebook color="primary" />
              </a>
              <Grid item ml={'auto'}>
                <Typography variant="subtitle1" color={isAvailable ? GRAY_TEXT_COLOR : 'error'}>
                  {isAvailable
                    ? t('gymProfile.service.available')
                    : t('gymProfile.service.unavailable')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={4} p={3} pl={3} pr={3}>
          <Grid
            container
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {isInterested && (
              <Grid
                item
                width="80%"
                pb={1}
                xs={12}
                display="flex"
                justifyContent={isAvailable ? 'flex-end' : 'center'}
                alignItems="center"
                flexDirection="column"
              >
                <Button
                  onClick={(e) => {
                    e.stopPropagation()
                    handleInterested(id, interestedByLoggedUser)
                  }}
                  fullWidth
                  disabled={
                    !user ||
                    interestLoading ||
                    isTrainer ||
                    isBusinessAdministrator ||
                    isClubAdministrator
                  }
                  variant="outlined"
                >
                  {interestLoading ? (
                    'Sending data ...'
                  ) : interestedByLoggedUser ? (
                    <Done />
                  ) : (
                    t('gymProfile.service.imIntrested')
                  )}
                </Button>
              </Grid>
            )}
            {isAvailable && (
              <Grid
                item
                width="80%"
                pt={1}
                xs={12}
                display="flex"
                justifyContent={isInterested ? 'flex-start' : 'center'}
                alignItems="center"
                flexDirection="column"
              >
                <Button
                  disabled={!user || isTrainer || isBusinessAdministrator || isClubAdministrator || !restOfService.Subscriptions.length}
                  onClick={(e) => {
                    e.stopPropagation()
                    navigate(`/checkout?service=${id}`)
                  }}
                  fullWidth
                  variant="contained"
                >
                  {t('gymProfile.service.buy')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Service
