import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@mui/material'
import { useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Subscription from './subcomponents/Subscription'

import * as VOUCHER_REQUESTS from '../../../../api/voucher'

const Subscriptions = ({ subscriptions, currency }) => {
  const { t } = useTranslation()
  const { domain } = useParams()

  const [subscriptionVoucherIds, setSubscriptionVoucherIds] = useState([])

  useEffect(() => {
    if (!domain) return
    VOUCHER_REQUESTS.getSubscriptionIdsWithVouchers(domain)
      .then(response => {
        setSubscriptionVoucherIds(response)
      })
  }, [domain])

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    setTimeout(() => {
      if (searchParams && searchParams.get('subscriptionId')) {
        const subId = searchParams.get('subscriptionId')
        const relatedSubEl = document.getElementById(`subscription-${subId}`)
        if (relatedSubEl) {
          relatedSubEl.scrollIntoView()
        }
      }
    }, 1000);
  }, [searchParams])

  return (
    <Grid container display="flex" p={3} pt={5}>
      <Grid container>
        {subscriptions.filter((el) => el !== null).length > 0 ? (
          subscriptions
            .filter((el) => el !== null)
            .map((subscription, index) => {
              return (
                <Grid key={index} xs={12} item pt={2} pb={2}>
                  <Subscription
                    {...subscription}
                    hasVoucher={subscriptionVoucherIds.includes(subscription.id)}
                    id={subscription.id}
                    Trainers={subscription.SubscriptionsTrainers.map((el) => el.Trainer)}
                    name={`${subscription.name}`}
                    description={subscription.description}
                    price={subscription.priceRon}
                    currency={currency}
                    credits={subscription.inHouseCredits}
                    limitedActivation={subscription.limitedActivation}
                  />
                </Grid>
              )
            })
        ) : (
          <Typography pl={1} color="#979797" fontWeight={500} fontSize={17}>
            {t('helper.noResultsFound')}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default Subscriptions
