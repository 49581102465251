import React, { useEffect, useState, useMemo } from 'react'
import { Grid, Container } from '@mui/material'
import { Navigate, useParams, useNavigate } from 'react-router-dom'

import BusinessActivity from './subcomponents/BusinessActivity'
import BusinessDetails from './subcomponents/BusinessDetails'
import { Footer } from '../common'

import * as COMPANY_REQUESTS from '../../api/company'
import * as FOLLOWER_REQUESTS from '../../api/follower'
import { refetchPublicBusinessProfileObservable } from '../../utils/observables'

const BusinessProfile = () => {
  const refetchProfile = refetchPublicBusinessProfileObservable.useRefetchPublicBusinessProfile()
  const { domain } = useParams()
  const navigate = useNavigate()

  const [company, setCompany] = useState()
  const [isFollowing, setIsFollowing] = useState(false)
  const [goToReview, setGoToReview] = useState(false)

  const getCompany = (domain) => COMPANY_REQUESTS.getBusinessByDomain(domain).then(setCompany)

  useEffect(() => {
    if (!refetchProfile) return
    getCompany(domain)
    FOLLOWER_REQUESTS.isFollowingCompany(domain)
      .then((res) => {
        setIsFollowing(!!res)
        refetchPublicBusinessProfileObservable.setRefetchPublicBusinessProfile(false)
      })
      .catch(() => {
        setIsFollowing(false)
      })
  }, [domain, refetchProfile])

  const renderBusinessDetails = useMemo(() => {
    return (
      <BusinessDetails
        company={company}
        onEditCloseHandler={getCompany}
        isFollowing={isFollowing}
        handleGoToReview={() => {
          setGoToReview(true)
          navigate(`/${domain}?active_option=3`)
        }}
      />
    )
  }, [company, isFollowing, domain])

  const renderBusinessActivity = useMemo(() => {
    return (
      <BusinessActivity
        goToReview={goToReview}
        resetGoToReview={() => setGoToReview(false)}
        company={company}
      />
    )
  }, [company, goToReview, isFollowing])

  if (!company) return

  return (
    <Container maxWidth="xl">
      <Grid container pt={5} display="flex" flexDirection="row" width="100%">
        <Grid item lg={3} xs={12} md={12} sm={12} p={2} pr={{ xs: 0, lg: 2 }} pl={{ xs: 0 }}>
          {renderBusinessDetails}
        </Grid>
        <Grid item lg={9} xs={12} md={12} sm={12} p={2} pr={{ xs: 0 }} pl={{ xs: 0 }}>
          {renderBusinessActivity}
        </Grid>
      </Grid>
    </Container>
  )
}

export default BusinessProfile
