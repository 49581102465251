import React, { useEffect, useState, useMemo } from 'react'
import { Container, Grid, Typography, CircularProgress, Card, Avatar, TextField, Box, Chip, Button, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ArrowLeft, ExpandMore, ExpandLess } from '@mui/icons-material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'

import { VerticalBarChart, Table } from '../../../common'

import * as REPORTS_REQUESTS from '../../../../api/reports'
import * as COMPANY_REQUESTS from '../../../../api/company'
import { useAuthentication } from '../../../../context/auth-context'
import { numberFomatter } from '../../../../utils/helpers/numberFomatter'
import { GRAY_TEXT_COLOR, COMPANIES_TRAINERS_REQUEST_STATUS, DATE_TIME_FORMAT } from '../../../../utils/constants'

const Filters = ({ onStartChange, onEndChange, startValue, endValue }) => {
    const { t } = useTranslation()

    return (
        <Grid container display="flex" flexDirection="row" alignItems="center" spacing={1} pt={1}>
            <Grid item lg={3} xs={6} display="flex" flexDirection="column">
                <Typography variant="p" fontWeight={500} gutterBottom>{t('reports.revenue.startTime')}</Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                        inputFormat="dd/MM/yyyy"
                        value={moment(startValue)}
                        onChange={(newValue) => {
                            if (Date.parse(newValue)) {
                                onStartChange(moment(new Date(newValue).toISOString().split('T')[0]))
                            }
                        }}
                        renderInput={(params) => <TextField
                            fullWidth
                            sx={{
                                '.MuiIconButton-root': { marginRight: 1 }
                            }}
                            {...params}
                        />}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item lg={3} xs={6} display="flex" flexDirection="column">
                <Typography variant="p" fontWeight={500} gutterBottom>{t('reports.revenue.endTime')}</Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                        inputFormat="dd/MM/yyyy"
                        value={moment(endValue)}
                        onChange={(newValue) => {
                            if (Date.parse(newValue)) {
                                onEndChange(moment(new Date(newValue).toISOString().split('T')[0]))
                            }
                        }}
                        renderInput={(params) => <TextField
                            fullWidth
                            sx={{
                                '.MuiIconButton-root': { marginRight: 1 }
                            }}
                            {...params}
                        />}
                    />
                </LocalizationProvider>
            </Grid>
        </Grid>
    )
}

const TrainerTable = ({ trainer, rows }) => {
    const { t } = useTranslation()
    const [showClassDetails, setShowClassDetails] = useState(false)

    const data = {
        totalBookedParticipants: 0,
        totalWaitingListParticipants: 0,
        totalConfirmedParticipants: 0,
        totalWaitingListParticipants: 0,
        averagePresence: 0
    }

    rows.forEach(row => {
        data.totalBookedParticipants += row.participantsNumber
        data.totalConfirmedParticipants += row.confirmedParticipants
        data.totalWaitingListParticipants += row.waitingListParticipants
        if (row.participantsNumber > 0) {
            data.averagePresence += row.presence
        }
    })

    if (rows.length !== 0) {
        const rowsCount = rows.filter(el => el.participantsNumber > 0).length
        if (rowsCount === 0) data.averagePresence = 0
        else data.averagePresence = numberFomatter(data.averagePresence / rowsCount)
    } else {
        data.averagePresence = 0
    }

    return <Grid container spacing={1}>
        <Grid item xs={12} display="flex" flexDirection="row" alignItems="center">
            <Avatar src={trainer.avatarPhotoSrc}>{trainer.firstName[0]}{trainer.lastName[0]}</Avatar> <Typography pl={1} variant="p" fontWeight="bold">{trainer.firstName + ' ' + trainer.lastName}</Typography>
            {rows.length ? <IconButton onClick={() => setShowClassDetails(!showClassDetails)}>
                {!showClassDetails ? <ExpandMore /> : <ExpandLess />}
            </IconButton> : null}
        </Grid>
        {rows.length && showClassDetails ? <Grid item xs={12}>
            <Table
                dense
                headCells={[
                    t('reports.trainers.cells.event'),
                    t('reports.trainers.cells.date'),
                    t('reports.trainers.cells.participants'),
                    t('reports.trainers.cells.confirmedParticipants'),
                    t('reports.trainers.cells.waitingListParticipants'),
                    t('reports.trainers.cells.presence'),
                ]}
                rows={rows.map(el => ({ ...el, presence: numberFomatter(el.presence) }))}
            />
        </Grid> : null}
        <Grid item xs={12}>
            <Grid container spacing={1}>
                <Grid item>
                    <Chip color="primary" label={`${t('reports.trainers.cells.participants')} ${data.totalBookedParticipants}`} />
                </Grid>
                <Grid item>
                    <Chip color="primary" label={`${t('reports.trainers.cells.confirmedParticipants')} ${data.totalConfirmedParticipants}`} />
                </Grid>
                <Grid item>
                    <Chip color="primary" label={`${t('reports.trainers.cells.waitingListParticipants')} ${data.totalWaitingListParticipants}`} />
                </Grid>
                <Grid item>
                    <Chip color="primary" label={`${t('reports.trainers.averagePresence')} ${data.averagePresence}%`} />
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}

export default () => {
    const { t } = useTranslation()
    const { user } = useAuthentication()
    const navigate = useNavigate()

    const [reportsTrainers, setReportsTrainers] = useState({})
    const [loading, setLoading] = useState(false)
    const [start, setStart] = useState()
    const [end, setEnd] = useState()
    const [trainers, setTrainers] = useState([])
    const [objectTrainers, setObjectTrainers] = useState()
    const [selectedTrainerId, setSelectedTrainerId] = useState()

    useEffect(() => {
        const currentDate = new Date()
        const year = currentDate.getFullYear()
        const month = currentDate.getMonth()
        const firstDay = new Date(year, month, 1)
        const lastDay = new Date(year, month + 1, 0)

        setStart(firstDay.toISOString().split('T')[0])
        setEnd(lastDay.toISOString().split('T')[0])
    }, [])


    useEffect(() => {
        COMPANY_REQUESTS.getAllMembers(user.companyId)
            .then(response => {
                const assignedTrainers = response.filter(el => el.status === COMPANIES_TRAINERS_REQUEST_STATUS.APPROVED)
                const objTrainers = {}

                setTrainers(assignedTrainers)

                assignedTrainers.forEach(trainer => {
                    objTrainers[trainer.id] = { ...trainer }
                })
                setObjectTrainers(objTrainers)
            })
    }, [user])

    useEffect(() => {
        REPORTS_REQUESTS.getTrainerReports({
            start: moment(start).startOf('day').toISOString().split('T')[0],
            end: moment(end).startOf('day').toISOString().split('T')[0]
        })
            .then(result => setReportsTrainers(result))
    }, [user, start, end])

    useEffect(() => {
        if (!start || !end) return

    }, [user, start, end])

    const totalConfirmed = useMemo(() => {
        const data = {}

        if (!objectTrainers) return {}

        Object.keys(reportsTrainers).forEach(trainerId => {
            if (trainerId !== 'events') {
                data[trainerId] = {
                    checkinsCount: reportsTrainers[trainerId].checkins.length,
                    participantsCount: reportsTrainers[trainerId].participants.length,
                    waitingListParticipantsCount: reportsTrainers[trainerId].waitingListParticipants.length,
                    trainer: objectTrainers[trainerId]
                }
            }
        })

        return data
    }, [reportsTrainers, objectTrainers])

    const detailedTrainerData = useMemo(() => {
        if (!objectTrainers) return null

        const data = {}

        Object.keys(reportsTrainers).forEach(reportTrainerId => {
            if (reportTrainerId !== 'events') {
                data[reportTrainerId] = { eventsData: [], trainer: { ...objectTrainers[reportTrainerId] } }

                const mappedEventsData = Object.keys(reportsTrainers[reportTrainerId].events)
                    .map(eventId => {

                        return {
                            ...reportsTrainers[reportTrainerId].events[eventId],
                            event: { ...reportsTrainers.events[eventId] }
                        }
                    })

                data[reportTrainerId].eventsData = [...mappedEventsData]
            }
        })

        return data

    }, [reportsTrainers, objectTrainers])

    if (loading) {
        return <Container maxWidth="xl">
            <Grid container pt={4}>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Button variant="outlined" onClick={() => navigate('/reports')}><ArrowLeft /> {t('button.back')}</Button>
                        <Typography variant="h6" fontWeight={'bold'} pl={2}>
                            {t('reports.trainers.ReportsTrainers')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} display="flex" flexDirection="row" aligItems="center" justifyContent="center">
                    <CircularProgress />
                </Grid>

            </Grid>
        </Container>
    }

    return (
        <Container maxWidth="xl">
            <Grid container pt={4} pb={5} spacing={3}>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} pb={5}>
                        <Button variant="outlined" onClick={() => navigate('/reports')}><ArrowLeft /> {t('button.back')}</Button>
                        <Typography variant="h6" fontWeight={'bold'} pl={2}>
                            {t('reports.trainers.ReportsTrainers')}
                        </Typography>
                    </Box>

                    <Filters
                        endValue={end}
                        startValue={start}
                        onEndChange={setEnd}
                        onStartChange={setStart}
                    />
                </Grid>

                <Grid item xs={12} lg={6} mt={5}>
                    <Typography variant="p" fontWeight="bold">{t('reports.trainers.clientiConfirmedPerTrainer')}</Typography>
                    <Grid container spacing={1} pt={1}>
                        <Grid item xs={12} lg={12}>
                            <VerticalBarChart
                                style={{ height: 300 }}
                                horizontal
                                maintainAspectRatio={false} data={{
                                    labels: Object.keys(totalConfirmed).map(el => totalConfirmed[el].trainer.firstName + ' ' + totalConfirmed[el].trainer.lastName),
                                    datasets: [
                                        {
                                            borderColor: '#ffffff',
                                            label: t('reports.trainers.presenceClienti'),
                                            data: Object.keys(totalConfirmed).map(el => totalConfirmed[el].checkinsCount),
                                            backgroundColor: 'rgba(33, 150, 243, 1)',
                                        }
                                    ]
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} lg={6} mt={5}>
                    <Typography variant="p" fontWeight="bold">{t('reports.trainers.confirmedPerTrainer')}</Typography>
                    <Grid container spacing={1} pt={1}>
                        <Grid item xs={12}>
                            <VerticalBarChart
                                style={{ height: 300 }}
                                horizontal
                                maintainAspectRatio={false} data={{
                                    labels: Object.keys(totalConfirmed).map(el => totalConfirmed[el].trainer.firstName + ' ' + totalConfirmed[el].trainer.lastName),
                                    datasets: [
                                        {
                                            borderColor: '#ffffff',
                                            label: t('reports.trainers.books'),
                                            data: Object.keys(totalConfirmed).map(el => totalConfirmed[el].participantsCount),
                                            backgroundColor: 'rgba(33, 150, 243, 1)',
                                        }
                                    ]
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} mt={5}>
                    <Typography variant="p" fontWeight="bold">{t('reports.trainers.percentagePresence')}</Typography>
                    <Grid container spacing={1} pt={1}>
                        <Grid item xs={12}>
                            <VerticalBarChart
                                horizontal
                                style={{ height: 300 }}
                                maintainAspectRatio={false} data={{
                                    labels: Object.keys(totalConfirmed).map(el => totalConfirmed[el].trainer.firstName + ' ' + totalConfirmed[el].trainer.lastName),
                                    datasets: [
                                        {
                                            borderColor: '#ffffff',
                                            label: t('reports.trainers.percentagePresencePerBooked'),
                                            data: Object.keys(totalConfirmed).map(el => totalConfirmed[el].checkinsCount / (totalConfirmed[el].participantsCount || 1)),
                                            backgroundColor: 'rgba(33, 150, 243, 1)',
                                        }
                                    ]
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} mt={5}>
                    {detailedTrainerData ? <Grid container spacing={3}>
                        {Object.values(detailedTrainerData).map(trainerData => {
                            const rows = trainerData.eventsData.map(data => {

                                const confirmedParticipants = Number(data.checkins.length)
                                const participantsNumber = Number(data.participants.length)

                                let percentage = 0

                                if (confirmedParticipants && participantsNumber) {
                                    percentage = Number(confirmedParticipants / participantsNumber) * 100
                                }

                                return {
                                    event: data.event.name,
                                    date: moment(data.event.start).subtract(2, 'hours').format(DATE_TIME_FORMAT),
                                    participantsNumber,
                                    confirmedParticipants,
                                    waitingListParticipants: data.waitingListParticipants.length,
                                    presence: percentage
                                }
                            }).sort((a, b) => moment(a.date).subtract(moment(b.date)))

                            return <Grid item xs={12} key={trainerData.trainer.id} component={Card} p={2} mt={2}>
                                <TrainerTable
                                    trainer={trainerData.trainer}
                                    rows={rows}
                                />
                            </Grid>

                        })}
                    </Grid> : null}
                </Grid>
            </Grid>
        </Container>
    )
}