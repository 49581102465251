import { CALENDAR_SERVICES_TYPES, SPORTTYPES_TYPES } from '../constants'

export const getBackgroundImage = (option) => {
    if (!option || option === CALENDAR_SERVICES_TYPES.OTHERS) {
        return require('../../assets/club_bg.jpeg')
    }
    if (option === SPORTTYPES_TYPES.MEDITATION) {
        return require('../../assets/sportTypes/Meditation.png')
    }
    if (option === SPORTTYPES_TYPES.POSTURAL_ALIGNMENT) {
        return require('../../assets/sportTypes/postural.jpg')
    }
    if (option === SPORTTYPES_TYPES.HYPOXI_VACUUM_FITNESS) {
        return require('../../assets/sportTypes/HypoxiVacuumFitness.jpg')
    }
    if (option === SPORTTYPES_TYPES.ACTIVE_RECOVERY) {
        return require('../../assets/sportTypes/ActiveRecovery.jpeg')
    }
    if (option === SPORTTYPES_TYPES.BALET) {
        return require('../../assets/sportTypes/Balet.jpg')
    }
    if (option === SPORTTYPES_TYPES.AEROBIC) {
        return require('../../assets/sportTypes/Aerobic-min.png')
    }
    if (option === SPORTTYPES_TYPES.AEROBIC_STEP) {
        return require('../../assets/sportTypes/Aerobic-Step.jpg')
    }
    if (option === SPORTTYPES_TYPES.ANIMAL) {
        return require('../../assets/sportTypes/animal.jpeg')
    }
    if (option === SPORTTYPES_TYPES.PREGNANT_TRAINING) {
        return require('../../assets/sportTypes/PregnantTraining.JPG')
    }
    if (option === SPORTTYPES_TYPES.STRENGTH_CONDITION) {
        return require('../../assets/sportTypes/StrenghtAndCondition.jpeg')
    }
    if (option === SPORTTYPES_TYPES.TRAIL_RUNNING) {
        return require('../../assets/sportTypes/TrailRunning.png')
    }
    if (option === SPORTTYPES_TYPES.YOGA) {
        return require('../../assets/sportTypes/Yoga-min.png')
    }
    if (option === SPORTTYPES_TYPES.PILATES) {
        return require('../../assets/sportTypes/Pilates-min.png')
    }
    if (option === SPORTTYPES_TYPES.CIRCUIT_TRAINING) {
        return require('../../assets/sportTypes/CircuitTraining-min.png')
    }
    if (option === SPORTTYPES_TYPES.BOXING) {
        return require('../../assets/sportTypes/Boxing.jpeg')
    }
    if (option === SPORTTYPES_TYPES.KICKBOXING) {
        return require('../../assets/sportTypes/Kickboxing-min.jpeg')
    }
    if (option === SPORTTYPES_TYPES.MARTIAL_ARTS) {
        return require('../../assets/sportTypes/martialArt.jpeg')
    }
    if (option === SPORTTYPES_TYPES.POLE_FITNESS) {
        return require('../../assets/sportTypes/PoleFitness.png')
    }
    if (option === SPORTTYPES_TYPES.BODYBUILDING) {
        return require('../../assets/sportTypes/Bodybuilding-min.JPG')
    }
    if (option === SPORTTYPES_TYPES.INTERVAL_TRAINING) {
        return require('../../assets/sportTypes/IntervalTraining-min.JPG')
    }
    if (option === SPORTTYPES_TYPES.WEIGHT_TRAINING) {
        return require('../../assets/sportTypes/WeightTraining-min.jpeg')
    }
    if (option === SPORTTYPES_TYPES.CROSSFIT) {
        return require('../../assets/sportTypes/Crossfit-min.png')
    }
    if (option === SPORTTYPES_TYPES.AERIAL_FITNESS) {
        return require('../../assets/sportTypes/AerialYoga.png')
    }
    if (option === SPORTTYPES_TYPES.GYMNASTICS) {
        return require('../../assets/sportTypes/Gymnastics-min.JPG')
    }
    if (option === SPORTTYPES_TYPES.SPARTAN_RACE) {
        return require('../../assets/sportTypes/SpartanRage.png')
    }
    if (option === SPORTTYPES_TYPES.FOOTBALL) {
        return require('../../assets/sportTypes/fb.png')
    }
    if (option === SPORTTYPES_TYPES.TENNIS) {
        return require('../../assets/sportTypes/Tenis.png')
    }
    if (option === SPORTTYPES_TYPES.RUNNING) {
        return require('../../assets/sportTypes/Running-min.jpg')
    }
    if (option === SPORTTYPES_TYPES.PHYSIOTHERAPY) {
        return require('../../assets/sportTypes/Physiotherapy-min.jpeg')
    }
    if (option === SPORTTYPES_TYPES.KINESIOTHERAPY) {
        return require('../../assets/sportTypes/Kinesiotherapy-min.JPG')
    }
    if (option === SPORTTYPES_TYPES.PERSONAL_TRAINING) {
        return require('../../assets/sportTypes/PersonalTraining-min.png')
    }
    if (option === SPORTTYPES_TYPES.MULTILEVEL_GROUP_TRAINING) {
        return require('../../assets/sportTypes/MultilevelGroupTraining-min.png')
    }
    if (option === SPORTTYPES_TYPES.BOOTCAMP) {
        return require('../../assets/sportTypes/Bootcamp-min.jpeg')
    }
    if (option === SPORTTYPES_TYPES.ZUMBA) {
        return require('../../assets/sportTypes/Zumba.png')
    }
    if (option === SPORTTYPES_TYPES.DANCE) {
        return require('../../assets/sportTypes/Dance-min.png')
    }
    if (option === SPORTTYPES_TYPES.WORKSHOP) {
        return require('../../assets/sportTypes/workshop.tt.jpeg')
    }
    if (option === SPORTTYPES_TYPES.NUTRITION) {
        return require('../../assets/sportTypes/Nutrition.png')
    }
    if (option === SPORTTYPES_TYPES.INDOOR_CYCLING) {
        return require('../../assets/sportTypes/ICycling.png')
    }
    if (option === SPORTTYPES_TYPES.KANGOO_JUMP) {
        return require('../../assets/sportTypes/kangoo.jpeg')
    }
    if (option === SPORTTYPES_TYPES.EMS_TRAINING) {
        return require('../../assets/sportTypes/EMSFitness-min.png')
    }
    if (option === SPORTTYPES_TYPES.CALISTHENICS) {
        return require('../../assets/sportTypes/Calisthenics-min.jpeg')
    }
    if (option === SPORTTYPES_TYPES.CYCLING) {
        return require('../../assets/sportTypes/CyclingOutdoor-min.jpeg')
    }
    if (option === SPORTTYPES_TYPES.HIKING) {
        return require('../../assets/sportTypes/Hiking.png')
    }
    if (option === SPORTTYPES_TYPES.HORSE_RIDING) {
        return require('../../assets/sportTypes/HorseRiding-min.png')
    }
    if (option === SPORTTYPES_TYPES.HOME_CYCLING) {
        return require('../../assets/sportTypes/cycling.png')
    }
    if (option === SPORTTYPES_TYPES.VOLLEYBALL) {
        return require('../../assets/sportTypes/Voleyball.png')
    }
    if (option === SPORTTYPES_TYPES.SWIMMING) {
        return require('../../assets/sportTypes/swimming.png')
    }
    if (option === SPORTTYPES_TYPES.SUSPENSION_TRAINING) {
        return require('../../assets/sportTypes/SuspensionTraining.jpg')
    }
    if (option === CALENDAR_SERVICES_TYPES.FOTBAL) {
        return require('../../assets/sportTypes/fb.png')
    }
    if (option === CALENDAR_SERVICES_TYPES.HIKING) {
        return require('../../assets/sportTypes/Hiking.png')
    }
    if (option === CALENDAR_SERVICES_TYPES.KARTING) {
        return require('../../assets/sportTypes/Karting.png')
    }
    if (option === CALENDAR_SERVICES_TYPES.MASSAGE) {
        return require('../../assets/sportTypes/Massage-min.png')
    }
    if (option === CALENDAR_SERVICES_TYPES.ONLINE) {
        return require('../../assets/sportTypes/OnlineTraining.png')
    }
    if (option === CALENDAR_SERVICES_TYPES.PAINTBALL) {
        return require('../../assets/sportTypes/Paintball.png')
    }
    if (option === CALENDAR_SERVICES_TYPES.SQUASH) {
        return require('../../assets/sportTypes/Squash.png')
    }
    if (option === CALENDAR_SERVICES_TYPES.BASKETBALL) {
        return require('../../assets/sportTypes/Basketball.png')
    }
    if (option === CALENDAR_SERVICES_TYPES.PADEL) {
        return require('../../assets/sportTypes/Padel.png')
    }
    if (option === CALENDAR_SERVICES_TYPES.SNOOKER) {
        return require('../../assets/sportTypes/Snooker.png')
    }
    if (option === CALENDAR_SERVICES_TYPES.BOWLING) {
        return require('../../assets/sportTypes/Bowling.png')
    }
    if (option === CALENDAR_SERVICES_TYPES.TENNIS_CLAY) {
        return require('../../assets/sportTypes/Tennis-min.png')
    }
    if (option === CALENDAR_SERVICES_TYPES.TENNIS_GRASS) {
        return require('../../assets/sportTypes/TennisCourt.png')
    }
    if (option === CALENDAR_SERVICES_TYPES.TENNIS_TABLE) {
        return require('../../assets/sportTypes/TennisTable.png')
    }
    if (option === CALENDAR_SERVICES_TYPES.DIETICIAN) {
        return require('../../assets/sportTypes/Dietician-min.png')
    }
    if (option === CALENDAR_SERVICES_TYPES.HORSE) {
        return require('../../assets/sportTypes/HorseRidding.png')
    }
    if (option === CALENDAR_SERVICES_TYPES.PHYSIO) {
        return require('../../assets/sportTypes/Physiotherapist-min.png')
    }
    if (option === CALENDAR_SERVICES_TYPES.SHOOTING) {
        return require('../../assets/sportTypes/ShootingRange-min.png')
    }

    return require('../../assets/club_bg.jpeg')
}