import React, { createContext, useState, useContext } from 'react'

const contextDefaultValues = {
  error: '',
  handleError: () => {}
}

export const ErrorContext = createContext(contextDefaultValues)

const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(contextDefaultValues.error)

  const handleError = (hasError) => setError(hasError)

  return (
    <ErrorContext.Provider
      value={{
        error,
        handleError
      }}
    >
      {children}
    </ErrorContext.Provider>
  )
}

export const useError = () => useContext(ErrorContext)

export default ErrorProvider
