import { Chip, TableCell } from "@mui/material";

const bookedStyle = { width: '100%', backgroundColor: '#E5EFFF', border: '0.1px solid #0664F2', fontSize: '1rem', fontWeight: 'bold', color: '#0664F2' }

const ActionCell = ({ action }) => (
    <TableCell sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '148px', border: 'none !important' }}>
        <Chip label={action} sx={bookedStyle} />
    </TableCell>
);

export default ActionCell;