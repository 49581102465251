import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const options = {
  responsive: true,
  plugins: {
    title: {
      display: false
    },
    datasets: {
      display: false
    },
    legend: {
      display: false
    }
  },
  maintainAspectRatio: true,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  }
}

const LineChart = ({ data, maintainAspectRatio = false }) => {
  return <Line options={{ ...options, maintainAspectRatio }} data={data} />
}

export default LineChart
