import axios from './axios'

export const getTrainerNotifications = () => {
  return axios.get('/trainers/notifications').then((res) => res.data)
}

export const getUserNotifications = () => {
  return axios.get('/users/notifications').then((res) => res.data)
}

export const getCompanyNotifications = (companyId) => {
  return axios.get('/companies/notifications/' + companyId).then((res) => res.data)
}

export const readNotification = (id) => {
  return axios.put(`/notifications/${id}`).then((res) => res.data)
}
