import queryString from 'query-string'

import axios from './axios'

export const getCompanies = (options = {}) => {
  const query = {}

  Object.keys(options).forEach((key) => {
    if (options[key] !== null && options[key] !== undefined) {
      query[key] = options[key]
    }
  })
  return axios
    .get('/public/explore/mobile/clubs?' + queryString.stringify({ ...query }))
    .then((res) => res.data)
}

export const getTrainers = (options = {}) => {
  const query = {}

  Object.keys(options).forEach((key) => {
    if (options[key] !== null && options[key] !== undefined) {
      query[key] = options[key]
    }
  })
  return axios
    .get('/public/explore/trainers?' + queryString.stringify({ ...query }))
    .then((res) => res.data)
}

export const getServices = (options = {}) => {
  const query = {}

  Object.keys(options).forEach((key) => {
    if (options[key] !== null && options[key] !== undefined) {
      query[key] = options[key]
    }
  })
  return axios
    .get('/public/explore/services?' + queryString.stringify({ ...query }))
    .then((res) => {
      return res.data
    })
}

export const getEvents = (options = {}) => {
  const query = {}

  Object.keys(options).forEach((key) => {
    if (options[key] !== null && options[key] !== undefined) {
      query[key] = options[key]
    }
  })
  return axios
    .get('/public/explore/events?' + queryString.stringify({ ...query }))
    .then((res) => {
      return res.data
    })
}
