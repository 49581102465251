import React, { useMemo } from 'react'
import { CircularProgress, Grid, Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import { CalendarDay } from '../subcomponents'
import { useTranslation } from 'react-i18next'

const CalendarDays = ({ days, loadingEvents, loadingServices, loadingItems, loadingBookUnbook, showPastEvents, handleEventDetails, handleBookEventWithOneSubscription, handleOpenSubscriptionSelector, handleOpenPayment, handleOpenWaitingList, handleBook, handleUnbook }) => {
  const theme = useTheme()
  const matchesPhone = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()

  const renderDays = useMemo(() => {
    return (
      <Grid container
        display="flex"
        flexDirection="row"
        maxWidth={{ xs: '99vw', lg: '100%' }}
        minHeight={150}
        overflow={'auto'}
        whiteSpace={'nowrap'}
        flexWrap={'nowrap'}
        pt={3}>
        {loadingItems ? <Grid item display="flex" flexDirection="row" alignItems="center" justifyContent="center" lg={4} md={4} sm={4} xs={4} minWidth={'100%'}>
          <CircularProgress />
          <Typography sx={{ paddingLeft: 3 }}>{loadingEvents ? t('calendar.loadingEvents') : loadingServices ? t('calendar.loadingServices') : 'Loading ...'}</Typography>
        </Grid> : days.map((day, index) => (
          <Grid item lg={4} md={4} sm={4} xs={4} key={index} minWidth={matchesPhone ? '99vw' : 420}>
            <CalendarDay
              loadingBookUnbook={loadingBookUnbook}
              showPastEvents={showPastEvents}
              handleOpenWaitingList={handleOpenWaitingList}
              handleBook={handleBook}
              handleOpenPayment={handleOpenPayment}
              handleUnbook={handleUnbook}
              handleEventDetails={handleEventDetails}
              handleOpenSubscriptionSelector={handleOpenSubscriptionSelector}
              handleBookEventWithOneSubscription={handleBookEventWithOneSubscription}
              dayName={day.dayName}
              dayDate={day.dayDate}
              dayItems={day.dayItems} />
          </Grid>
        ))
        }
      </Grid >
    )
  }, [days, loadingBookUnbook, loadingServices, loadingEvents, loadingItems, matchesPhone])

  return renderDays
}

export default CalendarDays
