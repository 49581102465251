import React, { useEffect, useState } from 'react'
import { Typography, Grid, Card, Box, IconButton, Popper, Fade, Paper, TextField, Button, } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment'
import { DateRange } from '@mui/icons-material';

import { useAuthentication } from '../../../context/auth-context'
import { ROLE_BUSINESS_ADMINISTRATOR, GRAY_TEXT_COLOR, GREEN_COLOR } from '../../../utils/constants'
import * as DASHBOARD_REQUESTS from '../../../api/dashboard'
import { Table } from '../../common'

export default () => {
    const { t } = useTranslation()
    const { user } = useAuthentication()

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()
    const [slice, setSlice] = useState(1)
    const [anchorEl, setAnchorEl] = useState(null)
    const [open, setOpen] = useState(false)
    const [placement, setPlacement] = useState()
    const [start, setStart] = useState(moment().startOf('month'))
    const [end, setEnd] = useState(moment().endOf('month'))

    useEffect(() => {
        if (!user || !user.role === ROLE_BUSINESS_ADMINISTRATOR) return

        DASHBOARD_REQUESTS.getReportCompanyTrainersCreditsServices({
            start: moment(start).toISOString().split('T')[0],
            end: moment(end).toISOString().split('T')[0]
        })
            .then(response => {
                setData(response)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [user, start, end])


    const handleOpenPopper = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget)
        setOpen((prev) => placement !== newPlacement || !prev)
        setPlacement(newPlacement)
    }

    if (!data || loading) return

    return (
        <>
            <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <Box p={2} border={`1px solid rgba(0,0,0,0.1)`}>
                                <Typography pb={2} variant="subtitle1">{t('reports.selectTime')}</Typography>
                                <Box display="flex" flexDirection="row">
                                    <Box pr={1}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                inputFormat="MM/dd/yyyy"
                                                value={start}
                                                onChange={(newValue) => setStart(newValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        sx={{
                                                            '.MuiIconButton-root': { marginRight: 1 }
                                                        }}
                                                        {...params}
                                                    />
                                                )} />
                                        </LocalizationProvider>
                                    </Box>
                                    <Box pl={1}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                inputFormat="MM/dd/yyyy"
                                                value={end}
                                                onChange={(newValue) => setEnd(newValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        sx={{
                                                            '.MuiIconButton-root': { marginRight: 1 }
                                                        }}
                                                        {...params}
                                                    />
                                                )} />
                                        </LocalizationProvider>
                                    </Box>
                                </Box>
                                <Box pt={2}>
                                    <Button onClick={() => {
                                        setAnchorEl()
                                        setOpen(false)
                                    }} variant="outlined" color="primary">Save</Button>
                                </Box>
                            </Box>
                        </Paper>
                    </Fade>
                )}
            </Popper>
            <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column">
                        <Typography variant="p" fontWeight={'bold'} fontSize={20} pl={1}>
                            {t('reports.trainersCreditsServices')}
                        </Typography>
                        <Typography variant="p" pb={1} color={GRAY_TEXT_COLOR} pl={1}>{moment(start).format('MM/DD/YYYY')} - {moment(end).format('MM/DD/YYYY')} </Typography>
                    </Box>
                    <Box ml="auto">
                        <IconButton onClick={handleOpenPopper('bottom-start')}><DateRange /></IconButton>
                    </Box>
                </Box>
                <Grid container component={Card} sx={{
                    boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
                    border: '1px solid #F0F0F0',
                    borderRadius: 2
                }} p={2} mt={1}>
                    <Grid item xs={12}>
                        <Table
                            size="small"
                            headCells={[
                                'name',
                                'credits'
                            ].map((key) => t(`reports.tableHead.${key}`))}
                            rows={data.slice(0, slice).map(el => {
                                return {
                                    nume: (
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography
                                                pl={1.5}
                                                variant="subtitle1"
                                                color="primary"
                                                fontWeight="bold"
                                            >
                                                {el.name}
                                            </Typography>
                                        </Box>
                                    ),
                                    credits: (
                                        <Typography variant="subtitle1" color={GREEN_COLOR} fontWeight="bold">
                                            {el.credits}
                                        </Typography>
                                    ),
                                }
                            })}
                        />
                    </Grid>
                    <Grid pt={2} display="flex" alignItems="center" justifyContent="center" item xs={12} onClick={() => setSlice(slice + 1)}>
                        <Typography sx={{ cursor: 'pointer' }}>
                            {slice < data.length ? t('reports.showMore') : ''}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}