import React, { useCallback, useMemo, useState } from 'react'
import { Grid, Container, Typography, TextField, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ReactGA from 'react-ga'
import jwt_decode from 'jwt-decode'

import { isValidEmail, isValidPassword } from '../../utils/helpers/validator'

import { ReactComponent as Logo } from '../../assets/logo.svg'
import LOGIN_BG from '../../assets/login.png'
import { BLACK, DEFAULT_MAX_LENGTH } from '../../utils/constants'

import { SendingButton as SendingButtonChild, Footer } from '../common'

import { useAuthentication } from '../../context/auth-context'
import * as AUTH_REQUESTS from '../../api/auth'
import { useError } from '../../context/error-context'
import Gleap from 'gleap'

const useStyles = makeStyles({
  item: {
    padding: '0px !important'
  },
  forgottenContainer: {
    paddingTop: '0.4rem'
  },
  forgottenText: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  createAccountText: {
    cursor: 'pointer'
  }
})

const Login = () => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { handleError } = useError()
  const { handleLoggedIn, handleToken, handleUser } = useAuthentication()
  const [searchParams, setSearchParams] = useSearchParams()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [wrongInputs, setWrongInputs] = useState([])
  const [sending, setSending] = useState(false)
  const [isUser, setIsUser] = useState(true)
  const [isDeleted, setIsDeleted] = useState(false)

  const handleChangePassword = useCallback((event) => {
    setWrongInputs((prevValues) => [...prevValues.filter((el) => el !== 'password')])
    setPassword(event.target.value)
  }, [])

  const handleChangeEmail = useCallback((event) => {
    setIsUser(true)
    setIsDeleted(false)
    setWrongInputs((prevValues) => [
      ...prevValues.filter((el) => el !== 'email' && el !== 'invalid')
    ])
    setEmail(event.target.value)
  }, [])

  const handleIsNotUser = async (email) => {
    try {
      await AUTH_REQUESTS.validateEmail(email)
      setIsUser(true)
      return true
    } catch (error) {
      if (error?.response?.data?.role === 'USER') {
        setIsUser(false)
        return false
      } else if (error?.response?.data?.role !== undefined) {
        setIsUser(true)
        return true
      } else {
        console.error('Role is undefined')
        return false
      }
    }
  }

  const handleLogin = useCallback(async () => {
    try {
      handleError('')
      let wrong = false

      if (!isValidPassword(password)) {
        setWrongInputs((prevValues) => [...prevValues, 'password'])
        wrong = true
      }

      if (!isValidEmail(email)) {
        setWrongInputs((prevValues) => [...prevValues, 'email'])
        wrong = true
      }

      const isNotUser = await handleIsNotUser(email)

      if (!isNotUser) {
        wrong = true
      }
      if (wrong) return

      setSending(true)
      const { token, refreshToken, user } = await AUTH_REQUESTS.login(email, password)
      setSending(false)

      handleLoggedIn(true)
      handleToken(token)
      handleUser(user)
      const tokenDecoded = jwt_decode(token)
      const userLanguage =
        tokenDecoded && tokenDecoded.role === 'ORGANIZATION'
          ? tokenDecoded.locale === 'ENG'
            ? 'en'
            : tokenDecoded.locale.toLowerCase()
          : tokenDecoded.language
          ? tokenDecoded.language === 'ENG'
            ? 'en'
            : tokenDecoded.language.toLowerCase()
          : tokenDecoded.user.language
          ? tokenDecoded.user.language === 'EN'
            ? 'en'
            : tokenDecoded.user.language.toLowerCase()
          : 'en'
      i18n.changeLanguage(userLanguage)
      localStorage.setItem('token', token)
      localStorage.setItem('refreshToken', refreshToken)
      localStorage.setItem('email', user.email)

      Gleap.identify(`${user.id}`, {
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        phone: user.phoneNumber,
        language: userLanguage,
        ...(user.role === 'BUSINESS_ADMINISTRATOR'
          ? { companyName: user.companyName, companyId: user.companyId }
          : {})
      })

      ReactGA.event({
        category: 'PRE_LOGIN',
        action: 'LOGIN',
        label: 'SUCCESS'
      })

      if (searchParams.get('serviceId')) {
        let queryParams = `?`
        const keys = [
          'selectedSlot',
          'selectedCost',
          'selectedPaymentMethod',
          'day',
          'serviceId',
          'selectedSubscription'
        ]
        keys.forEach((key) => {
          queryParams += `${key}=${searchParams.get(key)}&`
        })
        navigate(`/calendar/services/${searchParams.get('serviceId')}${queryParams}`)
      } else if (searchParams.get('eventId')) {
        navigate(`/calendar?eventId=${searchParams.get('eventId')}`)
      } else {
        navigate('/')
      }
    } catch (e) {
      if (e.response.data.includes('Deleted')) {
        setIsDeleted(true)
        setWrongInputs([])
      } else {
        setIsDeleted(false)
        setWrongInputs(['invalid'])
      }
      setSending(false)
      console.error(e)
      ReactGA.event({
        category: 'PRE_LOGIN',
        action: 'LOGIN',
        label: 'ERROR'
      })
    }
  }, [handleLoggedIn, email, password])

  const handleForgottenPassword = () => navigate('/reset-password')

  const handleCreateAccount = () => navigate('/signup')

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin()
    }
  }

  const renderForm = useMemo(() => {
    return (
      <Grid item xs={12} sm={12} lg={6} display="flex" flexDirection="column" alignItems="center">
        <Grid container height="100%" alignItems="center">
          <Container maxWidth="xs" display="flex" height="100%">
            <Grid container justifyContent="center" pb={4}>
              <Grid item>
                <Logo width={268} height={94} />
              </Grid>
            </Grid>
            <Grid
              className={classes.content}
              container
              rowSpacing={4}
              direction="column"
              height="100%">
              <Grid item>
                <Typography fontWeight="bold" variant="h4" style={{ color: BLACK }}>
                  {t('title.login')}
                </Typography>
              </Grid>
              <Grid maxHeight={75} height={75} item>
                <TextField
                  onKeyDown={handleKeyDown}
                  onChange={handleChangeEmail}
                  defaultValue={email}
                  placeholder={t('input.placeholder.email')}
                  fullWidth
                  inputProps={{
                    maxLength: DEFAULT_MAX_LENGTH
                  }}
                  variant="outlined"
                  error={
                    wrongInputs.includes('email') || wrongInputs.includes('invalid') || !isUser
                  }
                  helperText={
                    wrongInputs.includes('invalid')
                      ? t('helper.somethingWent')
                      : wrongInputs.includes('email')
                      ? email === ''
                        ? t('helper.emailEmpty')
                        : t('input.error.email')
                      : ''
                  }
                />
              </Grid>
              {isDeleted && (
                <Grid paddingTop={'12px'}>
                  <Typography color={'#FF3838'} variant="p" fontSize={14}>
                    {t('helper.deleted1')} {t('helper.deleted2')} {t('helper.deleted3')}{' '}
                    <Typography
                      variant="p"
                      fontWeight={600}
                      color={'#FF3838'}
                      sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                      onClick={() => (window.location.href = 'mailto:help@sportin.io')}>
                      help@sportin.io.
                    </Typography>
                  </Typography>
                </Grid>
              )}
              {!isUser && (
                <Grid paddingTop={'12px'}>
                  <Typography color={'#FF3838'} variant="p" fontSize={14}>
                    {t('helper.user1')}{' '}
                    <Typography
                      variant="p"
                      color={'#FF3838'}
                      fontWeight={600}
                      sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                      onClick={() => window.open('https://linktr.ee/sportin.io')}>
                      Android
                    </Typography>{' '}
                    {t('helper.or')}{' '}
                    <Typography
                      variant="p"
                      fontWeight={600}
                      color={'#FF3838'}
                      sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                      onClick={() => window.open('https://linktr.ee/sportin.io')}>
                      iOS
                    </Typography>{' '}
                    {t('helper.user2')}
                  </Typography>
                </Grid>
              )}

              <Grid maxHeight={120} height={120} item>
                <TextField
                  onKeyDown={handleKeyDown}
                  onChange={handleChangePassword}
                  defaultValue={password}
                  type="password"
                  placeholder={t('input.placeholder.password')}
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    maxLength: DEFAULT_MAX_LENGTH
                  }}
                  error={wrongInputs.includes('password')}
                  helperText={
                    wrongInputs.includes('password')
                      ? password === ''
                        ? t('helper.passwordEmpty')
                        : t('input.error.password')
                      : ''
                  }
                />
                <Grid justifyContent="flex-end" container>
                  <Grid className={classes.forgottenContainer} item>
                    <Typography
                      fontWeight={600}
                      fontSize={14}
                      onClick={handleForgottenPassword}
                      variant="p"
                      color="primary"
                      className={classes.forgottenText}>
                      {t('helper.forgottenPassword')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  disabled={sending}
                  onClick={handleLogin}
                  style={{ backgroundColor: BLACK, color: '#fff' }}
                  variant="contained"
                  fullWidth>
                  {!sending ? t('button.login') : <SendingButtonChild />}
                </Button>
              </Grid>
              <Grid item>
                <Typography variant="p" fontWeight={500}>
                  {t('helper.missingAccount')}{' '}
                  <Typography
                    onClick={handleCreateAccount}
                    variant="span"
                    color="primary"
                    fontWeight={600}
                    className={classes.createAccountText}>
                    {t('helper.createAccount')}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    )
  }, [
    classes.forgottenContainer,
    classes.createAccountText,
    classes.forgottenText,
    classes.content,
    handleCreateAccount,
    handleLogin,
    handleForgottenPassword,
    handleChangeEmail,
    handleChangePassword,
    isUser,
    email,
    password,
    sending,
    wrongInputs,
    t
  ])

  const renderImage = useMemo(() => {
    return (
      <Grid
        item
        alignItems="center"
        display={{ xs: 'none', sm: 'none', lg: 'block' }}
        lg={6}
        maxHeight={'98vh'}>
        <img
          style={{ position: 'relative', left: '5%', top: '-30%', objectFit: 'cover' }}
          height={'150%'}
          src={LOGIN_BG}
          width={1200}
        />
      </Grid>
    )
  }, [])

  return (
    <Grid container style={{ padding: 0, overflow: 'hidden' }} height="100%">
      {renderForm}
      {renderImage}
    </Grid>
  )
}

export default Login
