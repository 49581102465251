import axios from './axios'

export const findAll = () => {
    return axios.get(`/license-type`).then((res) => res.data)
}

export const create = (payload) => {
    return axios.post(`/license-type`, { ...payload }).then((res) => res.data)
}

export const remove = (id) => {
    return axios.delete(`/license-type/${id}`).then((res) => res.data)
}

export const findById = (id) => {
    return axios.get(`/license-type/${id}`).then((res) => res.data)
}

export const edit = (id, payload) => {
    return axios.put(`/license-type/${id}`, { ...payload }).then((res) => res.data)
}
