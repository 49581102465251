import React from 'react'
import { Grid, Typography, Card, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  CARD_TEXT_COLOR,
  LIGHT_GRAY_BORDER_COLOR,
  GRAY_TEXT_COLOR,
  GRAY_BG_COLOR
} from '../../../../../utils/constants'

const FavouriteClub = ({ name, address, avatar }) => {
  const { t } = useTranslation()

  return (
    <Grid
      p={2}
      sx={{ boxShadow: '0 4px 8px 0 rgba(41, 40, 56, 0.08)', minWidth: 350, maxWidth: 350 }}
      component={Card}
      border={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
      display="flex"
      flexDirection="column"
    >
      <Grid
        borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
        p={1}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        {avatar ? (
          <img
            src={avatar}
            alt="avatar"
            style={{ borderRadius: '50%', objectFit: 'fill' }}
            height={40}
            width={40}
          />
        ) : (
          <Box
            sx={{
              width: 40,
              height: 40,
              backgroundColor: GRAY_BG_COLOR,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%'
            }}
          >
            <Typography variant="h4" color={GRAY_TEXT_COLOR} fontWeight={500}>
              {name.charAt(0)}
            </Typography>
          </Box>
        )}
        <Typography pl={1} color="primary" gutterBottom fontWeight="bold" variant="p">
          {name}
        </Typography>
      </Grid>
      <Typography pt={2} noWrap pb={0.5} variant="subtitle2" color={CARD_TEXT_COLOR}>
        {t('userProfile.activity.detailComponent.favouriteClubComponent.address')}
      </Typography>
      <Typography pb={1} noWrap fontWeight={100} variant="subtitle1">
        {address}
      </Typography>
    </Grid>
  )
}

export default FavouriteClub
