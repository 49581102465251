import React from 'react'
import { Grid, Typography, Button } from '@mui/material'

import { CARD_TEXT_COLOR, PRIMARY_COLOR } from '../../../utils/constants'
import { BASE_URL } from '../../../utils/config'

import HERO from '../../../assets/hero.gif'
import QUOTE from '../../../assets/quote.png'
import ERROR_GIF from '../../../assets/no-result.gif'
import { useTranslation } from 'react-i18next'

const ErrorCheckout = ({
  computeName,
  computeOwnerName,
  computeDomain,
  computePhoneNumber,
  computeEmail
}) => {
  const { t } = useTranslation()
  return (
    <Grid
      container
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      pt={6}
      pb={6}
      pl={3}
      pr={3}
    >
      <Grid item xs={12}>
        <img height={200} width={200} src={ERROR_GIF} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" fontWeight={'bold'} color="error">
          {t('checkout.somethingWent')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" pt={3}>
          {t('checkout.weCouldntProcess')} {computeName()} {t('checkout.from')} {computeOwnerName()}.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{t('checkout.pleaseTryAgain')}</Typography>
      </Grid>
      <Grid item xs={12} pt={3}>
        <Typography variant="h6">{t('checkout.needHelp')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          {t('checkout.ifYouHave')}{' '}
          <a href={`${BASE_URL}/${computeDomain()}`}>{computeOwnerName()}</a> {t('checkout.at')}:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          {computeEmail() || '-'} or {computePhoneNumber() || '-'}
        </Typography>
      </Grid>
      <Grid item xs={12} pt={3}>
        <img height={50} width={50} src={QUOTE} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" color={CARD_TEXT_COLOR}>
          {t('checkout.whetherYou')}
        </Typography>
      </Grid>
      <Grid item xs={12} pt={2} width={{ xs: '100%', md: '25%' }}>
        <Button
          onClick={() => navigate(`/${computeDomain()}`)}
          fullWidth
          variant="contained"
          color="primary"
        >
          {t('checkout.goBack')} {computeOwnerName()} {t('checkout.profile')}
        </Button>
      </Grid>
      <Grid item xs={12} pt={9}>
        <img height={50} width={50} src={HERO} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{t('checkout.assistance')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <a href="https://www.facebook.com/groups/xtrainer/" style={{ color: PRIMARY_COLOR }}>
          {t('checkout.join')}
        </a>
      </Grid>
    </Grid>
  )
}

export default ErrorCheckout
