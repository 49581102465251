import { Grid, Typography, Box } from '@mui/material';
import React, { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Info from './Info';
import MonthDetails from './MonthDetails';
import ExpendedMonthDetails from './ExpendedMonthDetails';
import Actions from './Actions';
import FilterOptions from './FilterOptions';
import usePayouts from '../../../hooks/usePayouts';
import _ from 'lodash';
import moment from 'moment';

const PayoutsTable = () => {
    const [expandedMonth, setExpandedMonth] = useState(moment().format('MMMM'));
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterUsers, setFilterUsers] = useState('unset');
    const [filterSubscriptions, setFilterSubscriptions] = useState('unset')

    const { getPayoutsByMonth, monthPayouts, isLoading, memoizedMonths, clubSubscriptions } = usePayouts();

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleExpendMonth = (month) => {
        if (expandedMonth === month) {
            setExpandedMonth(null);
        } else {
            setExpandedMonth(month);
            getPayoutsByMonth(month, filterUsers, filterSubscriptions);
        }
    };

    return (
        <Grid maxWidth={'83%'} container paddingY={2} alignSelf={'center'} background='#FFFFFF 0% 0% no-repeat padding-box' boxShadow="5px 12px 30px #00000010" gap={1} borderRadius={3} display="flex" flexDirection="column" width="100%">
            <FilterOptions onChangeUserFilter={setFilterUsers} filterUsers={filterUsers} getPayoutsByMonth={getPayoutsByMonth} expandedMonth={expandedMonth} clubSubscriptions={clubSubscriptions} filterSubscriptions={filterSubscriptions} onChangeFilterSubscriptions={setFilterSubscriptions} />
            <Info />
            {memoizedMonths?.map((item, index) => (
                <Box key={index} margin={0} padding={0}>
                    <Box key={index} sx={{ display: 'flex', minHeight: '7vh', backgroundColor: '#dbdce0' }}>
                        <Box sx={{ width: '92%', display: 'flex', cursor: 'pointer', userSelect: 'none' }} onClick={() => handleExpendMonth(item.month)}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '5%' }}>
                                {expandedMonth === item.month ? <ExpandLess sx={{ fontSize: '2.5rem' }} /> : <ExpandMore sx={{ fontSize: '2.5rem' }} />}
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '75%' }}>
                                <Typography fontSize={'1.25rem'} fontWeight={'bold'}>{item.month}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '17%', width: '29%' }}>
                                <Box width={'17%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <MonthDetails value={item.paymentsAmount} />
                                </Box>
                                <Box width={'35%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <MonthDetails value={item.feesAmount} />
                                </Box>
                                <Box width={'30%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <MonthDetails value={item.payoutsAmount} />
                                </Box>
                            </Box>
                        </Box>
                        <Box width={'8%'} display={'flex'} justifyContent={'end'} alignItems={'center'}>
                            <MoreVertIcon aria-expanded={anchorEl ? 'true' : undefined} aria-controls={anchorEl ? 'payouts-actions-menu' : undefined} aria-haspopup='true' sx={{ width: '1.5em', height: '1.5em', cursor: 'pointer' }} onClick={(event) => handleMenuClick(event)} />
                            {item.month === expandedMonth && (
                                <Actions
                                    id='payouts-actions-menu'
                                    payoutsCount={monthPayouts.length}
                                    selectedMonth={item.month}
                                    anchor={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                    isAbleToDownloadFeesInvoices={item.isAbleToDownloadFeesInvoices}
                                    isAbleToDownloadPaymentsInvoices={item.isAbleToDownloadPaymentsInvoices}
                                />
                            )}

                        </Box>
                    </Box>
                    {expandedMonth === item.month && (
                        <ExpendedMonthDetails isLoading={isLoading} payouts={monthPayouts} />
                    )}
                </Box>
            ))}
        </Grid>
    );
};

export default PayoutsTable;