import React, { useMemo, useEffect, useState } from 'react'
import { Grid, Card, Typography, Button, Rating, CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  Facebook,
  Instagram,
  KeyboardArrowDown,
  KeyboardArrowRight,
  Delete
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Linkify from 'react-linkify'

import {
  LIGHT_GRAY_BORDER_COLOR,
  CARD_TEXT_COLOR,
  PRIMARY_COLOR,
  GREEN_COLOR
} from '../../../../../utils/constants'
import * as SERVICE_REQUESTS from '../../../../../api/services'
import { BASE_URL } from '../../../../../utils/config'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  }
})

const Service = ({
  name,
  description,
  handleOnDelete,
  priceRon,
  currency,
  Trainers,
  isFree,
  Trainer,
  expand,
  id,
  handleExpand,
  handleOnOpenEdit
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [reviewsAverage, setReviewAverage] = useState()
  const [reviewCount, setReviewCount] = useState()

  useEffect(() => {
    if (!expand) return

    SERVICE_REQUESTS.getAllReviews(id).then(({ reviewsAverage, reviewsCount }) => {
      setReviewAverage(reviewsAverage)
      setReviewCount(reviewsCount)
    })
  }, [expand])

  if (!expand)
    return (
      <Grid
        container
        component={Card}
        className={classes.content}
        display="flex"
        flexDirection="row"
        alignItems="center"
        onClick={() => handleExpand(id)}
        pt={3}
        pb={3}
      >
        <Grid item xs={0.3} lg={0.3} pl={2} pt={1}>
          <Typography fontSize="large" color="primary">
            <KeyboardArrowRight />
          </Typography>
        </Grid>
        <Grid item xs={11} lg={9.2}>
          <Typography sx={{ cursor: 'pointer' }} pl={3} variant="h6" fontWeight={500}>
            {name}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={2.5} pr={2} pl={{ lg: 2, xs: 3 }}>
          <Typography color="primary" fontWeight="bold" variant="h6">
            {priceRon || 'N/A'}{' '}
            {priceRon ? currency : ''}
          </Typography>
        </Grid>
      </Grid>
    )

  return (
    <Grid container component={Card} className={classes.content}>
      <Grid
        item
        xs={12}
        borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
        display="flex"
        flexDirection={{ lg: 'row', xs: 'column' }}
        width="100%"
      >
        <Grid
          item
          onClick={() => handleExpand(id)}
          lg={7}
          borderRight={{
            xs: 'none'
          }}
          borderBottom={{
            xs: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
            lg: 'none'
          }}
          display="flex"
          alignItems="center"
          flexWrap="noWrap"
        >
          <KeyboardArrowDown color="primary" sx={{ paddingLeft: 2 }} />
          <Typography sx={{ cursor: 'pointer' }} p={3} variant="h6" fontWeight={500}>
            {name}
          </Typography>
        </Grid>
      </Grid>
      <Grid xs={12} item display="flex" flexDirection={{ xs: 'column', lg: 'row' }} width="100%">
        <Grid lg={7} item borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
          <Grid flexWrap="noWrap" p={3} display="flex" flexDirection="column">
            <Typography color={CARD_TEXT_COLOR}>{t('userProfile.activity.description')}</Typography>
            <Linkify>
              <Typography fontWeight={500}>{description}</Typography>
            </Linkify>
            <Grid pt={2} container display="flex" flexDirection="row" alignItems="center">
              <Grid item pr={1}>
                <Typography color={CARD_TEXT_COLOR}>
                  {t('userProfile.activity.trainerProgramComponent.share')}
                </Typography>
              </Grid>
              <a href="https://instagram.com" rel="noreferrer" target="_blank">
                <Instagram color="primary" />
              </a>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${BASE_URL}/services/${id}`}
                rel="noreferrer"
                target="_blank"
              >
                <Facebook color="primary" />
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={5} pt={1} pl={3} pr={3}>
          <Grid container display="flex" flexDirection="column">
            <Grid container width="100%" display="flex" flexDirection="row">
              <Grid item xs={6} display="flex" justifyContent="flex-start" flexDirection="column">
                <Typography color={CARD_TEXT_COLOR}>
                  {t('userProfile.activity.trainerProgramComponent.price')}
                </Typography>
                <Typography pb={2} fontWeight={500} color={isFree ? GREEN_COLOR : 'primary'}>
                  {isFree ? (
                    'FREE'
                  ) : priceRon ? (
                    `${priceRon + ' ' + currency}`
                  ) : (
                    <span color={PRIMARY_COLOR}>N/A</span>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6} display="flex" flexDirection="column">
                <Typography display="flex" justifyContent="flex-end" color={CARD_TEXT_COLOR}>
                  {t('userProfile.activity.trainerProgramComponent.trainer').toUpperCase()}
                </Typography>
                {Trainers.length ? (
                  Trainers.map((el) => {
                    return (
                      <Link style={{ textDecoration: 'none' }} to={'/' + el.Trainer.domain}>
                        <Typography
                          display="flex"
                          justifyContent="flex-end"
                          sx={{ textDecoration: 'underline', textAlign: 'right' }}
                          fontWeight={600}
                          color="primary"
                        >
                          {el.Trainer.firstName} {el.Trainer.lastName}
                        </Typography>
                      </Link>
                    )
                  })
                ) : (
                  <Typography
                    display="flex"
                    justifyContent="flex-end"
                    sx={{ textDecoration: 'underline' }}
                    fontWeight={600}
                    color="primary"
                  >
                    N/A
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid
              pt={{ xs: 2, lg: 1 }}
              pb={{ xs: 2, lg: 4 }}
              item
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                sx={{ minWidth: 120 }}
                onClick={() => handleOnOpenEdit(id)}
                variant="outlined"
              >
                {t('button.edit')}
              </Button>
              <Button
                sx={{ minWidth: 70, marginLeft: 1 }}
                onClick={() => handleOnDelete(id)}
                variant="outlined"
                color="error"
              >
                <Delete />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Service
