import axios from './axios'
import queryString from 'query-string'

export const getAffiliateCompanies = ({ companyIds }) => {
  return axios.get('/public/companies/affiliates?' + queryString.stringify({ companyIds })).then(res => res.data)
}

export const getConfigurations = () => {
  return axios.get('/companies/configurations').then((res) => res.data)
}

export const updateConfiguration = (id, payload) => {
  return axios.patch('/companies/configurations/' + id, { ...payload }).then((res) => res.data)
}

export const getBusiness = (id) => {
  return axios.get('/companies/' + id).then((res) => res.data)
}

export const updateOwnUser = (userId, payload) => {
  return axios.put('/companies/team/ownclients/' + userId, payload).then((res) => res.data)
}

export const createOwnUser = payload => {
  return axios.post('/companies/team/ownclients', payload).then((res) => res.data)
}

export const getOwnUsers = ({ from, limit, search }) => {
  return axios.get(`/companies/team/ownclients?from=${from}&limit=${limit}&search=${search}`).then((res) => res.data)
}

export const getCalendarServiceBookings = ({ from, limit, search }) => {
  return axios.get(`/companies/calendar-services?from=${from}&limit=${limit}&search=${search}`).then((res) => res.data)
}

export const confirmBookingPayment = (id) => {
  return axios.post('/companies/calendar-services/confirmPayment', { id }).then((res) => res.data)
}

export const getBusinessByDomain = (domain) => {
  return axios.get('/public/companies/business/' + domain).then((res) => res.data)
}

export const getBusinessPopularHoursByDomain = (domain, selectedDay) => {
  return axios.get('/public/companies/popularhours/' + domain + '?selectedDay=' + selectedDay).then((res) => res.data)
}


export const getBusinessForReview = () => {
  return axios.get('/users/reviews/companies').then((res) => res.data)
}

export const getBusinessById = (id) => {
  return axios.get(`/companies/${id}`).then((res) => res.data)
}

export const getAll = (options = {}) => {
  const query = {}

  Object.keys(options).forEach((key) => {
    if (options[key]) {
      query[key] = options[key]
    }
  })

  return axios.get('/companies/all?' + queryString.stringify(options)).then((res) => res.data)
}

export const updateBusiness = (id, payload) => {
  return axios.patch('/companies/' + id, payload).then((res) => res.data)
}

export const getAllSubscriptions = (companyId, search = '') => {
  return axios
    .get(`/companies/subscriptions/all/subscriptions/${companyId}?search=${search}`)
    .then((res) => res.data)
}

export const getAllServices = (companyId) => {
  return axios.get('/companies/services/all/services/' + companyId).then((res) => res.data)
}
export const getAllCourses = (companyId) => {
  return axios.get('/companies/courses/all/courses/' + companyId).then((res) => res.data)
}
export const getAllSubscriptionsByDomain = (domain) => {
  return axios.get('/public/companies/subscriptions/all/' + domain).then((res) => res.data)
}

export const createClub = (payload) => {
  return axios.post('/companies', payload).then((res) => res.data)
}

export const regenerateToken = () => {
  return axios.post('/companies/regenerate-token', {}).then((res) => {
    return res.data
  })
}

export const getAllServicesByDomain = (domain) => {
  return axios.get('/public/companies/services/all/' + domain).then((res) => res.data)
}

export const getAllReviewsByDomain = (domain, userId) => {
  return axios.get(`/public/companies/reviews/all/${domain}/${userId}`).then((res) => res.data)
}

export const getAllReviews = (companyId) => {
  return axios.get('/companies/reviews/all/reviews/' + companyId).then((res) => res.data)
}

export const addMember = (companyId, memberId) => {
  return axios.post('/companies/team/' + companyId + '/' + memberId, {})
}

export const removeMember = (companyId, memberId) => {
  return axios.delete('/companies/team/' + companyId + '/' + memberId)
}

export const getAllMembers = (companyId) => {
  return axios.get('/companies/team/members/' + companyId).then((res) => res.data)
}

export const createReview = (domain, payload) => {
  return axios.post('/companies/reviews/' + domain, payload).then((res) => res.data)
}

export const deleteService = (id) => {
  return axios.delete(`/companies/services/${id}`).then((res) => res.data)
}

export const deleteSubscription = (id) => {
  return axios.delete(`/subscriptions/${id}`).then((res) => res.data)
}

export const getAllBusinesses = () => {
  return axios.get(`/companies/businesses`).then((res) => res.data)
}

export const getBusinessName = (id) => {
  return axios.get(`/public/companies/${id}`).then((res) => res.data)
}

export const findAllLogs = (options) => {

  return axios
    .get(`/companies/audit-logs/?${queryString.stringify({ ...options })}`)
    .then((res) => res.data)
}

export const findAllLogsV2 = async (companyId, searchQuery) => {
  const url = searchQuery ? `/companies/audit-logs?companyId=${companyId}&searchQuery=${searchQuery}` : `/companies/audit-logs?companyId=${companyId}`
  const res = await axios.get(url);
  const data = await res.data;

  return data;
};

export const getCompanyCurrency = async (companyId) => {
  const currency = await axios.get(`/companies/currency${companyId ? `?companyId=${companyId}` : ''}`).then(res => res.data);

  return currency;
}

export const createConnectAccount = async () => {
  const stripeUrlForOnboarding = await axios.post('/companies/stripe/connect').then(res => res.data);

  return stripeUrlForOnboarding;
}

export const checkStripeOnboardingStatus = async () => {
  const onboardingStatus = await axios.get('/companies/stripe/connect').then(res => res.data);

  return onboardingStatus;
}

export const addDeal = (domain, payload) => {
  return axios.post(`/companies/deals/${domain}`, payload).then((res) => res.data)
}

export const deleteDeal = (dealId) => {
  return axios.delete(`/companies/deals/${dealId}`).then((res) => res.data)
}

export const updateDeal = (dealId, payload) => {
  return axios.patch(`/companies/deals/${dealId}`, payload).then((res) => res.data)
}

export const getDeal = (dealId) => {
  return axios.get(`/companies/deals/${dealId}`).then((res) => res.data)
}

export const getDeals = (companyId) => {
  return axios.get(`/companies/deals/allCompany/${companyId}`).then((res) => res.data)
}

export const openStripeBillingPortal = async () => {
  const billingPortal = await axios.get('/companies/stripe/billing-portal').then(res => res.data);

  return billingPortal
}