import React from 'react'
import { Grid, Typography, Card } from '@mui/material'

import { CARD_TEXT_COLOR, LIGHT_GRAY_BORDER_COLOR, PRIMARY_COLOR } from '../../../utils/constants'
import { useTranslation } from 'react-i18next'

const CheckoutService = ({ service }) => {
  const { t } = useTranslation()

  return (
    <Grid container display="flex" flexDirection="column">
      <Grid item lg={12}>
        <Grid
          component={Card}
          sx={{ borderRadius: 2, border: `3px solid ${PRIMARY_COLOR}` }}
          container
          display="flex"
          flexDirection="column"
        >
          <Grid
            item
            lg={12}
            sx={{ borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }}
            p={2}
            display="flex"
            alignItems="center"
          >
            <Grid container display="flex" flexDirection="row">
              <Grid item lg={12}>
                <Typography
                  variant="h6"
                  fontWeight={600}
                  color="primary"
                  sx={{ textDecoration: 'underline' }}
                >
                  {service.title}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} sx={{ borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }} p={2}>
            <Typography color={CARD_TEXT_COLOR} variant="body1">
              {t('checkout.description')}
            </Typography>
            <Typography variant="body2">{service.description}</Typography>
          </Grid>
          <Grid item lg={12} sx={{ borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}` }} p={2}>
            <Grid container display="flex" flexDirection="row">
              <Grid item lg={12}>
                <Grid container display="flex" flexDirection="column">
                  <Grid item lg={12}>
                    <Typography color={CARD_TEXT_COLOR} variant="body1">
                      {t('checkout.trainer')}
                    </Typography>
                    <Typography variant="body2">
                      {service.trainerId ? 'Trainer 1' : 'N/A'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CheckoutService
