import React, { useEffect, useState, useCallback } from 'react'
import {
  Dialog,
  Typography,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Avatar,
  Chip,
  Button,
  Box,
  Tooltip
} from '@mui/material'
import { Close, Schedule, Money, Receipt, InfoOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga'
import validator from 'validator'

import { InputGenerator, Toggle } from '../../../../common'
import { isValidText } from '../../../../../utils/helpers/validator'
import { HourMinutesMask } from '../../../../../utils/inputMask'

import {
  PRIMARY_COLOR,
  SUBSCRIPTION_TYPES,
  GREEN_CHIP_COLOR,
  SUBSCRIPTION_VISIBILITY,
  PAYMENT_METHOD,
  DEFAULT_MAX_LENGTH
} from '../../../../../utils/constants'
import * as SUBSCRIPTIONS_REQUESTS from '../../../../../api/subscriptions'

const PAYMENT_EXTRA_FIELDS = {
  paymentDeadline: {
    value: 3,
    isTouched: false
  },
  confirmation: {
    value: true
  }
}

const PAUSE_EXTRA_FIELDS = {
  minimumDays: {
    value: null,
    isTouched: false,
    hasError: true,
    isValid: (value) => {
      return value && Number(value) > 0
    }
  },
  maximumDays: {
    value: null,
    isTouched: false,
    hasError: true,
    isValid: (value) => value && Number(value) > 0
  }
}

const PART_TIME_EXTRA_FIELDS = {
  startTime: {
    value: null,
    isTouched: false,
    hasError: true,
    isValid: (value) => {
      return value && String(value).length === 5
    }
  },
  endTime: {
    value: null,
    isTouched: false,
    hasError: true,
    isValid: (value) => value && String(value).length === 5
  }
}

const INITIAL_DATA = {
  name: {
    value: null,
    isTouched: false,
    hasError: true,
    isValid: isValidText
  },
  description: {
    value: null,
    isTouched: false,
    hasError: true,
    isValid: isValidText
  },
  inHouseCredits: {
    value: null,
    isTouched: true,
    hasError: false,
    isValid: () => true
  },
  limitedActivation: {
    value: null,
    isTouched: false
  },
  priceRon: {
    value: null,
    isTouched: false,
    hasError: true,
    isValid: (value) => String(value).length > 0 && validator.isInt(String(value), { min: 0 })

  },
  duration: {
    value: null,
    isTouched: false,
    hasError: true,
    isValid: (value) => Number(value) > -1 && String(value).length > 0
  },
  visibility: {
    value: SUBSCRIPTION_VISIBILITY.PUBLIC
  },
  isAvailable: {
    value: true
  },
  pauseSubscription: {
    value: false
  },
  subscriptionType: {
    value: SUBSCRIPTION_TYPES.FULL_TIME
  },
  trainerIds: {
    value: []
  },
  paymentMethods: {
    value: [PAYMENT_METHOD.CASH, PAYMENT_METHOD.BANK_TRANSFER]
  }
}

const SubscriptionModal = ({ subscriptionId, trainer, handleClose, getSubscriptions, Transition, currency }) => {
  const { t } = useTranslation()

  const [data, setData] = useState()
  const [pauseExtraFields, setPauseExtraFields] = useState()
  const [partTimeExtraFields, setPartTimeExtraFields] = useState()
  const [paymentExtraFields, setPaymentExtraFields] = useState()
  const [allMembers, setAllMembers] = useState([])
  const [loadingCreate, setLoadingCreate] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState({
    duration: false,
    statusAfterCreating: false,
    visibility: false,
    type: false,
    limitedActivation: false,
    afterCreating: false,
    pause: false,
    pauseRules: false,
    inHouseCredits: false,
    confirmPayment: false,
    payment: false,
    paymentDeadline: false
  })

  const onEditHandler = useCallback(() => {
    setLoadingCreate(true)

    const payload = {}

    Object.keys(data).forEach((key) => {
      if (key === 'paymentMethods') {
        [PAYMENT_METHOD.CASH, PAYMENT_METHOD.BANK_TRANSFER].forEach(pm => {
          if (data[key].value.includes(pm)) {
            if (pm === PAYMENT_METHOD.CASH) {
              payload.paymentCash = true
            } else if (pm === PAYMENT_METHOD.BANK_TRANSFER) {
              payload.paymentBankTransfer = true
            }
          }
          else {
            if (pm === PAYMENT_METHOD.CASH) {
              payload.paymentCash = false
            } else if (pm === PAYMENT_METHOD.BANK_TRANSFER) {
              payload.paymentBankTransfer = false
            }
          }
        })
      }
      if (key === 'paymentDeadline') {
        if (data[key].value) payload['paymentDeadline'] = data[key].value
      } else if (key === 'inHouseCredits') {
        if (data[key].value) payload[key] = data[key].value
      } else if (key === 'subscriptionType') {
        payload.isPartTime = data[key].value === SUBSCRIPTION_TYPES.PART_TIME
      } else {
        payload[key] = data[key].value
      }
    })

    if (partTimeExtraFields) {
      Object.keys(partTimeExtraFields).forEach((key) => {
        payload[key] = partTimeExtraFields[key].value
      })
    }

    if (pauseExtraFields) {
      Object.keys(pauseExtraFields).forEach((key) => {
        if (key === 'minimumDays') {
          payload.minimumPauseDays = pauseExtraFields[key].value
        } else if (key === 'maximumDays') {
          payload.maximumPauseDays = pauseExtraFields[key].value
        } else payload[key] = pauseExtraFields[key].value
      })
    }

    Object.keys(paymentExtraFields).forEach((key) => {
      if (key === 'paymentDeadline') {
        if (paymentExtraFields[key].value) {
          payload[key] = paymentExtraFields[key].value
        }
      } else payload[key] = paymentExtraFields[key].value
    })

    return SUBSCRIPTIONS_REQUESTS.update(subscriptionId, { ...payload, trainerId: trainer.id })
      .then(() => {
        return getSubscriptions()
      })
      .then(() => {
        handleClose()
        setLoadingCreate(false)
      })
  }, [data, partTimeExtraFields, pauseExtraFields, paymentExtraFields, trainer])

  const onCreateHandler = useCallback(() => {
    setLoadingCreate(true)

    const payload = {}

    Object.keys(data).forEach((key) => {
      if (key === 'paymentMethods') {
        data[key].value.forEach((paymentMethod) => {
          if (paymentMethod === PAYMENT_METHOD.CASH) {
            payload.paymentCash = true
          } else if (paymentMethod === PAYMENT_METHOD.BANK_TRANSFER) {
            payload.paymentBankTransfer = true
          }
        })
      }

      if (key === 'paymentDeadline') {
        if (data[key].value) payload['paymentDeadline'] = data[key].value
      } else if (key === 'inHouseCredits') {
        if (data[key].value) payload[key] = data[key].value
      } else if (key === 'subscriptionType') {
        payload.isPartTime = data[key].value === SUBSCRIPTION_TYPES.PART_TIME
      } else {
        payload[key] = data[key].value
      }
    })

    if (partTimeExtraFields) {
      Object.keys(partTimeExtraFields).forEach((key) => {
        payload[key] = partTimeExtraFields[key].value
      })
    }

    if (pauseExtraFields) {
      Object.keys(pauseExtraFields).forEach((key) => {
        if (key === 'minimumDays') {
          payload.minimumPauseDays = pauseExtraFields[key].value
        } else if (key === 'maximumDays') {
          payload.maximumPauseDays = pauseExtraFields[key].value
        } else payload[key] = pauseExtraFields[key].value
      })
    }

    if (paymentExtraFields) {
      Object.keys(paymentExtraFields).forEach((key) => {
        if (key === 'paymentDeadline') {
          if (paymentExtraFields[key].value) {
            payload[key] = paymentExtraFields[key].value
          }
        } else payload[key] = paymentExtraFields[key].value
      })
    }

    return SUBSCRIPTIONS_REQUESTS.create({ ...payload, trainerId: trainer.id })
      .then(() => {
        ReactGA.event({
          category: 'POST_LOGIN',
          action: 'CREATE_SUBSCRIPTION',
          label: 'SUCCESS'
        })
        return getSubscriptions()
      })
      .then(() => {
        handleClose()
        setLoadingCreate(false)
        ReactGA.event({
          category: 'POST_LOGIN',
          action: 'CREATE_SUBSCRIPTION',
          label: 'ERROR'
        })
      })
  }, [data, partTimeExtraFields, pauseExtraFields, paymentExtraFields, trainer])

  useEffect(() => {
    setAllMembers([
      {
        id: trainer.id,
        firstName: trainer.firstName,
        lastName: trainer.lastName,
        avatarPhotoSrc: trainer.avatarPhotoSrc
      }
    ])

    if (!subscriptionId) {
      const DATA = Object.assign({}, INITIAL_DATA)

      setData({
        ...DATA,
        trainerIds: {
          ...DATA.trainerIds,
          value: [trainer.id]
        }
      })
    } else {
      SUBSCRIPTIONS_REQUESTS.findById(subscriptionId).then((subscription) => {
        const trainerIds = subscription.SubscriptionsTrainers.map((el) => el.Trainer.id)
        const paymentMethods = []
        const subscriptionType = subscription.isPartTime
          ? SUBSCRIPTION_TYPES.PART_TIME
          : SUBSCRIPTION_TYPES.FULL_TIME

        if (subscription.paymentCash) {
          paymentMethods.push(PAYMENT_METHOD.CASH)
        }

        if (subscription.paymentBankTransfer) {
          paymentMethods.push(PAYMENT_METHOD.BANK_TRANSFER)
        }

        if (subscription.pauseSubscription) {
          setPauseExtraFields({
            minimumDays: {
              value: subscription.minimumPauseDays,
              isTouched: false,
              hasError: false,
              isValid: (value) => {
                return value && Number(value) > 0
              }
            },
            maximumDays: {
              value: subscription.maximumPauseDays,
              isTouched: false,
              hasError: false,
              isValid: (value) => value && Number(value) > 0
            }
          })
        }

        if (subscriptionType === SUBSCRIPTION_TYPES.PART_TIME) {
          setPartTimeExtraFields({
            startTime: {
              value: subscription.startTime,
              isTouched: false,
              hasError: false,
              isValid: (value) => {
                return value && String(value).length === 5
              }
            },
            endTime: {
              value: subscription.endTime,
              isTouched: false,
              hasError: false,
              isValid: (value) => value && String(value).length === 5
            }
          })
        }

        if (
          paymentMethods.includes(PAYMENT_METHOD.CASH) ||
          paymentMethods.includes(PAYMENT_METHOD.BANK_TRANSFER)
        ) {
          setPaymentExtraFields({
            paymentDeadline: {
              value: subscription.paymentDeadline,
              isTouched: false,
              hasError: false,
              isValid: (value) => {
                return value && Number(value) > 0
              }
            },
            confirmation: {
              value: subscription.confirmation
            }
          })
        }

        setData({
          name: {
            value: subscription.name,
            isTouched: false,
            hasError: false,
            isValid: isValidText
          },
          description: {
            value: subscription.description,
            isTouched: false,
            hasError: false,
            isValid: isValidText
          },
          limitedActivation: {
            value: subscription.limitedActivation,
            isTouched: false
          },
          inHouseCredits: {
            value: subscription.inHouseCredits,
            isTouched: false,
            hasError: false,
            isValid: (value) => Number(value) > -1 && String(value).length > 0
          },
          priceRon: {
            value: subscription.priceRon,
            isTouched: false,
            hasError: false,
            isValid: (value) => Number(value) > -1 && String(value).length > 0
          },
          duration: {
            value: subscription.duration,
            isTouched: false,
            hasError: false,
            isValid: (value) => Number(value) > -1 && String(value).length > 0
          },
          visibility: {
            value: subscription.visibility
          },
          isAvailable: {
            value: subscription.isAvailable
          },
          pauseSubscription: {
            value: subscription.pauseSubscription
          },
          subscriptionType: {
            value: subscriptionType
          },
          trainerIds: {
            value: trainerIds
          },
          paymentMethods: {
            value: paymentMethods
          }
        })
      })
    }
  }, [trainer])

  const isDisabled = () => {
    let someDataIsTouched = subscriptionId ? true : false
    let somePaymentIsTouched = subscriptionId ? true : paymentExtraFields ? false : true
    let pauseExtraIsTouched = subscriptionId ? true : pauseExtraFields ? false : true
    let partIsTouched = subscriptionId ? true : partTimeExtraFields ? false : true
    let dataError = true
    let paymentError = true
    let pauseError = true
    let partError = true

    dataError = data
      ? Object.keys(data).some((key) => {
        const field = data[key]

        if (field.isTouched === undefined) {
          someDataIsTouched = someDataIsTouched || true
        } else if (field.isTouched) {
          someDataIsTouched = someDataIsTouched || true
        }

        if (field.hasError === undefined) return false

        return field.hasError
      })
      : false

    paymentError = paymentExtraFields
      ? Object.keys(paymentExtraFields).some((key) => {
        const field = paymentExtraFields[key]

        if (field.isTouched === undefined) {
          somePaymentIsTouched = somePaymentIsTouched || true
        } else if (field.isTouched) {
          somePaymentIsTouched = somePaymentIsTouched || true
        }

        if (field.hasError === undefined) return false

        return field.hasError
      })
      : false

    pauseError = pauseExtraFields
      ? Object.keys(pauseExtraFields).some((key) => {
        const field = pauseExtraFields[key]

        if (field.isTouched === undefined) {
          pauseExtraIsTouched = pauseExtraIsTouched || true
        } else if (field.isTouched) {
          pauseExtraIsTouched = pauseExtraIsTouched || true
        }

        if (field.hasError === undefined) return false

        return field.hasError
      })
      : false

    partError = partTimeExtraFields
      ? Object.keys(partTimeExtraFields).some((key) => {
        const field = partTimeExtraFields[key]

        if (field.isTouched === undefined) {
          partIsTouched = partIsTouched || true
        } else if (field.isTouched) {
          partIsTouched = partIsTouched || true
        }

        if (field.hasError === undefined) return false

        return field.hasError
      })
      : false

    return (
      dataError ||
      paymentError ||
      pauseError ||
      partError ||
      !someDataIsTouched ||
      !somePaymentIsTouched ||
      !pauseExtraIsTouched ||
      !partIsTouched
    )
  }

  const onChangeHandler = (name, value) => {
    if (name === 'subscriptionType' && value === SUBSCRIPTION_TYPES.PART_TIME) {
      setPartTimeExtraFields(Object.assign({}, PART_TIME_EXTRA_FIELDS))
    } else if (name === 'subscriptionType' && value === SUBSCRIPTION_TYPES.FULL_TIME) {
      setPartTimeExtraFields()
    }

    setData((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value,
        ...(prevState[name].isTouched !== undefined ? { isTouched: true } : {}),
        ...(prevState[name].hasError !== undefined && !!prevState[name].isValid !== undefined
          ? {
            hasError: !prevState[name].isValid(value)
          }
          : {})
      }
    }))
  }

  const onPartTimeExtraFieldsChangeHandler = (name, value) => {
    setPartTimeExtraFields((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value,
        ...(prevState[name].isTouched !== undefined ? { isTouched: true } : {}),
        ...(prevState[name].hasError !== undefined && !!prevState[name].isValid !== undefined
          ? {
            hasError: !prevState[name].isValid(value)
          }
          : {})
      }
    }))
  }

  const onPaymentExtraFieldsChangeHandler = (name, value) => {
    setPaymentExtraFields((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value,
        ...(prevState[name].isTouched !== undefined ? { isTouched: true } : {}),
        ...(prevState[name].hasError !== undefined && !!prevState[name].isValid !== undefined
          ? {
            hasError: !prevState[name].isValid(value)
          }
          : {})
      }
    }))
  }

  const onPauseExtraFieldsChangeHandler = (name, value) => {
    setPauseExtraFields((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value,
        ...(prevState[name].isTouched !== undefined ? { isTouched: true } : {}),
        ...(prevState[name].hasError !== undefined && !!prevState[name].isValid !== undefined
          ? {
            hasError: !prevState[name].isValid(value)
          }
          : {})
      }
    }))
  }

  useEffect(() => {
    if (!data) return

    if (
      (data.paymentMethods.value.includes(PAYMENT_METHOD.BANK_TRANSFER) ||
        data.paymentMethods.value.includes(PAYMENT_METHOD.CASH)) &&
      !paymentExtraFields
    ) {
      setPaymentExtraFields(Object.assign({}, PAYMENT_EXTRA_FIELDS))
    } else if (
      !data.paymentMethods.value.includes(PAYMENT_METHOD.BANK_TRANSFER) &&
      !data.paymentMethods.value.includes(PAYMENT_METHOD.CASH)
    ) {
      setPaymentExtraFields()
    }
  }, [data])

  if (!data) return null

  return (
    <Dialog
      fullScreen
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          borderRadius: 0
        }
      }}
      open={true}
    >
      <Grid container p={4} pl={{ xs: 2, md: 2, lg: 10 }} pr={{ xs: 2, md: 2, lg: 10 }}>
        <Grid item xs={12}>
          <Grid container display="flex" flexDirection="row">
            <Grid item xs={10}>
              <Typography variant="h5" fontWeight="bold">
                {t('subscriptionModal.title')}
              </Typography>
            </Grid>
            <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
              <IconButton style={{ color: 'black' }} onClick={handleClose}>
                <Close fontSize="medium" color="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} pt={10}>
          <Grid container display="flex" flexDirection="row">
            <Grid item xs={12} md={12} lg={6} pr={{ xs: 0, md: 0, lg: 1 }}>
              <Grid container>
                <Typography variant="h6" fontWeight="bold">
                  {t('subscriptionModal.generalInfo')}
                </Typography>
                <Grid item xs={12} pt={4}>
                  <InputGenerator
                    handleOnChange={(e) => onChangeHandler('name', e.target.value)}
                    error={data.name.isTouched && data.name.hasError}
                    helperText={data.name.isTouched && data.name.hasError && t('input.error.name')}
                    input={{
                      type: 'text',
                      label: 'Name',
                      value: data.name.value,
                      mandatory: true,
                      placeholder: t('subscriptionModal.namePlaceholder')
                    }}
                  />
                </Grid>

                <Grid container pt={3} display="flex" flexDirection="row">
                  <Grid item xs={6} pr={1} display="flex" flexDirection="column">
                    <Stack direction="row" alignItems="center">
                      <Typography
                        sx={{ mb: 1 }}
                        gutterBottom
                        variant="p"
                        display="flex"
                        alignItems="center"
                        fontWeight={500}
                      >
                        {t('subscriptionModal.duration')}
                        <Tooltip
                          open={tooltipOpen.duration}
                          title={t('subscriptionModal.durationExplanation')}
                          arrow
                          placement="top-start"
                        >
                          <InfoOutlined
                            color={'primary'}
                            onClick={() => {
                              setTooltipOpen((prevState) => ({
                                ...prevState,
                                duration: !prevState.duration
                              }))
                            }}
                            sx={{ marginLeft: 0.5, marginRight: 0.5 }}
                            fontSize="14px"
                          />
                        </Tooltip>
                      </Typography>
                    </Stack>

                    <TextField
                      inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                      placeholder="30"
                      type="number"
                      error={data.duration.isTouched && data.duration.hasError}
                      helperText={
                        data.duration.isTouched &&
                        data.duration.hasError &&
                        t('subscriptionModal.durationError')
                      }
                      value={data.duration.value}
                      onChange={(e) => onChangeHandler('duration', e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <Typography variant="p" fontWeight="bold" color={PRIMARY_COLOR}>
                              {t('subscriptionModal.days')}
                            </Typography>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} pl={1}>
                    <InputGenerator
                      handleOnChange={(event) => onChangeHandler('visibility', event.target.value)}
                      input={{
                        tooltipProps: {
                          open: tooltipOpen.visibility,
                          arrow: true,
                          placement: 'top-start',
                          title: t('subscriptionModal.visibilityExplanation')
                        },
                        onInfoClick: () => {
                          setTooltipOpen((prevState) => ({
                            ...prevState,
                            visibility: !prevState.visibility
                          }))
                        },
                        type: 'select',
                        label: t('subscriptionModal.visibility'),
                        mandatory: true,
                        value: data.visibility.value,
                        values: [
                          {
                            label: t('subscriptionModal.private'),
                            value: SUBSCRIPTION_VISIBILITY.PRIVATE
                          },
                          {
                            label: t('subscriptionModal.public'),
                            value: SUBSCRIPTION_VISIBILITY.PUBLIC
                          }
                        ]
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} pt={3}>
                  <InputGenerator
                    handleOnChange={() => { }}
                    input={{
                      disabled: true,
                      type: 'select',
                      label: t('subscriptionModal.assignTrainer'),
                      mandatory: true,
                      multiple: true,
                      value: data.trainerIds.value,
                      renderValue: (selected) => {
                        return (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.2 }}>
                            {selected.map((userId) => {
                              const member = allMembers.find((el) => el.id === userId)
                              if (!member) return null

                              return (
                                <Chip
                                  key={userId}
                                  avatar={
                                    <Avatar alt={member.firstName} src={member.avatarPhotoSrc} />
                                  }
                                  color="primary"
                                  variant="outlined"
                                  label={`${member.firstName} ${member.lastName}`}
                                />
                              )
                            })}
                          </Box>
                        )
                      },
                      values: []
                    }}
                  />
                </Grid>

                <Grid item xs={12} pt={3}>
                  <InputGenerator
                    handleOnChange={(event) => {
                      onChangeHandler('subscriptionType', event.target.value)
                    }}
                    input={{
                      tooltipProps: {
                        open: tooltipOpen.type,
                        arrow: true,
                        placement: 'top-start',
                        title: t('subscriptionModal.subscriptionTypeExplanation')
                      },
                      onInfoClick: () => {
                        setTooltipOpen((prevState) => ({
                          ...prevState,
                          type: !prevState.type
                        }))
                      },
                      type: 'select',
                      label: t('subscriptionModal.subscriptionType'),
                      mandatory: true,
                      value: data.subscriptionType.value,
                      values: Object.keys(SUBSCRIPTION_TYPES).map((key) => ({
                        value: SUBSCRIPTION_TYPES[key],
                        label:
                          SUBSCRIPTION_TYPES[key] === SUBSCRIPTION_TYPES.FULL_TIME
                            ? t('subscriptionModal.fullTime')
                            : t('subscriptionModal.partTime')
                      }))
                    }}
                  />
                </Grid>

                {partTimeExtraFields && (
                  <Grid container pt={2} display="flex" flexDirection="row">
                    <Grid item xs={6} pr={1} display="flex" flexDirection="column">
                      <Typography gutterBottom variant="p" fontWeight={500}>
                        {t('subscriptionModal.startTime')}
                      </Typography>
                      <TextField
                        inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                        fullWidth
                        onChange={(event) =>
                          onPartTimeExtraFieldsChangeHandler('startTime', event.target.value)
                        }
                        value={partTimeExtraFields.startTime.value}
                        error={
                          partTimeExtraFields.startTime.isTouched &&
                          partTimeExtraFields.startTime.hasError
                        }
                        helperText={
                          partTimeExtraFields.startTime.isTouched &&
                          partTimeExtraFields.startTime.hasError &&
                          t('subscriptionModal.startTimeError')
                        }
                        placeholder="09:00"
                        sx={{ minHeight: 75 }}
                        type="text"
                        InputProps={{
                          inputComponent: HourMinutesMask,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Schedule color="disabled" />
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      pl={1}
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                    >
                      <Typography gutterBottom variant="p" fontWeight={500}>
                        {t('subscriptionModal.endTime')}
                      </Typography>
                      <TextField
                        inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                        fullWidth
                        placeholder="14:00"
                        sx={{ minHeight: 75 }}
                        type="text"
                        onChange={(event) =>
                          onPartTimeExtraFieldsChangeHandler('endTime', event.target.value)
                        }
                        value={partTimeExtraFields.endTime.value}
                        error={
                          partTimeExtraFields.endTime.isTouched &&
                          partTimeExtraFields.endTime.hasError
                        }
                        helperText={
                          partTimeExtraFields.endTime.isTouched &&
                          partTimeExtraFields.endTime.hasError &&
                          t('subscriptionModal.endTimeError')
                        }
                        InputProps={{
                          inputComponent: HourMinutesMask,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Schedule color="disabled" />
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                )}

                <Grid item pt={3} xs={12}>
                  <Grid container display="flex" flexDirection="row">
                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      md={12}
                      lg={6}
                      pr={{ xs: 0, md: 0, lg: 1 }}
                    >
                      <Typography
                        sx={{ mb: 1 }}
                        gutterBottom
                        variant="p"
                        display="flex"
                        alignItems="center"
                        fontWeight={500}
                      >
                        {t('subscriptionModal.statusAfterCreating')}
                        <Tooltip
                          open={tooltipOpen.statusAfterCreating}
                          title={t('subscriptionModal.statusAfterCreatingExplanation')}
                          arrow
                          placement="top-start"
                        >
                          <InfoOutlined
                            color={'primary'}
                            onClick={() => {
                              setTooltipOpen((prevState) => ({
                                ...prevState,
                                statusAfterCreating: !prevState.statusAfterCreating
                              }))
                            }}
                            sx={{ marginLeft: 0.5, marginRight: 0.5 }}
                            fontSize="14px"
                          />
                        </Tooltip>
                      </Typography>
                      <Grid
                        item
                        pt={1}
                        xs={12}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Toggle
                          color="primary"
                          defaultChecked={data.isAvailable.value}
                          onClick={() => {
                            setData((prevState) => ({
                              ...prevState,
                              isAvailable: {
                                ...prevState.isAvailable,
                                value: !prevState.isAvailable.value
                              }
                            }))
                          }}
                        />
                        <Typography pl={1} fontWeight="bold" variant="p">
                          {data.isAvailable.value
                            ? t('subscriptionModal.active')
                            : t('subscriptionModal.inactive')}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      md={12}
                      lg={6}
                      pl={{ xs: 0, md: 0, lg: 1 }}
                    >
                      <InputGenerator
                        handleOnChange={(e) => {
                          if (Number(e.target.value) < 0) return
                          onChangeHandler('limitedActivation', e.target.value)
                        }}
                        input={{
                          fullWidth: true,
                          type: 'number',
                          label: t('subscriptionModal.limitedActivation'),
                          tooltipProps: {
                            open: tooltipOpen.limitedActivation,
                            arrow: true,
                            placement: 'top-start',
                            title: t('subscriptionModal.limitedActivationExplanation')
                          },
                          onInfoClick: () => {
                            setTooltipOpen((prevState) => ({
                              ...prevState,
                              limitedActivation: !prevState.limitedActivation
                            }))
                          },
                          value: data.limitedActivation.value,
                          mandatory: false,
                          placeholder: t('subscriptionModal.limitedActivationPlaceholder')
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} pt={3}>
                  <InputGenerator
                    handleOnChange={(e) => onChangeHandler('description', e.target.value)}
                    error={data.description.isTouched && data.description.hasError}
                    helperText={
                      data.description.isTouched &&
                      data.description.hasError &&
                      t('subscriptionModal.detailsError')
                    }
                    input={{
                      type: 'text',
                      label: t('subscriptionModal.details'),
                      mandatory: true,
                      value: data.description.value,
                      multiline: true,
                      rows: 7,
                      placeholder: t('subscriptionModal.detailsPlaceholder')
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} lg={6} pl={{ xs: 0, md: 0, lg: 1 }}>
              <Grid container display="flex" flexDirection="column">
                <Typography variant="h6" fontWeight="bold">
                  {t('subscriptionModal.pauseRules')}
                </Typography>
                <Grid item pt={4}>
                  <Grid container display="flex" flexDirection="row">
                    <Grid item xs={12}>
                      <Typography
                        sx={{ mb: 1 }}
                        gutterBottom
                        variant="p"
                        display="flex"
                        alignItems="center"
                        fontWeight={500}
                      >
                        {t('subscriptionModal.enablePauseRules')}

                        <Tooltip
                          open={tooltipOpen.pauseRules}
                          title={t('subscriptionModal.pauseRulesExplanation')}
                          arrow
                          placement="top-start"
                        >
                          <InfoOutlined
                            color={'primary'}
                            onClick={() => {
                              setTooltipOpen((prevState) => ({
                                ...prevState,
                                pauseRules: !prevState.pauseRules
                              }))
                            }}
                            sx={{ marginLeft: 0.5, marginRight: 0.5 }}
                            fontSize="14px"
                          />
                        </Tooltip>
                      </Typography>
                      <Grid item xs={12} display="flex" flexDirection="row" alignItems="center">
                        <Toggle
                          color="primary"
                          defaultChecked={data.pauseSubscription.value}
                          onClick={() => {
                            if (!data.pauseSubscription.value) {
                              setPauseExtraFields(Object.assign({}, PAUSE_EXTRA_FIELDS))
                            } else {
                              setPauseExtraFields()
                            }

                            setData((prevState) => ({
                              ...prevState,
                              pauseSubscription: {
                                ...prevState.pauseSubscription,
                                value: !prevState.pauseSubscription.value
                              }
                            }))
                          }}
                        />
                        <Typography pl={1} fontWeight="bold" variant="p">
                          {data.pauseSubscription.value
                            ? t('subscriptionModal.enabled')
                            : t('subscriptionModal.disabled')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {pauseExtraFields && (
                  <Grid item pt={3}>
                    <Grid container display="flex" flexDirection="row">
                      <Grid item xs={6} pr={1}>
                        <Grid pb={1}>
                          <Typography variant="p" fontWeight={500}>
                            {t('subscriptionModal.minimumPause')}
                          </Typography>
                        </Grid>
                        <TextField
                          inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                          fullWidth
                          onChange={(event) =>
                            onPauseExtraFieldsChangeHandler('minimumDays', event.target.value)
                          }
                          value={pauseExtraFields.minimumDays.value}
                          error={
                            pauseExtraFields.minimumDays.isTouched &&
                            pauseExtraFields.minimumDays.hasError
                          }
                          helperText={
                            pauseExtraFields.minimumDays.isTouched &&
                            pauseExtraFields.minimumDays.hasError &&
                            t('subscriptionModal.minimumError')
                          }
                          placeholder="7"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <Typography variant="p" fontWeight="bold" color={PRIMARY_COLOR}>
                                  {t('subscriptionModal.days')}
                                </Typography>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>

                      <Grid item xs={6} pl={1}>
                        <Grid pb={1}>
                          <Typography variant="p" fontWeight={500}>
                            {t('subscriptionModal.maximumPause')}
                          </Typography>
                        </Grid>
                        <TextField
                          inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                          fullWidth
                          placeholder="10"
                          type="number"
                          onChange={(event) =>
                            onPauseExtraFieldsChangeHandler('maximumDays', event.target.value)
                          }
                          value={pauseExtraFields.maximumDays.value}
                          error={
                            pauseExtraFields.maximumDays.isTouched &&
                            pauseExtraFields.maximumDays.hasError
                          }
                          helperText={
                            pauseExtraFields.maximumDays.isTouched &&
                            pauseExtraFields.maximumDays.hasError &&
                            t('subscriptionModal.maximumError')
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <Typography variant="p" fontWeight="bold" color={PRIMARY_COLOR}>
                                  {t('subscriptionModal.days')}
                                </Typography>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <Grid container pt={6}>
                <Typography variant="h6" fontWeight="bold">
                  {t('subscriptionModal.paymentConfiguration')}
                </Typography>
                <Grid item xs={12} pt={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container display="flex" flexDirection="row">
                        <Grid item xs={12} md={12} lg={6} pr={{ xs: 0, md: 0, lg: 1 }}>
                          <Grid pb={1}>
                            <Typography variant="p" fontWeight={500}>
                              {t('subscriptionModal.priceRon')}
                            </Typography>
                          </Grid>
                          <TextField
                            inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                            fullWidth
                            onChange={(event) => onChangeHandler('priceRon', event.target.value)}
                            value={data.priceRon.value}
                            error={data.priceRon.isTouched && data.priceRon.hasError}
                            helperText={
                              data.priceRon.isTouched &&
                              data.priceRon.hasError &&
                              t('subscriptionModal.priceError')
                            }
                            placeholder="199.99"
                            type="number"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  <Typography variant="p" fontWeight="bold" color={PRIMARY_COLOR}>
                                    {currency}
                                  </Typography>
                                </InputAdornment>
                              )
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          pt={{ xs: 3, md: 3, lg: 0 }}
                          xs={12}
                          md={12}
                          lg={6}
                          pl={{ xs: 0, md: 0, lg: 1 }}
                        >
                          <Typography
                            sx={{ mb: 1 }}
                            gutterBottom
                            variant="p"
                            display="flex"
                            alignItems="center"
                            fontWeight={500}
                          >
                            {t('subscriptionModal.inHouseCredits')}
                            <Tooltip
                              open={tooltipOpen.inHouseCredits}
                              title={t('subscriptionModal.creditExplanation')}
                              arrow
                              placement="top-start"
                            >
                              <InfoOutlined
                                color={'primary'}
                                onClick={() => {
                                  setTooltipOpen((prevState) => ({
                                    ...prevState,
                                    inHouseCredits: !prevState.inHouseCredits
                                  }))
                                }}
                                sx={{ marginLeft: 0.5, marginRight: 0.5 }}
                                fontSize="14px"
                              />
                            </Tooltip>
                          </Typography>
                          <TextField
                            inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                            onFocus={() => {
                              setTooltipOpen((prevState) => ({
                                ...prevState,
                                inHouseCredits: true
                              }))
                            }}
                            onBlur={() => {
                              setTooltipOpen((prevState) => ({
                                ...prevState,
                                inHouseCredits: false
                              }))
                            }}
                            fullWidth
                            onChange={(event) =>
                              onChangeHandler('inHouseCredits', event.target.value)
                            }
                            value={data.inHouseCredits.value}
                            error={data.inHouseCredits.isTouched && data.inHouseCredits.hasError}
                            helperText={
                              data.inHouseCredits.isTouched &&
                              data.inHouseCredits.hasError &&
                              t('subscriptionModal.inHouseCreditsError')
                            }
                            placeholder="15"
                            type="number"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  <Typography variant="p" fontWeight="bold" color={PRIMARY_COLOR}>
                                    {t('subscriptionModal.number')}
                                  </Typography>
                                </InputAdornment>
                              )
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} pt={3}>
                      <InputGenerator
                        handleOnChange={(event) => {
                          setData((prevState) => ({
                            ...prevState,
                            paymentMethods: {
                              ...prevState.paymentMethods,
                              value: event.target.value
                            }
                          }))
                        }}
                        input={{
                          type: 'select',
                          label: t("clients.payMethod"),
                          mandatory: true,
                          multiple: true,
                          value: data.paymentMethods.value,
                          renderValue: (selected) => {
                            return (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((el) => {
                                  if (el === PAYMENT_METHOD.CASH) {
                                    return (
                                      <Chip
                                        key={el}
                                        icon={<Money />}
                                        sx={{ fontWeight: 500, backgroundColor: GREEN_CHIP_COLOR }}
                                        color="success"
                                        variant="outlined"
                                        label={t('subscriptionModal.cash')}
                                      />
                                    )
                                  }

                                  if (el === PAYMENT_METHOD.BANK_TRANSFER) {
                                    return (
                                      <Chip
                                        key={el}
                                        icon={<Receipt />}
                                        sx={{ fontWeight: 500, backgroundColor: GREEN_CHIP_COLOR }}
                                        color="success"
                                        variant="outlined"
                                        label={t('subscriptionModal.bankTransfer')}
                                      />
                                    )
                                  }
                                })}
                              </Box>
                            )
                          },
                          values: Object.keys(PAYMENT_METHOD).filter(key => key !== PAYMENT_METHOD.ONLINE && key !== PAYMENT_METHOD.CARD).map((key) => ({
                            label: t(`subscriptionModal.${PAYMENT_METHOD[key]}`),
                            value: PAYMENT_METHOD[key]
                          }))
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {paymentExtraFields && (
                <Grid item pt={4}>
                  <Grid container display="flex" flexDirection="row">
                    <Typography
                      sx={{ mb: 1 }}
                      gutterBottom
                      variant="p"
                      display="flex"
                      alignItems="center"
                      fontWeight={500}
                    >
                      {t('subscriptionModal.confirm')}
                      <Tooltip
                        open={tooltipOpen.payment}
                        title={t('subscriptionModal.confirmExplanation')}
                        arrow
                        placement="top-start"
                      >
                        <InfoOutlined
                          color={'primary'}
                          onClick={() => {
                            setTooltipOpen((prevState) => ({
                              ...prevState,
                              payment: !prevState.payment
                            }))
                          }}
                          sx={{ marginLeft: 0.5, marginRight: 0.5 }}
                          fontSize="14px"
                        />
                      </Tooltip>
                    </Typography>
                    <Grid item xs={12} display="flex" flexDirection="row" alignItems="center">
                      <Toggle
                        color="primary"
                        defaultChecked={paymentExtraFields.confirmation.value}
                        onClick={() =>
                          setPaymentExtraFields((prevState) => ({
                            ...prevState,
                            confirmation: {
                              value: !prevState.confirmation.value
                            }
                          }))
                        }
                      />
                      <Typography pl={1} fontWeight="bold" variant="p">
                        {paymentExtraFields.confirmation.value
                          ? t('subscriptionModal.confirmEnabled')
                          : t('subscriptionModal.confirmDisabled')}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container pt={3}>
                    <Grid item xs={12} display="flex" flexDirection="column">
                      <Typography
                        sx={{ mb: 1 }}
                        gutterBottom
                        variant="p"
                        display="flex"
                        alignItems="center"
                        fontWeight={500}
                      >
                        {t('subscriptionModal.paymentDeadline')}
                        <Tooltip
                          open={tooltipOpen.paymentDeadline}
                          title={t('subscriptionModal.paymentDeadlineExplanation')}
                          arrow
                          placement="top-start"
                        >
                          <InfoOutlined
                            color={'primary'}
                            onClick={() => {
                              setTooltipOpen((prevState) => ({
                                ...prevState,
                                paymentDeadline: !prevState.paymentDeadline
                              }))
                            }}
                            sx={{ marginLeft: 0.5, marginRight: 0.5 }}
                            fontSize="14px"
                          />
                        </Tooltip>
                      </Typography>
                      <TextField
                        inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                        placeholder="2"
                        type="number"
                        error={
                          paymentExtraFields.paymentDeadline.isTouched &&
                          paymentExtraFields.paymentDeadline.hasError
                        }
                        helperText={
                          paymentExtraFields.paymentDeadline.isTouched &&
                          paymentExtraFields.paymentDeadline.hasError &&
                          t('subscriptionModal.pauseCostDeadlineError')
                        }
                        value={paymentExtraFields.paymentDeadline.value}
                        onChange={(e) =>
                          onPaymentExtraFieldsChangeHandler('paymentDeadline', e.target.value)
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <Typography variant="p" fontWeight="bold" color={PRIMARY_COLOR}>
                                {t('subscriptionModal.days')}
                              </Typography>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} pt={4}>
          <Grid container display="flex" flexDirection="row" justifyContent="center" spacing={2}>
            <Grid item xs={12} md={12} lg={3}>
              <Button fullWidth variant="outlined" onClick={handleClose}>
                {t('button.cancel')}
              </Button>
            </Grid>
            <Grid item xs={12} md={12} lg={3}>
              <Button
                fullWidth
                variant="contained"
                disabled={loadingCreate || isDisabled()}
                onClick={subscriptionId ? onEditHandler : onCreateHandler}
              >
                {t('button.save')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default SubscriptionModal
