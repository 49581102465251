import axios from './axios'

export const getMe = () => {
  return axios.get('/users/me').then((res) => res.data)
}

export const getById = (id) => {
  return axios.get('/users/' + id).then((res) => res.data)
}
export const getPublicById = (id) => {
  return axios.get('/public/athlete/' + id).then((res) => res.data)
}
export const validateEmail = async (email) => {
  return await axios.get(`/auth/validate/email/${email}`).then((res) => res.data)
}
export const updateUser = (user) => {
  return axios.put('/users/me', { ...user }).then((res) => res.data)
}

export const register = (data, type) => {
  return axios.post(`/auth/register/${type}`, { ...data }).then((res) => res.data)
}

export const login = (email, password, associatedGymId) => {
  return axios.post('/auth/login', { email, password, ...(associatedGymId ? { associatedGymId } : {}) }).then((res) => res.data)
}

export const resetPassword = (email) => {
  return axios.post('/auth/resetPassword', { email }).then((res) => res.data)
}

export const createPassword = (password, confirmPassword, newTextRecoveryCode) => {
  return axios
    .post(`/auth/changePassword/${newTextRecoveryCode}`, { password, confirmPassword })
    .then((res) => res.data)
}

export const changePassword = (currentPassword, confirmPassword, password) => {
  return axios
    .put('/users/changePassword', {
      currentPassword,
      confirmPassword,
      password
    })
    .then((res) => res.data)
}

export const regenerateToken = () => {
  return axios.post('/users/regenerate-token').then((res) => res.data)
}
