import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Subscription from './subcomponents/Subscription'

const Subscriptions = ({ subscriptions }) => {
  const { t } = useTranslation()

  return (
    <Grid container display="flex" p={3}>
      <Grid pt={2} container>
        {subscriptions.length > 0 ? (
          subscriptions.map((subscription, index) => {
            return (
              <Grid key={index} xs={12} item pt={2} pb={2}>
                <Subscription
                  {...subscription}
                  id={subscription.id}
                  Trainers={subscription.SubscriptionsTrainers.map((el) => el.Trainer)}
                  name={`${subscription.name}`}
                  description={subscription.description}
                  price={subscription.priceRon}
                  currency={subscription.Trainer?.trainerCurrency || subscription.Company?.currency}
                  credits={subscription.inHouseCredits}
                  limitedActivation={subscription.limitedActivation}
                />
              </Grid>
            )
          })
        ) : (
          <Typography color="#979797" fontWeight={500} fontSize={17}>
            {t('helper.noResultsFound')}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default Subscriptions
