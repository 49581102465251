import { createTheme } from '@mui/material/styles'
import { PRIMARY_COLOR } from '../../constants'

const buttonBaseMui = createTheme({
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiRadio-root.Mui-checked': {
            color: PRIMARY_COLOR
          },
        },
      }
    }
  }
})

export default buttonBaseMui
