import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';

import { WHITE_COLOR, BLACK } from '../../../utils/constants';
import CustomTypography from '../../../utils/FontLato';

const TypeCard = ({ type, subtitle, content, selected, onSelect, subtitleColor, titleRight }) => {
  return (
    <Card
      sx={{
        minWidth: 295,
        boxShadow: '0 9px 14px 0 rgba(41, 40, 56, 0.14)',
        border: selected === type ? `2px solid ${BLACK}` : `2px solid ${WHITE_COLOR}`,
        borderRadius: '10px',
        cursor: 'pointer',
        backgroundColor: WHITE_COLOR,
        opacity: 1,
      }}
      onClick={() => onSelect(type)}
    >
      <CardContent
        sx={{
          p: '12px !important',
        }}
      >
        <Grid container flexDirection="column">
          <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <CustomTypography variant="h6" style={{ color:  BLACK }} fontWeight="bold">
              {type}
            </CustomTypography>
            {titleRight &&
              <CustomTypography variant="h6" style={{ color: '#E81515' }} fontWeight="bold">
                {titleRight}
              </CustomTypography>}
          </Grid>
          <Grid item display="flex" flexDirection="column">
            <CustomTypography
              fontWeight={subtitleColor ? undefined : "bold"}
              variant="subtitle1"
              style={{ color:  subtitleColor ? subtitleColor : '#aaa', textDecoration: subtitleColor ? undefined : 'underline', wordBreak: 'break-word', marginBottom: '2%' }}>
              {subtitle}
            </CustomTypography>
            <div style={{ color: 'inherit' }}>
              {content}
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TypeCard;
