import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { Star } from '@mui/icons-material'
import moment from 'moment'
import Linkify from 'react-linkify'
import { Delete } from '@mui/icons-material'

import { CARD_TEXT_COLOR } from '../../../../../utils/constants'
import { DeleteModal } from '../../../../common'


const Review = ({ comment, name, stars, createdAt, onDelete }) => {
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false)

  const generateStars = () => {
    const starsArr = []
    while (stars) {
      starsArr.push(stars)
      stars -= 1
    }

    return starsArr
  }

  return (
    <>
      {showConfirmDelete ? <DeleteModal
        title="Review"
        text="Are you sure you want to remove your review?"
        onDelete={() => onDelete().then(() => setShowConfirmDelete(false))}
      /> : null}
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" justifyContent="flex-start">
          <Typography
            pr={1}
            sx={{ textDecoration: 'underline' }}
            fontWeight={600}
            variant="p"
            color="primary"
          >
            {name}
          </Typography>
          {generateStars().map((index) => (
            <Star key={index} color="primary" />
          ))}
        </Box>
        <Typography gutterBottom color={CARD_TEXT_COLOR}>
          {moment(createdAt).format('DD/MM/YYYY')}
        </Typography>
        <Linkify>
          <Typography fontWeight={400}>{comment}</Typography>
        </Linkify>
        <Box pt={1}>
          <Button variant="outlined" color="error" onClick={event => {
            event.preventDefault()
            event.stopPropagation()
            setShowConfirmDelete(true)
          }}>
            <Delete />
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default Review
