import React, { useEffect, useMemo, useState } from 'react'
import { Grid, Card, Typography, Rating, Button, Box, Avatar, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { CheckCircle, Facebook, Instagram } from '@mui/icons-material'
import parse from 'html-react-parser'
import { toast } from 'react-toastify';

import { FollowModal } from '../../common'

import {
  LIGHT_GRAY_BORDER_COLOR,
  CARD_TEXT_COLOR,
  GRAY_TEXT_COLOR,
  PRIMARY_COLOR,
  GRAY_BG_COLOR,
  ROLE_BUSINESS_ADMINISTRATOR,
  BUSINESS_ROLES,
  ROLE_TRAINER
} from '../../../utils/constants'
import { refetchPublicBusinessProfileObservable } from '../../../utils/observables'
import * as FOLLOWER_REQUESTS from '../../../api/follower'
import * as MESSAGE_REQUESTS from '../../../api/message'

import MessageCompanyDialog from './subcomponents/subcomponents/MesssageDialog'
import { useAuthentication } from '../../../context/auth-context'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  avatarContainer: {
    borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  credibilityContainer: {
    borderBottom: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  borderRight: {
    borderRight: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  },
  performanceText: {
    color: GRAY_TEXT_COLOR
  },
  memberFrom: {
    color: CARD_TEXT_COLOR
  }
})

const BusinessDetails = ({ company, handleGoToReview, isFollowing }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useAuthentication()
  const navigate = useNavigate()

  const [showFollowModal, setShowFollowModal] = useState(false)
  const [openMessageDialog, setOpenMessageDialog] = useState(false)
  const [gymRating, setGymRating] = useState(5)

  useEffect(() => {
    const score = Math.ceil(company?.reviewsAverage > 5 ? 5 : company?.reviewsAverage < 1 ? 1 : company?.reviewsAverage);

    setGymRating(score)
  }, [company])

  const onFollowHandler = () => {
    if (isFollowing) {
      return FOLLOWER_REQUESTS.unfollow({ followerCompanyId: company.id }).then(() => {
        refetchPublicBusinessProfileObservable.setRefetchPublicBusinessProfile({})
      })
    }

    setShowFollowModal(true)
  }

  const handleSendMessage = async (title, message) => {
    try {
      await MESSAGE_REQUESTS.sendMessageToCompany(company.id, { title, message })
      setOpenMessageDialog(false)
    } catch (e) {
      console.error(e)
    }
  }

  const renderGymReviews = useMemo(() => {
    return (
      <>
        <Rating sx={{ color: PRIMARY_COLOR }} disabled value={gymRating} />
        <Typography pl={1} fontWeight="bold" display="flex" flexDirection="row">
          {Math.ceil(gymRating)}
          <strong style={{ color: CARD_TEXT_COLOR }}>/5</strong>
        </Typography>
      </>
    )
  }, [gymRating])

  return (
    <>
      {showFollowModal ? (
        <FollowModal
          onCancel={() => setShowFollowModal(false)}
          onConfirm={() => {
            return FOLLOWER_REQUESTS.follow({ followerCompanyId: company.id })
              .then(() => {
                refetchPublicBusinessProfileObservable.setRefetchPublicBusinessProfile({})
                setShowFollowModal(false)
              })
              .catch(() => {
                toast.error(
                  `${t('helper.alreadyFollow')} ${company.name}`
                ), { position: 'bottom-right', autoClose: false }
              })
          }}
        />
      ) : null}
      {openMessageDialog ? (
        <MessageCompanyDialog
          handleSend={handleSendMessage}
          handleClose={() => setOpenMessageDialog(false)}
          companyName={`${company.name}`}
        />
      ) : null}
      <Grid container className={classes.content} component={Card} flexDirection="column">
        <Grid container pt={3} width="100%" className={classes.avatarContainer}>
          <Grid
            xs={12}
            pl={2}
            pr={2}
            item
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {company.avatarPhotoSrc ? (
              <Avatar
                src={company.avatarPhotoSrc}
                alt="avatar"
                style={{ width: 100, height: 100 }}
              />
            ) : (
              <Box
                sx={{
                  width: 100,
                  height: 100,
                  backgroundColor: GRAY_BG_COLOR,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 50
                }}
              >
                <Typography fontWeight="bold" color={GRAY_TEXT_COLOR} variant="h4">
                  {company.BusinessOwner.firstName.charAt(0)}{' '}
                  {company.BusinessOwner.lastName.charAt(0)}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            pt={1}
            pl={3}
            pr={3}
            flexDirection="row"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Tooltip title={company.name}>
              <Typography variant="h5" pr={1} fontWeight="bold" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                {company.name}
              </Typography>
            </Tooltip>
            {company.registrationCertificateApproved && company.identityCardApproved ? (
              <CheckCircle color="primary" />
            ) : null}
          </Grid>
          <Grid item xs={12} pb={3} display="flex" alignItems="center" justifyContent="center">
            <Typography variant="h6" fontWeight={500} color={CARD_TEXT_COLOR}>
              {t('gymProfile.profile.description')}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            pl={2}
            pr={2}
            flexDirection="row"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {renderGymReviews}
          </Grid>

          <Grid
            item
            xs={12}
            pt={1}
            pb={3}
            flexDirection="row"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              onClick={() => handleGoToReview()}
              variant="subtitle2"
              color="primary"
              fontWeight={600}
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              {t('gymProfile.profile.seeReviews')}
            </Typography>
          </Grid>
        </Grid>

        <Grid className={classes.credibilityContainer} container>
          <Grid
            item
            xs={6}
            pl={3}
            pr={2}
            pb={2}
            pt={2}
            className={classes.borderRight}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography variant="p" className={classes.performanceText}>
              {t('gymProfile.profile.performance')}
            </Typography>
            <Grid pt={0.5} item display="flex" flexDirection="row">
              <Typography color="primary" fontWeight={500} variant="h4">
                {Number(company.performanceScore).toFixed(0)}
              </Typography>
              <Typography fontWeight={500} variant="p">
                /10
              </Typography>
            </Grid>
          </Grid>
          <Grid
            pl={3}
            pr={3}
            pb={2}
            pt={2}
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            item
            xs={6}
          >
            <Typography
              variant="p"
              className={classes.performanceText}
              display="flex"
              justifyContent="flex-end"
            >
              {t('gymProfile.profile.followers')}
            </Typography>
            <Grid pt={0.5} item display="flex" flexDirection="row" justifyContent="flex-end">
              <Typography color="primary" variant="h4" fontWeight={500}>
                {company.followersCount || 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} p={3} pt={2}>
            <Grid container display="flex" flexDirection="row">
              <Grid item pr={0.5} xs={6} display="flex" justifyContent="center" alignItems="center">
                <Button
                  disabled={
                    !user ||
                    user.role === ROLE_BUSINESS_ADMINISTRATOR ||
                    user.role === BUSINESS_ROLES.BUSINESS_EMPLOYEE ||
                    user.role === ROLE_TRAINER
                  }
                  fullWidth
                  variant="outlined"
                  onClick={onFollowHandler}
                >
                  {isFollowing && user ? t('button.unfollow') : t('button.follow')}
                </Button>
              </Grid>

              <Grid item xs={6} pl={0.5} display="flex" justifyContent="center" alignItems="center">
                <Button
                  disabled={!user}
                  onClick={() => setOpenMessageDialog(true)}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {t('button.message')}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} pt={1}>
              <Button onClick={() => navigate(`/calendar/${company.domain}`)} fullWidth variant="contained" color="success">
                {t('button.calendar')}
              </Button>
            </Grid>
            <Grid item xs={12} pt={1}>
              <Button onClick={() => navigate(`/calendar-services/company/${company.domain}`)} fullWidth variant="contained" color="primary">
                BOOK
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid container p={3} pb={0}>
          <Typography fontWeight="bold" variant="subtitle1">
            {t('gymProfile.profile.location')}
          </Typography>
          <Grid item xs={12} flexDirection="row" display="flex">
            <a
              style={{ textDecoration: 'none' }}
              href={`https://www.google.com/maps/search/?api=1&query=${company.locationName}`}
              rel="noreferrer"
              target="_blank"
            >
              <Typography
                color="primary"
                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                fontWeight={600}
                variant="subtitle2"
              >
                {company.locationName}
              </Typography>
            </a>
          </Grid>
        </Grid>

        <Grid container p={3}>
          <Typography fontWeight="bold" variant="subtitle1">
            {t('gymProfile.profile.schedule')}
          </Typography>
          <Grid item xs={12} flexDirection="row" display="flex">
            <Grid container display="flex" flexDirection="row">
              <Grid item xs={2}>
                <Typography color={CARD_TEXT_COLOR} variant="subtitle2">
                  L-V{' '}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography fontWeight={200} variant="subtitle2">
                  {company.mondayFridayStartProgram && company.mondayFridayEndProgram ? (
                    <>
                      {company.mondayFridayStartProgram} - {company.mondayFridayEndProgram}
                    </>
                  ) : (
                    t('businessEditProfile.closed')
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} flexDirection="row" display="flex">
            <Grid item xs={2}>
              <Typography color={CARD_TEXT_COLOR} variant="subtitle2">
                S
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography fontWeight={200} variant="subtitle2">
                {company.saturdayStartProgram && company.saturdayEndProgram ? (
                  <>
                    {company.saturdayStartProgram} - {company.saturdayEndProgram}
                  </>
                ) : (
                  t('businessEditProfile.closed')
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} flexDirection="row" display="flex">
            <Grid item xs={2}>
              <Typography color={CARD_TEXT_COLOR} variant="subtitle2">
                D
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography fontWeight={200} variant="subtitle2">
                {company.sundayStartProgram && company.sundayEndProgram ? (
                  <>
                    {company.sundayStartProgram} - {company.sundayEndProgram}
                  </>
                ) : (
                  t('businessEditProfile.closed')
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {(company.instagramProfileUrl || company.facebookProfileUrl) && (
          <Grid
            container
            p={3}
            pb={3}
            pt={6}
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <Typography fontWeight={500} color={GRAY_TEXT_COLOR} variant="subtitle2">
              {t('helper.followUs')}
            </Typography>
            <Grid
              pt={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
              flexDirection="row"
              spacing={1}
            >
              {company.instagramProfileUrl && (
                <a href={company.instagramProfileUrl} rel="noreferrer" target="_blank">
                  <Instagram color="primary" />
                </a>
              )}
              {company.facebookProfileUrl && (
                <a href={company.facebookProfileUrl} rel="noreferrer" target="_blank">
                  <Facebook color="primary" />
                </a>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default BusinessDetails
