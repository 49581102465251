import axios from './axios'

export const getAvailableCompanies = (subscriptionId) => {
  return axios.get(`/accessSubscriptions?subscriptionId=${subscriptionId}`).then((res) => res.data)
}

export const create = ({ subscriptionId, companyId }) => {
  return axios.post(`/accessSubscriptions`, { subscriptionId, companyId }).then((res) => res.data)
}

export const remove = ({ subscriptionId, companyId }) => {
  return axios.delete(`/accessSubscriptions/${subscriptionId}/company/${companyId}`).then((res) => res.data)
}
