import axios from './axios'

export const getAll = ({ businessAdministratorId }) => {
  return axios
    .get(`/companies-employees?businessAdministratorId=${businessAdministratorId}`)
    .then((res) => res.data)
}

export const create = (companyId, employeeId, role) => {
  return axios
    .post('/companies-employees', {
      companyId,
      employeeId,
      role
    })
    .then((res) => res.data)
}

export const deleteEmployee = (id) => {
  return axios.delete(`/companies-employees/${id}`).then((res) => res.data)
}

export const findById = (id) => {
  return axios.get(`/companies-employees/${id}`).then((res) => res.data)
}

export const getClubs = (userId) => {
  return axios.get(`/companies-employees/clubs/${userId}`).then((res) => res.data)
}

export const switchRole = (companyId) => {
  return axios.post('/companies-employees/switch-role', { companyId }).then((res) => res.data)
}

export const switchRoleBack = () => {
  return axios.post('/companies-employees/switch-role-back', {}).then((res) => res.data)
}
