import React, { useState } from 'react';
import {
  Grid,
  Button,
  Slide,
  TextField,
  InputAdornment,
  IconButton,
  Select,
  MenuItem
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Box } from "@mui/material";
import Section from './section';
import CreateDealsDialog from '../../Create';
import EditDealsDialog from '../../Edit';
import { Search } from '@mui/icons-material';

const FilterDeals = ({
  deals,
  type,
  handleGetAllDeals,
}) => {
  const { t } = useTranslation()
  const [openCreateDialog, setOpenCreateDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [selectedDealsId, setSelectedDealsId] = useState()
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })
  return (
    <>
      {openEditDialog && selectedDealsId ? (
        <EditDealsDialog
          type={type}
          handleGetAllDeals={handleGetAllDeals}
          handleClose={() => {
            setOpenEditDialog(false)
            setSelectedDealsId()
            handleGetAllDeals()
          }}
          dealId={selectedDealsId}
          Transition={Transition}
        />
      ) : null}
      {openCreateDialog ? (
        <CreateDealsDialog
          type={type}
          handleClose={() => {
            setOpenCreateDialog(false)
            handleGetAllDeals()
          }}
          Transition={Transition}
        />
      ) : null}
      <Grid container display="flex" p={3} pt={4} pb={1} flexDirection="row">
        <Grid item xs={12} lg={10}>
          <Grid container display="flex" flexDirection={{ lg: 'row', xs: 'column' }}>
            <Grid lg={6} xs={12} pr={{ lg: 2, xs: 0 }} item>
              <TextField
                fullWidth
                placeholder={'Search'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <Search style={{ color: '#C6C6C6' }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              ></TextField>
            </Grid>
            <Grid item lg={6} xs={12} pt={{ lg: 0, xs: 1 }}>
              <Grid
                container
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent={{ lg: 'center', xs: 'flex-start' }}
              >
                <Grid item xs={6} pr={{ lg: 2, xs: 2 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={'all'}
                    fullWidth
                  >
                    <MenuItem value={'all'}>{t('helper.all')}</MenuItem>
                    <MenuItem value={true}>{t('helper.active')}</MenuItem>
                    <MenuItem value={false}>{t('helper.inactive')}</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={6} pr={{ lg: 2, xs: 0 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={'all'}
                    fullWidth
                  >
                    <MenuItem value={'all'}>{t('helper.all')}</MenuItem>
                    <MenuItem value={true}>{t('helper.public')}</MenuItem>
                    <MenuItem value={false}>{t('helper.private')}</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid lg={2} xs={12} display="flex" justifyContent="flex-end" item pt={{ lg: 0, xs: 1 }}>
          <Button
            onClick={() => setOpenCreateDialog(true)}
            fullWidth
            variant="contained"
          >
            {t('deals.create')}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} display="flex" flexDirection="row">
        {deals &&
          <Grid pt={1} container pr={3} pl={3} pb={3}>
            {deals.approved?.length > 0 &&
              <Grid xs={12} item pt={2} >
                <Box display="flex" alignItems="center" flexDirection={'column'} minHeight="22vh">

                  <Section setOpenEditDialog={setOpenEditDialog} setSelectedDealsId={setSelectedDealsId} deals={deals.approved} status={'Active'} />

                </Box>
              </Grid>}
            {deals.rejected?.length > 0 &&
              <Grid xs={12} item pt={3} pb={2}>
                <Box display="flex" alignItems="center" flexDirection={'column'} minHeight="22vh">
                  <Section setOpenEditDialog={setOpenEditDialog} setSelectedDealsId={setSelectedDealsId} deals={deals.rejected} status={'Pending'} />

                </Box>
              </Grid>}
          </Grid>}
      </Grid>
    </>
  )
}

export default FilterDeals
