import React from 'react';
import { Box, CircularProgress } from "@mui/material";
import { Image, ImageBackdrop, ImageButton, ImageMarked, ImageSrc, StyledGrid } from "./styled";
import Typography from '@mui/material/Typography';

const CardDeals = ({ image, onClick, width, height, select, title, loading }) => {
    return (
        <StyledGrid item xs={12}>
            {select ?
                <ImageButton
                    onClick={onClick}
                    focusRipple
                    style={{
                        width: width,
                    }}
                >
                    {
                        loading ?
                            <CircularProgress /> :
                            <>
                                <ImageSrc style={{ backgroundImage: `url(${image})` }} />
                                <ImageBackdrop className="MuiImageBackdrop-root" />
                                <Image>
                                    <Typography
                                        component="span"
                                        variant="subtitle1"
                                        color="inherit"
                                        sx={{
                                            position: 'relative',
                                            p: 4,
                                            pt: 2,
                                            pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                        }}
                                    >
                                        {title}
                                        <ImageMarked className="MuiImageMarked-root" />
                                    </Typography>
                                </Image>
                            </>
                    }
                </ImageButton>
                :
                <Box
                    sx={{
                        backgroundImage: `url(${image})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        height: `${height}px`,
                        width: `${width}px`,
                        borderRadius: '25px'
                    }}
                >
                </Box>}
        </StyledGrid>
    );
};

export default CardDeals;

