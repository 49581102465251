/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import { AddCircle, Close } from '@mui/icons-material'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    IconButton,
    Container,
    Button,
    InputAdornment,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import moment from 'moment'
import ReactGA from 'react-ga'
import { toast } from 'react-toastify';

import { CalendarEventDesign, CalendarEventDetails, CalendarEventNotes, CalendarEventDate } from '.'
import { Tabs } from '../../common'
import {
    PRIMARY_COLOR,
    SPORTTYPES_TYPES,
    EQUIPMENTS_TYPES,
    ROLE_TRAINER,
    BUSINESS_ROLES,
    ROLE_BUSINESS_ADMINISTRATOR,
    COMPANIES_TRAINERS_REQUEST_STATUS
} from '../../../utils/constants'

import * as TRAINER_REQUESTS from '../../../api/trainer'
import * as COMPANY_REQUESTS from '../../../api/company'
import * as CALENDAR_REQUESTS from '../../../api/calendar'
import { useAuthentication } from '../../../context/auth-context'
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'

const CalendarCreateEvent = ({ handleClose, Transition, currency }) => {
    const { t } = useTranslation()
    const { user } = useAuthentication()

    const isTrainer = user && user.role && user.role.toLowerCase() === ROLE_TRAINER.toLowerCase()
    const isBusinessAdministrator =
        user && user.role && user.role.toLowerCase() === ROLE_BUSINESS_ADMINISTRATOR.toLowerCase()
    const isClubAdministrator =
        user && user.role && user.role.toLowerCase() === BUSINESS_ROLES.BUSINESS_EMPLOYEE.toLowerCase()

    const [disable, setDisable] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const [selectedDesign, setSelectedDesign] = useState('COMPACT')
    const [notes, setNotes] = useState('')
    const [selectedColor, setSelectedColor] = useState('#0277bd')
    const [selectedDateType, setSelectedDateType] = useState('custom')
    const [subscriptions, setSubscriptions] = useState([])
    const [enterPressed, setEnterPressed] = useState(false)
    const [schedule, setSchedule] = useState([
        { name: 'mon', active: false, values: [] },
        { name: 'tue', active: false, values: [] },
        { name: 'wed', active: false, values: [] },
        { name: 'thu', active: false, values: [] },
        { name: 'fri', active: false, values: [] },
        { name: 'sat', active: false, values: [] },
        { name: 'sun', active: false, values: [] }
    ])
    const [savedSchedules, setSavedSchedules] = useState([])
    const [dateInputFocused, setDateInputFocused] = useState(false)
    const [trainers, setTrainers] = useState([])
    const [selectedTrainerIds, setSelectedTrainerIds] = useState([])
    const [whenType, setWhenType] = useState('schedule')
    const [specificDates, setSpecificDates] = useState([])

    useEffect(() => {
        if (!isBusinessAdministrator && !isClubAdministrator) return

        handleGetTrainers()
    }, [isBusinessAdministrator])

    const handleGetTrainers = useCallback(async () => {
        try {
            const company = await COMPANY_REQUESTS.getBusiness(user.companyId)
            setTrainers(
                company.Users.filter(
                    (el) =>
                        el.Companies &&
                        el.Companies[el.Companies.findIndex(comp => comp.id === company.id)] &&
                        el.Companies[el.Companies.findIndex(comp => comp.id === company.id)].Companies_Trainers.status ===
                        COMPANIES_TRAINERS_REQUEST_STATUS.APPROVED
                )
            )
        } catch (e) {
            console.error(e)
        }
    }, [])

    useEffect(() => {
        handleGetSavedSchedules()
    }, [])

    const handleGetSavedSchedules = async () => {
        try {
            const createFor = isTrainer ? 'trainer' : 'company'
            const { rows } = await CALENDAR_REQUESTS.getSavedSchedules(createFor, user.companyId)
            setDates((prevDatesValue) => {
                const prevDatesValueCopy = [...prevDatesValue]
                const savedSchedulesPosition = prevDatesValueCopy.findIndex((el) => el.name === 'scheduleToUse')

                if (savedSchedulesPosition < 0) return
                prevDatesValueCopy[savedSchedulesPosition].values = rows.map((schedule) => ({
                    value: schedule.id,
                    label: schedule.name
                }))

                return prevDatesValueCopy
            })
            setSavedSchedules(rows)
        } catch (e) {
            console.error(e)
        }
    }

    const handleCreateSchedule = useCallback(async (scheduleName) => {
        try {
            const createFor = isTrainer ? 'trainer' : 'company'
            const scheduleToCreate = {
                name: scheduleName
            }
            const activeDays = schedule.filter(day => day.active)
            scheduleToCreate['days'] = activeDays.map(day => day.name)

            activeDays.forEach(day => {
                scheduleToCreate['intervals'] = {
                    ...scheduleToCreate['intervals'],
                    [day.name]: day.values.map(val => {
                        const startSplit = val.start.split(':')
                        return {
                            start: val.start,
                            end: moment().set('hour', startSplit[0]).set('minute', startSplit[1]).add(val.duration, 'minutes').format('HH:mm')
                        }
                    })
                }
            })

            await CALENDAR_REQUESTS.createSchedule(scheduleToCreate, createFor, user.companyId)
            toast.success(t('calendar.scheduleSuccess'), { position: 'bottom-right' })
        } catch (e) {
            console.error(e)
            toast.error(t('calendar.scheduleError'), { position: 'bottom-right', autoClose: false })
        }
    }, [isTrainer, schedule])

    const isEquipmentsAndNone = useCallback((event) => event.target.name === 'equipment' && event.target.value.includes('NONE'), [])
    const isSubscriptionsAndSelectedAll = useCallback((event) => event.target.name === 'subscriptions' && event.target.value.includes('all'), [])
    const isAccessAndSubscriptionValue = useCallback((event) => event.target.name === 'access' && event.target.value === 'SUBSCRIPTION', [])
    const isAccessAndPublicValue = useCallback((event) => event.target.name === 'access' && event.target.value === 'PUBLIC', [])
    const isCostAndCreditsValue = useCallback((event) => event.target.name === 'cost' && event.target.value === 'CREDITS', [])
    const isCostAndFreeValue = useCallback((event) => event.target.name === 'cost' && event.target.value === 'FREE', [])
    const isCostAndNotFreeValue = useCallback((event) => event.target.name === 'cost' && event.target.value !== 'FREE', [])
    const isCostAndNoneValue = useCallback((event) => event.target.name === 'cost' && event.target.value === 'NONE', [])
    const isMinParticipantsAndValueGraterThanMaxParticipants = useCallback((event, detailsToUse) => {
        if (event.target.name !== 'minParticipants') return false
        const maxParticipantsIndex = detailsToUse.findIndex(el => el.name === 'maxParticipants')
        const maxParticipantsValue = detailsToUse[maxParticipantsIndex].value

        return event.target.name === 'minParticipants' && Number(maxParticipantsValue) && Number(event.target.value) && (Number(maxParticipantsValue) <= Number(event.target.value))
    }, [])
    const isAvailabilityAndPrivateValue = useCallback((event) => event.target.name === 'availability' && event.target.value === 'PRIVATE', [])
    const isAvailabilityAndPublicValue = useCallback((event) => event.target.name === 'availability' && event.target.value === 'PUBLIC', [])

    /* Function that handle all conditions for disabled inputs */
    const computeDetailsDisabled = useCallback((inputs) => {
        let inputsCopy = [...inputs]
        inputsCopy = inputsCopy.map(input => {
            if (input.name === 'access') {
                const subscriptionsPosition = inputs.findIndex(el => el.name === 'subscriptions')
                if (subscriptionsPosition < 0) return
                inputsCopy[subscriptionsPosition].disabled = input.value === 'PUBLIC'
            }
            if (input.name === 'cost') {
                const valuePosition = inputs.findIndex(el => el.name === 'value')
                const paymentPosition = inputs.findIndex(el => el.name === 'payment')
                if (valuePosition < 0 || paymentPosition < 0) return
                inputsCopy[valuePosition].disabled = input.value === 'FREE' || input.value === 'NONE'
                inputsCopy[paymentPosition].disabled = input.value === 'NONE' || input.value === 'CREDITS' || input.value === 'FREE'
            }

            return { ...input }
        })
        return inputsCopy
    }, [])

    const handleOnDetailsInputChanged = useCallback((event) => {
        setDetails((prevDetailsValue) => {
            let prevDetailsValueCopy = [...prevDetailsValue]
            const inputPosition = prevDetailsValueCopy.findIndex((el) => el.name === event.target.name)
            let custom = false

            if (inputPosition < 0) return

            if (isAvailabilityAndPrivateValue(event)) {
                const accessPosition = prevDetailsValueCopy.findIndex((el) => el.name === 'access')
                if (accessPosition >= 0) {
                    prevDetailsValueCopy[accessPosition].value = 'SUBSCRIPTION'
                    prevDetailsValueCopy[accessPosition].values = [
                        { value: 'PUBLIC', label: t('calendar.public'), disabled: true },
                        { value: 'SUBSCRIPTION', label: t('calendar.subscription'), disabled: false }
                    ]
                }
                const costPosition = prevDetailsValueCopy.findIndex((el) => el.name === 'cost')
                if (costPosition >= 0) {
                    prevDetailsValueCopy[costPosition].values = [
                        { value: 'NONE', label: t('calendar.none') },
                        {
                            value: 'CREDITS', label: t('calendar.credits')
                        },
                        {
                            value: currency, label: currency
                        }]
                    prevDetailsValueCopy[costPosition].value = 'NONE'
                }
            }
            if (isAvailabilityAndPublicValue(event)) {
                const accessPosition = prevDetailsValueCopy.findIndex((el) => el.name === 'access')
                if (accessPosition >= 0) {
                    prevDetailsValueCopy[accessPosition].values = [
                        { value: 'PUBLIC', label: t('calendar.public'), disabled: false },
                        { value: 'SUBSCRIPTION', label: t('calendar.subscription'), disabled: false }
                    ]
                }
            }
            if (isEquipmentsAndNone(event)) {
                prevDetailsValueCopy[inputPosition].value = []
                custom = true
            }
            if (isSubscriptionsAndSelectedAll(event)) {
                prevDetailsValueCopy[inputPosition].value = subscriptions.map(sub => sub.value)
                custom = true
            }
            if (isAccessAndSubscriptionValue(event)) {
                const costPosition = prevDetailsValueCopy.findIndex((el) => el.name === 'cost')
                if (costPosition >= 0) {
                    prevDetailsValueCopy[costPosition].values = [
                        {
                            value: 'CREDITS', label: t('calendar.credits')
                        }
                    ]
                    prevDetailsValueCopy[costPosition].value = 'CREDITS'
                }
            }
            if (isAccessAndPublicValue(event)) {
                const subscriptionsInput = prevDetailsValueCopy.findIndex((el) => el.name === 'subscriptions')
                const costPosition = prevDetailsValueCopy.findIndex((el) => el.name === 'cost')
                const valuePosition = prevDetailsValueCopy.findIndex((el) => el.name === 'value')
                if (subscriptionsInput >= 0 && costPosition >= 0 && valuePosition >= 0) {
                    prevDetailsValueCopy[subscriptionsInput].value = []
                    prevDetailsValueCopy[costPosition].values = [
                        { value: 'NONE', label: t('calendar.none') },
                        { value: 'FREE', label: t('calendar.free') },
                        { value: currency, label: currency }
                    ]
                    prevDetailsValueCopy[costPosition].value = 'FREE'
                    prevDetailsValueCopy[valuePosition].value = ''
                }
            }
            if (isCostAndFreeValue(event)) {
                const valuePosition = prevDetailsValueCopy.findIndex((el) => el.name === 'value')
                if (valuePosition >= 0) {
                    prevDetailsValueCopy[valuePosition].value = ''
                }
                handleOnAdornmentClick('tags', 'FREE')
            }
            if (isCostAndNotFreeValue(event)) {
                handleOnDeleteCustomSelect('tags', 'FREE')
            }
            if (isCostAndNoneValue(event)) {
                const valuePosition = prevDetailsValueCopy.findIndex((el) => el.name === 'value')
                const paymentPosition = prevDetailsValueCopy.findIndex((el) => el.name === 'payment')
                if (valuePosition >= 0 && paymentPosition >= 0) {
                    prevDetailsValueCopy[valuePosition].value = ''
                    prevDetailsValueCopy[paymentPosition].value = ''
                }
            }
            if (isCostAndCreditsValue(event)) {
                const paymentPosition = prevDetailsValueCopy.findIndex((el) => el.name === 'payment')
                if (paymentPosition >= 0) {
                    prevDetailsValueCopy[paymentPosition].value = ''
                }
            }
            if (isMinParticipantsAndValueGraterThanMaxParticipants(event, prevDetailsValueCopy)) {
                const maxParticipantsIndex = prevDetailsValueCopy.findIndex(el => el.name === 'maxParticipants')
                const maxParticipantsValue = prevDetailsValueCopy[maxParticipantsIndex].value
                prevDetailsValueCopy[inputPosition].value = Number(maxParticipantsValue) - 1
                custom = true
            }
            if (event.target.name === 'name') {
                prevDetailsValueCopy[inputPosition].value = event.target.value.toUpperCase()
                custom = true
            }
            if (event.target.name === 'value') {
                if (prevDetailsValueCopy[inputPosition].type === 'number' && Number(event.target.value.replace(',', '').replace('.', '')) < 0) {
                    prevDetailsValueCopy[inputPosition].value = 0
                }
                else {
                    prevDetailsValueCopy[inputPosition].value = event.target.value.replace(',', '').replace('.', '')
                }
                custom = true
            }

            if (!custom) {
                if (prevDetailsValueCopy[inputPosition].type === 'number' && Number(event.target.value) < 0) {
                    prevDetailsValueCopy[inputPosition].value = 0
                }
                else {
                    prevDetailsValueCopy[inputPosition].value = event.target.value
                }
            }
            prevDetailsValueCopy = computeDetailsDisabled(prevDetailsValueCopy)

            return prevDetailsValueCopy
        })
    }, [subscriptions])

    const handleOnDatesInputChanged = useCallback(
        (event) => {
            setDates((prevDatesValue) => {
                const prevDatesValueCopy = [...prevDatesValue]
                const inputPosition = prevDatesValueCopy.findIndex((el) => el.name === event.target.name)

                if (inputPosition < 0) return

                if (event.target.name === 'recurringWeeks' && event.target.value > 5) {
                    toast.error("Number of recurring weeks cannot be bigger than 5.", { position: 'bottom-right' })
                    return prevDatesValueCopy
                }

                if (prevDatesValueCopy[inputPosition].type === 'number' && Number(event.target.value) < 0) {
                    prevDatesValueCopy[inputPosition].value = 0
                }
                else {
                    prevDatesValueCopy[inputPosition].value = event.target.value
                }
                if (event.target.name === 'onlineBooking' && !event.target.value) {
                    const accessOnlyOnStartPosition = prevDatesValueCopy.findIndex(el => el.name === 'accessOnlyOnStart')
                    const cancelPosition = prevDatesValueCopy.findIndex(el => el.name === 'cancel')
                    const bookPosition = prevDatesValueCopy.findIndex(el => el.name === 'book')
                    if (accessOnlyOnStartPosition >= 0) {
                        prevDatesValueCopy[accessOnlyOnStartPosition].value = false
                        prevDatesValueCopy[cancelPosition].value = ''
                        prevDatesValueCopy[bookPosition].value = ''
                    }
                }

                if (event.target.name === 'scheduleToUse' && event.target.value) {
                    const selectedSchedule = savedSchedules.filter(
                        (schedule) => schedule.id === event.target.value
                    )[0]
                    const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
                    let selectedScheduleMapped = []


                    days.forEach(d => {
                        const dayFromResponse = selectedSchedule.Days.filter(it => it.name === d)
                        selectedScheduleMapped = selectedScheduleMapped.concat({
                            name: d,
                            active: dayFromResponse.length > 0,
                            values: !dayFromResponse.length ? [] : dayFromResponse[0].ScheduleIntervals.map(interval => ({
                                start: interval.start,
                                duration: moment().set('hour', interval.end.split(':')[0]).set('minute', interval.end.split(':')[1]).diff(moment().set('hour', interval.start.split(':')[0]).set('minute', interval.start.split(':')[1]), 'minutes')
                            }))
                        })
                    })

                    setSchedule(selectedScheduleMapped)
                }

                return prevDatesValueCopy
            })
        },
        [savedSchedules]
    )

    const maximumTagsExceededCondition = useCallback((numberOfTags) => numberOfTags === 3, [])

    const handleOnAdornmentClick = useCallback((inputName, directValue) => {
        setDetails((prevDetailsValue) => {
            const prevDetailsValueCopy = [...prevDetailsValue]
            const inputPosition = prevDetailsValueCopy.findIndex((el) => el.name === inputName)
            let exceeded = false

            if (inputPosition < 0) return

            if (maximumTagsExceededCondition(prevDetailsValueCopy[inputPosition].values.length)) {
                toast.error(t('calendar.maxTags'), { position: 'bottom-right', autoClose: false })
                exceeded = true
            }

            if (!exceeded) {
                prevDetailsValueCopy[inputPosition].values = [
                    ...prevDetailsValueCopy[inputPosition].values,
                    (directValue ? directValue.toUpperCase() : prevDetailsValueCopy[inputPosition].value.toUpperCase())
                ]
            }
            prevDetailsValueCopy[inputPosition].value = ''

            return prevDetailsValueCopy
        })
        setEnterPressed(false)
    }, [maximumTagsExceededCondition])

    const handleOnNotificationChanged = useCallback((inputName, value) => {
        setDates((prevDatesValue) => {
            const prevDatesValueCopy = [...prevDatesValue]
            const inputPosition = prevDatesValueCopy.findIndex((el) => el.name === inputName)

            if (inputPosition < 0) return
            prevDatesValueCopy[inputPosition].value = prevDatesValueCopy[inputPosition].value.includes(
                value
            )
                ? prevDatesValueCopy[inputPosition].value.filter((el) => el !== value)
                : prevDatesValueCopy[inputPosition].value.concat(value)

            return prevDatesValueCopy
        })
    })

    /* Triggered when user is in tags input, typed something and pressed enter */
    useEffect(() => {
        if (!enterPressed) return
        handleOnAdornmentClick('tags')
    }, [enterPressed])

    const DETAILS_INPUTS = [
        {
            name: 'name',
            label: t('input.label.eventName'),
            mandatory: true,
            value: '',
            type: 'text',
            placeholder: t('input.placeholder.eventName'),
            maxLength: 30,
            endAdornmentComponent: (
                <InputAdornment position="start">
                    <Typography variant="p" fontWeight="bold" color={PRIMARY_COLOR}>
                        30
                    </Typography>
                </InputAdornment>
            )
        },
        {
            name: 'sportType',
            label: t('input.label.sportType'),
            infoMessage: t('input.placeholder.sportType'),
            value: 'NONE',
            type: 'select',
            placeholder: 'Type',
            values: [{ label: t('helper.none'), value: 'NONE' }].concat(Object.keys(SPORTTYPES_TYPES).map((key) => ({
                label: t(`sportTypesOptions.${SPORTTYPES_TYPES[key]}`),
                value: SPORTTYPES_TYPES[key]
            })))
        },
        {
            name: 'equipment',
            label: t('input.label.equipments'),
            infoMessage: 'Equipments',
            value: [],
            type: 'select',
            multiple: true,
            values: [{ label: t('helper.resetAll'), value: 'NONE', withoutShowing: true }].concat(Object.keys(EQUIPMENTS_TYPES).map((key) => ({
                label: t(`equipmentsOptions.${EQUIPMENTS_TYPES[key]}`),
                value: EQUIPMENTS_TYPES[key]
            }))),
            placeholder: t('input.placeholder.equipments')
        },
        {
            name: 'location',
            label: t('input.label.eventLocation'),
            infoMessage: 'Event Location',
            value: '',
            type: 'google',
            placeholder: t('input.placeholder.eventLocation')
        },
        {
            name: 'minParticipants',
            label: t('input.label.minParticipants'),
            minValue: 1,
            infoMessage: t('input.placeholder.minParticipants'),
            value: '',
            type: 'number',
            placeholder: t('input.placeholder.minParticipants'),
        },
        {
            name: 'maxParticipants',
            label: t('input.label.maxParticipants'),
            infoMessage: t('input.placeholder.maxParticipants'),
            value: '',
            type: 'number',
            placeholder: t('input.placeholder.maxParticipants'),
        },
        {
            name: 'tags',
            label: t('input.label.tags'),
            infoMessage: t('input.placeholder.tags'),
            value: '',
            values: [],
            type: 'customSelect',
            placeholder: '#hiking, crossfit, advanced',
            endAdornmentComponent: (
                <InputAdornment onClick={() => handleOnAdornmentClick('tags')} position="start">
                    <AddCircle sx={{ cursor: 'pointer' }} color="primary" />
                </InputAdornment>
            )
        },
        {
            name: 'availability',
            label: t('input.label.availability'),
            infoMessage: t('input.placeholder.availability'),
            value: 'PUBLIC',
            type: 'select',
            placeholder: t('input.placeholder.availability'),
            values: [
                { value: 'PUBLIC', label: t('calendar.public') },
                { value: 'PRIVATE', label: t('calendar.private') }
            ]
        },
        {
            name: 'access',
            label: t('input.label.access'),
            infoMessage: t('input.placeholder.access'),
            value: 'PUBLIC',
            type: 'select',
            placeholder: t('input.placeholder.access'),
            values: [
                { value: 'PUBLIC', label: t('calendar.public'), disabled: false },
                { value: 'SUBSCRIPTION', label: t('calendar.subscription'), disabled: false }
            ]
        },
        {
            name: 'subscriptions',
            label: t('input.label.subscriptions'),
            infoMessage: t('input.placeholder.subscriptions'),
            value: [],
            values: [],
            disabled: true,
            multiple: true,
            type: 'select',
            placeholder: t('input.placeholder.subscriptions')
        },
        {
            name: 'cost',
            label: t('input.label.cost'),
            infoMessage: t('input.placeholder.cost'),
            value: 'NONE',
            type: 'select',
            values: [
                { value: 'NONE', label: t('calendar.none') },
                { value: 'FREE', label: t('calendar.free') },
                { value: currency, label: currency }
            ],
            placeholder: t('input.placeholder.cost')
        },
        {
            name: 'value',
            label: t('input.label.value'),
            infoMessage: t('input.placeholder.value'),
            value: '',
            type: 'number',
            disabled: true,
            placeholder: t('input.placeholder.value')
        },
        {
            name: 'payment',
            label: t('input.label.payment'),
            infoMessage: t('input.placeholder.payment'),
            disabled: true,
            value: '',
            type: 'select',
            values: [
                { value: 'CASH', label: t('calendar.cashAtLocation') },
                { value: 'ONLINE', label: t('calendar.onlinePayment') },
            ],
            placeholder: t('input.placeholder.payment')
        },
        {
            name: 'withWaitingList',
            label: t('input.label.withWaitingList'),
            infoMessage: t('input.placeholder.withWaitingList'),
            value: true,
            type: 'toggle',
            placeholder: t('input.placeholder.withWaitingList')
        },
        {
            name: 'maxWaitingList',
            label: t('input.label.maxWaitingList'),
            infoMessage: t('input.placeholder.maxWaitingList'),
            value: '',
            type: 'number',
            placeholder: t('input.placeholder.maxWaitingList')
        },
        {
            name: 'showParticipantsName',
            label: t('input.label.showParticipantsName'),
            infoMessage: t('input.placeholder.showParticipantsName'),
            value: true,
            type: 'toggle',
            placeholder: t('input.placeholder.showParticipantsName')
        }
    ]

    const DATES_INPUTS = [
        {
            name: 'scheduleToUse',
            label: t('calendar.scheduleToUse'),
            value: '',
            type: 'select',
            values: []
        },
        {
            name: 'onlineBooking',
            label: t('calendar.onlineBooking'),
            infoMessage: t('calendar.onlineBooking'),
            value: true,
            type: 'toggle'
        },
        {
            name: 'cancel',
            label: t('calendar.cancel'),
            infoMessage: t('calendar.cancel'),
            value: '',
            type: 'number',
            endAdornmentComponent: (
                <InputAdornment position="start">
                    <Typography variant="p" fontWeight="bold" color={PRIMARY_COLOR}>
                        {t('calendar.hours')}
                    </Typography>
                </InputAdornment>
            )
        },
        {
            name: 'book',
            label: t('calendar.book'),
            infoMessage: t('calendar.book'),
            value: '',
            type: 'number',
            endAdornmentComponent: (
                <InputAdornment position="start">
                    <Typography variant="p" fontWeight="bold" color={PRIMARY_COLOR}>
                        {t('calendar.hours')}
                    </Typography>
                </InputAdornment>
            )
        },
        {
            name: 'withVideoLink',
            label: t('calendar.withVideoLink'),
            infoMessage: t('calendar.withVideoLink'),
            value: false,
            type: 'toggle'
        },
        {
            name: 'videoLink',
            value: '',
            type: 'text',
            placeholder: t('calendar.videoLink')
        },
        {
            name: 'accessOnlyOnStart',
            label: t('calendar.accessOnlyOnStart'),
            infoMessage: t('calendar.accessOnlyOnStart'),
            value: false,
            type: 'toggle'
        },
        {
            name: 'clubNotifications',
            label: t('calendar.clubNotifications'),
            infoMessage: t('calendar.clubNotifications'),
            value: [],
            type: 'textArray'
        },
        {
            name: 'trainerNotifications',
            label: t('calendar.trainerNotifications'),
            infoMessage: t('calendar.trainerNotifications'),
            value: [],
            type: 'textArray'
        },
        {
            name: 'clubNotificationsBook',
            label: t('calendar.notificationsBook'),
            value: false,
            type: 'toggle'
        },
        {
            name: 'clubNotificationsUnbook',
            label: t('calendar.notificationsUnbook'),
            value: false,
            type: 'toggle'
        },
        {
            name: 'clubNotificationsFullEvent',
            label: t('calendar.notificationsFullEvent'),
            value: false,
            type: 'toggle'
        },
        {
            name: 'clubNotificationsWaitingList',
            label: t('calendar.notificationsWaitingList'),
            value: false,
            type: 'toggle'
        },
        {
            name: 'trainerNotificationsBook',
            label: t('calendar.notificationsBook'),
            value: false,
            type: 'toggle'
        },
        {
            name: 'trainerNotificationsUnbook',
            label: t('calendar.notificationsUnbook'),
            value: false,
            type: 'toggle'
        },
        {
            name: 'trainerNotificationsFullEvent',
            label: t('calendar.notificationsFullEvent'),
            value: false,
            type: 'toggle'
        },
        {
            name: 'trainerNotificationsWaitingList',
            label: t('calendar.notificationsWaitingList'),
            value: false,
            type: 'toggle'
        },
        {
            name: 'recurring',
            label: t('calendar.recurring'),
            value: false,
            type: 'toggle'
        },
        {
            name: 'recurringWeeks',
            label: t('calendar.weeks'),
            infoMessage: t('calendar.numberOfWeeks'),
            value: '',
            type: 'number',
            disabled: true,
            maxLength: 5,
            placeholder: t('calendar.numberOfWeeks')
        },
        {
            name: 'withTrainers',
            label: t('calendar.withTrainers'),
            value: false,
            type: 'toggle'
        },
    ]

    const [details, setDetails] = useState(DETAILS_INPUTS)
    const [dates, setDates] = useState(DATES_INPUTS)

    const getInputData = useCallback((inputs, inputName, field, fromArray, position) => {
        const inputPosition = inputs.findIndex((el) => el.name === inputName)
        if (inputPosition < 0) return ''

        return fromArray ? inputs[inputPosition][field][position] : inputs[inputPosition][field]
    }, [])

    const handleCreateEvent = useCallback(async () => {
        try {
            setDisable(true)
            const createFor = isTrainer ? 'trainer' : 'company'
            const fromDatesInputs = [
                'onlineBooking',
                'cancel',
                'book',
                'withVideoLink',
                'videoLink',
                'accessOnlyOnStart',
                'clubNotificationsBook',
                'clubNotificationsUnbook',
                'clubNotificationsFullEvent',
                'clubNotificationsWaitingList',
                'trainerNotificationsBook',
                'trainerNotificationsUnbook',
                'trainerNotificationsFullEvent',
                'trainerNotificationsWaitingList']

            const eventToCreate = {
                subscriptions: getInputData(details, 'subscriptions', 'value'),
                type: selectedDesign,
                backgroundColor: selectedColor,
                trainers: isBusinessAdministrator || isClubAdministrator ? selectedTrainerIds : [],
                notes
            }

            // Set the Latitude and Longitude for the event
            const locationIndex = details.findIndex(input => input.name === 'location');

            await geocodeByAddress(details[locationIndex].value)
                .then(result => {
                    getLatLng(result[0])
                        .then(({ lat, lng }) => {
                            eventToCreate['locationLat'] = String(lat);
                            eventToCreate['locationLng'] = String(lng);
                        })
                })

            details.forEach(inp => {
                if (inp.name === 'subscriptions') {
                    return
                }
                if (inp.name === 'tags') {
                    eventToCreate[inp.name] = inp.values.join(',')
                }
                else {
                    eventToCreate[inp.name] = inp.type === 'number' && !inp.value ? 0 : inp.type === 'number' ? Number(inp.value) : inp.value
                }
            })

            dates.forEach(inp => {
                if (fromDatesInputs.includes(inp.name)) {
                    eventToCreate[inp.name] = inp.type === 'number' && !inp.value ? 0 : inp.type === 'number' ? Number(inp.value) : inp.value
                }
                if (inp.name === 'clubNotifications') {
                    eventToCreate['clubNotificationAsNotification'] = inp.value.includes('notification')
                    eventToCreate['clubNotificationAsEmail'] = inp.value.includes('mail')
                }
                if (inp.name === 'trainerNotifications') {
                    eventToCreate['trainerNotificationAsNotification'] = inp.value.includes('notification')
                    eventToCreate['trainerNotificationAsEmail'] = inp.value.includes('mail')
                }
            })

            const eventSchedule = {}

            schedule.filter(day => day.active).forEach(day => {
                eventSchedule[day.name] = day.values.map(val => {
                    return {
                        start: val.start,
                        duration: val.duration
                    }
                })
            })

            if (eventToCreate.access === 'SUBSCRIPTION' && eventToCreate.subscriptions.length === 0) {
                setDisable(false)
                toast.error(`${t('calendar.provideSubscriptions')}`, { position: 'bottom-right', autoClose: false })
                return
            }

            if (eventToCreate.maxParticipants < eventToCreate.minParticipants) {
                setDisable(false)
                toast.error(`${t('calendar.maxParticipantsCantBeLower')} ${eventToCreate.minParticipants}`, { position: 'bottom-right', autoClose: false })
                return
            }

            if (!eventToCreate.name) {
                setDisable(false)
                toast.error(t('calendar.provideEventName'), { position: 'bottom-right', autoClose: false })
                return
            }

            if (whenType !== 'specific' && (!Object.keys(eventSchedule).length || (Object.keys(eventSchedule).length && (Object.values(eventSchedule).flat(1).filter(el => !el.start).length > 0 || Object.values(eventSchedule).flat(1).filter(el => !el.duration).length > 0)))) {
                setDisable(false)
                toast.error(t('calendar.provideSchedule'), { position: 'bottom-right', autoClose: false })
                return
            }

            if ((!Object.keys(eventSchedule).length && !specificDates.length) || (specificDates.filter(el => !el.start).length > 0 || specificDates.filter(el => !el.duration).length > 0)) {
                setDisable(false)
                toast.error(t('calendar.provideSchedule'), { position: 'bottom-right', autoClose: false })
                return
            }
            eventToCreate['schedule'] = eventSchedule
            eventToCreate['recurring'] = {
                activate: dates[dates.findIndex(el => el.name === 'recurring')].value,
                weeks: Number(dates[dates.findIndex(el => el.name === 'recurringWeeks')].value),
            }
            eventToCreate['specificDates'] = specificDates.map(el => ({ ...el, date: moment(el.date).endOf('day') }))
            eventToCreate['specificDate'] = specificDates.length > 0 ? true : false

            await CALENDAR_REQUESTS.createEvent(eventToCreate, createFor, user.companyId)
            ReactGA.event({
                category: 'POST_LOGIN',
                action: 'CREATE_EVENT',
                label: 'SUCCESS'
            })
            setDisable(false)
            handleClose()
        }
        catch (e) {
            setDisable(false)
            console.error(e)
            toast.error(t('calendar.errorCreatingEvent'), { position: 'bottom-right', autoClose: false })
            ReactGA.event({
                category: 'POST_LOGIN',
                action: 'CREATE_EVENT',
                label: 'ERROR'
            })
        }
    }, [details, isClubAdministrator, specificDates, whenType, selectedTrainerIds, isBusinessAdministrator, selectedDesign, schedule, selectedColor, notes, isTrainer])

    const handleAddValueToSchedule = useCallback((scheduleName) => {
        setSchedule((prevScheduleValue) => {
            const prevScheduleValueCopy = [...prevScheduleValue]
            const schedulePosition = prevScheduleValueCopy.findIndex((el) => el.name === scheduleName)

            if (schedulePosition < 0) return
            prevScheduleValueCopy[schedulePosition].values = prevScheduleValueCopy[
                schedulePosition
            ].values.concat([
                {
                    start: '',
                    duration: ''
                }
            ])
            prevScheduleValueCopy[schedulePosition].active = true
            setSelectedDateType('custom')
            return prevScheduleValueCopy
        })
    }, [])

    const handleRemoveValueFromSchedule = useCallback((scheduleName, positionToDelete) => {
        setSchedule((prevScheduleValue) => {
            const prevScheduleValueCopy = [...prevScheduleValue]
            const schedulePosition = prevScheduleValueCopy.findIndex((el) => el.name === scheduleName)

            if (schedulePosition < 0) return
            prevScheduleValueCopy[schedulePosition].values.splice(positionToDelete, 1)

            if (!prevScheduleValueCopy[schedulePosition].values.length) {
                prevScheduleValueCopy[schedulePosition].active = false
            }
            setSelectedDateType('custom')
            return prevScheduleValueCopy
        })
    }, [])

    const handleChangeValueFromSchedule = useCallback(
        (scheduleName, positionToUpdate, field, value, dateInputFocusedToUse) => {
            setSchedule((prevScheduleValue) => {
                const prevScheduleValueCopy = [...prevScheduleValue]
                const schedulePosition = prevScheduleValueCopy.findIndex((el) => el.name === scheduleName)

                if (schedulePosition < 0) return
                if (positionToUpdate !== undefined) {
                    if (dateInputFocusedToUse) {
                        setSelectedDateType('custom')
                    }
                    prevScheduleValueCopy[schedulePosition].values[positionToUpdate][field] = value
                } else {
                    prevScheduleValueCopy[schedulePosition][field] =
                        !prevScheduleValueCopy[schedulePosition][field]
                    if (prevScheduleValueCopy[schedulePosition][field] && !prevScheduleValueCopy[schedulePosition].values.length) {
                        setSelectedDateType('custom')
                        handleAddValueToSchedule(scheduleName)
                    }
                }

                return prevScheduleValueCopy
            })
        },
        []
    )

    const handleGetBusinessSubscriptions = useCallback(() => {
        COMPANY_REQUESTS.getAllSubscriptions(user.companyId).then((res) => {
            const mappedSubscriptions = res.filter(it => it.isAvailable).map((el) => ({
                value: el.id,
                label: el.name
            }))
            setSubscriptions(mappedSubscriptions)
            setDetails((prevDetailsValue) => {
                const prevDetailsValueCopy = [...prevDetailsValue]
                const inputPosition = prevDetailsValueCopy.findIndex((el) => el.name === 'subscriptions')
                const locationPosition = prevDetailsValueCopy.findIndex((el) => el.name === 'location')

                if (inputPosition >= 0) {
                    prevDetailsValueCopy[inputPosition].values = [{ value: 'all', label: t('calendar.selectAll') }].concat(mappedSubscriptions)
                }

                if (locationPosition >= 0) {
                    prevDetailsValueCopy[locationPosition].value = user ? user.companyLocation : ''
                }

                return prevDetailsValueCopy
            })
        })
    }, [user])

    const handleGetTrainerSubscriptions = useCallback(() => {
        TRAINER_REQUESTS.getTrainerSubscriptions().then((res) => {
            const mappedSubscriptions = res.map((el) => ({
                value: el.id,
                label: el.name
            }))
            setSubscriptions(mappedSubscriptions)
            setDetails((prevDetailsValue) => {
                const prevDetailsValueCopy = [...prevDetailsValue]
                const inputPosition = prevDetailsValueCopy.findIndex((el) => el.name === 'subscriptions')
                const locationPosition = prevDetailsValueCopy.findIndex((el) => el.name === 'location')

                if (inputPosition >= 0) {
                    prevDetailsValueCopy[inputPosition].values = [{ value: 'all', label: t('calendar.selectAll') }].concat(mappedSubscriptions)
                }

                if (locationPosition >= 0) {
                    prevDetailsValueCopy[locationPosition].value = user ? user.locationName : ''
                }

                return prevDetailsValueCopy
            })
        })
    }, [user])

    useEffect(() => {
        if (isTrainer) {
            handleGetTrainerSubscriptions()
        } else {
            handleGetBusinessSubscriptions()
        }
    }, [isTrainer, isBusinessAdministrator])

    const handleOnDeleteCustomSelect = useCallback((inputName, toDelete) => {
        setDetails((prevDetailsValue) => {
            const prevDetailsValueCopy = [...prevDetailsValue]
            const inputPosition = prevDetailsValueCopy.findIndex((el) => el.name === inputName)

            if (inputPosition < 0) return
            prevDetailsValueCopy[inputPosition].values = prevDetailsValueCopy[
                inputPosition
            ].values.filter((el) => el !== toDelete)

            return prevDetailsValueCopy
        })
    }, [])

    const handleOnDesignChanged = useCallback((event) => {
        setSelectedDesign(event.target.value.toUpperCase())
    }, [])

    const handleOnNotesChanged = useCallback((newNotesValue) => {
        setNotes(newNotesValue)
    }, [])

    const handleOnSelectedColorChanged = useCallback((newColor) => {
        setSelectedColor(newColor)
    }, [])

    const handleOnSelectedDateTypeChanged = useCallback((newSelectedDateType) => {
        setSelectedDateType(newSelectedDateType)
        handleOnDatesInputChanged({ target: { name: 'scheduleToUse', value: '' } })
        setSchedule([
            { name: 'mon', active: false, values: [] },
            { name: 'tue', active: false, values: [] },
            { name: 'wed', active: false, values: [] },
            { name: 'thu', active: false, values: [] },
            { name: 'fri', active: false, values: [] },
            { name: 'sat', active: false, values: [] },
            { name: 'sun', active: false, values: [] }
        ])
    }, [])

    const handleOnNextButton = useCallback(() => {
        if (activeTab === 3) return handleCreateEvent()

        setActiveTab((prevActiveTab) => prevActiveTab + 1)
    }, [activeTab, handleCreateEvent, handleClose])

    const handleOnPrevButton = useCallback(() => {
        if (activeTab === 0) return handleClose()

        setActiveTab((prevActiveTab) => prevActiveTab - 1)
    }, [activeTab, handleClose])

    const handleEnterPressed = useCallback(() => {
        setEnterPressed(true)
    }, [])

    const handleSelectTrainer = useCallback((trainerId) => {
        setSelectedTrainerIds(prevValue => {
            const newPrevValue = prevValue.concat(trainerId)
            return newPrevValue
        })
    })

    const handleRemoveTrainer = useCallback((trainerId) => {
        setSelectedTrainerIds(prevValue => {
            const newPrevValue = prevValue.filter(el => el !== trainerId)
            return newPrevValue
        })
    }, [])

    return (
        <Dialog
            open={true}
            fullWidth={true}
            maxWidth={'xl'}
            fullScreen
            TransitionComponent={Transition}
            PaperProps={{
                style: {
                    borderRadius: 0,
                    paddingTop: '3%'
                }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Container maxWidth="xl">
                <>
                    <DialogTitle
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        p={4}
                        pl={{ xs: 2, md: 2, lg: 3 }}
                    >
                        <Grid container display="flex" flexDirection="row">
                            <Grid item lg={10} xs={10}>
                                <Typography variant="h5" fontWeight="bold">
                                    {t('calendar.createEvent')}
                                </Typography>
                            </Grid>
                            <Grid item lg={2} xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                                <IconButton style={{ color: 'black' }} onClick={handleClose}>
                                    <Close fontSize="medium" color="inherit" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Grid pt={4} item lg={12}>
                            <Tabs
                                variant="fullWidth"
                                value={activeTab}
                                onChange={(_, newValue) => {
                                    setActiveTab(newValue)
                                }}
                                options={[
                                    {
                                        label: t('calendar.design'),
                                        value: (
                                            <CalendarEventDesign
                                                selectedDesign={selectedDesign}
                                                handleOnDesignChanged={handleOnDesignChanged}
                                            />
                                        )
                                    },
                                    {
                                        label: t('calendar.details'),
                                        value: (
                                            <CalendarEventDetails
                                                design
                                                editing
                                                inputs={details}
                                                selectedColor={selectedColor}
                                                selectedDesign={selectedDesign}
                                                handleEnterPressed={handleEnterPressed}
                                                handleOnDeleteCustomSelect={handleOnDeleteCustomSelect}
                                                handleOnSelectedColorChanged={handleOnSelectedColorChanged}
                                                handleOnDetailsInputChanged={handleOnDetailsInputChanged}
                                            />
                                        )
                                    },
                                    {
                                        label: t('calendar.date'),
                                        value: (
                                            <CalendarEventDate
                                                isClubAdministrator={isClubAdministrator}
                                                isBusinessAdministrator={isBusinessAdministrator}
                                                dateInputFocused={dateInputFocused}
                                                inputs={dates}
                                                whenType={whenType}
                                                setWhenType={newWhenType => {
                                                    setWhenType(newWhenType)
                                                    setSpecificDates([])
                                                    setSchedule([
                                                        { name: 'mon', active: false, values: [] },
                                                        { name: 'tue', active: false, values: [] },
                                                        { name: 'wed', active: false, values: [] },
                                                        { name: 'thu', active: false, values: [] },
                                                        { name: 'fri', active: false, values: [] },
                                                        { name: 'sat', active: false, values: [] },
                                                        { name: 'sun', active: false, values: [] }
                                                    ])
                                                }}
                                                trainers={trainers}
                                                selectedTrainerIds={selectedTrainerIds}
                                                schedule={schedule}
                                                selectedDateType={selectedDateType}
                                                handleAddSpecificDate={(specificDate) => {
                                                    setSpecificDates(prevValue => {
                                                        let prevValueCopy = [...prevValue]
                                                        prevValueCopy = prevValueCopy.concat({ date: specificDate, duration: 0 })
                                                        return prevValueCopy
                                                    })
                                                }}
                                                handleUpdateSpecificDate={(position, value, type) => {
                                                    setSpecificDates(prevValue => {
                                                        let prevValueCopy = [...prevValue]
                                                        if (type === 'date') {
                                                            prevValueCopy[position].date = moment(value)
                                                        }
                                                        else if (type === 'duration') {
                                                            prevValueCopy[position].duration = value
                                                        }
                                                        else {
                                                            prevValueCopy[position].start = value
                                                        }
                                                        return prevValueCopy
                                                    })
                                                }}
                                                handleRemoveSpecificDate={(position) => {
                                                    setSpecificDates(prevValue => {
                                                        let prevValueCopy = [...prevValue]
                                                        prevValueCopy.splice(position, 1)
                                                        return prevValueCopy
                                                    })
                                                }}
                                                specificDates={specificDates}
                                                handleSelectTrainer={handleSelectTrainer}
                                                handleRemoveTrainer={handleRemoveTrainer}
                                                handleCreateSchedule={handleCreateSchedule}
                                                handleRemoveValueFromSchedule={handleRemoveValueFromSchedule}
                                                handleAddValueToSchedule={handleAddValueToSchedule}
                                                handleChangeValueFromSchedule={handleChangeValueFromSchedule}
                                                handleOnNotificationChanged={handleOnNotificationChanged}
                                                handleOnSelectedDateTypeChanged={handleOnSelectedDateTypeChanged}
                                                handleOnDatesInputChanged={handleOnDatesInputChanged}
                                                handleFocusInput={(value) => {
                                                    setDateInputFocused(value)
                                                }}
                                            />
                                        )
                                    },
                                    {
                                        label: t('calendar.notes'),
                                        value: (
                                            <CalendarEventNotes notes={notes} handleOnNotesChanged={handleOnNotesChanged} />
                                        )
                                    }
                                ]}
                            />
                        </Grid>
                        <Grid
                            item
                            display="flex"
                            flexDirection={{ lg: 'row', xs: 'column' }}
                            alignItems="center"
                            justifyContent="center"
                            pb={4}
                        >
                            <Grid item pr={{ lg: 2, xs: 0 }} pb={{ lg: 0, xs: 1 }} minWidth={250} width={{ lg: 'auto', xs: '100%' }}>
                                <Button onClick={handleOnPrevButton} fullWidth variant="outlined">
                                    {activeTab === 0 ? t('button.cancel') : t('button.prev')}
                                </Button>
                            </Grid>
                            <Grid item pl={{ lg: 2, xs: 0 }} pt={{ lg: 0, xs: 1 }} minWidth={250} width={{ lg: 'auto', xs: '100%' }}>
                                <Button disabled={disable} onClick={handleOnNextButton} fullWidth variant="contained">
                                    {activeTab === 3 ? t('button.save') : t('button.next')}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </>

            </Container>
        </Dialog>
    )
}

export default CalendarCreateEvent
