import React, { useCallback, useEffect, useState } from 'react'
import { TableContainer, Typography, Table, TableHead, TableBody, TableRow, TableCell, Paper, Checkbox, Box, IconButton, Grid, TextField, Button } from '@mui/material'
import { Edit, ArrowBack } from '@mui/icons-material'

import * as SUPER_ADMIN_REQUESTS from '../../api/superAdmin'
import * as USER_REQUESTS from '../../api/user'
import { toast } from 'react-toastify'

const SuperAdminIndividuals = ({ search, app }) => {

    const [individuals, setIndividuals] = useState([])
    const [editing, setEditing] = useState()

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')

    const [limit, setLimit] = useState(40)
    const [totalResults, setTotalResults] = useState(0)
    const from = 0

    const handleGetIndividuals = useCallback(async () => {
        try {
            const { data, total } = await SUPER_ADMIN_REQUESTS.getIndividuals(search, app, from, limit)
            setIndividuals(data)
            setTotalResults(total)
        } catch (e) {
            console.error(e)
        }
    }, [search, app, from, limit])

    useEffect(() => {
        handleGetIndividuals()
    }, [handleGetIndividuals, search, app, limit])

    const handleDelete = useCallback(async (e, userId, isDeleted) => {
        try {
            if (isDeleted) {
                await USER_REQUESTS.recoverAccount(userId)
            } else {
                await USER_REQUESTS.deleteUser(userId)
            }


            handleGetIndividuals()
        } catch (e) {
            if (isDeleted) {
                toast.error("Can't recover this account because there already exists an account with the same email.", { position: 'bottom-right' });
            } else {
                toast.error("Something went wrong while deleting the account.", { position: 'bottom-right' });
            }
        }
    }, [handleGetIndividuals])

    const handleDeleteProfileImage = useCallback(async (userId) => {
        try {
            await SUPER_ADMIN_REQUESTS.updateUser(userId, { identityCardSrc: null, avatarPhotoSrc: null })
            handleGetIndividuals()
            alert("Successfully removed")
        } catch (e) {
            console.error(e)
            alert("Error removing avatar")
        }
    }, [handleGetIndividuals])

    const handleUpdate = useCallback(async (userId) => {
        try {
            await SUPER_ADMIN_REQUESTS.updateUser(userId, { firstName, lastName, email, phoneNumber })
            handleGetIndividuals()
            alert("Successfully updated")
        } catch (e) {
            console.error(e)
            alert("Error updating!")
        }
    }, [firstName, lastName, email, phoneNumber, handleGetIndividuals])

    const getLabelForKey = key => {
        if (key === 'firstName') return "First Name"
        if (key === 'lastName') return "Last Name"
        if (key === 'email') return "Email"
        if (key === 'phoneNumber') return "Phone Number"
        return ""
    }

    const getValueForKey = key => {
        if (key === 'firstName') return firstName
        else if (key === 'lastName') return lastName
        else if (key === 'email') return email
        else if (key === 'phoneNumber') return phoneNumber
    }

    const handleShowMore = () => {
        setLimit(prevLimit => prevLimit + 40)
    }
    const handleShowLess = () => {
        setLimit(prevLimit => (prevLimit > 40 ? prevLimit - 40 : 40))
    }

    if (editing) return (
        <Grid container display="flex" flexDirection="column">
            <Grid item xs={12}>
                <IconButton onClick={() => setEditing()}><ArrowBack /></IconButton>
            </Grid>
            <Grid item xs={12} pt={2} pl={1.5}>
                <Grid container display="flex" flexDirection="row">
                    <Grid item xs={6}>
                        <Grid container display="flex" flexDirection="column">
                            {['firstName', 'lastName', 'email', 'phoneNumber'].map(key => {
                                return (
                                    <Grid container display="flex" flexDirection="column" pt={0.5} pb={0.5} pr={2}>
                                        <Grid item xs={12} pb={0.5}>
                                            <Typography fontWeight={500} variant="p">
                                                {getLabelForKey(key)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField onChange={e => {
                                                if (key === 'firstName') setFirstName(e.target.value)
                                                else if (key === 'lastName') setLastName(e.target.value)
                                                else if (key === 'email') setEmail(e.target.value)
                                                else if (key === 'phoneNumber') setPhoneNumber(e.target.value)
                                            }} value={getValueForKey(key)} fullWidth />
                                        </Grid>
                                    </Grid>
                                )
                            })}
                            <Grid container display="flex" flexDirection="column" pt={0.5} pb={0.5} pr={2}>
                                <Grid item xs={12}>
                                    Do you want to remove profile image?
                                </Grid>
                                <Grid item xs={12} pt={2}>
                                    <Button onClick={() => handleDeleteProfileImage(editing.id)} variant="contained" color="error">Remove</Button>
                                </Grid>
                            </Grid>
                            <Grid container display="flex" flexDirection="column" pt={0.5} pb={0.5} pr={2}>
                                <Grid item xs={12} pt={2}>
                                    <Button onClick={() => handleUpdate(editing.id)} variant="contained" color="primary">Save your changes</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>Country</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell align='right'>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {individuals.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>
                                    {row.firstName} {row.lastName}
                                </TableCell>
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>{row.email}</TableCell>
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>{row.phoneNumber}</TableCell>
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>{row.country ? row.country : '-'}</TableCell>
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }}>{row.city ? row.city : '-'}</TableCell>
                                <TableCell sx={{
                                    paddingBottom: 1,
                                    paddingTop: 1
                                }} align='right'>
                                    <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                        <Box pl={1} pr={1}>
                                            <Checkbox checked={row.isDeleted} onChange={e => handleDelete(e, row.id, row.isDeleted)} /> Deleted
                                        </Box>
                                        <Box pl={1} pr={1}>
                                            <IconButton onClick={() => {
                                                setEditing(row)
                                                setFirstName(row.firstName)
                                                setLastName(row.lastName)
                                                setEmail(row.email)
                                                setPhoneNumber(row.phoneNumber)
                                            }}><Edit /></IconButton>
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="center" mt={2}>
                <Button onClick={handleShowLess} variant="contained" disabled={limit <= 5} sx={{ mr: 2 }}>Show Less</Button>
                <Button onClick={handleShowMore} variant="contained" disabled={limit >= totalResults}>Show More</Button>
            </Box>
        </Box>
    )
}

export default SuperAdminIndividuals