import configuredAxios from './axios'

export const createRevolutOrder = (data) => {
  return configuredAxios.post('/transactionInformations/revolut/order', data).then((res) => res.data)
}

// STRIPE
export const createStripeCheckoutSession = (data) => {
  return configuredAxios.post('/transactionInformations/stripe/checkout-session', data).then((res) => res.data)
}

export const checkStripePaymentStatus = (sessionId) => {
  return configuredAxios.get(`/transactionInformations/stripe/payment-status?sessionId=${sessionId}`).then((res) => res.data)
}

export const getByCompanyId = (companyId) => {
  return configuredAxios.get(`/transactionInformations/${companyId}`).then((res) => res.data)
}

export const getLastOrder = (companyId) => {
  return configuredAxios.get(`/transactionInformations/order/${companyId}`).then((res) => res.data)
}

export const generatePaymentUrl = (companyId, data) => {
  return configuredAxios.patch(`/transactionInformations/generate-url/${companyId}`, data)
    .then(res => res.data)
}

export const getSubscriptions = () => {
  return configuredAxios.get(`/transactionInformations/subscriptions`)
    .then(res => res.data)
}

export const complete = (body) => {
  return configuredAxios.post(`/transactionInformations/complete`, {
    ...body
  })
    .then(res => res.data)
}

export const completeSlot = (body) => {
  return configuredAxios.post(`/transactionInformations/complete/slot`, {
    ...body
  })
    .then(res => res.data)
}