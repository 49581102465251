// SubscriptionsStep.js
import React from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import AvailableSubscriptions from './AvailableSubscriptions';
import {
    DEFAULT_MAX_LENGTH,
} from '../../../../utils/constants'
import { useTranslation } from 'react-i18next';

function SubscriptionsStep({ searchSubscription, handleExpand, setSearchSubscription, availableSubscriptions, selectedSubscriptions, handleSelectSubscription }) {
    const { t } = useTranslation()

    return (
        <Grid
            container
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12}>
                <Typography variant="h6">
                    {t('course.youCanAttach')}
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                lg={8}
                width="100%"
                display="flex"
                justifyContent="center"
                flexDirection="row"
                pt={6}
            >
                <TextField
                    inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                    value={searchSubscription}
                    fullWidth
                    placeholder={t('course.searchSub')}
                    name={'searchSubscription'}
                    onChange={(event) => setSearchSubscription(event.target.value)}
                    variant="outlined"
                />
            </Grid>
            <Grid
                item
                xs={12}
                lg={8}
                width="100%"
                display="flex"
                alignItems="center"
                flexDirection="column"
                pt={6}
            >
                <AvailableSubscriptions handleExpand={handleExpand} selectedSubscriptions={selectedSubscriptions} availableSubscriptions={availableSubscriptions} handleSelectSubscription={handleSelectSubscription} />
            </Grid>
        </Grid>
    )
}

export default SubscriptionsStep;