import React from 'react';
import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import * as COMPANY_REQUESTS from '../../../api/company';
import * as ORGANIZATION_REQUESTS from '../../../api/organization';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useAuthentication } from '../../../context/auth-context';

export const openStripeBillingPortal = async (type = 'company') => {
    try {
        const billingPortalUrl = type === 'company' ? await COMPANY_REQUESTS.openStripeBillingPortal() : await ORGANIZATION_REQUESTS.openStripeBillingPortal()

        window.location.href = billingPortalUrl;
    } catch (error) {
        console.error('Cannot open Stripe Billing Portal')
    }
}

const Payments = () => {
    const { t } = useTranslation();
    return (
        <Grid display={'flex'} flexDirection={'column'} pl={{ lg: '110px', md: '20px' }} justifyContent={'center'} sx={{ marginTop: '10%' }}>

            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Typography sx={{ fontWeight: 'bold' }} variant="h6" gutterBottom>
                    Add your payment details
                </Typography>
                <QuestionMarkIcon sx={{ color: '#fff', backgroundColor: '#000', borderRadius: '10px', marginLeft: '10px', fontSize: '20px', marginBottom: '8px' }} />
            </Box>

            <Typography color={'#707070'} variant="subtitle1" gutterBottom>
                Add your card details here and start using SportIn today. No need to talk to our sales team.
            </Typography>

            <Box sx={{
                width: '90%', marginTop: '20px', marginBottom: '20px'
            }}>
                <Button
                    sx={{
                        minWidth: '147px',
                        height: '41px',
                        backgroundColor: '#000000',
                        borderRadius: '30px',
                        opacity: 1,
                        color: '#ffffff',
                        padding: '0 15px',
                        borderColor: '#000000',
                        '&:hover': {
                            backgroundColor: '#000000',
                            borderColor: '#000000'
                        }
                    }}
                    variant="outlined"
                    color="primary"
                    onClick={openStripeBillingPortal}
                >
                    {t('price.updateBillingInformation')}
                </Button>
            </Box>
            <Typography color={'#707070'} variant="subtitle1" gutterBottom>
                Your card will be charged for Ads-Ons in 3 days and for transactions or users at the end of 30 days.
            </Typography>
        </Grid>
    );
};

export default Payments;
