import React, { useEffect, useState, useCallback } from 'react'
import { Dialog, Slide, Grid, Typography, IconButton, Box, Avatar, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { makeStyles } from '@mui/styles'
import { CreditCard, Money, AccountBalance, Close } from '@mui/icons-material'

import { Table, SimplePagination } from '../../../common'

import * as PAYMENT_REQUESTS from '../../../../api/payments'
import { useAuthentication } from '../../../../context/auth-context'
import { numberFomatter } from '../../../../utils/helpers/numberFomatter'
import { PRIMARY_COLOR, PAYMENT_METHOD, GRAY_TEXT_COLOR, DATE_FORMAT } from '../../../../utils/constants'

const PAGE_SIZE = 30


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles(() => ({
    noBorder: {
        borderRadius: 0
    }
}))

export default ({ open, onClose, paymentMethod, start, end }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { user } = useAuthentication()
    const [payments, setPayments] = useState([])
    const [paymentsCount, setPaymentsCount] = useState(0)
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(true)

    const handleGetPayments = useCallback(async () => {
        try {
            const paymentsResp = await PAYMENT_REQUESTS.getPayments(user.companyId, {
                from: (page - 1) * PAGE_SIZE,
                limit: PAGE_SIZE,
                ...(paymentMethod !== 'NOT_PROCESSED' ? { paymentMethod } : {}),
                success: paymentMethod === 'NOT_PROCESSED' ? false : true,
                startDate: moment(start).startOf('day').toISOString().split('T')[0],
                endDate: moment(end).startOf('day').toISOString().split('T')[0]
            })
            setPayments(paymentsResp.rows)
            setPaymentsCount(paymentsResp.count)
            setLoading(false)
        } catch (e) {
            console.error(e)
        }
    }, [user, page, paymentMethod, start, end])

    useEffect(() => {
        handleGetPayments()
    }, [user, page, paymentMethod, start, end])


    const renderPaymentMethod = (method, isBt) => {
        if (method === PAYMENT_METHOD.CARD && !isBt) {
            return <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <Avatar variant="square" src={require('../../../../assets/pos.png')} style={{ width: 25, height: 25, paddingRight: 8 }} /> {t('subscriptionModal.pos')}
            </Box>
        }

        if (method === PAYMENT_METHOD.CARD && isBt) {
            return <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <CreditCard sx={{ paddingRight: 1 }} color='success' /> {t('subscriptionModal.card')}
            </Box>
        }


        if (method === PAYMENT_METHOD.BANK_TRANSFER) {
            return <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <AccountBalance sx={{ paddingRight: 1 }} color="success" /> {t('subscriptionModal.BANK_TRANSFER')}
            </Box>
        }

        return <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <Money sx={{ paddingRight: 1 }} color="success" /> {t('subscriptionModal.cash')}
        </Box>

    }


    return (
        <Dialog classes={{ paper:  classes.noBorder }} fullScreen TransitionComponent={Transition} open={open}>
            <Grid container p={2}>
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item xs={10}>
                            <Typography variant='h4' fontWeight="bold">
                               {t('reports.revenue.transactions')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                            <IconButton onClick={onClose}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="p" color={GRAY_TEXT_COLOR}>
                        {t('reports.revenue.transactionsDescription', {
                            start: moment(start).format(DATE_FORMAT),
                            until: moment(end).format(DATE_FORMAT),
                        })}
                    </Typography>
                </Grid>
                <Grid pt={4} item xs={12} display="flex" alignItems="center" justifyContent="center">
                    {!loading ? <Table
                        dense
                        headCells={[
                            'company',
                            'paymentOrderNumber',
                            'username',
                            'subscription',
                            'status',
                            'paymentMethod',
                            'createdAt',
                            'amount', ,
                            'voucher'
                        ].map((key) => t(`payments.${key}`))}
                        rows={payments.map(payment => {
                            const status = payment.paymentState === 'DEPOSITED' ? 'Paid' : payment.paymentState
                            return {
                                company: <Typography fontWeight={'bold'} color={PRIMARY_COLOR}>{payment.Company ? payment.Company.name : '-'}</Typography>,
                                paymentOrderNumber: payment.paymentOrderNumber || 0,
                                username: payment.User ? payment.User.firstName + ' ' + payment.User.lastName : '-',
                                subscription: payment.Subscription ? payment.Subscription.name : '-',
                                status: <Typography variant="body2" fontWeight="bold" color={status === 'Paid' ? 'green' : 'red'}>{status}</Typography>,
                                paymentMethod: renderPaymentMethod(payment.paymentMethod, payment.paymentOrderId),
                                createdAt: moment(payment.createdAt).format('DD/MM/YYYY HH:mm'),
                                amount: `${numberFomatter(Number(payment.Subscription.priceRon))} ${payment.currency}`,
                                voucher: payment.Voucher ? `${payment.Voucher.discount} ${payment.Voucher.hasFixedDiscount ? payment.currency : '%'}` : '-'
                            }
                        })}
                    /> : <CircularProgress />}
                </Grid>
                {!loading ? <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" pt={1}>
                    <SimplePagination
                        count={Math.ceil(Number(paymentsCount / PAGE_SIZE))}
                        onPageChange={setPage}
                    />
                </Grid> : null}
            </Grid>
        </Dialog>

    )
}