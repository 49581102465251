import { ButtonBase, Container, Grid, StepConnector, StepLabel } from '@mui/material';
import { styled } from '@mui/system';
const StyledContainer = styled(Container)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    padding: '30px',
    borderRadius: '25px',
    height: '852px',
    background: '#FFFFFF',
    boxShadow: '5px 12px 30px #00000010',
    opacity: 1,
}));
const StyledGrid = styled(Grid)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    paddingLeft: '0 px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}));
const StyledGridButton = styled(Grid)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    paddingLeft: '0 px',
    flexDirection: 'row',

}));

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    borderRadius: '25px',
    height: 200,
    [theme.breakpoints.down('sm')]: {
        width: '100% !important',
        height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    borderRadius: '25px',
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    borderRadius: '25px',
    aspectRatio: 16 / 9,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    borderRadius: '25px',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
}));
export {
    ImageButton,
    Image,
    ImageSrc,
    StyledContainer,
    StyledGridButton,
    StyledGrid,
    ImageMarked,
    ImageBackdrop,
}