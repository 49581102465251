import { Grid, Dialog, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'

const DeleteModal = ({ onClose, title, text, onDelete, noDelete }) => {
  const { t } = useTranslation()

  const handleCancelModal = () => {
    onClose()
  }

  const handleDelete = () => {
    return onDelete()
  }

  return (
    <Dialog open={true} PaperProps={{ sx: { width: '100%' } }}>
      <Grid
        p={4}
        container
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
      >
        <Grid item sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <DeleteForeverRoundedIcon
            sx={{ alignSelf: 'center', fontSize: 90 }}
            color={noDelete ? 'primary': "error"}
          ></DeleteForeverRoundedIcon>
          <Typography
            p={2}
            alignSelf="center"
            flex={1}
            id="alert-dialog-title"
            fontWeight="bold"
            variant="h4"
            color="#292838"
          >
            {title}
          </Typography>
        </Grid>
        <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography flex={1} alignSelf="center" variant="p" fontSize={18}>
            {text}
          </Typography>
        </Grid>
        <Grid
          paddingTop={8}
          container
          sx={{
            flexDirection: {
              xs: 'column-reverse',
              md: 'row'
            }
          }}
          spacing={2}
        >
          <Grid xs={12} lg={6} item>
            <Button onClick={handleCancelModal} fullWidth color="primary" variant="outlined">
              {t('button.cancel')}
            </Button>
          </Grid>
          <Grid item xs={12} lg={6}>
            {!noDelete ? <Button onClick={handleDelete} fullWidth color="error" variant="contained">
              {t('button.delete')}
            </Button> : null}
            {noDelete ? <Button onClick={handleCancelModal} fullWidth color="primary" variant="contained">
              OK
            </Button> : null}
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default DeleteModal
