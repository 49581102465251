import React, { useCallback, useMemo, useState } from 'react'
import { Grid, Typography, Avatar, Tooltip, Button, Box, ButtonGroup, TextField, InputAdornment, Autocomplete } from '@mui/material'
import {
    Add,
    AddCircle,
    Check,
    Close,
    Info,
    Markunread,
    Notifications,
    RemoveCircle
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'

import {
    CARD_TEXT_COLOR,
    ERROR_COLOR,
    GRAY_BORDER_COLOR,
    GRAY_TEXT_COLOR,
    GREEN_COLOR,
    LIGHT_PRIMARY_COLOR,
    PRIMARY_COLOR,
    SECONDARY_TEXT_COLOR,
    GRAY_BG_COLOR
} from '../../../utils/constants'
import { HourMinutesMask } from '../../../utils/inputMask'

import { InputGenerator, Toggle } from '../../common'
import moment from 'moment'

const CalendarEventDate = ({
    isClubAdministrator,
    isBusinessAdministrator,
    dateInputFocused,
    inputs,
    trainers,
    selectedTrainerIds,
    schedule,
    handleAddSpecificDate,
    handleUpdateSpecificDate,
    handleRemoveSpecificDate,
    specificDates,
    whenType,
    setWhenType,
    selectedDateType,
    handleRemoveTrainer,
    handleCreateSchedule,
    handleAddValueToSchedule,
    handleRemoveValueFromSchedule,
    handleOnNotificationChanged,
    handleChangeValueFromSchedule,
    handleSelectTrainer,
    handleOnDatesInputChanged,
    handleOnSelectedDateTypeChanged,
    handleFocusInput,
    editing
}) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const matchesPhone = useMediaQuery(theme.breakpoints.down('sm'))

    const [openSpecificDatePicker, setOpenSpecificDatePicker] = useState({
        datePicker: '',
        open: false
    })
    const [openDatePicker, setOpenDatePicker] = useState(false)
    const [createSchedule, setCreateSchedule] = useState({
        show: false,
        value: ''
    })

    const handleSaveSchedule = useCallback(() => {
        handleCreateSchedule(createSchedule.value)
        setCreateSchedule({
            show: false,
            value: ''
        })
    }, [createSchedule])

    const handleCloseSchedule = useCallback(() => {
        setCreateSchedule({
            show: false,
            value: ''
        })
    }, [])

    const getInputData = useCallback((inputName, field) => {
        const inputPosition = inputs.findIndex((el) => el.name === inputName)
        if (inputPosition < 0) return ''

        return inputs[inputPosition][field]
    }, [])

    const createLabelAndInfo = useCallback(
        (inputName, withoutPadding) => {
            const inputPosition = inputs.findIndex((el) => el.name === inputName)
            if (inputPosition < 0) return null

            if (!inputs[inputPosition].label) return null

            return (
                <Grid container display="flex" flexDirection="row" pb={withoutPadding ? 0 : 1}>
                    <Grid item pr={0.5}>
                        <Typography fontWeight={500} variant="p">
                            {inputs[inputPosition].label}
                        </Typography>
                    </Grid>
                    {inputs[inputPosition].mandatory ? (
                        <Grid item pr={0.5}>
                            <Typography fontWeight={500} variant="p" color="primary">
                                *
                            </Typography>
                        </Grid>
                    ) : null}
                    {inputs[inputPosition].infoMessage ? (
                        <Grid item pl={0.5} pt={0.3}>
                            <Tooltip title={inputs[inputPosition].infoMessage} arrow placement="top-start">
                                <Info sx={{ color: CARD_TEXT_COLOR }} fontSize="14px" />
                            </Tooltip>
                        </Grid>
                    ) : null}
                </Grid>
            )
        },
        [inputs]
    )

    const createSimpleInput = useCallback(
        (inputName) => {
            const inputPosition = inputs.findIndex((el) => el.name === inputName)
            if (inputPosition < 0) return null

            return (
                <InputGenerator
                    handleOnChange={handleOnDatesInputChanged}
                    handleOnChangeGoogle={(googleData) =>
                        handleOnDatesInputChanged({ target: { name: inputName, value: googleData.label } })
                    }
                    input={{
                        name: inputName,
                        type: inputs[inputPosition].type,
                        placeholder: inputs[inputPosition].placeholder,
                        value: inputs[inputPosition].value,
                        maxLength: inputs[inputPosition].maxLength,
                        values: inputs[inputPosition].values,
                        endAdornmentComponent: inputs[inputPosition].endAdornmentComponent
                    }}
                />
            )
        },
        [handleOnDatesInputChanged, inputs]
    )

    const createToggleInput = useCallback(
        (inputName, withoutToggle) => {
            const inputPosition = inputs.findIndex((el) => el.name === inputName)
            if (inputPosition < 0) return null

            return (
                <Grid
                    container
                    display="flex"
                    flexDirection="row"
                    sx={{ backgroundColor: LIGHT_PRIMARY_COLOR, borderRadius: '4px' }}
                    p={1.3}
                >
                    <Grid item>
                        <Typography>{createLabelAndInfo(inputName, true)}</Typography>
                    </Grid>
                    {!withoutToggle ? (
                        <Grid item ml={'auto'}>
                            <Toggle
                                color="primary"
                                defaultChecked={inputs[inputPosition].value}
                                onClick={() =>
                                    handleOnDatesInputChanged({
                                        target: { name: inputName, value: !inputs[inputPosition].value }
                                    })
                                }
                            />
                        </Grid>
                    ) : null}
                </Grid>
            )
        },
        [inputs]
    )

    const renderSchedule = useMemo(() => {
        if (whenType !== 'schedule') return null
        return (
            <>
                {schedule.map((el) => {
                    return (
                        <Grid
                            item
                            lg={12}
                            display="flex"
                            flexDirection={{ lg: 'row', xs: el.values.length > 0 ? 'column' : 'row' }}
                            pb={2}
                            pt={2}
                            sx={{ borderBottom: `1px solid ${LIGHT_PRIMARY_COLOR}` }}
                        >
                            <Grid item display="flex" flexDirection="row" pt={1.5} pb={el.values.length > 0 ? 1.5 : 0}>
                                <Toggle
                                    color="primary"
                                    defaultChecked={el.active}
                                    onClick={() => {
                                        handleChangeValueFromSchedule(el.name, undefined, 'active', undefined)
                                    }}
                                />
                                <Typography
                                    pt={0.5}
                                    pl={2}
                                    pr={2}
                                    variant="body2"
                                    fontWeight={700}
                                >
                                    {t(`days.${el.name.toUpperCase()}`).toUpperCase()}
                                </Typography>
                            </Grid>
                            <Grid item display="flex" flexDirection="column" pl={{ lg: 4, xs: 0.5 }} pt={{ lg: 0, xs: 2 }}>
                                {el.values.map((val, index) => (
                                    <Grid item display="flex" flexDirection="row" alignItems="center" pb={1}>
                                        <TextField
                                            sx={{ width: matchesPhone ? 110 : 170 }}
                                            value={val.start}
                                            onFocus={() => handleFocusInput(true)}
                                            onBlur={() => handleFocusInput(false)}
                                            onChange={(e) => {
                                                handleChangeValueFromSchedule(el.name, index, 'start', e.target.value, dateInputFocused)
                                            }
                                            }
                                            placeholder="Start"
                                            type="time"
                                            InputProps={{
                                                inputComponent: HourMinutesMask,
                                            }}
                                        />
                                        <Typography color={CARD_TEXT_COLOR} pl={2} pr={2}>
                                            -
                                        </Typography>
                                        <TextField
                                            sx={{ width: matchesPhone ? 110 : 170 }}
                                            value={val.duration}
                                            onChange={(e) =>
                                                handleChangeValueFromSchedule(el.name, index, 'duration', e.target.value, dateInputFocused)
                                            }
                                            onFocus={() => handleFocusInput(true)}
                                            onBlur={() => handleFocusInput(false)}
                                            placeholder={t('calendar.duration')}
                                            type="number"
                                            InputProps={{
                                                endAdornment: !matchesPhone ? (
                                                    <InputAdornment position="start">
                                                        <Typography variant="p" fontWeight="bold" color={PRIMARY_COLOR}>
                                                            min
                                                        </Typography>
                                                    </InputAdornment>
                                                ) : null
                                            }}
                                        />
                                        <RemoveCircle
                                            onClick={() => handleRemoveValueFromSchedule(el.name, index)}
                                            sx={{ marginLeft: 2, color: ERROR_COLOR, cursor: 'pointer' }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid item ml="auto" pt={{ lg: 1.5, xs: el.values.length > 0 ? 0 : 1.5 }}>
                                <AddCircle
                                    onClick={() => handleAddValueToSchedule(el.name)}
                                    sx={{ paddingRight: 1, cursor: 'pointer' }}
                                    color="primary"
                                />
                            </Grid>
                        </Grid>
                    )
                })}
            </>
        )
    }, [schedule, matchesPhone, whenType, dateInputFocused, t])

    const renderLeftInputs = useMemo(() => {
        return (
            <Grid item lg={7} xs={12} pr={{ lg: 3, xs: 0 }}>
                {!editing ? <Grid container display="flex" flexDirection="column" pb={4}>
                    <Grid item lg={12}>
                        <Typography variant="body2" fontWeight={600}>
                            {t('calendar.whenToBeCreated')}
                        </Typography>
                    </Grid>
                    <Grid item lg={12} display="flex" flexDirection="row" pt={2}>
                        <ButtonGroup
                            fullWidth
                            variant="outlined"
                            sx={{ maxHeight: 35 }}
                            aria-label="outlined button group"
                        >
                            <Button
                                onClick={() => setWhenType('specific')}
                                variant={whenType === 'specific' ? 'contained' : 'outlined'}
                            >
                                {t('calendar.specificDate')}
                            </Button>
                            <Button
                                onClick={() => setWhenType('schedule')}
                                variant={whenType === 'schedule' ? 'contained' : 'outlined'}
                            >
                                {matchesPhone ? t('calendar.schedule') : t('calendar.createSchedule')}
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid> : null}
                <Grid container display="flex" flexDirection="column">
                    {!createSchedule.show && whenType === 'schedule' ? <Grid item lg={12} ml="auto" display="flex" flexDirection="row" alignItems="center">
                        <Add sx={{ marginRight: 0.5, cursor: 'pointer' }} onClick={() => {
                            setCreateSchedule({
                                show: true
                            })
                        }} color="primary" />
                        <Typography onClick={() => {
                            setCreateSchedule({
                                show: true
                            })
                        }} sx={{ cursor: 'pointer' }} variant="body2" color="primary" fontWeight={600}>
                            {t('calendar.saveAsSchedule')}
                        </Typography>
                    </Grid> : null}
                    {createSchedule.show && whenType === 'schedule' ? <Grid item lg={12} display="flex" flexDirection="row" alignItems="flex-end" ml={{ lg: 'auto', xs: 0 }}>
                        <InputGenerator
                            handleOnChange={event => {
                                setCreateSchedule(prevValue => ({ ...prevValue, value: event.target.value }))
                            }}
                            input={{
                                name: 'newSchedule',
                                type: '',
                                placeholder: t('calendar.scheduleName'),
                            }}
                        />
                        <Button
                            variant="outlined"
                            onClick={handleSaveSchedule}
                            sx={{
                                backgroundColor: '#F5F9FD',
                                borderColor: GRAY_BORDER_COLOR,
                                borderWidth: 1,
                                marginLeft: 1,
                                marginRight: 0.5,
                                minWidth: 40,
                                width: 40,
                                height: 45,
                                '&:hover': { borderWidth: 1, borderColor: GRAY_BORDER_COLOR }
                            }}
                        >
                            <Check sx={{ color: GREEN_COLOR }} />
                        </Button>
                        <Button
                            onClick={handleCloseSchedule}
                            variant="outlined"
                            sx={{
                                backgroundColor: '#F5F9FD',
                                borderColor: GRAY_BORDER_COLOR,
                                borderWidth: 1,
                                marginLeft: 0.5,
                                marginRight: 0.5,
                                minWidth: 40,
                                width: 40,
                                height: 45,
                                '&:hover': { borderWidth: 1, borderColor: GRAY_BORDER_COLOR }
                            }}
                        >
                            <Close sx={{ color: ERROR_COLOR }} />
                        </Button>
                    </Grid> : null}
                    {renderSchedule}
                    {whenType === 'specific' ? specificDates.length === 0 ?
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                label={t('calendar.startDate')}
                                inputFormat="MM/DD/YYYY"
                                onChange={e => {
                                    handleAddSpecificDate(e)
                                    setOpenDatePicker(false)
                                }}
                                renderInput={(params) => <TextField {...params} onClick={() => setOpenDatePicker(true)} fullWidth sx={{
                                    "& .MuiInputBase-root": {
                                        "& .MuiButtonBase-root": {
                                            padding: 0,
                                            marginRight: 2,
                                        },
                                    }
                                }} />}
                            />
                        </LocalizationProvider> : <Grid container display="flex" flexDirection="column">
                            {specificDates.map((el, index) => {
                                return (
                                    <Grid item xs={12} pb={2}>
                                        <Grid container display="flex" flexDirection="row" alignItems="center">
                                            <Grid item lg={4} xs={12} pb={{ lg: 0, xs: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DesktopDatePicker
                                                        label={t('calendar.startDate')}
                                                        inputFormat="MM/DD/YYYY"
                                                        value={moment(el.date)}
                                                        onChange={date => {
                                                            handleUpdateSpecificDate(index, date, 'date')

                                                        }}
                                                        renderInput={(params) => <TextField {...params} onClick={() => setOpenSpecificDatePicker({
                                                            datePicker: index,
                                                            open: true
                                                        })} sx={{
                                                            "& .MuiInputBase-root": {
                                                                "& .MuiButtonBase-root": {
                                                                    padding: 0,
                                                                    marginRight: 2,
                                                                },
                                                            },
                                                            width: '100%'
                                                        }} />}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item lg={8} xs={12} pl={{ lg: 1, xs: 0 }}>
                                                <Grid container display="flex" flexDirection="row" alignItems="center">
                                                    <Grid item lg={editing ? 6 : 5.5} xs={editing ? 6 : 5.5}>
                                                        <TextField
                                                            fullWidth
                                                            sx={{ paddingRight: 1 }}
                                                            value={el.start}
                                                            onChange={(e) => {
                                                                handleUpdateSpecificDate(index, e.target.value, 'start')
                                                            }
                                                            }
                                                            placeholder={t('calendar.start')}
                                                            type="time"
                                                            InputProps={{
                                                                inputComponent: HourMinutesMask,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={editing ? 6 : 5.5} xs={editing ? 6 : 5} pr={1}>
                                                        <TextField
                                                            fullWidth
                                                            sx={{ paddingLeft: 1 }}
                                                            placeholder={t('calendar.duration')}
                                                            type="number"
                                                            value={el.duration}
                                                            onChange={event => handleUpdateSpecificDate(index, event.target.value, 'duration')}
                                                            InputProps={{
                                                                endAdornment: !matchesPhone ? (
                                                                    <InputAdornment position="start">
                                                                        <Typography variant="p" fontWeight="bold" color={PRIMARY_COLOR}>
                                                                            min
                                                                        </Typography>
                                                                    </InputAdornment>
                                                                ) : null
                                                            }}
                                                        />
                                                    </Grid>
                                                    {!editing && <Grid item lg={1} xs={1}>
                                                        <RemoveCircle
                                                            onClick={() => handleRemoveSpecificDate(index)}
                                                            sx={{ marginLeft: 2, color: ERROR_COLOR, cursor: 'pointer' }}
                                                        />
                                                    </Grid>}
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                )
                            })}
                            {!editing && <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        label={t('calendar.startDate')}
                                        inputFormat="MM/DD/YYYY"
                                        onChange={e => {
                                            handleAddSpecificDate(e)
                                            setOpenDatePicker(false)
                                        }}
                                        renderInput={(params) => <TextField {...params} onClick={() => setOpenDatePicker(true)} fullWidth sx={{
                                            "& .MuiInputBase-root": {
                                                "& .MuiButtonBase-root": {
                                                    padding: 0,
                                                    marginRight: 2,
                                                },
                                            }
                                        }} />}
                                    />
                                </LocalizationProvider>
                            </Grid>}
                        </Grid> : null}
                    {whenType === 'schedule' && !editing ? <Grid container display="flex" flexDirection="row" pb={{ lg: 0, xs: 2 }} pt={2}>
                        <Grid item lg={getInputData('recurring', 'value') ? 6 : 12} xs={12} pt={{ lg: 4.5, xs: 2 }} pb={{ xs: 1, lg: 0 }}>
                            {createToggleInput('recurring')}
                        </Grid>
                        {getInputData('recurring', 'value') ? <Grid item lg={6} xs={12} pl={{ lg: 1, xs: 0 }} pt={{ xs: 1, lg: 0 }}>
                            {createLabelAndInfo('recurringWeeks')}
                            {createSimpleInput('recurringWeeks')}
                        </Grid> : null}
                    </Grid> : null}
                    {(isBusinessAdministrator || isClubAdministrator) && !editing ? <Grid container display="flex" flexDirection="column">
                        <Grid item lg={12} xs={12} pt={{ lg: 4.5, xs: 2 }} pb={{ xs: 1, lg: 0 }}>
                            {createToggleInput('withTrainers')}
                        </Grid>
                        {getInputData('withTrainers', 'value') ? <Grid item lg={12} xs={12} pt={2}>
                            <Autocomplete
                                options={trainers
                                    .filter((el) => !selectedTrainerIds.includes(el.id))
                                    .map((el) => ({
                                        label: `${el.firstName} ${el.lastName}`,
                                        avatar: el.avatarPhotoSrc,
                                        phoneNumber: el.phoneNumber,
                                        value: el.id,
                                        firstName: el.firstName,
                                        lastName: el.lastName
                                    }))}
                                autoHighlight
                                placeholder="Search for trainer"
                                renderOption={(props, option) => {
                                    return (
                                        <Box {...props} sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                {option.avatar ? (
                                                    <img
                                                        style={{ borderRadius: 25 }}
                                                        width={50}
                                                        height={50}
                                                        src={option.avatar}
                                                    />
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            width: 50,
                                                            height: 50,
                                                            backgroundColor: GRAY_BG_COLOR,
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            borderRadius: 25
                                                        }}
                                                    >
                                                        <Typography fontWeight="bold" color={GRAY_TEXT_COLOR}>
                                                            {option.firstName.charAt(0)} {option.lastName.charAt(0)}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 1 }}>
                                                <Typography fontWeight={500}>{option.label}</Typography>
                                            </Box>
                                        </Box>
                                    )
                                }}
                                filterOptions={(options, state) => options}
                                onChange={(_, newValue) => {
                                    if (!newValue) return
                                    return handleSelectTrainer(newValue.value)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        placeholder={t('calendar.searchTrainer')}
                                        {...params}
                                        InputProps={{ ...params.InputProps, endAdornment: null }}
                                        inputProps={{
                                            ...params.inputProps,
                                            style: {
                                                padding: 11.5,
                                                color: SECONDARY_TEXT_COLOR,
                                                fontWeight: 500,
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid> : null}
                        {getInputData('withTrainers', 'value') && selectedTrainerIds.length > 0 ? <Grid item lg={12} xs={12} pt={2} pr={1}>
                            <Grid container display="flex" flexDirection="row">
                                {selectedTrainerIds.map((trainerId, index) => {
                                    const relatedTrainer = trainers.find(el => el.id === trainerId)
                                    return (
                                        <Grid key={trainerId} item display="flex" flexDirection="row" alignItems="center" mr={2} mt={2}>
                                            <Avatar alt={`${relatedTrainer.firstName.charAt(0).toUpperCase()} ${relatedTrainer.lastName.charAt(0).toUpperCase()}`} src={relatedTrainer.avatarPhotoSrc}>{relatedTrainer.firstName.charAt(0).toUpperCase()} {relatedTrainer.lastName.charAt(0).toUpperCase()}</Avatar>
                                            <Typography variant="body1" ml={2} mr={2}>{relatedTrainer.firstName} {relatedTrainer.lastName}</Typography>
                                            <Close sx={{ color: ERROR_COLOR, cursor: 'pointer' }} onClick={() => handleRemoveTrainer(trainerId)} fontSize="sm" />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid> : null}
                    </Grid> : null}
                </Grid>
            </Grid >
        )
    }, [inputs, editing, openDatePicker, openSpecificDatePicker, specificDates, trainers, whenType, selectedTrainerIds, handleSelectTrainer, handleSaveSchedule, handleCloseSchedule, createSchedule, renderSchedule, matchesPhone, dateInputFocused])

    const renderRightInputs = useMemo(() => {
        return (
            <Grid item lg={5} xs={12} pl={{ lg: 3, xs: 0 }} pt={{ lg: 0, xs: 3 }}>
                <Grid container display="flex" flexDirection="column" pb={4}>
                    <Grid item lg={12}>
                        <Typography variant="body2" fontWeight={600}>
                            {t('calendar.datesTitle')}
                        </Typography>
                    </Grid>
                    <Grid item lg={12} display="flex" flexDirection="row" pt={2}>
                        <ButtonGroup
                            fullWidth
                            variant="outlined"
                            sx={{ maxHeight: 35 }}
                            aria-label="outlined button group"
                        >
                            <Button
                                onClick={() => handleOnSelectedDateTypeChanged('saved')}
                                variant={selectedDateType === 'saved' ? 'contained' : 'outlined'}
                            >
                                {t('calendar.savedSchedule')}
                            </Button>
                            <Button
                                onClick={() => handleOnSelectedDateTypeChanged('custom')}
                                variant={selectedDateType === 'custom' ? 'contained' : 'outlined'}
                            >
                                {t('calendar.customHours')}
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                {selectedDateType === 'saved' ? (
                    <Grid container display="flex" flexDirection="row" pb={4}>
                        <Grid item lg={12} xs={12}>
                            {createLabelAndInfo('scheduleToUse')}
                            {createSimpleInput('scheduleToUse')}
                        </Grid>
                    </Grid>
                ) : null}
                <Grid container display="flex" flexDirection="row" pb={4}>
                    <Grid
                        item
                        lg={getInputData('onlineBooking', 'value') ? 5 : 12}
                        xs={12}
                        pb={{ lg: 0, xs: 1 }}
                        display="flex"
                        alignItems="flex-end"
                    >
                        {createToggleInput('onlineBooking')}
                    </Grid>
                    {getInputData('onlineBooking', 'value') ? (
                        <Grid item lg={3.5} xs={12} pl={{ lg: 2, xs: 0 }} pr={{ lg: 1, xs: 0 }} pb={{ lg: 0, xs: 1 }} pt={{ lg: 0, xs: 1 }}>
                            {createLabelAndInfo('cancel')}
                            {createSimpleInput('cancel')}
                        </Grid>
                    ) : null}
                    {getInputData('onlineBooking', 'value') ? (
                        <Grid item lg={3.5} xs={12} pl={{ lg: 1, xs: 0 }} pr={{ lg: 1, xs: 0 }} pt={{ lg: 0, xs: 1 }}>
                            {createLabelAndInfo('book')}
                            {createSimpleInput('book')}
                        </Grid>
                    ) : null}
                </Grid>
                <Grid container display="flex" flexDirection="row" pb={4}>
                    <Grid item lg={12} xs={12}>
                        {createToggleInput('withVideoLink')}
                    </Grid>
                </Grid>
                {getInputData('withVideoLink', 'value') ? <Grid container display="flex" flexDirection="row" pb={4}>
                    <Grid item lg={12} xs={12}>
                        {createLabelAndInfo('videoLink')}
                        {createSimpleInput('videoLink')}
                    </Grid>
                </Grid> : null}
                {getInputData('onlineBooking', 'value') ? <Grid container display="flex" flexDirection="row" pb={4}>
                    <Grid item lg={12} xs={12}>
                        {createToggleInput('accessOnlyOnStart')}
                    </Grid>
                </Grid> : null}
                <Grid container display="flex" flexDirection="row" pb={4}>
                    <Grid item lg={6} xs={8}>
                        {createToggleInput('clubNotifications', true)}
                    </Grid>
                    <Grid
                        item
                        lg={6}
                        xs={4}
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Notifications
                            onClick={() => handleOnNotificationChanged('clubNotifications', 'notification')}
                            fontSize="large"
                            sx={{
                                marginRight: 1,
                                cursor: 'pointer',
                                color: getInputData('clubNotifications', 'value').includes('notification')
                                    ? PRIMARY_COLOR
                                    : CARD_TEXT_COLOR
                            }}
                        />
                        <Markunread
                            onClick={() => handleOnNotificationChanged('clubNotifications', 'mail')}
                            fontSize="large"
                            sx={{
                                marginLeft: 1,
                                cursor: 'pointer',
                                color: getInputData('clubNotifications', 'value').includes('mail')
                                    ? PRIMARY_COLOR
                                    : CARD_TEXT_COLOR
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container display="flex" flexDirection="row" pb={4}>
                    <Grid item lg={6} xs={6}>
                        {createToggleInput('clubNotificationsBook')}
                    </Grid>
                    <Grid item lg={6} xs={6}>
                        {createToggleInput('clubNotificationsUnbook')}
                    </Grid>
                    <Grid item lg={6} xs={6}>
                        {createToggleInput('clubNotificationsFullEvent')}
                    </Grid>
                    <Grid item lg={6} xs={6}>
                        {createToggleInput('clubNotificationsWaitingList')}
                    </Grid>
                </Grid>
                <Grid container display="flex" flexDirection="row" pb={4}>
                    <Grid item lg={6} xs={8}>
                        {createToggleInput('trainerNotifications', true)}
                    </Grid>
                    <Grid
                        item
                        lg={6}
                        xs={4}
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Notifications
                            onClick={() => handleOnNotificationChanged('trainerNotifications', 'notification')}
                            fontSize="large"
                            sx={{
                                marginRight: 1,
                                cursor: 'pointer',
                                color: getInputData('trainerNotifications', 'value').includes('notification')
                                    ? PRIMARY_COLOR
                                    : CARD_TEXT_COLOR
                            }}
                        />
                        <Markunread
                            onClick={() => handleOnNotificationChanged('trainerNotifications', 'mail')}
                            fontSize="large"
                            sx={{
                                marginLeft: 1,
                                cursor: 'pointer',
                                color: getInputData('trainerNotifications', 'value').includes('mail')
                                    ? PRIMARY_COLOR
                                    : CARD_TEXT_COLOR
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container display="flex" flexDirection="row" pb={4}>
                    <Grid item lg={6} xs={6}>
                        {createToggleInput('trainerNotificationsBook')}
                    </Grid>
                    <Grid item lg={6} xs={6}>
                        {createToggleInput('trainerNotificationsUnbook')}
                    </Grid>
                    <Grid item lg={6} xs={6}>
                        {createToggleInput('trainerNotificationsFullEvent')}
                    </Grid>
                    <Grid item lg={6} xs={6}>
                        {createToggleInput('trainerNotificationsWaitingList')}
                    </Grid>
                </Grid>
            </Grid>
        )
    }, [inputs, selectedDateType, handleOnNotificationChanged])

    const renderDates = useMemo(() => {
        return (
            <Grid container display="flex" flexDirection="column" pt={{ lg: 6, xs: 2 }} pb={4} minHeight={'65vh'}>
                <Grid item lg={12} xs={12}>
                    <Grid container display="flex" flexDirection="row">
                        {renderLeftInputs}
                        {renderRightInputs}
                    </Grid>
                </Grid>
            </Grid>
        )
    }, [inputs, renderRightInputs, renderLeftInputs, dateInputFocused])

    return renderDates
}

export default CalendarEventDate
