import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, IconButton, Button, Typography, Grid } from '@mui/material'
import { Close, Warning } from '@mui/icons-material'

import { LIGHT_GRAY_BORDER_COLOR } from '../../utils/constants'

const Confirm = ({
  cancelText,
  onCancel,
  confirmText,
  onConfirm,
  description,
  titleText,
  titleColor,
  confirmButtonColor,
  onlyConfirm,
  isMissingInfo,
  Icon,
  iconColor,
  noTitle
}) => {
  return (
    <Dialog open maxWidth="sm" fullWidth>
      <Grid container pt={4}>
        {isMissingInfo ? <Grid pl={5} pr={5} item xs={12} pt={2} pb={2} display="flex" justifyContent="center">
          {Icon ? <Icon sx={{ width: 80, height: 80 }} color={iconColor ? iconColor : 'primary'} /> : <Warning sx={{ width: 60, height: 60 }} color="primary" />}
        </Grid> : null}
        <Grid item xs={12} pl={5} pr={5}>
          <Grid container display="flex" flexDirection="row">
            {!noTitle ? <Grid item
              xs={isMissingInfo ? 12 : 10}
              md={isMissingInfo ? 12 : 10}
              lg={isMissingInfo ? 12 : 10}
              display="flex"
              justifyContent={isMissingInfo ? 'center' : 'flex-start'}
            >
              <Typography variant="h5" fontWeight="bold" display color={titleColor ? titleColor : 'black'}>
                {titleText}
              </Typography>
            </Grid> : null}
            {isMissingInfo ? null : <Grid item xs={2} display="flex" flexDirection="row" justifyContent={"flex-end"} sx={isMissingInfo ? { alignItems: 'center', justifyContent: 'center' } : {}}>
              <IconButton onClick={onCancel}>
                <Close />
              </IconButton>
            </Grid>}
          </Grid>
        </Grid>
        <Grid
          item
          pt={3}
          pb={3}
          xs={12}
          display="flex"
          justifyContent="center"
          pl={4.5} pr={4.5}
          borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
        >
          <Typography variant="p">{description}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container display="flex" flexDirection="row" sx={{ height: 60 }}>
            {!onlyConfirm && <Grid item xs={6} lg={6} sx={{ height: 60 }} display="flex" justifyContent="center" alignItems="center" borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
              <Button style={{ height: '100%', fontWeight: 'bold' }} onClick={onCancel} fullWidth variant="text" color="error">
                {cancelText}
              </Button>
            </Grid>}
            <Grid item xs={onlyConfirm ? 12 : 6} lg={onlyConfirm ? 12 : 6}>
              <Button
                sx={{ height: 60 }}
                style={{ height: '100%', fontWeight: 'bold' }}
                onClick={() => {
                  onConfirm()
                  onCancel()
                }}
                fullWidth
                color={confirmButtonColor || 'primary'}
                variant="text"
              >
                {confirmText}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}

Confirm.propTypes = {
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmText: PropTypes.string,
  titleText: PropTypes.string,
  titleColor: PropTypes.string
}

export default Confirm
