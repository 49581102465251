import { useMemo, useState, useCallback, useEffect, useRef } from 'react'
import { Grid, Dialog, Typography, Button, TextField, Avatar } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import * as AUTH_REQUESTS from '../../../../api/auth'
import * as DOCUMENTS_REQUESTS from '../../../../api/documents'
import { useError } from '../../../../context/error-context'
import { ConfirmNoSavingModal, SendingButton as SendingButtonChild } from '../../../common'
import { BUCKET_BASE_URL } from '../../../../utils/config'
import { DEFAULT_MAX_LENGTH } from '../../../../utils/constants'
import { useAuthentication } from '../../../../context/auth-context'

const useStyles = makeStyles(() => ({
  noBorder: {
    borderRadius: 0
  }
}))

const EditProfile = ({
  onClose,
  firstName,
  lastName,
  bioDescription,
  avatarPhotoSrc,
  handleGetMe,
  Transition
}) => {
  const { handleToken, handleUser } = useAuthentication()
  const theme = useTheme()
  const classes = useStyles()
  const matchesTablet = useMediaQuery(theme.breakpoints.down('md'))

  const [firstname, setFirstName] = useState(firstName)
  const [lastname, setLastName] = useState(lastName)
  const [bio, setBio] = useState(bioDescription)
  const [sending, setSending] = useState(false)
  const [openClose, setOpenClose] = useState(false)
  const [changesMade, setChangesMade] = useState(false)
  const inputFileRef = useRef()

  const { t } = useTranslation()
  const { handleError } = useError()

  const onFileChangeHandler = (event) => {
    const file = event.target.files[0]

    const name = Date.now()
    const extension = file.type.split('/')[1]

    setSending(true)

    DOCUMENTS_REQUESTS.generatePresignedUrl({ name, extension })
      .then((presignedUrl) => {
        return DOCUMENTS_REQUESTS.uploadDocumentS3(presignedUrl, file)
      })
      .then(() => {
        return AUTH_REQUESTS.updateUser({
          avatarPhotoSrc: `${BUCKET_BASE_URL}/${name}.${extension}`
        })
      })
      .then(async () => {
        const response = await AUTH_REQUESTS.regenerateToken();
        handleToken(response.token);
        
        setChangesMade(true)
        setSending(false)
        return handleGetMe()
      })
      .catch(() => {
        setSending(false)
      })
  }

  useEffect(() => {
    setBio(bioDescription)
    setFirstName(firstName)
    setLastName(lastName)
  }, [firstName, lastName, bioDescription])

  const handleCancelModal = () => {
    onClose()
    setChangesMade(false)
  }

  const handleChangeFirstName = useCallback(
    (event) => {
      setChangesMade(true)
      setFirstName(event.target.value)
    },
    [firstname]
  )

  const handleChangeLasttName = useCallback(
    (event) => {
      setChangesMade(true)
      setLastName(event.target.value)
    },
    [lastname]
  )

  const handleUpdateProfile = async () => {
    const body = { firstName: firstname, lastName: lastname, bio: bio }
    let result
    setSending(true)
    try {
      result = await AUTH_REQUESTS.updateUser(body)
      const response = await AUTH_REQUESTS.regenerateToken()
      handleToken(response.token)
      handleUser(response.user)
      localStorage.setItem('token', response.token)

      setSending(false)
    } catch (e) {
      handleError(e)
      setSending(false)
    }

    if (result) {
      handleCancelModal()
    }
  }

  const handleRemoveProfileImage = async () => {
    try {
      setSending(true)
      await AUTH_REQUESTS.updateUser({ avatarPhotoSrc: null })
      handleGetMe()
      setSending(false)
    } catch (e) {
      setSending(false)
      console.error(e)
    }
  }

  const renderDialogEditProfile = useMemo(() => {
    return (
      <>
        {openClose ? <ConfirmNoSavingModal onClose={() => setOpenClose(false)} onConfirm={() => {
          setOpenClose(false)
          handleCancelModal()
        }} /> : null}
        <Dialog
          maxWidth={'xl'}
          fullScreen={matchesTablet}
          open={true}
          TransitionComponent={Transition}
          classes={{
            ...(matchesTablet ? { paper: classes.noBorder } : {})
          }}
        >
          <input
            type="file"
            ref={inputFileRef}
            onChangeCapture={onFileChangeHandler}
            style={{ display: 'none' }}
          />
          <Grid
            p={2}
            container
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            <Grid
              item
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography p={2} fontWeight={500} variant="h4" color="#292838">
                {t('userProfile.editProfile')}
              </Typography>
              <Avatar
                src={avatarPhotoSrc}
                alt="avatar"
                style={{ borderRadius: 50, objectFit: 'contain', height: 100, width: 100 }}
              >
                <Typography variant="h4" color="white" fontWeight="bold">
                  {firstName.charAt(0)} {lastName.charAt(0)}
                </Typography>
              </Avatar>
              <Grid
                container
                pt={2}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Button
                    disabled={sending}
                    sx={{ alignSelf: 'center' }}
                    onClick={() => {
                      inputFileRef.current.click()
                    }}
                    color="primary"
                    variant="outlined"
                  >
                    {!sending ? t('userProfile.uploadImage') : <SendingButtonChild />}
                  </Button>
                </Grid>
                <Grid item>
                  {avatarPhotoSrc ? (
                    <Button
                      onClick={handleRemoveProfileImage}
                      sx={{ minWidth: 70, marginLeft: 1 }}
                      variant="outlined"
                      color="error"
                    >
                      <Delete />
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid container sx={{ display: 'flex', flexDirection: 'column' }} marginTop={2}>
              <Grid
                container
                flexDirection={{ lg: 'row', xs: 'column' }}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Grid item xs={12} lg={6} pr={0.5}>
                  <Grid paddingTop={1}>
                    <Typography color="black" variant="p">
                      {t('input.label.firstName')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} pt={0.5}>
                    <TextField
                      placeholder={t('input.placeholder.firstName')}
                      inputProps={{
                        style: { fontSize: 14, color: '#292838' },
                        maxLength: DEFAULT_MAX_LENGTH
                      }}
                      value={firstname}
                      fullWidth={{ xl: false, xs: true }}
                      onChange={handleChangeFirstName}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} lg={6} pl={0.5}>
                  <Grid paddingTop={1}>
                    <Typography color="black" variant="p">
                      {t('input.label.lastName')}
                    </Typography>
                  </Grid>
                  <Grid xs={12} paddingTop={0.5}>
                    <TextField
                      inputProps={{
                        style: { fontSize: 14, color: '#292838' },
                        maxLength: DEFAULT_MAX_LENGTH
                      }}
                      placeholder={t('input.placeholder.lastName')}
                      fullWidth
                      value={lastname}
                      onChange={handleChangeLasttName}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid paddingTop={1}>
                <Grid>
                  <Typography color="black" variant="p">
                    {t('userProfile.bio')}
                  </Typography>
                </Grid>
                <Grid paddingTop={1}>
                  <ReactQuill
                    defaultValue={bio}
                    modules={{
                      clipboard: {
                        matchVisual: false
                      }
                    }}
                    onChange={(newContent) => {
                      setBio(newContent)
                      setChangesMade(true)
                    }}
                  >
                    <div style={{ minHeight: 250 }} />
                  </ReactQuill>
                </Grid>
              </Grid>
              <Grid
                paddingTop={3}
                container
                sx={{
                  flexDirection: {
                    xs: 'column-reverse',
                    lg: 'row'
                  }
                }}
                spacing={2}
              >
                <Grid xs={12} lg={6} item>
                  <Button onClick={() => {
                    if (changesMade) {
                      setOpenClose(true)
                    }
                    else {
                      handleCancelModal()
                    }
                  }} fullWidth color="primary" variant="outlined">
                    {t('button.cancel')}
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button onClick={handleUpdateProfile} fullWidth color="primary" variant="contained">
                    {!sending ? t('button.save') : <SendingButtonChild />}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      </>
    )
  }, [firstname, openClose, bio, lastname, avatarPhotoSrc, matchesTablet])

  return renderDialogEditProfile
}

export default EditProfile
