import React, { useEffect, useState } from 'react'
import { Grid, Card, Typography, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  Instagram,
  Facebook,
  KeyboardArrowRight,
  KeyboardArrowDown,
  Delete
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import Linkify from 'react-linkify'
import { Link } from 'react-router-dom'

import { LIGHT_GRAY_BORDER_COLOR, CARD_TEXT_COLOR } from '../../../../../utils/constants'
import * as SERVICE_REQUESTS from '../../../../../api/services'
import { BASE_URL } from '../../../../../utils/config'

const useStyles = makeStyles({
  content: {
    borderRadius: 10,
    border: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
  }
})

const Service = ({
  name,
  description,
  price,
  currency,
  trainerName,
  expand,
  handleExpand,
  id,
  handleOnOpenEdit,
  handleOnDelete,
  ...restOfService
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [reviewsAverage, setReviewAverage] = useState()
  const [reviewCount, setReviewCount] = useState()

  useEffect(() => {
    if (!expand) return

    SERVICE_REQUESTS.getAllReviews(id).then(({ reviewsAverage, reviewsCount }) => {
      setReviewAverage(reviewsAverage)
      setReviewCount(reviewsCount)
    })
  }, [expand])

  if (!expand)
    return (
      <Grid
        container
        component={Card}
        className={classes.content}
        display="flex"
        flexDirection="row"
        alignItems="center"
        onClick={() => handleExpand(id)}
        pt={3}
        pb={3}
      >
        <Grid item xs={0.3} lg={0.3} pl={2} pt={1}>
          <Typography fontSize="large" color="primary">
            <KeyboardArrowRight />
          </Typography>
        </Grid>
        <Grid item xs={11} lg={9.2}>
          <Typography sx={{ cursor: 'pointer' }} pl={3} variant="h6" fontWeight={500}>
            {name}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={2.5} pr={2} pl={{ lg: 2, xs: 3 }}>
          <Typography color="primary" fontWeight="bold" variant="h6">
            {price || 'N/A'} {price ? currency : ''}
          </Typography>
        </Grid>
      </Grid>
    )

  return (
    <Grid container component={Card} className={classes.content}>
      <Grid item xs={12} display="flex" flexDirection={{ lg: 'row', xs: 'column' }} width="100%">
        <Grid
          item
          onClick={() => handleExpand(id)}
          lg={8}
          xs={12}
          borderRight={{
            xs: 'none',
            lg: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
          }}
          borderBottom={{
            xs: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
            lg: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
          }}
          display="flex"
          alignItems="center"
          flexWrap="noWrap"
        >
          <KeyboardArrowDown color="primary" sx={{ paddingLeft: 2 }} />
          <Typography sx={{ cursor: 'pointer' }} p={3} pl={1.5} variant="h6" fontWeight={500}>
            {name}
          </Typography>
        </Grid>
        <Grid
          item
          lg={4}
          xs={12}
          borderBottom={{
            xs: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`,
            lg: `1px solid ${LIGHT_GRAY_BORDER_COLOR}`
          }}
        >
          <Grid
            container
            display="flex"
            flexDirection="row"
            pt={2}
            pl={2}
            pr={2}
            pb={{ xs: 2, lg: 2 }}
          >
            <Grid xs={6} lg={6} item>
              <Typography color={CARD_TEXT_COLOR}>{t('userProfile.activity.price')}</Typography>
              <Typography color="primary">
                {price || 'N/A'}{' '}
                {price ? currency : ''}
              </Typography>
            </Grid>
            <Grid
              xs={6}
              lg={6}
              item
              display="flex"
              flexDirection="column"
              alignItems={{ lg: 'flex-end', xs: 'flex-end' }}
            >
              <Typography color={CARD_TEXT_COLOR}>{t('userProfile.activity.trainer')}</Typography>
              {restOfService.Trainers.length ? (
                restOfService.Trainers.map((el) => {
                  return (
                    <Link style={{ textDecoration: 'none' }} to={'/' + el.Trainer.domain}>
                      <Typography
                        display="flex"
                        justifyContent="flex-end"
                        sx={{ textDecoration: 'underline', textAlign: 'right' }}
                        fontWeight={600}
                        color="primary"
                      >
                        {el.Trainer.firstName} {el.Trainer.lastName}
                      </Typography>
                    </Link>
                  )
                })
              ) : (
                <>
                  <Typography
                    sx={{ cursor: 'pointer', textDecoration: 'underline', textAlign: 'right' }}
                    fontWeight={600}
                    color="primary"
                  >
                    {trainerName}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item display="flex" flexDirection={{ xs: 'column', lg: 'row' }} width="100%">
        <Grid lg={8} item borderRight={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}>
          <Grid flexWrap="noWrap" p={3} display="flex" flexDirection="column">
            <Typography color={CARD_TEXT_COLOR}>{t('userProfile.activity.description')}</Typography>
            <Linkify>
              <Typography fontWeight={500}>{description}</Typography>
            </Linkify>
            <Grid pt={2} container display="flex" flexDirection="row" alignItems="center">
              <Grid item pr={1}>
                <Typography color={CARD_TEXT_COLOR}>
                  {t('userProfile.activity.trainerProgramComponent.share')}
                </Typography>
              </Grid>
              <a href="https://instagram.com" rel="noreferrer" target="_blank">
                <Instagram color="primary" />
              </a>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${BASE_URL}/services/${id}`}
                rel="noreferrer"
                target="_blank"
              >
                <Facebook color="primary" />
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          lg={4}
          pr={{ lg: 3, xs: 3 }}
          pl={{ lg: 3, xs: 3 }}
          pb={{ xs: 3, lg: 0 }}
          pt={1}
          item
          display="flex"
          flexDirection="column"
        >
          <Grid
            container
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="flex-end"
            height="100%"
            pb={2}
          >
            {!restOfService.fromCompany ? (
              <Button
                sx={{ minWidth: 120 }}
                onClick={() => handleOnOpenEdit(id)}
                variant="outlined"
              >
                {t('userProfile.activity.trainerProgramComponent.edit')}
              </Button>
            ) : null}
            {!restOfService.fromCompany ? (
              <Button
                sx={{ minWidth: 70, marginLeft: 1 }}
                onClick={() => handleOnDelete(id)}
                variant="outlined"
                color="error"
              >
                <Delete />
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Service
