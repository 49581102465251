import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'

import FavouriteClub from './subcomponents/FavouritClub'
import FavouritTrainer from './subcomponents/FavouritTrainer'

import { LIGHT_GRAY_BORDER_COLOR } from '../../../../utils/constants'

import * as USER_REQUESTS from '../../../../api/user'
import { useAuthentication } from '../../../../context/auth-context'

const Details = ({ bio, Reviews }) => {
  const { t } = useTranslation()
  const { user } = useAuthentication()

  const [followTrainers, setFollowTrainers] = useState([])
  const [followGyms, setFollowGyms] = useState([])

  useEffect(() => {
    USER_REQUESTS.getFollowers({ userId: user.id }).then((res) => {
      setFollowTrainers(
        res
          .filter((el) => el.FollowerUser)
          .map((el) => ({
            name: `${el.FollowerUser.firstName} ${el.FollowerUser.lastName}`,
            domain: el.FollowerUser.domain,
            id: el.FollowerUser.id,
            avatarPhotoSrc: el.FollowerUser.avatarPhotoSrc
          }))
      )

      setFollowGyms(
        res
          .filter((el) => el.FollowerCompany)
          .map((el) => ({
            name: el.FollowerCompany.name,
            domain: el.FollowerCompany.domain,
            locationName: el.FollowerCompany.locationName,
            id: el.FollowerCompany.id,
            avatarPhotoSrc: el.FollowerCompany.avatarPhotoSrc
          }))
      )
    })
  }, [user])

  return (
    <Grid
      container
      width="100%"
      display="flex"
      flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }}
      pt={2}
      pb={4}
    >
      <Grid display="flex" item flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }} xs={12}>
        <Grid item pl={3} pr={3} lg={12} xs={12}>
          <Grid
            pb={2}
            borderBottom={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Grid pb={1.5}>
              <Typography fontWeight={500} color="black" fontSize={20}>
                {t('userProfile.activity.detailComponent.bio')}
              </Typography>
            </Grid>
            <Grid className="bio">
              {bio ? (
                <Typography color={`${bio ? 'black' : '#979797'}`} fontSize={17}>
                  {parse(bio) || t('helper.noInformation')}
                </Typography>
              ) : (
                <Typography color="#979797" fontWeight={500} fontSize={17}>
                  {t('helper.noInformation')}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid pb={2} pt={2} display="flex" justifyContent="center" flexDirection="column">
            <Grid pb={1.5}>
              <Typography fontWeight={500} color="black" fontSize={20}>
                {t('userProfile.activity.detailComponent.favouritesTrainers')}
              </Typography>
            </Grid>
            <Grid item display="flex" flexDirection={{ xs: 'column', lg: 'row', xl: 'row' }}>
              {followTrainers.length > 0 ? (
                followTrainers.map((el, index) => {
                  return (
                    <Grid item xs={12} lg={4} pb={1} key={index}>
                      <Link style={{ textDecoration: 'none', color: 'black' }} to={`/${el.domain}`}>
                        <FavouritTrainer name={el.name} avatarPhotoSrc={el.avatarPhotoSrc} />
                      </Link>
                    </Grid>
                  )
                })
              ) : (
                <Typography color="#979797" fontWeight={500} fontSize={17}>
                  {t('helper.noInformation')}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        pt={1.5}
        mr={3}
        ml={3}
        borderTop={`1px solid ${LIGHT_GRAY_BORDER_COLOR}`}
        item
        width="100%"
      >
        <Grid pb={1.5}>
          <Typography fontWeight={500} color="black" fontSize={20}>
            {t('userProfile.activity.detailComponent.favouritesGyms')}
          </Typography>
        </Grid>
        <Grid sx={{ overflowX: 'scroll' }} display="flex" flexDirection="row" pb={2}>
          {followGyms.length > 0 ? (
            followGyms.map((el, index) => {
              return (
                <Grid key={index} mr={2}>
                  <Link style={{ textDecoration: 'none', color: 'black' }} to={`/${el.domain}`}>
                    <FavouriteClub
                      name={el.name}
                      address={el.locationName}
                      avatar={el.avatarPhotoSrc}
                    />
                  </Link>
                </Grid>
              )
            })
          ) : (
            <Typography color="#979797" fontWeight={500} fontSize={17}>
              {t('helper.noInformation')}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Details
